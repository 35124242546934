import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { PaymentType } from '../enums/payment-type';
import { FinalizeCart } from '../models/finalize-cart';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslatePipe } from '@ngx-translate/core';
import { FullCart } from '../models/full-cart';
import { CartGroup } from '../models/cart-group';
import { CartItem } from '../models/cart-item';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-recapitulatif',
    templateUrl: './recapitulatif.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./recapitulatif.component.less']
})
export class RecapitulatifComponent implements OnInit, OnDestroy {
    //#region local properties
    //subscriptions
    private finalizeCartSubscription?: Subscription;
    private getCartSubscription: Subscription | undefined;

    recapitulatifForm!: FormGroup;
    submitted: boolean = false;
    paymentType = PaymentType;

    infosPaiementVirementBancaire: any;

    //current user
    user: any = null;

    //@changesNotSaved : flag to detect non saved changes of properties modePaiement, valideRGPD or authorizeSendInfo
    //choicesChanged if user changes modePaiement, valideRGPD or authorizeSendInfo
    //so we can swich between validate button and continue button
    //changesNotSaved: boolean = false;

    //current Cart
    cart?: FullCart;

    //#endregion

    //#region cotr
    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private translatePipe: TranslatePipe,
        private formBuilder: FormBuilder
    ) {
        //init user
        this.user = JSON.parse(sessionStorage.getItem('userData')!);

        //init form
        this.initializeForm();
    }
    //#endregion

    //#region handle lifecycle hooks
    ngOnInit() {
        this.getCart();
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                this.infosPaiementVirementBancaire = resG.infosPaiementVirementBancaire;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeActiveSubscriptions();
    }
    //#endregion

    //#region handle events
    onSubmit(): void {
        this.submitted = true;
        if (this.recapitulatifForm.invalid) {
            console.log('this.recapitulatifForm.invalid ==> ', this.recapitulatifForm.invalid);
            this.messageService.add({ severity: 'error', summary: this.app.translate.instant('racine.errorsOther.errorForm')
            , detail: this.app.translate.instant('racine.errorsOther.errorFormDetail') });
        }
        else { this.finalizeCart(); }
    }

    onChoicesChange(): void {
        //console.log(this.recapitulatifForm.get('paymentType')?.value)
        //this.startEditingChoices();
    }
    //#endregion

    //#region submit event
    async saveLastStepAndGoToNextPage(_path: any) {
        let stepObj: any = {
            Step: JSON.parse(sessionStorage.getItem('activeStep')!),
            FolderID: this.user.FolderID
        }

        try {
            const response = await this.app.apiService.setdpstep(stepObj);
            console.log("[setPreviewStep] response API ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(this.app.translate.instant(''), this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.app.sharedTools.goTo('/contrat');
        }
        catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region private methods

    private unsubscribeActiveSubscriptions(): void {
        if (this.finalizeCartSubscription) {
            this.finalizeCartSubscription.unsubscribe();
        }

        if (this.getCartSubscription) {
            this.getCartSubscription.unsubscribe();
        }
    }

    private finalizeCart(): void {
        let finalizeCart: FinalizeCart = new FinalizeCart(this.user.FolderID
            , this.recapitulatifForm.get('paymentType')?.value
            , this.recapitulatifForm.get('gPRDAccepted')?.value
            , this.recapitulatifForm.get('shareInfoAccepted')?.value);

        this.finalizeCartSubscription = this.app.apiService.finalizeCart(finalizeCart).subscribe({
            next: (data: any) => {
                console.log('finalizeCartSubscription API ==> ', data);

                if (data && data.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                    return;
                }

                this.saveLastStepAndGoToNextPage('/contrat');

                //this.endEditingChoices();
                // this.messageService.add({
                //     severity: 'success'
                //     , summary: this.translatePipe.transform('fo.recapPage.requestSuccessHeader')
                //     , detail: this.translatePipe.transform('fo.recapPage.finalizeCartSuccessfully')
                // });

            },
            error: (error: any) => {
                console.log(error);
                // this.messageService.add({
                //     severity: 'error'
                //     , summary: this.translatePipe.transform('fo.recapPage.requestErrorHeader')
                //     , detail: this.translatePipe.transform('fo.recapPage.finalizeCartError')
                // });
            }
        });
    }

    // private startEditingChoices(): void {
    //     this.changesNotSaved = true;
    // }

    // private endEditingChoices(): void {
    //     this.changesNotSaved = false;
    // }

    private getCart(): void {
        this.getCartSubscription = this.app.apiService
            .getFullCart(this.user.FolderID).subscribe({
                next: (data: FullCart) => {
                    this.processGetFullCartSuccess(data);
                    this.mapUserChoicesFromCart();
                },
                error: (error: any) => {
                    console.log(error);
                }
            })
    }

    private processGetFullCartSuccess(data: FullCart): void {
        //save response data to local property
        let newFullCart: FullCart = new FullCart();

        data.CartGroups = data.CartGroups?.map((cg: CartGroup) => {

            cg.CartItems = cg.CartItems?.map((ci: CartItem) => {
                let newCartItem: CartItem = new CartItem();
                Object.assign(newCartItem, ci);

                return newCartItem;
            });

            let newCartGroup: CartGroup = new CartGroup();
            Object.assign(newCartGroup, cg);

            return newCartGroup;
        });

        Object.assign(newFullCart, data);

        this.cart = newFullCart;
    }

    private mapUserChoicesFromCart(): void {
        this.recapitulatifForm.get('paymentType')?.setValue(this.cart?.PaymentType);
        this.recapitulatifForm.get('gPRDAccepted')?.setValue(this.cart?.GPRDAccepted ?? null);
        this.recapitulatifForm.get('shareInfoAccepted')?.setValue(this.cart?.ShareInfoAccepted);
    }

    private initializeForm(): void {
        this.recapitulatifForm = this.formBuilder.group({
            paymentType: [null],
            gPRDAccepted: [null, Validators.requiredTrue],
            shareInfoAccepted: [null],
        })
    }
    //#endregion
}
