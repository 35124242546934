import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';
import { StatusCode } from '../enums/status-code';
import { AwardsRoutes, AwardsRoutesQueryParamsNames } from '../awards-module/awards.routes';

@Injectable({
  providedIn: 'root'
})
export class AwardGuard implements CanActivate {
  folderGuid: any;
  awardFormId: any;

  constructor(
    public apiService: ApiService,
    public router: Router) {
      this.getFormAwardData();
    }
    
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let currentRoute = route.url.map(segment => segment.path).join('/');

    // no awardId means create mode, wich is only available on generalities while we didnt exceed nbmaxproduct
    if (!route.queryParams?.hasOwnProperty(AwardsRoutesQueryParamsNames.awardId)) {

      // handle case route is generalities
      if (!this.awardFormId) return false;

      if (currentRoute === AwardsRoutes.generalities) {
        let can: any = this.apiService.checkAwardSolutions(this.awardFormId)
          .then(
            (response: any) => {
              if (!response || response && response.Result) return false;

              console.log("checkAwardSolutions ==> ", response);
              if (response && response.NbSolutions < response.NbMaxProduct) {
                return true
              }
              else {
                return false;
              }
            },
            (reason) => {
              return false;
            }
          );
        return can;
      }

      // handle other routes
      return false;
    }



    //get solution id
    let solutionId = route.queryParams[AwardsRoutesQueryParamsNames.awardId];

    //get sol by id
    console.log("solutionId ==> ", solutionId);
    if (solutionId) {
      let can: any = this.apiService.getAwardSolutionById(solutionId)
        .then(
          (result: any) => {
            if (result) {
              console.log("solution ==> ", result);

              if (result && result.Status.Code === StatusCode.TO_BE_SUBMIT || result.Status.Code === StatusCode.DRAFT_FICHE) {
                return true
              }
              else {
                this.navigateToBestChoice(solutionId);
                return false;
              }
            }
            else {
              this.navigateToBestChoice(solutionId);
              return false;
            }
          },
          (reason) => {
            this.navigateToBestChoice(solutionId);
            return false;
          }
        );
      return can;

      // if status == draft or to be submit return true
      // else no ===> return false
    }
    // create mode
    else {
      return true;
    }
  }

  navigateToBestChoice(solutionId?: any): void {
    console.log(solutionId)
    if (solutionId) {
      let queryParams: any =
      {
        [AwardsRoutesQueryParamsNames.awardId]: solutionId
      };

      this.router.navigate([AwardsRoutes.recap], { queryParams: queryParams });
    }
    else {
      if (this.folderGuid) {
        let queryParams: any =
        {
          [AwardsRoutesQueryParamsNames.folderGuid]: this.folderGuid
        };

        this.router.navigate([AwardsRoutes.welcome], { queryParams: queryParams });
      }
    }
  }

  private getFormAwardData() {
    console.log(sessionStorage.getItem('award-form'))
    let awardform: any = JSON.parse(sessionStorage.getItem('award-form') ?? '{}');

    if (awardform && awardform.FolderGuid) {
      this.folderGuid = awardform.FolderGuid;
    }

    if (awardform && awardform.AwardFormID) {
      this.awardFormId = awardform.AwardFormID;
    }
  }
}