import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-tab-navigation',
    templateUrl: './tab-navigation.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./tab-navigation.component.less']
})
export class TabNavigationComponent {
    menutabs: any = [];
    paths: any = [];
    currentPath: any = "";
    activeIndex: number = 0;

    constructor(
        public app: AppComponent,
    ) { }

    ngOnInit() {
        let _tabActive = JSON.parse(sessionStorage.getItem('tabActive')!);
        this.paths = location.pathname.split('/');
        console.log("this.paths ==> ", this.paths);
        this.currentPath = this.paths[1];
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                // this.menutabs = resG.tabMenu[this.currentPath];
                this.menutabs = resG.tabMenu.filter((tab: any) => { return tab.pathsIn.indexOf(this.currentPath) != -1 })
                if (_tabActive) this.activeIndex = this.app.sharedTools.getIndexOfTab();
            });
    }

    onTabChange(event: any) {
        console.log('Tab changed:', event.index);
        let tab: any = null;
        tab = this.menutabs.filter((tab: any) => { return tab.index == event.index })[0];

        if (tab) {
            sessionStorage.setItem('tabActive', JSON.stringify(tab));
            if (tab.parentPath != this.currentPath) this.app.sharedTools.redirectTo(tab.parentPath);
        }
    }
}
