import { Component, ViewEncapsulation } from '@angular/core';
import { Menu, MenuItem, SideMenu } from '../models/menu';
import { SharedtoolsService } from '../services/sharedtools.service';
import { IdentityManagerService } from '../services/identity-manager.service';
import { cloneDeep } from 'lodash';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./sidenav.component.less']
})
export class SidenavComponent {

  sideMenu: SideMenu = { menu: [] };
  folderGuid: string = '';

  constructor(public sharedTools: SharedtoolsService
    , private identityManagerService: IdentityManagerService
    , public app: AppComponent) { }

  async ngOnInit() {
    let user = JSON.parse(sessionStorage.getItem('userData')!);
    console.log(user);
    if (!user) {
      this.app.router.navigate(['/login']);
      return;
    }

    //
    this.folderGuid = await this.app.apiService.getFirstFolderGuid();

    this.sharedTools.getJSON('general_data').subscribe(
      resG => {
        let menu = resG.sideMenu;
        this.sideMenu = this.checkRoles(menu);
        this.orderMenu();
        console.log("this.sideMenu ===> ", this.sideMenu);
      });
  }

  navigate(menu: Menu): void {
    if (menu?.isExternal) {
      window.open(menu.path, "_blank")
    } else {
      if (menu.id === 'marketing' || menu.id == "marketing-speaker") {
        let obj: any = {
          folderguid: this.folderGuid
        }
        this.app.router.navigate([menu.path], { queryParams: obj });
      }
      else {
        if (menu.path)
          this.app.router.navigate([menu.path]);
      }
    }
  }

  //#region private methods 
  private checkRoles(menu: Menu[]): SideMenu {
    let newMenus: Menu[] = [];

    menu.forEach((m: Menu) => {
      //process first level
      let clonedMenu = cloneDeep(m);
      if (clonedMenu.menuItems
        && clonedMenu.menuItems.length > 0
        && (!clonedMenu.showToRoles
          || (clonedMenu.showToRoles.length === 0)
          || this.identityManagerService.haveAuthorizedRole(clonedMenu.showToRoles))
      ) {
        //begin process second level
        let authorizedMenuItems
          = clonedMenu.menuItems.filter((mi: MenuItem) => {
            if (!mi.showToRoles
              || (mi.showToRoles.length === 0)
              || this.identityManagerService.haveAuthorizedRole(mi.showToRoles)) {
              return true;
            }
            return false;
          });

        //fin process second level
        if (authorizedMenuItems && authorizedMenuItems.length > 0) {
          clonedMenu.menuItems = authorizedMenuItems;
          newMenus.push(clonedMenu);
        }
      }
      //menu have no menu items
      else {
        if (this.identityManagerService.haveAuthorizedRole(clonedMenu.showToRoles ?? [])) {
          newMenus.push(clonedMenu);
        }

      }
    });

    let newSideMenu: SideMenu = { menu: newMenus };
    return newSideMenu;
  }

  //order menu && menu items by property view order
  private orderMenu(): void{
    if(!this.sideMenu || !this.sideMenu.menu || !Array.isArray(this.sideMenu.menu) || !(this.sideMenu.menu.length > 0) ){
      return;
    }

    //order second level menus
    this.sideMenu.menu.forEach((m: Menu)=> {
      if(!m.menuItems || !Array.isArray(m.menuItems) || !(m.menuItems.length > 0) ){
        return;
      }

      m.menuItems = m.menuItems.sort(this.compareFn);
    });
    

    //order first level menus
    this.sideMenu.menu = this.sideMenu.menu.sort(this.compareFn);
  }

  private compareFn(a: Menu, b:Menu): number{
    return (a.viewOrder ?? 0) - (b.viewOrder ?? 0)
  }
  //#endregion
}
