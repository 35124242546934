import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ImageModelSuffix } from 'src/app/enums/image-model-suffix';
import { Languages } from 'src/app/enums/language';
import { ConferenceHelper } from '@helpers/conference.helper';
import { IdentityManagerService } from 'src/app/services/identity-manager.service';
import { SharedtoolsService } from 'src/app/services/sharedtools.service';
import { FOExternalPagesRoutes } from 'src/app/consts/fo-external-pages.routes';

@Component({
  selector: 'app-conf-card-details',
  templateUrl: './conf-card-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./conf-card-details.component.less']
})
export class ConfCardDetailsComponent implements OnInit {

  @Input() confDetail: any;
  @Input() DateOfSearch: any;
  search: any = {};
  DayConference: any;
  currentlangue: any;
  interests: any;
  speakers: any;
  exhibitors: any;
  isEnabled: boolean = false;
  calendarObj: any;
  Description: string = '';
  Location: string = '';
  Summary: string = '';
  IsfileIsc: boolean = false;
  isActive: boolean = false;
  currentMonth: number = 1;
  tagsMaxLenght: number = 3;
  notDisplayedTags: string[] = [];

  get dividerColor(): string{
    return (this.confDetail?.ThemeCategory?.Color ?? '#000000');
  };
  tagsArray: string[] = [];
  notDisplayedTagsArray: string[] = [];

  isMobile!: boolean;

  languages = Languages;

  imageModelSuffix = ImageModelSuffix;
  conferenceHelper = ConferenceHelper;
  selectedAccess : any
  access : any
  
  constructor(
    public app: AppComponent,
    private datePipe: DatePipe,
    private breakpointObserver: BreakpointObserver,
    public sharedService : SharedtoolsService,
    protected ims: IdentityManagerService) {
  }

  onToggleOverlay(event: any, op: any, targetEl: any) {
    //if (!this.isCompositionFormEditMode) {
      op.toggle(event, targetEl)
    //}
  }


  isPale : boolean = false;
  ngOnInit(): void {
    this.app.sharedTools.getJSON('general_data').subscribe(
      data => {
        this.access = data.accessTypes
      }
    )
    if (this.confDetail?.CnfdTags) {
      this.tagsArray = this.confDetail?.CnfdTags.split('|');
    } else {
      this.tagsArray = [];
    }
    for (let i =this.tagsMaxLenght; i < this.tagsArray.length; i++) {     
        this.notDisplayedTags.push(this.tagsArray[i]); 
    }
    
    // TODO : commented by @Hassen, ticket @CS-174
    // this.checkDateConf();
    
  }

  async getDetail(confEventID: any) {
    let queryParams: any = {
      eventID: confEventID
    };

    this.app.sharedTools.routeTo(FOExternalPagesRoutes.confDetail, queryParams);
  }

  getSelectedAccess(conference : any){
    if(conference?.IsPrivate == null) return null
    if(conference.IsPrivate == true){
      this.selectedAccess = this.access?.find((x:any)=> x.TypeId == 1)
    }else if(conference.IsPrivate == false ){
      this.selectedAccess = this.access?.find((x:any)=> x.TypeId == 2)
    }
    return this.selectedAccess
  }

  async AddToCalendar(confID: any): Promise<void> {
    this.IsfileIsc = true;
    console.log(confID);
    let calendarData: string = '';
    const response = await this.app.apiService.getConfByEventId(confID)
    this.calendarObj = response;
    console.log("calendarObj", this.calendarObj);
    if (this.calendarObj) {
      if (this.app.translate.currentLang == 'en') {
        this.Description = this.calendarObj?.CnfdDescriptionEN;
        this.Location = this.calendarObj?.ConferenceEvent?.CategoryEventsDetails?.LabelEN;
        if (this.calendarObj?.ConferenceProgram?.CnfprNameEN != null && this.calendarObj?.ConferenceProgram?.CnfprNameEN != '') {
          this.Summary = this.calendarObj?.ConferenceProgram?.CnfprNameEN + ' : ' + this.calendarObj?.CnfdNameEN

        } else {
          this.Summary = this.calendarObj?.CnfdNameEN
        }
      }
      else if (this.app.translate.currentLang == 'fr') {
        this.Description = this.calendarObj?.CnfdDescriptionFR;
        this.Location = this.calendarObj?.ConferenceEvent?.CategoryEventsDetails?.LabelFR;
        if (this.calendarObj?.ConferenceProgram?.CnfprNameFR != null && this.calendarObj?.ConferenceProgram?.CnfprNameFR != '') {
          this.Summary = this.calendarObj?.ConferenceProgram?.CnfprNameFR + ' : ' + this.calendarObj?.CnfdNameFR

        }
        else {
          this.Summary = this.calendarObj?.CnfdNameFR
        }

      }
      else if (this.app.translate.currentLang == 'es') {
        this.Description = this.calendarObj?.CnfdDescriptionES;
        this.Location = this.calendarObj?.ConferenceEvent?.CategoryEventsDetails?.LabelES;
        if (this.calendarObj?.ConferenceProgram?.CnfprNameES != null && this.calendarObj?.ConferenceProgram?.CnfprNameES != '') {
          this.Summary = this.calendarObj?.ConferenceProgram?.CnfprNameES + ' : ' + this.calendarObj?.CnfdNameES

        } else {
          this.Summary = this.calendarObj?.CnfdNameES
        }
      }
      calendarData = [
        'data:text/calendar;charset=utf8,',
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'DESCRIPTION:' + this.Description,
        'DTSTART:' + this.calendarObj?.ConferenceEvent?.CnfeHourStart,
        'DTEND:' + this.calendarObj?.ConferenceEvent?.CnfeHourEnd,
        'LOCATION:' + this.Location,
        'SUMMARY:' + this.Summary,
        'TRANSP:TRANSPARENT',
        'END:VEVENT',
        'END:VCALENDAR',
        'UID:' + this.calendarObj?.ConfDetailID,
        'DTSTAMP:' + this.calendarObj?.ConferenceEvent?.Duration,
        'PRODID:website-1.0'
      ].join('\n');
      console.log(calendarData);
      this.downloadCalendar(calendarData);
    }

    this.IsfileIsc = false;
  }

  downloadCalendar(data: any) {

    let dtStart = this.calendarObj?.ConferenceEvent?.CnfeHourStart;
    let formatedDtStart = this.datePipe.transform(dtStart, 'dd/MM/yyyy');

    let fileName = formatedDtStart?.toString();

    var link = document.createElement("a");

    link.setAttribute("href", data);
    link.setAttribute("download", fileName!);
    link.click();

  }

  isImageUrl(url: string) {

    let isValidUrl: boolean = false;
    if (url == null || url == undefined) {
      return false;
    }
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const extension = url?.substring(url.lastIndexOf('.'))?.toLowerCase();

    isValidUrl = imageExtensions.includes(extension);

    return isValidUrl;
  }

  formatDayOfWeek(date: any) {
    return this.datePipe.transform(date, 'EEEE', undefined, this.app.translate.currentLang);
  }

  formatDayOfMonth(date: any) {
    return this.datePipe.transform(date, 'd', undefined, this.app.translate.currentLang);
  }

  formatDate(date: any): string{    
    return `${this.formatDayOfWeek(date)} ${this.formatDayOfMonth(date)}`;
  }

  getCurrentMonth(date: any) {
    return this.datePipe.transform(date, 'MM');
  }

  async GoToMap(location: any) {
    this.sharedService.goToMap(location)
  }

  onSpeakerClick(speaker: any): void{
    if(speaker && speaker.ContactID){
      const queryparams = {
        speakerID: speaker.ContactID
      }
    
      this.app.sharedTools.routeTo(FOExternalPagesRoutes.speakerDetail, queryparams);
    }
  }

  public function(contact: any): string{
    if(!contact){
      return "";
    }

    return this.app.sharedTools.getPropertySwitchLang(contact, 'Function');
  }

  public grade(contact: any): string{
    if(!contact){
      return "";
    }

    return this.app.sharedTools.getPropertySwitchLang(contact.GradeCategory, 'Label');
  }

  private checkDateConf(): void{
    if(this.confDetail?.ConferenceEvent?.CnfeHourEnd){
      let dateNow = new Date();
      let dateConf = new Date(this.confDetail?.ConferenceEvent?.CnfeHourEnd);
      if (dateConf < dateNow) {
        this.isPale = true;
      }
      else{
        this.isPale = false;
      }
    }
  }
}
