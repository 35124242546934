import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./footer.component.less']
})
export class FooterComponent {
    appID: any = null;
    copyRight: any = null;
    
    constructor(
        public app: AppComponent,
    ) { }

    ngOnInit() {
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG ==> ", resG);
                this.appID = resG.APP_ID;
                this.copyRight = resG.copyRight;
            });
    }

}
