import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { Dropdown } from 'primeng/dropdown';
import { EmailRegex } from '../consts/utils';
import { SendInvitationMailRequest } from '../models/send-invitation-mail-request';

@Component({
  selector: 'app-contact-innov',
  templateUrl: './contact-innov.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./contact-innov.component.less']
})
export class ContactInnovComponent {
  @Input() componentID: number = -1;
  @Input() solutionID: number = -1;
  @Input() isDisplayOnly: boolean = false;
  @Input() isMultiple: boolean = false;
  @Input() FormeID!: number;
  @Input() enableInvitationEmail: boolean = false;
  @Input() hasSmartphone:boolean =true;
  user: any = null;

  langues: any[] = [];
  civilities: any[] = [];
  phones: any[] = [];
  PhonePrefixSelected: any;
  mobiles: any[] = [];
  MobilePrefixSelected: any;

  SelectedCivility: any;
  SelectedLanguage: any;

  //#region composition form
  initCompositionForm: boolean = false;
  compositionForm!: FormGroup;
  isCompositionFormEditMode: boolean = false;
  showCompositionForm: boolean = false;

  availableContactsList: any = [];
  selectedContacts: any = [];
  selectedContactsMap: any = {};
  availableContactsMap: any = {};

  newContact: any;
  isContactFormSubmitted: boolean = false;
  focusedContact: any;


  selectedContact: any;
  userID: any;
  emailContact: any;
  //#endregion

  isEmailUnique: boolean = false;
  @Input() isContactRequired : boolean = false 
  @Input() isSubmitted : boolean = false 
  isContactAttached : boolean = false

  @Output() isContactSelectd = new EventEmitter<any>()

  constructor(
    public app: AppComponent,
  ) {
    this.getFormInnovData();
  }



  ngOnInit() {

    this.user = JSON.parse(sessionStorage.getItem('userData')!);
    console.log("this.user ==> ", this.user);
    if (!this.user) {
      this.app.router.navigate(['/login']);
      return;
    }

    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
        this.refreshComposition();
        this.getPhones();
        this.getLanguages();
        this.getCivilities();
      }
    );



    // this.primengConfig.ripple = true;

  }

  //#region handle events
  onDetachContact(contact: any) {
    this.app.confirm.confirmDialog(''
      , this.app.translate.instant('racine.confirmMsg.confirmODContactDetach')
      , 'AUTRE'
      , 'confirm'
      , () => {
        this.detachContact(contact, this.componentID); // ComponentID = 1
      }
      , () => { });
  }

  onAttachContact(event: any) {
    let contact = event.value;
    console.log('onAttachContact ', contact);
    this.app.confirm.confirmDialog(''
      , this.app.translate.instant('racine.confirmMsg.confirmODContactAttach')
      , 'AUTRE'
      , 'confirm'
      , () => {
        this.attachContact(contact, this.componentID);
      }
      , () => { });
  }

  onCompositionFormCancel() {
    this.clearCompositionForm();
  }

  onCompositionFormSubmit(sendEmail: boolean = false) {
    let mobileControl = this.compositionForm.get('Mobile');
    let mobilePrefixControl = this.compositionForm.get('MobilePrefixSelected');

    let phoneControl = this.compositionForm.get('Phone');
    let phonePrefixControl = this.compositionForm.get('PhonePrefixSelected');

    mobileControl?.updateValueAndValidity();
    mobilePrefixControl?.updateValueAndValidity();
    phoneControl?.updateValueAndValidity();
    phonePrefixControl?.updateValueAndValidity();

    this.isContactFormSubmitted = true;
    console.log('[this.compositionForm]', this.compositionForm);

    if (this.compositionForm.invalid) {

      console.log("invalid form ===>", this.compositionForm);
      console.log("invalid form ===>", this.compositionForm.errors);

      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.compositionForm.controls;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log('Invalid form values:', invalidValues);

      if (invalidField && invalidField.length > 1) {
        console.log("invalidField", invalidField)

        setTimeout(() => {
          let el = invalidField[1].offsetTop - 100;
          if (el) window.scroll({ top: el, behavior: "smooth" });
        }, 10);
      }
      //
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
      }, () => { });
    } else {

      let newDo = this.compositionForm.getRawValue();

      // if (newDo.selectedODContactRole) newDo.TypeRoleOD = newDo.selectedODContactRole.ID;
      // if (newDo.SelectedChefDO) newDo.ChefDO = newDo.SelectedChefDO.ChefDO;
      if (newDo.SelectedCivility) newDo.CivilityID = newDo.SelectedCivility.CivilityID;
      if (newDo.SelectedLanguage) newDo.LanguageID = newDo.SelectedLanguage.LanguageID;

      if (newDo.MobilePrefixSelected) newDo.MobilePrefix = newDo.MobilePrefixSelected.PhoneID;
      if (newDo.MobilePrefixSelected) newDo.MobileCodeIso = newDo.MobilePrefixSelected.CodeISO2;

      if (newDo.PhonePrefixSelected) newDo.PhonePrefix = newDo.PhonePrefixSelected.PhoneID;
      if (newDo.PhonePrefixSelected) newDo.PhoneCodeIso = newDo.PhonePrefixSelected.CodeISO2;

      // newDo.PhoneNumber = newDo.Phone;
      // newDo.MobileNumber = newDo.Mobile;


      this.app.sharedTools.deleteParamsInObject(newDo, ['SelectedCivility', 'SelectedLanguage', 'MobilePrefixSelected', 'PhonePrefixSelected'])
      console.log('[newDo]', newDo);

      this.createOrUpdateVmContact(newDo, this.componentID, sendEmail); // ComponentID = 1
    }
  }

  onNewContact(): void {
    console.log("Nouveau contact...");

    this.isCompositionFormEditMode = false;
    this.initializeCompositionForm(null);

    this.showCompositionForm = true;
  }

  onToggleOverlay(event: any, op: any, targetEl: any) {
    if (!this.isCompositionFormEditMode) {
      op.toggle(event, targetEl)
    }
  }

  onEditContact(contact: any) {
    console.log("🚀  editContact clicked ");
    console.log("🚀  editContact : ", contact);
    this.showCompositionForm = true;
    this.isCompositionFormEditMode = true;

    this.emailContact = contact?.Email;
    this.userID = contact?.User?.UserID;

    this.initializeCompositionForm(contact);

  }

  onSelectedContactChange(event: any): void {
    let contact = event.value;
    this.attachContact(contact, this.componentID, false);
    this.selectedContact = null;
  }

  onFocusContact(contact: any): void {
    this.focusedContact = cloneDeep(contact);
    console.log('focusedContact ', this.focusedContact);
  }

  onAttachNewContact(event: any, selectODHostDropdown: Dropdown): void {
    let contact = event?.value;
    if (contact) {
      this.attachContact(contact, this.componentID, true);
      selectODHostDropdown.clear(event);
    }
  }
  //#endregion

  //#region private methods  
  getPhones() {
    this.app.apiService.getPhones().subscribe((data: any) => {
      console.log("getPhones ==> ", data);
      this.mobiles = cloneDeep(data);
      this.phones = cloneDeep(data);
    });
  }

  getLanguages() {
    this.app.apiService.getLanguages().subscribe((data: any) => {
      console.log("getLanguages ==>", data);
      this.langues = cloneDeep(data);
    });
  }

  getCivilities() {
    this.app.apiService.getCivilities().subscribe((data: any) => {
      console.log("getCivilities ==> ", data);
      this.civilities = cloneDeep(data);
    });
  }

  //contacts

  private async getContactSource() {

    try {
      let reqest: any = {
        FormeID: this.FormeID,
        SolutionID: this.solutionID,
        ComponentID: this.componentID
      }
      console.log("reqest",reqest);
      const resp = await this.app.apiService.getInnovContactSource(reqest);
      console.log("🚀  availableContactsList resp:", resp);
      if (resp && Array.isArray(resp)) {

      this.availableContactsList = cloneDeep(resp);

      console.log("🚀  this.availableContactsList", this.availableContactsList);

      this.getSelectedContactsList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  private async getSelectedContactsList() {
    let reqest: any = {
      FormeID: this.FormeID,
      SolutionID: this.solutionID,
      ComponentID: this.componentID
    }

    const resp = await this.app.apiService.getInnovContactSelected(reqest);

    if (resp && Array.isArray(resp)) {

      this.selectedContacts = cloneDeep(resp);
      console.log('selectedContacts ', this.selectedContacts);

      this.selectedContacts.forEach((c: any) => {
        c.Language = this.langues.find((l: any) => l.LanguageID === c.LanguageID);
        c.Civility = this.civilities.find((civ: any) => { civ.CivilityID === c.CivilityID });
        this.availableContactsMap[c.ContactID] = [c, ...this.availableContactsList];
      });

      console.log("🚀  this.selectedContactsMap:", this.selectedContactsMap)
    }

    if (this.isDisplayOnly) {
      if (this.selectedContacts && this.selectedContacts.length > 0) {
        this.onEditContact(this.selectedContacts[0]);
      }
    }

    await this.isContactValid(this.selectedContacts);

    //ici
        this.isContactAttached = this.selectedContacts && Array.isArray(this.selectedContacts) && this.selectedContacts.length > 0;
        this.isContactSelectd.emit(this.isContactAttached);
  }

  async isContactValid(contactList: any) {
    if (contactList && Array.isArray(contactList)) {
      contactList.forEach(async (contact: any) => {
        contact.isValid = await this.reCheckValidity(contact);
        // console.log(">>>  contact.isValid:", contact.isValid)
      });
    }
  }

  private async createOrUpdateVmContact(formObj: any, componentID: number, sendEmail: boolean = false): Promise<any> {
    console.log("CREATE VM Contact ===>", formObj);
    try {
      formObj.FormeID = this.FormeID;
      formObj.SolutionID = this.solutionID;
      formObj.ComponentID = this.componentID;

      if (!this.isCompositionFormEditMode) {
        const response = await this.app.apiService.createVmContact(formObj);
        console.log('RESPONSE CREATE VM Contact ===>', response);

        this.userID = response?.User?.UserID;
        this.emailContact = response?.Email;

        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }

        if (sendEmail) {
          await this.sendEmailForContact();
        } else {

          setTimeout(() => {
            this.isSubmitted = true
            this.app.confirm.confirmDialog(""
              , this.app.translate.instant('racine.successMsg.successContactCree')
              , 'OK'
              , "alert"
              , () => { }
              , () => { });
          }, 200);
        }
      }
      else {
        console.log("🚀  formObj:", formObj);
        const response = await this.app.apiService.updateVmContact(formObj);
        console.log('RESPONSE UPDATE Contact ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }

        if (sendEmail) {
          await this.sendEmailForContact();
        }
        else {
          setTimeout(() => {
            this.app.confirm.confirmDialog(""
              , this.app.translate.instant('racine.successMsg.successContactUpdate')
              , 'OK'
              , "alert"
              , () => { }
              , () => { });
          }, 200);
        }
      }

      this.clearCompositionForm();
      this.refreshComposition();
    } catch (error) {
      console.error(error);
    }
  }

  private async attachContact(contact: any, compID: number, newAttach: boolean = true): Promise<any> {
    try {
      if (contact) {
        let request: any = {
          ComponentID: compID,
          FormeID: this.FormeID,
          SolutionID: this.solutionID,
          ContactID: contact.ContactID
        }

        if (!newAttach) {
          request.LinkAssocationID = this.focusedContact?.LinkAssocationID
        }

        console.log('attach Contact ===>', request);
        const response = await this.app.apiService.contactSelect(request);
        console.log('RESPONSE attach Contact ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog(""
            , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
            , 'KO'
            , "alert", () => { }
            , () => { });
          return;
        }
        this.isSubmitted = true
        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.successMsg.successODContactAttach')
          , 'OK'
          , "alert"
          , () => {
          }
          , () => {
          });

        setTimeout(() => {
          this.refreshComposition();
        }, 500);

      }

    } catch (error) {
      console.error(error);
    }
  }

  private async detachContact(contact: any, componentID: any): Promise<any> {
    try {
      if (contact) {
        let request: any = {
          ComponentID: componentID,
          FormeID: this.FormeID,
          SolutionID: this.solutionID,
          ContactID: contact.ContactID,
          LinkAssocationID: contact.LinkAssocationID
        }

        console.log("detach Contact ===>", contact);

        const response = await this.app.apiService.contactUnSelect(request);
        console.log('RESPONSE detach Contact ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog(""
            , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
            , 'KO'
            , "alert", () => { }
            , () => { });
          return;
        }
        this.isSubmitted = true
        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.successMsg.successODContactDetach')
          , 'OK'
          , "alert"
          , () => { }
          , () => { });

        this.refreshComposition();
      }

    } catch (error) {
      console.error(error);
    }
  }

  private refreshComposition(): void {
    this.getContactSource(); // ComponentID = 1
  }

  private initializeCompositionForm(contact: any): void {
    let SelectedCivility = this.civilities.find((c: any) => c.CivilityID === contact?.CivilityID);
    let SelectedLanguage = this.langues.find((l: any) => l.LanguageID === contact?.LanguageID);
    let MobilePrefixSelected = (contact?.MobileCodeIso) ? this.app.sharedTools.getObjByLabel(contact?.MobileCodeIso, "CodeISO2", this.mobiles) : null;
    let PhonePrefixSelected = (contact?.PhoneCodeIso) ? this.app.sharedTools.getObjByLabel(contact?.PhoneCodeIso, "CodeISO2", this.phones) : null;

    this.isContactFormSubmitted = false;

    if (this.isCompositionFormEditMode) {
      this.compositionForm = new FormGroup({

        CivilityID: new FormControl(contact?.CivilityID ?? ''),
        SelectedCivility: new FormControl(SelectedCivility, Validators.required),

        ContactID: new FormControl(contact?.ContactID ?? null, Validators.required),

        FirstName: new FormControl(contact?.FirstName, Validators.required),
        LastName: new FormControl(contact?.LastName, Validators.required),
        OtherFunction: new FormControl(contact?.OtherFunction),
        Email: new FormControl(contact?.Email, [Validators.required, Validators.pattern(EmailRegex)]),

        MobilePrefixSelected: new FormControl(MobilePrefixSelected),
        MobileNumber: new FormControl(contact?.MobileNumber ?? ''),

        // PhonePrefixSelected: new FormControl(PhonePrefixSelected),
        // PhoneNumber: new FormControl(contact?.PhoneNumber ?? ''),

        LanguageID: new FormControl(contact?.LanguageID ?? ''),
        SelectedLanguage: new FormControl(SelectedLanguage, Validators.required),
      });
    }
    else {
      this.compositionForm = new FormGroup({

        CivilityID: new FormControl(''),
        SelectedCivility: new FormControl(null, Validators.required),

        FirstName: new FormControl('', Validators.required),
        LastName: new FormControl('', Validators.required),
        OtherFunction: new FormControl(''),
        Email: new FormControl('', [Validators.required, Validators.pattern(EmailRegex)]),

        MobilePrefixSelected: new FormControl(null),
        MobileNumber: new FormControl(''),

        // PhonePrefixSelected: new FormControl(null),
        // PhoneNumber: new FormControl(''),

        LanguageID: new FormControl(''),
        SelectedLanguage: new FormControl(null, Validators.required),

      });
    }

    this.initCompositionForm = true;
    setTimeout(() => {
      if (this.isDisplayOnly) {
        this.compositionForm.disable();
      }
    }, 500);
  }

  private clearCompositionForm(): void {
    this.isCompositionFormEditMode = false;
    this.showCompositionForm = false;
    this.initCompositionForm = false;
    this.initializeCompositionForm(null);
  }


  private getFormInnovData(): void {
    let innovform: any = JSON.parse(sessionStorage.getItem('innov-form') ?? '{}');

    if (innovform) {
      this.FormeID = innovform.InnoFormeID;
      //this.folder = f18form.FolderID;
    }
  }
  //#endregion

  private async reCheckValidity(contact: any): Promise<boolean> {
    let isValid: boolean;
    let selectedCivility = this.civilities.find((c: any) => c.CivilityID === contact?.CivilityID);
    let selectedLanguage = this.langues.find((l: any) => l.LanguageID === contact?.LanguageID);
    // let mobilePrefixSelected = (contact?.MobileCodeIso) ? this.app.sharedTools.getObjByLabel(contact?.MobileCodeIso, "CodeISO2", this.mobiles) : null;
    // console.log('MobilePrefixSelected ', mobilePrefixSelected);


    let newContactForm = new FormGroup({
      SelectedCivility: new FormControl(selectedCivility, Validators.required),
      ContactID: new FormControl(contact?.ContactID ?? null, Validators.required),
      FirstName: new FormControl(contact?.FirstName, Validators.required),
      LastName: new FormControl(contact?.LastName, Validators.required),
      Email: new FormControl(contact?.Email, [Validators.required, Validators.pattern(EmailRegex)]),
      // MobilePrefixSelected: new FormControl(mobilePrefixSelected, Validators.required),
      // MobileNumber: new FormControl(contact?.MobileNumber ?? '', Validators.required),
      SelectedLanguage: new FormControl(selectedLanguage, Validators.required),
    });

    newContactForm.get('SelectedCivility')?.setValidators(Validators.required);
    newContactForm.get('FirstName')?.setValidators(Validators.required);
    newContactForm.get('LastName')?.setValidators(Validators.required);
    newContactForm.get('Email')?.setValidators([Validators.required, Validators.pattern(EmailRegex)]);
    // newContactForm.get('MobilePrefixSelected')?.setValidators(Validators.required);
    // newContactForm.get('MobileNumber')?.setValidators(Validators.required);
    newContactForm.get('SelectedLanguage')?.setValidators(Validators.required);

    //let isMobileNumberValid : boolean = await this.app.formatTelHelper.phoneFormatValidator(newContactForm, 'MobileNumber');

    newContactForm.get('SelectedCivility')?.updateValueAndValidity();
    newContactForm.get('FirstName')?.updateValueAndValidity();
    newContactForm.get('LastName')?.updateValueAndValidity();
    newContactForm.get('Email')?.updateValueAndValidity();
    // newContactForm.get('MobilePrefixSelected')?.updateValueAndValidity();
    // newContactForm.get('MobileNumber')?.updateValueAndValidity();
    newContactForm.get('SelectedLanguage')?.updateValueAndValidity();

    newContactForm.updateValueAndValidity();

    // console.log("MobileNumber : ", newContactForm.get('MobileNumber'));
    console.log("newContactForm : ", newContactForm);

    // if (newContactForm.valid && isMobileNumberValid) {
    if (newContactForm.valid) {
      isValid = true;
    } else {
      isValid = false;
    }

    console.log(">>> isContact insideFunction : ", isValid);

    return await isValid;
  }

  async onSubmitContactForm(sendEmail: boolean) {
    this.isContactFormSubmitted = true;
    if (this.compositionForm.invalid) {
      console.log("<>>> Contact is invalid !");
      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.compositionForm.controls;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log('Invalid form values:', invalidValues);

      if (invalidField && invalidField.length > 1) {
        console.log("invalidField", invalidField)

        setTimeout(() => {
          let el = invalidField[1].offsetTop - 100;
          if (el) window.scroll({ top: el, behavior: "smooth" });
        }, 10);

        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
        }, () => { });

      }
    } else {
      const ctcObj = {
        Email: this.compositionForm.get('Email')?.value,
        ContactID: this.compositionForm.get('ContactID')?.value
      }

      await this.emailCheck(ctcObj);

      if (this.isEmailUnique == false) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_CONTACTALREADYEXIST'), 'KO', 'alert', () => {
        }, () => { });
      } else {
        this.confirmSendEmail(sendEmail);
      }
    }
  }

  async sendEmailForContact() {
    let sendInvitationMailRequest: SendInvitationMailRequest = {
      FormID: this.FormeID,
      UserID: this.userID,
      Email: this.emailContact,
      ComponentID: this.componentID,
      SolutionID: this.solutionID
    }
    let response = await this.app.apiService.sendEmailForContact(sendInvitationMailRequest);

    if (response && response.Result) {
      this.app.confirm.confirmDialog(this.app.translate.instant('')
        , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
        , 'KO'
        , "alert", () => { }
        , () => { })
      return;
    }
    let msg: string = this.isCompositionFormEditMode ? 'racine.successMsg.successContactUpdateAndSendMail' : 'racine.successMsg.successContactCreeAndSendMail';

    setTimeout(() => {
      this.app.confirm.confirmDialog(""
        , this.app.translate.instant(msg)
        , 'OK'
        , "alert"
        , () => { }
        , () => { });
    }, 200);

  }

  async emailCheck(ctcObj: any) {
    const resp = await this.app.apiService.emailCheck(ctcObj);

    if (resp) {
      console.log("<<<🚀  resp:", resp);
      this.isEmailUnique = resp.IsEmailUnique;
    }
  }

  confirmSendEmail(sendEmail: any) {
    if (this.enableInvitationEmail && (this.isCompositionFormEditMode == false || sendEmail)) {
      this.app.confirm.confirmDialog("",
        this.app.translate.instant('racine.confirmMsg.confirmSendContact')
        , 'OK'
        , "confirm"
        , () => {
          //true to save and send email
          this.onCompositionFormSubmit(true);
        }
        , () => { });

    }
    else {
      //false to save only
      this.onCompositionFormSubmit();
    }
  }
}
