import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { FormGroup, Validators, FormBuilder, FormArray } from "@angular/forms";
import { ProductInnov } from '../models/product-innov';
import { WebsiteRegex } from '../consts/utils';
import { cloneDeep } from 'lodash';
import { FormCode } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-form-produit-generalites',
    templateUrl: './form-produit-generalites.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./form-produit-generalites.component.less']
})
export class FormProduitGeneralitesComponent {
    user: any = null;
    newProductForm!: FormGroup;
    submitted: boolean = false;
    product: ProductInnov = new ProductInnov();
    initForm: boolean = false;
    isEditMode: boolean = false;
    productId: any;
    formInnovID!: number;
    steps: any;
    paths = location.pathname.split('/');
    currentPath: any = null;
    showFixPublicationDate: boolean = false
    editionOpeningDate: Date = new Date()
    innoForm: any 
    isPublicationDelayed : boolean = false 
    frmData: any

    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private formBuilder: FormBuilder
    ) {
    }

   async  ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log("this.user ==> ", this.user);
        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }
        this.app.route.queryParams
            .subscribe(params => {
                console.log('[params]', params);
                if (params && params['productid']) {
                    this.productId = params['productid'];
                }

                if(params && params['innovid']){
                    sessionStorage.setItem('innov-form', JSON.stringify({InnoFormID: params['innovid']}));
                }
                if(params && params['folderguid']){
                    sessionStorage.setItem('folderGuid', JSON.stringify(params['folderguid']));                
                }
            });


            
       await  this.getFormInnovData();
       await this.getFrmInfo()
        // this.app.sharedTools.changeUsrLangInInit();

        // this.app.sharedTools.getCurrentLanguage();

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG ==> ", resG);

                //
                if (this.productId) {
                    this.isEditMode = true;
                    this.getProduct(this.productId);
                    console.log("this.productId ==> ", this.productId);
                }
                else {
                    this.isEditMode = false;
                    this.addNewProductForm();

                }
            })
    }

    onIsMultiWriterChange(event: any): void {
        this.onSubmit(false);

        // if(event.checked && !this.isEditMode)
        console.log("onIsMultiWriterChange", event);
    }

    onFixDatePublicationChange() {
        let value = this.newProductForm.get('IsPublicationDifferentDate')?.value
        this.showFixPublicationDate = value
    }

    async getFrmInfo() {
        let request = { frmType: "" }
        request.frmType = FormCode.FRM_INNOV
        let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
        if (data != undefined && data.Id != undefined) {
            this.frmData = data
        }
    }



    addNewProductForm() {
        console.log("addNewProductForm !!!")
        const notRequiredValidators: any = this.product.getListNotRequiredsInStep1();

        const formModel: any = {};

        console.log("addNewProductForm product ==> ", this.product)

        let prod: any = cloneDeep(this.product);
        for (const propertyName in this.product) {
            if (this.product.hasOwnProperty(propertyName)
            && propertyName != 'SolNameEN'
            && propertyName != 'SolNameFR') {
                const propertyValue = prod[propertyName];
                let validators: any = [];

                if (notRequiredValidators.indexOf(propertyName) == -1) validators.push(Validators.required);
                if (propertyName == 'SolUrl') validators.push(Validators.pattern(WebsiteRegex));
                formModel[propertyName] = [propertyValue, validators];
                console.log(formModel[propertyName], propertyName);
            }
        }
        
        formModel['SolNameEN'] = [prod.SolNameEN, [Validators.required, Validators.maxLength(60)]];
        formModel['SolNameFR'] = [prod.SolNameFR, [Validators.required, Validators.maxLength(60)]];
        
        this.newProductForm = this.formBuilder.group(formModel);
        console.log(formModel)
        console.log("this.newProductForm ==> ", this.newProductForm);

        this.initForm = true;

        if (this.product.PublicationWishDate != undefined && this.product.innoIsPublicationDelayed) {
            this.newProductForm.get("IsPublicationDifferentDate")?.setValue(true)
            this.showFixPublicationDate = true
        }


    }



    async createProduct(productObj: any, andRedirect: boolean): Promise<any> {
        try {

            if (!this.isEditMode) {

                console.log("CREATE Produit ===>", productObj);
                productObj.InnovationID = this.formInnovID;
                console.log("CREATE Produit after attach innov ===>", productObj);
                const response = await this.app.apiService.createProduct(productObj);
                console.log('RESPONSE CREATE Product ===>', response);

                if (response && response.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                    }, () => {
                    })
                    return;
                }

                this.productId = response.SolID;
                this.newProductForm.get('SolID')?.setValue(this.productId);
                this.newProductForm.get('SolID')?.updateValueAndValidity();
                this.isEditMode = true;

                if (andRedirect) {
                    this.onNextStep();
                }
            }
            else {

                console.log("UPDATE Produit ===>", productObj);

                const response = await this.app.apiService.updateProduct(productObj);
                console.log('RESPONSE UPDATE Product ===>', response);

                if (response && response.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                    }, () => {
                    })
                    return;
                }
                if (andRedirect) {
                    this.onNextStep();
                }
            }

        } catch (error) {
            console.error(error);
        }
    }
    onSolUrlInput() {
        const solNameFRElement = this.newProductForm.get('SolNameFR');
        const solNameENElement = this.newProductForm.get('SolNameEN');

        if (solNameFRElement && solNameFRElement.value === '' && solNameENElement) {
            solNameFRElement.setValue(solNameENElement.value);
        }
    }

    onSubmit(andRedirect: boolean = true) {
        this.submitted = true;
        this.messageService.clear();

        if (this.showFixPublicationDate) {
            let publicationWishDate = this.newProductForm.get("publicationWishDate")?.value
            if (publicationWishDate != undefined && (publicationWishDate.getTime() > this.editionOpeningDate.getTime())) {
                return;
            }
        }

        // Logic to handle form submission
        if (this.newProductForm.invalid) {
            console.log("Is invalid !!!!");

            const invalidValues: any = {};
            const controls = this.newProductForm.controls;
            let invalidFields: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('ng-invalid') as HTMLCollectionOf<HTMLElement>;

            for (const controlName in controls) {
                if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                    invalidValues[controlName] = controls[controlName].value;
                }
            }

            console.log('Invalid form values:', invalidValues);
            console.log('this.newProductForm:', this.newProductForm);

            if (invalidFields && invalidFields.length > 1) this.app.formInvalid.addSubmittedInvalidClass(invalidFields);

            this.messageService.add({ key: 'custom', severity: 'error', summary: this.app.translate.instant('racine.errorsOther.errorForm'), detail: '', sticky: true });
        }
        else {
            let product: any = this.newProductForm.value;

            //Map nested properties according to api request input
            this.product.mapFormStep1Form(product);

            if (product.innoIsPublicationDelayed && this.newProductForm.get('IsPublicationDifferentDate')?.value == true
                && this.newProductForm.get("PublicationWishDate")?.value != undefined) {
                this.product.PublicationWishDate = this.newProductForm.get("PublicationWishDate")?.value
                    
            }

            if (!this.showFixPublicationDate) {
                this.product.PublicationWishDate = undefined
            }

            

            // ==> Ici on save le step1 du product
            console.log(this.product)
            this.createProduct(this.product, andRedirect);
        }
    }



    private async getFormInnovData() {
        let innovform: any = JSON.parse(sessionStorage.getItem('innov-form') ?? '{}');

        if (innovform) {
            this.formInnovID = innovform.InnoFormID;
            await this.getInnoForm();
            //this.folder = f18form.FolderID;
        }

    }

    async getProduct(solId: any) {
        let response = await this.app.apiService.getProductById(solId);
        this.product.mapFormGetResponse(response);

        console.log('this.product : ', this.product) 

        this.editionOpeningDate = new Date(new Date(this.product?.editionOpeningDate).setDate(
            this.product?.editionOpeningDate?.getDate() - 1))

        this.addNewProductForm();

    }

    onPreviousStep(): void {
        this.app.sharedTools.goTo('/products-innov')
    }

    onCancelStep(): void {
        this.app.sharedTools.goTo('/products-innov')
    }

    // onNextStep(): void {
    //     //extract mode from route (edit or consult)
    //     const url = this.app.router.url;
    //     console.log('this.app.router.url ', this.app.router.url);
    //     if(url === '/product-generalites'){
    //         let queryparams: any = {
    //             productid: this.productId
    //         }

    //         this.app.sharedTools.redirectToWithParameters(`product-description/edit` , queryparams);
    //     }
    //     else{
    //         const segments = url.split('/');
    //         const segmentWithQueryParams = segments[segments.length - 1];
    //         const mode = segmentWithQueryParams.split('?')[0];

    //         console.log('mode ', mode); // This should log 'consult' or 'edit'

    //         if(mode === FicheOpenMode.Consult || mode === FicheOpenMode.Edit){
    //             let queryparams: any = {
    //                 productid: this.productId
    //             }

    //             this.app.sharedTools.redirectToWithParameters(`product-description/${mode}` , queryparams);
    //         }
    //     }

    // }

    onNextStep(): void {
        let queryparams: any = {
            productid: this.productId
        }

        this.app.sharedTools.redirectToWithParameters(`product-description/edit`, queryparams);
    }

    private initOnChangeHandlers(): void {
        let isMultiWriterControl = this.newProductForm.get('IsMultiWriter');
        if (isMultiWriterControl) {
            isMultiWriterControl.valueChanges.subscribe((value: any) => {
                if (this.isEditMode != true && value == true) {
                    //TODO @hassen
                }
            });
        }
    }

    async getInnoForm() {
        this.innoForm = await this.app.apiService.getAllProducts({ InoFormID: this.formInnovID });
        this.isPublicationDelayed = this.innoForm.IsPublicationDelayed

        
    }
}
