import { Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import VisioWebEssential from "../../../assets/VisioMap/map.essential.js";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from 'src/app/app.component';
import * as _ from "lodash";
import { DataView } from 'primeng/dataview';
import { FOExternalPagesRoutes } from 'src/app/consts/fo-external-pages.routes';

@Component({
  selector: 'app-conf-map',
  templateUrl: './conf-map.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './conf-map.component.less',
    './conf-map-responsive.component.less'
  ]
})
export class ConfMapComponent implements OnInit, OnDestroy {
  @ViewChild('dvExhibitors') dvExhibitors?: DataView;
  private _sidebarSearchEngineVisible = false;
  public get sidebarSearchEngineVisible(): boolean {
    return this._sidebarSearchEngineVisible;
  }
  public set sidebarSearchEngineVisible(value: boolean) {
    this._sidebarSearchEngineVisible = value;
  }
  filterExhiKeyword: string = '';

  essential: any;
  arrIdsSelected: any = [];
  isModalOpen: boolean = false;
  locations: any[] = [];
  zoomCamera: number = 200;

  conference!: any;
  showFilterMobile: boolean = false;

  tabIndex = 0;
  activeTab = 0;

  screenWidth: number = 0;
  isDesktop: boolean = false;
  isMobile: boolean = false;

  isFromExh: boolean = false;
  eventPlaces: any[] = [];
  eventPlaceMapPoints: string[] = [];


  isPopup: boolean = true;
  routeFromElement: string | undefined;
  routeToElement: string | undefined;
  exhibitors: any = [];
  conferences: any = [];
  arrFilterStand: any[] = [];
  hall = "";
  placeTitle: any;
  placeIcon: any;
  isListDropDown: boolean = false;
  enableClosePopup: boolean = false;
  elementsNext = 5;
  elementFirst = 0;
  from: any;
  searchInputPopup: string = '';
  searchResults: any[] = [];
  private readonly _exhibitorsKey: string = 'exhibitors';
  private coloredPlacesIDs: any[] = [];  

  constructor(
    private activatedRoute: ActivatedRoute,
    public app: AppComponent,
    private renderer: Renderer2,
    private _router: Router
  ) {
    this.screenWidth = window.innerWidth;
    this.renderer.setStyle(document.body, 'background-color', '#144c96');
  }

  ngOnInit(): void {
    this.onResize();
    this.retrieveExhibitors();
    this.getEventsPlaces();
  }

  ngOnDestroy() {
    this.renderer.removeStyle(document.body, 'background-color');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 995) {
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }

    setTimeout(() => {
      this.initMap();
    }, 100);

  }

  initMap() {
    let mapSettings: any = this.app.sharedTools.getMapSettings();
    console.log('mapSettings : ', mapSettings);

    this.essential = new VisioWebEssential({
      element: document.getElementById("container"),
      imagePath: mapSettings.imagePath,
    });
    
    this.essential.setParameters(mapSettings.bundleParams);

    this.essential.setParameters({
      parameters: { locale: { language: navigator.language } },
    });

    this.essential
      .createMapviewer()
      .then(() => {
        // this.essential._mapviewer.camera.minRadius = this.zoomCamera;
        //this.startCameraAnimation();

        setTimeout(() => {
          this.essential._mapviewer.getPOI('DynamicDemo')[0].options('startAnimation', 'Take 001');
        }, 2000);

        this.mapParams();
        this.setupPlaceBubble();

      })
      .catch((e: any) => {
        // eslint-disable-line
        console.log(e);
        console.log(
          "Could not load map. Check your config or get in touch with Visioglobe team."
        ); // eslint-disable-line
      });
  }

  //animation camera
  startCameraAnimation() {
    this.initialCameraViewpoint();
  }

  initialCameraViewpoint() {
    let lPoints = this.essential.venue.getFootprintPoints({ id: "B5A" });
    let lViewPoint = this.essential.venue.getViewpoint({ points: lPoints });
    lViewPoint.animationDuration = 0;
    this.essential.venue.goToViewpoint(lViewPoint)
      .then(() => {
        this.activatedRoute.queryParams.subscribe((params) => {
          console.log("ROUTE----", params)
          console.log("ESSENTIAL----", this.essential.venue)
          this.essential.venue.goToPlace({ id: "21918" });

        })
        this.firstCameraAnimation();
      })
  }

  firstCameraAnimation() {
    // Peut remplacer l'identifiant "B5A" par l'identifiant d'une autre footprint de votre choix
    let lPoints = this.essential.venue.getFootprintPoints({ id: "B5A" });
    let lViewPoint = this.essential.venue.getViewpoint({ points: lPoints });
    lViewPoint.animationDuration = 0.5;

    this.essential.venue.goToViewpoint(lViewPoint)
      .then(() => {
      })
  }

  mapParams() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      console.log("params:", params);
      if (params) {
        if (params.myArrayMatPoints) {
          let arrStand = JSON.parse(params.myArrayMatPoints);
          if (arrStand.length == 1) {
            console.log("UNE LISTE");

            console.log("IS DEFAULT======================");
            if (this.arrIdsSelected.length > 0) {
              this.arrIdsSelected.forEach((idSelected: any) => {
                this.essential.content.resetPlaceColor({ id: idSelected });
              });
              this.arrIdsSelected = [];
            }

            if (arrStand[0].MapPoint) {
              setTimeout(() => {
                this.arrIdsSelected.push(arrStand[0].MapPoint);
                console.log("arrStand[0].MapPoint-----", arrStand[0].MapPoint);
                this.essential.venue.goToPlace({ id: arrStand[0].MapPoint });
                this.essential.content.setPlaceColor({
                  id: arrStand[0].MapPoint,
                  color: "red",
                });
                this.coloredPlacesIDs.push(arrStand[0].MapPoint);

                var pois = this.essential._mapviewer.getPOIs(
                  arrStand[0].MapPoint
                );
                console.log("POIS------", pois);
                var position;
                var floor;
                if (
                  pois[arrStand[0].MapPoint] &&
                  pois[arrStand[0].MapPoint].length > 0
                ) {
                  var options = pois[arrStand[0].MapPoint][0].options();
                  position = options.position;
                  console.log("position------>", position);
                  this.essential._mapviewer.addPOI({
                    url: "/assets/images/black-marker.png",
                    //  text: 'The object "'+element.vg.id+'" has been clicked',
                    position: position,
                    alignment: {
                      x: 0,
                      y: 1,
                    },
                    id: "pictoLoc",
                    // onObjectMouseUp: function() {alert('click');},
                    scale: 6,
                  });
                }
              }, 1);
            } else {
              console.log("No map point data");
            }
            return;
          }

          this.isModalOpen = true; //ouverture de la modal
          console.log("MODAL---", this.isModalOpen);
          //let arrStand = JSON.parse(params.myArrayMatPoints);
          let batimants = [];
          let lang = localStorage.getItem("langue");
          console.log("LANGUE*********************----------", lang);
          for (var i = 0; i < arrStand.length; i++) {
            if (lang == "en")
              if (arrStand[i].Hall.includes("EXT"))
                arrStand[i].Hall = "OUTDOOR";
            if (lang == "fr")
              if (arrStand[i].Hall.includes("EXT"))
                arrStand[i].Hall = "EXTERIEUR";
          }

          console.log(arrStand);

          // let tab = _.groupBy(arrStand, "Hall");

          this.locations = [];
          // Object.entries(tab).forEach((entry) => {
          //   const [key, value] = entry;
          //   console.log(key, value);
          //   this.locations.push({ group: key, list: value });
          // });

          // this.locations = _.orderBy(this.locations, ["group"], ["asc"]);
          console.log("LOCATIONS----", this.locations);
        } else {
          setTimeout(() => {
            if (params.mapPoint) {
              let pointMap = params.mapPoint.replaceAll(" ", "");
              console.log("***MapPoint Splité***", pointMap);
              this.essential._mapviewer.camera.minRadius = this.zoomCamera;
              this.essential.venue.goToPlace({ id: pointMap });
              this.essential.content.setPlaceColor({
                id: pointMap,
                color: "red",
              });
                this.coloredPlacesIDs.push(pointMap);

              var pois = this.essential._mapviewer.getPOIs(pointMap);
              console.log("POIS------", pois);
              var position;
              var floor;
              if (pois[pointMap] && pois[pointMap].length > 0) {
                var options = pois[pointMap][0].options();
                floor = options.floor;
                position = options.position;

                console.log("position------>", position);

                this.essential._mapviewer.addPOI({
                  url: "/assets/images/black-marker.png",
                  //  text: 'The object "'+element.vg.id+'" has been clicked',
                  position: position,
                  alignment: {
                    x: 0,
                    y: 1,
                  },
                  id: "pictoLoc",
                  // onObjectMouseUp: function() {alert('click');},
                  scale: 6,
                });
              }
            } else if (params.building) {
              if (params.building == "OUT") {
                this.essential.venue.goToGlobal();
              } else {
                this.essential.venue.goToBuilding({ id: params.building });
              }
            }
            this.resetMinRadius();
          }, 1);
        }
      }
    });
  }

  clickLink(routerLink: any) {
    this.app.sharedTools.redirectTo(routerLink);
  }

  switchHeaders(tabNumber: any) {
    this.activeTab = tabNumber.index;
  }

  checkExhibitorMenu(event: any) {
    this.isFromExh = event;
    this.isExhibitorMenuEvent();
    console.log("🚀  this.isFromExh:", this.isFromExh)
  }

  isRouterActive(route: string): boolean {
    return this._router.isActive(route, false);
  }

  isExhibitorMenuEvent() {
    if (this.isRouterActive('/mapExhi') || this.isRouterActive('/welcome-sol') || this.isRouterActive('/welcome-exhibitor')) {
      this.isFromExh = true;
    } else {
      this.isFromExh = false;
    }
  }

  //#region map utils
  /**
  * Modal active
  */

  setupPlaceBubble() {
    this.isPopup = true;
    this.routeFromElement = this.essential.parameters.locale.route.start;
    console.log("routeFromElement--", this.routeFromElement)
    this.routeToElement = this.essential.parameters.locale.route.destination;
    console.log("routeToElement--", this.routeToElement)
    this.essential.route.setFrom({ from: this.essential.content.activePlaceID });

    this.essential.content.placeBubbleEnabled = true;


    this.essential.onObjectMouseUp = ({ targetElement }: any) => {
      console.log("target----", targetElement)
      this.activatedRoute.queryParams.subscribe((param) => {
        console.log(param)
        if (param['mapPoint']) {

          ({ id: param['mapPoint'] });
          var pois = this.essential._mapviewer.getPOIs();
          if (pois['pictoLoc'] && pois['pictoLoc'].length > 0) {
            console.log(pois['pictoLoc'][0]);
            pois['pictoLoc'][0].remove();
            //console.log(this.essential._mapviewer.getPOIs());
          }
        }
      });
      this.setActivePlace(targetElement)
    };
  }

  /**
  * récupération des infos d'une place active
  * @param place
  */
  setActivePlace(place: any) {
    console.log("place----", place)
    this.essential.content.setActivePlace({ place });
    requestAnimationFrame(() => {
      if (this.essential.content.activePlace && this.essential.content.places[this.essential.content.activePlaceID]) {
        // get active place
        const placeContent = this.essential.content.places[this.essential.content.activePlaceID];

        if (placeContent) {
          // handle active place if conf room
          if (this.checkActivePlaceConfRoom(placeContent)) {
            return;
          }
          console.log("placeContent---", placeContent)
          this.essential.route.setTo({ to: placeContent.id });
          console.log(placeContent.name)

          //=====================Filter EXHI=======================//
          let arrFilterExhi = _.filter(this.exhibitors, function (item) { return item.Stands; });
          arrFilterExhi = _.filter(arrFilterExhi, (item) => {
            let results = _.filter(item.Stands, (stand) => {
              return stand.MapPoint == placeContent.id;
            })
            return results.length > 0;
          });

          let arrFilterStand: any = []
          for (let index = 0; index < arrFilterExhi.length; index++) {
            arrFilterStand.push({
              id: arrFilterExhi[index].Exhi_Id,
              label: arrFilterExhi[index].Exhi_CompanyName,
              label2: arrFilterExhi[index].Exhi_CompanyName2,
              type: 'exhi',
              Exhi_Country_CodeISO2: arrFilterExhi[index].Exhi_Country_CodeISO2,
              Exhi_IsDirect: arrFilterExhi[index].Exhi_IsDirect
            });
          }

          //====================Filter CONF=======================//
          let arrFilterConf = _.filter(this.conferences, function (item) { return item.Place.MapPoint == placeContent.id; });

          for (let index = 0; index < arrFilterConf.length; index++) {
            arrFilterStand.push({
              id: arrFilterConf[index].Event_Id,
              label: arrFilterConf[index].Event_Title,
              type: 'conf',
              Exhi_Country_CodeISO2: arrFilterExhi[index].Exhi_Country_CodeISO2,
              Exhi_IsDirect: false
            })
          }
          if (arrFilterExhi[0] !== null && arrFilterExhi[0] !== undefined) {
            this.hall = arrFilterExhi[0].Stands[0].Hall;
            console.log('arrFilterExhi', arrFilterExhi);
          }

          // Order Exhibitors : Direct first than indirect
          console.log('exhibitors on stand before order : ', arrFilterStand.map((s: any) => s.Exhi_IsDirect), arrFilterStand);
          arrFilterStand = arrFilterStand.sort(this.sortByExhi_IsDirect);
          console.log('exhibitors on stand after order : ', arrFilterStand.map((s: any) => s.Exhi_IsDirect), arrFilterStand);

          setTimeout(() => {
            this.arrFilterStand = _.cloneDeep(arrFilterStand)
          }, 100);
        }
        console.log("arrFilterStandXXX----->", this.arrFilterStand);

        this.placeTitle = `${this.hall ? this.hall + '-' : ''} ${placeContent.name || placeContent.id}`;
        console.log('hall', this.hall);
        console.log("placeTitle----->", this.placeTitle);
        console.log("placeContent--->", placeContent)
        if (placeContent.icon) {
          this.placeIcon = placeContent.icon
        }
        else if (!this.placeIcon && placeContent.categories.length > 0) {
          const category = this.essential.content.categories[placeContent.categories[0]];
          if (category.icon) {
            this.placeIcon = category.icon;
          }
        }
        if (placeContent.description) {
          //this.placeDesctription = placeContent.description;
          const placeDescription = document.getElementById('placeDescription');
          placeDescription!.innerHTML = placeContent.description;
        }
      }
      //à faire après=================
      let test = this.essential.route.hasFrom();
      console.log("test---", test);
      let testTo = this.essential.route.hasTo();
      console.log("testTo---", testTo);
    })
  }
  /**
   * fermeture de la modal
   */
  closeBubble() {
    console.log('out')
    // this.isPopup=true;
    // this.isBuble=false;
    this.arrFilterStand = [];
    this.essential.content.resetActivePlace();
    this.enableClosePopup = false;
  }

  onFocusOut() {
    this.isListDropDown = false;
  }

  nextPopUp() {
    this.isPopup = false;
  }

  nextElement() {
    console.log("nbARR---", this.arrFilterStand.length)
    if (this.elementsNext >= this.arrFilterStand.length)
      return;
    let elem = 5
    this.elementsNext += elem;
    // console.log("elemNext----", this.elementsNext);

    this.elementFirst += elem;

  }

  previousElement() {
    if (this.elementFirst == 0 && this.elementsNext == 5)
      return;
    let elem = 5
    this.elementsNext -= elem;
    //console.log("elemNext----", this.elementsNext);

    this.elementFirst -= elem;
    // console.log("elemFirst---", this.elementFirst);
  }

  //Go to Exhibitor
  goToDetail(item: any) {
    if (!item) {
      return;
    }

    let queryParams: any = {
      exhibitorID: item.id
    }

    this.app.sharedTools.routeTo(FOExternalPagesRoutes.exhiDetail, queryParams);
  }

  onFromPlaceSelected(evt: any) {
    this.from = evt;
    this.fromTo()
    console.log(this.from)
  }

  fromTo() {
    this.isPopup = false;
    console.log(this.from)
    this.essential.route.setFrom({ from: this.from.HallName });
    this.essential.content.resetActivePlace();
  }

  async searchExhibitor() {
    let search: any = {
      CurrentLanguage: this.app.translate.currentLang
    };

    console.log("search exhibitors response ==> ", new Date());
    let response = await this.app.apiService.searchExhibitors(search);
    console.log("search exhibitors response ==> ", new Date(), response);

    if (response && response.ListDetailsExhibitors && Array.isArray(response.ListDetailsExhibitors)) {
      this.exhibitors = response.ListDetailsExhibitors;
      sessionStorage.setItem(this._exhibitorsKey, JSON.stringify(this.exhibitors));
    }
  }

  private async retrieveExhibitors() {
    let findExhibitors = sessionStorage.getItem(this._exhibitorsKey);
    if (!findExhibitors) {
      return await this.searchExhibitor();
    }

    let parseExhibitors = JSON.parse(findExhibitors);

    if (!(parseExhibitors && Array.isArray(parseExhibitors) && parseExhibitors.length > 0)) {
      return await this.searchExhibitor();
    }

    this.exhibitors = parseExhibitors;
  }
  //#endregion

  //#region identify conference place & redirect to conferences
  async getEventsPlaces() {
    let categories = await this.app.apiService.getRooms('');
    console.log(categories)
    if (categories && Array.isArray(categories)) {
      this.eventPlaces = categories;
      this.eventPlaceMapPoints = this.eventPlaces
        .filter((ep: any) => ep.MapPoint)
        .map((ep: any) => ep.MapPoint);

    }
  }

  private checkActivePlaceConfRoom(placeContent: any): boolean {
    console.log('check if conf room', placeContent.id, this.eventPlaceMapPoints);
    if (placeContent && placeContent.id && this.eventPlaceMapPoints.indexOf(placeContent.id) >= 0) {
      let bubble = document.getElementById('bubble');
      if (bubble) {
        bubble.style.display = 'none';
      }

      // let queryParams: any = {
      //   room: placeContent.id
      // };

      // this.app.sharedTools.goToWithParameters('welcome-conf', queryParams);

      sessionStorage.setItem('map-selected-room', placeContent.id);
      this.app.sharedTools.routeTo(FOExternalPagesRoutes.confList);

      return true;
    }
    return false;
  }
  //#endregion

  goBack() {
    this.app.location.back();
  }

  private sortByExhi_IsDirect(a: any, b: any): number {
    if (a.Exhi_IsDirect && !b.Exhi_IsDirect) {
      return -1; // a should come before b
    } else if (!a.Exhi_IsDirect && b.Exhi_IsDirect) {
      return 1; // b should come before a
    } else {
      return 0; // no change in order
    }
  }

  public filterDVExhibitors(event: any): void {
    if (this.dvExhibitors) {
      this.dvExhibitors.filter(event, 'contains');
    }
  }

  public goToFirstMapPoint(item: any): void {
    console.log(item)
    let stands = item.Stands;
    if (stands && Array.isArray(stands) && stands.length > 0 && stands[0] && stands[0].MapPoint) {
      let params: any = {
        mapPoint: stands[0].MapPoint
      }

      let route: string = '';
      if (this.app.router.url.startsWith('/mapExhi')) {
        route = 'mapExhi';
      }
      else if (this.app.router.url.startsWith('/map')) {
        route = 'map';
      }
      this.app.router.navigate([route], { queryParams: params });
      this.sidebarSearchEngineVisible = false;
    }
  }

  public goToMapPoint(stand: any): void {
    this.resetPlacesColors();

    console.log(stand)
    if (stand && stand.MapPoint) {
      let params: any = {
        mapPoint: stand.MapPoint
      }

      let route: string = '';
      if (this.app.router.url.startsWith('/mapExhi')) {
        route = 'mapExhi';
      }
      else if (this.app.router.url.startsWith('/map')) {
        route = 'map';
      }
      this.app.router.navigate([route], { queryParams: params });
      this.sidebarSearchEngineVisible = false;
    }
  }

  // reset minRadius after goto(MapPoint) in order to not bloc manual zoom
  private resetMinRadius(): void {
    this.essential._mapviewer.camera.minRadius = 0;
  }

  onSearchClick(event: any): void{
    this.sidebarSearchEngineVisible = true;
  }

  resetPlacesColors(): void{
    if(this.coloredPlacesIDs && Array.isArray(this.coloredPlacesIDs) && this.coloredPlacesIDs.length > 0){
      this.coloredPlacesIDs.forEach((id: any) =>{
        console.log(id)
        this.essential.content.resetPlaceColor({
          id: id
        });
        console.log(id)
      });
    }

    this.coloredPlacesIDs = [];
  }
}
