import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { FormCode } from 'src/app/enums/form-code';
import { StatusType } from 'src/app/enums/status-type';
import { AwardsRoutes } from '../awards.routes';

@Component({
  selector: 'app-parametrage-awards',
  templateUrl: './parametrage-awards.component.html',
  styleUrls: ['./parametrage-awards.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ParametrageAwardsComponent implements OnInit {
  eventName: string = ""
  isReady: boolean = false
  status: any[] = []
  submitted: boolean = false
  data: any;
  awardFormGroup !: FormGroup
  frmType: string = "";
  content: string = "";
  showAlert: boolean = false;
  receivedDataFromChildComponent: any;
  newMaximumNumber: any;

  constructor(private _formBuilder: FormBuilder, private app: AppComponent) { }

  ngOnInit(): void {
    this.initFormGroupFields();
    this.getStatus();
    this.getAwardInfo();
  }

  getAwardInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_AWARD
    this.frmType = FormCode.FRM_AWARD
    this.app.apiService.getEditionInfo(request).subscribe(
      data => {
        this.isReady = true
        console.log(data)
        if (data != undefined && data.Id != undefined) {
          this.data = data;
          this.initFormGroupFields(data);
          this.getEventNameSwitchLang();
        }
      }
    )
  }
  getStatus() {
    this.app.apiService.getStatus().subscribe((data: any) => {
      if (this.status) {
        this.status = data.filter((s: any) => s.StatusType === StatusType.FormState);
      }
    });
  }

  initFormGroupFields(data?: any) {
    if (data != undefined) {
      this.awardFormGroup = this._formBuilder.group({
        numberFicheMax: new FormControl(data.ChangeJobModeAt, Validators.required),
        activerEmails: new FormControl(data.ActiverEmails),
      })
    } else {
      this.awardFormGroup = this._formBuilder.group({
        numberFicheMax: new FormControl(null, Validators.required),
        activerEmails: new FormControl(null),
      })
    }
  }
  getEventNameSwitchLang() {
    let currentLang = this.app.translate.currentLang
    if (this.data != undefined && this.data.Result != "ERROR") {
      if (currentLang == "fr") {
        this.eventName = this.data.LabelFR
      } else if (currentLang == "en") {
        this.eventName = this.data.LabelEN
      } else {
        this.eventName = this.data.LabelES
      }
    }
  }

  verifForm() {
    this.submitted = true;
    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
    }, () => { });
  }
  submit($event: any) {
    this.submitted = true
    this.receivedDataFromChildComponent = $event
    console.log($event)
    if (this.awardFormGroup.invalid) {
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
      }, () => { });
    } else {
      if (this.data?.Id != null && this.data?.Id != undefined) {
        this.newMaximumNumber = this.awardFormGroup.get("numberFicheMax")?.value
        let getInvalidFormRequest: {
          frm_id: string,
          frm_type: string,
          newMaximumNumber: number
        } = {
          frm_id: this.data.Id,
          frm_type: this.frmType,
          newMaximumNumber: this.newMaximumNumber
        }
        this.app.apiService.getInvalidFormMaximumSheetNumber(getInvalidFormRequest).subscribe(
          data => {
            if (data && !data.Result && data > 0) {
              let firstPart = this.app.translate.instant('bo.parametrage.attention');
              let secondPart = this.app.translate.instant('bo.parametrage.alertInvalidFormMaximumSheet');
              let thirdPart = this.app.translate.instant("bo.parametrage.appliquerNombreAunouveauForms");
              let fourthPart = this.app.translate.instant("bo.parametrage.appliquerAuNouveaux");

              this.content = `${firstPart} ${data} ${secondPart} ${thirdPart} ${this.newMaximumNumber} ${fourthPart}`;
              this.showAlert = true
            } else if (data == 0) {
              this.updateParameters()
            }
          })
      }
    }
  }
  updateParameters() {
    let request = this.mapValueIntoObject()
    request = {
      ...request,
      ...this.receivedDataFromChildComponent
    }
    this.app.apiService.updateEditionFormAwardInfo(request).subscribe(
      data => {
        if (data == true) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('bo.parametrage.successModification'), 'OK', "alert", () => {
            this.getAwardInfo();
            this.submitted = false
          }, () => { })
        }
      }
    )
  }
  mapValueIntoObject() {
    return {
      ActiverEmails: this.awardFormGroup.get("activerEmails")?.value,
      ChangeJobModeAt: this.awardFormGroup.get("numberFicheMax")?.value,
      frmType: this.frmType
    }
  }
  annuler() {
    this.app.router.navigate([AwardsRoutes.listBO])
  }
  DeleteDataAward() {
    this.app.confirm.confirmDialog("", this.app.translate.instant('bo.parametrage.deleteConfirmMessage'),
      "AUTRE", "confirm", () => {
        let request = { frmType: "" }
        request.frmType = FormCode.FRM_AWARD
        this.app.apiService.deleteAttachedEditionInfo(request).subscribe(
          data => {
            this.app.confirm.confirmDialog("", this.app.translate.instant('bo.parametrage.successDeletion'), 'OK', "alert", () => {
              this.getAwardInfo()
            },
              () => { })
          }
        )
      },
      () => { })
  }
  appliquerNouveau() {
    this.showAlert = false
    this.updateParameters()
  }
  appliquerTous() {
    let getInvalidFormRequest: {
      frm_id: string,
      frm_type: string,
      newMaximumNumber: number
    } = {
      frm_id: this.data.Id,
      frm_type: this.frmType,
      newMaximumNumber: this.newMaximumNumber
    }
    this.showAlert = false
    this.app.apiService.updateMaxNumberSheetFormsAwards(getInvalidFormRequest).subscribe(
      data => {
        this.updateParameters()
      }
    )
  }
}
