import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Steps } from 'primeng/steps';
import { AppComponent } from '../app.component';
import { cloneDeep, forEach } from 'lodash';

@Component({
    selector: 'app-steps',
    templateUrl: './steps.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./steps.component.less']
})

export class StepsComponent {

    @ViewChild('stepper', { static: true }) stepper?: Steps;
    user: any;
    stepSwitchPath: any;
    folderStep: any;
    steps: any = [];
    folderId:any = null;

    //check if user redirected from BO ==> do not show steps 
    redirectedFromBO: boolean = false;

    public activeIndex = 4;

    constructor(
        public app: AppComponent,
    ) { }



    ngOnInit() {
        let pathActif = '/' + this.app.router.url.split('/').pop();

        let paths = location.pathname.split('/');

        let currentPath: string = '/' + paths[1];

        console.log('pathActif ==> ', pathActif);
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        this.folderStep = JSON.parse(sessionStorage.getItem('folderStep')!);
        console.log("this.user ==> ", this.user);

        this.checkBORedirectionByFolderID();

        let directFolderID = this.app.route.snapshot.queryParams['folderID'];
        if (directFolderID) this.folderId = directFolderID;
        else this.folderId = this.user.FolderID;

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                //console.log("resG", resG);
                this.steps = resG.steps;


                this.stepSwitchPath = this.steps.filter((stp: any) => { return stp.routerLink == currentPath })[0];
                console.log('pathActif this.stepSwitchPath  ==> ', this.stepSwitchPath);

                if (this.stepSwitchPath && this.user) {
                    sessionStorage.setItem('activeStep', this.stepSwitchPath.StepID);

                    let stepObj: any = {
                        Step: this.stepSwitchPath.StepID - 1,
                        FolderID: this.folderId
                    }

                    this.setPreviewStep(stepObj);
                }
                else {
                    sessionStorage.removeItem('activeStep');
                    this.getStep(this.folderId);
                }
            });
    }

    async getStep(_FolderID: any) {
        const response = await this.app.apiService.getFolder(_FolderID);
        console.log("[getStep] response API ==> ", response);

        if (response && response.Result) {
            this.app.confirm.confirmDialog(this.app.translate.instant(''), this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {}, () => { })
            return;
        }

        let folder: any = cloneDeep(response);
        let stepID: any;

        if (folder && folder.ExtraFieldValues && folder.ExtraFieldValues.length > 0) {
            let extraFieldStep = folder.ExtraFieldValues.filter((ef: any) => { return ef.ExtraFieldID == 1 })[0];
            if (extraFieldStep) stepID = parseInt(extraFieldStep.ExtraFieldValueEN);
            console.log("[getStep] stepID API ==> ", stepID);
            if (stepID != null) sessionStorage.setItem('folderStep', stepID);
        }
    }

    async setPreviewStep(stepObj: any) {
        console.log("[setPreviewStep] stepObj ==> ", stepObj);
        try {
            const response = await this.app.apiService.setdpstep(stepObj);
            console.log("[setPreviewStep] response API ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {}, () => { })
                return;
            }

            this.getStep(this.folderId);

        }
        catch (error) {
            console.error(error);
        }
    }

    redirectRoute(step: any) {
        this.folderStep = JSON.parse(sessionStorage.getItem('folderStep')!);

        //step.disabled = true;
        console.log("redirectRoute folderStep ==> ", this.folderStep);

        if (this.folderStep >= step.StepID) step.disabled = false;

        console.log("redirectRoute step ==> ", step.disabled);

        if (step.disabled) return;
        else this.app.sharedTools.goTo(step.routerLink);
    }

    returnClass(_step: any, _param: any) {
        this.folderStep = JSON.parse(sessionStorage.getItem('folderStep')!);
        // console.log("_step ===============>", _step)
        // console.log("_param ===============>", _param)

        if (!_param) return;
        let boolActive: boolean = (this.stepSwitchPath && this.stepSwitchPath.StepID == _step.StepID) ? true : false;
        let boolComplete: boolean = (!this.stepSwitchPath || (this.stepSwitchPath && this.stepSwitchPath.StepID > _step.StepID || (this.folderStep && this.folderStep >= _step.StepID))) ? true : false;

        return (_param == "active") ? boolActive : boolComplete;
    }

    //#region private methods
    private checkBORedirectionByFolderID(): void {
        //check if route have query params 'folderID' ===> redirected from BO
        let directFolderID = this.app.route.snapshot.queryParams['folderID'];
        if (directFolderID) {
            this.redirectedFromBO = true;
        }
    }
    //#endregion
}
