import { Component, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Menu } from 'primeng/menu';
import { Table } from 'primeng/table';
import { firstValueFrom, Subscription } from 'rxjs';
import { SearchVmFolder } from '../models/search-vm-folder';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { VMFolderSearchLocalStorageKey, VMProgDemandeSearchLocalStorageKey, hasAnyNonNullProperty } from '../consts/utils';
import { cloneDeep } from 'lodash';
import { StatusType } from '../enums/status-type';
import { FolderStatus } from '../enums/folder-status';
import { SearchHelperService } from '../services/search-helper.service';
import { RoleCode } from '../enums/role-code';
import { CategoryType } from '../enums/category-type';
import { StatusCode } from '../enums/status-code';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DemandDetailComponent } from '../demand-detail/demand-detail.component';
import { HelperService } from '../services/helper.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Languages } from '../enums/language';

@Component({
    selector: 'app-gestion-vm-prog-demandes',
    templateUrl: './gestion-vm-prog-demandes.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./gestion-vm-prog-demandes.component.less']
})
export class GestionVmProgDemandesComponent implements OnDestroy {
    //#region local properties
    //subscriptions
    private foldersExportSubscription?: Subscription;
    private listDemandeExportSubscription?: Subscription;
    private companyAutocompleteSubscription?: Subscription;
    private langSubscription?: Subscription; 

    @ViewChild('menuAction') menuAction!: Menu;
    @ViewChild('dtDemandes') dtDemandes!: Table;
    @ViewChild('dvDemandes') dvDemandes!: DataView;

    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }

    layout: any = 'grid';
    groupedActions: any;
    selectActiveInactiveOptions: any;

    showTable: boolean = true;
    rangeDates: Date[] = [];
    maxDate = new Date();
    search: any = new SearchVmFolder();
    showAdvancedSearch: boolean = false;
    fastFiltersFolder: any = ['NumExposant', 'CompanyName', 'ContactName', 'ContactEmail', 'ContactPhone', 'Surface', 'AmountHT'];

    demandes: any = [];
    countries: any = [];

    status: any = [];
    statusAll: any = [];
    statusBrouillonSupp: any = [];
    priority: any = [];
    priorities: any = [];
    priviesGroup: any = [];
    meetingStands: any = [];
    period: any = [];
    typeCategory: any = [];
    groupSectorType: any = [];

    isRequestHaveVisit: boolean = false;
    userDo: boolean = false;
    day: any;
    selectedDay: string = "";
    keyValueDays: any;
    getPeriodDays: any;
    progID: any;

    exportcolsVmProgs: any = [];
    items: any = [];
    selectedProgDemande: any = [];
    selectedAction: any;
    _selectedColumns: any = [];
    selectedEtat: any = [];
    fastSearchFolderKeyword: string = '';

    colsVmProgs: any = [];

    vmStatusEtatDemande: any;

    ExposantNameSuggestions: string[] = [];
    listDemandCustomOptions: any = {};

    allODsTypeCategories: any = [];
    userODsTypeCategories: any = [];
    vmDoList: any = [];

    userODs: any[] = [];
    allODs: any[] = [];
    edition: any;
    noteVisible: boolean = false;
    currentActivity: any;
    userId: any
    actions: any[] = []
    _selectedAction: any
    allDemandsActions: any[] = []
    //#endregion

    constructor(
        public app: AppComponent,
        private searchHelperService: SearchHelperService,
        private datePipe: DatePipe,
        public dialogService: DialogService,
        public helperService: HelperService,
        private router: Router
    ) { }

    ngOnDestroy(): void {
        this.unsubscribeActiveSubscriptions();
    }

    filterFast() {
        this.searchHelperService.setFastSearchKeyword(this.fastSearchFolderKeyword, VMProgDemandeSearchLocalStorageKey);
        console.log("_value", this.fastSearchFolderKeyword);
        //this.dvDemandes.filter(_value, 'contains');
        this.dtDemandes.filterGlobal(this.fastSearchFolderKeyword, 'contains');
    }

    async ngOnInit() {

        //get privious advanced search value before routing to other routes
        this.search = cloneDeep(this.searchHelperService.getAdvancedSearch(VMProgDemandeSearchLocalStorageKey));
        this.fastSearchFolderKeyword = this.searchHelperService.getFastSearchKeyword(VMProgDemandeSearchLocalStorageKey);
        let user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log(user);
        if (!user) {
            this.app.router.navigate(['/login']);
            return;
        }
        this.userId = user.UserID
        // this.colsVmProgs = this.app.translate.("racine.generalites.columns");
        this.app.sharedTools.getJSON('general_data').subscribe(
           async resG => {
                console.log("resG", resG);
                this.priorities = resG.vmPriority;
                this.period = resG.periods;
                this.vmStatusEtatDemande = resG.vmStatusEtatDemande;
                this.allDemandsActions = resG.actionDemandTable
                this.fillGroupedActionCombo(resG.actionDemandTable)
                //this.meetingStands = resG.vmMeetingStand;

                console.log("🚀  this.getPeriodDays:", this.getPeriodDays)
                console.log("🚀  period:", this.period);

                console.log("🚀  this.keyValueDays:", this.keyValueDays)


                this.items = resG.actionsRow;

                this.items.forEach((itemMenu: any) => {
                    itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
                });

                // this.colsVmProgs = resG.colsVmExposant;
                this.colsVmProgs = resG.colsVmProgs;
                this.listDemandCustomOptions = resG.planninDOCustomOptions;

                this._selectedColumns = this.colsVmProgs;
                this.exportcolsVmProgs = this._selectedColumns.map((col: any) => ({ title: col.header, dataKey: col.header }));
                this.getEdition();
                this.getAllHall();
                this.getCountries();
                this.getPriviGroups();
                await this.getStatus();

                this.search.isRequestHaveVisit = false;
                this.search.userDo = false;
                this.getCategories();
                this.getGroups();
                this.searchDemandes(this.search, true);
            });
        this.langSubscription = this.app.translate.onLangChange.subscribe(
            data => {
                this.fillGroupedActionCombo(this.allDemandsActions)
            }
        )
    }

    fillGroupedActionCombo(data: any) {
        this.actions = []
        if (this.app.translate.currentLang == "fr") {
            data.forEach((action: any) => {
                let actionModel = {
                    label: action.LabelFR,
                    ActionID: action.ActionID
                }
                this.actions.push(actionModel);
            })
        }
        if (this.app.translate.currentLang == "en") {
            data.forEach((action: any) => {
                let actionModel = {
                    label: action.LabelEN,
                    ActionID: action.ActionID
                }
                this.actions.push(actionModel);
            })
        }
        if (this.app.translate.currentLang == "es") {
            data.forEach((action: any) => {
                let actionModel = {
                    label: action.LabelES,
                    ActionID: action.ActionID
                }
                this.actions.push(actionModel);
            })
        }
    }

    onShowAllODChange(event: any): void {

        this.userDo = event;
        this.getCategories();
        this.getGroups();

    }

    //#region  Get
    async getCategories() {

        if (this.search.userDo === false) {
            if (!this.userODsTypeCategories || this.userODsTypeCategories.length < 1) {
                this.userODsTypeCategories = await this.app.apiService.getTypeDO(false);
            }

            this.typeCategory = this.userODsTypeCategories;
        }
        else {
            if (!this.allODsTypeCategories || this.allODsTypeCategories.length < 1) {
                this.allODsTypeCategories = await this.app.apiService.getTypeDO(true);
            }

            this.typeCategory = this.allODsTypeCategories;
        }
    }




    set selectedColumns(val: any[]) {
        // ==> restore original order
        this._selectedColumns = this.colsVmProgs.filter((col: any) => val.includes(col));
    }

    // TODO finir qaund il y aura tous les états
    getColorAvailibility(request: any): any {
        let classColor: any = {};
        if (request) {
            switch (request.IsFlagDispo) {
                case 1:
                    classColor = {
                        right: 'fill-lime-green',
                        left: 'fill-lime-green'
                    }
                    break;

                case 2:
                    classColor = {
                        right: 'fill-orange',
                        left: 'fill-lime-green'
                    }
                    break;

                case 3:
                    classColor = {
                        right: 'fill-red',
                        left: 'fill-lime-green'
                    }
                    break;
                case 7:
                    classColor = {
                        right: 'fill-lime-green',
                        left: 'fill-red'
                    }
                    break;
                case 8:
                    classColor = {
                        right: 'fill-orange',
                        left: 'fill-red'
                    }
                    break;
                case 9:
                    classColor = {
                        right: 'fill-red',
                        left: 'fill-red'
                    }
                    break;



                default:
                    classColor = {
                        right: 'fill-dark-blue',
                        left: 'fill-dark-blue'
                    }
                    break;
            }
        }
        return classColor;
    }

    getColorStatus(status: any) {
        let classColor = "";
        if (status) {
            switch (status.Code) {
                case StatusCode.DRAFTED_REQ:
                    classColor = "color-light-grey"
                    break;

                case StatusCode.DELETED_REQ:
                    classColor = "color-black"
                    break;

                case StatusCode.CONSIDERED_VIS:
                    classColor = "color-lime-green"
                    break;

                case StatusCode.WAITING_VIS:
                    classColor = "color-orange"
                    break;

                case StatusCode.NOTCONSIDERED_VIS:
                    classColor = "color-red"
                    break;

                default:
                    classColor = "color-dark-blue"
                    break;
            }
        }
        return classColor;
    }

    async getStatus() {
        let data = await  firstValueFrom(this.app.apiService.getStatus())
        console.log("getStatus ==> ", data);
            if (data && Array.isArray(data)) {
                this.status = cloneDeep(data);

                this.status = this.status.filter((s: any) => s.StatusType === StatusType.F18Request
                    || s.StatusType === StatusType.F18Visite
                    && s.Code != StatusCode.DELETED_VIS);

                this.statusAll = [this.listDemandCustomOptions.allExceptArchivedStatusOption, ...this.status];
                //this.statusAll = this.status?.filter((status :any)=> status.StatusType === StatusType.F18Request)
                this.statusBrouillonSupp = this.status.filter((s: any) => s.StatusType === StatusType.F18Request)
                if(!this.search){
                    this.search = {}
                }
                
            }
    }

    getAllHall() {
        this.app.apiService.getAllHall().subscribe((data: any) => {
            console.log("meetingStandsHall ==> ", data);
            this.meetingStands = cloneDeep(data);
        });
    }


    async onchangeStatus(_req: any) {
        console.log("onchangeStatus Request ==> ", _req);
        if (!_req.state) return;

        console.log("objStatus ==> ", _req);

        try {
            const response = await this.app.apiService.setStatusFonctionnel(_req);
            console.log("apiService.changestatusReq ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.searchDemandes(this.search);

        } catch (error) {
            console.error(error);
        }
    }

    async getPriviGroups() {
        try {
            const response = await this.app.apiService.privigroupgetall();

            if (response && Array.isArray(response)) {
                let priviGroups = response;
                if (priviGroups) {
                    this.priviesGroup = priviGroups;
                }
                console.log("🚀  this.priviesGroup:", this.priviesGroup);
            }

        } catch (error) {
            console.error(error);
        }
    }

    async getGroups() {
        // console.log("🚀  |---------> ");
        let ods: any[] = [];
        //if show all is true and allODs is empty ===> go to API to get Data
        //OR show all is NOT true and usersODs is empty ===> go to API to get Data
        //else u already have data in allODs
        // or u already have data in usersODs
        if (this.search.userDo === true && (!this.allODs || this.allODs.length < 1)
            || this.search.userDo != true && (!this.userODs || this.userODs.length < 1)) {
            let ods = await this.app.apiService.getAllDO(this.search.userDo);
            console.log("🚀  ods:", ods)



            if (this.search.userDo === true) {
                this.allODs = cloneDeep(ods);
            }
            else {
                this.userODs = cloneDeep(ods);
            }
        }


        //i already have data, now set data to vmDoList selon this.search.userDo
        if (this.search.userDo === true) {
            this.vmDoList = this.allODs;
        }
        else {
            this.vmDoList = this.userODs;
        }

    }

    getCountries() {
        this.app.apiService.getCountries().subscribe((data: any) => {
            console.log("getCountries ==> ", data);

            if (data && Array.isArray(data)) {
                this.countries = data;
            }
        });
    }
    //#endregion
    async searchDemandes(search: any, thenFilterFast: boolean = false) {
        //save search to retrieve it when coming back to BO after redirect from BO
        this.searchHelperService.setAdvancedSearch(cloneDeep(search), VMProgDemandeSearchLocalStorageKey);

        console.log("search ==> ", search);
        let searchTemp: any = cloneDeep(search);

        if (searchTemp.selectedStatusEtatDemandes) searchTemp.StatusID = searchTemp.selectedStatusEtatDemandes.StatusID;
        if (searchTemp.StatusID === -1) {
            searchTemp.AllStatusExceptArch = true;
            searchTemp.StatusID = null;
        }

        if (searchTemp.selectedPriviGroup) searchTemp.PriviGroupID = searchTemp.selectedPriviGroup.PriviGroupID;
        if (searchTemp.selectedPriorities) searchTemp.ReqPriority = searchTemp.selectedPriorities.ReqPriority;
        if (searchTemp.selectedPeriod) searchTemp.Time = searchTemp.selectedPeriod.Period;
        if (searchTemp.selectedVmDo) searchTemp.GroupID = searchTemp.selectedVmDo.GroupID;
        if (searchTemp.selectedTypeCategory) searchTemp.TypeCategoryID = searchTemp.selectedTypeCategory.CategoryID;

        searchTemp.UserDO = this.userDo;
        searchTemp.RequestHaveVisit = search.RequestHaveVisit;

        this.app.sharedTools.deleteParamsInObject(searchTemp
            , ['selectedStatusEtatDemandes', 'selectedPriviGroup', 'selectedPriorities', 'selectedPeriod', 'selectedVmDo', 'selectedTypeCategory'])

        console.log("searchTemp ==> ", searchTemp);

        try {
            const response = await this.app.apiService.searchVmProgReq(searchTemp);
            console.log("searchDemandes ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert"
                    , () => { this.app.sharedTools.redirectTo('/'); }
                    , () => { })
                return;
            }

            this.demandes = cloneDeep(response)

            setTimeout(() => {
                if (this.demandes) {
                    this.demandes.forEach((demande: any) => {
                        if (demande?.CreationDate) {
                            demande.CreationDateFormat = this.datePipe.transform(demande.CreationDate, 'dd/MM/yyyy HH:mm:ss');
                        }

                        if (this.fastFiltersFolder.indexOf('CreationDateFormat') == -1) this.fastFiltersFolder.push("CreationDateFormat");

                        demande.selectedStatus = this.status.filter((etat: any) => { return etat?.StatusID == demande.StatusID })[0];
                        demande.selectedCountry = this.countries.filter((country: any) => { return country.CountryID == demande.CountryID })[0];
                        demande.selectedTypeCategory = this.typeCategory.filter((typCat: any) => { return typCat.TypeCategoryID == demande.CategoryID })[0];
                        demande.selectedPriviGroup = this.priviesGroup.filter((prvGrp: any) => { return prvGrp.PriviGroupID == demande.PriviGroupID })[0];
                        demande.selectedMeetingStand = this.meetingStands?.filter((mtSt: any) => { return mtSt.FolderID == demande.FolderID })[0];
                        demande.selectedPriorities = this.priorities.filter((p: any) => { return p.PriorityID == demande.PriorityID })[0];

                    });
                }

                if (thenFilterFast) {
                    this.filterFast();
                    if (hasAnyNonNullProperty(this.search)) {
                        console.log("hasAnyNonNullProperty", hasAnyNonNullProperty(search))
                        this.initAdvancedSearch();
                        this.showAdvancedSearch = true;
                    }
                }
            }, 100);

        } catch (error) {
            console.error(error);
        }
    }

    clear(table: Table) {
        table.clear();
    }

    clearSearch() {
        console.log("clearSearch ==>");
        this.rangeDates = [];
        let showAllDo = this.search.userDo
        let requestHaveVisit = this.search.RequestHaveVisit
        this.search = new SearchVmFolder();
        this.search.userDo = showAllDo
        this.search.RequestHaveVisit = requestHaveVisit
        this.searchDemandes(this.search);
        this.clearFastSearch();
    }

    clearFastSearch(): void {
        this.fastSearchFolderKeyword = '';
        this.filterFast();
    }

    onRowSelect(event: any) {
        console.log("event.data==>", event.data);
        console.log("selectedProgDemande==>", this.selectedProgDemande);
    }

    goToDetail(_demandeId: any) {
        console.log("[gotToDetail] ==> ", _demandeId);
        this.app.sharedTools.redirectTo('/detail-vm-prog-demandes/' + _demandeId.toString());
    }

    deleteFoldersSelected(_foldersSelected: any) {
        console.log('deleteFoldersSelected ==> ', _foldersSelected)
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteFolder'), 'AUTRE', 'confirm', () => {
            this.deleteFolders(_foldersSelected);
        }, () => {
            //this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
        });
    }

    deleteFolders(_foldersSelected: any) {
        console.log('deleteFolders TODO ==> ', _foldersSelected);
        //this.messageService.add({ severity: 'success', summary: 'Folder deleted successfully', detail: "You have accepted" });
    }

    onchangeSelectedAction(selectedAction: any) {
        console.log(selectedAction)
    }

    onSearchExposantName(event: any): void {
        let keyword: string | undefined = event.query;

        if (!keyword || keyword === '') {
            this.ExposantNameSuggestions = [];
        }
        else {
            this.refreshCompanyNameAutocomplete(keyword);
        }
    }

    onPrint() {
        console.log("🚀  print btn clicked !...");
    }

    getActionsMenuByFolder(folder: any): any {
        if (this.showConsultDirectExhibitorOption(folder)) {
            return this.items;
        }
        else {
            //ActionID = 2 is for View direct exhibitor Action
            return this.items.filter((item: any) => item.ActionID != 2);
        }
    }

    onchangePlanning(_req: any) {
        console.log("onchangesetDemandeOnPlanning ==> ", _req);
        if (!_req.state) return;

        console.log("objsetDemandeOnPlanning ==> ", _req);

        let visitIdList = []
        visitIdList.push(_req.ReqID)

        try {
            if (_req.ReqID) {
                console.log("changestatusReq ==> ", _req);
                this.app.apiService.setDemandOnPlanning(visitIdList).subscribe(
                    data => {
                        this.searchDemandes(this.search);
                    }
                )
                // console.log("response changestatusReq ==> ", response);

                // if (response && response.Result) {
                //     this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                //     return;
                // }

            }

        } catch (error) {
            console.error(error);
        }
    }


    //#region private methods
    private unsubscribeActiveSubscriptions(): void {
        if (this.foldersExportSubscription) {
            this.foldersExportSubscription.unsubscribe();
        }

        if (this.companyAutocompleteSubscription) {
            this.companyAutocompleteSubscription.unsubscribe();
        }
        if (this.langSubscription) {
            this.langSubscription.unsubscribe()
        }
    }

    private refreshCompanyNameAutocomplete(keyword: string): void {
        this.companyAutocompleteSubscription = this.app.apiService.companyAutocompleteVmFolder(keyword).subscribe({
            next: (data: any) => {
                if (data && data != '' && Array.isArray(data)) {
                    this.ExposantNameSuggestions = data;
                }
                else {
                    this.ExposantNameSuggestions = []
                }
            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }

    private showConsultDirectExhibitorOption(folder: any): boolean {
        if (folder?.StatusID) {
            return folder.StatusID == FolderStatus.InProgress
                || folder.StatusID == FolderStatus.WaitingSign
                || folder.StatusID == FolderStatus.Signed
                || folder.StatusID == FolderStatus.Validated
        }

        return false;
    }


    private initAdvancedSearch(): void {
        this.search =  cloneDeep(this.searchHelperService.getAdvancedSearch(VMProgDemandeSearchLocalStorageKey))
        let findSelectedStatusEtatDemandes = this.statusAll.find((c: any) => c.StatusID === this.search.selectedStatusEtatDemandes?.StatusID);
        let findSelectedPriviGroup = this.priviesGroup.find((c: any) => c.PriviGroupID === this.search.selectedPriviGroups?.PriviGroupID);
        let findSelectedPriorities = this.priority.find((c: any) => c.Priority === this.search.Priority);
        let findSelectedMeetingStand = this.meetingStands?.find((c: any) => c.FolderID === this.search.selectedMeetingStand?.FolderID);
        let findSelectedTypeCategory;


        // this.search.selectedStatus = findSelectedStatus;
        this.search.selectedPriviGroups = findSelectedPriviGroup;
        this.search.Priority = findSelectedPriorities;
        this.search.selectedStatusEtatDemandes = findSelectedStatusEtatDemandes;
        this.search.selectedPriorities = findSelectedPriorities;
        this.search.selectedHallsLieux = findSelectedMeetingStand;
        this.search.selectedTypeCategory = findSelectedTypeCategory;


        this.isRequestHaveVisit = this.search.isRequestHaveVisit;
        this.userDo = this.search.userDo;
    }
    //#endregion


    //#region Export
    listDemandeToCsv(): void {
        this.listDemandeExport();
    }

    private listDemandeExport(): void {
        this.listDemandeExportSubscription = this.app.apiService.listDemandeExport({}).subscribe({
            next: (data: any) => {
                console.log('listDemandeExport', data);
                if (data) {
                    let filesUrl: string = data;

                    window.open(filesUrl);
                }

            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }


    // DISPONIBILITY
    private async getEdition() {
        try {
            const response = await this.app.apiService.getEdition();
            console.log("🚀  response getEdition :", response);

            this.edition = response;
            if (this.edition?.AllEditionDates
                && this.edition?.AllEditionDates.length > 0) {
                this.getPeriodDays = [];


                this.keyValueDays = this.getPeriodDays.map((day: any) => ({
                    key: day.key,
                    value: day.LabelFR
                }));

                this.edition.AllEditionDates.forEach((e: any) => {
                    let disponibility: any = {
                        date: e,
                        LabelEN: this.datePipe.transform(e, 'E', '', Languages.English)?.substring(0, 1) ?? '',
                        LabelFR: this.datePipe.transform(e, 'E', '', Languages.French)?.substring(0, 1) ?? '',
                        LabelES: this.datePipe.transform(e, 'E', '', Languages.Spanish)?.substring(0, 1) ?? '',
                    };

                    this.getPeriodDays.push(disponibility);
                });
            }

            // this.disponibilities = [this.planninDOCustomOptions.allDisponibilityOption, ...this.disponibilities];


            console.log("🚀  this.disponibilities :", this.getPeriodDays);
        } catch (error) {
            console.error(error);
        }
    }
    onShowNoteDialog(activity: any) {
        this.currentActivity = activity;
        this.noteVisible = true;
    }

    onHideNoteDialog() {
        this.noteVisible = false;
        this.currentActivity = null;
    }
    //#endregion

    ref: DynamicDialogRef | undefined;

    openRequestEdit(request: any) {
        if (request) {
            this.ref = this.dialogService.open(DemandDetailComponent, {
                width: '80%',
                height: '90%',
                contentStyle: { overflow: 'auto' },
                baseZIndex: 10000,
                maximizable: false,
                data:
                {
                    od: request.Group
                    ,
                    formID: request.FormID,
                    app: this.app,
                    priority: request.ReqPriority,
                    //folderID: this.folderID,
                    requestID: request.ReqID,
                    isEdit: true
                }
            });

            this.ref.onClose.subscribe((val: any) => {
                console.log('closed ', val);
                if (val === true) {
                    //   this.getListDo();
                    //   this.getMyDoListGroup();
                }
            });
        }
    }

    mapColumn() {
        return this.demandes.map((row: any) => {
            const filteredColumns: any = {};
            let selectedColumnsExceptAction: any[] = this.selectedColumns.filter((c: any) => c.field !== 'SetDemandOnPlan')
            selectedColumnsExceptAction.forEach(column => {
                //   if(this.translalteService.currentLang === "fr"){
                switch (column.field) {

                    case "NumExposant": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Companies?.CompanyExternalID1 ?? ''
                        break;
                    case "CompanyName2": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = ` ${row.Companies?.CompanyName2 ?? ''}`
                        break;
                    case "Nbp1": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.ReqPriority?.substring(0, 2) ?? ''
                        break;
                    case "MeetingStand": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.MeetingStand ?? ''
                        break;
                    case "NbPersonnes": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Group?.NbPersonnes ?? ''
                        break;
                    case "delegation": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getPropertySwitchLang(row?.Group, 'Name')
                        break;
                    case "Nature": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.RequestActivities?.map((activity: any) => `${this.app.sharedTools.getLabelSwitchLang(activity)}`).join(', ');
                        break;
                    case "Status": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(row.state) ?? ''
                        break;

                    case "Datetime": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row?.VisitStartTime ?? ''
                        break;

                    default:
                        break;
                }
            });
            return filteredColumns;
        })
    }
    exportCSV() {
        this.helperService.exportCSV(this.demandes, this.mapColumn(), 'liste_demandes_export_' + new Date().toLocaleDateString(), true);
    }
    planningExposants(planningExposants: any, arg1: any, arg2: string, arg3: boolean) {
        throw new Error('Method not implemented.');
    }

    navigateToPlanningHtml(demande: any) {
        let showAllDo = 0
        if (this.search.userDo == false || this.search.userDo == undefined) {
            showAllDo = 1
        }
        let currentLang = this.app.translate.currentLang
        let currentUrl = this.router.url.split('/')[1]
        let url = `${environment.planningHtml}?user-id=${this.userId}&do-id=${demande.GroupID}&show-owned-only=${showAllDo}&lang=${currentLang}&from=/${currentUrl}`
        window.open(url, "_blank")
    }

    onActionSelectChange(action: any) {
        if (action.value.ActionID == 1) {
            this.app.confirm.confirmDialog(''
                , this.app.translate.instant('bo.vm-prog-dem.gestion-vm-affectation-demande')
                , 'AUTRE'
                , 'confirm'
                , () => {
                    let visitIdList : any[]= []
                    this.selectedProgDemande.forEach((element : any) => {
                            visitIdList.push(element.ReqID) 
                    });
                    this.app.apiService.setDemandOnPlanning(visitIdList).subscribe(
                        data => {
                            this.searchDemandes(this.search);
                        }
                    )
                    this._selectedAction = undefined
                }
                , () => { });
        }
    }
}
