import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { WebsiteRegex } from '../consts/utils';
import { cloneDeep } from 'lodash';
import { ProductHelped } from '../models/product-helped';
import { DatePipe } from '@angular/common';
import { DateHelper } from '../helpers/date.helper';
import { firstValueFrom } from 'rxjs';
import { FormCode } from '../enums/form-code';

@Component({
    selector: 'app-form-helped-generalites',
    templateUrl: './form-helped-generalites.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./form-helped-generalites.component.less'],
})
export class FormHelpedGeneralitesComponent {
    user: any = null;
    newProductForm!: FormGroup;
    submitted: boolean = false;
    product: ProductHelped = new ProductHelped();
    initForm: boolean = false;
    isEditMode: boolean = false;
    productId: any;
    HlpFormID!: number;
    steps: any;
    paths = location.pathname.split('/');
    currentPath: any = null;
    showFixPublicationDate: boolean = false
    editionOpeningDate: Date = new Date()
    isPublicationDelayed: boolean = false
    hlpForm: any
    frmData : any

    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe
    ) {
    }

    async getHelpedInfo() {
        let request = { frmType: "" }
        request.frmType = FormCode.FRM_HLP
        let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
        if (data != undefined && data.Id != undefined) {
            this.frmData = data
        }
    }

    async ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log("this.user ==> ", this.user);
        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }
        await this.getHelpedInfo()
        this.app.route.queryParams
            .subscribe(params => {
                console.log('[params]', params);
                if (params && params['productid']) {
                    this.productId = params['productid'];
                    console.log(this.productId);
                }

                if (params && params['helpedid']) {
                    sessionStorage.setItem('helped-form', JSON.stringify({ HelpedFormID: params['helpedid'] }));
                }
                if (params && params['folderguid']) {
                    sessionStorage.setItem('folderGuid', JSON.stringify(params['folderguid']));
                }
            });

        await this.getFormHelpedData();
        // this.app.sharedTools.changeUsrLangInInit();

        // this.app.sharedTools.getCurrentLanguage();

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG ==> ", resG);

                //
                if (this.productId) {
                    this.isEditMode = true;
                    this.getProduct(this.productId);
                    console.log("this.productId ==> ", this.productId);
                }
                else {
                    this.isEditMode = false;
                    this.addNewProductForm();

                }
            })
    }

    onIsMultiWriterChange(event: any): void {
        this.onSubmit(false);

        // if(event.checked && !this.isEditMode)
        console.log("onIsMultiWriterChange", event);
    }

    onFixDatePublicationChange() {
        let value = this.newProductForm.get('IsPublicationDifferentDate')?.value
        this.showFixPublicationDate = value
    }



    addNewProductForm() {
        console.log("addNewProductForm !!!")
        const notRequiredValidators: any = this.product.getListNotRequiredsInStep1();

        const formModel: any = {};

        console.log("addNewProductForm product ==> ", this.product)

            let prod: any = cloneDeep(this.product);
        for (const propertyName in this.product) {
            if (this.product.hasOwnProperty(propertyName)
            && propertyName != 'NameEN'
            && propertyName != 'NameFR') {
                const propertyValue = prod[propertyName];
                let validators: any = [];

                if (notRequiredValidators.indexOf(propertyName) == -1) validators.push(Validators.required);
                if (propertyName == 'SolUrl') validators.push(Validators.pattern(WebsiteRegex));
                formModel[propertyName] = [propertyValue, validators];
                console.log(formModel[propertyName], propertyName);
            }
        }
        
        formModel['NameEN'] = [prod.NameEN, [Validators.required, Validators.maxLength(60)]];
        formModel['NameFR'] = [prod.NameFR, [Validators.required, Validators.maxLength(60)]];

        this.newProductForm = this.formBuilder.group(formModel);
        console.log(formModel)
        console.log("this.newProductForm ==> ", this.newProductForm);

        this.initForm = true;

        if (this.product.PublicationWishDate != undefined && this.product.hlpIsPublicationDelayed) {
            this.newProductForm.get("IsPublicationDifferentDate")?.setValue(true)
            this.showFixPublicationDate = true
        }


    }



    async createProduct(productObj: any, andRedirect: boolean): Promise<any> {
        try {

            if (!this.isEditMode) {

                console.log("CREATE Produit ===>", productObj);
                productObj.HlpFormID = this.HlpFormID;
                console.log("CREATE Produit after attach innov ===>", productObj);
                const response = await this.app.apiService.createHelpedSolution(productObj);
                console.log('RESPONSE CREATE Product ===>', response);

                if (response && response.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                    }, () => {
                    })
                    return;
                }

                this.productId = response.ID;
                console.log(this.productId)
                console.log(andRedirect)
                this.newProductForm.get('ID')?.setValue(this.productId);
                this.newProductForm.get('ID')?.updateValueAndValidity();
                this.isEditMode = true;

                if (andRedirect) {
                    this.onNextStep();
                }
            }
            else {

                console.log("UPDATE Produit ===>", productObj);

                const response = await this.app.apiService.updateHelpedSolution(productObj);
                console.log('RESPONSE UPDATE Product ===>', response);

                if (response && response.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                    }, () => {
                    })
                    return;
                }
                if (andRedirect) {
                    this.onNextStep();
                }
            }

        } catch (error) {
            console.error(error);
        }
    }
    onSolUrlInput() {
        const solNameFRElement = this.newProductForm.get('NameFR');
        const solNameENElement = this.newProductForm.get('NameEN');

        if (solNameFRElement && solNameFRElement.value === '' && solNameENElement) {
            solNameFRElement.setValue(solNameENElement.value);
        }
    }

    onSubmit(andRedirect: boolean = true) {
        this.submitted = true;
        this.messageService.clear();

        if (this.showFixPublicationDate) {
            let publicationWishDate = this.newProductForm.get("publicationWishDate")?.value
            if (publicationWishDate != undefined && (publicationWishDate.getTime() > this.editionOpeningDate.getTime())) {
                return;
            }
        }

        // Logic to handle form submission
        if (this.newProductForm.invalid) {
            console.log("Is invalid !!!!");

            const invalidValues: any = {};
            const controls = this.newProductForm.controls;
            let invalidFields: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('ng-invalid') as HTMLCollectionOf<HTMLElement>;

            for (const controlName in controls) {
                if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                    invalidValues[controlName] = controls[controlName].value;
                }
            }

            console.log('Invalid form values:', invalidValues);
            console.log('this.newProductForm:', this.newProductForm);

            if (invalidFields && invalidFields.length > 1) this.app.formInvalid.addSubmittedInvalidClass(invalidFields);

            this.messageService.add({ key: 'custom', severity: 'error', summary: this.app.translate.instant('racine.errorsOther.errorForm'), detail: '', sticky: true });
        }
        else {
            let product: any = this.newProductForm.value;

            //Map nested properties according to api request input
            this.product.mapFormStep1Form(product);

            if (product.hlpIsPublicationDelayed && this.newProductForm.get('IsPublicationDifferentDate')?.value == true
                && this.newProductForm.get("PublicationWishDate")?.value != undefined) {
                let publicationWishDate = this.newProductForm.get("PublicationWishDate")?.value;
                this.product.PublicationWishDate = this.datePipe.transform(publicationWishDate, DateHelper.ApiDateTimeFormat);

            }

            if (!this.showFixPublicationDate) {
                this.product.PublicationWishDate = undefined;
            }



            // ==> Ici on save le step1 du product
            console.log(this.product)
            this.createProduct(this.product, andRedirect);
        }
    }



    private async getFormHelpedData() {
        let hlepform: any = JSON.parse(sessionStorage.getItem('helped-form') ?? '{}');

        if (hlepform && hlepform.HelpedFormID) {
            this.HlpFormID = hlepform.HelpedFormID;
            //this.folder = f18form.FolderID;
        } else if (hlepform && hlepform.HlpFormID) {
            this.HlpFormID = hlepform.HlpFormID;
        }
        await this.getHelpedForm();
    }

    async getProduct(solId: any) {
        console.log(solId)
        let response = await this.app.apiService.getHelpedSolutionById(solId);
        this.product.mapFormGetResponse(response);

        console.log('this.product : ', this.product)

        // this.editionOpeningDate = new Date(new Date(this.product?.editionOpeningDate).setDate(
        //     this.product?.editionOpeningDate?.getDate() - 1))
        this.addNewProductForm();

    }

    onPreviousStep(): void {
        this.app.sharedTools.goTo('/products-helped')
    }

    onCancelStep(): void {
        this.app.sharedTools.goTo('/products-helped')
    }

    onNextStep(): void {

        let queryparams: any = {
            productid: this.productId
        }
        console.log('query params', this.productId);

        this.app.sharedTools.redirectToWithParameters(`product-helped-description/edit`, queryparams);
    }

    private initOnChangeHandlers(): void {
        let isMultiWriterControl = this.newProductForm.get('IsMultiWriter');
        if (isMultiWriterControl) {
            isMultiWriterControl.valueChanges.subscribe((value: any) => {
                if (this.isEditMode != true && value == true) {
                    //TODO @hassen
                }
            });
        }
    }
    async getHelpedForm() {
        this.hlpForm = await this.app.apiService.getAllSolutionsHLP({ HlpFormID: this.HlpFormID });
        this.isPublicationDelayed = this.hlpForm.IsPublicationDelayed

    }
}
