import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslatePipe } from '@ngx-translate/core';
import { FullCart } from '../models/full-cart';
import { CartGroup } from '../models/cart-group';
import { CartItem } from '../models/cart-item';

@Component({
    selector: 'app-contrat',
    templateUrl: './contrat.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./contrat.component.less']
})
export class ContratComponent implements OnInit, OnDestroy {
    //#region local properties
    //subscriptions
    private acceptCGVSubscription?: Subscription;
    private getCartSubscription: Subscription | undefined;

    pdfSrc: string = "";
    pdfSrcLocalUrl: any;

    stepSignature: any;
    declarationCGV: boolean = false;
    declarationCGVTemp: boolean = false;

    //current user
    user: any = null;

    //current Cart
    cart?: FullCart;

    //#endregion

    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private translatePipe: TranslatePipe,
        private cdRef: ChangeDetectorRef
    ) {
        //init user
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
    }
    //#region handle lifecycle hooks
    ngOnInit(): void {
        this.getCart();
    }

    ngOnDestroy(): void {
        this.unsubscribeActiveSubscriptions();
    }
    //#endregion

    //#region handle events
    onacceptCGVChange(event: any): void {
        this.cdRef.detectChanges();
        console.log('[event] => ', event)
        console.log('[this.declarationCGV] => ', this.declarationCGV);
    }
    //#endregion

    //#region save and next
    saveAndNext(_declarationCGV: any) {
        console.log('[_declarationCGV] => ', _declarationCGV);
        if (!this.declarationCGV) this.app.confirm.confirmDialog(this.app.translate.instant('racine.errorsOther.errorAcceptCgvTitle'), this.app.translate.instant('racine.errorsOther.errorAcceptCgvContent'), 'KO', "alert", () => { }, () => { })

        if (this.declarationCGV) this.acceptCGV();
    }
    //#endregion

    //#region private methods
    private acceptCGV(): void {
        if (this.declarationCGV) {
            this.acceptCGVSubscription = this.app.apiService
                .acceptCGV(this.user.FolderID).subscribe({
                    next: (data: any) => {
                        console.log('acceptCGV success with result : ', data);

                        if (data && data.Result) {
                            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                            return;
                        }

                        this.app.sharedTools.goTo('/signature');
                    },
                    error: (error: any) => {
                        console.log(error);
                        this.messageService.add({
                            severity: 'error'
                            , summary: this.translatePipe.transform('fo.contratPage.requestErrorHeader')
                            , detail: this.translatePipe.transform('fo.contratPage.acceptCGVError')
                        });
                    }
                });
        }
    }

    private unsubscribeActiveSubscriptions(): void {
        if (this.acceptCGVSubscription) {
            this.acceptCGVSubscription.unsubscribe();
        }

        if (this.getCartSubscription) {
            this.getCartSubscription.unsubscribe();
        }
    }

    private getCart(): void {
        this.getCartSubscription = this.app.apiService
            .getFullCart(this.user.FolderID).subscribe({
                next: (data: FullCart) => {
                    this.processGetFullCartSuccess(data);
                    this.mapUserChoicesFromCart();
                },
                error: (error: any) => {
                    console.log(error);
                }
            })
    }

    private processGetFullCartSuccess(data: FullCart): void {
        //save response data to local property
        let newFullCart: FullCart = new FullCart();

        data.CartGroups = data.CartGroups?.map((cg: CartGroup) => {

            cg.CartItems = cg.CartItems?.map((ci: CartItem) => {
                let newCartItem: CartItem = new CartItem();
                Object.assign(newCartItem, ci);

                return newCartItem;
            });

            let newCartGroup: CartGroup = new CartGroup();
            Object.assign(newCartGroup, cg);

            return newCartGroup;
        });

        Object.assign(newFullCart, data);

        this.cart = newFullCart;

        this.getContrat();
    }

    private mapUserChoicesFromCart(): void {
        this.declarationCGV = this.cart?.CGVAccepted ?? false;
        this.declarationCGVTemp = this.cart?.CGVAccepted ?? false;
    }

    private getContrat(): void {
        this.pdfSrc = encodeURI(this.cart?.InvoicePDF ?? '');
    }
    //#endregion
}
