import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { EmailRegex, PasswordRegex } from '../consts/utils';
import { MustMatch } from '../services/sharedtools.service';
import { MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { MediasTypes } from '../enums/media-type';
import { MediaValueChange } from '../models/media-value-change';
import { MediaCmpComponent } from '../medias-components/media-cmp/media-cmp.component';
import { UploadMediaInfo } from '../models/media-upload-info';
import { FunctionCodes } from '../enums/function-code';

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./create-user.component.less']
})
export class CreateUserComponent {
    submitted: boolean = false;
    initForm: boolean = false;
    newLeadForm!: FormGroup;
    civilities: any[] = [];
    langues: any[] = [];
    roles: any[] = [];
    phones: any[] = [];
    user?: any;
    newUser?: any;
    isEditMode: boolean = false;
    SelectedCivility: any;
    SelectedFunction: any
    otherFunctionName: any
    SelectedRole: any;
    SelectedRoles: any = [];
    SelectedLanguage: any;
    MobilePrefixSelected: any;
    paths = location.pathname.split('/');
    currentPath: any = null;
    userId: any;
    functions: any
    showOtherFunctionField: boolean = false
    mediasTypes = MediasTypes
    userPhoto: any
    @ViewChild('photoInput') photoInput?: MediaCmpComponent;
    isUserPictureSelected: boolean = false
    constructor(
        public app: AppComponent,
        private messageService: MessageService,
    ) {
    }
    async ngOnInit() {
        let cuser = JSON.parse(sessionStorage.getItem('userData')!);
        console.log(cuser);
        if (!cuser) {
            this.app.router.navigate(['/login']);
            return;
        }
        //check create or edit user
        console.log("this.path==> ", this.paths);
        this.currentPath = this.paths[1];
        var urlId: any = this.app.router.url.split('/').pop();
        if (this.currentPath == 'detail-user') {
            this.userId = parseInt(urlId);
        }
        await this.getFunctions()

        this.getCivilities();
        this.getRoles();
        this.getLanguages();
        this.getPhones();

        //
        if (this.userId) {
            this.isEditMode = true;
            this.getUser(this.userId);
            console.log("this.userId ==> ", this.userId);
        }
        else {
            //
            this.InitForm();
            console.log("this.newLeadForm", this.newLeadForm);
        }

    }

    async getFunctions() {
        const data = await firstValueFrom(this.app.apiService.getFunctions())
        console.log("getFunctions ==> ", data);
        this.functions = data; //cloneDeep(data);
    }

    onchangeSelect(val: any) {
        if (!val) return;
        //console.log("onchangeSelect", val);
    }

    getPhones() {
        this.app.apiService.getPhones().subscribe((data: any) => {
            console.log("getPhones ==> ", data);
            this.phones = data; //cloneDeep(data);
        });
    }
    getRoles() {
        this.app.apiService.getRoles().subscribe((data: any) => {
            console.log("getRoles ==> ", data);
            this.app.apiService.getRoles().subscribe((data: any) => {
                this.roles = data; //cloneDeep(data);
                let roleNameFieldName = this.app.sharedTools.getLangPropertyName("Label")
                this.roles.sort((a: any, b: any) => {
                    const roleNameA = a[roleNameFieldName].toLowerCase();
                    const roleNameB = b[roleNameFieldName].toLowerCase();
                    if (roleNameA < roleNameB) {
                        return -1;
                    } else if (roleNameA > roleNameB) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            });
        });
    }
    getLanguages() {
        this.app.apiService.getLanguages().subscribe((data: any) => {
            console.log("getLanguages ==>", data);
            this.langues = data;
        });
    }
    getCivilities() {
        this.app.apiService.getCivilities().subscribe((data: any) => {
            console.log("getCivilities ==> ", data);
            this.civilities = data; //cloneDeep(data);
        });
    }

    onDeleteUser(user: any) {
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteUser'), 'AUTRE', 'confirm', () => {
            setTimeout(() => { this.deleteUser(user); }, 1000);
        }, () => { });
    }

    async deleteUser(user: any) {
        console.log("DELETE User ===>", user);
        try {
            const response = await this.app.apiService.deleteUser(user);
            console.log('Response API : DELETE User ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successUserDelete'), 'OK', "alert", () => {
                this.app.sharedTools.redirectTo('/users');
            }, () => { });


        } catch (error) {
            console.error(error);
        }
    }

    annuler() {
        this.app.sharedTools.redirectTo('/users');
    }
    async createUser(formObj: any, sendMail: boolean): Promise<any> {

        console.log("CREATE USER ===w>", formObj);
        console.log("SEND MAIL =>", sendMail)
        try {
            if (!this.isEditMode) {
                let uploadMediaInfo = this.constuctMediaUploadInfo()
                const response = await this.app.apiService.createUser(
                    formObj,
                    this.photoInput?.selectedFile,
                    uploadMediaInfo
                );
                console.log('RESPONSE CREATE USER ===>', response);

                if (response && response.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                    }, () => {
                    })
                    return;
                }
                if (sendMail) {
                    let user = {
                        Login: (response.Login) ? response.Login : response.Email,
                        Email: response.Email,
                        Language: this.app.sharedTools.getParamByID(response.LanguageID,
                            'LanguageID', this.langues, 'CodeISO2', true)
                    }
                    console.log('[user]', user);

                    const responseMail = await this.app.apiService.sendPasswordChoiceEmail(user);
                    console.log('RESPONSE EMAIL ===>', responseMail);

                    if (responseMail && responseMail.Result) {
                        this.app.confirm.confirmDialog(this.app.translate.instant(''), this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + responseMail.Result), 'KO', "alert", () => {
                        }, () => {
                        })
                        return;
                    }

                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successUserCreePasswordSendMail'), 'OK', "alert", () => {
                        this.app.sharedTools.redirectTo('/users');
                    }, () => { });

                }
                else {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successUserCree'), 'OK', "alert", () => {
                        this.app.sharedTools.redirectTo('/users');
                    }, () => { });

                }
            }
            else {
                formObj.UserID = this.userId;
                let uploadMediaInfo = this.constuctMediaUploadInfo()
                const response = await this.app.apiService.updateUser(
                    formObj,
                    this.photoInput?.selectedFile,
                    uploadMediaInfo
                );
                console.log('RESPONSE UPDATE USER ===>hr', response);

                if (response && response.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                    }, () => {
                    })
                    return;
                }
                if (sendMail) {
                    let user = {
                        Login: (response.Login) ? response.Login : response.Email,
                        Email: response.Email,
                        Language: this.app.sharedTools.getParamByID(response.LanguageID,
                            'LanguageID', this.langues, 'CodeISO2', true)
                    }
                    console.log('[user]', user);

                    const responseMail = await this.app.apiService.sendPasswordChoiceEmail(user);
                    console.log('RESPONSE EMAIL ===>', responseMail);

                    if (responseMail && responseMail.Result) {
                        this.app.confirm.confirmDialog(this.app.translate.instant(''), this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + responseMail.Result), 'KO', "alert", () => {
                        }, () => {
                        })
                        return;
                    }

                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successUserUpdatePasswordSendMail'), 'OK', "alert", () => {
                        this.app.sharedTools.redirectTo('/users');
                    }, () => { });

                }
                else {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successUserUpdate'), 'OK', "alert", () => {
                        this.app.sharedTools.redirectTo('/users');
                    }, () => { });

                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    checkIfImageValid() {
        return this.photoInput?.isConformTodimension
    }


    constuctMediaUploadInfo(): UploadMediaInfo {
        return new UploadMediaInfo(this.photoInput?.degRotation ?? 0, true, 1);
    }



    onSubmit(sendMail: boolean) {
        this.submitted = true;
        console.log('[this.newLeadForm]', this.newLeadForm);

        if (this.newLeadForm.invalid || !this.checkIfImageValid()) {

            console.log("invalid form ===>", this.newLeadForm);
            console.log("invalid form ===>", this.newLeadForm.errors);

            //bloc explore form errors

            let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
            const invalidValues: any = {};
            const controls = this.newLeadForm.controls;

            for (const controlName in controls) {
                if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                    invalidValues[controlName] = controls[controlName].value;
                }
            }

            console.log('Invalid form values:', invalidValues);

            if (invalidField && invalidField.length > 1) {
                console.log("invalidField", invalidField)

                setTimeout(() => {
                    let el = invalidField[1].offsetTop - 100;
                    if (el) window.scroll({ top: el, behavior: "smooth" });
                }, 10);
            }
            //
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
            }, () => { });
        } else {
            let newUser = this.newLeadForm.value;
            if (newUser.SelectedCivility) newUser.CivilityID = newUser.SelectedCivility.CivilityID;
            //if (newUser.SelectedRole) newUser.RoleID = newUser.SelectedRole.RoleID;
            console.log("***newUser.SelectedRoles==>", newUser.RoleID)
            if (newUser.SelectedRoles && newUser.SelectedRoles.length > 0) {
                newUser.RoleID = "";
                newUser.SelectedRoles.forEach((roleSelect: any, index: any) => {
                    newUser.RoleID += roleSelect.RoleID;
                    if (index != newUser.SelectedRoles.length - 1) newUser.RoleID += ',';
                });
            }
            if (newUser.SelectedRoles == null) {
                newUser.RoleID = "";
            }
            if (newUser.SelectedLanguage) newUser.LanguageID = newUser.SelectedLanguage.LanguageID;
            if (newUser.MobilePrefixSelected && newUser.MobilePrefixSelected.CodeISO2) {
                newUser.MobilePrefix = newUser.MobilePrefixSelected.Prefix;
                newUser.MobileCodeIso = newUser.MobilePrefixSelected.CodeISO2;
            }
            newUser.Login = newUser.Login;
            newUser.IsUserPictureSelected = this.isUserPictureSelected
            console.log(newUser.SelectedFunction)

            newUser.Function = newUser.SelectedFunction?.FunctionID
            newUser.OtherFunctionName = this.newLeadForm.get("OtherFunction")?.value

            // this.app.sharedTools.deleteParamsInObject(newUser, ['SelectedCivility', 'SelectedRole', 'SelectedLanguage','MobilePrefixSelected'])
            this.app.sharedTools.deleteParamsInObject(newUser, ['SelectedCivility', 'SelectedRoles', 'SelectedLanguage', 'MobilePrefixSelected'])
            console.log('[newUser]', newUser);

            this.createUser(newUser, sendMail);
        }
    }
    private async getUser(userId: any) {

        try {
            const response = await this.app.apiService.getUser(userId);
            console.log("getUser ==> ", response);
            this.userPhoto = response?.UserImage
            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                }, () => {
                })
                return;
            }
            this.user = cloneDeep(response);

            setTimeout(() => {
                this.SelectedCivility = (this.user.CivilityID) ? this.app.sharedTools.getObjById(this.user.CivilityID, "CivilityID", this.civilities) : null;
                this.SelectedLanguage = (this.user.LanguageID) ? this.app.sharedTools.getObjById(this.user.LanguageID, "LanguageID", this.langues) : null;
                this.SelectedFunction = this.functions?.find((fct: any) => {
                    return fct?.FunctionID == this.user?.Function
                })
                this.otherFunctionName = this.user?.OtherFunctionName
                if (this.user.RoleID) {
                    let listRoles: any = this.user.RoleID.split(',').map((roleId: string) => {
                        return { RoleID: parseInt(roleId) };
                    });

                    this.SelectedRoles = this.roles.filter((sourceElement: any) =>
                        listRoles.some((cibleElement: any) => cibleElement.RoleID === sourceElement.RoleID)
                    );
                }

                //this.SelectedRole = (this.user.RoleID) ? this.app.sharedTools.getObjById(this.user.RoleID, "RoleID", this.roles) : null;
                this.MobilePrefixSelected = (this.user.MobileCodeIso) ? this.app.sharedTools.getObjByLabel(this.user.MobileCodeIso, "CodeISO2", this.phones) : null;

                this.InitForm();
            }, 500);

        }
        catch (error) {
            console.error(error);
        }
    }

    emailChanged() {
        let userEmail = this.newLeadForm.get("Email")?.value
        let userLogin = this.newLeadForm.get("Login")?.value
        if (userEmail != undefined && userEmail != "" && (userLogin == undefined || userLogin == "")) {
            this.newLeadForm.get("Login")?.setValue(userEmail)
            this.newLeadForm.updateValueAndValidity()
        }
    }

    private InitForm(): void {
        if (this.isEditMode) {
            this.newLeadForm = new FormGroup({
                CivilityID: new FormControl(this.user?.CivilityID ?? ''),
                SelectedCivility: new FormControl(this.SelectedCivility, Validators.required),
                LastName: new FormControl(this.user?.LastName ?? '', Validators.required),
                FirstName: new FormControl(this.user?.FirstName ?? '', Validators.required),
                Email: new FormControl(this.user?.Email ?? '', [Validators.required, Validators.pattern(EmailRegex)]),
                LanguageID: new FormControl(this.user?.LanguageID ?? ''),
                SelectedLanguage: new FormControl(this.SelectedLanguage, Validators.required),
                RoleID: new FormControl(this.user?.RoleID ?? ''),
                //SelectedRole: new FormControl(this.SelectedRole, Validators.required),
                // SelectedRoles: new FormControl(this.SelectedRoles, Validators.required),
                // MobilePrefixSelected: new FormControl(this.MobilePrefixSelected, Validators.required),
                // Mobile: new FormControl(this.user?.Mobile ?? '', Validators.required),
                SelectedRoles: new FormControl(this.SelectedRoles),
                MobilePrefixSelected: new FormControl(this.MobilePrefixSelected),
                Mobile: new FormControl(this.user?.Mobile ?? ''),
                Password: new FormControl(this.user?.password ?? '', Validators.pattern(PasswordRegex)),
                ConfirmPassword: new FormControl(this.user?.ConfirmPassword ?? '', Validators.pattern(PasswordRegex)),
                // Password: new FormControl(this.user?.password ?? '', Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')),
                // ConfirmPassword: new FormControl(this.user?.ConfirmPassword ?? '', Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$%\^&\*]).{8,}$")),
                Active: new FormControl(this.user?.Active ?? false, Validators.required),
                Login: new FormControl(this.user?.Login ?? "", Validators.required),
                SelectedFunction: new FormControl(this.SelectedFunction),
                OtherFunction: new FormControl(this.otherFunctionName)
            }
                , { validators: MustMatch('Password', 'ConfirmPassword') });
            this.enableOtherFunctionNameField()
        }
        else {
            this.newLeadForm = new FormGroup({
                CivilityID: new FormControl(''),
                SelectedCivility: new FormControl(null, Validators.required),
                LastName: new FormControl('', Validators.required),
                FirstName: new FormControl('', Validators.required),
                Email: new FormControl('', [Validators.required, Validators.pattern(EmailRegex)]),
                LanguageID: new FormControl(''),
                SelectedLanguage: new FormControl(null, Validators.required),
                RoleID: new FormControl(''),
                //SelectedRole: new FormControl(null, Validators.required),
                // SelectedRoles: new FormControl([], Validators.required),
                // MobilePrefixSelected: new FormControl(null, Validators.required),
                // Mobile: new FormControl(null, Validators.required),
                SelectedRoles: new FormControl([]),
                MobilePrefixSelected: new FormControl(null),
                Mobile: new FormControl(null),
                Password: new FormControl('', Validators.pattern(PasswordRegex)),
                ConfirmPassword: new FormControl('', Validators.pattern(PasswordRegex)),
                // Password: new FormControl('', Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')),
                // ConfirmPassword: new FormControl('', Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$%\^&\*]).{8,}$")),
                Active: new FormControl(false, Validators.required),
                Login: new FormControl(null, Validators.required),
                SelectedFunction: new FormControl(null),
                OtherFunction: new FormControl(null)
            },
                { validators: MustMatch('Password', 'ConfirmPassword') }
            );
        }

        this.initForm = true;
        this.addOtherFunctionField()
    }

    enableOtherFunctionNameField() {
        let otherFucntionFieldControl = this.newLeadForm.get("OtherFunction")
        if (this.user.FunctionObject?.FunctionCode == FunctionCodes.OtherFunction) {
            this.showOtherFunctionField = true
            if (otherFucntionFieldControl) {
                otherFucntionFieldControl.setValue(this.otherFunctionName)
            }
        } else {
            if (otherFucntionFieldControl) {
                otherFucntionFieldControl.setValidators(null)
                otherFucntionFieldControl.updateValueAndValidity()
            }
        }
    }

    addOtherFunctionField() {
        let selectedFunctionControl = this.newLeadForm.get("SelectedFunction")
        if (selectedFunctionControl) {
            selectedFunctionControl.valueChanges.subscribe((data: any) => {
                this.showOtherFunctionField = true
                let otherFucntionFieldControl = this.newLeadForm.get("OtherFunction")
                if (data?.FunctionCode == FunctionCodes.OtherFunction) {
                    if (otherFucntionFieldControl) {
                        otherFucntionFieldControl.addValidators([Validators.required])
                        otherFucntionFieldControl.updateValueAndValidity()
                    }
                } else {
                    this.showOtherFunctionField = false
                    if (otherFucntionFieldControl) {
                        otherFucntionFieldControl.setValue(null)
                        otherFucntionFieldControl.setValidators(null)
                        otherFucntionFieldControl.updateValueAndValidity()
                    }
                }
            })
        }
    }

    mediaPhotoValueChanged(event: MediaValueChange) {
        this.isUserPictureSelected = event?.HaveValue ?? false
    }
}
