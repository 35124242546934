import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";
import { Plage } from "../models/plage";
import { DateHelper } from "../helpers/date.helper";
import { Languages } from "../enums/language";

export class CustomValidators {
    static arrayLengthGreaterThanZeroValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const values: any[] = control.value;
            console.log('values', values)
            return values.length > 0 ? null : { invalidElements: true };
        };
    }

    //this Validator was added to validate two form contols of exhibitor formgroup
    //at least one activity must be provided
    //either on Activities Control (array)
    //either on OtherActivity Control(text input)
    static atLeastHaveOneActivityValidator(): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {
            if (!(formGroup instanceof FormGroup)) {
                return null; // Return null if it's not a FormGroup
            }

            const activitiesControl = formGroup.get('Activities');
            const otherActivityControl = formGroup.get('OtherActivity');

            return (activitiesControl?.value.length > 0
                || otherActivityControl?.value && otherActivityControl?.value != '') ? null : { atLeastHaveOneActivityRequired: true };
        };
    }

    static atLeastHaveOneTypeDelegation(): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {
            if (!(formGroup instanceof FormGroup)) {
                return null; // Return null if it's not a FormGroup
            }

            const PriviGroupTypesDo = formGroup.get('ODTypeCategoriesIDs');

            return (PriviGroupTypesDo?.value?.length > 0
                || PriviGroupTypesDo?.value && PriviGroupTypesDo?.value != '') ? null : { atLeastHaveOneTypeDelegation: true };
        };
    }
    static RequiredNotNullOrZero(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value: any = control.value;
            console.log('value', value)
            return (value && value > 0) ? null : { RequiredNotNullOrZero: true };
        };
    }

    static greaterOrEqual(lessControlName: string, greaterControlName: string): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {
            if (!(formGroup instanceof FormGroup)) {
                return null; // Return null if it's not a FormGroup
            }

            const lessFormControl = formGroup.get(lessControlName);
            const greaterFormControl = formGroup.get(greaterControlName);

            if (lessFormControl && greaterFormControl) {
                return (lessFormControl.value <= greaterFormControl.value)
                    ? null
                    : { greaterOrEqual: true };
            }
            return null;
        };
    }

    static atLeastHaveOneCategoryValidator(): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {
            if (!(formGroup instanceof FormGroup)) {
                return null; // Return null if it's not a FormGroup
            }

            const categoriesControl = formGroup.get('Categories');
            const otherCategoryControl = formGroup.get('OtherCategory');

            return (categoriesControl?.value.length > 0
                || otherCategoryControl?.value && otherCategoryControl?.value != '') ? null : { atLeastHaveOneotherCategoryRequired: true };
        };
    }


    static plageEndHourStartHourBothNullOrHaveValue(): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {
            if (!(formGroup instanceof FormGroup)) {
                return null; // Return null if it's not a FormGroup
            }

            const startHourControl = formGroup.get('StartHour');
            const endHourControl = formGroup.get('EndHour');



            return (
                //both Not Null && both != MidnightTime (00:00:00)
                (endHourControl?.value && endHourControl.value != DateHelper.MidnightTime && startHourControl?.value && startHourControl.value != DateHelper.MidnightTime)
                ||
                //both null (MidNight)
                ((!endHourControl?.value || endHourControl?.value === DateHelper.MidnightTime)
                    &&
                    (!startHourControl?.value || startHourControl?.value === DateHelper.MidnightTime)
                )
            )
                ? null
                : { plageEndHourStartHourBothNullOrHaveValue: true };
        };
    }

    static plageEndHourGreaterOrEqualThanStartHour(): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {
            if (!(formGroup instanceof FormGroup)) {
                return null; // Return null if it's not a FormGroup
            }

            const startHourControl = formGroup.get('StartHour');
            const endHourControl = formGroup.get('EndHour');



            return (DateHelper.greaterOrEmptyTimeStrings(endHourControl?.value, startHourControl?.value))
                ? null
                : { endDateGreaterOrEqualThanStartDate: true };
        };
    }

    static endDateTimeGreaterOrEqualThanStartDateTime(endDateControlName: string,
        endTimeControlName: string,
        startDateControlName: string,
        startTimeControlName: string): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {
            if (!(formGroup instanceof FormGroup)) {
                return null; // Return null if it's not a FormGroup
            }

            const endDateControl = formGroup.get(endDateControlName);
            const endTimeControl = formGroup.get(endTimeControlName);

            const startDateControl = formGroup.get(startDateControlName);
            const startTimeControl = formGroup.get(startTimeControlName);

            if (endDateControl
                && endTimeControl
                && startDateControl
                && startTimeControl) {
                    // console.log('wow',endDateControl.value, startDateControl.value
                    //     , endTimeControl.value, startTimeControl.value);
                let bothNullOrNot: Boolean = true;

                let isAllNull = !endDateControl.value &&  !startDateControl.value
                && !startTimeControl.value && !endTimeControl.value;
;
                if(isAllNull){
                    return null;
                }

                bothNullOrNot =
                    (
                        (endDateControl.value && endTimeControl.value)
                        ||
                        (!endDateControl.value && !endTimeControl.value)
                    )
                    &&
                    (
                        (startDateControl.value && startTimeControl.value)
                        ||
                        (!startDateControl.value && !startTimeControl.value)
                    );

                if (bothNullOrNot) {
                    if (endDateControl.value > startDateControl.value || !endDateControl.value
                        || !startDateControl.value
                    ) {
                        return null;
                    }
                    else if (endDateControl.value?.toString() == startDateControl.value?.toString()) {
                        return endTimeControl.value > startTimeControl.value
                            ? null
                            : { greaterOrEqual: true };
                    }
                    else {
                        return { greaterOrEqual: true };
                    }
                }
                else {
                    return { bothNullOrNot: true };
                }
            }
            return null;
        };
    }

    static controlsRequiredAtLeastOneLang(controlsNames: string[]): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {
            if (!(formGroup instanceof FormGroup)) {
                return null; // Return null if it's not a FormGroup
            }

            let allNotNullEN: boolean = true;
            let allNotNullFR: boolean = true;
            let allNotNullES: boolean = true;

            controlsNames.forEach(name => {
                const controlEN = formGroup.get(`${name}${Languages.English.toUpperCase()}`);
                console.log(controlEN?.value, allNotNullEN)
                allNotNullEN = allNotNullEN && controlEN?.value;
            });

            controlsNames.forEach(name => {
                const controlFR = formGroup.get(`${name}${Languages.French.toUpperCase()}`);
                allNotNullFR = allNotNullFR && controlFR?.value;
            });

            controlsNames.forEach(name => {
                const controlES = formGroup.get(`${name}${Languages.Spanish.toUpperCase()}`);
                allNotNullES = allNotNullES && controlES?.value;
            });

            if(allNotNullEN || allNotNullFR || allNotNullES){
                return null;
            }

            return { controlsRequiredAtLeastOneLang: true };
        };
    }
}