import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./error-page.component.less']
})
export class ErrorPageComponent {
  appID: any = null;

  constructor(
    public app: AppComponent,
  ) {}

  ngOnInit() {
    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
          console.log("resG", resG);
          this.appID = resG.APP_ID;
      });

  }
}
