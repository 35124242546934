export class ODPlanningSearch {
    StatusID?: number;
    AllStatusExceptArch?: boolean;
    ODTypeCategoryID?: number;
    GroupID?: number;
    GroupSectorType?: string;
    CountryID?: number;
    Disponibility?: Date;
    OZContactID?: number;
    OAContactID?: number;
  }