import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { GroupSearchHelperService } from '../services/group-search-helper.service';
import { Table } from 'primeng/table';
import { cloneDeep } from 'lodash';
import { Menu } from 'primeng/menu';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
import { HelperService } from '../services/helper.service';

@Component({
  selector: 'app-federateur-list-window',
  templateUrl: './federateur-list-window.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./federateur-list-window.component.less'],
  providers: [AppComponent]
})
export class FederateurListWindowComponent {


  fastFiltersFederators: any = ['Company.CompanyExternalID1', 'Company.CompanyName2', 'Contact.FirstName', 'Contact.LastName', 'MaxDemandsNumber', 'RequestsNumber'];

  @ViewChild('dtFederateurListWindow') dtFederateurListWindow!: Table;
  @ViewChild('menuAction') menuAction!: Menu;

  fastSearchFederatuerKeyword: string = '';
  showAdvancedSearch: boolean = false;
  showTable: boolean = true;

  selectedItem!: any;
  groupPeriority: any = [];
  listDOExhiToExport: any[] = [];
  myDoExhibitors: any = [];
  exposants: any[] = [];
  colsFederateurs: any[] = [];
  selectedFederateurs: any[] = [];
  fastFiltersFederateurs: any[] = [];
  optionsDrpdwn: any[] = [];
  items: any = [];
  countries: any = [];
  federators: any = [];
  folderID?: number;
  federatorExportSubscription?: any;
  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // ==> restore original order
    this._selectedColumns = this.colsFederateurs.filter((col: any) => val.includes(col));
  }

  _selectedColumns: any = [];


  constructor(
    public app: AppComponent,
    private groupSearchHelperService: GroupSearchHelperService,
    private config: DynamicDialogConfig,
    private helperService: HelperService
  ) { }


  ngOnInit() {
    console.log('fld ID config', this.config);
    if (this.config?.data) {
      this.folderID = this.config.data.folderID;
      console.log('fld ID ', this.folderID);
    }
    this.fastSearchFederatuerKeyword = this.groupSearchHelperService.fastSearchKeyword;
    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
        this.optionsDrpdwn = cloneDeep(resG.selectGralOptions);
        this.items = resG.actionsGroupsRow;

        this.items.forEach((itemMenu: any) => {
          itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
        });

        this.colsFederateurs = resG.colsFederateurs;

        this.colsFederateurs.forEach((col: any) => {
          this.fastFiltersFederateurs.push(col.field);
        })

        this._selectedColumns = this.colsFederateurs;
        this.selectedItem = resG.listeDemandesExhebitor;
        this.groupPeriority = resG.GroupPeriority;

        this.getFederators(this.folderID)
        this.getCountries();
        //this.getExposants();
      });
  }

  filterFast() {
    this.groupSearchHelperService.fastSearchKeyword = this.fastSearchFederatuerKeyword;
    console.log("_value", this.fastSearchFederatuerKeyword);

    this.dtFederateurListWindow.filterGlobal(this.fastSearchFederatuerKeyword, 'contains');
  }
  clearFastSearch(): void {
    this.fastSearchFederatuerKeyword = '';
    this.filterFast();
  }
  getActionsMenuByGroup(group: any): any {
    return this.items.filter((item: any) => item.ActionID);
  }
  toggleMenu(event: any, PriviGroupID: number, items: any) {
    this.menuAction.toggle(event);

    console.log("PriviGroupID:", PriviGroupID);
    console.log("items:", items);

    items.forEach((itemMenu: any) => {
      itemMenu.groupIdRow = PriviGroupID;
      itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

      if (itemMenu.ActionID == 1) {
        itemMenu.command = (event: any) => {
          this.app.sharedTools.redirectTo('/detail-group/' + event.item.groupIdRow.toString());
        }
      }
    });
  }


  getCountries() {
    this.app.apiService.getCountries().subscribe((data: any) => {
      console.log("getCountries ==> ", data);
      this.countries = data; //cloneDeep(data);
    });
  }

  private async getFederators(federateurId: any) {

    try {
      const response = await this.app.apiService.getFederators(federateurId);
      console.log("getFederators ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
        }, () => {
        })
        return;
      }
      this.federators = cloneDeep(response);

    }
    catch (error) {
      console.error(error);
    }
    // this.app.apiService.getFederators(federateurId).subscribe((data: any) => {
    //   console.log("getFederators ==> ", data);
    //   this.federators = cloneDeep(data);
    // });
  }
  exportFederatorListToCsv() {
    this.FederatorListExport();
  }


  private FederatorListExport(): void {
    this.federatorExportSubscription = this.app.apiService.exportEP(this.federators, environment.visitmanagerRefCtrl.export_planning_exhibitor).subscribe({
      next: (data: any) => {
        console.log('FederatorListExport()====>', data);
        if (data) {
          let filesUrl: string = data;

          window.open(filesUrl);
        }

      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }




  EditExposant(federateur: any) {
    if (federateur.LessThanFederatedExhibitorSurface) {
      this.app.confirm.confirmDialog('', this.app.translate.instant('fo.federateur-list-window.CetExposantAyantMoins') + ' ' + federateur.FederatedExhibitorSurface + ' ' + this.app.translate.instant('fo.federateur-list-window.lesDemandes'), 'AUTRE', 'confirm', () => {
        this.continue(federateur)
      }, () => { });
    }
    else {
      this.continue(federateur)
    }


  }

  continue(federateur: any) {

    let queryparams: any = {
      FormID: federateur.FormID
    };

    this.app.sharedTools.redirectToWithParameters('/vm-f18-etat2sur2/exhibitor/', queryparams)
  }

  annuler() {
    //this.dialogVisible = false
  }

  //#region  csv exhibitor
  async getExport(federator: any) {
    await this.getMyDoExhibitor(federator);
    await this.exportMyDoExhibitorCSV()
  }

  async getMyDoExhibitor(federateur: any) {
 
    console.log("federateur.FormID", federateur.FormID)
    const resp = await this.app.apiService.getMyDoListGroup(federateur.FormID);

    console.log(resp)
    this.myDoExhibitors = cloneDeep(resp);
    console.log("🚀  this.myDoExhibitors:", this.myDoExhibitors);

  }

  exportMyDoExhibitorCSV() {
    this.helperService.exportCSV(this.mapDOExhibitorsListToPlaneList(), this.mapColumn(), 'liste_demandes_export_' + new Date().toLocaleDateString(), true);
  }

  mapDOExhibitorsListToPlaneList(): any[] {
    const filteredColumns: any = [];
    let i = 0;
    this.myDoExhibitors.forEach
      (
        (pair: any) => {
          let key = pair.Key;
          pair.Value.forEach
            (
              (row: any) => {
                let fullrow = row;
                fullrow.Group = key;
                filteredColumns[i] = fullrow;
                i++;
              }
            );
        }
      );
    console.log('filteredColumns====> ', filteredColumns)
    return filteredColumns;

  }

  mapColumn() {
    this.listDOExhiToExport = this.mapDOExhibitorsListToPlaneList();
    console.log('this.listDOExhiToExport==>', this.listDOExhiToExport);
    return this.listDOExhiToExport.map((row: any) => {



      const filteredColumns: any = {};
      this.selectedItem.forEach((column: any) => {
        //   if(this.translalteService.currentLang === "fr"){
        switch (column.field) {

          case "GroupPeriority": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.getGroupPeriority(row.Group.toString())
            break;
          case "Etat": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.Status[this.app.sharedTools.getLangPropertyName('Label')];
            break;
          case "delegation": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups[this.app.sharedTools.getLangPropertyName('Name')];
            break;
          case "Country": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.Country[this.app.sharedTools.getLangPropertyName('Label')]
            break;
          case "TYPE_DO": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.Categories[0][this.app.sharedTools.getLangPropertyName('Label')] ?? ''
            break;
          case "Sector": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.SectorCategory[this.app.sharedTools.getLangPropertyName('Label')] ?? ''
            break;
          case "ChefFullName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.Chef?.FullName ?? '';
            break;
          case "fonction": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.DetailGroup?.ShortDOName ?? ''
            break;
          case "GuestInterestCenter": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.Contacts?.map((ctc: any) => `${ctc.InterestCenter ? ctc.InterestCenter + ',' : ''}`).join(' ');
            break;
          case "NbPersonnes": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.DetailGroup?.Numberpersons ?? '';
            break;
          default:
            break;
        }
      });
      return filteredColumns;
    })
  }

  getGroupPeriority(periority: string): string {
    let response = ''
    this.groupPeriority.forEach
      (
        (p: any) => {
          if (periority.toString() == p.field.toString()) {
            response = p[this.app.sharedTools.getLangPropertyName('Label')];
          }
        }
      );
    return response;
  }

  //#endregion


}
