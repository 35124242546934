import { Component } from '@angular/core';
import { SharedtoolsService } from 'src/app/services/sharedtools.service';
import { AwardsRoutes, AwardsRoutesQueryParamsNames } from '../awards.routes';

@Component({
  selector: 'app-liste-products-award',
  templateUrl: './liste-products-award.component.html',
  styleUrls: ['./liste-products-award.component.less']
})
export class ListeProductsAwardComponent {
  constructor(private sharedTools: SharedtoolsService) {

  }

  onPreviousClick(): void {
    let awardForm = sessionStorage.getItem('award-form' ?? {});
    let guid = JSON.parse(awardForm!)?.FolderGuid;

    this.sharedTools.goTo(`/${AwardsRoutes.companyInfo}?${AwardsRoutesQueryParamsNames.folderGuid}=${guid}`);
  }
}
