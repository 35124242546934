import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ConferenceHelper } from '@helpers/conference.helper';
import { cloneDeep } from 'lodash';
import { Carousel } from 'primeng/carousel';
import { AppComponent } from 'src/app/app.component';
import { FOExternalPagesQueryParamsNames, FOExternalPagesRoutes } from 'src/app/consts/fo-external-pages.routes';
import { ConferenceProgramCode } from 'src/app/enums/conference-program-code';
import { ImageModelSuffix } from 'src/app/enums/image-model-suffix';
import { SocialNetworkType } from 'src/app/enums/social-network-type';
import { ApiService } from 'src/app/services/api.service';
import { ConferenceStoreService } from 'src/app/services/conference-store.service';
import { IdentityManagerService } from 'src/app/services/identity-manager.service';

@Component({
  selector: 'app-speaker-details',
  templateUrl: './speaker-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./speaker-details.component.less','speaker-details-responsive.component.less']
})
export class SpeakerDetailsComponent implements OnInit {
  @ViewChild('carousel') carousel?: Carousel;

  user: any = null;
  welcomeObj: any = null;


  speaker!: any;
  search: any = {};
  interests: any;
  exhibitors: any;
  isEnabled: boolean = false;
  speakerslength: number = 0;
  nextSessions: any;
  nextSession: any;
  allSessions: any;
  speakerID: any;
  IsValidUrl: boolean = false;
  tagsMaxLenght: number = 3;
  notDisplayedTags: string[] = [];

  responsiveOptions: any[] | undefined;

  numVisibleCard: number = 3;

  screenWidth: number = 0;
  isDesktop: boolean = false;
  isMobile: boolean = false;

  currentProgramCode: string = ConferenceProgramCode.PROG1;
  socialNetworkType = SocialNetworkType;

  isFirstElem: boolean = false;
  isLastElem: boolean = false;
  imageModelSuffix = ImageModelSuffix;
  conferenceHelper = ConferenceHelper;

  constructor(
    public app: AppComponent,
    private apiService: ApiService,
    public conferenceStore: ConferenceStoreService,
    protected ims: IdentityManagerService
  ) {
    this.screenWidth = window.innerWidth;
  }

  async ngOnInit() {

    this.onResize();

    this.app.route.queryParams.subscribe(params => {
      if (params && params['program'] && params['program'] != '') {
        this.currentProgramCode = params['program'];
      }
      if (params && params[FOExternalPagesQueryParamsNames.speakerDetail.speakerID]) {
        this.speakerID = params[FOExternalPagesQueryParamsNames.speakerDetail.speakerID];
        this.retrieveSpeaker();
      }
    });

    this.responsiveOptions = [
      {
        breakpoint: '1400px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '1220px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '1100px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '995px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 995) {
      this.numVisibleCard = 1;
      this.isMobile = true;
      this.isDesktop = false;
    }
    else if (this.screenWidth > 995 && this.screenWidth <= 1536) {
      this.numVisibleCard = 2;
      this.isMobile = false;
      this.isDesktop = true;
    }else{
      this.numVisibleCard = 2.5;
      this.isMobile = false;
      this.isDesktop = true;
    }
  }

  private async retrieveSpeaker() {
    await this.getSpeakerByID(this.speakerID);
    this.getLastOrFirstElement();
  }

  openNewTab(url: string): void {
    window.open(url, "_blank")
  }

  //#region  GET
  async getSpeakerByID(id: any) {
    try {
      const response = await this.apiService.getSpeakerById(id, this.currentProgramCode);
      if(response){
        this.speaker = response;
        console.log("🚀  this.speaker:", this.speaker)

        if(this.speaker.Conferences && Array.isArray(this.speaker.Conferences)){
          this.allSessions = this.speaker.Conferences;
        }

        for (let i = this.tagsMaxLenght; i < this.speaker.Tags?.length; i++) {
          this.notDisplayedTags.push(this.speaker.Tags[i]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getAllSessionsBySpeakerId(id: any) {
    try {
      const response = await this.apiService.getAllSessionsBySpeakerId(id, this.currentProgramCode);
      console.log(response);
      setTimeout(() => {
        this.allSessions = response;
        console.log("getAllSessionsBySpeakerId", this.allSessions)
      }, 10);
    } catch (error) {
      console.error(error);
    }
  }

  async getNextSessionsBySpeakerId(obj: any) {
    try {
      if(!obj){
        obj = {};
      }
      obj.ProgramCode = this.currentProgramCode;

      const response = await this.apiService.getNextSessionsBySpeakerId(obj);
      this.nextSessions = response;
      if(this.nextSessions && Array.isArray(this.nextSessions) && this.nextSessions.length>0){
        this.nextSession = this.nextSessions[0];
      }
      let speakerID = this.speakerID
      const response1 = await this.apiService.getAllSessionsBySpeakerId(speakerID, this.currentProgramCode);
      setTimeout(() => {
        this.allSessions = response1;
        // for (let i: number = 0; i < 5; i++) {
        //   this.allSessions.push(response1[i]);
        // }
        console.log("getAllSessionsBySpeakerId", this.allSessions)
      }, 10);
      console.log("getNextSessionsBySpeakerId", this.nextSessions)
    } catch (error) {
      console.error(error);
    }
  }
  //#endregion

  isImageUrl(url: string) {

    let isValidUrl: boolean = false;

    if (url == null || url == undefined) {
      return false;
    }

    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const extension = url.substring(url.lastIndexOf('.')).toLowerCase();

    isValidUrl = imageExtensions.includes(extension);
    return isValidUrl;
  }

  goBack() {
    this.app.location.back();
  }

  goPrevious() {
    let previousSpeakerID: any = this.conferenceStore.previousConfSpeaker(this.speakerID);
    if (previousSpeakerID) {
      let queryparams: any = {
        speakerID: previousSpeakerID
      };
      this.app.sharedTools.routeTo(FOExternalPagesRoutes.speakerDetail, queryparams );
    }
  }

  goNext() {
    let nextSpeakerID: any = this.conferenceStore.nextConfSpeaker(this.speakerID);
    if (nextSpeakerID) {
      let queryparams: any = {
        speakerID: nextSpeakerID
      };
      this.app.sharedTools.routeTo(FOExternalPagesRoutes.speakerDetail, queryparams );
    }
  }

  goNextSession(event: any) {
    this.carousel?.navForward(event);
  }

  goPreviousSession(event: any) {
    this.carousel?.navBackward(event);
  }

  onToggleOverlay(event: any, op: any, targetEl: any) {
    //if (!this.isCompositionFormEditMode) {
      op.toggle(event, targetEl)
    //}
  }

  private getLastOrFirstElement() {
    this.isFirstElem = this.conferenceStore.isFirstConfSpeaker(this.speakerID);
    this.isLastElem = this.conferenceStore.isLastConfSpeaker(this.speakerID);
  }

  public function(): string{
    if(!this.speaker){
      return "";
    }

    if(this.speaker.GradeCategory){
      return this.app.sharedTools.getPropertySwitchLang(this.speaker.GradeCategory, 'Label');
    }

    return this.app.sharedTools.getPropertySwitchLang(this.speaker, 'Function');
  }
}
