import { AfterViewChecked, ChangeDetectorRef, Component, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { ApiService } from './services/api.service';
import { SharedtoolsService } from './services/sharedtools.service';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { SpinnerService } from './services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { FormatTelHelperService } from './services/format-tel-helper.service';
import { FormInvalidHelperService } from './services/form-invalid-helper.service';
import { FOExternalDetailPagesRoutes, FOExternalListPagesRoutes, FOExternalMapPagesRoutes } from './consts/fo-external-pages.routes';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements AfterViewChecked {
    title = 'dunecore-BO';
    appID: any = null;
    showSpinner = false;
    private renderer : Renderer2;
    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public location: Location,
        public apiService: ApiService,
        public sharedTools: SharedtoolsService,
        public confirm: ConfirmDialogService,
        public spinnerService: SpinnerService,
        public translate: TranslateService,
        public http: HttpClient,
        public formatTelHelper: FormatTelHelperService,
        public formInvalid: FormInvalidHelperService,
        private cdRef: ChangeDetectorRef,
        private rendererFactory: RendererFactory2
        //public gmaps: GoogleMapService,
    ) {
        this.sharedTools.getCurrentLanguage();
        this.getAppID();
        this.renderer = rendererFactory.createRenderer(null, null);
        console.log('new app');
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                console.log(event.url);
                this.switchBodyBgColor(event.url)
            }
        });
    }

    ngAfterViewChecked() {
        let showSpinner = this.spinnerService.isSpinnerVisible();
        if (showSpinner != this.showSpinner) { // check if it change, tell CD update view
            this.showSpinner = showSpinner;
            this.cdRef.detectChanges();
        }
    }

    getAppID() {
        this.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("getAppID resG", resG);
                this.appID = resG.APP_ID;
            });
    }

    switchBodyBgColor(url: string): void {
        if (!(url && url.length > 1)) {
            return;
        }
        let extractedRoute = this.substringToCharOrEnd(url, 1, '?');
        console.log('x ', extractedRoute)
        if (extractedRoute
            && (FOExternalListPagesRoutes.indexOf(extractedRoute) > -1
                || FOExternalMapPagesRoutes.indexOf(extractedRoute) > -1
            )) {
            console.log(url, extractedRoute, 'is list or map');
            this.renderer.removeClass(document.body, 'body-light-background');
            this.renderer.addClass(document.body, 'body-blue-background');
        }
        else if (extractedRoute
            && FOExternalDetailPagesRoutes.indexOf(extractedRoute) > -1) {
            console.log('is detail');
            this.renderer.removeClass(document.body, 'body-blue-background');
            this.renderer.addClass(document.body, 'body-light-background');
        }
    }

    private substringToCharOrEnd(str: string, position: number, char: string): string {
        const index = str.indexOf(char);
        return index !== -1 ? str.substring(position, index) : str.substring(position, str.length);
    }
}
