import { Component, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Table } from 'primeng/table';
import { Menu } from 'primeng/menu';
import { AppComponent } from '../app.component';
import { SearchProductInnov } from '../models/search-product-innov';
import { MessageService } from 'primeng/api';
import { DataView, DataViewLayoutOptions } from 'primeng/dataview';
import { cloneDeep } from 'lodash';
import { Subscription, firstValueFrom } from 'rxjs';
import { StatusType } from '../enums/status-type';
import { FolderStatus } from '../enums/folder-status';
import { FolderSearchHelperService } from '../services/folder-search-helper.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DatePipe } from '@angular/common';
import { MediasTypes } from '../enums/media-type';
import { DateHelper } from '../helpers/date.helper';
import { StatusCode } from '../enums/status-code';
import { PlanningHTMLOptionsComponent } from '../planning-htmloptions/planning-htmloptions.component';
import { hasAnyNonNullProperty, InnovationAdvancedSearchLocalStorageKey, InnovationMediaLocalStorageKey } from '../consts/utils';
import { ExportZipHtmlOptionsComponent } from '../exportzip-htmloptions/exportzip-htmloptions.component';
import { SearchHelperService } from '../services/search-helper.service';
import { Paginator } from 'primeng/paginator';
import { PagePaginationInfo } from '../models/page-pagination-info';
import { FormCode } from '../enums/form-code';

@Component({
    selector: 'app-gestion-innov',
    templateUrl: './gestion-innov.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./gestion-innov.component.less']
})
export class GestionInnovComponent {
    //#region local properties
    //subscriptions
    private foldersExportSubscription?: Subscription;
    private companyAutocompleteSubscription?: Subscription;

    @ViewChild('menuAction') menuAction!: Menu;
    @ViewChild('dtFolders') dtFolders!: Table;
    @ViewChild('dtMedias') dtMedias!: Table;

    @ViewChild('dvFolders') dvFolders!: DataView;
    searchExport: any;
    medias: any = [];
    ref: any;
    solutions: any = [];
    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }
    layout: any = 'grid';
    groupedActions: any;
    showTable: boolean = true;
    rangeDates: Date[] = [];
    maxDate = new Date();
    search: any = new SearchProductInnov();
    showAdvancedSearch: boolean = false;
    fastFiltersFolder: any = ['NumExposant', 'CompanyName', 'ContactName', 'ContactEmail', 'ContactPhone'];
    folders: any = [];
    status: any = [];
    stats: any = [];
    categories: any = [];
    catTypeActivities: any = [];
    catTypeNature: any = [];
    catTypeStand: any = [];
    countries: any = [];
    colsFolders: any = [];
    exportcolsFolders: any = [];
    items: any = [];
    selectedFolders: any = [];
    selectedAction: any;
    _selectedColumns: any = [];
    selectOptionsPhotoLogo: any = [];
    companyNameSuggestions: string[] = [];
    publishStatus: any = [];
    fastSearchFolderKeyword: string = '';
    // unlockedCustomers: any = [];
    // lockedCustomers: any = [];
    //#endregion

    mediaTypes = MediasTypes;
    isLoaded: boolean = false;
    fichesZipExportSubscription: any = [];
    modalOptions: any
    showModal: boolean = false
    _selectedAction: any
    actions: any
    modalContent : any
    currentPageTable : number = 1
    currentPageIndex : number = 1
    rowsCapacity : number = 50
    pagePaginationInfo : PagePaginationInfo = {pageNumber : 1, rowSize : 50}

    currentMediaPageTable : number = 1
    currentMediaPageIndex : number = 1
    rowsMediaCapacity : number = 50
    pageMediaPaginationInfo : PagePaginationInfo = {pageNumber : 1, rowSize : 50}
    isMediaLoaded : boolean = false

    formCode : FormCode = FormCode.FRM_INNOV;


    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private datePipe: DatePipe,
        private dialogService: DialogService,
        private searchHelperService : SearchHelperService
    ) { }
    ngOnDestroy(): void {
        this.unsubscribeActiveSubscriptions();
    }
    onchangeSelect(_item: any) {
        if (!_item) return;
        console.log("onchangeSelect12**this.search**", _item, this.search.selectedStatus);

    }
    onchangestatSelect(_item: any) {
        if (!_item) return;

        console.log("onchangeSelect12**this.search**", this.search.selectedStatus);
    }
    onchangePublishOnNetSelect(ProductID: any, statusID: any) {
        const updateRequest = { ProductIds: [ProductID], StatusID: statusID };
        console.log("onchangePublishOnNetSelect updateRequest ====>", updateRequest);
        this.updatePublishOnNet(updateRequest);
    }
    onchangeCountry(_item: any) {
        if (!_item) return;
        console.log("onchangeSelect Country ====>", this.search.countryID);

    }

    onchangeWebPublishedStatus(_item: any) {
        this.search.webPublishedStatus = _item.webPublishedStatus;
        console.log("onchangeWebPublishedStatus folder ==> ", _item);
    }

    async onchangeStatus(_folder: any, _status: any) {
        console.log("onchangeStatus folderrrrr ==> ", _folder, _status);
        console.log("onchangeStatus statusss===>>", _status.Code);
        if (!_folder && !_status) return;
        let objStatus: any = {
            StatusID: _status.StatusID,
            ProductIds: [_folder.ProductID]
        }

        console.log("objStatus ==> ", objStatus);

        try {
            const response = await this.app.apiService.changestatusInnovation(objStatus);
            console.log("apiService.changestatus ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }


        } catch (error) {
            console.error(error);
        }
    }
    onchangeCategory(_category: any) {
        console.log("onchangeCategory _category ==> ", _category);

    }
    filterFast() {
        //this.folderSearchHelperService.fastSearchKeyword = this.fastSearchFolderKeyword;
        console.log("_value", this.fastSearchFolderKeyword);
        //this.dvFolders.filter(_value, 'contains');
        this.dtFolders?.filterGlobal(this.fastSearchFolderKeyword, 'contains');
    }

    async ngOnInit() {
        //get privious advanced search value before routing to other routes
        // this.search = cloneDeep(this.folderSearchHelperService.advancedSearch);
        // this.fastSearchFolderKeyword = this.folderSearchHelperService.fastSearchKeyword;
        this.search =cloneDeep(this.searchHelperService.getAdvancedSearch(InnovationAdvancedSearchLocalStorageKey))
        if(!this.search){
            this.search = {}
        }
        let user = JSON.parse(sessionStorage.getItem('userData')!);
        this.pagePaginationInfo = this.searchHelperService.getPagePaginationInfo(InnovationAdvancedSearchLocalStorageKey)
        this.rowsCapacity = this.pagePaginationInfo.rowSize ?? 50

        this.pageMediaPaginationInfo = this.searchHelperService.getPagePaginationInfo(InnovationMediaLocalStorageKey)
        this.rowsMediaCapacity = this.pageMediaPaginationInfo.rowSize ?? 50
        if (!user) {
            this.app.router.navigate(['/login']);
            return;
        }

        // this.colsFolders = this.app.translate.("racine.generalites.columns");
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG", resG);
                this.actions = resG.actionListeInnov;
                this.items = resG.actionsRow;

                this.items.forEach((itemMenu: any) => {
                    itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
                });

                this.colsFolders = resG.colsInnovation;

                this._selectedColumns = this.colsFolders;
                this.exportcolsFolders = this._selectedColumns.map((col: any) => ({ title: col.header, dataKey: col.header }));
            });

        await this.getStatus();
        await this.getCategories();
        await this.getCountries();
        this.initAdvancedSearch()
        this.searchFolders(this.search, true, true);
        this.solutions = this.selectedFolders;
    }

    toggleLayout() {
        this.showTable = !this.showTable;
    }

    toggleMenu(event: any, solution: any, folderGuid: string, items: any) {
        this.menuAction.toggle(event);

        console.log("items:", items);

        items.forEach((itemMenu: any) => {
            itemMenu.solIDRow = solution.ProductID;
            itemMenu.innoIDRow = solution.InnoID;
            itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

            if (itemMenu.ActionID == 1) {
                itemMenu.command = (event: any) => {
                    this.app.sharedTools.goTo('/detail-fiche/' + event.item.solIDRow.toString());
                }
            }

            if (itemMenu.ActionID == 2) {
                itemMenu.command = (event: any) => {

                    this.consultDirectSolExhibitor(event.item.innoIDRow, event.item.solIDRow);
                }
            }
        });
    }

    onDateChange(ev: any) {
        if (!ev || ev == null || "") {
            this.search.submissionDateStart = null;
            this.search.submissionDateEnd = null;
            return;
        };
        this.search.submissionDateStart = (ev[0]) ? this.datePipe.transform(ev[0], DateHelper.ApiDateTimeFormat) : null;
        this.search.submissionDateEnd = (ev[1]) ? this.datePipe.transform(ev[1], DateHelper.ApiDateTimeFormat) : null;
    }

    set selectedColumns(val: any[]) {
        // ==> restore original order
        this._selectedColumns = this.colsFolders.filter((col: any) => val.includes(col));
    }

    getColorStatus(WebPublishedStatus: any) {
        let classColor = "";

        switch (WebPublishedStatus.Code) {
            // Publish status
            case StatusCode.UNPUBLISHED_FICHE_PUBLISH:
                classColor = "color-dark-blue"
                break;
            case StatusCode.SCHEDULED_FICHE_PUBLISH:
                classColor = "text-orange-400"
                break;
            case StatusCode.PUBLISHED_FICHE_PUBLISH:
                classColor = "color-lime-green"
                break;
                
            // Validation status
            case StatusCode.DRAFT_FICHE:
                classColor = "color-light-grey"
                break;
                
            case StatusCode.TOVALIDATE_FICHE:
                classColor = "text-orange-400"
                break;
                
            case StatusCode.UNDER_REVIEW_FICHE:
                classColor = "color-bluegray"
                break;
                
            case StatusCode.VALIDATED_FICHE:
                classColor = "color-lime-green"
                break;
                
            case StatusCode.CANCELED_FICHE:
                classColor = "color-red"
                break;
                
            default:
                classColor = "color-dark-blue"
                break;
        }
        return classColor;
    }

    goToPdf(_urlPathPdf: any) {
        if (!_urlPathPdf) return;
        window.open(_urlPathPdf);
    }

    getTheTooltip() {
        let infoBulle: string = "";
        infoBulle = '<span class="font-bold">' + this.app.translate.instant('bo.dossiers-dp.tooltipTotalFolders');

        if (this.stats && this.stats.length > 0) {
            let statTotals: any = this.stats.filter((st: any) => { return st.StatusID == -99 })[0] || null;
            if (statTotals) infoBulle += ' : ' + statTotals.NbFolders;

            infoBulle += '<ul class="pl-3">';
            this.stats.forEach((stat: any) => {
                if (stat.StatusID == -99) return;
                infoBulle += '<li class="white-space-nowrap">' + stat.NbFolders + ' x ' + this.app.sharedTools.getLabelSwitchLangByID(stat.StatusID, 'StatusID', this.status) + '</li>'
            });
            infoBulle += '</ul>';
        }
        return infoBulle;
    }



    getStatus() {
        try {
            this.app.apiService.getStatus().subscribe((data: any) => {
                console.log("getStatus ==> ", data);
                let status = cloneDeep(data);
                this.selectOptionsPhotoLogo = status.filter((s: any) => s.StatusType === StatusType.FicheMediaImage)
                this.publishStatus = status.filter((s: any) => s.StatusType === StatusType.FichePublishState)
                this.status = status.filter((s: any) => s.StatusType === StatusType.FicheState)

            });
        } catch (error) {
            console.error(error);
        }
    }

    async getCategories() {
        try {
            const response = await this.app.apiService.getCategories();
            let categories = response;

            this.catTypeActivities = categories.filter((cat: any) => { return cat.CategoryType == "ACTIVITIES" });
            this.catTypeNature = categories.filter((cat: any) => { return cat.CategoryType == "EXHI_NATURE" });
            this.catTypeStand = categories.filter((cat: any) => { return cat.CategoryType == "STAND_TYPE" });
        } catch (error) {
            console.error(error);
        }
    }
    async getCountries() {
        this.countries = await firstValueFrom(this.app.apiService.getExtendedCountries());
    }

    async searchFolders(search: any, thenFilterFast: boolean = false, goLatestViewedPage : boolean = false) {
        //save search to retrieve it when coming back to BO after redirect from BO
        //this.folderSearchHelperService.advancedSearch = cloneDeep(search);
        this.searchHelperService.setAdvancedSearch(cloneDeep(search), InnovationAdvancedSearchLocalStorageKey);

        console.log("search1 ==> ", search);
        console.log("search2 ==> ", search.selectedStatus);
        let searchTemp: any = cloneDeep(search);

        // if (searchTemp.ExhibitorID) searchTemp.ExhibitorID = parseInt(searchTemp.ExhibitorID);
        // if (searchTemp.selectedCategory) searchTemp.CategoryID = searchTemp.selectedCategory.CategoryID;
        if (searchTemp.selectedCountry) searchTemp.CountryID = searchTemp.selectedCountry.CountryID;
        if (searchTemp.selectedStatus) searchTemp.StatusID = searchTemp.selectedStatus.StatusID;
        console.log("searchTemp.SelectedStatus ==> ", searchTemp);
        if (searchTemp.selectedWebPublishedStatus) searchTemp.WebPublishedStatus = searchTemp.selectedWebPublishedStatus.StatusID;
        if (searchTemp.ExhibitorID) searchTemp.CompanyExternalID1 = searchTemp.ExhibitorID;
        if (searchTemp.selectOptionsPhotoLogo) searchTemp.AvecLogo = searchTemp.selectOptionsPhotoLogo.AvecLogo;
        if (searchTemp.selectOptionsPhotoLogo) searchTemp.AvecPhoto = searchTemp.selectOptionsPhotoLogo.AvecPhoto;

        searchTemp[this.app.sharedTools.getLangPropertyName('SolutionName')] = searchTemp.SolutionName;
        delete searchTemp.SolutionName;
        console.log("search ==> ", search);

        searchTemp.Language = JSON.parse(sessionStorage.getItem('currentLangOnSwitchClick')!);
        if (searchTemp.selectedCategory) searchTemp.CategoryID = searchTemp.selectedCategory.CategoryID;
        console.log("searchTemp.IsPublishOnNet ==> ", searchTemp.IsPublishOnNet);

        if (searchTemp.selectedCountry) delete searchTemp.selectedCountry;
        // if (searchTemp.selectedStatus) delete searchTemp.selectedStatus;
        if (searchTemp.selectedWebPublishedStatus) delete searchTemp.selectedWebPublishedStatus;
        if (searchTemp.selectedCategory) delete searchTemp.selectedCategory;
        if (searchTemp.selectOptionsPhotoLogo) delete searchTemp.selectOptionsPhotoLogo;
        console.log("searchTemp last version ==> ", searchTemp);

        // delete searchTemp.selectedCategory;
        // delete searchTemp.selectedTypeStand;
        await this.getFicheInnovation(searchTemp);

        this.getMediaFromFolders(this.folders);

        try {
            // this.folders = cloneDeep(response.filter((fld: any) => { return fld.FolderNatureID != 8 }))//response; //cloneDeep(response); 


            //setTimeout(() => {
            this.folders.forEach((folder: any) => {
                folder.ProductName = this.app.sharedTools.getLabelSwitchLang(folder);
                if (this.fastFiltersFolder.indexOf('ProductName') == -1) this.fastFiltersFolder.push("ProductName");
                folder.SubmitedDate! = this.datePipe.transform(folder.SubmitedDate, DateHelper.ApiDateTimeFormat);
                if (this.fastFiltersFolder.indexOf('SubmitedDate') == -1) this.fastFiltersFolder.push("SubmitedDate");

                folder.WebPublicationWishDateFormat! = this.datePipe.transform(folder.WebPublicationWishDate, 'dd/MM/yyyy HH:mm:ss');
                if (this.fastFiltersFolder.indexOf('WebPublicationWishDateFormat') == -1) this.fastFiltersFolder.push("WebPublicationWishDateFormat");


                folder.selectedStatus = this.status.find((etat: any) => etat.StatusID == folder.StatusID);

                folder.selectedCountry = this.countries.filter((country: any) => { return country.CountryID == folder.CountryID })[0];
                folder.selected = false;

                // ==> On met ces champs dans la liste des filtres rapides.
                if (folder.selectedStatus) {
                    this.app.sharedTools.getLabelSwitchLang(folder.selectedStatus);
                    if (this.fastFiltersFolder.indexOf('selectedStatus.label') == -1) this.fastFiltersFolder.push("selectedStatus.label");
                }
                folder.selectedWebPublishedStatus = this.publishStatus.find((s: any) => s.StatusID === folder.WebPublishedStatus);
                if (folder.selectedWebPublishedStatus) {
                    this.app.sharedTools.getLabelSwitchLang(folder.selectedWebPublishedStatus);
                    if (this.fastFiltersFolder.indexOf('selectedWebPublishedStatus.label') == -1) this.fastFiltersFolder.push("selectedWebPublishedStatus.label");
                }

                if (folder.selectedCountry) {
                    this.app.sharedTools.getLabelSwitchLang(folder.selectedCountry);
                    if (this.fastFiltersFolder.indexOf('selectedCountry.label') == -1) this.fastFiltersFolder.push("selectedCountry.label");
                }
                if (folder.HistoricStatus.length > 0) {
                    folder.HistoricStatus.forEach((his: any) => {
                        if (his.StatusID == 1) {
                            folder.DateCreatedFormat! = this.datePipe.transform(his.DateCreation, 'dd/MM/yyyy HH:mm:ss');
                            if (this.fastFiltersFolder.indexOf('DateCreatedFormat') == -1) this.fastFiltersFolder.push("DateCreatedFormat");
                        }

                        if (his.StatusID == 2) {
                            folder.DateSubmittedFormat! = this.datePipe.transform(his.DateCreation, 'dd/MM/yyyy HH:mm:ss');
                            if (this.fastFiltersFolder.indexOf('DateSubmittedFormat') == -1) this.fastFiltersFolder.push("DateSubmittedFormat");
                        }

                        if (his.StatusID == 3) {
                            folder.DatePublishedFormat! = this.datePipe.transform(his.DateCreation, 'dd/MM/yyyy HH:mm:ss');
                            if (this.fastFiltersFolder.indexOf('DatePublishedFormat') == -1) this.fastFiltersFolder.push("DatePublishedFormat");
                        }
                    });
                }


            });
            this.isLoaded = true;

            if (thenFilterFast) {
                this.filterFast();
                if (hasAnyNonNullProperty(this.search)) {
                    console.log("hasAnyNonNullProperty", hasAnyNonNullProperty(this.search))
                    // this.initAdvancedSearch();
                    this.showAdvancedSearch = true;
                }
            }

            if(goLatestViewedPage){
                this.currentPageTable = ((this.pagePaginationInfo.pageNumber ?? 1) - 1) * (this.pagePaginationInfo.rowSize ?? 1);
            }

            console.log("this.folders ", this.folders);
            //}, 2000);

        } catch (error) {
            console.error(error);
        }
    }
    getMediaFromFolders(folders: any) {
        let i = 0;
        this.medias = [];
        folders.forEach((folder: any) => {

            folder.Media.forEach((media: any) => {
                if(media?.typeDocument?.TypeDOCCode===this.mediaTypes.IMAGE 
                    || media?.typeDocument?.TypeDOCCode=== this.mediaTypes.LOGO){
                        this.medias[i] = media;
                        i++;
                }
            });
        });
        console.log(this.medias)
        this.currentMediaPageTable = ((this.pageMediaPaginationInfo.pageNumber ?? 1) - 1) * (this.pageMediaPaginationInfo.rowSize ?? 1);
        this.isMediaLoaded = true
    }

    clear(table: Table) {
        table.clear();
    }

    //   filterExpert(event: any) {
    //     let filtered: any[] = [];
    //     let query = event.query;

    //     for (let i = 0; i < this.experts.length; i++) {
    //       let expert = this.experts[i];
    //       if (expert.toLowerCase().indexOf(query.toLowerCase()) == 0) {
    //         filtered.push(expert);
    //       }
    //     }

    //     this.filteredExperts = filtered;
    //   }

    clearSearch() {
        console.log("clearSearch ==>");
        this.rangeDates = [];
        this.search = new SearchProductInnov();
        this.searchFolders(this.search);
        this.getMediaFromFolders(this.folders)

        this.clearFastSearch();
    }

    clearFastSearch(): void {
        this.fastSearchFolderKeyword = '';
        this.filterFast();
    }

    toggleAdvancedSearch() {
        this.showAdvancedSearch = !this.showAdvancedSearch;
    }

    onRowSelect(event: any) {
        console.log("event.data==>", event.data);
        console.log("selectedFolders==>", this.selectedFolders);
        //this.messageService.add({ severity: 'info', summary: 'Folder Selected', detail: event.data.FolderID });
    }

    doAction() { }

    goToDetail(_productID: any) {
        console.log("[gotToDetail] ==> ", _productID);
        this.app.sharedTools.redirectTo('/detail-fiche/' + _productID.toString());
    }

    download(_folderId: any) {
        console.log("download ==> ", _folderId);
        //this.messageService.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted' });
    }

    exportExcel() {
        console.log('exportExcel !!!');

        // import('xlsx').then((xlsx) => {
        //     const worksheet = xlsx.utils.json_to_sheet(this.products);
        //     const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        //     const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        //     this.saveAsExcelFile(excelBuffer, 'products');
        // });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        console.log('saveAsExcelFile !!!');

        // let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        // let EXCEL_EXTENSION = '.xlsx';
        // const data: Blob = new Blob([buffer], {
        //     type: EXCEL_TYPE
        // });
        // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    deleteFoldersSelected(_foldersSelected: any) {
        console.log('deleteFoldersSelected ==> ', _foldersSelected)
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteFolder'), 'AUTRE', 'confirm', () => {
            this.deleteFolders(_foldersSelected);
        }, () => {
            //this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
        });
    }

    deleteFolders(_foldersSelected: any) {
        console.log('deleteFolders TODO ==> ', _foldersSelected);
        //this.messageService.add({ severity: 'success', summary: 'Folder deleted successfully', detail: "You have accepted" });
    }

    onchangeSelectedAction(selectedAction: any) {
        console.log(selectedAction)
    }

    exportFoldersToCsv(): void {
        this.foldersExport();
    }
    async exportFichesToZip(search: any): Promise<void> {
        console.log('exportFichesToZip', search);
        let searchTemp: any = cloneDeep(search);
        if (searchTemp.selectedCountry) searchTemp.CountryID = searchTemp.selectedCountry.CountryID;
        if (searchTemp.selectedStatus) searchTemp.StatusID = searchTemp.selectedStatus.StatusID;
        console.log("searchTemp.SelectedStatus ==> ", searchTemp);
        if (searchTemp.selectedWebPublishedStatus) searchTemp.WebPublishedStatus = searchTemp.selectedWebPublishedStatus.StatusID;
        if (searchTemp.ExhibitorID) searchTemp.CompanyExternalID1 = searchTemp.ExhibitorID;
        if (searchTemp.selectOptionsPhotoLogo) searchTemp.AvecLogo = searchTemp.selectOptionsPhotoLogo.AvecLogo;
        if (searchTemp.selectOptionsPhotoLogo) searchTemp.AvecPhoto = searchTemp.selectOptionsPhotoLogo.AvecPhoto;
        searchTemp[this.app.sharedTools.getLangPropertyName('SolutionName')] = searchTemp.SolutionName;
        delete searchTemp.SolutionName;
        console.log("search ==> ", search);

        searchTemp.Language = JSON.parse(sessionStorage.getItem('currentLangOnSwitchClick')!);
        if (searchTemp.selectedCategory) searchTemp.CategoryID = searchTemp.selectedCategory.CategoryID;
        console.log("searchTemp.IsPublishOnNet ==> ", searchTemp.IsPublishOnNet);

        if (searchTemp.selectedCountry) delete searchTemp.selectedCountry;
        // if (searchTemp.selectedStatus) delete searchTemp.selectedStatus;
        if (searchTemp.selectedWebPublishedStatus) delete searchTemp.selectedWebPublishedStatus;
        if (searchTemp.selectedCategory) delete searchTemp.selectedCategory;
        if (searchTemp.selectOptionsPhotoLogo) delete searchTemp.selectOptionsPhotoLogo;
        console.log("searchTemp last version ==> ", searchTemp);

        await this.fichesExportZip(searchTemp);
    }

    onSearchCompanyName(event: any): void {
        let keyword: string | undefined = event.query;

        if (!keyword || keyword === '') {
            this.companyNameSuggestions = [];
        }
        else {
            this.refreshCompanyNameAutocomplete(keyword);
        }
    }

    getActionsMenuByFolder(folder: any): any {
        if (this.showConsultDirectExhibitorOption(folder)) {
            return this.items;
        }
        else {
            //ActionID = 2 is for View direct exhibitor Action
            return this.items.filter((item: any) => item.ActionID != 2);
        }
    }
    consultDirectInnovExhibitor(InnovID: any): void {
        // let params = { folderID: folderId }
        // console.log(params);
        //this.app.sharedTools.redirectToWithParameters('/infos-exposant', params);
        // ==> new code
        this.app.apiService.getUrlAutologinInnov(InnovID).subscribe({
            next: (data: any) => {
                console.log('[getUrlAutologin] API =>', data);

                if (data) {
                    if (data.Result) {
                        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                        return;
                    }
                    window.open(data);
                }
            },
            error: (error: any) => {
                console.log(error);
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_ERROR'), 'KO', "alert", () => { }, () => { });
                return;
            }
        });
    }
    //#region private methods
    private unsubscribeActiveSubscriptions(): void {
        if (this.foldersExportSubscription) {
            this.foldersExportSubscription.unsubscribe();
        }

        if (this.companyAutocompleteSubscription) {
            this.companyAutocompleteSubscription.unsubscribe();
        }
    }

    private foldersExport(): void {
        if(!this.searchExport){
            this.searchExport = {}
        }
        this.foldersExportSubscription = this.app.apiService.foldersExport(this.searchExport).subscribe({
            next: (data: any) => {
                console.log('foldersExport', data);
                if (data) {
                    let filesUrl: string = data;

                    window.open(filesUrl);
                }

            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }

    private refreshCompanyNameAutocomplete(keyword: string): void {
        this.companyAutocompleteSubscription = this.app.apiService.companyAutocomplete(keyword).subscribe({
            next: (data: any) => {

                if (data && data != '' && Array.isArray(data)) {

                    this.companyNameSuggestions = data;

                }

                else {

                    this.companyNameSuggestions = []

                }

            },

            error: (error: any) => {

                console.log(error);

            }

        });
    }

    private showConsultDirectExhibitorOption(folder: any): boolean {
        if (folder?.StatusID) {
            return folder.StatusID == FolderStatus.InProgress
                || folder.StatusID == FolderStatus.WaitingSign
                || folder.StatusID == FolderStatus.Signed
                || folder.StatusID == FolderStatus.Validated
        }

        return false;
    }

    private consultDirectSolExhibitor(InnoID: any, SolID: any): void {
        // let params = { folderID: folderId }
        // console.log(params);
        //this.app.sharedTools.redirectToWithParameters('/infos-exposant', params);
        // ==> new code
        this.app.apiService.getUrlAutologinSolution(InnoID, SolID).subscribe({
            next: (data: any) => {
                console.log('[getUrlAutologin] API =>', data);

                if (data) {
                    if (data.Result) {
                        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                        return;
                    }
                    window.open(data);
                }
            },
            error: (error: any) => {
                console.log(error);
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_ERROR'), 'KO', "alert", () => { }, () => { });
                return;
            }
        });
    }

    private initAdvancedSearch(): void {
        let findSelectedCountry = this.countries.find((c: any) => c.CountryID === this.search?.selectedCountry?.CountryID);
        let findSelectedStatus = this.status.find((c: any) => c.StatusID === this.search.selectedStatus?.StatusID);
        let findSelectedCategory = this.catTypeActivities.find((c: any) => c.CategoryID === this.search.selectedCategory?.CategoryID);
        let findSelectedTypeStand = this.catTypeStand.find((c: any) => c.CategoryID === this.search.selectedTypeStand?.CategoryID);
        let findSelectedWebPublishedStatus = this.publishStatus.find((c: any) => c.StatusID === this.search.selectedWebPublishedStatus?.StatusID);
        if(this.search.AvecPhoto != false && this.search?.AvecPhoto != null && this.search?.AvecPhoto != undefined){
            let selectedOptions = this.selectOptionsPhotoLogo.filter((option:any) =>
                this.search?.AvecPhoto.some((selected:any) => selected.StatusID === option.StatusID)
            );
            this.search.AvecPhoto = selectedOptions
        }

        if(this.search.AvecLogo != false && this.search?.AvecLogo != null && this.search?.AvecLogo != undefined){
            let selectedOptions = this.selectOptionsPhotoLogo.filter((option:any) =>
                this.search?.AvecLogo.some((selected:any) => selected.StatusID === option.StatusID)
            );
            this.search.AvecLogo = selectedOptions
        }

        this.search.selectedCountry = findSelectedCountry;
        this.search.selectedStatus = findSelectedStatus;
        this.search.selectedCategory = findSelectedCategory;
        this.search.selectedTypeStand = findSelectedTypeStand;
        this.search.selectedWebPublishedStatus = findSelectedWebPublishedStatus

        this.rangeDates = [];

        if (this.search.submissionDateStart) {
            this.rangeDates.push(new Date(this.search.submissionDateStart));
        }

        if (this.search.submissionDateEnd) {
            this.rangeDates.push(new Date(this.search.submissionDateEnd));
        }
    }

    async getFicheInnovation(filter: any) {
        this.folders = await firstValueFrom(this.app.apiService.getFicheInnovation(filter))
        this.getMediaFromFolders(this.folders)
        console.log("this.folder", this.folders);
    }

    async updatePublishOnNet(request: any) {
        await firstValueFrom(this.app.apiService.updatePublishOnNet(request));
        await this.searchFolders(this.search);
    }
    private fichesExportZip(search: any): void {

        console.log("test", search);

        this.fichesZipExportSubscription = this.app.apiService.fichesExportZip(search).subscribe({
            next: (data: any) => {
                console.log('fichesZipExport', data);
                if (data || (data.Result != null && data.Result != 'ERROR')) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('bo.innov-fiches.PopInfoExportFiches'), 'KO', "alert", () => {
                    }, () => {
                    })
                    return;
                }
            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }
    generateManual() {
        this.ref = this.dialogService.open(ExportZipHtmlOptionsComponent, {
            header: this.app.translate.instant('bo.innov-fiches.ListCourielles'),
            width: '50%',
            contentStyle: { overflow: 'auto' },
            baseZIndex: 200,
            maximizable: false,
            styleClass: 'generateExportZip',
            data: { includeNoteSection: true }
        });

        this.ref.onClose.subscribe((val: { emails: string[] }) => {
            console.log('closed --------------------------> ', val);
            if (val) {
                let generateFicheInnovation: {
                    listSolutions: any,
                    isSingleFicheRequest: boolean,
                    emails: string[],
                    frmCode : string
                } = {
                    listSolutions: [...this.selectedFolders?.map((x: any) => x.ProductID)],
                    isSingleFicheRequest: false,
                    emails: val.emails,
                    frmCode: FormCode.FRM_INNOV 
                }
                this.app.apiService.generateFicheMediaAsync(generateFicheInnovation,FormCode.FRM_INNOV ).subscribe(
                    data => {
                        this.app.confirm.confirmDialog("", this.app.translate.instant('fo.productFormInnovation.success-generated-fiche')
                            , 'OK'
                            , "alert"
                            , () => { }
                            , () => { });
                    }
                )
            }
        })



        // commented (export csv of selected folders and send it to emails )
        // this.ref.onClose.subscribe((val: { emails: string[] }) => {
        //     console.log('closed --------------------------> ', val);
        //     if (val) {
        //         search.EmailForCSV = val.emails.join(';');
        //         search.LanguageForCSV = this.app.translate.currentLang;
        //         search.SelectedSolutionsIds = this.selectedFolders.map((prod: any) => prod.ProductID);


        //             this.app.apiService.fichesExportZip(search).subscribe({
        //                 next: (data: any) => {
        //                     console.log('fichesZipExport', data);
        //                     if (data) {
        //                         setTimeout(() => {
        //                             this.app.confirm.confirmDialog("", this.app.translate.instant('bo.innov-fiches.PopInfoExportFiches'), 'KO', "alert", () => {
        //                             }, () => {
        //                             })
        //                             return;
        //                         }, 200);
        //                     }
        //                     console.log('getZipFiles: ', data);


        //                 },
        //                 error: (error: any) => {
        //                     console.log("error", error);


        //                 }
        //             });
        //     }
        // });

        // commented (export csv of selected folders and send it to emails )

    }

    onActionSelectChange() {
        if (this._selectedAction?.ActionID == 1) {
            this.modalOptions = this.status
            this.modalContent = this.app.translate.instant('bo.innov-fiches.choix-new-sts')
        } else if (this._selectedAction?.ActionID == 2) {
            this.modalOptions = this.publishStatus
            this.modalContent = this.app.translate.instant('bo.innov-fiches.choix-new-web-publish-sts')
        }
        this.showModal = true
    }

    pageMediaChanged(event : any){
        let pageIndex = event.first/event.rows + 1
        this.currentMediaPageIndex = pageIndex
        this.rowsMediaCapacity = event.rows
        this.updatePaginationInfo(InnovationMediaLocalStorageKey, this.currentMediaPageIndex, this.rowsMediaCapacity)
    }

    pageChanged(event : any){
        let pageIndex = event.first/event.rows + 1
        this.currentPageIndex = pageIndex
        this.updatePaginationInfo(InnovationAdvancedSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
    }
    rowsChanged(event : any){
        this.rowsCapacity = event
        this.updatePaginationInfo(InnovationAdvancedSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
    }

    updatePaginationInfo(key : string, currentPageIndex: number, rowCapacity : number){
        let pagePaginationInfo : PagePaginationInfo={
            pageNumber : currentPageIndex,
            rowSize : rowCapacity
        }
        this.searchHelperService.setPagePaginationInfo(pagePaginationInfo,key)
    }

   async acceptAction(result: any) {
        let request :{
            ProductIds : any,
            StatusID : any
        }
        if (this._selectedAction?.ActionID == 1) {
            //change the status of the fiche
            request = {
                StatusID : result.StatusID,
                ProductIds : this.selectedFolders?.map((folder :any)=> {
                    return folder?.ProductID
                })
            }
            try {
                const response = await this.app.apiService.changestatusInnovation(request);
                console.log("apiService.changestatus ==> ", response);
    
                if (response && response.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                    return;
                }
                this._selectedAction = null
                this.searchFolders(this.search, false, true);
            } catch (error) {
                console.error(error);
            }
        }
        else if (this._selectedAction?.ActionID == 2) {
            //change web published status
            request = {
                StatusID : result.StatusID,
                ProductIds : this.selectedFolders?.map((folder :any)=> {
                    return folder?.ProductID
                }),
            }
            await this.updatePublishOnNet(request);
            this._selectedAction = null
            this.searchFolders(this.search, false, true);
        }
        this.showModal = false
        this.selectedFolders = []
    }

    rejectAction() {
        this.showModal = false
        this._selectedAction = null
    }
    //#endregion
}
