import {Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';
import { GroupNew } from '../models/group-new';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../validators/custom-validators';
import { Table } from 'primeng/table';
import { PriviGroupHelper } from '../helpers/privigroup.helper';
import { DatePipe } from '@angular/common';
import { DateHelper } from '../helpers/date.helper';
import { ExtendedCountries } from '../enums/extended-countries';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-create-group',
    templateUrl: './create-group.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./create-group.component.less']
})
export class CreateGroupComponent {
    @ViewChild('dtActivities') dtActivities!: Table;

    submitted: boolean = false;
    initForm: boolean = false;
    newForm!: FormGroup;
    isEditMode: boolean = false;
    paths = location.pathname.split('/');
    currentPath: any = null;
    groupID: any;
    group?: any;
    groupNew = new GroupNew();
    typeDelegations: any = [];
    optionsDrpdwn: any[] = [];
    subtabs: any[] = [];
    typesDO: any[] = [];
    ExhActivities: any[] = [];
    colsActivities: any[] = [];
    activeSubIndex: any = 0;
    optionsAllowRequest : any = []
    countries : any
    selectedCountries : any

    constructor(
        public app: AppComponent,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
    ) { }

    ngOnInit() {
        let user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log(user);
        if (!user) {
            this.app.router.navigate(['/login']);
            return;
        }

        this.app.sharedTools.getJSON('general_data').subscribe(
            async resG => {
                this.optionsDrpdwn = cloneDeep(resG.selectGralOptions);
                this.optionsAllowRequest = this.optionsDrpdwn.filter((x:any)=> x.Id != 3)
                console.log('selectGralOptions ', resG.selectGralOptions);
                let tabMenu = cloneDeep(resG.tabMenu);
                console.log("tabMenu ==> ", tabMenu);

                let tabActive = this.app.sharedTools.getIndexOfTab();
                console.log("tabActive ==> ", tabActive);

                let activeTab = tabMenu.filter((item: any) => { return item.index == tabActive })[0];
                if (activeTab && activeTab.subMenu) {
                    this.subtabs = activeTab.subMenu;
                }

                this.colsActivities = cloneDeep(resG.colsActivities);

                this.getCategories();
                await this.getCountries()

                this.currentPath = this.paths[1];
                var urlId: any = this.app.router.url.split('/').pop();
                if (this.currentPath == 'detail-group') {
                    this.groupID = parseInt(urlId);
                }

                if (this.groupID) {
                    this.isEditMode = true;
                    this.privigroupget(this.groupID);
                    //console.log("this.groupID ==> ", this.groupID);
                }
                else {
                    this.initFormulaire();
                }
            });
    }

    private async privigroupget(groupId: any) {
        let pvgr: any = {
            PriviGroupID: groupId
        }
        try {
            const response = await this.app.apiService.privigroupget(pvgr);
            console.log("privigroupget ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                }, () => {
                })
                return;
            }
            let group: any = cloneDeep(response);
            this.group = new GroupNew();
            this.group.PriviGroupID = group.PriviGroupID;
            this.group.PriviGroupName = group.PriviGroupName;
            this.group.PriviGroupIsenabled = group.PriviGroupIsenabled;
            this.group.PriviGroupBlockStand = group.PriviGroupBlockStand;
            //this.group.PriviGroupWeightsRequest = group.PriviGroupWeightsRequest;
            this.group.PriviGroupTotalRequest = group.PriviGroupTotalRequest;
            this.group.PriviGroupChoice1 = group.PriviGroupChoice1;
            this.group.PriviGroupChoice2 = group.PriviGroupChoice2;
            this.group.PriviGroupChoice3 = group.PriviGroupChoice3;
            this.group.PriviGroupOpeningDate = group.PriviGroupOpeningDate;
            this.group.PriviGroupClosingDate = group.PriviGroupClosingDate;
            this.group.PriviGroupImprPlanDo = group.PriviGroupImprPlanDo;
            this.group.PriviGroupAllowRequestAfterDeadline = group.PriviGroupAllowRequestAfterDeadline;
            this.group.PriviGroupExpPlanDo = group.PriviGroupExpPlanDo;
            this.group.PriviGroupTypesDo = group.PriviGroupTypesDo;
            this.group.ExhActivities = group.ExhActivities;
            this.group.SurfaceMin = group.SurfaceMin
            this.group.SurfaceMax = group.SurfaceMax
            //this.group.ODTypeCategoriesIDs = group.ODTypeCategoriesIDs;

            this.selectedCountries = this.countries.filter((option:any) =>
                group?.Countries?.some((selected:any) => selected === option.CountryID)
            );
            //this.group.SelectedCountries = selectedCountries
            // ==> pushDates to ADD dates in dateRange
            this.group.pushDates();
            this.ExhActivities = this.group.ExhActivities;

            console.log("this.group ==> ", this.group);
            this.initFormulaire();
        }
        catch (error) {
            console.error(error);
        }
    } 

    private initFormulaire() {
        const notRequiredValidators: any = this.groupNew.getListNotRequireds();

        let formModel: any = {};
        if (this.isEditMode) formModel = cloneDeep(this.group);

        let newGroup: any = new GroupNew();
        if (this.isEditMode) newGroup = cloneDeep(this.group);
        //const newGroup: any = new GroupNew();

        for (const propertyName in newGroup) {
            if (newGroup.hasOwnProperty(propertyName) && propertyName != 'ExhActivities') {
                const propertyValue = newGroup[propertyName];
                let validators: any = [];

                if (notRequiredValidators.indexOf(propertyName) == -1) validators.push(Validators.required);

                if (propertyName === 'PriviGroupChoice1'
                    || propertyName === 'PriviGroupChoice2'
                    || propertyName === 'PriviGroupChoice3') {
                    formModel[propertyName] = [{ value: propertyValue, disabled: true }
                        , validators];
                }
                else {
                    formModel[propertyName] = [propertyValue, validators];
                }
            }
        }
        
        this.newForm = this.formBuilder.group(formModel);

        setTimeout(() => {
            this.newForm.get('SelectedCountries')?.setValue(this.selectedCountries);
          }, 0);
        if(!this.groupID){
            this.initDefaults();
        }
        //this.newForm.addValidators(CustomValidators.atLeastHaveOneTypeDelegation());
        this.initOnChangeHandlers();
        this.initForm = true;
        this.newForm.updateValueAndValidity()
    }

    async getCategories() {
        try {
            const response = await this.app.apiService.getCategories();
            //console.log('getCategories', response)

            let categoriesIni = cloneDeep(response);

            let categories = categoriesIni.filter((cat: any) => {
                return cat.CategoryType == "TYPE_DO"
            });

            //this.typeDelegations = cloneDeep(categories);
            //console.log('typeDelegations', this.typeDelegations);

        } catch (error) {
            console.error(error);
        }
    }

    onTabChange(newIndex: any) {
        // Aquí puedes realizar acciones en respuesta al cambio de pestaña
        console.log('newIndex:', newIndex);
        this.activeSubIndex = newIndex; // Actualizamos el valor de activeSubIndex si es necesario
    }

    handleChange(e: any) {
        this.activeSubIndex = e.index;
    }

    // onChangeTypesDO() {
    //     const selectedTypes = this.typeDelegations
    //         .filter((typedo: any) => this.newForm.get('ODTypeCategoriesIDs')?.value.includes(typedo.CategoryID))
    //         .map((typedo: any) => typedo.CategoryID);
    //     this.newForm.get('ODTypeCategoriesIDs')?.setValue(selectedTypes);

    //     // const selectedTypes = this.typeDelegations.filter((typedo: any) => this.newForm.get('ODTypeCategoriesIDs')?.value.includes(typedo));
    //     // this.newForm.get('ODTypeCategoriesIDs')?.setValue(selectedTypes);

    //     console.log("this.newForm ==> ", this.newForm);
    // }

    annuler() {
        this.app.sharedTools.redirectTo('/admin-exposants');
    }

    onSubmit() {
        this.updateValueAndValidity();
        console.log("this.newForm ==> ", this.newForm);
        this.submitted = true;

        if (this.newForm.invalid) {
            console.log("invalid form ===>", this.newForm);
            console.log("invalid form ===>", this.newForm.errors);

            //bloc explore form errors
            const invalidValues: any = {};
            const controls = this.newForm.controls;

            // let priviGroupTypesDo = document.getElementById('ODTypeCategoriesIDs');
            // if (this.newForm.errors?.['atLeastHaveOneTypeDelegation']) priviGroupTypesDo?.classList.add('ng-invalid');

            let invalidFields: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('ng-invalid') as HTMLCollectionOf<HTMLElement>;

            for (const controlName in controls) {
                if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                    invalidValues[controlName] = controls[controlName].value;
                }
            }

            console.log('Invalid form values:', invalidValues);
            console.log(this.newForm.errors?.['atLeastHaveOneTypeDelegation'])

            //if (invalidFields && invalidFields.length > 1) this.app.formInvalid.addSubmittedInvalidClass(invalidFields);
            if (invalidFields && invalidFields.length > 1) {
                setTimeout(() => {
                    let fristInvalidField: any = invalidFields[1] as HTMLElement;
                    let elFirst: any = (fristInvalidField.offsetTop == 0) ? fristInvalidField.offsetParent?.offsetTop - 20 : fristInvalidField.offsetTop - 20;
                    if (elFirst) window.scroll({ top: elFirst, behavior: "smooth" });
                }, 10);
            }

            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
                // ==> TO DO WELL : Not working. Je veux changer de tab dans le panel
                this.activeSubIndex = 0;
            }, () => { });
        }
        else {
            //we use form.getRawValue() instead of form.value because we want to include disabled controls values
            //P1, P2 and P3
            let group: any = this.newForm.getRawValue();
            if (group.rangeDates != undefined) {
                if (group.rangeDates[0]) group.PriviGroupOpeningDate = this.datePipe.transform(group.rangeDates[0], DateHelper.ApiDateTimeFormat);
                if (group.rangeDates[1]) group.PriviGroupClosingDate = this.datePipe.transform(group.rangeDates[1], DateHelper.ApiDateTimeFormat);
            }else {
                group.PriviGroupOpeningDate = undefined
                group.PriviGroupClosingDate = undefined
            }
            group.ExhActivities = this.ExhActivities;
            group.Countries = group.SelectedCountries?.map((x:any)=> x.CountryID)
            this.updateOrCreateGroup(group);
        }

    }

    async getCountries() {
        let data = await firstValueFrom(this.app.apiService.getExtendedCountries(ExtendedCountries.vm))
          this.countries = data; //cloneDeep(data);
      }

    async updateOrCreateGroup(group: any) {
        delete group.rangeDates;
        console.log("group ==> ", group);

        const response = (this.isEditMode) ? await this.app.apiService.privigroupupdate(group) : await this.app.apiService.privigroupcreate(group);
        if (this.isEditMode) console.log("updateGroup ==> ", response); else console.log("createGroup ==> ", response);

        if (response && response.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
            return;
        }

        let msg: any = (this.isEditMode) ? this.app.translate.instant('racine.successMsg.successGroupUpdate') : this.app.translate.instant('racine.successMsg.successGroupCree');

        this.app.confirm.confirmDialog("", msg, 'OK', "alert", () => { this.app.location.back(); }, () => { });
    }

    onDeleteGroup(priviGroupID: number) {
        let priviGroup: any = {
            PriviGroupID: priviGroupID
        }
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteGroup'), 'AUTRE', 'confirm', () => {
            setTimeout(() => { this.deleteGroup(priviGroup); }, 1000);
        }, () => { });
    }

    async deleteGroup(priviGroup: any) {
        console.log("DELETE priviGroup ===>", priviGroup);
        try {
            const response = await this.app.apiService.privigroupdelete(priviGroup);
            console.log('Response API : DELETE Group ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successGroupDelete'), 'OK', "alert", () => {
                this.app.sharedTools.redirectTo('/admin-exposants');
            }, () => { });


        } catch (error) {
            console.error(error);
        }
    }

    private initOnChangeHandlers(): void {
        this.setPriviGroupTotalRequestChangesHandler();
    }

    private setPriviGroupTotalRequestChangesHandler(): void {
        let priviGroupTotalRequestControl = this.newForm.get('PriviGroupTotalRequest');

        if (priviGroupTotalRequestControl) {
            priviGroupTotalRequestControl
                .valueChanges
                .subscribe((value: number) => {
                    //calculate P1, P2 and P3 (20%, 30% and 50% of PriviGroupTotalRequest)
                    let choices = PriviGroupHelper.calculateChoices(value);

                    //set P1, P2 and P3 calculate values
                    let priviGroupChoice1Control = this.newForm.get('PriviGroupChoice1');
                    if (priviGroupChoice1Control) {
                        priviGroupChoice1Control.setValue(choices.Priority1);
                    }

                    let priviGroupChoice2Control = this.newForm.get('PriviGroupChoice2');
                    if (priviGroupChoice2Control) {
                        priviGroupChoice2Control.setValue(choices.Priority2);
                    }

                    let priviGroupChoice3Control = this.newForm.get('PriviGroupChoice3');
                    if (priviGroupChoice3Control) {
                        priviGroupChoice3Control.setValue(choices.Priority3);
                    }
                });
        }
    }

    initDefaults(): void {
        let priviGroupIsenabledControl = this.newForm.get('PriviGroupIsenabled');
        if (priviGroupIsenabledControl && this.optionsDrpdwn[2]) {
            priviGroupIsenabledControl.setValue(this.optionsDrpdwn[2].Value);
        }
        let priviGroupExpPlanDoControl = this.newForm.get('PriviGroupExpPlanDo');
        if (priviGroupExpPlanDoControl && this.optionsDrpdwn[2]) {
            priviGroupExpPlanDoControl.setValue(this.optionsDrpdwn[2].Value);
        }
        let priviGroupImprPlanDoControl = this.newForm.get('PriviGroupImprPlanDo');
        if (priviGroupImprPlanDoControl && this.optionsDrpdwn[2]) {
            priviGroupImprPlanDoControl.setValue(this.optionsDrpdwn[2].Value);
        }
        let priviGroupAllowRequestAfterDeadlineControl = this.newForm.get('PriviGroupAllowRequestAfterDeadline');
        if (priviGroupAllowRequestAfterDeadlineControl && this.optionsDrpdwn[1]) {
            priviGroupAllowRequestAfterDeadlineControl.setValue(this.optionsDrpdwn[1].Value);
        }
        let priviGroupBlockStandControl = this.newForm.get('PriviGroupBlockStand');
        if (priviGroupBlockStandControl && this.optionsDrpdwn[2]) {
            priviGroupBlockStandControl.setValue(this.optionsDrpdwn[2].Value);
        }
    }

    private updateValueAndValidity(): void {

        //update P1, P2 and P3 values validity
        let priviGroupChoice1Control = this.newForm.get('PriviGroupChoice1');
        if (priviGroupChoice1Control) {
            priviGroupChoice1Control.updateValueAndValidity();
        }

        let priviGroupChoice2Control = this.newForm.get('PriviGroupChoice2');
        if (priviGroupChoice2Control) {
            priviGroupChoice2Control.updateValueAndValidity();
        }

        let priviGroupChoice3Control = this.newForm.get('PriviGroupChoice3');
        if (priviGroupChoice3Control) {
            priviGroupChoice3Control.updateValueAndValidity();
        }
    }
}
