import { Injectable } from '@angular/core';
import { SearchRole } from '../models/search-role';
import { RoleSearchLocalStorageKey } from '../consts/utils';

@Injectable({
  providedIn: 'root'
})
export class RoleSearchHelperService {

  private _advancedSearch: any;

  get advancedSearch(): any {
      this.extractFromLocalStorage();
      return this._advancedSearch;
  }

  set advancedSearch(value: any) {
      this._advancedSearch = value;
      this.saveToLocalStorage();
  }

  private _fastSearchKeyword: any;
  get fastSearchKeyword(): any {
      this.extractFromLocalStorage();
      return this._fastSearchKeyword;
  }
  set fastSearchKeyword(value: any) {
      this._fastSearchKeyword = value;
      this.saveToLocalStorage();
  }

  constructor() { this._advancedSearch = new SearchRole(); }

  private saveToLocalStorage(): void {
      let searchModel: any =
      {
          'advancedSearch': this._advancedSearch,
          'fastSearchKeyword': this._fastSearchKeyword
      };

      localStorage.setItem(RoleSearchLocalStorageKey, JSON.stringify(searchModel));
  }

  private extractFromLocalStorage(): void {
      let searchModelJson = localStorage.getItem(RoleSearchLocalStorageKey);
      if (searchModelJson && searchModelJson != '') {
          let searchModel: any = JSON.parse(searchModelJson);
          this._advancedSearch = Object.assign(new SearchRole(), searchModel['advancedSearch']);
          this._fastSearchKeyword = searchModel['fastSearchKeyword'];
      }
      else {
          this._advancedSearch = new SearchRole();
          this._fastSearchKeyword = '';
      }
  }
}
