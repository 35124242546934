import { Component, EventEmitter, Input, OnInit, Output,ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.less'],
  encapsulation : ViewEncapsulation.None
})
export class CustomDialogComponent implements OnInit {



  @Input()
  acceptBtnLabel: string = ""

  @Input()
  rejectBtnLabel: string = ""

  @Input()
  customBtnLabel: string = ""

  @Input()
  showModal: boolean = false

  @Input()
  showAcceptButton: boolean = true

  @Input()
  showConfirmButton: boolean = false

  @Input()
  showRejectButton: boolean = true

  @Input()
  showCustomButton: boolean = true

  @Input()
  showCloseIcon: boolean = true

  @Input()
  public set content(value: any) {
    this._content = this.sanitizer.bypassSecurityTrustHtml(value)
  }

  public get content(): any {
    return this._content;
  }



  @Input()
  showDropdown: boolean = false

  @Input()
  isModal: boolean = false

  @Input()
  dropDownOptions: any;

  @Input()
  isDropDownOptionsRequired: boolean = false;

  @Input()
  getDropDownOptionsColor: (sts: any) => string

  @Input()
  imageAlertUrl: string = '/assets/images/popup_AUTRE.png'

  @Output() acceptEvent = new EventEmitter<any>()

  @Output() rejectEvent = new EventEmitter<any>()

  @Output() customEvent = new EventEmitter<any>()
  @Output() selectedItem = new EventEmitter<any>()

  dropDownSelectedItem: any
  _content: any

  constructor(public app: AppComponent, private sanitizer: DomSanitizer) {
    this.getDropDownOptionsColor = (sts: any) => '';
  }

  

  ngOnInit(): void {
  }

  selectedItemChange() {
    this.selectedItem.emit(this.dropDownSelectedItem)
  }


  reject() {
    this.rejectEvent.emit();
  }

  accept() {
    this.acceptEvent.emit(this.dropDownSelectedItem);
  }

  custom() {
    this.customEvent.emit()
  }

}