import { cloneDeep } from "lodash";
import { ImageModelSuffix } from "../enums/image-model-suffix";

export class MediaHelper{
    public static readonly extension = 'png';
    public static readonly mediaSettingsDocTypesSeparator = ',';

    public static getImageModel(imageUrl: string, model: ImageModelSuffix) : string {
        let url: string = '';
        if(!imageUrl || !model){
            return url;
        }
        url = cloneDeep(imageUrl);

        // if(!extension){
        //     return url;
        // }

        return `${url}.${model}.${MediaHelper.extension}`;
    }

    public static extractExtensionFromFileUrl(fileUrl: string): string | null{
        // Regular expression to match the file extension
        const regex = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        const match = fileUrl.match(regex);
        if (match) {
            // match[1] will contain the extension without the dot
            return match[1];
        } else {
            // If no match found, return null
            return null;
        }
    }
}