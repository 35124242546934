import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Menu } from 'primeng/menu';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-awards-toolbar',
  templateUrl: './awards-toolbar.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./awards-toolbar.component.less']
})
export class AwardsToolbarComponent {

  @Input() redirectUrl: string | undefined
  @Input() titleToShow: any

  _formConfiguration: any
  @Input()
  get formConfiguration(): any {
    return this._formConfiguration;
  }

  set formConfiguration(value: any) {
    this._formConfiguration = value;
    this.updateSupportInfo()
  }

  forFormsSettings: any = null; // ==> test d'objet. A effacer après call api
  appID: any = null;

  @ViewChild('toolbarMenu') toolbarMenu!: Menu;
  user: any = null;
  toolbarActions: any = [];
  //breadcrumb: any = [{ label: "Dossiers DP" }, { label: "Détail dossier" }, { label: "Nouveau dossier" }];
  paths: any;
  currentPath: any = null;
  isPageFront: any = [];
  infoSupport: any = null;
  allToolBarAction: any[] = []

  constructor(
    public app: AppComponent,
    public translate: TranslateService,
    private router: Router
  ) { }

  redirectToUrl() {
    console.log(this.redirectUrl)
    let indexOfQueryParam = this.redirectUrl?.indexOf("?")
    if (indexOfQueryParam != -1) {
      let splittedUrl = this.redirectUrl?.split("?")
      if (splittedUrl && Array.isArray(splittedUrl)) {

        let url = splittedUrl[0]
        let queryParamsName = splittedUrl[1].split("=")[0]
        let queryParamsValue = splittedUrl[1].split("=")[1]

        let queryParams: any = {};
        queryParams[queryParamsName] = queryParamsValue;
        
        this.router.navigate([url], {
          queryParams
        })
      }
    }
    else {
      this.router.navigate([this.redirectUrl])
    }
  }

  toggleMenuToolbar(event: any) {
    //filter menu items
    this.toolbarActions = cloneDeep(this.allToolBarAction);
    let currentLang = this.app.translate.currentLang;
    let indexCurrentLang = this.allToolBarAction.findIndex((x: any) => x.codeLang === currentLang);
    this.toolbarActions.splice(indexCurrentLang, 1);

    let items = this.toolbarActions;



    //format label
    items.forEach((itemMenu: any) => {
      if (itemMenu.ActionID == 1) {
        itemMenu.label = `${this.user.Contact?.FullName}`
      }
      else {
        itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
      }

      if (itemMenu.ActionID != 4 && itemMenu.ActionID != 1) {
        itemMenu.command = (event: any) => {
          this.app.sharedTools.switchLanguage(itemMenu.codeLang)
        }
      }
      if (itemMenu.ActionID == 6) {
        itemMenu.command = (event: any) => {
          this.logOut();
        }
      }
    });

    //toggle

    this.toolbarMenu.toggle(event);
    setTimeout(() => {
      const profilePictureElement = document.querySelector('.profile-picture') as HTMLElement;
      if (profilePictureElement) {
        const imageUrl = this.user?.Contact?.UserProfilePictureUrl || '/assets/images/default-user-gray.png';
        profilePictureElement.style.backgroundImage = `url(${imageUrl})`;
      }
    }, 1);
  }
  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('userData')!);
    this.paths = location.pathname.split('/');
    console.log("this.paths ==> ", this.paths);
    this.currentPath = this.paths[1];

    console.log("this.currentPath==> ", this.currentPath);



    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
        console.log("resG", resG);
        this.allToolBarAction = resG.toolbarActions.filter((x: any) => x.isEnabled == "true");
        this.isPageFront = resG.toolbarFrontPages;

        this.toolbarActions.forEach((itemMenu: any) => {
          itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
        });

        this.appID = resG.APP_ID;

        this.infoSupport = resG.infoSupport;

        this.updateSupportInfo()

        // this.forFormsSettings = {
        //     //formImage: "/assets/images/ESY22_LOGO_BLANC.png",
        //     formContactTelEN: "Hotline: + 33 (0) 1 44 14 58",
        //     formContactTelFR: "Assistance : + 33 (0) 1 44 14 58 10",
        //     formContactMailEN: "hotline@eurosatory.com",
        //     formContactMailFR: "hotline@eurosatory.com"
        // }
      });
  }

  // Function to navigate back
  goBack() {
    this.app.location.back();
  }

  updateSupportInfo() {
    if (!this.infoSupport) {
      this.infoSupport = {}
    }

    if (this._formConfiguration?.SupportTel != undefined && this._formConfiguration?.SupportTel != "") {
      this.infoSupport.TelInfo = this._formConfiguration?.SupportTel
    }

    if (this._formConfiguration?.SupportEmail != undefined && this._formConfiguration?.SupportEmail != "") {
      this.infoSupport.EmailInfo = this._formConfiguration?.SupportEmail
    }
  }

  // Function to navigate forward
  goForward() {
    this.app.location.forward();
  }

  getTheClass() {
    let classes: string = "";
    if (this.isPageFront.indexOf(this.currentPath) != -1) {
      if (this.appID != 2) {
        classes = " bg-gradient-blue px-3";
      }
      else {
        classes = 'px-6';
      }
    }

    return classes;
  }

  getTheParent() {
    const path = location.pathname.split('/');
    let title: any = "";
    switch (path[1]) {
      case "parametrage-innov":
        title = "bo.innov-fiches.innovation"
        break;
      case "detail-helped":
        title = "bo.help-fiches.gestion-fiches-title"
        break;
      case "parametrage-helped":
        title = "bo.help-fiches.helped"
        break;
      case "detail-conference":
        title = "bo.liste-conferences.liste-conferences-title"
        break;
      case "detail-program":
        title = "bo.detail-program.fiche-programme"
        break;
      case "detail-vm-do":
        title = "bo.admin-delegations.title"
        break;
      case "detail-vm-user":
        title = "bo.vm-users.gestion-vm-user-title"
        break;
      case "detail-othervisit":
        title = "bo.othervisits.gestion-othervisit-title"
        break
      case "detail-actualite":
        title = "bo.liste-news.liste-news-title"
        break;
      case "detail-edition":
        title = "bo.gestion-des-editions.editions"
        break;
      case "detail-dp":
      case "create-dp":
        title = 'bo.dossiers-dp.gestion-dossiers-title';
        break;
      case "detail-user":
      case "create-user":
        title = 'bo.users.gestion-user-title';
        break;
      case "detail-role":
      case "create-role":
        title = 'bo.roles.gestion-role-title';
        break;
      case "detail-group":
      case "create-group":
        title = 'bo.groups.gestion-group-title';
        break;
      case "create-vm-exposant":
      case "detail-vm-exposant":
        title = 'bo.vm-exposant.gestion-vm-exposant-title';
        break;
      case "create-fiche":
      case "detail-fiche":
        title = 'bo.innov-fiches.gestion-fiches-title';
        break;
      default:
        title = null
        break;
    }
    return title;
  }

  getTheTitle() {
    const path = location.pathname.split('/');
    let title: string = "";
    switch (path[1]) {
      case "admin-board":
        title = "racine.generalites.admin-board"
        break
      case "member-board":
        title = "racine.generalites.member-board"
        break
      case "detail-othervisit":
        title = "bo.othervisits.titleEditOtherVisit"
        break
      case "detail-vm-do":
        title = "bo.vm-detail-do.titleCreateDo-vm"
        break;
      case "dp":
        title = 'bo.dossiers-dp.gestion-dossiers-title'
        break;
      case "create-dp":
        title = 'bo.dossiers-dp.create-dossier-title';
        break;
      case "detail-dp":
        title = 'bo.dossiers-dp.detail-dossier-title';
        break;
      case "parametrage-innov":
        title = "bo.parametrage.settings"
        break;
      case "help-fiches":
        title = "bo.help-fiches.gestion-fiches-title"
        break;
      case "parametrage-helped":
        title = "bo.parametrage.settings"
        break;
      case "liste-conferences":
        title = "bo.liste-conferences.liste-conferences-title"
        break;
      case "liste-programs":
        title = "bo.detail-program.liste-program-title"
        break;
      case "vm-prog-demandes":
        title = "bo.vm-prog-dem.gestion-vm-prog-title"
        break;
      case "planning-exposants":
        title = "bo.vm-planning-exposants.title"
        break;
      case "od-planning":
        title = "bo.vm-planning-do.title"
        break;
      case "admin-delegations":
        title = "bo.admin-delegations.title"
        break;
      case "vm-users":
        title = "bo.vm-users.gestion-vm-user-title"
        break;
      case "othervisits":
        title = "bo.othervisits.gestion-othervisit-title"
        break;
      case "statistiques":
        title = "bo.statistiques.etat_requetes"
        break;
      case "parametrage-vm":
        title = "bo.parametrage.settings"
        break;
      case "liste-attendees":
        title = "bo.liste-attendees.liste-attendees-title"
        break;
      case "liste-news":
        title = "bo.liste-news.liste-news-title"
        break;
      case "gestion-des-editions":
        title = "bo.gestion-des-editions.editions"
        break;
      case "traductions":
        title = "bo.traductions.traductions_title"
        break;
      case "users":
        title = 'bo.users.gestion-user-title';
        break;
      case "create-user":
        title = 'bo.users.titleCreateUtilisateur';
        break;
      case "detail-user":
        title = 'bo.users.titleEditUtilisateur';
        break;
      case "roles":
        title = 'bo.roles.gestion-role-title';
        break;
      case "create-role":
        title = 'bo.roles.titleCreateRole';
        break;
      case "detail-role":
        title = 'bo.roles.titleEditRole';
        break;
      case "admin-exposants":
        title = 'bo.admin-exposants.title';
        break;
      case "groups":
        title = 'bo.groups.gestion-group-title';
        break;
      case "create-group":
        title = 'bo.groups.titleCreateGroup';
        break;
      case "detail-group":
        title = 'bo.groups.titleEditGroup';
        break;
      case "create-vm-exposant":
        title = 'bo.vm-exposant.titleCreateVmExposant';
        break;
      case "detail-vm-exposant":
        title = 'bo.vm-exposant.titleEditVmExposant';
        break;
      case "fiches":
        title = 'bo.innov-fiches.gestion-fiches-title';
        break;
      case "detail-fiche":
        title = 'bo.innov-fiches.detail-fiches-title';
        break;
      case "detail-helped":
        title = 'bo.help-fiches.detail-fiches-title';
        break;
      case "detail-conference":
        title = "bo.detail-program.fiche-conference"
        break;
      case "detail-program":
        title = "bo.detail-program.fiche-programme"
        break;
      case "detail-vm-user":
        title = "bo.users.titleEditUtilisateur"
        break;
      case "detail-actualite":
        title = "bo.detail-actualite.title"
        break;
      case "detail-edition":
        title = "bo.detail-edition.titleEditDetailEdition"
        break;
      default:
        title = 'bo.dossiers-dp.gestion-dossiers-title'
        break;
    }
    return title;
  }

  logOut() {
    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.confirmMsg.logoutPopup'), "AUTRE", "confirm", () => {
      sessionStorage.clear();
      localStorage.clear();
      this.app.sharedTools.redirectTo('/login');
    }, () => { })
  }

  navigateTouserHomePage() {
    let userHomePage = sessionStorage.getItem("userHome")
    if (userHomePage) {
      this.app.sharedTools.goTo(userHomePage);
    }
  }
}
