import { Component, Input } from '@angular/core';
import { cloneDeep } from 'lodash';
import { TreeNode } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { CategoryType } from 'src/app/enums/category-type';
import { MediasTypes } from 'src/app/enums/media-type';
import { StatusCode } from 'src/app/enums/status-code';
import { AwardsRoutes, AwardsRoutesQueryParamsNames } from '../awards.routes';

@Component({
  selector: 'app-recap-awards-form',
  templateUrl: './recap-awards-form.component.html',
  styleUrls: ['./recap-awards-form.component.less']
})
export class RecapAwardsFormComponent {

  @Input() awardData: any
  @Input() showEditButtons: boolean = true
  @Input() awardId: any
  @Input() folderGuid : any
  otherCategories: any[] = [];
  orderedCategories?: TreeNode[]
  categories: any
  mediaTypes = MediasTypes
  frmData: any
  cpRecapCGV: any
  gcvAccepted = false
  status: any[] = [];
  toBeValidateStatus: any;
  toBeSubmitStatus: any;
  isEditMode: boolean = false;
  award: any;
  gotocgv: boolean = false;
  state: any;
  companyData: any

  constructor(public app: AppComponent) { }


  async ngOnInit() {
    if(!this.folderGuid){
      let awardFormData = JSON.parse(sessionStorage.getItem("award-form") ?? "")
      this.folderGuid = awardFormData?.FolderGuid
    }
    await this.getCompanyInfo()
    if(!this.awardData){
      this.app.route.queryParams
      .subscribe(async params => {
        if (params && params[AwardsRoutesQueryParamsNames.awardId]) {
          this.awardId = params[AwardsRoutesQueryParamsNames.awardId];
          console.log(this.awardId)
          // this.gotocgv = params['gotocgv'];
          await this.getAward(this.awardId);
        }
      });
    }
    this.app.sharedTools.getJSON('general_data').subscribe(
      async resG => {
        this.cpRecapCGV = resG.awardRecapCGV;
        if (this.awardId) {
          this.getStatus();
          this.isEditMode = true;
        }
        else {
          this.isEditMode = false;
        }
      })

    this.app.sharedTools.getJSON('general_data').subscribe(
      async resG => {
        this.otherCategories = resG.otherCategories;
        await this.getCategories()
        this.orderCategories()
      });
  }

  async getCompanyInfo() {
    this.companyData = await this.app.apiService.getCompanyInfo(this.folderGuid);
  }


  orderCategories() {
    this.orderedCategories = this.getOrderedCategoriesTree(this.awardData);
  }

  async getCategories() {
    try {
      const response = await this.app.apiService.getCategories();

      let categoriesIni = cloneDeep(response);

      let categories = categoriesIni.filter((cat: any) => {
        return cat.CategoryType == CategoryType.AwardCategory
      });

      this.categories = cloneDeep(categories);

    } catch (error) {
      console.error(error);
    }
  }


  getOrderedCategoriesTree(product: any): TreeNode[] {
    let cats = product?.Categories ?? [];
    let selectedCategories =
      this.categories.filter((c: any) => cats.indexOf(c.CategoryID) >= 0) ?? [];

    if (this.otherCategories && this.otherCategories.length > 0 && product?.OtherCategory && product?.OtherCategory != '') {
      this.otherCategories[1].LabelFR = product?.OtherCategory;
      this.otherCategories[1].LabelEN = product?.OtherCategory;
      this.otherCategories[1].LabelES = product?.OtherCategory;

      selectedCategories = [...selectedCategories, ...this.otherCategories];
    }

    let orderedCategoriesTree = this.buidlOrderedCategoriesTree(selectedCategories);
    if (this.otherCategories && this.otherCategories.length > 0) {
      this.otherCategories[1].LabelFR = "";
      this.otherCategories[1].LabelEN = "";
      this.otherCategories[1].LabelES = "";
    }

    console.log('orderedCategories ', orderedCategoriesTree);
    return orderedCategoriesTree;
  }

  private buidlOrderedCategoriesTree(categories: any[]): TreeNode[] {
    //map les categories manquantes
    let requiredCategories = [...categories];

    this.categories.forEach((c: any) => {
      let findCategoryAsParent = categories
        .find((sc: any) => sc.ParentCategoryID && sc.ParentCategoryID === c.CategoryID);

      let findCategory = categories
        .find((sc: any) => sc.CategoryID && sc.CategoryID === c.CategoryID);

      if (findCategoryAsParent && !findCategory) {
        requiredCategories.push(c);
      }
    });
    //

    requiredCategories = requiredCategories.sort((a: any, b: any) => { return b.Code > a.Code ? -1 : 1; });

    let tree: any = {};
    let orderedCategories!: TreeNode[];

    requiredCategories.forEach((cat: any) => {
      const parentId = cat.ParentCategoryID;

      if (!tree[parentId]) {
        tree[parentId] = [];
      }
      tree[parentId].push(cat);
    });

    orderedCategories = this.buildTree(tree, null);

    return orderedCategories;

  }
  onEditGeneralites() {
    let queryparams: any = {
      [AwardsRoutesQueryParamsNames.awardId]: this.awardId
    }
    this.app.sharedTools.redirectToWithParameters(AwardsRoutes.generalities, queryparams);
  }

  onEditDescription() {
    let queryparams: any = {
      [AwardsRoutesQueryParamsNames.awardId]: this.awardId
    }
    this.app.sharedTools.redirectToWithParameters(AwardsRoutes.description, queryparams);
  }
  private buildTree(tree: any, parentId: any) {
    const children = tree[parentId] || [];

    return children.map((child: any) => {
      const node = {
        key: child.CategoryID,
        label: this.app.sharedTools.getLabelSwitchLang(child),
        icon: '',
        parent: parentId,
        expanded: false,
        children: this.buildTree(tree, child.CategoryID),
        data: child.Code
      };
      return node;
    });
  }
  getStatus() {
    this.app.apiService.getStatus().subscribe((data: any) => {
      this.status = cloneDeep(data);

      this.toBeValidateStatus = this.status.find((s: any) => s.Code === StatusCode.TOVALIDATE_FICHE);
      this.toBeSubmitStatus = this.status.find((s: any) => s.Code === StatusCode.TO_BE_SUBMIT);
      console.log(this.status, this.toBeValidateStatus, this.toBeSubmitStatus)
    });
  }
  async setStatus(SolutionID: number, soumettre: boolean) {
    try {
      let reqToBeValidateObj: any = {
        SolID: SolutionID,
        Status: this.toBeValidateStatus.StatusID,
        Submited: soumettre
      }
      let reqToBeSubmitObj: any = {
        SolID: SolutionID,
        Status: this.toBeSubmitStatus.StatusID,
        Submited: soumettre
      }
      if (soumettre) {
        console.log(">>>> reqToBeValidateObj", reqToBeValidateObj)
        const response = await this.app.apiService.setStatusProduct(reqToBeValidateObj);
        console.log("response", response)
        setTimeout(() => {
          if (response && response.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
            }, () => {
            })
            return;
          }
          this.state = response

          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successproduitSetState'), 'OK', "alert", () => {
            this.onCancelStep();
          }, () => { });
        }, 700);
      }
      if (!soumettre) {
        const response = await this.app.apiService.setStatusProduct(reqToBeSubmitObj);
        console.log("response", response)
        setTimeout(() => {
          if (response && response.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
            }, () => {
            })
            return;
          }
          this.state = response
          this.onCancelStep();

        }, 700);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async getAward(solId: any) {
    let response = await this.app.apiService.getAwardSolutionById(solId);
    this.awardData = response;
    console.log('this.award resp: ', response);
    // if(this.gotocgv)
    // {
    //     const element: any = document.getElementById('scrollBottom');
    //     console.log('elementCible 01', element);
    //     if (element) {
    //         console.log('elementCible 02', element);
    //         let el = element.scrollHeight + 300;
    //         if (el) window.scroll({ top: el, behavior: "smooth" });
    //     }
    // }
  }
  onCancelStep(): void {
    this.app.sharedTools.goTo(AwardsRoutes.welcome);
  }
  onEditCompanyInfo() {
    let queryparams: any = {
      [AwardsRoutesQueryParamsNames.awardId]: this.awardId
    }
    this.app.sharedTools.redirectToWithParameters(AwardsRoutes.companyInfo, queryparams);
  }




}
