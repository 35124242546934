import { Component } from '@angular/core';

@Component({
  selector: 'app-closed-folder-page',
  templateUrl: './closed-folder-page.component.html',
  styleUrls: ['./closed-folder-page.component.less']
})
export class ClosedFolderPageComponent {

}
