import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-detail-dp',
  templateUrl: './detail-dp.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./detail-dp.component.less']
})
export class DetailDpComponent {
  // claimId: any;
  // contacts: any = [];
  // exposant: any = null;


  paths = location.pathname.split('/');
  currentPath: any = null;

  // exposant
  exposant?: any;
  folderId: any;
  user: any = null;
  numExposant: any;

  constructor(
    public app: AppComponent,
  ) { }

  ngOnInit() {
    let user = JSON.parse(sessionStorage.getItem('userData')!);
    console.log(user);
    if (!user) {
      this.app.router.navigate(['/login']);
      return;
    }
    console.log("this.path==> ", this.paths);
    this.currentPath = this.paths[1];
    var urlId: any = this.app.router.url.split('/').pop();

    this.folderId = (this.currentPath == 'recapitulatif') ? this.user.folderID : parseInt(urlId);
    console.log("this.folderId ==> ", this.folderId);

    if (this.folderId) {
      this.getFolder(this.folderId);
      console.log("this.folderId ==> ", this.folderId);
    }


  }
  private async getFolder(folderId: any) {

    try {
      const response = await this.app.apiService.getFolder(folderId);
      console.log("getFolder ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
        }, () => {
        })
        return;
      }

      this.exposant = cloneDeep(response);
      this.numExposant = this.exposant.Company.CompanyExternalID1;
      console.log('folder at side bar', this.numExposant, this.exposant)
    }
    catch (error) {
      console.error(error);
    }
  }

  confirmDelete() {
    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteDP'), 'AUTRE', 'confirm', async() => {
      this.deleteDP()
    }, () => { });
  }

  async deleteDP() {
    const response = await this.app.apiService.deleteFolder(this.folderId)
    console.log(response)
    if(response && response.Result){
      setTimeout(() => {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
            , 'KO'
            , "alert"
            , () => { this.app.sharedTools.goTo('/')}
            , () => { });
    }, 200);
    }else {
      this.app.confirm.confirmDialog(""
      , this.app.translate.instant('bo.dossiers-dp.folder-deleted-successfully')
      , 'OK'
      , "alert"
      , 
      () => { this.app.sharedTools.goTo('/')}, 
      () => { });
    }
  }
  async onSaveNumeroExposant() : Promise<any> {
     if(this.exposant){
      let request = this.exposant;
      console.log("company",request)
      request.Company.CompanyExternalID1 = this.numExposant;
      console.log("CREATE FOLDER ===w>", request);
      try {
          const response = await this.app.apiService.updateExihibitorNumber(request);
          console.log('RESPONSE CREATE DP ===>', response);

          if (response && response.Result) {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
              }, () => {
              })
              return;
          }
          else {
            this.app.confirm.confirmDialog(""
            , this.app.translate.instant('racine.successMsg.successDossierUpdt')
            , 'OK'
            , "alert"
            ,() => { this.app.sharedTools.goTo('/dp'); }
            , () => { })
          }
          
      } catch (error) {
          console.error(error);
      }
    }
  }
  
}
