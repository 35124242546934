import { Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Carousel } from 'primeng/carousel';
import { AppComponent } from 'src/app/app.component';
import { FOExternalPagesRoutes } from 'src/app/consts/fo-external-pages.routes';
import { ConferenceProgramCode } from 'src/app/enums/conference-program-code';
import { ImageModelSuffix } from 'src/app/enums/image-model-suffix';
import { SocialNetworkType } from 'src/app/enums/social-network-type';
import { ApiService } from 'src/app/services/api.service';
import { ConferenceStoreService } from 'src/app/services/conference-store.service';
import { ExhibitorStoreService } from 'src/app/services/exhibitor-store.service';
import { IdentityManagerService } from 'src/app/services/identity-manager.service';
import { SharedtoolsService } from 'src/app/services/sharedtools.service';

@Component({
  selector: 'app-exhibitor-details',
  templateUrl: './exhibitor-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./exhibitor-details-global.component.less',
    './exhibitor-details.component.less',
    './exhibitor-details-responsive.component.less']
})
export class ExhibitorDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('carousel') carousel?: Carousel;
  appID: any = null;
  solID: any;
  solutions: any;
  exhibitorID: any;
  isFirstElem: boolean = false;
  isLastElem: boolean = false;
  showNextPrevBtn: boolean = true;

  responsiveOptions: any[] | undefined;

  numVisibleCard: number = 3;
  screenWidth: number = 0;

  isDesktop: boolean = true;
  isMobile: boolean = false;

  socialNetworkType = SocialNetworkType;

  allConferences: any;
  exhibitor: any;
  tagsArray: string[] = [];
  dividerColor!: string;
  currentLang: any;

  CodeISO2 = 'fr'
  // country = 'france'

  Tags: any[] = ['Banque/Assuarance/Iat', 'Banque', 'Assurance', 'Courbe'];
  tagsMaxLenght: number = 3;
  notDisplayedTags: string[] = [];

  products: any[] = [];

  mappedExhibitorEvent: any;
  imageModelSuffix = ImageModelSuffix;

  constructor(
    public app: AppComponent,
    private exhibitorStore: ExhibitorStoreService,
    private sharedService: SharedtoolsService,
    private renderer: Renderer2,
    protected ims: IdentityManagerService
  ) {
    this.screenWidth = window.innerWidth;
  }

  async ngOnInit() {
    this.app.route.queryParams.subscribe((params) => {
      if (params && params['exhibitorID']) {
        this.exhibitorID = params['exhibitorID'];

        this.retrieveExhibitor();
      }
    });

    console.log(this.exhibitorID);
    this.currentLang = this.app.translate.currentLang;


    this.responsiveOptions = [
      {
        breakpoint: '1400px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '1220px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '1100px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '995px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
      }
    ];

    this.onResize();

  }

  private retrieveExhibitor(): void{
    this.currentLang = this.app.translate.currentLang;
    this.getExhbitiorByID(this.exhibitorID, this.currentLang);

    let exhibitorIds = sessionStorage.getItem('exhibitor-ids');
    if (exhibitorIds != null && exhibitorIds != '') {
      this.showNextPrevBtn = JSON.parse(exhibitorIds).length > 1 ? true : false;
    }

    this.getLastOrFirstElement();
  }

  ngOnDestroy() {
    this.renderer.removeStyle(document.body, 'background-color');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 995) {
      this.renderer.setStyle(document.body, 'background-color', '#f6f6f6');
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.renderer.setStyle(document.body, 'background-color', '#fff');
      this.isDesktop = true;
      this.isMobile = false;
    }
  }

  isImageUrl(url: string) {
    let isValidUrl: boolean = false;

    if (url == null || url == undefined) { return false; }

    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const extension = url.substring(url.lastIndexOf('.')).toLowerCase();

    isValidUrl = imageExtensions.includes(extension);
    return isValidUrl;
  }

  goBack() {
    this.app.location.back();
  }



  goPrevious() {
    if (!this.isFirstElem) {
      let queryParams: any = {
        exhibitorID: this.exhibitorStore.previousExhibitor(this.exhibitorID)
      };

      this.app.sharedTools.routeTo(FOExternalPagesRoutes.exhiDetail, queryParams);
    }
  }

  goNext() {
    if (!this.isLastElem) {
      let queryParams: any = {
        exhibitorID: this.exhibitorStore.nextExhibitor(this.exhibitorID)
      };

      this.app.sharedTools.routeTo(FOExternalPagesRoutes.exhiDetail, queryParams);
    }
  }

  openNewTab(url: string): void {
    window.open(url, "_blank")
  }

  onToggleOverlay(event: any, op: any, targetEl: any) {
    op.toggle(event, targetEl)
    //}
  }

  goPreviousConference(event: any) {
    this.carousel?.navBackward(event);
  }

  goNextConference(event: any) {
    console.log(this.carousel);
    this.carousel?.navForward(event);
  }

  goPreviousSolution(event: any) {
    this.carousel?.navBackward(event);
  }

  goNextSolution(event: any) {
    console.log(this.carousel);
    this.carousel?.navForward(event);
  }


  async getExhbitiorByID(exhiID: any, lang: any) {
    const response = await this.app.apiService.getExhibitorById(exhiID, lang);
    this.exhibitor = response;
    console.log("Exhibitor", this.exhibitor)

    this.dividerColor = this.exhibitor?.CodeColor || '#3f3f3f';
    console.log("this.exhibitor", this.exhibitor)
    
    for (let i = this.tagsMaxLenght; i < this.exhibitor.CategoriesLabels?.length; i++) {
      this.notDisplayedTags.push(this.exhibitor.CategoriesLabels[i]);
      console.log("this.notDisplayedTags", this.exhibitor?.CategoriesLabels[i])
    }

    // setTimeout(() => {
      for (let i = this.tagsMaxLenght; i < this.exhibitor?.CategoriesLabels?.length; i++) {
        this.notDisplayedTags.push(this.exhibitor?.CategoriesLabels[i]);
      }
      console.log("this.notDisplayedTags", this.notDisplayedTags)
    // }, 300);

    this.mappedExhibitorEvent = this.exhibitor?.Events;
    console.log("🚀 >>>  this.mappedExhibitorEvent:", this.mappedExhibitorEvent)
  
  }

  GoToMap(location: any) {
    this.sharedService.goToExhiMap(location)
  }

  getLastOrFirstElement() {
    this.isFirstElem = this.exhibitorStore.isFirstExhibitor(this.exhibitorID);
    this.isLastElem = this.exhibitorStore.isLastExhibitor(this.exhibitorID);
  }

}
