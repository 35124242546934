export class UploadMediaInfo
{
    Rotation: number;
    ViewOrder: number;
    IsModified: boolean;

    /**
     *
     */
    constructor(rotation: number, isModified: boolean, viewOrder: number = 1) {
        this.Rotation = rotation;
        this.ViewOrder = viewOrder;
        this.IsModified = isModified;
    }
}

export class ConferenceUploadMediaInfo
{
    LogoUploadMediaInfo: UploadMediaInfo;
    PhotoUploadMediaInfo: UploadMediaInfo;

    /**
     *
     */
    constructor(logoUploadMediaInfo: UploadMediaInfo, pogoUploadMediaInfo: UploadMediaInfo) {
        this.LogoUploadMediaInfo = logoUploadMediaInfo;
        this.PhotoUploadMediaInfo = pogoUploadMediaInfo;
    }
}


export class NewsUploadMediaInfo
{
    FileUploadMediaInfo: UploadMediaInfo;
    PhotoUploadMediaInfo: UploadMediaInfo;

    /**
     *
     */
    constructor(fileUploadMediaInfo: UploadMediaInfo, photoUploadMediaInfo: UploadMediaInfo) {
        this.FileUploadMediaInfo = fileUploadMediaInfo;
        this.PhotoUploadMediaInfo = photoUploadMediaInfo;
    }
}

export class AwardsUploadMediaInfo{
    FileUploadMediaInfo: UploadMediaInfo;
    PhotoUploadMediaInfo: UploadMediaInfo;

    constructor(fileUploadInfo :  UploadMediaInfo, photoUploadMediaInfo : UploadMediaInfo){
        this.FileUploadMediaInfo = fileUploadInfo
        this.PhotoUploadMediaInfo = photoUploadMediaInfo
    }
}

export class NewsDocUploadMediaInfo
{
    PhotoFrUploadMediaInfo: UploadMediaInfo;
    PhotoEnUploadMediaInfo: UploadMediaInfo;
    PhotoEsUploadMediaInfo: UploadMediaInfo;

    /**
     *
     */
    constructor(photoFrUploadMediaInfo: UploadMediaInfo, photoEnUploadMediaInfo: UploadMediaInfo, photoEsUploadMediaInfo: UploadMediaInfo) {
        this.PhotoFrUploadMediaInfo = photoFrUploadMediaInfo;
        this.PhotoEnUploadMediaInfo = photoEnUploadMediaInfo;
        this.PhotoEsUploadMediaInfo = photoEsUploadMediaInfo;
    }
}

