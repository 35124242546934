import { Injectable } from '@angular/core';
import { GroupSearchLocalStorageKey } from '../consts/utils';

@Injectable({
    providedIn: 'root'
})
export class GroupSearchHelperService {
    private _fastSearchKeyword: any;
    get fastSearchKeyword(): any {
        this.extractFromLocalStorage();
        return this._fastSearchKeyword;
    }
    set fastSearchKeyword(value: any) {
        this._fastSearchKeyword = value;
        this.saveToLocalStorage();
    }

    constructor() { }

    private saveToLocalStorage(): void {
        let searchModel: any =
        {
            'fastSearchKeyword': this._fastSearchKeyword
        };

        localStorage.setItem(GroupSearchLocalStorageKey, JSON.stringify(searchModel));
    }

    private extractFromLocalStorage(): void {
        let searchModelJson = localStorage.getItem(GroupSearchLocalStorageKey);
        if (searchModelJson && searchModelJson != '') {
            let searchModel: any = JSON.parse(searchModelJson);
            this._fastSearchKeyword = searchModel['fastSearchKeyword'];
        }
        else {
            this._fastSearchKeyword = '';
        }
    }
}
