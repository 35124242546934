import { Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Menu } from 'primeng/menu';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { FolderSearchHelperService } from '../services/folder-search-helper.service';
import { DatePipe } from '@angular/common';
import { cloneDeep } from 'lodash';
import { StatusType } from '../enums/status-type';
import { CategoryType } from '../enums/category-type';
import { hasAnyNonNullProperty } from '../consts/utils';
import { FolderStatus } from '../enums/folder-status';
import { SearchVmDo } from '../models/search-VmDo';
import { RoleCode } from '../enums/role-code';
import { User } from '../models/user';
import { StatusCode } from '../enums/status-code';
import { RoleType } from '../enums/role-type';
import { DetailRoleType } from '../enums/detail-role-type';
import { ExtendedCountries } from '../enums/extended-countries';
import { Languages } from '../enums/language';

@Component({
  selector: 'app-gestion-vm-manage-do',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './gestion-vm-manage-do.component.html',
  styleUrls: ['./gestion-vm-manage-do.component.less']
})
export class GestionVmManageDoComponent implements OnDestroy {
  //#region local properties
  //subscriptions
  private vmDosExportSubscription?: Subscription;
  private companyAutocompleteSubscription?: Subscription;

  @ViewChild('menuAction') menuAction!: Menu;
  @ViewChild('dtVmDo') dtVmDo!: Table;
  @ViewChild('dvVmDo') dvVmDo!: DataView;
  @ViewChild("fileInput") fileInput?: ElementRef

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  layout: any = 'grid';
  groupedActions: any;

  vmGroupExternalImportActive: any;
  vmGroupSectorType: any;
  vmDetailGroupNumberPeople: any;

  showTable: boolean = true;
  rangeDates: Date[] = [];
  // search: any = new SearchVmDo();
  search: any;
  showAdvancedSearch: boolean = false;
  fastFiltersFolder: any = ['GroupNameEN', 'TypeCategoryID', 'GroupCountries', 'DetailGroupNumberPeople', 'GroupExternalImportActive', 'GroupSectorType'];
  vmManageDos: any = [];
  countr: any = [];
  roles: any[] = [];

  status: any = [];
  groupExternalImportActive: any = [];
  countries: any = [];
  typeCategory: any = [];
  detailGroupNumberPeople: any = [];
  groupSectorType: any = [];
  groupNameEn: any = [];

  externalImportActive: any = [];
  typeCategoryID: any = [];

  stats: any = [];
  users: any = [];

  categories: any = [];


  colsFolders: any = [];
  exportcolsFolders: any = [];
  items: any = [];
  selectedVmDo: any = [];
  selectedAction: any;
  _selectedColumns: any = [];
  selectedEtat: any = [];


  fastSearchFolderKeyword: string = '';

  //admin OD
  oaUsers: any[] = [];
  ozUsers: any[] = [];
  progUsers: any[] = [];

  detailRoleType = DetailRoleType;
  msgAlertImport: string = ""
  showModal : boolean = false
  //#endregion

  constructor(
    public app: AppComponent,
    private messageService: MessageService,
    private folderSearchHelperService: FolderSearchHelperService,
    private datePipe: DatePipe,
  ) { }

  ngOnDestroy(): void {
    this.unsubscribeActiveSubscriptions();
  }

  onchangeSelect(_item: any) {
    if (!_item) return;
    //console.log("onchangeSelect", _item);
  }

  async onchangeStatus(_folder: any) {
    console.log("onchangeStatus folder ==> ", _folder);
    if (!_folder.selectedStatus) return;

    let objStatus: any = {
      StatusID: _folder.selectedStatus.StatusID,
      FolderID: _folder.FolderID
    }

    console.log("objStatus ==> ", objStatus);

    try {
      const response = await this.app.apiService.changestatus(objStatus);
      console.log("apiService.changestatus ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
        return;
      }

      this.getstats();
      this.searchVmDo(this.search);

    } catch (error) {
      console.error(error);
    }
  }

  filterFast() {
    this.folderSearchHelperService.fastSearchKeyword = this.fastSearchFolderKeyword;
    console.log("_value", this.fastSearchFolderKeyword);
    // this.dvVmDo.filter(_value, 'contains');
    this.dtVmDo.filterGlobal(this.fastSearchFolderKeyword, 'contains');
  }

  ngOnInit() {
    //get privious advanced search value before routing to other routes
    this.search = cloneDeep(this.folderSearchHelperService.advancedSearch);
    this.fastSearchFolderKeyword = this.folderSearchHelperService.fastSearchKeyword;

    let user = JSON.parse(sessionStorage.getItem('userData')!);
    console.log(user);
    if (!user) {
      this.app.router.navigate(['/login']);
      return;
    }

    // this.colsFolders = this.app.translate.("racine.generalites.columns");
    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
        console.log("resG", resG);
        this.vmGroupExternalImportActive = resG.vmGroupExternalImportActive;
        this.vmDetailGroupNumberPeople = resG.vmDetailGroupNumberPeople;

        this.items = resG.actionsRow;

        this.items.forEach((itemMenu: any) => {
          itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
        });

        this.colsFolders = resG.colsVmManagedDo;

        this._selectedColumns = this.colsFolders;
        this.exportcolsFolders = this._selectedColumns.map((col: any) => ({ title: col.header, dataKey: col.header }));

        this.getstats();
        this.getStatus();
        this.getCountries();
        this.searchVmDo(this.search, true);
        this.getCategories();
        this.getRoles();
        this.getalloausers();
        this.getallozusers();
        this.getallprogusers();
      });
  }

  onChangeLink(od: any, userID: any, type: string): void {
    let request: any = {
      UserID: userID,
      GroupID: od.GroupID,
      TypeRoleOD: type
    };
    console.log('yes ', userID)

    this.createODLink(request);
  }

  toggleLayout() {
    this.showTable = !this.showTable;
  }

  toggleMenu(event: any, folderId: number, items: any) {
    this.menuAction.toggle(event);

    console.log("Folder ID:", folderId);
    console.log("items:", items);

    items.forEach((itemMenu: any) => {
      itemMenu.folderIdRow = folderId;
      itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

      if (itemMenu.ActionID == 1) {
        itemMenu.command = (event: any) => {
          this.app.sharedTools.redirectTo('/detail-vm-exposant/' + event.item.folderIdRow.toString());
        }
      }
      if (itemMenu.ActionID == 2) {
        itemMenu.command = (event: any) => {
          this.consultDirectExhibitor(event.item.folderIdRow);
        }
      }
    });
  }


  set selectedColumns(val: any[]) {
    // ==> restore original order
    this._selectedColumns = this.colsFolders.filter((col: any) => val.includes(col));
  }

  getstats() {
    this.app.apiService.getstats().subscribe((data: any) => {
      console.log("getstats data ==>", data);
      this.stats = cloneDeep(data);
    })
  }

  getStatus() {
    this.app.apiService.getStatus().subscribe((data: any) => {
      console.log("getStatus ==> ", data);
      this.status = cloneDeep(data);

      this.status = this.status.filter((s: any) => s.StatusType === StatusType.F18OD)
    });
  }

  getCountries() {
    this.app.apiService.getExtendedCountries(ExtendedCountries.vm).subscribe((data: any) => {
      console.log("getCountries ==> ", data);
      this.countries = data; //cloneDeep(data);
    });
  }

  async getCategories() {
    try {
      const response = await this.app.apiService.getCategories();
      let categoriesIni = cloneDeep(response);

      this.typeCategory = cloneDeep(categoriesIni.filter((cat: any) => {
        return cat.CategoryType === CategoryType.TypeDO;
      }));

      this.groupSectorType = cloneDeep(categoriesIni.filter((cat: any) => {
        return cat.CategoryType === CategoryType.SectorDO;
      }));

    } catch (error) {
      console.error(error);
    }
  }

  formatDate(date: any) {
    return
  }

  async searchVmDo(search: any, thenFilterFast: boolean = false) {
    //save search to retrieve it when coming back to BO after redirect from BO
    this.folderSearchHelperService.advancedSearch = cloneDeep(search);

    console.log("search ==> ", search);
    let searchTemp: any = cloneDeep(search);

    if (searchTemp.selectedStatus) searchTemp.StatusID = searchTemp.selectedStatus.StatusID;
    if (searchTemp.selectedCountry) searchTemp.GroupCountries = [searchTemp.selectedCountry.CountryID];
    if (searchTemp.selectedTypeCategory) searchTemp.TypeCategoryID = searchTemp.selectedTypeCategory.CategoryID;
    if (searchTemp.selectedDetailGroupNumberPeople) searchTemp.DetailGroupNumberPeople = searchTemp.selectedDetailGroupNumberPeople.DetailGroupNumberPeople;
    if (searchTemp.selectedGroupSectorType) searchTemp.GroupSectorType = searchTemp.selectedGroupSectorType.CategoryID;
    if (searchTemp.selectedUsers) searchTemp.UserID = [searchTemp.selectedUsers];



    if (searchTemp.selectedCategory) delete searchTemp.selectedCountry;
    if (searchTemp.selectedStatus) delete searchTemp.selectedStatus;
    if (searchTemp.selectedCountry) delete searchTemp.selectedCountry;
    if (searchTemp.selectedTypeCategory) delete searchTemp.selectedTypeCategory;
    if (searchTemp.selectedDetailGroupNumberPeople) delete searchTemp.selectedDetailGroupNumberPeople;
    if (searchTemp.selectedGroupSectorType) delete searchTemp.selectedGroupSectorType;
    if (searchTemp.selectedUsers) delete searchTemp.selectedUsers;

    console.log("searchTemp1* ==> ", searchTemp);

    searchTemp[this.app.sharedTools.getLangPropertyName('GroupName')] = searchTemp.GroupName;
    delete searchTemp.GroupName;

    delete searchTemp.selectedStatus;
    delete searchTemp.selectedCountry;
    delete searchTemp.selectedTypeCategory;
    delete searchTemp.selectedDetailGroupNumberPeople;
    delete searchTemp.selectedGroupSectorType;
    delete searchTemp.selectedUsers;

    console.log("searchTemp ==> ", searchTemp);

    try {
      const response = await this.app.apiService.searchVmDo(searchTemp);
      console.log("searchVmDo ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/'); }, () => { })
        return;
      }

      // this.vmDos = cloneDeep(response.filter((fld: any) => { return fld.UserID != 8 }))//response; //cloneDeep(response); 
      this.vmManageDos = cloneDeep(response)//response; //cloneDeep(response); 

      setTimeout(() => {
        this.vmManageDos.forEach((folder: any) => {

          folder.CreationDateFormat! = this.datePipe.transform(folder.CreationDate, 'dd/MM/yyyy HH:mm:ss');
          if (this.fastFiltersFolder.indexOf('CreationDateFormat') == -1) this.fastFiltersFolder.push("CreationDateFormat");

          folder.selectedStatus = this.status.filter((etat: any) => { return etat.StatusID == folder.StatusID })[0];
          folder.selectedCountry = this.countries.filter((country: any) => { return country.CountryID == folder.CountryID })[0];
          folder.selectedTypeCategory = this.typeCategory.filter((typCat: any) => { return typCat.TypeCategoryID == folder.TypeCategoryID })[0];
          folder.selectedDetailGroupNumberPeople = this.detailGroupNumberPeople.filter((detGrp: any) => { return detGrp.DetailGroupNumberPeople == folder.DetailGroupNumberPeople })[0];
          folder.selectedGroupSectorType = this.groupSectorType.filter((grpSec: any) => { return grpSec.GroupSectorType == folder.GroupSectorType })[0];
          folder.selectedUsers = this.users.filter((user: any) => { return user.UserID == folder.UserID })[0];

          //console.log("folder ==> ", folder);

          // ==> On met ces champs dans la liste des filtres rapides. TODO: comment chercher dans les LinkedFolders
          if (folder.selectedStatus) {
            this.app.sharedTools.getLabelSwitchLang(folder.selectedStatus);
            if (this.fastFiltersFolder.indexOf('selectedStatus.label') == -1) this.fastFiltersFolder.push("selectedStatus.label");
          }
          if (folder.selectedCountry) {
            this.app.sharedTools.getLabelSwitchLang(folder.selectedCountry);
            if (this.fastFiltersFolder.indexOf('selectedCountry.label') == -1) this.fastFiltersFolder.push("selectedCountry.label");
          } if (this.fastFiltersFolder.indexOf('selectedTypeStand.label') == -1) this.fastFiltersFolder.push("selectedTypeStand.label");

        });

        console.log(">>> VmDO : ", this.vmManageDos);

        if (thenFilterFast) {
          this.filterFast();

          if (hasAnyNonNullProperty(this.search)) {
            console.log("hasAnyNonNullProperty", hasAnyNonNullProperty(this.search))
            this.initAdvancedSearch();
            this.showAdvancedSearch = true;
          }
        }
      }, 100);

    } catch (error) {
      console.error(error);
    }
  }

  clear(table: Table) {
    table.clear();
  }

  clearSearch() {
    console.log("clearSearch ==>");
    this.rangeDates = [];
    this.search = new SearchVmDo();
    this.searchVmDo(this.search);
    this.clearFastSearch();
  }

  clearFastSearch(): void {
    this.fastSearchFolderKeyword = '';
    this.filterFast();
  }

  onRowSelect(event: any) {
    console.log("event.data==>", event.data);
    console.log("selectedVmDo==>", this.selectedVmDo);
    //this.messageService.add({ severity: 'info', summary: 'Folder Selected', detail: event.data.FolderID });
  }

  goToDetail(_folderId: any) {
    console.log("[gotToDetail] ==> ", _folderId);
    this.app.sharedTools.redirectTo('/detail-vm-do/' + _folderId.toString());
  }

  exportExcel() {
    console.log('exportExcel !!!');

    // import('xlsx').then((xlsx) => {
    //     const worksheet = xlsx.utils.json_to_sheet(this.products);
    //     const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    //     const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    //     this.saveAsExcelFile(excelBuffer, 'products');
    // });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    console.log('saveAsExcelFile !!!');

    // let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    // let EXCEL_EXTENSION = '.xlsx';
    // const data: Blob = new Blob([buffer], {
    //     type: EXCEL_TYPE
    // });
    // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  deleteFoldersSelected(_foldersSelected: any) {
    console.log('deleteFoldersSelected ==> ', _foldersSelected)
    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteFolder'), 'AUTRE', 'confirm', () => {
      this.deleteFolders(_foldersSelected);
    }, () => {
      //this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
    });
  }

  deleteFolders(_foldersSelected: any) {
    console.log('deleteFolders TODO ==> ', _foldersSelected);
    //this.messageService.add({ severity: 'success', summary: 'Folder deleted successfully', detail: "You have accepted" });
  }

  onchangeSelectedAction(selectedAction: any) {
    console.log(selectedAction)
  }


  getActionsMenuByFolder(folder: any): any {
    if (this.showConsultDirectExhibitorOption(folder)) {
      return this.items;
    }
    else {
      //ActionID = 2 is for View direct exhibitor Action
      return this.items.filter((item: any) => item.ActionID != 2);
    }
  }

  //#region private methods
  private unsubscribeActiveSubscriptions(): void {
    if (this.vmDosExportSubscription) {
      this.vmDosExportSubscription.unsubscribe();
    }

    if (this.companyAutocompleteSubscription) {
      this.companyAutocompleteSubscription.unsubscribe();
    }
  }


  private showConsultDirectExhibitorOption(folder: any): boolean {
    if (folder?.StatusID) {
      return folder.StatusID == FolderStatus.InProgress
        || folder.StatusID == FolderStatus.WaitingSign
        || folder.StatusID == FolderStatus.Signed
        || folder.StatusID == FolderStatus.Validated
    }

    return false;
  }

  private consultDirectExhibitor(folderId: any): void {
    // ==> new code
    this.app.apiService.getUrlAutologin(folderId).subscribe({
      next: (data: any) => {
        console.log('[getUrlAutologin] API =>', data);

        if (data) {
          if (data.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
            return;
          }
          window.open(data);
        }
      },
      error: (error: any) => {
        console.log(error);
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_ERROR'), 'KO', "alert", () => { }, () => { });
        return;
      }
    });
  }

  private initAdvancedSearch(): void {
    this.search = cloneDeep(this.folderSearchHelperService.advancedSearch);
    let findSelectedStatus = this.status.find((c: any) => c.StatusID === this.search.selectedStatus?.StatusID);
    let findSelectedCountry = this.countries.find((c: any) => c.CountryID === this.search.selectedCountry?.CountryID);

    let findSelectedTypeCategory = this.typeCategory.find((c: any) => c.TypeCategoryID === this.search.selectedTypeCategory?.TypeCategoryID);
    let findSelectedDetailGroupNumberPeople = this.detailGroupNumberPeople.find((c: any) => c.DetailGroupNumberPeople === this.search.selectedDetailGroupNumberPeople?.DetailGroupNumberPeople);
    let findSelectedGroupSectorType = this.groupSectorType.find((c: any) => c.GroupSectorType === this.search.selectedGroupSectorType?.GroupSectorType);
    let findSelectedUsers = this.users.find((c: any) => c.UserID === this.search.selectedUsers?.UserID);

    let findGroupNameEn = this.groupNameEn.find((c: any) => c.GroupNameEN === this.search.GroupNameEN);

    this.search.selectedStatus = findSelectedStatus;
    this.search.selectedCountry = findSelectedCountry;

    this.search.selectedStatus = findSelectedStatus;
    this.search.selectedUserFullName = findSelectedUsers;
    this.search.selectedCountry = findSelectedCountry;
    this.search.selectedTypeCategory = findSelectedTypeCategory;
    this.search.selectedDetailGroupNumberPeople = findSelectedDetailGroupNumberPeople;
    this.search.selectedGroupSectorType = findSelectedGroupSectorType;
    this.search.groupNameEn = findGroupNameEn;

    this.rangeDates = [];

    if (this.search.SubmissionDateStart) {
      this.rangeDates.push(new Date(this.search.SubmissionDateStart));
    }

    if (this.search.SubmissionDateEnd) {
      this.rangeDates.push(new Date(this.search.SubmissionDateEnd));
    }
  }

  getRoles() {
    this.app.apiService.getRoles().subscribe((data: any) => {
      console.log("getRoles ==> ", data);
      this.roles = data; //cloneDeep(data);
      this.roles = this.roles.filter(r => r.RoleType === RoleType.F18User &&
        (r.RoleCode === RoleCode.OZ
          || r.RoleCode === RoleCode.OA
          || r.RoleCode === RoleCode.Programmer)
      );
      let rolesIds: string = this.roles.filter(r => r.RoleType === RoleType.F18User &&
        (r.RoleCode === RoleCode.OZ
          || r.RoleCode === RoleCode.OA
          || r.RoleCode === RoleCode.Programmer)
      ).map((r: any) => { return r.RoleID }).join(',');

      let searchOAOZPROGUersRequest = {
        RoleID: rolesIds
      }

      this.searchUsers(searchOAOZPROGUersRequest);

    });
  }

  private async searchUsers(search: any) {
    try {
      const response = await this.app.apiService.adminUsersSearch(search);
      console.log("searchUsers==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/users'); }, () => { })
        return;
      }

      this.users = cloneDeep(response)
      console.log("users", this.users);
      this.users.forEach((user: any) => {
        console.log("-> User : ", user);
      });

      this.users.map((user: any) => {
        user.FullName = user.LastName + ' ' + user.FirstName
        return;
      })
      console.log("after users", this.users);

    } catch (error) {
      console.error(error);
    }
  }

  private async getalloausers() {
    try {
      const response = await this.app.apiService.getalloausers();

      this.oaUsers = cloneDeep(response);

      console.log('get getalloausers ===> ', this.oaUsers);

    } catch (error) {
      console.error(error);
    }
  }

  private async getallozusers() {
    try {
      const response = await this.app.apiService.getallozusers();

      this.ozUsers = cloneDeep(response);

      console.log('get getallozusers ===> ', this.ozUsers);

    } catch (error) {
      console.error(error);
    }
  }

  private async getallprogusers() {
    try {
      const response = await this.app.apiService.getallprogusers();

      this.progUsers = cloneDeep(response);

      console.log('get getallprogusers ===> ', this.progUsers);

    } catch (error) {
      console.error(error);
    }
  }

  private async createODLink(request: any) {
    try {
      console.log('createODLink request ===> ', request);
      const response = await this.app.apiService.createODLink(request);

      console.log('createODLink response ===> ', response);

    } catch (error) {
      console.error(error);
    }
  }

  getColorStatus(status: any) {
    let classColor = "";
    if (status) {
      switch (status.Code) {
        case StatusCode.SCHEDULED_OD:
          classColor = "color-light-blue"
          break;

        case StatusCode.DATE_CONFIRMED_OD:
          classColor = "color-lime-green"
          break;

        case StatusCode.CANCELLED_OD:
          classColor = "color-red"
          break;

        case StatusCode.ARCHIVED_OD:
          classColor = "color-light-grey"
          break;

        default:
          classColor = "color-dark-blue"
          break;
      }
    }
    return classColor;
  }

  importCsv(event: any) {
    let selectedFileExtension = event.target.files[0]?.name?.split('.')[1]
    if (selectedFileExtension == "xlsx" || selectedFileExtension == "xls" || selectedFileExtension == "csv") {
      this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmImportOz'),
        'AUTRE', 'confirm'
        , () => {
          const file: File = event.target.files[0]
          this.app.apiService.uploadOzCsv(file).subscribe(
            data => {
              if (data && data?.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                return;
              }
              else {
                this.msgAlertImport = data?.Data?.InnerHTML_EN ?? ""
                if (this.app.translate.currentLang == Languages.French) {
                  this.msgAlertImport = data?.Data?.InnerHTML_FR ?? ""
                } else if (this.app.translate.currentLang == Languages.Spanish) {
                  this.msgAlertImport = data?.Data?.InnerHTML_ES ?? ""
                }
                this.showModal = true
              }
            }
          )
        }
        , () => {
          this.resetFileInput()
        });

    } else {
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorInvalidFileFormat'), 'KO', "alert", () => { }, () => { });
      return;
    }

  }

  resetFileInput() {
    this.showModal = false
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = ""
    }
  }

  //#endregion
}
