import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { firstValueFrom } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { FormCode } from 'src/app/enums/form-code';
import { StatusCode } from 'src/app/enums/status-code';
import { StatusType } from 'src/app/enums/status-type';
import { AwardsRoutes, AwardsRoutesQueryParamsNames, AwardsRoutesStep } from '../awards.routes';

@Component({
    selector: 'app-awards-recap-page',
    templateUrl: './awards-recap-page.component.html',
    styleUrls: ['./awards-recap-page.component.less']
})
export class AwardsRecapPageComponent {

    frmData: any
    awardData: any
    cpRecapCGV: any
    gcvAccepted = false
    awardId: any;
    status: any[] = [];
    toBeValidateStatus: any;
    toBeSubmitStatus: any;
    isEditMode: boolean = false;
    award: any;
    gotocgv: boolean = false;
    state: any;
    folderGuid: any
    get returnUrl(): string{
        return this.isEditMode 
        ? `/${AwardsRoutes.description}?${AwardsRoutesQueryParamsNames.awardId}=${this.awardId}`
        : `/${AwardsRoutes.welcome}?${AwardsRoutesQueryParamsNames.folderGuid}=${this.folderGuid}`;
    }
    constructor(public app: AppComponent, private router: Router) { }

    async ngOnInit() {
        await this.getStatus()
        await this.getAwardFormInfo()
        this.app.route.queryParams
            .subscribe(async params => {
                console.log('[params]', params);
                if (params && params[AwardsRoutesQueryParamsNames.awardId]) {
                    this.awardId = params[AwardsRoutesQueryParamsNames.awardId];
                    await this.getAward(this.awardId);
                }
                else {
                    this.isEditMode = false
                }
            });

        let formData = JSON.parse(sessionStorage.getItem("award-form") ?? "")
        this.folderGuid = formData?.FolderGuid
        this.app.sharedTools.getJSON('general_data').subscribe(
            async resG => {
                this.cpRecapCGV = resG.awardRecapCGV;
            })
    }

    onPreviousStep() {
        if(this.isEditMode){
            let queryParams: any = {
                [AwardsRoutesQueryParamsNames.awardId]: this.awardId
            };

            console.log('route ', AwardsRoutes.description, queryParams)
            this.app.router.navigate([AwardsRoutes.description], {queryParams: queryParams});
        }
        else{
            let queryParams: any = {
                [AwardsRoutesQueryParamsNames.folderGuid]: this.folderGuid
            };
            console.log('route ', AwardsRoutes.welcome, queryParams);
            this.app.router.navigate([AwardsRoutes.welcome], {queryParams: queryParams});

        }
    }

    async getAwardFormInfo() {
        let request = { frmType: "" }
        request.frmType = FormCode.FRM_AWARD
        let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
        if (data != undefined && data.Id != undefined) {
            this.frmData = data
        }
    }

    OpenPDF() {
        let filePath: string = this.app.sharedTools.getPropertySwitchLang(this.cpRecapCGV, 'FilePath');
        window.open(filePath, "_blank");
    }

    async getStatus() {
        this.status = await firstValueFrom(this.app.apiService.getStatus())
        console.log("this.status", this.status)
    }

    async getAward(solId: any) {
        let response = await this.app.apiService.getAwardSolutionById(solId);
        if(!response || response.Result){
            return;
        }

        this.awardData = response;
        this.gcvAccepted = this.awardData.CGVaccepted;
        this.isEditMode = this.awardData.Status.Code === StatusCode.DRAFT_FICHE || this.awardData.Status.Code === StatusCode.TO_BE_SUBMIT;
        console.log('this.award resp: ', response);
    }
    onCancelStep(): void {
        this.app.sharedTools.goTo(AwardsRoutes.welcome);
    }
    async onSubmit() {
        this.awardData.CGVaccepted = this.gcvAccepted;
        this.awardData.SolutionLastStep = AwardsRoutesStep.recap;
        let response = await this.app.apiService.updateAwardSolution(this.awardData)
        if (response && response.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
            }, () => {
            })
            return;
        }
        this.UpdateAwardStatus(StatusCode.TO_BE_SUBMIT);
        this.app.confirm.confirmDialog(""
            , this.app.translate.instant("racine.successMsg.successAwardSubmitted")
            , 'OK'
            , "alert"
            , () => { }
            , () => { });
    }

    async publishAwardForm() {
        this.awardData.CGVaccepted = this.gcvAccepted;
        this.awardData.SolutionLastStep = AwardsRoutesStep.recap;

        this.app.confirm.confirmDialog(''
            , this.app.translate.instant('racine.confirmMsg.confirm-submit-award-form')
            , 'AUTRE'
            , 'confirm'
            , () => {
                this.UpdateAwardStatus(StatusCode.TOVALIDATE_FICHE, true);
            }
            , () => { });
    }

    async UpdateAwardStatus(statusCode: string, submitted: boolean = false): Promise<any> {

        try {
            console.log("this.awardData", this.awardData)
            let status = this.status.find((sts: any) => {
                return sts.Code == statusCode
                    && sts.StatusType == StatusType.Award_Jury
            })
            console.log("status", status)
            if (status) {
                this.awardData.Status = status
                this.awardData.Submitted = submitted
                this.awardData.CurrentLanguage = this.app.translate.currentLang.toLocaleUpperCase()
                const response = await this.app.apiService.publishAwardSolution(this.awardData);
                if (response && response.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                    }, () => {
                    })
                    return;
                }

                setTimeout(() => {
                    this.app.confirm.confirmDialog(""
                        , this.app.translate.instant("racine.successMsg.successAwardSubmitted")
                        , 'OK'
                        , "alert"
                        , () => {
                            if (submitted) {
                                this.router.navigate([AwardsRoutes.welcome], {
                                    queryParams: {
                                        [AwardsRoutesQueryParamsNames.folderGuid]: this.folderGuid
                                    }
                                })
                            }
                        }
                        , () => { });
                }, 500);

            }

        } catch (error) {
            console.error(error);
        }
    }

    onPrevious(): void{
        if(this.isEditMode){
            let queryParams: any = {
                [AwardsRoutesQueryParamsNames.awardId]: this.awardId
            };

            this.app.router.navigate([AwardsRoutes.description], queryParams);
        }
        else{
            let queryParams: any = {
                [AwardsRoutesQueryParamsNames.folderGuid]: this.folderGuid
            };
            console.log(queryParams);
            this.app.router.navigate([AwardsRoutes.welcome], queryParams);

        }
    }
}
