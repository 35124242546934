import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { cloneDeep, forEach } from 'lodash';
import * as CryptoJS from 'crypto-js';
import { Subscription, retry } from 'rxjs';
import { CartItem } from '../models/cart-item';
import { FullCart } from '../models/full-cart';
import { ProductsGroup } from '../models/products-group';
import { CartGroup } from '../models/cart-group';
import { Product } from '../models/product';
import { TreeNode } from 'primeng/api';
import { Languages } from '../enums/language';

@Component({
    selector: 'app-content-recap',
    templateUrl: './content-recap.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./content-recap.component.less']
})
export class ContentRecapComponent implements OnInit, OnDestroy {
    private getCartSubscription: Subscription | undefined;
    private getProductsgroupsSubscription?: Subscription;
    private onLangChangeSubscription?: Subscription;

    //current Cart
    cart?: FullCart;

    //groups
    productsGroups: ProductsGroup[] = [];

    contacts: any = [];
    countries: any = [];
    civilities: any = [];
    languages: any = [];
    functions: any = [];
    exposant: any = null;
    paths = location.pathname.split('/');
    currentPath: any = null;
    folderId: any = null;
    user: any = null;

    categories: any = [];
    tree: any = {};
    orderedCategories!: TreeNode[]; // Initialize as null

    otherActivityCategories: any[] = [];
    lienAutologin: string = "";

    constructor(
        public app: AppComponent,
    ) { }
    ngOnDestroy(): void {
        this.unsubscribeActiveSubscriptions();
    }

    ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log(this.user);
        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }

        //get otherActivityCategories from general_data json
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                this.otherActivityCategories = resG.otherActivityCategories;
            });

        this.currentPath = this.paths[1];

        var urlId: any = this.app.router.url.split('/').pop();
        // console.log("urlId", urlId);
        // var decodedUrlId = decodeURIComponent(urlId);
        // console.log("decodedUrlId", decodedUrlId);
        // var bytes = CryptoJS.AES.decrypt(decodedUrlId, 'secret key 123');
        // console.log("bytes", bytes);
        // var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        // console.log("decryptedData", decryptedData);
        // var id = decryptedData.split("=");
        this.folderId = (this.currentPath == 'recapitulatif') ? this.user.FolderID : parseInt(urlId);
        console.log("this.folderId ==> ", this.folderId);

        this.getCountries();
        this.getCivilities();
        this.getLanguages();
        this.getFunctions();
        this.getCart();

        // TODO @why: commented by @sana, @whatToDo: evolution
        // TODO @why: to review par Hassen
        if (this.folderId) {
            if (this.currentPath != 'recapitulatif') this.getLienAutologin(this.folderId);
            this.getFolder(this.folderId);
        }

        setTimeout(() => {
            this.handleLanguageChange();
        }, 100);
    }

    getCivilities() {
        this.app.apiService.getCivilities().subscribe((data: any) => {
            console.log("getCivilities ==> ", data);
            this.civilities = cloneDeep(data);
        });
    }

    getCountries() {
        this.app.apiService.getCountries().subscribe((data: any) => {
            console.log("getCountries ==> ", data);
            this.countries = cloneDeep(data);
        });
    }

    getLanguages() {
        this.app.apiService.getLanguages().subscribe((data: any) => {
            console.log("getLanguages ==> ", data);
            this.languages = cloneDeep(data);
        });
    }

    getFunctions() {
        this.app.apiService.getFunctions().subscribe((data: any) => {
            console.log("getFunctions ==> ", data);
            this.functions = cloneDeep(data);
        });
    }

    onEdit() {
        console.log("on edit hello")
        if (this.currentPath === 'recapitulatif') {
            //recapitulatif === > from FO ===> just redirect to 'infos-exposant' (step 1)
            this.app.sharedTools.goTo('/infos-exposant');
        }
        else {
            //else === > from BO ===> redirect to 'infos-exposant' (step 1) and pass folderID parameter
            let params = { folderID: this.folderId }
            console.log(params);
            this.app.sharedTools.redirectToWithParameters('/infos-exposant', params);
        }
    }

    onEditIndirectExhibitor(indirectExhibitor: any) {
        console.log("on edit hello");
        //pass indirectFolderID parameter to identify the linked folder
        let params: any = { indirectFolderID: indirectExhibitor.FolderID };

        if (this.currentPath === 'detail-dp') {
            //detail-dp === > from BO ===> add folderID parameter
            params['folderID'] = this.folderId;
        }

        console.log(params);
        //redirect to 'exposants-indirects' (step 2) and pass params
        this.app.sharedTools.redirectToWithParameters('/exposants-indirects', params);

    }

    getContactByCategoryID(CategoryID: any, arrayContacts: any) {
        let newArrContacts = arrayContacts.filter((ctc: any) => {
            return ctc.Categories.filter((cat: any) => {
                return cat.CategoryID == CategoryID
            }).length > 0;
        });

        //console.log("newArrContacts ====>", newArrContacts)
        return newArrContacts;
    }

    async getFolder(folderId: any) {

        try {
            const response = await this.app.apiService.getFolder(folderId);
            console.log("getFolder ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                }, () => {
                })
                return;
            }

            this.exposant = cloneDeep(response);
            console.log("this.exposant ==> ", response);

            this.getCategories();
        }
        catch (error) {
            console.error(error);
        }
    }

    cartHaveCartItem(): boolean {
        console.log('status', this.exposant.StatusID)
        console.log('cart', this.cart)
        if (this.cart
            && this.cart.CartGroups) {
            let atLeastOne = this.cart.CartGroups.find(c => c.CartItems && c.CartItems.length > 0);
            console.log(atLeastOne)
            if (atLeastOne) {
                return true;
            }
        }

        return false;
    }

    //#region private methods
    private getCart(): void {
        this.getCartSubscription = this.app.apiService
            .getFullCart(this.folderId).subscribe({
                next: (data: FullCart) => {
                    this.processGetFullCartSuccess(data);
                    this.getProductsGroups();
                },
                error: (error: any) => {
                    console.log(error);
                }
            })
    }

    private processGetFullCartSuccess(data: FullCart): void {
        //save response data to local property
        let newFullCart: FullCart = new FullCart();

        data.CartGroups = data.CartGroups?.map((cg: CartGroup) => {

            cg.CartItems = cg.CartItems?.map((ci: CartItem) => {
                let newCartItem: CartItem = new CartItem();
                Object.assign(newCartItem, ci);

                return newCartItem;
            });

            let newCartGroup: CartGroup = new CartGroup();
            Object.assign(newCartGroup, cg);

            return newCartGroup;
        });

        Object.assign(newFullCart, data);

        this.cart = newFullCart;
    }

    private unsubscribeActiveSubscriptions(): void {
        if (this.getCartSubscription) {
            this.getCartSubscription.unsubscribe();
        }

        if (this.getProductsgroupsSubscription) {
            this.getProductsgroupsSubscription.unsubscribe();
        }

        if (this.onLangChangeSubscription) {
            this.onLangChangeSubscription.unsubscribe();
        }
    }


    private getProductsGroups(): void {
        if (!this.productsGroups || this.productsGroups.length < 1) {
            this.getProductsgroupsSubscription = this.app.apiService.getProductsgroups().subscribe({
                next: (data: ProductsGroup[]) => {
                    this.processGetProductsGroupsSuccess(data);
                },
                error: (error: any) => {
                    console.log(error);
                },
            });
        }
    }

    private processGetProductsGroupsSuccess(data: ProductsGroup[]): void {
        //save response data to local property
        this.productsGroups = data.map(pg => {
            let newProductsGroup: ProductsGroup = new ProductsGroup();

            pg.Products = pg.Products?.map((p: Product) => {
                let newProduct: Product = new Product();
                Object.assign(newProduct, p);

                //initialize Product Quantity to Product MinQuantity
                if (!newProduct.Quantity) {
                    newProduct.Quantity = newProduct.MinQuantity ?? 0;
                }

                return newProduct;
            });

            Object.assign(newProductsGroup, pg);

            return newProductsGroup;
        });

        this.mapCartItemsRequiredPropertiesFromProducts();
    }

    private mapCartItemsRequiredPropertiesFromProducts(): void {
        this.cart?.CartGroups?.forEach((cg: CartGroup) => {
            let pg: ProductsGroup | undefined = this
                .productsGroups
                .find(pg => pg.GroupID === cg.ProductGroupID);

            if (pg && pg.Products && pg.Products.length > 0) {
                cg.LabelEN = pg.LabelEN;
                cg.LabelFR = pg.LabelFR;
                cg.LabelES = pg.LabelES;

                cg.isTypeSurface = (cg.ProductGroupID == 8) ? true : false;

                cg.CartItems?.forEach((ci: CartItem) => {
                    this.mapCartFromProduct(ci, pg?.Products);
                });
            }
        });
    }

    private mapCartFromProduct(ci: CartItem, products: Product[] | undefined): void {
        let prodcut: Product | undefined = products?.find(p => p.ProductID === ci.ProductID);
        if (prodcut) {
            ci.NameEN = prodcut.NameEN;
            ci.NameFR = prodcut.NameFR;
            ci.NameES = prodcut.NameES;
            ci.Price = prodcut.getPrice();
        }
    }

    private handleLanguageChange(): void {


        this.onLangChangeSubscription =
            this.app.translate.onLangChange
                .subscribe((lang: any) => {
                    let selectedCategories = this.exposant.Company.Categories;
                    this.orderedCategories = this.buildTree(selectedCategories, null);
                });
    }

    private buidlOrderedCategoriesTree(categories: any[]): TreeNode[] {
        //map les categories manquantes
        let requiredCategories = [...categories];

        this.categories.forEach((c: any) => {
            let findCategoryAsParent = categories
                .find((sc: any) => sc.ParentCategoryID && sc.ParentCategoryID === c.CategoryID);

            let findCategory = categories
                .find((sc: any) => sc.CategoryID && sc.CategoryID === c.CategoryID);

            if (findCategoryAsParent && !findCategory) {
                requiredCategories.push(c);
            }
        });
        //

        requiredCategories = requiredCategories.sort((a: any, b: any) => { return b.Code > a.Code ? -1 : 1; });

        let tree: any = {};
        let orderedCategories!: TreeNode[];

        requiredCategories.forEach((cat: any) => {
            const parentId = cat.ParentCategoryID;

            if (!tree[parentId]) {
                tree[parentId] = [];
            }
            tree[parentId].push(cat);
        });

        orderedCategories = this.buildTree(tree, null);


        return orderedCategories;

    }


    private buildTree(tree: any, parentId: any) {
        const children = tree[parentId] || [];

        return children.map((child: any) => {
            const node = {
                key: child.CategoryID,
                label: this.app.sharedTools.getLabelSwitchLang(child),
                icon: '',
                parent: parentId,
                expanded: false,
                children: this.buildTree(tree, child.CategoryID),
                data: child.Code
            };
            return node;
        });
    }

    private extractDirectExhibitorActivities(): void {
        let selectedCategories = this.exposant.Company.Categories;


        if (this.exposant?.Company?.OtherActivity && this.exposant?.Company?.OtherActivity != '') {
            this.otherActivityCategories[1].LabelFR = this.exposant?.Company?.OtherActivity;
            this.otherActivityCategories[1].LabelEN = this.exposant?.Company?.OtherActivity;
            this.otherActivityCategories[1].LabelES = this.exposant?.Company?.OtherActivity;

            selectedCategories = [...selectedCategories, ...this.otherActivityCategories];
        }

        this.orderedCategories = this.buidlOrderedCategoriesTree(selectedCategories);

        this.otherActivityCategories[1].LabelFR = "";
        this.otherActivityCategories[1].LabelEN = "";
        this.otherActivityCategories[1].LabelES = "";
    }
    //#endregion

    async sendMail(DP_contactTemp: any, folderID: any) {
        if (!DP_contactTemp || DP_contactTemp.length == 0) return;

        let DP_contact: any = DP_contactTemp[0];
        let lang: any = this.app.sharedTools.getParamByID(DP_contact.LanguageID, 'LanguageID', this.languages, 'CodeISO2', true);

        let user = {
            Login: (DP_contact.Login) ? DP_contact.Login : DP_contact.Email,
            Email: DP_contact.Email,
            FolderID: folderID,
            Language : (lang) ? lang : Languages.Default
        }
        console.log('[user]', user);

        const responseMail = await this.app.apiService.mailautologin(user);
        //console.log('RESPONSE EMAIL ===>', responseMail);

        if (responseMail && responseMail.Result) {
            this.app.confirm.confirmDialog(this.app.translate.instant(''), this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + responseMail.Result), 'KO', "alert", () => {
            }, () => {
            })
            return;
        }

        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successSendMail'), 'OK', "alert", () => { }, () => { });
    }

    openUrlAutologin(_url: string) {
        if (!_url) return;
        window.open(_url);
    }

    getLienAutologin(folderId: any) {
        this.app.apiService.getUrlAutologin(folderId).subscribe({
            next: (data: any) => {
                console.log('[getUrlAutologin] API =>', data);

                if (data) {
                    if (data.Result) {
                        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                        return;
                    }

                    this.lienAutologin = data;
                }
            },
            error: (error: any) => {
                console.log(error);
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_ERROR'), 'KO', "alert", () => { }, () => { });
                return;
            }
        });
    }

    async getCategories() {
        try {
            const response = await this.app.apiService.getCategories();

            let categoriesIni = cloneDeep(response);

            let categories = categoriesIni.filter((cat: any) => {
                return cat.CategoryType == "ACTIVITIES"
            });

            this.categories = cloneDeep(categories);

            //direct exhibitor activities
            this.extractDirectExhibitorActivities();
            //

        } catch (error) {
            console.error(error);
        }
    }

    getOrderedCategoriesTreeOfFolder(folder: any): TreeNode[] {
        let selectedCategories = folder?.Company?.Categories;

        if (folder?.Company?.OtherActivity && folder?.Company?.OtherActivity != '') {
            this.otherActivityCategories[1].LabelFR = folder?.Company?.OtherActivity;
            this.otherActivityCategories[1].LabelEN = folder?.Company?.OtherActivity;
            this.otherActivityCategories[1].LabelES = folder?.Company?.OtherActivity;

            selectedCategories = [...selectedCategories, ...this.otherActivityCategories];
        }

        let orderedCategoriesTree = this.buidlOrderedCategoriesTree(selectedCategories);

        this.otherActivityCategories[1].LabelFR = "";
        this.otherActivityCategories[1].LabelEN = "";
        this.otherActivityCategories[1].LabelES = "";

        return orderedCategoriesTree;
    }
}
