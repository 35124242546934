import { Component, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { MessageService, TreeNode } from 'primeng/api';
import { SpinnerService } from '../services/spinner.service';
import { AppComponent } from '../app.component';
import { WebsiteRegex } from '../consts/utils';
import { CustomValidators } from '../validators/custom-validators';
import { Chips } from 'primeng/chips';
import * as _ from 'lodash';
import { ProductHelped } from '../models/product-helped';
import { Languages } from '../enums/language';
import { CategoryType } from '../enums/category-type';
import { FormCode } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-form-helped-description',
    templateUrl: './form-helped-description.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./form-helped-description.component.less']
})
export class FormHelpedDescriptionComponent {
    user: any = null;
    newProductForm!: FormGroup;
    submitted: boolean = false;
    product: ProductHelped = new ProductHelped();
    initForm: boolean = false;
    timelineSteps: any = [];
    layout: any = "horizontal";
    nomenclatures!: TreeNode[];
    selectedNodes: any = [];
    categories: any = [];
    tree: any = {};
    productId: any;
    isEditMode: boolean = false;
    formHelpedID!: number;
    paths = location.pathname.split('/');
    currentPath: any = null;
    dialogVisible: boolean = false;
    form: FormGroup;
    activeStepIndex: number = 0;
    isNomenclatureHaveChild: boolean = true
    frmData : any

    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private translate: TranslatePipe,
        private spinnerService: SpinnerService,
        private fb: FormBuilder
    ) {
        this.getFormHelpedData();
        this.form = this.fb.group({
            Normes: ['', [Validators.required]],
        });
    }

    inputText: any;
    onBlurTest(event: any) {

        let eventKey = new KeyboardEvent('keyup', {
            key: 'Enter',
            keyCode: 13,
            which: 13
        });

        document.dispatchEvent(eventKey);
    }
    onBlur(event: any) {
        const normesControl = this.form.get('Normes') as FormControl;
        let tags = normesControl.value as string[];


        const inputText = event.target.value;

        if (inputText && inputText.trim() !== '' && !tags.includes(inputText)) {
            if (!Array.isArray(tags)) {
                tags = [];
            }
            tags.push(inputText.trim());
            normesControl.setValue(tags);
        }
    }
    onInput(event: any) {
        const normesControl = this.form.get('Normes') as FormControl;
        let tags = normesControl.value as string[];
        const inputText = event.target.value;

        if (inputText.endsWith('')) {
            const newTag = inputText.trim();
            if (newTag) {
                if (!Array.isArray(tags)) {
                    tags = [];
                }
                tags.push(newTag);
                normesControl.setValue(tags);
            }
        }
    }

    async getHelpedInfo() {
        let request = { frmType: "" }
        request.frmType = FormCode.FRM_HLP
        let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
        if (data != undefined && data.Id != undefined) {
            this.frmData = data
        }
    }



    async ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log("this.user ==> ", this.user);

        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }

        await this.getHelpedInfo()

        this.app.route.queryParams
            .subscribe(params => {
                console.log('[params]', params);
                if (params && params['productid']) {
                    this.productId = params['productid'];
                }
            });

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                this.timelineSteps = resG.timelineSteps;

                this.getCategories();

                if (this.productId) {
                    this.isEditMode = true;
                    this.getProduct(this.productId);
                    console.log("this.productId ==> ", this.productId);
                }
                else {
                    this.isEditMode = false;

                }
                setTimeout(() => {
                    this.addNewProductFormHelped();
                }, 500);


            })
    }

    async getCategories() {
        try {
            const response = await this.app.apiService.getCategories();

            let categoriesIni = _.cloneDeep(response);
            let categories = categoriesIni.filter((cat: any) => {
                return cat.CategoryType == CategoryType.HELPActivities
            });
            this.categories = _.cloneDeep(categories);

            categories.sort((a: any, b: any) => {
                return a?.ViewOrder - b?.ViewOrder
            })

            categories.forEach((cat: any) => {
                const parentId = cat.ParentCategoryID;
                if (!this.tree[parentId]) {
                    this.tree[parentId] = [];
                }
                this.tree[parentId].push(cat);
            });

            this.nomenclatures = this.buildTree(null);

            this.isNomenclatureHaveChild = this.nomenclatures.filter((x: any) => {
                return x.children.length > 0
            }).length > 0
        } catch (error) {
            console.error(error);
        }
    }

    buildTree(parentId: any) {
        const children = this.tree[parentId] || [];
        return children.map((child: any) => {
            const node = {
                key: child.CategoryID,
                label: this.app.sharedTools.getLabelSwitchLang(child),
                LabelEN: child.LabelEN,
                LabelFR: child.LabelFR,
                LabelES: child.LabelES,
                data: '',
                icon: '',
                children: this.buildTree(child.CategoryID)
            };
            return node;
        });
    }


    addNewProductFormHelped() {
        const notRequiredValidators: any = this.product.getListNotRequiredsInStep2();

        const formModel: any = {};

        console.log("add New product HLP ==> ", this.product)

        let prod: any = _.cloneDeep(this.product);
        for (const propertyName in this.product) {
            if (this.product.hasOwnProperty(propertyName) && propertyName != 'Categories'
                && propertyName != 'DescriptionFR'
                && propertyName != 'DescriptionEN') {
                const propertyValue = prod[propertyName];
                let validators: any = [];

                if (notRequiredValidators.indexOf(propertyName) == -1) validators.push(Validators.required);
                if (propertyName == 'Website') validators.push(Validators.pattern(WebsiteRegex));
                formModel[propertyName] = [propertyValue, validators];

            }
        }
        formModel['DescriptionFR'] = [prod.DescriptionFR, [Validators.required, Validators.maxLength(600)]];
        formModel['DescriptionEN'] = [prod.DescriptionEN, [Validators.required, Validators.maxLength(600)]];
        this.newProductForm = this.formBuilder.group(formModel);
        this.setConditionalValidators();
        this.newProductForm.addControl('Categories', this.formBuilder.array(this.product?.Categories ?? []));
        this.newProductForm.addValidators(CustomValidators.atLeastHaveOneCategoryValidator());

        this.initForm = true;
    }

    nodeUnselect(event: any) {
        if (this.selectedNodes.length == 0) {
            this.submitted = true
        }
    }

    onChangeValueForm(_value: any, _valueParent: any) {
        const value = this.newProductForm.get(_value)!;

        this.newProductForm.get(_valueParent)?.valueChanges
            .subscribe(_valueParent => {
                if (_valueParent) {
                    value.setValidators([Validators.required]);
                }
                else {
                    value.setValidators(null);
                    value.setValue('');
                }
                value.updateValueAndValidity();
            });
    }

    setConditionalValidators() {
        this.newProductForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }

    async UpdateHelpedSolution(productObj: any): Promise<any> {
        try {
            console.log('UPDATE Product ===>', productObj);
            const response = await this.app.apiService.updateHelpedSolution(productObj);
            console.log('RESPONSE UPDATE Product ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                }, () => {
                })
                return;
            }

            this.onNextStep();

        } catch (error) {
            console.error(error);
        }
    }

    onSubmit() {
        this.submitted = true;
        this.messageService.clear();

        // Logic to handle form submission
        if (this.newProductForm.invalid) {
            console.log("Is invalid !!!!", this.newProductForm.invalid);

            const invalidValues: any = {};
            const controls = this.newProductForm.controls;
            console.log('control ------>>>>>', this.newProductForm.controls);
            let idNomenclatures = document.getElementById('nomenclatures');
            if (this.newProductForm.errors?.['atLeastHaveOneActivityRequired']) idNomenclatures?.classList.add('ng-invalid');

            let invalidFields: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('ng-invalid') as HTMLCollectionOf<HTMLElement>;

            for (const controlName in controls) {
                if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                    invalidValues[controlName] = controls[controlName].value;

                }
            }

            console.log('Invalid form values:', invalidValues);

            if (invalidFields && invalidFields.length > 1) this.app.formInvalid.addSubmittedInvalidClass(invalidFields);

            this.messageService.add({ key: 'custom', severity: 'error', summary: this.app.translate.instant('racine.errorsOther.errorForm'), detail: '', sticky: true });
        }
        else {

            console.log(this.nomenclatures)

            //
            // ==> Ici on save le step1 du product
            let product: any = this.newProductForm.value;
            this.product.mapFormStep2Form(product);

            this.UpdateHelpedSolution(this.product);
        }
    }

    onChipsFocusout(formControlName: string, chipRef: Chips): void {
        if (formControlName && formControlName !== '') {
            let formControl = this.newProductForm.get(formControlName);
            if (formControl) {
                let nonTaggedValue: any = chipRef.inputViewChild.nativeElement.value;
                if (nonTaggedValue && nonTaggedValue !== '') {
                    if (!formControl.value) {
                        formControl.setValue([]);
                    }

                    formControl.value.push(nonTaggedValue);
                    formControl.updateValueAndValidity();
                }
                chipRef.inputViewChild.nativeElement.value = null;
            }
        }
    }

    private getFormHelpedData(): void {
        let helpedForm: any = JSON.parse(sessionStorage.getItem('helped-form') ?? '{}');

        if (helpedForm) {
            this.formHelpedID = helpedForm.HlpFormID;
            //this.folder = f18form.FolderID;
        }
    }

    async getProduct(solId: any) {
        let response = await this.app.apiService.getHelpedSolutionById(solId);
        console.log('this.product resp: ', response)
        this.product.mapFormGetResponse(response);


        if (this.product.Categories) {
            this.product.Categories.forEach((cat: any) => {
                console.log('cat ==> ', cat);
                setTimeout(() => {
                    this.selectNodesByData(cat, this.nomenclatures);
                }, 500);
            });

        }
        console.log('this.selectNodesByData : ', this.selectedNodes)
        console.log('this.product : ', this.product)
        this.addNewProductFormHelped();

    }

    onPreviousStep(): void {
        this.onPrevStep()
    }

    onCancelStep(): void {
        this.app.sharedTools.goTo('/products-helped')
    }

    refreshFormControlCategories(): void {
        // ==> We format the nodes
        let categories = this.app.sharedTools.getCategoriesFromSelectedNodes(this.categories, this.selectedNodes);
        //set activities to Activities form control
        this.setCategoriesToForm(categories);
    }

    private setCategoriesToForm(newValues: any): void {
        let categoriesControl = this.newProductForm.get('Categories') as FormArray;

        // Clear the existing controls in the FormArray
        categoriesControl.clear();

        // Add the new values to the FormArray as individual controls
        newValues.forEach((value: any) => {
            categoriesControl.push(this.formBuilder.control(value));
        });
    }

    selectNodesByData(data: any, nodes: TreeNode[] = this.nomenclatures) {
        try {
            for (const node of nodes) {
                if (node.key === data) {
                    // if (node.parent) this.expandsNodes(node, nodes);
                    this.selectedNodes.push(node);
                    break;
                }
                if (node.children) {
                    this.selectNodesByData(data, node.children);
                }
            }
        } catch (error) {
            console.warn(error);
        }
    }
    //#region Generer les textes

    onGenerateDescriptionClick() {
        this.app.confirm.confirmDialog(''
            , this.app.translate.instant('fo.productFormHelped.confirmChatgptGeneration')
            , 'AUTRE'
            , 'confirm'
            , () => {
                this.generateChatGptDescription()
            }
            , () => { });

    }

    generateChatGptDescription() {
        let requestObj = {
            enTranslationDescription: this.newProductForm.get('DescriptionFR')?.value,
            frTranslationDescription: this.newProductForm.get('DescriptionEN')?.value,
            productUrl: this.product.SolUrl
        }
        console.log("translate", requestObj)

        let requestMsg: string = `${this.translate.transform('productDescriptionGenerator.translate-json-command')} ${JSON.stringify(requestObj)}`;

        let request: any = {
            Message: requestMsg,
            JsonObject: {}
        }

        this.chatgptGeneratePresentation(request);

        console.log("translate", request)
    }


    private mapGenertedDescription(description: any): void {
        console.log("InnovationAspectENControl", description);
        if (description && Array.isArray(description) && description.length > 0) {
            let descriptionENControl: any = this.newProductForm.get('DescriptionEN');
            let descriptionFRControl: any = this.newProductForm.get('DescriptionFR');


            if (descriptionENControl && description[0].DescriptionEN) {
                descriptionENControl.setValue(description[0].DescriptionEN);
            }

            if (descriptionFRControl && description[0].DescriptionFR) {
                descriptionFRControl.setValue(description[0].DescriptionFR);
            }
            console.log("descriptionENControl1", descriptionENControl)
        }
        else if (description) {
            let descriptionENControl: any = this.newProductForm.get('DescriptionEN');
            let descriptionFRControl: any = this.newProductForm.get('DescriptionFR');

            if (descriptionENControl && description.DescriptionEN) {
                descriptionENControl.setValue(description.DescriptionEN);
            }
            if (descriptionENControl && description.enTranslationDescription) {
                descriptionENControl.setValue(description.enTranslationDescription);
            }
            if (descriptionFRControl && description.DescriptionFR) {
                descriptionFRControl.setValue(description.DescriptionFR);
            }
            if (descriptionFRControl && description.frTranslationDescription) {
                descriptionFRControl.setValue(description.frTranslationDescription);
            }



        }
    }

    onCancelClick() {
        this.dialogVisible = false;
    }
    private async chatgptGeneratePresentation(request: any) {
        try {
            this.spinnerService.disableSpinner();
            this.dialogVisible = true;
            const timerPromise = new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 15000);
            });

            const apiRequestPromise = this.app.apiService.chatgptGeneratePresentation(request);

            const response = await Promise.race([apiRequestPromise, timerPromise]);

            if (response === timerPromise) {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                }, () => {
                });
                this.dialogVisible = false;
                return;
            }

            console.log('chatgptGeneratePresentation response ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', 'alert', () => {
                }, () => {
                });
                return;
            }

            console.log('Response Data:', response);

            if (response !== undefined) {
                try {
                    const parsedResponse = JSON.parse(response);
                    this.mapGenertedDescription(parsedResponse);
                    this.dialogVisible = false;
                    this.spinnerService.enableSpinner();
                } catch (error) {
                    console.error('Error parsing response:', error);
                    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                    }, () => {
                    });
                    this.dialogVisible = false;
                }
            } else {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                }, () => {
                });
                this.dialogVisible = false;
            }

        } catch (error) {
            console.error('Error parsing response:', error);
            this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
            }, () => {
            });
            this.dialogVisible = false;
        }
    }


    //#endregion

    //#region  traduire le texte
    onTranslateToENClick() {
        let requestObj = {
            DescriptionFR: this.newProductForm.get('DescriptionFR')?.value,
        }
        console.log("translate", requestObj)

        let requestMsg: string = `${this.translate.transform('productTranslateENGenerator.translate-json-command')} '${requestObj.DescriptionFR}'`;

        let request: any = {
            Message: requestMsg,
            JsonObject: {}
        }

        this.chatgptTranslate(request, Languages.English);

        console.log("translate", request)

    }

    onTranslateToFRClick() {
        let requestObj = {
            DescriptionEN: this.newProductForm.get('DescriptionEN')?.value,
        }
        console.log("translate", requestObj)

        let requestMsg: string = `${this.translate.transform('productTranslateFRGenerator.translate-json-command')} '${requestObj.DescriptionEN}'`;

        let request: any = {
            Message: requestMsg,
            JsonObject: {}
        }

        this.chatgptTranslate(request, Languages.French);

        console.log("translate", request)

    }

    private async chatgptTranslate(request: any, verslanguage: string) {
        try {
            this.spinnerService.disableSpinner();
            this.dialogVisible = true;
            const timerPromise = new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 15000);
            });

            const apiRequestPromise = this.app.apiService.chatgptTranslateTexte(request);

            const response = await Promise.race([apiRequestPromise, timerPromise]);

            if (response === timerPromise) {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                }, () => {
                });
                this.dialogVisible = false;
                return;
            }
            console.log('chatgptTranslateTexte response ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', 'alert', () => {
                }, () => {
                });
                return;
            }
            console.log('Response Data:', response);


            if (response !== undefined) {
                try {
                    if (verslanguage === Languages.French) {
                        console.log('API Response for FR--->:', response);
                        this.mapTranslateFRTexte(JSON.parse(response));
                        this.dialogVisible = false;
                        this.spinnerService.enableSpinner();
                    }
                    else if (verslanguage === Languages.English) {
                        console.log('API Response for EN--->:', response);
                        this.mapTranslateENTexte(JSON.parse(response));
                        this.dialogVisible = false;
                        this.spinnerService.enableSpinner();
                    }
                } catch (error) {
                    console.error('Error parsing response:', error);
                    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                    }, () => {
                    });
                    this.dialogVisible = false;
                }
            } else {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                }, () => {
                });
                this.dialogVisible = false;
            }

        } catch (error) {
            console.error('Error parsing response:', error);
            this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
            }, () => {
            });
            this.dialogVisible = false;
        }
    }



    private mapTranslateENTexte(description: any): void {
        if (description && Array.isArray(description) && description.length > 0) {
            let descriptionENControl: any = this.newProductForm.get('DescriptionEN');

            if (descriptionENControl && description[0].DescriptionEN) {
                descriptionENControl.setValue(description[0].DescriptionEN);
            }
        }
        else if (description) {
            let descriptionENControl: AbstractControl<any, any> | null = this.newProductForm.get('DescriptionEN');
            if (descriptionENControl && description.DescriptionEN) {
                descriptionENControl.setValue(description.DescriptionEN);
            }

        }
    }

    private mapTranslateFRTexte(description: any): void {
        if (description && Array.isArray(description) && description.length > 0) {
            let descriptionFRControl: any = this.newProductForm.get('DescriptionFR');

            if (descriptionFRControl && description[0].DescriptionFR) {
                descriptionFRControl.setValue(description[0].DescriptionFR);
            }

        }
        else if (description) {
            let descriptionFRControl: AbstractControl<any, any> | null = this.newProductForm.get('DescriptionFR');
            if (descriptionFRControl && description.DescriptionFR) {
                descriptionFRControl.setValue(description.DescriptionFR);
            }

        }
    }

    //#endregion


    onNextStep(): void {
        let queryparams: any = {
            productid: this.productId
        }
        this.app.sharedTools.redirectToWithParameters(`product-helped-medias/edit`, queryparams);
    }

    onPrevStep(): void {
        let queryparams: any = {
            productid: this.productId
        }
        console.log('query params', this.productId);

        this.app.sharedTools.redirectToWithParameters(`product-helped-generalites/edit`, queryparams);
    }


}
