import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-mkt-email-signature',
  templateUrl: './mkt-email-signature.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./mkt-email-signature.component.less']
})
export class MktEmailSignatureComponent implements OnInit {
  @ViewChild('imageElement') imageElement?: ElementRef;
  newTab2Form!: FormGroup;

  locations: any;
  imageUrl: any;
  imageByte: any;
  FolderID!: number;
  isBannerExist: boolean = true;
  @Input() mktFormID!: number;

  @Input() userInfo: any;

  @Input() reloadGetUserInfo: boolean = false;

  @Input() logoSettings: {} = {};
  @Input() isLoadLogoSettings: boolean = false;


  @Input() categoryID: any;

  @Output() canTriggerReloadUserInfo: EventEmitter<any> = new EventEmitter<any>();
  @Input() canRecieveUserInfo: boolean = false;

  @Output() canReloadGetUserInfo: EventEmitter<any> = new EventEmitter<any>();

  isGenerated: boolean = false;
  canRegenerateMedia: boolean = false;
  mediaGenerationInProgress=false;

  constructor(public app: AppComponent, private clipboard: Clipboard) {

  }

  async ngOnInit() {

    console.log("> [getUserInfo] from mkt-email-signature-cmp :", this.userInfo);

    this.initTab2Form();

    await this.getKeysFromStorage();

    this.isGenerated = this.userInfo?.IsGenerated?.MAILSIGN;

    if (this.isGenerated === false) {
      await this.getBanner();
    }

    this.handleTriggerReloadEvent(this.reloadGetUserInfo);

  }

  private async getKeysFromStorage() {
    const folderIdString = sessionStorage.getItem("mktFolderID");
    const formIDString = sessionStorage.getItem("mktFormID");

    if (folderIdString !== null) {
      this.FolderID = parseInt(folderIdString);
    }

    if (formIDString !== null) {
      this.mktFormID = parseInt(formIDString);
    }
  }
  async getBanner() {

    console.log("🚀  this.mktFormID:", this.mktFormID)
    let resp = await this.app.apiService.getBanner(this.mktFormID, this.categoryID);
    console.log("==>>getBanner", resp);
    this.imageUrl = resp.ImageUrl;
    this.imageByte = resp.ImageContent;
    console.log("🚀  this.imageUrl:", this.imageUrl)
    // this.isBannerExist = false;
    if (this.imageUrl != null) {
      this.isBannerExist = true;
    } else {
      this.isBannerExist = false;
    }
  }

  generateMedias() {

  }

  downloadMediaZIP() {

  }

  writeWithAI() {

  }

  async copyDataOfThePost() {
    const dataURL = this.imageByte;
    console.log("dataURL", dataURL)
    const blob = this.dataURLtoBlob(dataURL);
    try {

      await navigator.clipboard.write([
        new ClipboardItem({
          'image/png': blob,
        }),
      ]);
      console.log('Image copied to clipboard');
    } catch (imageError) {
      console.error('Error copying image to clipboard:', imageError);
    }

  }

  dataURLtoBlob(dataURL: string): Blob {
    const bstr = atob(dataURL);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const mime = 'image/png';
    return new Blob([u8arr], { type: mime });
  }


  sendEmail() {
    console.log("> Sending email...");
  }

  //#region initialiseForm
  private initTab2Form(): void {
    this.newTab2Form = new FormGroup({
      CompanyName: new FormControl(),
      SelectedLocation: new FormControl(),
    });
  }
  //#endregion

  async isMediaGenerated(event: any) {
    this.canRegenerateMedia = false;
    this.isGenerated = event?.MAILSIGN ?? false ;
    console.log(">>>---  this.isGenerated:", this.isGenerated);
    console.log(">>>--- this.canRegenerateMedia:", this.canRegenerateMedia);
    if (this.canRegenerateMedia === false && this.isGenerated === true) {
      if (!this.mediaGenerationInProgress) {
        this.mediaGenerationInProgress = true;
  
        await this.getBanner();
  
        this.mediaGenerationInProgress = false;
      }
    }
  }

  handleTriggerReloadEvent(event: boolean) {

    this.canTriggerReloadUserInfo.emit(event);

  }

  handleCanReloadGetUserInfo(event: boolean) {

    this.canReloadGetUserInfo.emit(event);

  }

  handleRegenerateMedia(event: boolean) {
    this.canRegenerateMedia = event;
    console.log("🚀  this.canRegenerateMedia:", this.canRegenerateMedia)
  }

}
