import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-mkt-advertisements',
  templateUrl: './mkt-advertisements.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./mkt-advertisements.component.less']
})
export class MktAdvertisementsComponent implements OnInit {

  newTab4Form!: FormGroup;
  FolderID!: number;
  imageUrl: any;
  imageByte:any;
  isBannerExist: boolean = true;
  @Input() mktFormID!: number;

  @Input() userInfo: any;

  
  @Input() logoSettings: {} = {};
  @Input() isLoadLogoSettings: boolean = false;

  
  @Input() categoryID: any;

  @Output() canTriggerReloadUserInfo: EventEmitter<any> = new EventEmitter<any>();
  @Input() canRecieveUserInfo: boolean = false;

  @Output() canReloadGetUserInfo: EventEmitter<any> = new EventEmitter<any>();
  @Input() reloadGetUserInfo: boolean = false;

  isGenerated: boolean = false;
  canRegenerateMedia: boolean = false;
  mediaGenerationInProgress=false;

  constructor(public app: AppComponent, private http: HttpClient) {

  }

  async ngOnInit() {

    console.log("> [getUserInfo] from mkt-advertisement-cmp :", this.userInfo);
    
    this.initTab4Form();
    
    await this.getKeysFromStorage();

    this.isGenerated = this.userInfo?.IsGenerated?.PUBLICITY;

    if (this.isGenerated === false) {
      await this.getBanner();
    }

    this.handleTriggerReloadEvent(this.reloadGetUserInfo);
  }

  private async getKeysFromStorage() {
    const folderIdString = sessionStorage.getItem("mktFolderID");
    const formIDString = sessionStorage.getItem("mktFormID");

    if (folderIdString !== null) {
      this.FolderID = parseInt(folderIdString);
    }

    if (formIDString !== null) {
      this.mktFormID = parseInt(formIDString);
    }
  }


  generateMedias() {

  }

  downloadStaticZip(){
    const zipFileUrl = 'assets/documents/marketing/your-eurosatory-adds.zip';
    console.log("downloadStaticZip")
    this.http.get(zipFileUrl, { responseType: 'blob' })
      .subscribe((data: any) => {
        const blob = new Blob([data], { type: 'application/zip' });

        // Create a link element, set its attributes, and trigger a download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'your-eurosatory-adds.zip';
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }, (error: any) => {
        console.error('Error downloading zip file', error);
      });
  }
  downloadMediaZIP() {
    return this.app.apiService.downloadZipFile(this.FolderID, this.categoryID).subscribe({
      next: (res) => {
        let imageData = "data:image/*;base64," + res.FileContent;

        console.log("download Zip File", res)
        var link = document.createElement("a")
        link.setAttribute("href", imageData);
        link.setAttribute("download", res.FileName);
        link.click();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  async getBanner() {

    console.log("🚀  this.mktFormID:", this.mktFormID)
    let resp = await this.app.apiService.getBanner(this.mktFormID,this.categoryID);
    console.log("==>>getBanner", resp);
    this.imageUrl = resp.ImageUrl;
    this.imageByte=resp.ImageContent;
    console.log("🚀  this.imageUrl:", this.imageUrl)
    // this.isBannerExist = false;
    if (this.imageUrl != null) {
      this.isBannerExist = true;
    } else {
      this.isBannerExist = false;
    }
  
  

}
  writeWithAI() {

  }

  copyDataOfThePost() {

  }

  sendEmail() {
    console.log("> Sending email...");
  }

  //#region initialiseForm
  private initTab4Form(): void {
    this.newTab4Form = new FormGroup({
      CompanyName: new FormControl(),
      SelectedLocation: new FormControl(),
      LandingPageUrl: new FormControl(),
    });
  }
  //#endregion

  async isMediaGenerated(event: any) {
    this.canRegenerateMedia = false;
    this.isGenerated = event;
    console.log(">>>---  this.isGenerated:", this.isGenerated);
    console.log(">>>--- this.canRegenerateMedia:", this.canRegenerateMedia);
    if (this.canRegenerateMedia === false && this.isGenerated === true) {
      if (!this.mediaGenerationInProgress) {
        this.mediaGenerationInProgress = true;
  
        await this.getBanner();
  
        this.mediaGenerationInProgress = false;
      }
    }
  }

  handleTriggerReloadEvent(event: boolean) {

    this.canTriggerReloadUserInfo.emit(event);

  }

  handleCanReloadGetUserInfo(event: boolean) {

    this.canReloadGetUserInfo.emit(event);

  }

  handleRegenerateMedia(event: boolean){
    this.canRegenerateMedia = event;
    console.log("🚀  this.canRegenerateMedia:", this.canRegenerateMedia)
  }

}
