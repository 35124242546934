import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { DatePipe } from '@angular/common';
import { DateHelper } from '../helpers/date.helper';
import { CustomValidators } from '../validators/custom-validators';
import { MediasTypes } from '../enums/media-type';
import { CategoryType } from '../enums/category-type';
import { StatusType } from '../enums/status-type';
import { ConferenceRoutes } from '../consts/conference.routes';
import { IdentityManagerService } from '../services/identity-manager.service';
import { firstValueFrom } from 'rxjs';
import { MediaCmpComponent } from '../medias-components/media-cmp/media-cmp.component';
import { MediaValueChange } from '../models/media-value-change';
import { EmailRegex, WebsiteRegex } from '../consts/utils';
import { SpinnerService } from '../services/spinner.service';
import { Languages } from '../enums/language';
import { RoleTypeId } from '../enums/role-type-id';
import { RoleCode } from '../enums/role-code';
import { RoleType } from '../enums/role-type';
import { ConferenceUploadMediaInfo, UploadMediaInfo } from '../models/media-upload-info';
import { TreeNode } from 'primeng/api';
import { FormCode } from '../enums/form-code';
@Component({
    selector: 'app-create-vm-program',
    templateUrl: './create-vm-program.component.html',
    styleUrls: ['./create-vm-program.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class CreateVmProgramComponent {
    //#region properties
    submitted: boolean = false;
    initForm: boolean = false;
    isEditMode: boolean = false;
    isProgram: boolean = false;
    canDeleteConference: boolean = true;

    conferenceForm!: FormGroup;

    langues: any[] = [];
    currentEditionDays: any[] = [];
    interests: any[] = [];
    POCs: any;
    exposants: any[] = [];
    organiserTypes: any[] = [];
    languages: any[] = [];
    selectYesNoTranslated: any[] = [];
    status: any[] = [];
    cycles: any[] = [];
    Acces: any[] = [];
    types: any[] = [];
    roles: any[] = [];
    pocRoleId: any;

    conference: any;
    conferenceId: any;
    edition: any;

    mediasTypes = MediasTypes;



    documentLogo: any;
    documentImage: any;
    mediaDoc: any;

    selectedConfStatus: any = null;
    selectedPoc: any = null;
    selectedAccess: any = null;
    selectedCycle: any = null;
    selectedLanguage: any = null;
    selectedTranslated: any = null;
    selectedType: any = null;
    selectedInterests: any = null;
    CommunicationPhonePrefixSelected: any;
    PressPhonePrefixSelected: any;
    phones: any[] = [];
    dialogVisible: boolean = false;
    languagesCodes = Languages;
    isManager: boolean = false;
    interestCategoriesTree!: TreeNode[];
    tree: any = {};
    isInterestCategoriesHaveChild: boolean = true;
    selectedNodes: any = [];
    placesEvents: any
    isWriter: boolean = false
    isPhotoSelected : boolean = false
    isLogoSelected : boolean = false

    @ViewChild('logoInput') logoInput?: MediaCmpComponent;
    @ViewChild('photoInput') photoInput?: MediaCmpComponent;
    formCode : FormCode = FormCode.FRM_CONF;
    //#endregion

    //#region ctor
    constructor(
        public app: AppComponent,
        private identityManagerService: IdentityManagerService,
        private datePipe: DatePipe,
        private formBuilder: FormBuilder,
        private spinnerService: SpinnerService,
        private ims: IdentityManagerService
    ) {
    }
    //#endregion

    async ngOnInit() {
        let urlWithoutQueryParameters: string = this.app.router.url;
        if (this.app.router.url.indexOf("?") > 0) {
            urlWithoutQueryParameters = urlWithoutQueryParameters.substring(0, this.app.router.url.indexOf("?"))
        }

        this.isProgram = urlWithoutQueryParameters == `/${ConferenceRoutes.createProg}`;
        if(this.isProgram){
            this.formCode = FormCode.FRM_PROGRAM;
        }

        if (!this.identityManagerService.checkIsUserAuthenticated()) {
            return;
        }

        this.initializeParams();

        //check create or edit conf
        this.app.route.queryParams
            .subscribe(params => {
                if (params
                    && (this.isProgram && params['programId'])
                    || (!this.isProgram && params['conferenceId'])) {
                    this.conferenceId = params[this.isProgram ? 'programId' : 'conferenceId'];
                    this.isEditMode = true;
                }
            });

        await this.getPOCs();
        await this.getConfCategories();
        await this.getStatusTypes();
        await this.getLanguages();
        await this.getEdition();
        await this.getPhones();
        await this.getPlacesEvents()

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                this.selectYesNoTranslated = cloneDeep(resG.selectYesNoTranslated);
                this.organiserTypes = cloneDeep(resG.ConfOrganiserType);
                this.Acces = resG.accessTypes
                if (this.isEditMode) {
                    this.getConference(this.conferenceId);
                }
                else {
                    this.initFormulaire();
                }
            });
    }

    //#region handle events
    onCancel() {
        this.navigateToList();
    }



    onSubmit() {
        this.conferenceForm.get('Timings')?.updateValueAndValidity();
        this.conferenceForm.get('Interest')?.updateValueAndValidity();
        this.conferenceForm.updateValueAndValidity();
        this.checkOrganizerNameValidator()
        this.submitted = true;
        if (this.conferenceForm.invalid) {
            console.log("invalid form ===>", this.conferenceForm);
            console.log("invalid form errors ===>", this.conferenceForm.errors);

            //bloc explore form errors
            let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
            const invalidValues: any = {};
            const controls = this.conferenceForm.controls;

            for (const controlName in controls) {
                if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                    invalidValues[controlName] = controls[controlName].value;
                }
            }

            console.log('Invalid form values:', invalidValues);

            if (invalidField && invalidField.length > 1) {
                console.log("invalidField", invalidField)

                setTimeout(() => {
                    let el = invalidField[1].offsetTop - 100;
                    if (el) window.scroll({ top: el, behavior: "smooth" });
                }, 10);
            }
            //
            this.app.confirm.confirmDialog(""
                , this.app.translate.instant('racine.errorsOther.errorForm')
                , 'KO'
                , 'alert'
                , () => { }
                , () => { });
        }
        else {
            if (!this.selectedNodes) {
                this.selectedNodes = []
            }
            this.onInterestCategoriesSelectionChanged()
            let newConference = this.conferenceForm.getRawValue();
            console.log("this is new newConference = ", newConference, newConference.SelectedIsTranslated)
            //format dates

            if (newConference.SelectedStatus) {
                newConference.ConfStatus = newConference.SelectedStatus
            }


            newConference.LanguageDetail = newConference.LanguesConf;

            // if (newConference.Acces) {
            //     newConference.AccessCategoryID = newConference.Acces.CategoryID
            // }
            // else {
            //     newConference.AccessCategoryID = null;
            // }

            if (newConference.Cycle) {
                newConference.CyclesIds = [newConference.Cycle.CategoryID]
            }
            else {
                newConference.CyclesIds = [];
            }


            if (newConference.Interest) {
                newConference.IntersetsCategoryIds = newConference.Interest;
                console.log("Exposant.Interest", newConference.IntersetsCategoryIds)
            }
            else {
                newConference.IntersetsCategoryIds = [];
            }

            if (newConference.ExposantAutoComplete) {
                newConference.CompanyId = newConference.ExposantAutoComplete.CompanyID
            }

            if (newConference.Tags) {
                const tagsStr: string = newConference.Tags.join(" | ");
                newConference.CnfdTags = tagsStr;
            }
            else {
                newConference.CnfdTags = "";
            }

            if (newConference.Poc) {
                newConference.PocContactID = newConference.Poc.ContactID
            }

            if (newConference.SelectedIsTranslated) {
                newConference.IsTranslated = newConference.SelectedIsTranslated.Value;
            }
            else {
                newConference.IsTranslated = null;
            }

            if (newConference.Type) {
                newConference.ConfTypeCategory = newConference.Type.CategoryID
            }

            if (newConference.OrganizerNameEN) {
                newConference.OrganizerNameEN = newConference.OrganizerNameEN
            }

            if (newConference.OrganizerNameFR) {
                newConference.OrganizerNameFR = newConference.OrganizerNameFR
            }

            newConference.CnfdLogo = this.documentLogo;
            newConference.CnfdImage = this.documentImage;

            let timing_list = this.getTimingsArrayControls();
            let confEvents: any = [];
            timing_list.map((timing: any) => {

                let dateString = timing.value.Date;
                let startHourString = timing.value.StartHour;
                let endHourString = timing.value.EndHour;

                let formattedStartDateTime: string = this.datePipe
                    .transform(dateString, DateHelper.ApiDateTimeFormat) ?? '';

                let startDateTime: string = DateHelper.concatDateTime(formattedStartDateTime, startHourString) ?? '';
                let endDateTime: string = DateHelper.concatDateTime(formattedStartDateTime, endHourString) ?? '';
                let confEvent = {
                    CnfeHourStart: startDateTime,
                    CnfeHourEnd: endDateTime,
                    CnfeID: timing.value.CnfeID,
                    CategoryEventsDetails: timing.value.Place ?? null,
                }

                confEvents.push(confEvent);
            });
            newConference.ConferenceEventList = confEvents;

            newConference.IsProgram = this.isProgram;

            if (newConference.ContactPressPhonePrefixSelected
                && newConference.ContactPressPhonePrefixSelected.CodeISO2) {
                newConference.ContactPressPhoneCodeISO2 = newConference.ContactPressPhonePrefixSelected.CodeISO2;
            }

            if (newConference.ContactCommunicationPhonePrefixSelected
                && newConference.ContactCommunicationPhonePrefixSelected.CodeISO2) {
                newConference.ContactCommunicationPhoneCodeISO2 = newConference.ContactCommunicationPhonePrefixSelected.CodeISO2;
            }

            newConference.IsLogoSelected = this.isLogoSelected
            newConference.IsPhotoSelected = this.isPhotoSelected

            newConference.IsPrivate = newConference.Acces?.TypeId == 1 ? true : newConference.Acces?.TypeId == 2 ? false : null

            console.log('[newConference before]', newConference);
            this.app.sharedTools.deleteParamsInObject(newConference
                , ['LanguesConf' //new
                    , 'SelectedStatus'//new
                    , 'Interest'
                    , 'Acces'
                    , 'Organiser'
                    , 'SelectedIsTranslated'
                    , 'Timings'
                    // , 'CompanyName2'
                    , 'CompanyName'
                    , 'Cycle'
                    , 'Poc'
                    , 'Tags'
                    , 'Type'
                ]);

            console.log('[newConference after]', newConference);

            this.createConference(newConference);
        }
    }

    onExposantSelect(value: any) {
        console.log("this is select", value)
        this.conferenceForm.patchValue({
            OrganizerWebsite: value.Website
        });
        this.app.apiService.setImageMedia(value.LogoID).subscribe({
            next: (res) => {
                if (!this.documentLogo) {
                    this.documentLogo = res;
                }
            },
            error: (err) => {

                console.log("this is err", err);
            },
        });

    }

    onSearchExposantName(event: any): void {
        let keyword: string | undefined = event.query;

        if (!keyword || keyword === '') {
            this.exposants = [];
        }
        else {
            this.refreshExposantNameAutocomplete(keyword);
        }
    }

    onDeleteTiming(index: number) {

        let timingsArray = this.getTimingsArrayControls();

        if (timingsArray && timingsArray.length > index) {
            timingsArray.splice(index, 1);
            this.conferenceForm.get('Timings')?.updateValueAndValidity();
            this.conferenceForm.updateValueAndValidity();
        }
    }

    getTimingsArrayControls(): AbstractControl<any, any>[] {
        let timings: AbstractControl<any, any>[] = (this.conferenceForm.get('Timings') as FormArray).controls;

        return timings;
    }

    onAddTiming(): void {
        const newTiming = this.formBuilder.group({
            CnfeID: null,
            Date: [{ value: null, disabled: !this.isManager }, [Validators.required]],
            StartHour: [{ value: null, disabled: !this.isManager }, [Validators.required]],
            EndHour: [{ value: null, disabled: !this.isManager }, [Validators.required]],
            Place: [{ value: null, disabled: !this.isManager }, []]
        },
            {
                validators: [CustomValidators.plageEndHourGreaterOrEqualThanStartHour()]
            });

        let timingsArray = this.getTimingsArrayControls();
        if (timingsArray) {
            timingsArray.push(newTiming)
        }
        this.conferenceForm.get('Timings')?.updateValueAndValidity();
        this.conferenceForm.updateValueAndValidity();
    }

    onLogoSelected(logo: MediaValueChange): void {
        console.log(' logo ', logo);
        let organizatorLogoUpdatedControl = this.conferenceForm.get('OrganizatorLogoUpdated');

        if (organizatorLogoUpdatedControl && logo.IsFileSelected) {
            organizatorLogoUpdatedControl.setValue(true);
        }
        this.isLogoSelected = logo?.HaveValue ?? false
        console.log(logo, this.conferenceForm, this.logoInput?.selectedFile)
    }

    onPhotoSelected(image: MediaValueChange): void {
        console.log(' photo ', image);
        let imageUpdatedControl = this.conferenceForm.get('ImageUpdated');

        if (imageUpdatedControl && image.IsFileSelected) {
            imageUpdatedControl.setValue(true);
        }
        this.isPhotoSelected = image?.HaveValue ?? false

        console.log(image, this.conferenceForm, this.photoInput?.selectedFile)
    }

    onSetLockImport() {
        this.conference.ExternalImportEnabled = !this.conference.ExternalImportEnabled;
        this.setLockImport(this.conference);
    }

    onCancelClick() {
        this.dialogVisible = false;
    }

    onDeleteConference() {
        let confirmMsg: string = this.isProgram
            ? 'racine.confirmMsg.confirmDeleteProgram'
            : 'racine.confirmMsg.confirmDeleteConference';
        this.app.confirm.confirmDialog(''
            , this.app.translate.instant(confirmMsg)
            , 'AUTRE'
            , 'confirm'
            , () => { setTimeout(() => { this.deleteConference(this.conference); }, 500); }
            , () => { });
    }

    formControlHavePatternError(name: string): boolean {
        let havePatternError: boolean = false;

        let control = this.conferenceForm.get(name);
        if (control?.errors && control.errors['pattern']) {
            havePatternError = true;
        }
        return havePatternError;
    }

    getTitle(): string {
        if (!this.conference) {
            return '';
        }

        let title: string = ' ' + this.app.sharedTools.getPropertySwitchLang(this.conference, 'CnfdName') ?? '';
        if (this.conference.Code && this.conference.Code != '') {
            title += ' (' + (this.conference.Code) + ')'
        }
        return title;
    }

    onInterestNodeUnselect(event: any) {
        if (this.selectedNodes.length == 0) {
            this.submitted = true
        }
    }

    onInterestCategoriesSelectionChanged(): void {
        // ==> We format the nodes
        let categories = this.app.sharedTools.getCategoriesFromSelectedNodes(this.interests, this.selectedNodes);
        //set activities to Activities form control
        this.setCategoriesToForm(categories);
    }
    //#endregion

    //#region private local utils
    private initFormulaire(): void {
        let requiredConditionalValidators: ValidatorFn[] = this.isManager
            ? []
            : [Validators.required];
        if (this.isEditMode) {
            let isOrganiserCompany: boolean = this.conference.Company && this.conference.Company.CompanyID;
            this.conferenceForm = new FormGroup({
                Organiser: new FormControl({ value: isOrganiserCompany ? this.organiserTypes[0] : this.organiserTypes[1], disabled: !this.isManager }, [Validators.required]),
                Code: new FormControl({ value: this.conference.Code, disabled: !this.isManager }, [Validators.required]),
                Poc: new FormControl({ value: this.selectedPoc, disabled: !this.isManager }, [Validators.required]),
                OrganizerWebsite: new FormControl(
                    { value: this.conference.OrganizerWebSite, disabled: !this.isWriter },
                    [Validators.pattern(WebsiteRegex)]
                ),
                LanguesConf: new FormControl(this.selectedLanguage, requiredConditionalValidators),
                SelectedIsTranslated: new FormControl(this.selectedTranslated),
                IsTranslated: new FormControl(this.conference.IsTranslated),
                Acces: new FormControl(this.selectedAccess),
                Cycle: new FormControl(this.selectedCycle, requiredConditionalValidators),
                Tags: new FormControl((this.conference.CnfdTags && this.conference.CnfdTags != '') ? this.conference.CnfdTags.split('|') : null),
                Interest: new FormControl(this.selectedInterests ?? []),
                OrganiserLogo: new FormControl(this.conference),
                CompanyName: new FormControl(this.conference),
                CompanyName2: new FormControl(this.conference),
                Type: new FormControl({ value: this.selectedType, disabled: !this.isManager }, [Validators.required]),
                CnfdNameFR: new FormControl(this.conference.CnfdNameFR, requiredConditionalValidators),
                CnfdNameEN: new FormControl(this.conference.CnfdNameEN, requiredConditionalValidators),
                CnfdDescriptionFR: new FormControl(this.conference.CnfdDescriptionFR
                    , [Validators.maxLength(600), ...requiredConditionalValidators]),
                CnfdDescriptionEN: new FormControl(this.conference.CnfdDescriptionEN
                    , [Validators.maxLength(600), ...requiredConditionalValidators]),
                CnfdUrl: new FormControl(this.conference.CnfdUrl, Validators.pattern(WebsiteRegex)),
                SelectedStatus: new FormControl({ value: this.selectedConfStatus, disabled: !this.isManager }, [Validators.required]),
                ExposantAutoComplete: new FormControl({ value: this.conference.Company, disabled: !this.isManager }, isOrganiserCompany ? requiredConditionalValidators : []),
                OrganizerNameEN: new FormControl(this.conference.OrganizerNameEN, [Validators.required]),
                OrganizerNameFR: new FormControl(this.conference.OrganizerNameFR, [Validators.required]),

                Timings: this.formBuilder.array([]),

                // external import properties
                DateLastSync: new FormControl({ value: this.conference.DateLastSync, disabled: true }),
                ExternalCode: new FormControl({ value: this.conference.ExternalCode, disabled: true }),

                OrganizatorLogoUpdated: new FormControl(false),
                ImageUpdated: new FormControl(false),


                PressName: new FormControl(this.conference.PressName),
                PressEmail: new FormControl(this.conference.PressEmail),
                PressPhoneNumber: new FormControl(this.conference.PressPhoneNumber),
                ContactName: new FormControl(this.conference.ContactName),
                ContactEmail: new FormControl(this.conference.ContactEmail),
                ContactPhoneNumber: new FormControl(this.conference.ContactPhoneNumber),
                ContactCompany: new FormControl(this.conference.ContactCompany),

            }, { validators: [CustomValidators.greaterOrEqual('StartHour', 'EndHour')] });
        }
        else {
            // defaults
            let draftStatus = this.status.filter((x: any) => x?.Code == "DRAFT")?.length > 0 ? this.status.filter((x: any) => x?.Code == "DRAFT")[0] : null
            let defaultLng: any = this.languages.find((l: any) => l.Code && l.Code.toLowerCase() == Languages.English);
            let defaultIsTranslated: any =
                (this.selectYesNoTranslated && Array.isArray(this.selectYesNoTranslated) && this.selectYesNoTranslated.length > 0)
                    ? this.selectYesNoTranslated.find((s) => s.Value == false)
                    : null;

            this.conferenceForm = new FormGroup(
                {
                    Code: new FormControl({ value: null, disabled: !this.isManager }, [Validators.required]),
                    Poc: new FormControl({ value: null, disabled: !this.isManager }, [Validators.required]),
                    Type: new FormControl({ value: null, disabled: !this.isManager }, [Validators.required]),
                    Organiser: new FormControl({ value: this.organiserTypes ? this.organiserTypes[0] : null, disabled: !this.isManager }, requiredConditionalValidators),
                    OrganizerWebsite: new FormControl({ value: null, disabled: !this.isManager }
                        , [Validators.pattern(WebsiteRegex),]),
                    LanguesConf: new FormControl(defaultLng, requiredConditionalValidators),
                    SelectedIsTranslated: new FormControl(defaultIsTranslated),
                    IsTranslated: new FormControl(null),
                    Acces: new FormControl(null),
                    Cycle: new FormControl(null, requiredConditionalValidators),
                    Tags: new FormControl(null),
                    Interest: new FormControl(null),
                    CnfdNameFR: new FormControl(null, requiredConditionalValidators),
                    OrganiserLogo: new FormControl(null),
                    CnfdDescriptionFR: new FormControl(null
                        , [Validators.maxLength(600), ...requiredConditionalValidators]),
                    CompanyName: new FormControl(null),
                    CompanyName2: new FormControl(null),
                    CnfdNameEN: new FormControl(null, requiredConditionalValidators),
                    CnfdDescriptionEN: new FormControl(null
                        , [Validators.maxLength(600), ...requiredConditionalValidators]),
                    CnfdUrl: new FormControl(null, Validators.pattern(WebsiteRegex)),
                    SelectedStatus: new FormControl({ value: draftStatus, disabled: !this.isManager }, [Validators.required]),
                    ExposantAutoComplete: new FormControl({ value: null, disabled: !this.isManager }, requiredConditionalValidators),
                    OrganizerNameEN: new FormControl(null, requiredConditionalValidators),
                    OrganizerNameFR: new FormControl(null, requiredConditionalValidators),
                    Timings: this.formBuilder.array([]),

                    // external import properties
                    DateLastSync: new FormControl({ value: null, disabled: true }),
                    ExternalCode: new FormControl({ value: null, disabled: true }),

                    OrganizatorLogoUpdated: new FormControl(false),
                    ImageUpdated: new FormControl(false),

                    PressName: new FormControl(null),
                    PressEmail: new FormControl(null),
                    PressPhoneNumber: new FormControl(null),
                    ContactName: new FormControl(null),
                    ContactEmail: new FormControl(null),
                    ContactPhoneNumber: new FormControl(null),
                    ContactCompany: new FormControl(null),

                }, { validators: [CustomValidators.greaterOrEqual('StartHour', 'EndHour')] });
        }
        if (this.isProgram) {
            this.conferenceForm.get("Cycle")?.setValidators([])
            this.conferenceForm.updateValueAndValidity()
        }
        this.appendAdditionalControls();
        this.initializeEmbeddedFormGroups();
        this.initializeOnChangeHandlers();
        this.initForm = true;
    }

    OrganizerNameFRChanged() {
        let organizerNameENControl = this.conferenceForm.get("OrganizerNameEN")
        let organizerNameFRControl = this.conferenceForm.get("OrganizerNameFR")
        if (organizerNameENControl && organizerNameFRControl) {
            let organizerNameFRValue = organizerNameFRControl.value
            if ((organizerNameENControl.value == null || organizerNameENControl.value == undefined || organizerNameENControl.value == "")
                && organizerNameFRValue != undefined && organizerNameFRValue != null) {
                organizerNameENControl.setValue(organizerNameFRValue)
                organizerNameENControl.updateValueAndValidity()
            }
        }
    }

    private initializeEmbeddedFormGroups(): void {
        if (this.conference?.ConferenceEventList
            && Array.isArray(this.conference.ConferenceEventList)
            && this.conference.ConferenceEventList.length > 0) {
            //init timings form controls
            let timingsFormArray = this.conferenceForm.get('Timings') as FormArray;
            if (timingsFormArray) {
                this.conference.ConferenceEventList.forEach((e: any) => {
                    const newTiming = this.newTimingFormGroup(e);

                    timingsFormArray.push(newTiming);
                });
                this.conferenceForm.get('Timings')?.updateValueAndValidity();
                this.conferenceForm.updateValueAndValidity();
            }
        }
    }

    private newTimingFormGroup(event: any): FormGroup {
        let date: string = this.datePipe
            .transform(event.CnfeHourStart
                , `${DateHelper.ApiDateFormat}${DateHelper.DateTimeSeparator}${DateHelper.MidnightTime}`)
            ?? '';

        let startHour: string = this.datePipe.transform(event.CnfeHourStart, DateHelper.ApiTimeFormat) ?? '';
        let endHour: string = this.datePipe.transform(event.CnfeHourEnd, DateHelper.ApiTimeFormat) ?? '';

        console.log('date date ', date)

        return this.formBuilder.group({
            CnfeID: [{ value: event.CnfeID, disabled: !this.isManager }, [Validators.required]],
            Date: [{ value: date, disabled: !this.isManager }, [Validators.required]],
            StartHour: [{ value: startHour, disabled: !this.isManager }, [Validators.required]],
            EndHour: [{ value: endHour, disabled: !this.isManager }, [Validators.required]],
            Place: [{ value: event.CategoryEventsDetails, disabled: !this.isManager }, []],
        },
            {
                validators: [CustomValidators.plageEndHourGreaterOrEqualThanStartHour()]
            });
    }

    private navigateToList(): void {
        this.app.sharedTools.redirectTo(this.isProgram
            ? `/${ConferenceRoutes.programList}`
            : `/${ConferenceRoutes.confList}`);
    }

    checkOrganizerNameValidator() {
        let organiserControl = this.conferenceForm.get('Organiser');
        let exposantAutoCompleteControl = this.conferenceForm.get('ExposantAutoComplete');
        let organizerNameENControl = this.conferenceForm.get('OrganizerNameEN');
        let organizerNameFRControl = this.conferenceForm.get('OrganizerNameFR');
        if (organiserControl
            && exposantAutoCompleteControl
            && organizerNameENControl
            && organizerNameFRControl) {
            if (organiserControl.value?.Id == 1) {
                organizerNameENControl?.clearValidators();
                organizerNameFRControl?.clearValidators();
                exposantAutoCompleteControl?.setValidators(Validators.required);
            } else if (organiserControl.value?.Id == 2) {
                exposantAutoCompleteControl?.clearValidators();
                organizerNameENControl?.setValidators(Validators.required);
                organizerNameFRControl?.setValidators(Validators.required);
            }
            organizerNameENControl?.updateValueAndValidity();
            organizerNameFRControl?.updateValueAndValidity();
            exposantAutoCompleteControl?.updateValueAndValidity();
        }
    }

    private initializeOnChangeHandlers(): void {
        let organiserControl = this.conferenceForm.get('Organiser');
        let exposantAutoCompleteControl = this.conferenceForm.get('ExposantAutoComplete');
        let organizerNameENControl = this.conferenceForm.get('OrganizerNameEN');
        let organizerNameFRControl = this.conferenceForm.get('OrganizerNameFR');
        // let organizerWebsiteControl = this.conferenceForm.get('OrganizerWebsite');

        if (organiserControl
            && exposantAutoCompleteControl
            && organizerNameENControl
            && organizerNameFRControl
            // && organizerWebsiteControl
        ) {
            organiserControl.valueChanges.subscribe((value: any) => {
                organizerNameENControl?.setValue(undefined);
                organizerNameFRControl?.setValue(undefined);
                // organizerWebsiteControl?.setValue(undefined);
                if (value.Id == 1) {
                    // organizerWebsiteControl?.disable();

                    organizerNameENControl?.clearValidators();
                    organizerNameFRControl?.clearValidators();
                    // organizerWebsiteControl?.clearAsyncValidators();
                    exposantAutoCompleteControl?.setValidators(Validators.required);

                    organizerNameENControl?.updateValueAndValidity();
                    organizerNameFRControl?.updateValueAndValidity();
                    exposantAutoCompleteControl?.updateValueAndValidity();
                    // organizerWebsiteControl?.updateValueAndValidity();
                }
                else if (value.Id == 2) {
                    exposantAutoCompleteControl?.setValue(undefined);
                    // organizerWebsiteControl?.enable();

                    exposantAutoCompleteControl?.clearValidators();
                    // organizerWebsiteControl?.setValidators([Validators.required, Validators.pattern(WebsiteRegex)]);
                    organizerNameENControl?.setValidators(Validators.required);
                    organizerNameFRControl?.setValidators(Validators.required);


                    organizerNameENControl?.updateValueAndValidity();
                    organizerNameFRControl?.updateValueAndValidity();
                    exposantAutoCompleteControl?.updateValueAndValidity();
                    // organizerWebsiteControl?.updateValueAndValidity();
                }
            })
        }
    }

    private appendAdditionalControls(): void {
        if (this.isProgram) {
            let additionalControls: { [key: string]: AbstractControl } = {
                // Press
                ContactPressName: new FormControl(this.conference?.ContactPressName ?? ''),
                ContactPressEmail: new FormControl(this.conference?.ContactPressEmail ?? '', [Validators.pattern(EmailRegex)]),
                ContactPressPhonePrefixSelected: new FormControl(this.PressPhonePrefixSelected),
                ContactPressPhone: new FormControl(this.conference?.ContactPressPhone ?? ''),

                // Communication
                ContactCommunicationName: new FormControl(this.conference?.ContactCommunicationName ?? ''),
                ContactCommunicationEmail: new FormControl(this.conference?.ContactCommunicationEmail ?? '', [Validators.pattern(EmailRegex)]),
                ContactCommunicationPhonePrefixSelected: new FormControl(this.CommunicationPhonePrefixSelected),
                ContactCommunicationPhone: new FormControl(this.conference?.ContactCommunicationPhone ?? ''),
                ContactCommunicationCompany: new FormControl(this.conference?.ContactCommunicationCompany ?? '')
            };
            for (const controlName in additionalControls) {
                if (additionalControls.hasOwnProperty(controlName)) {
                    this.conferenceForm.addControl(controlName, additionalControls[controlName]);
                }
            }
        }
    }

    private initializeParams(): void {
        let progManagerRoles: number[] = [
            RoleTypeId.DP_SuperAdmin
            , RoleTypeId.ADMIN_FINDERR
            , RoleTypeId.PROG_Administrator
            , RoleTypeId.PROG_Manager];

        let confManagerRoles: number[] = [
            RoleTypeId.DP_SuperAdmin
            , RoleTypeId.ADMIN_FINDERR
            , RoleTypeId.CONF_Administrator
            , RoleTypeId.CONF_Manager];

        let isManager: boolean = false;

        isManager = this.isProgram
            ? this.ims.haveAuthorizedRole(progManagerRoles)
            : this.ims.haveAuthorizedRole(confManagerRoles);

        confManagerRoles = [
            ...confManagerRoles, RoleTypeId.CONF_Writer,
        ]

        progManagerRoles = [
            ...progManagerRoles, RoleTypeId.PROG_Writter
        ]

        let isWriter = this.isProgram ?
            this.ims.haveAuthorizedRole(progManagerRoles) : this.ims.haveAuthorizedRole(confManagerRoles)

        this.isManager = isManager;
        this.isWriter = isWriter
    }

    private processInterestCategories(): void {
        this.interests.sort((a: any, b: any) => {
            return a?.ViewOrder - b?.ViewOrder
        })

        this.interests.forEach((cat: any) => {
            const parentId = cat.ParentCategoryID;
            if (!this.tree[parentId]) {
                this.tree[parentId] = [];
            }
            this.tree[parentId].push(cat);
        });

        this.interestCategoriesTree = this.buildTree(null);
        this.isInterestCategoriesHaveChild = this.interestCategoriesTree.some((x: any) => {
            return x?.children.length > 0
        });
    }

    private buildTree(parentId: any) {
        const children = this.tree[parentId] || [];
        return children.map((child: any) => {
            const node = {
                key: child.CategoryID,
                label: this.app.sharedTools.getLabelSwitchLang(child),
                LabelEN: child.LabelEN,
                LabelFR: child.LabelFR,
                LabelES: child.LabelES,
                data: '',
                icon: '',
                children: this.buildTree(child.CategoryID)
            };
            return node;
        });
    }

    private selectNodesByData(data: any, nodes: TreeNode[] = this.interestCategoriesTree) {
        try {
            for (const node of nodes) {
                if (node.key === data) {
                    this.selectedNodes.push(node);
                    break;
                }
                if (node.children) {
                    this.selectNodesByData(data, node.children);
                }
            }
        } catch (error) {
            console.warn(error);
        }
    }

    private initSelectedAccess(){
        if(this.conference.IsPrivate != null){
            if(this.conference.IsPrivate == true){
                this.selectedAccess = this.Acces.find((x:any)=> x.TypeId == 1)
            }else if(this.conference.IsPrivate == false ){
                this.selectedAccess = this.Acces.find((x:any)=> x.TypeId == 2)
            }
        }
    }

    private initializeConference(): void {
        console.log('conference ,', this.conference);
        if (this.conference) {
            this.documentLogo = this.conference.CnfdLogo;
            this.documentImage = this.conference.CnfdImage;
            this.selectedConfStatus = this.status.find((s: any) => s.StatusID == this.conference.ConfStatus?.StatusID);
            this.initSelectedAccess()
            // to change it when it will be multi select 
            this.selectedCycle = this.cycles.find((c: any) => c.CategoryID == this.conference.CyclesIds[0]);
            this.selectedLanguage = this.languages.find((l: any) => l.LanguageID == this.conference.LanguageDetail?.LanguageID);
            this.selectedTranslated = this.selectYesNoTranslated.find((t: any) => t.Value == this.conference.IsTranslated);
            this.selectedPoc = this.POCs.find((p: any) => p.ContactID == this.conference.PocContactID);
            this.selectedType = this.types.find((t: any) => t.CategoryID == this.conference.ConfTypeCategory);
            this.CommunicationPhonePrefixSelected = (this.conference?.ContactCommunicationPhoneCodeISO2)
                ? this.app.sharedTools.getObjByLabel(this.conference.ContactCommunicationPhoneCodeISO2
                    , "CodeISO2"
                    , this.phones)
                : null;
            this.PressPhonePrefixSelected = (this.conference?.ContactPressPhoneCodeISO2)
                ? this.app.sharedTools.getObjByLabel(this.conference.ContactPressPhoneCodeISO2
                    , "CodeISO2"
                    , this.phones)
                : null;

            //this.selectedInterests = this.interests.filter((i: any) => (this.conference.IntersetsCategoryIds).includes(i.CategoryID) == true);

            if (this.conference.IntersetsCategoryIds && Array.isArray(this.conference.IntersetsCategoryIds)) {
                this.conference.IntersetsCategoryIds.forEach((cat: any) => {
                    this.selectNodesByData(cat, this.interestCategoriesTree);
                });
            }
        }
    }

    private setCategoriesToForm(newValues: any): void {
        let interestCategoriesControl = this.conferenceForm.get('Interest');
        if (interestCategoriesControl) {
            if (newValues && Array.isArray(newValues)) {
                // let selectedNodesKey = newValues.map((v: any)=> v.key);
                // let categories = this.interests.filter((i: any) => (selectedNodesKey).includes(i.CategoryID) == true);

                interestCategoriesControl.setValue(newValues);
            }
            else {
                interestCategoriesControl.setValue([]);
            }
        }
    }
    //#endregion

    //#region retrieve data from remote api service
    private async getEdition() {
        try {
            const response = await this.app.apiService.getEdition();
            this.edition = response;

            if (this.edition?.AllEditionDates
                && Array.isArray(this.edition.AllEditionDates)
                && this.edition.AllEditionDates.length > 0) {
                this.currentEditionDays = this.edition.AllEditionDates.map((d: any) => {
                    return d.replace(/T\d{2}:\d{2}:\d{2}/, `${DateHelper.DateTimeSeparator}${DateHelper.MidnightTime}`);});
                console.log(this.edition);
            }
        } catch (error) {
            console.error(error);
        }
    }

    private async getConfCategories() {
        try {
            const response = await this.app.apiService.getCategories();
            let categoriesIni = cloneDeep(response);

            let typeCategoryType: string = this.isProgram ? CategoryType.DemoType : CategoryType.ConfType;
            let typeCategories = categoriesIni.filter((cat: any) => {
                return cat.CategoryType == typeCategoryType
            });

            let interestsCategories = categoriesIni.filter((cat: any) => {
                return cat.CategoryType == CategoryType.Activities
            });

            let cycleCategories = categoriesIni.filter((cat: any) => {
                return cat.CategoryType == CategoryType.ConfTheme
            })

            this.types = cloneDeep(typeCategories);
            this.interests = cloneDeep(interestsCategories);
            this.cycles = cloneDeep(cycleCategories)

            this.processInterestCategories();
        } catch (error) {
            console.error(error);
        }
    }

    private async getPOCs() {
        try {
            // await this.getRoles();

            // const response = await this.app.apiService
            // .getContactsByRole(this.pocRoleId);

            const response = await this.app.apiService
                .getAllOrganisers(this.isProgram)
            this.POCs = response;
            console.log("POCs", this.POCs);
        } catch (error) {
            console.error(error);
        }
    }

    private async getLanguages() {
        const response = await firstValueFrom(this.app.apiService.getLanguages());

        if (response && Array.isArray(response)) {
            this.languages = response
        }
        else {
            this.languages = [];
        }

        console.log('languages ', this.languages);
    }

    async getStatusTypes() {
        let data = this.app.apiService.getStatus().subscribe((statusResponse: any) => {
            console.log('statusResponse : ', statusResponse);
            if (statusResponse && statusResponse != '' && Array.isArray(statusResponse)) {
                this.status = statusResponse.filter(status => status.StatusType === StatusType.CONFState);
                console.log('status conf : ', this.status);
            }
            else {
                this.status = [];
            }
        });
    }

    private refreshExposantNameAutocomplete(keyword: string): void {
        this.app.apiService.confAutocompleteExposant(keyword).subscribe((data: any) => {
            if (data && data != '' && Array.isArray(data)) {
                this.exposants = data;
            }
            else {
                this.exposants = []
            }
        });
    }

    private async getConference(confId: any) {
        try {
            const response = await this.app.apiService.getConfById(confId);
            console.log("getConference==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                    , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                    , 'KO'
                    , "alert"
                    , () => { }
                    , () => { });
                return;
            }
            this.conference = cloneDeep(response);

            this.initializeConference();
            this.initFormulaire();

        }
        catch (error) {
            console.error(error);
        }
    }

    async getPhones() {
        let data = await firstValueFrom(this.app.apiService.getPhones());
        if (data && Array.isArray(data)) {
            this.phones = data;
        }
        else {
            this.phones = [];
        }
    }

    async getPlacesEvents() {
        let categories = await this.app.apiService.getCategories()
        if (categories && Array.isArray(categories)) {
            if (this.isProgram) {
                this.placesEvents = categories.filter((x: any) => x.CategoryType == CategoryType.ProgPlace)
            } else {
                this.placesEvents = categories.filter((x: any) => x.CategoryType == CategoryType.ConfPlace)
            }
        }
    }

    async getRoles() {
        let roles = await firstValueFrom(this.app.apiService.getRoles());
        if (roles && Array.isArray(roles)) {
            this.roles = roles;
            console.log('roles : ', roles);

            let pocRoleCode: string = RoleCode.Writer;
            let pocRoleType: string = this.isProgram ? RoleType.Program : RoleType.Conference;

            let pocRole = roles.find((r) => r.RoleType == pocRoleType && r.RoleCode == pocRoleCode);
            if (pocRole) {
                this.pocRoleId = pocRole.RoleID;
            }
        }
    }
    //#endregion

    //#region post data to remote api service
    private async createConference(formObj: any): Promise<any> {
        try {
            if (!this.isEditMode) {
                console.log("CREATE Conference ===>", formObj);
                const response = await this.app.apiService.createConf(formObj
                    , this.logoInput?.selectedFile
                    , this.photoInput?.selectedFile
                    , this.constructMedifaUploadInfo());
                console.log('RESPONSE CREATE Conference ===>', response);

                setTimeout(() => {
                    if (response && response.Result) {
                        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                        }, () => {
                        })
                        return;
                    }

                    let msgKey: string = `racine.successMsg.${this.isProgram ? 'successProgramCree' : 'successConferenceCree'}`;
                    this.app.confirm.confirmDialog(""
                        , this.app.translate.instant(msgKey)
                        , 'OK'
                        , "alert"
                        , () => { this.navigateToList(); }
                        , () => { });
                }, 300);
            }
            else {
                console.log("UPDATE Conference ===>", formObj);
                formObj.ConfDetailID = this.conferenceId;
                const response = await this.app.apiService.updateConference(formObj
                    , this.logoInput?.selectedFile
                    , this.photoInput?.selectedFile
                    , this.constructMedifaUploadInfo());
                console.log('RESPONSE UPDATE Conf ===>', response);

                setTimeout(() => {
                    if (response && response.Result) {
                        this.app.confirm.confirmDialog(""
                            , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                            , 'KO'
                            , "alert"
                            , () => { }
                            , () => { })
                        return;
                    }

                    let msgKey: string = `racine.successMsg.${this.isProgram ? 'successProgramUpdt' : 'successConferenceUpdt'}`;
                    this.app.confirm.confirmDialog(""
                        , this.app.translate.instant(msgKey)
                        , 'OK'
                        , "alert"
                        , () => { this.navigateToList(); }
                        , () => { });
                }, 300);

            }

        } catch (error) {
            console.error(error);
        }
    }

    private async setLockImport(conference: any) {
        let objRequest: any = {
            ExternalImportEnabled: conference.ExternalImportEnabled,
            ConfDetailID: conference.ConfDetailID
        }

        console.log("objRequest ==> ", objRequest);

        try {
            const response = await this.app.apiService.conferenceSetLockImport(objRequest);
            console.log("apiService.conferenceSetLockImport ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                    , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                    , 'KO'
                    , "alert"
                    , () => { }
                    , () => { });
                return;
            }

            if (conference.ExternalImportEnabled != true) {
                this.app.confirm.confirmDialog(""
                    , this.app.translate.instant('racine.successMsg.successConferenceLockImport')
                    , 'OK'
                    , "alert"
                    , () => { }
                    , () => { });
            }


        } catch (error) {
            console.error(error);
            return;

        }
    }

    async deleteConference(conference: any) {
        console.log("DELETE Conference ===>", conference);
        try {
            const response = await this.app.apiService.conferenceDelete(conference);
            console.log('Response API : DELETE Conference ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                    , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                    , 'KO'
                    , "alert"
                    , () => { }
                    , () => { });
                return;
            }

            let confirmMsg: string = this.isProgram
                ? 'racine.successMsg.successProgramDelete'
                : 'racine.successMsg.successConferenceDelete';

            this.app.confirm.confirmDialog(""
                , this.app.translate.instant(confirmMsg)
                , 'OK'
                , "alert"
                , () => { this.navigateToList(); }
                , () => { });


        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region  traduire le texte
    canTranslateTo(language: string): boolean {
        if (language == Languages.English) {
            return this.conferenceForm.get('CnfdNameFR')?.value
                || this.conferenceForm.get('CnfdDescriptionFR')?.value;
        }
        else if (language == Languages.French) {
            return this.conferenceForm.get('CnfdNameEN')?.value
                || this.conferenceForm.get('CnfdDescriptionEN')?.value;
        }

        return false;
    }

    onTranslateToENClick() {
        let requestObj = {
            CnfdName: this.conferenceForm.get('CnfdNameFR')?.value ?? '',
            CnfdDescription: this.conferenceForm.get('CnfdDescriptionFR')?.value ?? ''
        }
        console.log("translate", requestObj)

        let requestMsg: string = this.app.translate
            .instant(`jsonTranslateGenerator.translate-json-command-${Languages.English}`);

        let request: any = {
            Message: requestMsg,
            JsonObject: requestObj
        }

        this.chatgptTranslate(request, Languages.English);

        console.log("translate", request)

    }

    onTranslateToFRClick() {
        let requestObj = {
            CnfdName: this.conferenceForm.get('CnfdNameEN')?.value ?? '',
            CnfdDescription: this.conferenceForm.get('CnfdDescriptionEN')?.value ?? ''
        }
        console.log("translate", requestObj)

        let requestMsg: string = this.app.translate
            .instant(`jsonTranslateGenerator.translate-json-command-${Languages.French}`);

        let request: any = {
            Message: requestMsg,
            JsonObject: requestObj
        }

        this.chatgptTranslate(request, Languages.French);

        console.log("translate", request)

    }

    private async chatgptTranslate(request: any, verslanguage: string) {
        try {
            this.spinnerService.disableSpinner();
            this.dialogVisible = true;
            const timerPromise = new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 15000);
            });

            const apiRequestPromise = this.app.apiService.generateTraduction(request);

            const response = await Promise.race([apiRequestPromise, timerPromise]);
            console.log('Response Data: response ', response);

            if (response === timerPromise) {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                }, () => {
                });
                this.dialogVisible = false;
                return;
            }
            console.log('chatgptTranslateTexte response ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', 'alert', () => {
                }, () => {
                });
                return;
            }
            console.log('Response Data:', response);


            if (response !== undefined) {
                try {
                    if (verslanguage === Languages.French) {
                        console.log('API Response for FR--->:', response);
                        this.mapTranslateFRTexte(response);
                        this.dialogVisible = false;
                        this.spinnerService.enableSpinner();
                    }
                    else if (verslanguage === Languages.English) {
                        console.log('API Response for EN--->:', response);
                        this.mapTranslateENTexte(response);
                        this.dialogVisible = false;
                        this.spinnerService.enableSpinner();
                    }
                } catch (error) {
                    console.error('Error parsing response:', error);
                    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                    }, () => {
                    });
                    this.dialogVisible = false;
                }
            } else {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                }, () => {
                });
                this.dialogVisible = false;
            }

        } catch (error) {
            console.error('Error parsing response:', error);
            this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
            }, () => {
            });
            this.dialogVisible = false;
        }
    }

    private mapTranslateENTexte(jsonObject: any): void {
        // Name
        if (jsonObject && Array.isArray(jsonObject) && jsonObject.length > 0) {
            let nameENControl: any = this.conferenceForm.get('CnfdNameEN');

            if (nameENControl && jsonObject[0].CnfdName) {
                nameENControl.setValue(jsonObject[0].CnfdName);
            }
        }
        else if (jsonObject) {
            let nameENControl: AbstractControl<any, any> | null = this.conferenceForm.get('CnfdNameEN');
            if (nameENControl && jsonObject.CnfdName) {
                nameENControl.setValue(jsonObject.CnfdName);
            }

        }

        // Description
        if (jsonObject && Array.isArray(jsonObject) && jsonObject.length > 0) {
            let descriptionENControl: any = this.conferenceForm.get('CnfdDescriptionEN');

            if (descriptionENControl && jsonObject[0].CnfdDescription) {
                descriptionENControl.setValue(jsonObject[0].CnfdDescription);
            }
        }
        else if (jsonObject) {
            let descriptionENControl: AbstractControl<any, any> | null = this.conferenceForm.get('CnfdDescriptionEN');
            if (descriptionENControl && jsonObject.CnfdDescription) {
                descriptionENControl.setValue(jsonObject.CnfdDescription);
            }

        }
    }

    private mapTranslateFRTexte(jsonObject: any): void {
        // Name
        if (jsonObject && Array.isArray(jsonObject) && jsonObject.length > 0) {
            let nameFRControl: any = this.conferenceForm.get('CnfdNameFR');

            if (nameFRControl && jsonObject[0].CnfdName) {
                nameFRControl.setValue(jsonObject[0].CnfdName);
            }

        }
        else if (jsonObject) {
            let nameFRControl: AbstractControl<any, any> | null = this.conferenceForm.get('CnfdNameFR');
            if (nameFRControl && jsonObject.CnfdName) {
                nameFRControl.setValue(jsonObject.CnfdName);
            }

        }

        // Description        
        if (jsonObject && Array.isArray(jsonObject) && jsonObject.length > 0) {
            let descriptionFRControl: any = this.conferenceForm.get('CnfdDescriptionFR');

            if (descriptionFRControl && jsonObject[0].CnfdDescription) {
                descriptionFRControl.setValue(jsonObject[0].CnfdDescription);
            }

        }
        else if (jsonObject) {
            let descriptionFRControl: AbstractControl<any, any> | null = this.conferenceForm.get('CnfdDescriptionFR');
            if (descriptionFRControl && jsonObject.CnfdDescription) {
                descriptionFRControl.setValue(jsonObject.CnfdDescription);
            }

        }
    }

    private constructMedifaUploadInfo(): ConferenceUploadMediaInfo {
        let logoUploadInfo: UploadMediaInfo = new UploadMediaInfo(this.logoInput?.degRotation ?? 0
            , this.conferenceForm.get('OrganizatorLogoUpdated')?.value
            , 1);

        let photoUploadInfo: UploadMediaInfo = new UploadMediaInfo(this.photoInput?.degRotation ?? 0
            , this.conferenceForm.get('ImageUpdated')?.value
            , 1);

        let mediaUploadInfo: ConferenceUploadMediaInfo = new ConferenceUploadMediaInfo(logoUploadInfo
            , photoUploadInfo);

        return mediaUploadInfo;
    }
    //#endregion
}
