import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { AppComponent } from 'src/app/app.component';
import { ImageModelSuffix } from 'src/app/enums/image-model-suffix';
import { Languages } from 'src/app/enums/language';
import { SocialNetworkType } from 'src/app/enums/social-network-type';
import { SharedtoolsService } from 'src/app/services/sharedtools.service';

@Component({
  selector: 'app-mini-card-exhibitor-details',
  templateUrl: './mini-card-exhibitor-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./mini-card-exhibitor-details.component.less']
})
export class MiniCardExhibitorDetailsComponent implements OnInit {


  @Input() exhibitor : any;
  socialNetworkType = SocialNetworkType;

  languages = Languages;
  // country= 'France'
  imageModelSuffix = ImageModelSuffix;

  constructor(public app: AppComponent, private datePipe: DatePipe,private sharedService : SharedtoolsService) {

  }

  ngOnInit(): void {
  }

  formatDayOfWeek(date: any) {
    return this.datePipe.transform(date, 'EEEE', undefined, this.app.translate.currentLang);
  }

  formatDayOfMonth(date: any) {
    return this.datePipe.transform(date, 'd', undefined, this.app.translate.currentLang);
  }

  getCurrentMonth(date: any) {
    return this.datePipe.transform(date, 'MM');
  }

  inscription() {

  }

  async GoToMap(location: any) {
    this.sharedService.goToMap(location)
  }

  openNewTab(url: string): void {
    window.open(url, "_blank")
  }

}
