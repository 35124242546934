import { DatePipe } from '@angular/common';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { DateHelper } from '@helpers/date.helper';
import { cloneDeep } from 'lodash';
import { Menu } from 'primeng/menu';
import { Table } from 'primeng/table';
import { firstValueFrom, Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { AwardsAdvancedSearchLocalStorageKey, AwardsMediaLocalStorageKey } from 'src/app/consts/utils';
import { CategoryType } from 'src/app/enums/category-type';
import { StatusCode } from 'src/app/enums/status-code';
import { StatusType } from 'src/app/enums/status-type';
import { PagePaginationInfo } from 'src/app/models/page-pagination-info';
import { SearchAward } from 'src/app/models/search-award';
import { SearchHelperService } from 'src/app/services/search-helper.service';
import { AwardsRoutes, AwardsRoutesQueryParamsNames } from '../awards.routes';
import { MediasTypes } from 'src/app/enums/media-type';
import { FormCode } from 'src/app/enums/form-code';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-awards-bo-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './awards-bo-list.component.html',
  styleUrls: ['./awards-bo-list.component.less']
})
export class AwardsBoListComponent {

  @ViewChild('dtFiches') dtFiches!: Table;
  @ViewChild('menuAction') menuAction!: Menu;
  @ViewChild('dtMedias') dtMedias!: Table;

  private companyAutocompleteSubscription?: Subscription;
  isLoaded: boolean = false
  search: any = {}
  showAdvancedSearch: boolean = false
  fastSearchFolderKeyword: string = '';
  companyNameSuggestions: string[] = [];
  countries: any[] = []
  maxDate = new Date();
  rangeDates: Date[] = [];
  status: any[] = []
  publishStatus: any[] = []
  catTypeActivities: any[] = []
  showTable: boolean = true
  fiches: any[] = []
  fastFiltersFolder: any = ['NumExposant', 'CompanyName', 'ContactName', 'ContactEmail', 'ContactPhone'];
  rowsCapacity: number = 50
  rowsMediaCapacity: number = 50
  pagePaginationInfo: PagePaginationInfo = { pageNumber: 1, rowSize: 50 }
  pageMediaPaginationInfo: PagePaginationInfo = { pageNumber: 1, rowSize: 50 }
  currentPageTable: number = 1
  currentMediaPageTable: number = 1
  actions: any
  items: any
  colAwardsList: any[] = []
  selectedColumns: any[] = []
  selectedFiches: any[] = []
  currentPageIndex: number = 1
  currentMediaPageIndex: number = 1
  _selectedAction: any
  isMediaLoaded: boolean = false
  medias: any = []
  mediaTypes = MediasTypes
  formCode: FormCode = FormCode.FRM_AWARD;
  showModal: boolean = false;
  modalOptions: any;
  modalContent: any;
  private readonly _awardEnabledPublishStatusCodes: string[]
  = [StatusCode.UNPUBLISHED_FICHE_PUBLISH, StatusCode.PUBLISHED_FICHE_PUBLISH];

  statusCode = StatusCode;

  constructor(public app: AppComponent, private datePipe: DatePipe,
    private searchHelperService: SearchHelperService,
    private helperService : HelperService
  ) { }

  async ngOnInit() {

    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
        console.log("resG", resG);
        this.actions = resG.actionListeAwards;
        this.items = resG.actionsRow;

        this.items.forEach((itemMenu: any) => {
          itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
        });

        this.colAwardsList = resG.colsAwardsList;

        this.selectedColumns = this.colAwardsList;
      });

    this.pagePaginationInfo = this.searchHelperService.getPagePaginationInfo(AwardsAdvancedSearchLocalStorageKey)
    this.rowsCapacity = this.pagePaginationInfo.rowSize ?? 50

    this.pageMediaPaginationInfo = this.searchHelperService.getPagePaginationInfo(AwardsMediaLocalStorageKey)
    this.rowsMediaCapacity = this.pageMediaPaginationInfo.rowSize ?? 50
    await this.getCountries()
    await this.getStatus()
    await this.getCategories()
    this.initAdvancedSearch()
    await this.searchFolders(this.search, true)
  }

  openAwardDetailFiche(productId: any, folderGuid: any) {
    this.app.router.navigate([AwardsRoutes.detailFiche], {
      queryParams: {
        [AwardsRoutesQueryParamsNames.awardId]: productId,
        [AwardsRoutesQueryParamsNames.folderGuid]: folderGuid
      }
    })
  }

  initAdvancedSearch() {
    let searchObject = this.searchHelperService.getAdvancedSearch(AwardsAdvancedSearchLocalStorageKey)
    console.log(searchObject)

    let selectedStatus = this.status.find((sts: any) => {
      return sts.StatusID == searchObject?.selectedStatus?.StatusID
    })

    let selectedWebPublishedStatus = this.publishStatus.find((sts: any) => {
      return sts.StatusID == searchObject?.selectedWebPublishedStatus?.StatusID
    })

    let selectedCountry = this.countries.find((country: any) => {
      return country?.CountryID == searchObject?.selectedCountry?.CountryID
    })

    let selectedCategory = this.catTypeActivities.find((cat: any) => {
      return cat?.CategoryID == searchObject?.selectedCategory?.CategoryID
    })

    this.search = searchObject
    this.search.selectedCategory = selectedCategory
    this.search.selectedStatus = selectedStatus
    this.search.selectedWebPublishedStatus = selectedWebPublishedStatus
    this.search.selectedCountry = selectedCountry
    if (this.search.submissionDateStart) {
      this.rangeDates.push(new Date(this.search?.submissionDateStart))
    }
    if (this.search.submissionDateEnd) {
      this.rangeDates.push(new Date(this.search?.submissionDateEnd))
    }
    // searchObject.
  }

  ngOnDestroy(): void {
    this.unsubscribeActiveSubscriptions()
  }

  toggleMenu(event: any, solution: any, folderGuid: string, items: any) {
    this.menuAction.toggle(event);

    console.log("items:", items);

    items.forEach((itemMenu: any) => {
      itemMenu.solIDRow = solution.ProductID;
      itemMenu.innoIDRow = solution.InnoID;
      itemMenu.FolderGuid = solution.FolderGuid
      itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

      if (itemMenu.ActionID == 1) {
        itemMenu.command = (event: any) => {
          this.app.router.navigate([AwardsRoutes.detailFiche],{
            queryParams : {
              [AwardsRoutesQueryParamsNames.awardId] : event.item.solIDRow,
              [AwardsRoutesQueryParamsNames.folderGuid] : event.item.FolderGuid
            }
          })
        }
      }

      if (itemMenu.ActionID == 2) {
        itemMenu.command = (event: any) => {
          console.log(event.item)
          this.autoLoginAwards(event.item)
        }
      }
    });
  }

  filterFast() {
    console.log("_value", this.fastSearchFolderKeyword);
    this.dtFiches?.filterGlobal(this.fastSearchFolderKeyword, 'contains');
  }

  onActionSelectChange() {
    if (this._selectedAction?.ActionID == 1) {
      this.modalOptions = this.status
      this.modalContent = this.app.translate.instant('bo.list-awards.choix-new-sts')
    } else if (this._selectedAction?.ActionID == 2) {
      this.modalOptions = this.publishStatus
      this.modalContent = this.app.translate.instant('bo.list-awards.choix-new-web-publish-sts')
    }
    this.showModal = true
  }

  clearFastSearch(): void {
    this.fastSearchFolderKeyword = '';
    this.filterFast();
  }

  async getStatus() {
    let statusList = await firstValueFrom(this.app.apiService.getStatus())
    if (statusList && Array.isArray(statusList)) {

      this.status = statusList?.filter((sts: any) => {
        return sts?.StatusType == StatusType.Award_Jury
      })
      this.publishStatus = statusList.filter((s: any) => s.StatusType === StatusType.FichePublishState
    && this._awardEnabledPublishStatusCodes.indexOf(s.Code) >= 0)
    }
    console.log(this.publishStatus)
  }

  async getCategories() {
    let categories = await this.app.apiService.getCategories()
    if (categories && Array.isArray(categories)) {
      this.catTypeActivities = categories?.filter((cat: any) => {
        return cat.CategoryType == CategoryType.AwardCategory
      })
    }
  }

  async searchFolders(search: any, goLatestViewedPage: boolean = false) {
    console.log(search)
    this.searchHelperService.setAdvancedSearch(search, AwardsAdvancedSearchLocalStorageKey)

    let searchRequest: any = {}
    searchRequest = cloneDeep(search)
    if (search.selectedCountry) {
      searchRequest.CountryID = search.selectedCountry.CountryID
    }

    if (search.selectedStatus) {
      searchRequest.StatusID = search.selectedStatus.StatusID
    }

    if (search.selectedWebPublishedStatus) {
      searchRequest.WebPublishedStatus = search.selectedWebPublishedStatus.StatusID
    }

    if (search.selectedCategory) {
      searchRequest.CategoryID = search.selectedCategory.CategoryID
    }

    this.fiches = await firstValueFrom(this.app.apiService.getAwardsList(searchRequest))
    console.log(this.fiches)
    this.fiches.forEach((element: any) => {

      let status = this.status?.find((sts) => {
        return sts.StatusID == element.StatusID
      })

      let webPublishedStatus = this.publishStatus.find((sts: any) => {
        return sts.StatusID == element.WebPublishedStatus
      })

      element.selectedWebPublishedStatus = webPublishedStatus
      element.selectedStatus = status
    })
    this.getMediaFromFolders(this.fiches)
    if (goLatestViewedPage) {
      this.currentPageTable = ((this.pagePaginationInfo.pageNumber ?? 1) - 1) * (this.pagePaginationInfo.rowSize ?? 1);
    }
    this.isLoaded = true
  }

  pageChanged(event: any) {
    let pageIndex = event.first / event.rows + 1
    this.currentPageIndex = pageIndex
    this.updatePaginationInfo(AwardsAdvancedSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
  }

  pageMediaChanged(event: any) {
    let pageIndex = event.first / event.rows + 1
    this.currentMediaPageIndex = pageIndex
    this.rowsMediaCapacity = event.rows
    this.updatePaginationInfo(AwardsMediaLocalStorageKey, this.currentMediaPageIndex, this.rowsMediaCapacity)
  }
  rowsChanged(event: any) {
    this.rowsCapacity = event
    this.updatePaginationInfo(AwardsAdvancedSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
  }

  updatePaginationInfo(key: string, currentPageIndex: number, rowCapacity: number) {
    let pagePaginationInfo: PagePaginationInfo = {
      pageNumber: currentPageIndex,
      rowSize: rowCapacity
    }
    this.searchHelperService.setPagePaginationInfo(pagePaginationInfo, key)
  }

  async getCountries() {
    this.countries = await firstValueFrom(this.app.apiService.getExtendedCountries())
  }

  async clearSearch() {
    this.search = {}
    await this.searchFolders(this.search)
  }

  private unsubscribeActiveSubscriptions(): void {
    if (this.companyAutocompleteSubscription) {
      this.companyAutocompleteSubscription.unsubscribe();
    }
  }

  onSearchCompanyName(event: any): void {
    let keyword: string | undefined = event.query;

    if (!keyword || keyword === '') {
      this.companyNameSuggestions = [];
    }
    else {
      this.refreshCompanyNameAutocomplete(keyword);
    }
  }

  onDateChange(ev: any) {
    if (!ev || ev == null || "") {
      this.search.submissionDateStart = null;
      this.search.submissionDateEnd = null;
      return;
    };
    this.search.submissionDateStart = (ev[0]) ? this.datePipe.transform(ev[0], DateHelper.ApiDateTimeFormat) : null;
    this.search.submissionDateEnd = (ev[1]) ? this.datePipe.transform(ev[1], DateHelper.ApiDateTimeFormat) : null;
  }

  private refreshCompanyNameAutocomplete(keyword: string): void {
    this.companyAutocompleteSubscription = this.app.apiService.companyAutocomplete(keyword).subscribe({
      next: (data: any) => {
        if (data && data != '' && Array.isArray(data)) {
          this.companyNameSuggestions = data;
        }
        else {
          this.companyNameSuggestions = []
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  getColorStatus(WebPublishedStatus: any) {
    let classColor = "";

    switch (WebPublishedStatus.Code) {
      // Publish status
      case StatusCode.UNPUBLISHED_FICHE_PUBLISH:
        classColor = "color-dark-blue"
        break;
      case StatusCode.SCHEDULED_FICHE_PUBLISH:
        classColor = "text-orange-400"
        break;
      case StatusCode.PUBLISHED_FICHE_PUBLISH:
        classColor = "color-lime-green"
        break;

      // Validation status
      case StatusCode.DRAFT_FICHE:
        classColor = "color-light-grey"
        break;

      case StatusCode.TOVALIDATE_FICHE:
        classColor = "text-orange-400"
        break;

      case StatusCode.UNDER_REVIEW_FICHE:
        classColor = "color-bluegray"
        break;

      case StatusCode.VALIDATED_FICHE:
        classColor = "color-lime-green"
        break;

      case StatusCode.CANCELED_FICHE:
        classColor = "color-red"
        break;

      default:
        classColor = "color-dark-blue"
        break;
    }
    return classColor;
  }

  getMediaFromFolders(folders: any) {
    let i = 0;
    this.medias = [];
    folders.forEach((folder: any) => {

      folder.Media.forEach((media: any) => {
        if (media?.typeDocument?.TypeDOCCode === this.mediaTypes.IMAGE
          || media?.typeDocument?.TypeDOCCode === this.mediaTypes.LOGO) {
          this.medias[i] = media;
          i++;
        }
      });
    });
    console.log(this.medias)
    this.currentMediaPageTable = ((this.pageMediaPaginationInfo.pageNumber ?? 1) - 1) * (this.pageMediaPaginationInfo.rowSize ?? 1);
    this.isMediaLoaded = true
  }
  async updatePublishStatus(folder: any) {
    await this.updateAwardStatus([folder.ProductID], folder.selectedWebPublishedStatus.StatusID, true)
  }

  async updateStatus(folder: any) {
    await this.updateAwardStatus([folder.ProductID], folder.selectedStatus.StatusID, false)
  }

  async updateAwardStatus(productIds: any, statusID: any, changePublishStatus: boolean, goLatestViewedPage: boolean = true) {
    let request: any = {}
    request.ProductIds = productIds
    request.StatusID = statusID
    request.ChangePublishStatus = changePublishStatus
    let response = await firstValueFrom(this.app.apiService.updateAwardStatusList(request))
    this.searchFolders(this.search, goLatestViewedPage)
    if (!goLatestViewedPage) {
      this.rejectAction()
    }
  }

  async acceptAction(event: any) {
    console.log(event)
    console.log(this._selectedAction)
    console.log(this.selectedFiches)
    let productIds = this.selectedFiches?.map((fiche: any) => {
      return fiche?.ProductID
    })
    let changePublishStatus = this._selectedAction.ActionID == 2 ? true : false
    let response = await this.updateAwardStatus(productIds, event?.StatusID, changePublishStatus, false)
  }

  rejectAction() {
    this.showModal = false
    this._selectedAction = null
  }

  async autoLoginAwards(folder : any){
    let autoLoginUrl = await firstValueFrom(this.app.apiService.getCustomUrlAutoLogin())
    if(autoLoginUrl != undefined && autoLoginUrl != ""){
      let redirectUrl = `${AwardsRoutes.welcome}?${AwardsRoutesQueryParamsNames.folderGuid}=${folder?.FolderGuid}`
      let encodedUrl = this.helperService.generateRedirectAndEncodeUri(autoLoginUrl, redirectUrl)
      window.open(encodedUrl, "_blank")
    }
  }
}
