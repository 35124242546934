export class FolderIndirectNew {
    ParentFolderID?: number | null = null;
    CompanyName?: string | null = '';
    CatalogName?: string | null = '';
    AddressLine1?: string | null = '';
    AddressLine2?: string | null = '';
    AddressLine3?: string | null = '';
    ZipCode?: string | null = '';
    City?: string | null = '';
    CountryID?: number | null = null;
    Email?: string | null = '';
    PhonePrefix?: string | null = '';
    PhoneNumber?: string | null = '';
    Website?: string | null = '';
    DP_CivilityID?: number | null = null;
    DP_FirstName?: string | null = '';
    DP_LastName?: string | null = '';
    DP_FunctionID?: number | null = null;
    DP_OtherFunction?: string | null = '';
    DP_Email?: string | null = '';
    DP_PhonePrefix?: string | null = '';
    DP_PhoneCodeIso?: string | null = '';
    DP_PhoneNumber?: string | null = '';
    DP_MobilePrefix?: string | null = '';
    DP_MobileCodeIso?: string | null = '';
    DP_MobileNumber?: string | null = '';
    DP_LanguageID?: number | null = null;
    CountrySelected?: any = null;
    PhonePrefixSelected: any = null;
    selectedDPCivility: any = null;
    selectedDpFunction: any = null;
    selectedDPLanguage: any = null;
    adresseComplete?: string = '';
    DPPhonePrefixSelected: any = null;
    DPMobilePrefixSelected: any = null;
    IsBillingAddressSameAsPrincipal: boolean = true;
    IsDPManagerSameAsLegal: boolean = true;
    Activities?: string[] | null;
    OtherActivity?: string | null = '';
    //Activities?: [] = [];

    public getListNotRequireds(): boolean {
        const notRequiredValidators: any = ['AddressLine2', 'AddressLine3', 'adresseComplete', 'ParentFolderID', 'CountryID', 'PhonePrefix', 'PhoneCodeIso', 'DP_CivilityID', 'DP_FunctionID', 'DP_PhonePrefix', 'DP_PhoneCodeIso', 'DP_MobilePrefix', 'DP_MobileCodeIso', 'DP_LanguageID', 'DP_OtherFunction', 'OtherActivity', 'Website'];
        return notRequiredValidators;
    }
}