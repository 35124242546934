import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-admin-exposants-detail',
    templateUrl: './admin-exposants-detail.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./admin-exposants-detail.component.less']
})
export class AdminExposantsDetailComponent {
    menutabs: any = [];
    paths: any = [];
    currentPath: any = "";
    constructor(
        public app: AppComponent,
    ) { }

    ngOnInit() {
        let user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log(user);
        if (!user) {
            this.app.router.navigate(['/login']);
            return;
        }

        this.paths = location.pathname.split('/');
        console.log("this.paths ==> ", this.paths);
        this.currentPath = this.paths[1];
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                this.menutabs = resG.tabMenu[this.currentPath];
            });
    }
}
