import { Component, OnInit } from "@angular/core";
import VisioWebEssential from "../../assets/VisioMap/map.essential.js";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-map-view",
  templateUrl: "./map-view.component.html",
  styleUrls: ["./map-view.component.less"],
})
export class MapViewComponent implements OnInit {
  essential: any;
  arrIdsSelected: any = [];
  isModalOpen: boolean = false;
  locations: any[] = [];
  zoomCamera: number = 100;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.essential = new VisioWebEssential({
      element: document.getElementById("container"),
      imagePath: "./assets",
    });
    this.essential.setParameters({
      parameters: { baseURL: "../../assets/VisioMap", hash: "" },
    });
    this.essential.setParameters({
      parameters: { locale: { language: navigator.language } },
    });

    this.essential
      .createMapviewer()
      .then(() => {
        this.mapParams()
      })
      .catch((e: any) => {
        // eslint-disable-line
        console.log(e);
        console.log(
          "Could not load map. Check your config or get in touch with Visioglobe team."
        ); // eslint-disable-line
      });
  }

  mapParams() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      console.log("params:", params);
      if (params) {
        if (params.myArrayMatPoints) {
          let arrStand = JSON.parse(params.myArrayMatPoints);
          if (arrStand.length == 1) {
            console.log("UNE LISTE");

            console.log("IS DEFAULT======================");
            if (this.arrIdsSelected.length > 0) {
              this.arrIdsSelected.forEach((idSelected: any) => {
                this.essential.content.resetPlaceColor({ id: idSelected });
              });
              this.arrIdsSelected = [];
            }

            if (arrStand[0].MapPoint) {
              setTimeout(() => {
                this.arrIdsSelected.push(arrStand[0].MapPoint);
                console.log("arrStand[0].MapPoint-----", arrStand[0].MapPoint);
                this.essential.venue.goToPlace({ id: arrStand[0].MapPoint });
                this.essential.content.setPlaceColor({
                  id: arrStand[0].MapPoint,
                  color: "red",
                });
                var pois = this.essential._mapviewer.getPOIs(
                  arrStand[0].MapPoint
                );
                console.log("POIS------", pois);
                var position;
                var floor;
                if (
                  pois[arrStand[0].MapPoint] &&
                  pois[arrStand[0].MapPoint].length > 0
                ) {
                  var options = pois[arrStand[0].MapPoint][0].options();
                  position = options.position;
                  console.log("position------>", position);
                  this.essential._mapviewer.addPOI({
                    url: "/assets/images/black-marker.png",
                    //  text: 'The object "'+element.vg.id+'" has been clicked',
                    position: position,
                    alignment: {
                      x: 0,
                      y: 1,
                    },
                    id: "pictoLoc",
                    // onObjectMouseUp: function() {alert('click');},
                    scale: 6,
                  });
                }
              }, 1);
            } else {
              console.log("No map point data");
            }
            return;
          }

          this.isModalOpen = true; //ouverture de la modal
          console.log("MODAL---", this.isModalOpen);
          //let arrStand = JSON.parse(params.myArrayMatPoints);
          let batimants = [];
          let lang = localStorage.getItem("langue");
          console.log("LANGUE*********************----------", lang);
          for (var i = 0; i < arrStand.length; i++) {
            if (lang == "en")
              if (arrStand[i].Hall.includes("EXT"))
                arrStand[i].Hall = "OUTDOOR";
            if (lang == "fr")
              if (arrStand[i].Hall.includes("EXT"))
                arrStand[i].Hall = "EXTERIEUR";
          }

          console.log(arrStand);

          // let tab = _.groupBy(arrStand, "Hall");

          this.locations = [];
          // Object.entries(tab).forEach((entry) => {
          //   const [key, value] = entry;
          //   console.log(key, value);
          //   this.locations.push({ group: key, list: value });
          // });

          // this.locations = _.orderBy(this.locations, ["group"], ["asc"]);
          console.log("LOCATIONS----", this.locations);
        } else {
          setTimeout(() => {
            if (params.mapPoint) {
              let pointMap = params.mapPoint.replaceAll(" ", "");
              console.log("***MapPoint Splité***", pointMap);
              this.essential._mapviewer.camera.minRadius = this.zoomCamera;
              this.essential.venue.goToPlace({ id: pointMap });
              this.essential.content.setPlaceColor({
                id: pointMap,
                color: "red",
              });
              var pois = this.essential._mapviewer.getPOIs(pointMap);
              console.log("POIS------", pois);
              var position;
              var floor;
              if (pois[pointMap] && pois[pointMap].length > 0) {
                var options = pois[pointMap][0].options();
                floor = options.floor;
                position = options.position;

                console.log("position------>", position);

                this.essential._mapviewer.addPOI({
                  url: "/assets/images/black-marker.png",
                  //  text: 'The object "'+element.vg.id+'" has been clicked',
                  position: position,
                  alignment: {
                    x: 0,
                    y: 1,
                  },
                  id: "pictoLoc",
                  // onObjectMouseUp: function() {alert('click');},
                  scale: 6,
                });
              }
            } else if (params.building) {
              if (params.building == "OUT") {
                this.essential.venue.goToGlobal();
              } else {
                this.essential.venue.goToBuilding({ id: params.building });
              }
            }
          }, 1);
        }
      }
    });
  }
}
