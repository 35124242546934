import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { IdentityManagerService } from '../services/identity-manager.service';
import { RoleTypeId } from '../enums/role-type-id';

@Component({
  selector: 'app-products-carousel-program',
  templateUrl: './products-carousel-program.component.html',
  styleUrls: ['./products-carousel-program.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ProductsCarouselProgramComponent {
  @Input() products: any[] = [];
  @Input() numbers: any;
  listInnov: any[] = [];
  formProgramID!: number;
  programForm: any;
  IsRoleWriter:boolean=false;
  user: any;

  teeest(product:any){
    console.log(product)
  }
  responsiveOptions: any[] = [
      {
          breakpoint: '1199px',
          numVisible: 2,
          numScroll: 1 
      },
      {
          breakpoint: '991px',
          numVisible: 2,
          numScroll: 1
      },
      {
          breakpoint: '767px',
          numVisible: 1,
          numScroll: 1
      }
  ];

  constructor(
      public app: AppComponent,
      private ims : IdentityManagerService
  ) { }

  ngOnInit() {
      this.user = JSON.parse(sessionStorage.getItem('userData')!);
      this.app.sharedTools.getJSON('general_data').subscribe(
          resG => {

          });
      console.log("****this number", this.numbers)
      console.log(this.user.RoleID);
    //   if(this.ims.haveAuthorizedRole([RoleTypeId.HLP_Writer])
    //   && !this.ims.haveAuthorizedRole([RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator, RoleTypeId.HLP_Exhibitor])){
    //       this.IsRoleWriter=true;
    //    }
      }


  async getProgramForm() {
      this.programForm = await this.app.apiService.getProgramForm({ ProgFormID: this.formProgramID });
      this.products = this.programForm?.Solutions ? this.programForm?.Solutions : [];
      

      //map other properties to product in products list (solutions)
       this.products.forEach((p: any) => {
      //     //this.mapSuppPropertiesToProduct(p);
       })

      
  }
}
