export module ConferenceHelper {
    export function CompanyName(speaker: any): string {
        if (speaker?.CompanyName2) {
            return speaker.CompanyName2
        }
        else if (speaker?.CompanyName1) {
            return speaker.CompanyName1;
        }

        return "";
    }
}