import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';
import { HelperService } from '../services/helper.service';
import { DatePipe } from '@angular/common';
import { DateHelper } from '../helpers/date.helper';
import { StatusType } from '../enums/status-type';
import { StatusCode } from '../enums/status-code';
import { Menu } from 'primeng/menu';
import { CsvExportSameColumnSeparator, ListNewsAdvancedSearchLocalStorageKey } from '../consts/utils';
import { Table } from 'primeng/table';
import { SearchNews } from '../models/search-news';
import { RoleType } from '../enums/role-type';
import { SearchHelperService } from '../services/search-helper.service';
import { firstValueFrom } from 'rxjs';
import { PagePaginationInfo } from '../models/page-pagination-info';
@Component({
  selector: 'app-liste-news',
  templateUrl: './liste-news.component.html',
  styleUrls: ['./liste-news.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ListeNewsComponent implements OnInit {
  @ViewChild('menuAction') menuAction!: Menu;
  rangeDates: Date[] = [];
  fastSearchNewsKeyword: any
  showAdvancedSearch: boolean = false
  search: SearchNews = new SearchNews();
  StatePubOptions: any
  areasPublicationsOptions: any;
  RolesOptions: any
  newsCategories : any
  LanguageOptions: any
  menuActionItems: [] = [];
  news: any[] = []
  selectedColumns: any[] = []
  listeNewsColumns: any[] = []
  currentPageTable: number = 1
  currentPageIndex: number = 1
  rowsCapacity: number = 100
  pagePaginationInfo: PagePaginationInfo = { pageNumber: 1, rowSize: 100 }
  isLoaded: boolean = false
  // fastFiltersNews: any;


  get fastFiltersNews(): string[] {
    return [`Language.${this.app.sharedTools.getLangPropertyName('Label')}`
      , 'NewsName'
      , `NewsWebPublishedStatus.${this.app.sharedTools.getLangPropertyName('Label')}`
      , 'PublicationDate'
      , 'PublicationAreas'
      , `NewsTitleEN`
      , `NewsTitleES`
      , `NewsTitleFR`
    ]
  }
  selectedNews: any[] = []
  selectedAction: any
  groupedActions: any[] = [];
  tempMenuActions : [] = []
  @ViewChild('dtNews') dtNews!: Table;

  constructor(public app: AppComponent,
    public helperService: HelperService,
    private datePipe: DatePipe,
    private searchHelperService: SearchHelperService ) { }

  async ngOnInit() {
    let user = JSON.parse(sessionStorage.getItem('userData')!);
    if (!user) {
      this.app.router.navigate(['/login']);
      return;
    }

    this.app.sharedTools.getJSON('general_data').subscribe(
      data => {
        this.selectedColumns = data.colsNewsList
        this.listeNewsColumns = data.colsNewsList
        this.groupedActions = data.actionListeNews
        this.menuActionItems = data.actionsNewsRow;
        this.menuActionItems.forEach((itemMenu: any) => {
          itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
        });
        this.tempMenuActions = this.menuActionItems
      }
    )

    this.pagePaginationInfo = this.searchHelperService.getPagePaginationInfo(ListNewsAdvancedSearchLocalStorageKey)

    // TODO: @MohamedAli is temporary
    if(!localStorage.getItem(ListNewsAdvancedSearchLocalStorageKey)){
      this.pagePaginationInfo.rowSize = 100;
    }

    this.rowsCapacity = this.pagePaginationInfo.rowSize ?? 100

    await this.getStatusTypes();
    await this.getRoles();
    await this.getLanguages();
    await this.getAllAreasPublications();
    await this.getNewsCategories()
    this.search = cloneDeep(this.searchHelperService.getAdvancedSearch(ListNewsAdvancedSearchLocalStorageKey))
    this.initAdvancedSearch()
    this.getAllNews(this.search, true);
  }

  customSort(event : any){
    if(event.field == "NewsTitle"){
      if(event.order == 1){
        this.helperService.sortTable(event?.data,`NewsTitle${this.app.translate.currentLang.toUpperCase()}`,true)
      }
      else{
        this.helperService.sortTable(event?.data,`NewsTitle${this.app.translate.currentLang.toUpperCase()}`,false)
      }
    }
    if(event.field == "State"){
      if(event.order == 1){
        this.helperService.sortTable(event?.data,`NewsWebPublishedStatus.Label${this.app.translate.currentLang.toUpperCase()}`,true)
      }
      else{
        this.helperService.sortTable(event?.data,`NewsWebPublishedStatus.Label${this.app.translate.currentLang.toUpperCase()}`,false)
      }
    }

    if(event.field == "FormattedPublicationDate"){
      if(event.order == 1){
        this.helperService.sortTable(event?.data,`PublicationDate`,true, true)
      }
      else{
        this.helperService.sortTable(event?.data,`PublicationDate`,false, true)
      }
    }

    if(event.field == "ZonePublication"){
      if(event.order == 1){
        this.helperService.sortTable(event?.data,`PublicationAreas`,true, false, this.app.sharedTools.getLangPropertyName('Label'));
      }
      else{
        this.helperService.sortTable(event?.data,`PublicationAreas`,false, false, this.app.sharedTools.getLangPropertyName('Label'));
      }
    }
  }

  async openFrmCp(news : any){
    let autoLoginUrl = await firstValueFrom(this.app.apiService.getCustomUrlAutoLogin())
    if(autoLoginUrl != undefined && autoLoginUrl != ""){
      let redirectUrl = `communique-press?folderguid=${news?.FolderGuid}`
      let encodedUrl = this.helperService.generateRedirectAndEncodeUri(autoLoginUrl, redirectUrl)
      window.open(encodedUrl, "_blank")
    }
  }
  

  async getNewsCategories() {
    let response = await firstValueFrom(this.app.apiService.getNewsCategories())
    if (response && response != '' && Array.isArray(response)) {
      this.newsCategories = response;
    }
  }

  private initAdvancedSearch() {
    let selectedState = this.StatePubOptions?.find((x: any) => x?.StatusID == this.search.selectedState?.StatusID)
    this.search.selectedState = selectedState
    this.rangeDates = [];

    if (this.search.DateMin) {
      this.rangeDates.push(new Date(this.search.DateMin));
    }

    if (this.search.DateMax) {
      this.rangeDates.push(new Date(this.search.DateMax));
    }
  }

  addNews() {
    this.app.router.navigate(["detail-actualite"])
  }


  async getStatusTypes() {
    let statusResponse = await firstValueFrom(this.app.apiService.getStatus())
    if (statusResponse && statusResponse != '' && Array.isArray(statusResponse)) {
      this.StatePubOptions = statusResponse.filter(status => status.StatusType === StatusType.NEWS_Publish_State);
    }
    else {
      this.StatePubOptions = []
    }
  }

  async getRoles() {
    let roleResponse = await firstValueFrom(this.app.apiService.getRoles())
    if (roleResponse && roleResponse != '' && Array.isArray(roleResponse)) {
      this.RolesOptions = roleResponse.filter(role => role.RoleType === RoleType.News);
    }
  }

  async getLanguages() {
    let languages = await firstValueFrom(this.app.apiService.getLanguages())
    if (languages && languages != '' && Array.isArray(languages)) {
      this.LanguageOptions = languages;
    }
  }

  async getAllAreasPublications() {
    let areaPubs = await firstValueFrom(this.app.apiService.GetAllAreasPublications())
    if (areaPubs && areaPubs != '' && Array.isArray(areaPubs)) {
      this.areasPublicationsOptions = areaPubs;
    }
  }

  resetMenuAction(){
    this.menuActionItems = cloneDeep(this.tempMenuActions)
  }

  toggleMenu(event: any, news: any) {
    this.resetMenuAction()
    if(!news.FolderGuid){
      let openFrmActionIndex = this.menuActionItems?.findIndex((x:any)=> x?.ActionID == 2)
      if(openFrmActionIndex != -1){
        this.menuActionItems?.splice(openFrmActionIndex, 1)
      }
    }

    this.menuAction.toggle(event);

    this.menuActionItems.forEach((itemMenu: any) => {
      itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

      if (itemMenu.ActionID == 1) {
        itemMenu.command = (event: any) => {
          this.app.router.navigate(["detail-actualite"], { queryParams: { id: news.NewsID } });
        }
      }

      if (itemMenu.ActionID == 2) {
        itemMenu.command = (event: any) => {
          this.openFrmCp(news)
        }
      }
    });
  }

  navigateToDetail(news: any) {
    this.app.router.navigate(["detail-actualite"], { queryParams: { id: news.NewsID } });
  }

  onDateChange(ev: any) {
    if (!ev || ev == null || "") {
      this.search.DateMin = null;
      this.search.DateMax = null;
      return;
    };
    this.search.DateMin = (ev[0]) ? this.datePipe.transform(ev[0], DateHelper.ApiDateTimeFormat) : null;
    this.search.DateMax = (ev[1]) ? this.datePipe.transform(ev[1], DateHelper.ApiDateTimeFormat) : null;
  }

  getColorStatus(status: any) {
    let classColor = "";
    switch (status.Code) {
      case StatusCode.VALIDATED:
        classColor = "color-lime-green"
        break;

      case StatusCode.REJECTED:
        classColor = "color-red"
        break;

      case StatusCode.WAITING:
        classColor = "color-orange"
        break;

      case StatusCode.EXPIRED:
        classColor = "color-blue"
        break;


      case StatusCode.PUBLISHED_NEWS:
        classColor = "color-lime-green"
        break;


      case StatusCode.UNPUBLISHED_NEWS:
        classColor = "color-orange"
        break;

      default:
        classColor = "color-black"
        break;
    }

    return classColor;
  }

  modalOptions: any;
  showModal: boolean = false;
  onActionSelectChange(action: any) {
    this.showModal = true
    this.modalOptions = this.StatePubOptions;
  }

  async onChangePubState(newsID: any, statusID: any) {
    let listIds = []
    listIds.push(newsID)
    let body = {
      NewsDetailIDs: listIds,
      StatusID: statusID
    };
    try {
      const response = await this.app.apiService.ChangeNewsStatus(body);
      this.search = new SearchNews()
      this.rangeDates = [];
      this.getAllNews(this.search, true)
    } catch (error) {
      console.error(error);
    }
  }

  async acceptAction(event: any) {
    console.log(event, "event")
    let body = {
      NewsDetailIDs: this.selectedNews.map((x: any) => x.NewsID),
      StatusID: event.StatusID
    };
    try {
      await this.app.apiService.ChangeNewsStatus(body);
      this.showModal = false;
      this.getAllNews(this.search, true)
      this.selectedAction = undefined
      this.selectedNews = []
    } catch (error) {
      console.error(error);
    }

  }

  rejectAction() {
    this.showModal = false
    this.selectedAction = undefined
    this.selectedNews = []
  }

  async getAllNews(search: SearchNews, goLatestViewedPage: boolean = false) {
    this.searchHelperService.setAdvancedSearch(search, ListNewsAdvancedSearchLocalStorageKey)
    let langList: any[] = []
    if (search?.LanguageList != null) {
      search?.LanguageList.forEach((lang: any) => {
        langList.push(lang)
      })
    }

    let pubList: any[] = []
    if (search?.PublicationArea != null) {
      search?.PublicationArea.forEach((pub: any) => {
        pubList.push(pub.NewsChannelID)
      })
    }
    let body: any = {
      DateMin: search.DateMin,
      DateMax: search.DateMax,
      TitleEN: undefined,
      TitleES: undefined,
      TitleFR: undefined,
      LanguageList: search.LanguageList?.map((x: any) => { return x?.Code }),
      StatusID: search.selectedState?.StatusID,
      PublicationArea: pubList,
      Categories : search.Categories?.map((x:any)=> {return x?.CategoryID})
    };

    body[`Title${this.app.translate?.currentLang?.toUpperCase()}`] = search.Title

    try {
      const response = (await this.app.apiService.searchNews(body))
        .subscribe((news: any) => {
          if (news && Array.isArray(news)) {
            this.news = news;
            this.news.forEach((p: any) => {
              p.NewsWebPublishedStatus = this.StatePubOptions.find((n: any) => {
                return p.NewsWebPublishedStatus?.StatusID == n.StatusID
              });
              p.FormattedPublicationDate = p.PublicationDate ? new Date(DateHelper.extractDate(p.PublicationDate)) : null;
            });
            if (goLatestViewedPage) {
              this.currentPageTable = ((this.pagePaginationInfo.pageNumber ?? 1) - 1) * (this.pagePaginationInfo.rowSize ?? 1);
            }
            this.isLoaded = true
          }
        })
    } catch (error) {
      console.error(error);
    }
  }

  pageChanged(event: any) {
    let pageIndex = event.first / event.rows + 1
    this.currentPageIndex = pageIndex
    this.updatePaginationInfo(ListNewsAdvancedSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
  }
  rowsChanged(event: any) {
    this.rowsCapacity = event
    this.updatePaginationInfo(ListNewsAdvancedSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
  }

  updatePaginationInfo(key: string, currentPageIndex: number, rowCapacity: number) {
    let pagePaginationInfo: PagePaginationInfo = {
      pageNumber: currentPageIndex,
      rowSize: rowCapacity
    }
    this.searchHelperService.setPagePaginationInfo(pagePaginationInfo, key)
  }



  filterFast() {
    this.dtNews.filterGlobal(this.fastSearchNewsKeyword, 'contains');
  }




  clearFastSearch() {
    this.fastSearchNewsKeyword = '';
    this.filterFast();
  }

  toggleAdvancedSearch() {
    this.showAdvancedSearch = !this.showAdvancedSearch
  }

  getTheTooltip() {
    return ""
  }



  clearSearch() {
    this.search = new SearchNews()
    this.rangeDates = [];
    this.getAllNews(this.search)
  }

  searchNews() {
    this.getAllNews(this.search);
  }

  exportCSV() {
    let fileName: string = "";
    fileName = this.app.translate.instant('bo.liste-news.fileName');
    fileName = fileName && fileName != '' ? fileName : 'list_news';
    let dateStr: string = this.datePipe.transform(new Date(), 'dd_MM_yyyy') ?? "";
    let hourStr: string = this.datePipe.transform(new Date(), 'HH_mm_ss') ?? "";
    fileName = fileName.replace('{date}', dateStr);
    fileName = fileName.replace('{hour}', hourStr);
    console.log("this is news ", this.news)
    this.helperService.exportCSV(this.news, this.mapColumn(), fileName + new Date().toLocaleDateString(), true);
    console.log("this is exportCSV")
  }


  mapColumn() {
    return this.news.map((row: any) => {
      const filteredColumns: any = {};
      console.log("mapcolumns selectedcols ", row)
      this.selectedColumns.forEach(column => {
        switch (column.field) {
          case "Langue": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] =
            this.getelementsText(row.Languages);
            break;
          case "Title": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] =
            this.app.sharedTools.getPropertySwitchLang(row, 'NewsName') ?? ''
            break;
          case "State": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] =
            this.app.sharedTools.getLabelSwitchLang(row.NewsWebPublishedStatus)

            break;
          case "Date": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] =
            this.datePipe.transform(row.PublicationDate, 'dd/MM/yyyy HH:mm:ss')
            break;
          case "ZonePublication": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] =
            this.getelementsText(row.PublicationAreas);
            break;

          default:
            break;
        }
      });
      return filteredColumns;
    })
  }

  private getelementsText(elements: any[]): string {
    let elementsText: string = "";
    if (!(elements && Array.isArray(elements))) {
      return elementsText;
    }

    elements.forEach((lang: any) => {
      if (this.app.sharedTools.getLabelSwitchLang(lang)) {
        elementsText += `${this.app.sharedTools.getLabelSwitchLang(lang)} ${CsvExportSameColumnSeparator}`
      }
    });

    return elementsText.slice(0, -2); // to delete the last "CsvExportSameColumnSeparator"
  }


}

