import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { CategoryType } from '../enums/category-type';
import { StatusType } from '../enums/status-type';
import { Plage } from '../models/plage';
import { DatePipe } from '@angular/common';
import { DateHelper } from '../helpers/date.helper';
import { CustomValidators } from '../validators/custom-validators';
import { ExtendedCountries } from '../enums/extended-countries';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-create-vm-detail-do',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './create-vm-detail-do.component.html',
  styleUrls: ['./create-vm-detail-do.component.less']
})
export class CreateVmDetailDoComponent {
  //#region local properties

  //#region informations form
  isODFormSubmitted: boolean = false;
  initODForm: boolean = false;
  odForm!: FormGroup;
  isODFormEditMode: boolean = false;

  //drop down selected item
  SelectedTypeCategory: any;
  SelectedSectorCategory: any;
  SelectedCountry: any;
  SelectedStatus: any;
  SelectedActivities : any;
  Activities : any;


  //actions
  //used @ lock/unlock action
  ExternalImportActive: boolean = true;
  //#endregion 

  //#region composition form
  initCompositionForm: boolean = false;
  compositionForm!: FormGroup;
  isCompositionFormEditMode: boolean = false;
  isRepresentedBy: boolean = false;
  showCompositionForm: boolean = false;

  //drop down selected item
  selectedODContactRole: any;

  //calculated properties
  nextContactPlaceholder: string = '';
  nextContactRole: any;

  //
  focusedContact: any;
  //#endregion

  //#region get od
  odID: any;
  odModel?: any;
  CodeExt: any;
  DateImprt: any;
  HaveVisit: any;
  //#endregion

  //#region contact component requirements
  compModel?: any;
  selectedContactsMap: any = {};
  availableContactsMap: any = {};
  //#endregion

  //#region data sources
  //api data sources
  odTypeCategories: any[] = [];
  odSectorCategories: any[] = [];
  countries: any = [];
  status: any = [];
  edition: any;
  allEditionDates: Date[] = [];

  availableContactsList: any = [];
  odContactsList: any = [];

  //local json data sources
  odContactRoles: any[] = [];

  //calculated data sources
  plages: Plage[] = [];

  // categories tree select
    interestCategoriesTree!: TreeNode[];
    tree: any = {};
    isInterestCategoriesHaveChild: boolean = true;
    selectedNodes: any = [];
  //#endregion

  //#region routing info (to retirieve parameters : od ID)
  paths = location.pathname.split('/');
  currentPath: any = null;
  //#endregion

  civilities: any[] = [];
  langues: any[] = [];

  //#region ctor
  constructor(
    public app: AppComponent,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe) {

  }
  //#endregion

  //#region handle life cycle hooks

  
  async ngOnInit() {
    let cuser = JSON.parse(sessionStorage.getItem('userData')!);
    console.log(cuser);
    if (!cuser) {
      this.app.router.navigate(['/login']);
      return;
    }

    //check create or edit OD
    console.log("this.path==> ", this.paths);
    this.currentPath = this.paths[1];
    var urlId: any = this.app.router.url.split('/').pop();
    console.log("🚀  urlId:", urlId)
    if (this.currentPath == 'detail-vm-do') {
      this.odID = parseInt(urlId);
      console.log("🚀  this.odID:", this.odID)

    }

    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
        this.odContactRoles = resG.vmDOContactRoles;
        console.log('this.odContactRoles ', this.odContactRoles)

        this.getEdition();
        this.getCountries();
        this.getStatus();
        this.getCategories();
        this.getCivilities();
        this.getLanguages();


        if (this.odID) {
          this.isODFormEditMode = true;
          this.getOD(this.odID);
          this.refreshComposition();
          console.log("this.odID ==> ", this.odID);
        }
        else {
          setTimeout(() => {
            this.initializeForm();
            //this.initializeCompositionForm();
            console.log("this.odForm", this.odForm);

          }, 500);
        }

      });

  }
  //#endregion

  //#region public actions (getters) 
  getPlageArrayControls(): AbstractControl<any, any>[] {
    let controls: AbstractControl<any, any>[] = (this.odForm.get('Plages') as FormArray).controls;

    return controls;
  }

  get DOPlages(): FormArray { return this.odForm.get('Plages') as FormArray; }
  //#endregion

  //#region handle events
  //tab information
  onDeleteOD() {
    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteOD'), 'AUTRE', 'confirm', () => {
      this.deleteOD(this.odModel);
    }, () => { });
  }

  onCancel() {
    this.app.sharedTools.redirectTo('/admin-delegations');
  }

  onSubmit() {
    this.isODFormSubmitted = true;
    console.log('submit [this.odForm]', this.odForm);

    if (this.odForm.invalid) {
      console.log("invalid form ===>", this.odForm);
      console.log("invalid form ===>", this.odForm.errors);

      //bloc explore form errors

      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.odForm.controls;
      console.log("this is controls ", controls)
      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log('Invalid form values:', invalidValues);

      if (invalidField && invalidField.length > 1) {
        console.log("invalidField", invalidField)

        setTimeout(() => {
          let el = invalidField[1].offsetTop - 100;
          if (el) window.scroll({ top: el, behavior: "smooth" });
        }, 10);
      }
      //
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
      }, () => { });
    } else {
      let newDo = this.odForm.getRawValue();
      // if (newDo.SelectedCountry) newDo.CodeISO2 = newDo.SelectedCountry.CodeISO2;

      if (newDo.SelectedCountry) newDo.Country = { CountryID: newDo.SelectedCountry.CountryID };
      if (newDo.SelectedTypeCategory) newDo.TypeCategoryID = newDo.SelectedTypeCategory.CategoryID;
      if (newDo.SelectedSectorCategory) newDo.SectorCategoryID = newDo.SelectedSectorCategory.CategoryID;
      if (newDo.SelectedStatus) newDo.StatusID = newDo.SelectedStatus.StatusID;
      console.log("SelectedActivities,",newDo.SelectedActivities)

      // tree select
      if(newDo.SelectedActivities){
        newDo.ActivitiesIDs = newDo.SelectedActivities ?? []/*.map( (c:any) => c.CategoryID)*/;
      }
      else{
        newDo.ActivitiesIDs = [];
      }
      console.log("newDo!!!",newDo);


      newDo.DetailGroup = {
        LongDOName: newDo?.LongDOName ?? '',
        ShortDOName: newDo?.ShortDOName ?? '',
        Subdivision: newDo?.Subdivision ?? '',
        CountryStatut: newDo?.CountryStatut ?? ''
      };

      newDo.Categories = [{
        NameEN: newDo?.NameEN,
        NameFr: newDo?.NameFr,
        NameEs: newDo?.NameEs
      }];

       this.app.sharedTools.deleteParamsInObject(newDo, ['SelectedActivities','SelectedCountry', 'SelectedTypeCategory', 'SelectedSectorCategory', 'SelectedStatus'])
      console.log('[newDo]', newDo);


      newDo.Plages?.forEach((p: any) => {
        p.StartDate = DateHelper.concatDateTime(p.LabelDate, p.StartHour);
        p.EndDate = DateHelper.concatDateTime(p.LabelDate, p.EndHour);
      });

      console.log('[newDo after]', newDo);
      this.createVmDo(newDo);
    }
  }

  onExternalImportLock() {
    this.odModel.ExternalImportActive = !this.odModel.ExternalImportActive;
    this.setLockImport(this.odModel);
  }

  //tab composition
  onDetachContact(contact: any) {
    this.app.confirm.confirmDialog(''
      , this.app.translate.instant('racine.confirmMsg.confirmODContactDetach')
      , 'AUTRE'
      , 'confirm'
      , () => {
        this.detachContact(contact);
      }
      , () => { });
  }

  onAttachContact(event: any) {
    let contact = event.value;
    console.log('onAttachContact ', contact);
    this.app.confirm.confirmDialog(''
      , this.app.translate.instant('racine.confirmMsg.confirmODContactAttach')
      , 'AUTRE'
      , 'confirm'
      , () => {
        this.attachContact(contact);
      }
      , () => { });
  }

  onCompositionFormCancel() {
    this.clearCompositionForm();
  }

  onCompositionFormSubmit() {
    this.isODFormSubmitted = true;
    console.log('[this.compositionForm]', this.compositionForm);

    if (this.compositionForm.invalid) {

      console.log("invalid form ===>", this.compositionForm);
      console.log("invalid form ===>", this.compositionForm.errors);

      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.compositionForm.controls;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log('Invalid form values:', invalidValues);

      if (invalidField && invalidField.length > 1) {
        console.log("invalidField", invalidField)

        setTimeout(() => {
          let el = invalidField[1].offsetTop - 100;
          if (el) window.scroll({ top: el, behavior: "smooth" });
        }, 10);
      }
      //
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
      }, () => { });
    } else {

      let newDo = this.compositionForm.getRawValue();

      if (newDo.selectedODContactRole) newDo.TypeRoleOD = newDo.selectedODContactRole.ID;
      // if (newDo.SelectedChefDO) newDo.ChefDO = newDo.SelectedChefDO.ChefDO;


      this.app.sharedTools.deleteParamsInObject(newDo, ['SelectedCountry', 'SelectedTypeCategory', 'SelectedSectorCategory', 'SelectedStatus'])
      console.log('[newDo]', newDo);

      this.createOrUpdateVmContact(newDo);
    }
  }

  onNewContact(): void {
    // ? TODO: Ajouter nouveau contact
    console.log("Nouveau contact...");

    this.isCompositionFormEditMode = false;
    this.initializeCompositionForm(null);

    this.showCompositionForm = true;
  }

  onToggleOverlay(event: any, op: any, targetEl: any) {
    if (!this.isCompositionFormEditMode) {
      op.toggle(event, targetEl)
    }
  }

  onEditContact(contact: any) {
    console.log("🚀  editContact clicked ");
    console.log("🚀  editContact : ", contact);
    this.showCompositionForm = true;
    this.isCompositionFormEditMode = true;
    this.isRepresentedBy = false;

    this.initializeCompositionForm(contact);

  }

  onRepresentedByChange(): void {
    this.calculateNextContactRole();
    if (this.odModel.DetailGroup.RepresentedBy) {
      this.setrepresentedby();
    }
    else {
      let chef = this.odModel.Chef ?? {};
      chef.ComponentID = 3;
      this.setrepresentedby();

      setTimeout(() => {
        this.detachContact(chef);
      }, 500);
    }
  }

  onSelectedContactChange(event: any): void {
    let contact = event.value;
    this.attachContact(contact, false);
  }

  onFocusContact(contact: any): void {
    this.focusedContact = cloneDeep(contact);
    console.log('focusedContact ', this.focusedContact);
  }
  onFocusOutContact(): void {
    //this.focusedContact = {};
  }
  
  onInterestNodeUnselect(event: any) {
    if (this.selectedNodes.length == 0) {
        this.isODFormSubmitted = true
    }
}

onInterestCategoriesSelectionChanged(): void {
    // ==> We format the nodes
    let categories = this.app.sharedTools.getCategoriesFromSelectedNodes(this.Activities, this.selectedNodes);
    //set activities to Activities form control
    this.setCategoriesToForm(categories);
}
  //#endregion

  //#region private actions

  //api endpoints calls
  private async getOD(odID: any) {
    console.log("getOD ==> ", odID);
    try {
      const response = await this.app.apiService.getVmDo(odID);
      console.log("getOD ==> ", response);

      // this.isRepresentedBy = response.DetailGroup.RepresentedBy;

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
        }, () => {
        })
        return;
      }
      this.odModel = cloneDeep(response);
      console.log("🚀  this.odModel:", this.odModel);

      this.calculateNextContactRole();

      setTimeout(() => {

        this.SelectedTypeCategory = (this.odModel.TypeCategoryID) ? this.app.sharedTools.getObjById(this.odModel.TypeCategoryID, "CategoryID", this.odTypeCategories) : null;
        this.SelectedSectorCategory = (this.odModel.SectorCategoryID) ? this.app.sharedTools.getObjById(this.odModel.SectorCategoryID, "CategoryID", this.odSectorCategories) : null;
        this.SelectedCountry = (this.odModel.Country.CountryID) ? this.app.sharedTools.getObjById(this.odModel.Country.CountryID, "CountryID", this.countries) : null;
        this.SelectedStatus = (this.odModel.StatusID) ? this.app.sharedTools.getObjById(this.odModel.StatusID, "StatusID", this.status) : null;

        this.CodeExt = (this.odModel.CodeEXT);
        this.DateImprt = this.odModel.DateLastSync;
        this.HaveVisit = this.odModel.HaveVisit;

        // this.SelectedActivities = (this.odModel.ActivitiesIDs) ? this.Activities
        // .filter((cat: any) => this.odModel.ActivitiesIDs
        // .find( (a: any) => a == cat.CategoryID))
        // : null;
        
        if(this.odModel.ActivitiesIDs && Array.isArray(this.odModel.ActivitiesIDs)){
          this.selectedNodes = [];
          this.odModel.ActivitiesIDs.forEach((cat: any) => {
              this.selectNodesByData(cat, this.interestCategoriesTree);
          });
      }

        this.initializeForm();
        //this.initializeCompositionForm();
      }, 500);

    }
    catch (error) {
      console.error(error);
    }
  }

  private async getCategories() {
    try {
      const response = await this.app.apiService.getCategories();
      console.log("🚀  response category :", response);

      let categoriesIni = cloneDeep(response);

      this.odTypeCategories = cloneDeep(categoriesIni.filter((cat: any) => {
        return cat.CategoryType === CategoryType.TypeDO;
      }));

      this.odSectorCategories = cloneDeep(categoriesIni.filter((cat: any) => {
        return cat.CategoryType === CategoryType.SectorDO;
      }));

      this.Activities =  cloneDeep(categoriesIni.filter((cat: any) => {
        return cat.CategoryType === CategoryType.Activities;
      }));
      
      this.processInterestCategories();

  console.log("Activities ", this.Activities)

    } catch (error) {
      console.error(error);
    }
  }

  private async getContactSource(grpID: any) {
    try {
      const resp = await this.app.apiService.getVmContactSource(3, grpID);
      console.log("🚀  resp:", resp);

      let contactSourceClone = cloneDeep(resp);
      console.log("🚀  contactSourceClone:", contactSourceClone);

      this.availableContactsList = contactSourceClone.filter((c: any) => {
        return c.FullName;
      });
      console.log("🚀  this.availableContactsList:", this.availableContactsList);

      this.getSelectedContactsList(this.odID);

    } catch (error) {
      console.log(error);
    }
  }

  private async getSelectedContactsList(grpID: any) {
    const resp = await this.app.apiService.getVmContactSelected(3, grpID);

    let selectedContacts: any[] = cloneDeep(resp);
    console.log('selectedContacts ', selectedContacts);
    if (selectedContacts && Array.isArray(selectedContacts)) {

      selectedContacts.forEach((c: any) => {
        console.log('this.odContactRoles ', this.odContactRoles);
        c.RoleDescription = this.odContactRoles?.find((f: any) => f.ID === c.TypeRoleOD);
        c.selectedODContactRole = cloneDeep(c.RoleDescription);

        c.Language = this.langues.find((l: any) => l.LanguageID === c.Contact.LanguageID);
        c.Civility = this.civilities.find((civ: any) => { civ.CivilityID === c.Contact.CivilityID });
        console.log("🚀  c.:", c)
        console.log("🚀  c.Language:", c.Language)
        console.log("🚀  c.Civility:", c.Civility)
        console.log('RoleDescription ', c.RoleDescription)
        this.availableContactsMap[c.LinkAssocationID] = [c.Contact, ...this.availableContactsList];
      });

      console.log("🚀  selectedContacts:", selectedContacts)

      this.odContactsList = cloneDeep(selectedContacts);
      console.log("🚀  availableContactsMap:", this.availableContactsMap, this.odContactsList)

      this.orderODContactsList();
    }
  }

  // private getCountries() {
  //   this.app.apiService.getCountries().subscribe((data: any) => {
  //     console.log("getCountries ==> ", data);
  //     this.countries = data; //cloneDeep(data);
  //   });
  // }
  private getCountries() {
    this.app.apiService.getExtendedCountries(ExtendedCountries.vm).subscribe((data: any) => {
      console.log("getCountries ==> ", data);
      this.countries = data; //cloneDeep(data);
    });
  }

  private getStatus() {
    this.app.apiService.getStatus().subscribe((data: any) => {
      console.log("getStatus ==> ", data);
      if (this.status) {
        this.status = data.filter((s: any) => s.StatusType === StatusType.F18OD);
      }
    });
  }

  private async deleteOD(odObject: any) {
    console.log("DELETE OD ===>", odObject);
    try {
      const response = await this.app.apiService.deleteVmDo(odObject);
      console.log('Response API : DELETE OD ===>', response);

      setTimeout(() => {
        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
            , 'KO'
            , "alert"
            , () => { }
            , () => { });
          return;
        }

        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.successMsg.successODDelete')
          , 'OK'
          , "alert"
          , () => { this.app.sharedTools.redirectTo('admin-delegations'); }
          , () => { });
      }, 300);



    } catch (error) {
      console.error(error);
    }
  }

  private async getEdition() {
    try {
      //get edition from api
      const response = await this.app.apiService.getEdition();
      console.log("🚀  response getEdition :", response);

      this.edition = response;

      //retrieve AllEditionDates from edition
      if (this.edition?.AllEditionDates
        && this.edition.AllEditionDates.length > 0) {
        this.allEditionDates = this.edition.AllEditionDates;
      }
      console.log("🚀  allEditionDates :", this.allEditionDates);

      //initialize plages from allEditionDates (calculated property)
      if (this.allEditionDates
        && this.allEditionDates.length > 0
        && this.edition.StartDate
        && this.edition.EndDate) {
        this.allEditionDates.forEach((date: any) => {
          this.plages.push(new Plage(undefined, this.edition.StartDate, this.edition.EndDate, date));
        })
      }
      console.log("🚀  plages :", this.plages);

    } catch (error) {
      console.error(error);
    }
  }
  activitiesList : any;
  Display(x:any){
    console.log("display ",x)
  }
  private async createVmDo(formObj: any): Promise<any> {
    console.log("CREATE VM DO ===>", formObj);
    try {
      if (!this.isODFormEditMode) {
        const response = await this.app.apiService.createVmDo(formObj);
        console.log('RESPONSE CREATE VM DO ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }

      }
      else {
        formObj.GroupID = this.odID;
        console.log("🚀  formObj:", formObj);
        const response = await this.app.apiService.updateVmDo(formObj);
        console.log('RESPONSE UPDATE DO ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }
      }

      let msg: any = (this.isODFormEditMode)
        ? this.app.translate.instant('racine.successMsg.successODUpdate')
        : this.app.translate.instant('racine.successMsg.successODCree');

      this.app.confirm.confirmDialog(""
        , msg
        , 'OK'
        , "alert"
        , () => { this.app.location.back(); }
        , () => { });

    } catch (error) {
      console.error(error);
    }
  }

  private async createOrUpdateVmContact(formObj: any): Promise<any> {
    console.log("CREATE VM Contact ===>", formObj);
    try {
      formObj.GroupID = this.odID;
      formObj.ComponentID = 3;

      if (!this.isCompositionFormEditMode) {
        const response = await this.app.apiService.createVmContact(formObj);
        console.log('RESPONSE CREATE VM Contact ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }

        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.successMsg.successODContactCree')
          , 'OK'
          , "alert"
          , () => { }
          , () => { });
      }
      else {
        console.log("🚀  formObj:", formObj);
        const response = await this.app.apiService.updateVmContact(formObj);
        console.log('RESPONSE UPDATE Contact ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }

        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.successMsg.successODContactUpdate')
          , 'OK'
          , "alert"
          , () => { }
          , () => { });
      }

      this.clearCompositionForm();
      this.refreshComposition();

    } catch (error) {
      console.error(error);
    }
  }

  private async attachContact(contactLink: any, newAttach: boolean = true): Promise<any> {

    console.log('focusedContact ', this.focusedContact);
    console.log("attach Contact ===>", contactLink);
    try {
      if (contactLink) {
        let request: any = {
          ComponentID: 3,
          GroupID: this.odID,
          detailRoleID: contactLink.detailRoleID,
          ContactID: contactLink.ContactID
        }

        if (!newAttach) {
          request.LinkAssocationID = this.focusedContact?.LinkAssocationID;
        }

        console.log("attach Contact :::: request ===>", request);
        const response = await this.app.apiService.contactSelect(request);
        console.log('RESPONSE attach Contact ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog(""
            , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
            , 'KO'
            , "alert", () => { }
            , () => { });
          return;
        }

        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.successMsg.successODContactAttach')
          , 'OK'
          , "alert"
          , () => { }
          , () => { });

        this.refreshComposition();
      }

    } catch (error) {
      console.error(error);
    }
  }

  private async detachContact(contactLink: any): Promise<any> {
    console.log("detach contactLink ===>", contactLink);
    try {
      if (contactLink) {
        let request: any = {
          ComponentID: 3,
          GroupID: this.odID,
          ContactID: contactLink.Contact.ContactID,
          LinkAssocationID: contactLink.LinkAssocationID,
        }

        const response = await this.app.apiService.contactUnSelect(request);
        console.log('RESPONSE detach Contact ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog(""
            , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
            , 'KO'
            , "alert", () => { }
            , () => { });
          return;
        }

        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.successMsg.successODContactDetach')
          , 'OK'
          , "alert"
          , () => { }
          , () => { });

        this.refreshComposition();
      }

    } catch (error) {
      console.error(error);
    }
  }

  async setrepresentedby() {
    console.log("setrepresentedby OD ==> ", this.odModel.DetailGroup?.RepresentedBy);
    if (!this.odModel.DetailGroup) return;

    let request: any = {
      GroupID: this.odModel.GroupID,
      RepresentedBy: this.odModel.DetailGroup?.RepresentedBy,
    }

    try {
      const response = await this.app.apiService.setrepresentedbyVmDo(request);
      if (response && response.Result) {
        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
          , 'KO'
          , "alert"
          , () => { }
          , () => { });
        return;
      }
      this.refreshComposition();

    } catch (error) {
      console.error(error);
    }
  }

  private refreshComposition(): void {
    this.getContactSource(this.odID);
    this.getOD(this.odID);
  }
  //utils
  private initializeForm(): void {
    
    if (this.isODFormEditMode) {
      this.odForm = this.formBuilder.group({
        ExternalImportActive: new FormControl(this.odModel?.ExternalImportActive),

        Name: new FormControl({
          value: this.app.sharedTools.getPropertySwitchLang(this.odModel, 'Name')
          , disabled: true
        }),

        TypeCategoryID: new FormControl(this.odModel?.TypeCategoryID ?? ''),
        SelectedTypeCategory: new FormControl(this.SelectedTypeCategory, Validators.required),

        SectorCategoryID: new FormControl(this.odModel?.SectorCategoryID ?? ''),
        SelectedSectorCategory: new FormControl({value : this.SelectedSectorCategory, disabled : true}, Validators.required),

        CountryID: new FormControl(this.odModel?.Country?.CountryID ?? ''),
        SelectedCountry: new FormControl({ value: this.SelectedCountry, disabled: true }),

        CountryStatut: new FormControl(this.odModel?.DetailGroup?.CountryStatut ?? ''),


        LongDOName: new FormControl(this.odModel?.DetailGroup?.LongDOName),
        ShortDOName: new FormControl({value: this.odModel?.DetailGroup?.ShortDOName,disabled : true}),

        Subdivision: new FormControl(this.odModel?.DetailGroup?.Subdivision),

        StatusID: new FormControl(this.odModel?.StatusID ?? ''),
        SelectedStatus: new FormControl(this.SelectedStatus, Validators.required),
        Plages: this.formBuilder.array([]),
        SelectedActivities : new FormControl(this.SelectedActivities)


      });
    }
    else {
      this.odForm = this.formBuilder.group({
        ExternalImportActive: new FormControl(this.ExternalImportActive),
        Name: new FormControl({ value: null, disabled: true }),

        TypeCategoryID: new FormControl(''),
        SelectedTypeCategory: new FormControl(this.odTypeCategories[0], Validators.required),

        SectorCategoryID: new FormControl(''),
        SelectedSectorCategory: new FormControl(null, Validators.required),

        CountryID: new FormControl(''),
        SelectedCountry: new FormControl(null, Validators.required),

        CountryStatut: new FormControl(''),

        LongDOName: new FormControl(''),
        ShortDOName: new FormControl(''),

        Subdivision: new FormControl(''),

        StatusID: new FormControl(''),
        SelectedStatus: new FormControl(this.status.find((s: any) => s.Code == 'SCHEDULED_OD'), Validators.required),

        Plages: this.formBuilder.array([]),

        SelectedActivities : new FormControl('')
      });
    }

    this.initializeEmbeddedFormGroups();
    this.initODForm = true;
  }

  private initializeCompositionForm(contactLink: any): void {
    if (this.isCompositionFormEditMode) {
      this.compositionForm = new FormGroup({

        // availableContactsList: new FormControl(this.compModel?.availableContactsList ?? ''),
        // SelectedContactSource: new FormControl(this.availableContactsList),

        // isRepresentedBy: new FormControl(false),
        ContactID: new FormControl(contactLink?.Contact.ContactID ?? null, Validators.required),
        Grade: new FormControl(contactLink?.Grade ?? ''),
        FullName: new FormControl(contactLink.Contact?.FullName, Validators.required),
        Function: new FormControl(contactLink?.Function),

        TypeRoleOD: new FormControl(contactLink?.TypeRoleOD ?? ''),
        selectedODContactRole: new FormControl({ value: contactLink?.selectedODContactRole, disabled: true }, Validators.required),


        InterestCenter: new FormControl(contactLink?.InterestCenter ?? ''),

        // ChefDO: new FormControl(this.compModel?.ChefDO ?? ''),
        // SelectedChefDO: new FormControl(this.chefDO, Validators.required),

        // Membre1: new FormControl(this.compModel?.Membre1 ?? ''),
        // SelectedMembre1: new FormControl(this.membre1, Validators.required),

        // Membre2: new FormControl(this.compModel?.Membre2 ?? ''),
        // SelectedMembre2: new FormControl(this.membre2, Validators.required),

        // Membre3: new FormControl(this.compModel?.Membre3 ?? ''),
        // SelectedMembre3: new FormControl(this.membre3, Validators.required),


      });
    }
    else {
      this.calculateNextContactRole();
      this.compositionForm = new FormGroup({

        // availableContactsList: new FormControl(''),
        // SelectedContactSource: new FormControl(null),

        // isRepresentedBy: new FormControl(''),
        Grade: new FormControl(''),
        FullName: new FormControl('', Validators.required),
        Function: new FormControl(''),

        TypeRoleOD: new FormControl(''),
        selectedODContactRole: new FormControl({ value: this.nextContactRole, disabled: true }, Validators.required),

        InterestCenter: new FormControl(''),

      });
    }

    this.initCompositionForm = true;
  }

  private clearCompositionForm(): void {
    this.isCompositionFormEditMode = false;
    this.showCompositionForm = false;
    this.initCompositionForm = false;
    this.initializeCompositionForm(null);
  }

  private calculateNextContactRole(): void {

    //calculated properties init
    if (this.odModel) {
      if (this.odModel.DetailGroup?.RepresentedBy) {
        if (!this.odModel.Guest) {
          this.nextContactPlaceholder = 'bo.vm-detail-do.formCreateDo-vm-TypeRoleODInvite';
          this.nextContactRole = this.odContactRoles?.find((r: any) => r.ID === 'INVITE');
        }
        else if (this.odModel.Chef && this.odModel.Chef.ContactID != this.odModel.Guest.ContactID && this.odModel?.DetailGroup?.RepresentedBy == true) {
          this.nextContactPlaceholder = 'bo.vm-detail-do.formCreateDo-vm-TypeRoleODMember';
          this.nextContactRole = this.odContactRoles?.find((r: any) => r.ID === 'MEMBER');
        }
        else {
          this.nextContactPlaceholder = 'bo.vm-detail-do.formCreateDo-vm-TypeRoleODChef';
          this.nextContactRole = this.odContactRoles?.find((r: any) => r.ID === 'CHEF');
        }
      }
      else if (this.odModel.Guest) {
        this.nextContactPlaceholder = 'bo.vm-detail-do.formCreateDo-vm-TypeRoleODMember';
        this.nextContactRole = this.odContactRoles?.find((r: any) => r.ID === 'MEMBER');
      }
      else {
        this.nextContactPlaceholder = 'bo.vm-detail-do.formCreateDo-vm-TypeRoleODInvite';
        this.nextContactRole = this.odContactRoles?.find((r: any) => r.ID === 'INVITE');
      }
    }
  }

  private orderODContactsList(): void {
    let invite: any = this.odContactsList.find((r: any) => r.TypeRoleOD === 'INVITE');
    let chef: any = this.odContactsList.find((r: any) => r.TypeRoleOD === 'CHEF');
    let members: any = this.odContactsList.filter((r: any) => r.TypeRoleOD === 'MEMBER');

    this.odContactsList = [];

    if (invite) {
      this.odContactsList = [invite];
    }
    // 
    if (chef && this.odModel?.DetailGroup?.RepresentedBy == true && chef?.Contact?.ContactID != invite?.Contact?.ContactID) {
      this.odContactsList = [...this.odContactsList, chef];
    }

    if (members) {
      this.odContactsList = [...this.odContactsList, ...members];
    }
  }

  private async setLockImport(od: any) {
    let objRequest: any = {
      ExternalImportActive: od.ExternalImportActive,
      GroupID: od.GroupID
    }

    try {
      const response = await this.app.apiService.setLockImportVmDo(objRequest);
      if (response && response.Result) {
        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
          , 'KO'
          , "alert"
          , () => { }
          , () => { });
        return;
      }

      if (od.ExternalImportActive != true) {
        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.successMsg.successODCreeLockImport')
          , 'OK'
          , "alert"
          , () => { }
          , () => { });
      }


    } catch (error) {
      console.error(error);
      return;

    }
  }

  private initializeEmbeddedFormGroups(): void {
    let plages: Plage[] = [];

    if (this.isODFormEditMode) {
      this.odModel?.Plages?.forEach((p: any) => {
        plages.push(new Plage(p.PlageID, p.StartDate, p.EndDate))
      });
    }
    else {
      plages = this.plages;
    }

    //init plages form controls
    let dOPlages = this.odForm.get('Plages') as FormArray;
    if (dOPlages) {
      plages.forEach((p: Plage) => {
        const newPlage = this.formBuilder.group({
          PlageID: [p.PlageID],
          StartHour: [p.StartHour, { updateOn: 'blur' }],
          EndHour: [p.EndHour, { updateOn: 'blur' }],
          LabelDate: [p.StartDate]
        }, {
          validators: [CustomValidators.plageEndHourGreaterOrEqualThanStartHour()
            , CustomValidators.plageEndHourStartHourBothNullOrHaveValue()]
        });

        let startDateControl: any = newPlage.get('StartHour');
        startDateControl?.valueChanges.subscribe((v: any) => {
          if (!v) {
            startDateControl?.setValue(DateHelper.MidnightTime)
          }
        });

        let endDateControl: any = newPlage.get('EndHour');
        endDateControl?.valueChanges.subscribe((v: any) => {
          if (!v) {
            endDateControl?.setValue(DateHelper.MidnightTime)
          }
        });

        dOPlages.push(newPlage);
      });
    }
  }
  
  private processInterestCategories(): void{
    this.Activities.sort((a: any, b: any) => {
        return a?.ViewOrder - b?.ViewOrder
    })

    this.Activities.forEach((cat: any) => {
        const parentId = cat.ParentCategoryID;
        if (!this.tree[parentId]) {
            this.tree[parentId] = [];
        }
        this.tree[parentId].push(cat);
    });

    this.interestCategoriesTree = this.buildTree(null);
    this.isInterestCategoriesHaveChild = this.interestCategoriesTree.some((x: any) => {
        return x?.children.length > 0
    });
}

private buildTree(parentId: any) {
    const children = this.tree[parentId] || [];
    return children.map((child: any) => {
        const node = {
            key: child.CategoryID,
            label: this.app.sharedTools.getLabelSwitchLang(child),
            LabelEN: child.LabelEN,
            LabelFR: child.LabelFR,
            LabelES: child.LabelES,
            data: '',
            icon: '',
            children: this.buildTree(child.CategoryID)
        };
        return node;
    });
}

private selectNodesByData(data: any, nodes: TreeNode[] = this.interestCategoriesTree) {
    try {
        for (const node of nodes) {
            if (node.key === data) {
                this.selectedNodes.push(node);
                break;
            }
            if (node.children) {
                this.selectNodesByData(data, node.children);
            }
        }

        console.log('selected nodes : ', this.selectedNodes)
    } catch (error) {
        console.warn(error);
    }
}

private setCategoriesToForm(newValues: any): void {
  let interestCategoriesControl = this.odForm.get('SelectedActivities');
  if(interestCategoriesControl){
      if(newValues && Array.isArray(newValues)){
          // let selectedNodesKey = newValues.map((v: any)=> v.key);
          // let categories = this.interests.filter((i: any) => (selectedNodesKey).includes(i.CategoryID) == true);
          
          interestCategoriesControl.setValue(newValues);
      }
      else{
          interestCategoriesControl.setValue([]);
      }
  }
}
  //#endregion

  getCivilities() {
    this.app.apiService.getCivilities().subscribe((data: any) => {
      this.civilities = cloneDeep(data);
    });
  }

  getLanguages() {
    this.app.apiService.getLanguages().subscribe((data: any) => {
      this.langues = cloneDeep(data);
    });
  }
}
