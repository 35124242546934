import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { EmailRegex, PasswordRegex } from '../consts/utils';
import { MustMatch } from '../services/sharedtools.service';
import { SearchVmFolder } from '../models/search-vm-folder';
import { DatePipe, KeyValue } from '@angular/common';
import { ExtendedCountries } from '../enums/extended-countries';
import { DateHelper } from '../helpers/date.helper';
import { CustomValidators } from '../validators/custom-validators';

@Component({
    selector: 'app-create-vm-exposant',
    templateUrl: './create-vm-exposant.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./create-vm-exposant.component.less']
})
export class CreateVmExposantComponent {
    readonly prioritairePriority: string = 'prioritaire';

    submitted: boolean = false;
    initForm: boolean = false;
    newLeadForm!: FormGroup;
    civilities: any[] = [];
    langues: any[] = [];
    roles: any[] = [];
    eligibles: any[] = [];
    priviGroups: any[] = [];
    priorities: any[] = [];
    countries: any[] = [];
    search: any = new SearchVmFolder();
    phones: any[] = [];
    exposant?: any;
    newExposant?: any;
    isEditMode: boolean = false;
    SelectedCivility: any;
    SelectedRole: any;
    SelectedRoles: any = [];
    SelectedEligible: any;
    SelectedEligibles: any = [];
    SelectedPriviGroup: any;
    SelectedPriviGroups: any = [];
    SelectedPriority: any;
    SelectedPriorities: any = [];
    SelectedPrintExposantPlanning: any;
    SelectedAllowRequestAfterDeadline: any;
    SelectedPrintDoPlanning: any;
    SelectedRightPlan: any;
    SelectedFedFolder: any;
    SelectedExposantType: any;
    SelectedCountry: any;
    SelectedLanguage: any;
    MobilePrefixSelected: any;
    paths = location.pathname.split('/');
    currentPath: any = null;
    exposantId: any;
    federatorFolders: any[] = [];
    selectYesNoGroupOptionsList: any[] = [];
    folderExposantTypeList: any[] = [];
    formPrioritiesList: any[] = [];
    selectYesNoWaitingOptionsList: any[] = [];
    odCapacities: KeyValue<number, any>[] = [];
    meetingstands: any[] = [];
    oDcapacitiesConfig: any = {};
    vmFolderID: any = null;
    
    selectedMeetingStand: any;
    canDeleteFolder: boolean = false;

    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private datePipe: DatePipe
    ) { 
    }

    async ngOnInit() {
        let cuser = JSON.parse(sessionStorage.getItem('userData')!);
        console.log(cuser);
        if (!cuser) {
            this.app.router.navigate(['/login']);
            return;
        }
        //check create or edit user
        console.log("this.path==> ", this.paths);
        this.currentPath = this.paths[1];
        var urlId: any = this.app.router.url.split('/').pop();
        if (this.currentPath == 'detail-vm-exposant') {
            this.exposantId = parseInt(urlId);
        }



        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                this.selectYesNoGroupOptionsList = cloneDeep(resG.selectYesNoGroupOptions);
                this.folderExposantTypeList = cloneDeep(resG.folderExposantType);
                this.formPrioritiesList = cloneDeep(resG.formPriorities);
                this.selectYesNoWaitingOptionsList = cloneDeep(resG.selectYesNoWaitingOptions);
                this.oDcapacitiesConfig = cloneDeep(resG.odCapacities);


                this.currentPath = this.paths[1];
                var urlId: any = this.app.router.url.split('/').pop();
                if (this.currentPath == 'detail-vm-exposant') {
                    this.vmFolderID = parseInt(urlId);
                }

                this.initODcapacities();
                this.getCivilities();
                this.getRoles();
                this.getLanguages();
                this.getPhones();
                this.getCountries();
                this.getFederatorFolders();
                this.privigroupgetall();

                //
                if (this.exposantId) {
                    this.getmeetingstands();
                    this.isEditMode = true;
                    this.getExposant(this.exposantId);
                    console.log("this.exposantId ==> ", this.exposantId);
                }
                else {
                    //
                    this.initFormulaire();
                    console.log("this.newLeadForm", this.newLeadForm);
                }
            });
          
    }

    onSetLockImport(): void {
        if (this.exposant) {
            this.exposant.ExternalImportEnabled = !this.exposant.ExternalImportEnabled;
            this.setLockImport(this.exposant);
            
        }
    }
    
    onchangeSelect(val: any) {
        if (!val) return;
        //console.log("onchangeSelect", val);
    }

    getPhones() {
        this.app.apiService.getPhones().subscribe((data: any) => {
            console.log("getPhones ==> ", data);
            this.phones = data; //cloneDeep(data);
        });
    }

    getRoles() {
        this.app.apiService.getRoles().subscribe((data: any) => {
            console.log("getRoles ==> ", data);
            this.roles = data; //cloneDeep(data);
        });
    }

    getLanguages() {
        this.app.apiService.getLanguages().subscribe((data: any) => {
            console.log("getLanguages ==>", data);
            this.langues = data;
        });
    }

    getCivilities() {
        this.app.apiService.getCivilities().subscribe((data: any) => {
            console.log("getCivilities ==> ", data);
            this.civilities = data; //cloneDeep(data);
        });
    }

    getmeetingstands() {
        if (this.exposantId) {
            this.app.apiService.getmeetingstandsVmFolder(this.exposantId).subscribe((data: any) => {
                console.log("getmeetingstands ==> ", data);
                this.meetingstands = data; //cloneDeep(data);
            });
        }
    }


    onDeleteExposant() {
        let form: any = {
            FormID: this.exposantId
        }
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteVMDossier'), 'AUTRE', 'confirm', () => {
            setTimeout(() => { this.deleteExposant(form); }, 1000);
        }, () => { });
    }

    async deleteExposant(form: any) {
        console.log("DELETE folder ===>", form);
        try {
            const response = await this.app.apiService.deleteVmFolder(form);
            console.log('Response API : DELETE Exposant ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successVMDossierDelete'), 'OK', "alert", () => {
                this.app.sharedTools.redirectTo('/admin-exposants');
            }, () => { });


        } catch (error) {
            console.error(error);
        }
    }

    annuler() {
        this.app.sharedTools.redirectTo('/admin-exposants');
    }

    async createExposant(formObj: any): Promise<any> {

        console.log("CREATE Exposant ===>", formObj);
        try {
            if (!this.isEditMode) {
                const response = await this.app.apiService.createVmFolder(formObj);
                console.log('RESPONSE CREATE Exposant ===>', response);

                if (response && response.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                    }, () => {
                    })
                    return;
                }

                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successVMDossierCree'), 'OK', "alert", () => {
                    this.app.sharedTools.redirectTo('/admin-exposants');
                }, () => { });
            }
            else {
                formObj.FormID = this.exposantId;
                const response = await this.app.apiService.updateVmFolder(formObj);
                console.log('RESPONSE UPDATE USER ===>', response);

                if (response && response.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                    }, () => {
                    })
                    return;
                }

                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successVMDossierUpdt'), 'OK', "alert", () => {
                    this.app.sharedTools.redirectTo('/admin-exposants');
                }, () => { });
            }

        } catch (error) {
            console.error(error);
        }
    }

    onSubmit() {
        this.submitted = true;
        
        let nbVisitMiniControl = this.newLeadForm.get('NbVisitMini');

        nbVisitMiniControl?.updateValueAndValidity();
        console.log('[this.newLeadForm]', this.newLeadForm);

        if (this.newLeadForm.invalid) {

            console.log("invalid form ===>", this.newLeadForm);
            console.log("invalid form ===>", this.newLeadForm.errors);

            //bloc explore form errors

            let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
            const invalidValues: any = {};
            const controls = this.newLeadForm.controls;

            for (const controlName in controls) {
                if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                    invalidValues[controlName] = controls[controlName].value;
                }
            }

            console.log('Invalid form values:', invalidValues);

            if (invalidField && invalidField.length > 1) {
                console.log("invalidField", invalidField)

                setTimeout(() => {
                    let el = invalidField[1].offsetTop - 100;
                    if (el) window.scroll({ top: el, behavior: "smooth" });
                }, 10);
            }
            //
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
            }, () => { });
        } else {

            let newExposant = this.newLeadForm.value;
                        //format dates
            if (newExposant.OpeningDate) {
                newExposant.OpeningDate = this.datePipe.transform(newExposant.OpeningDate, DateHelper.ApiDateTimeFormat); 
              }
            if (newExposant.ClosingDate) {
 
              newExposant.ClosingDate =this.datePipe.transform(newExposant.ClosingDate, DateHelper.ApiDateTimeFormat);
            }
               
            //retrieve selected properties
            if (newExposant.SelectedCountry) newExposant.CountryID = newExposant.SelectedCountry.CountryID;
            if (newExposant.SelectedExposantType) newExposant.ExposantType = newExposant.SelectedExposantType.ExposantType;
            if (newExposant.SelectedFedFolder){
                newExposant.FldFedId = newExposant.SelectedFedFolder.FolderID;
            }
            else{
                newExposant.FldFedId = null;
            }

            if (newExposant.SelectedEligible) newExposant.Eligible = newExposant.SelectedEligible.Value;
            if (newExposant.SelectedPriviGroup){
                newExposant.PriviGroupID = newExposant.SelectedPriviGroup.PriviGroupID;
                console.log("newExposant.SelectedPriviGroup.PriviGroupID**",newExposant.SelectedPriviGroup.PriviGroupID);
            } else{
                newExposant.PriviGroupID =null;
            }
            
            if (newExposant.SelectedPriority) newExposant.Priority = newExposant.SelectedPriority.Priority;
            
            
            // if (newExposant.SelectedRightPlan) newExposant.RightPlan = newExposant.SelectedRightPlan.Value;
            if (newExposant.SelectedPrintExposantPlanning) newExposant.ImprPlanExh = newExposant.SelectedPrintExposantPlanning.Value;
            if (newExposant.SelectedPrintDoPlanning) newExposant.ImprPlanOd = newExposant.SelectedPrintDoPlanning.Value;
            if(newExposant.SelectedAllowRequestAfterDeadline) newExposant.AllowRequestAfterDeadline = newExposant.SelectedAllowRequestAfterDeadline.Value
            if (this.isEditMode) {
                if (newExposant.SelectedMeetingStand) {
                    newExposant.MeetingStand = newExposant.SelectedMeetingStand.MeetingStandLabel;
                    newExposant.MeetingStandFolderID = newExposant.SelectedMeetingStand.FolderID;
                    
                }
                else{
                    newExposant.MeetingStand = null;
                    newExposant.MeetingStandFolderID = null;
                }

            }

            // if (newExposant.SelectedRightPlan) newExposant.RightPlan = newExposant.SelectedRightPlan.Value;
            // if (newExposant.SelectedRightExport) newExposant.RightExport = newExposant.SelectedRightExport.Value;
            // if (newExposant.SelectedRightPrintPlan) newExposant.RightPrintPlan = newExposant.SelectedRightPrintPlan.Value;
          
            console.log('selects ', newExposant.SelectedRightPlan
                , newExposant.SelectedRightExport
                , newExposant.SelectedRightPrintPlan);
                
            this.app.sharedTools.deleteParamsInObject(newExposant
                , ['SelectedCountry'
                    , 'SelectedExposantType'
                    , 'SelectedFedFolder'
                    , 'SelectedEligible'
                    , 'SelectedPriviGroup'
                    , 'SelectedPriority'
                    , 'SelectedRightPlan'
                    , 'SelectedRightExport'
                    , 'SelectedRightPrintPlan']);

            console.log('[newExposant]', newExposant);
            this.createExposant(newExposant);        
        }
    }

    onCompanyNameFocusout(value: any): void{
        let companyNameControl: any = this.newLeadForm.get('CompanyName');
        let companyName2Control: any = this.newLeadForm.get('CompanyName2');
        if(companyName2Control && !companyName2Control.value){
            companyName2Control.setValue(companyNameControl.value);
        }
    }

    onSelectedExposantTypeFocusout(value: any): void{
        let selectedExposantType: any = this.newLeadForm.get('SelectedExposantType');
        if(selectedExposantType?.value && selectedExposantType.value.ExposantType === 'FEDERATOR'){
            let selectedFedFolderControl: any = this.newLeadForm.get('SelectedFedFolder');
            let FldFedIdControl: any = this.newLeadForm.get('FldFedId');
            selectedFedFolderControl?.setValue(null);
            FldFedIdControl?.setValue(null);
        }
    }
    
    private async getExposant(exposantId: any) {

        try {
            const response = await this.app.apiService.getVmFolder(exposantId);
            console.log("getExposant==> ", response);
            await this.getFederatorFolders();
            console.log(this.federatorFolders)
            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                }, () => {
                })
                return;
            }
            this.exposant = cloneDeep(response);
           
            setTimeout(() => {

                if (this.exposant) {
                    this.canDeleteFolder = this.exposant.CanDelete;

                    this.SelectedCountry = (this.exposant.CountryID)
                        ? this.app.sharedTools.getObjById(this.exposant.CountryID, "CountryID", this.countries)
                        : null;

                    this.SelectedFedFolder = (this.exposant.FldFedId)
                        ? this.app.sharedTools.getObjById(this.exposant.FldFedId, "FolderID", this.federatorFolders)
                        : null;

                    this.SelectedPriviGroup = (this.exposant.PriviGroupID)
                        ? this.app.sharedTools.getObjById(this.exposant.PriviGroupID, "PriviGroupID", this.priviGroups)
                        : null;

                    this.SelectedExposantType = (this.exposant.ExposantType) ? this.folderExposantTypeList.find(e => e.ExposantType === this.exposant.ExposantType) : null;
                    this.SelectedPriority = (this.exposant.Priority) ? this.formPrioritiesList.find(e => e.Priority === this.exposant.Priority) : null;

                    // this.SelectedRightPlan = this.selectYesNoGroupOptionsList.find(e => e.Value === this.exposant.RightPlan) ?? null;
                    this.SelectedPrintDoPlanning = this.selectYesNoGroupOptionsList.find(e => e.Value === this.exposant.ImprPlanOd) ?? null;
                    this.SelectedPrintExposantPlanning = this.selectYesNoGroupOptionsList.find(e => e.Value === this.exposant.ImprPlanExh) ?? null;
                    this.SelectedAllowRequestAfterDeadline = this.selectYesNoGroupOptionsList.find(e => e.Value === this.exposant.AllowRequestAfterDeadline) ?? null;
                    this.SelectedEligible = this.selectYesNoWaitingOptionsList.find(e => e.Value === this.exposant.Eligible) ?? null;

                    if (this.exposant.MeetingStand
                        && this.meetingstands
                        && this.meetingstands.length > 0) {
                        // this.selectedMeetingStand = this.meetingstands
                        //     .find((m: any) => m.MeetingStandLabel
                        //         && m.MeetingStandLabel.endsWith('-' + this.exposant.MeetingStand));
                        this.selectedMeetingStand = this.meetingstands.filter(x=>x.MeetingStandLabel == this.exposant.MeetingStand)[0]
                        console.log('ici ', this.exposant.MeetingStand
                            , this.selectedMeetingStand)
                    }

                    // this.SelectedRightPlan = (this.exposant.RightPlan) ? this.selectYesNoGroupOptionsList.find(e => e.Value === this.exposant.RightPlan): null;
                    // this.SelectedRightExport = (this.exposant.RightExport) ? this.selectYesNoGroupOptionsList.find(e => e.Value === this.exposant.RightExport): null;
                    // this.SelectedRightPrintPlan = (this.exposant.RightPrintPlan) ? this.selectYesNoGroupOptionsList.find(e => e.Value === this.exposant.RightPrintPlan): null;
                    console.log('this.SelectedPriority', this.SelectedPriority, this.SelectedRightPlan);
                }
                this.initFormulaire();
            }, 500);

        }
        catch (error) {
            console.error(error);
        }
    }

    private initFormulaire(): void {
        if (this.isEditMode) {
            this.newLeadForm = new FormGroup({
                CompanyName: new FormControl({value: this.exposant?.CompanyName ?? null, disabled:  !this.canDeleteFolder} , Validators.required),
                CompanyName2: new FormControl(this.exposant?.CompanyName2 ?? null, Validators.required),

                SelectedCountry: new FormControl(this.SelectedCountry, Validators.required),
                CountryID: new FormControl(this.exposant?.CountryID ?? null),

                SelectedExposantType: new FormControl(this.SelectedExposantType, Validators.required),
                ExposantType: new FormControl(this.exposant?.ExposantType ?? null),

                SelectedFedFolder: new FormControl(this.SelectedFedFolder),
                FldFedId: new FormControl(this.exposant?.FldFedId ?? null),

                SelectedMeetingStand: new FormControl(this.selectedMeetingStand ?? null),
                MeetingStand: new FormControl(this.exposant?.MeetingStand ?? null),
                MeetingStandFolderID: new FormControl(null),

                Odcapacities: new FormControl(this.exposant?.Odcapacities ?? null),

                SelectedEligible: new FormControl(this.SelectedEligible),
                Eligible: new FormControl(this.exposant?.Eligible ?? null),

                SelectedPriviGroup: new FormControl(this.SelectedPriviGroup),
                PriviGroupID: new FormControl(this.exposant?.PriviGroupID ?? null),

                SelectedPriority: new FormControl(this.SelectedPriority),
                Priority: new FormControl(this.exposant?.Priority ?? null),

                NbVisitMini: new FormControl(this.exposant?.NbVisitMini ?? null),

                // SelectedRightPlan: new FormControl(this.SelectedRightPlan),
                RightPlan: new FormControl(this.exposant?.RightPlan ?? null),

                SelectedPrintDoPlanning: new FormControl(this.SelectedPrintDoPlanning),
                ImprPlanOd: new FormControl(this.exposant?.ImprPlanOd ?? null),

                SelectedPrintExposantPlanning: new FormControl(this.SelectedPrintExposantPlanning),
                ImprPlanExh: new FormControl(this.exposant?.ImprPlanExh ?? null),

                SelectedAllowRequestAfterDeadline: new FormControl(this.SelectedAllowRequestAfterDeadline),
                AllowRequestAfterDeadline: new FormControl(this.exposant?.ImprPlanExh ?? null),

                Keepgoing: new FormControl(this.exposant?.Keepgoing ?? null),
                Bloque: new FormControl(this.exposant?.Bloque ?? null),
                

                OpeningDate: new FormControl((this.exposant?.OpeningDate)
                    ? new Date(this.exposant?.OpeningDate)
                    : null),
                ClosingDate: new FormControl((this.exposant?.ClosingDate)
                    ? new Date(this.exposant?.ClosingDate)
                    : null),
            },{ validators : [CustomValidators.greaterOrEqual('OpeningDate','ClosingDate')] });
        }
        else {
            this.newLeadForm = new FormGroup(
                {
                    CompanyName: new FormControl(null, Validators.required),
                    CompanyName2: new FormControl(null, Validators.required),

                    SelectedCountry: new FormControl(null, Validators.required),
                    CountryID: new FormControl(null),

                    SelectedExposantType: new FormControl(null, Validators.required),
                    ExposantType: new FormControl(null),

                    SelectedFedFolder: new FormControl(null),
                    FldFedId: new FormControl(null),

                    MeetingStandFolderID: new FormControl(null),
                    MeetingStand: new FormControl(null),
                    Odcapacities: new FormControl(null),

                    SelectedEligible: new FormControl(this.selectYesNoWaitingOptionsList[1]),
                    Eligible: new FormControl(null),

                    SelectedPriviGroup: new FormControl(null),
                    PriviGroupID: new FormControl(null),

                    SelectedPriority: new FormControl(this.formPrioritiesList[0]),
                    Priority: new FormControl(null),

                    NbVisitMini: new FormControl(null),

                    // SelectedRightPlan: new FormControl(this.selectYesNoGroupOptionsList[2]),
                    RightPlan: new FormControl(null),

                    SelectedPrintDoPlanning: new FormControl(this.selectYesNoGroupOptionsList[2]),
                    ImprPlanOd: new FormControl(null),

                    SelectedPrintExposantPlanning: new FormControl(this.selectYesNoGroupOptionsList[2]),
                    ImprPlanExh: new FormControl(null),

                    SelectedAllowRequestAfterDeadline: new FormControl(this.selectYesNoGroupOptionsList[2]),
                    AllowRequestAfterDeadline: new FormControl(null),

                    Keepgoing: new FormControl(null),
                    Bloque: new FormControl(null),
                    
                    OpeningDate: new FormControl(null),
                    ClosingDate: new FormControl(null),
                },{ validators : [CustomValidators.greaterOrEqual('OpeningDate','ClosingDate')] });
        }

        this.initOnChangeHandlers();
        
        this.initForm = true;
    }

    // getCountries() {
    //     this.app.apiService.getCountries().subscribe((data: any) => {
    //         console.log("getCountries ==> ", data);
    //         this.countries = cloneDeep(data);
    //     });
    // }

    getCountries() {
        this.app.apiService.getExtendedCountries(ExtendedCountries.vm).subscribe((data: any) => {
          console.log("getCountries ==> ", data);
          this.countries = data; //cloneDeep(data);
        });
      }

    async getFederatorFolders() {
        try {
            const response = await this.app.apiService.federatorListe();
            console.log("getFederators ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/'); }, () => { })
                return;
            }

            this.federatorFolders = cloneDeep(response); 

        } catch (error) {
            console.error(error);
        }
    }

    async privigroupgetall(thenFilterFast: boolean = false) {
        try {
            const response = await this.app.apiService.privigroupgetall();
            console.log("privigroupgetall==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.priviGroups = cloneDeep(response)
            console.log("priviGroups", this.priviGroups);

        } catch (error) {
            console.error(error);
        }
    }

    private initODcapacities(): void {
        this.odCapacities = [];
        for (let i: number = this.oDcapacitiesConfig.min; i <= this.oDcapacitiesConfig.max; i++) {
            let capacity: KeyValue<number, any> = {
                key: i,
                value: i > 1
                    ? this.oDcapacitiesConfig.pluralCapacity
                    : this.oDcapacitiesConfig.singularCapacity
            }
            this.odCapacities.push(capacity);
        }
    }



    private async setLockImport(folder: any) {
        let objRequest: any = {
            ExternalImportEnabled: folder.ExternalImportEnabled,
            FormID: folder.FormID
        }

        console.log("objRequest ==> ", objRequest);

        try {
            const response = await this.app.apiService.setLockImportVmFolder(objRequest);
            console.log("apiService.setLockImportVmFolder ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                    , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                    , 'KO'
                    , "alert"
                    , () => { }
                    , () => { });
                return;
            }

            if (folder.ExternalImportEnabled === false) {
                this.app.confirm.confirmDialog(""
                    , this.app.translate.instant('racine.successMsg.successDossierCreeLockImport')
                    , 'OK'
                    , "alert"
                    , () => { }
                    , () => { });
            }


        } catch (error) {
            console.error(error);
            return;

        }
    }

    private initOnChangeHandlers(): void {
        this.setPriorityChangesHandler();
    }

    private setPriorityChangesHandler(): void {
        let selectedPriorityControl = this.newLeadForm.get('SelectedPriority');

        if (selectedPriorityControl) {
            selectedPriorityControl
                .valueChanges
                .subscribe((value: any) => {
                    console.log(value)
                    let nbVisitMiniControl = this.newLeadForm.get('NbVisitMini');
                    if (value?.Priority === this.prioritairePriority) {
                            nbVisitMiniControl?.setValue(null);
                            nbVisitMiniControl?.setValidators(Validators.required);
                    }
                    else{
                        nbVisitMiniControl?.clearValidators();
                    }
                });
        }
    }
}
