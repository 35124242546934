import { Component } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-admin-board',
  templateUrl: './admin-board.component.html',
  styleUrls: ['./admin-board.component.less']
})
export class AdminBoardComponent {
  appID: any = null;

  constructor(
    public app: AppComponent, 
  ) {}

  ngOnInit() {
    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
          console.log("resG", resG);
          this.appID = resG.APP_ID;
      });

  }

}
