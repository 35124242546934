import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { IdentityManagerService } from '../services/identity-manager.service';
import { cloneDeep } from 'lodash';
import { Dropdown } from 'primeng/dropdown';
import { EmailRegex } from '../consts/utils';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { SendInvitationMailRequest } from '../models/send-invitation-mail-request';

@Component({
  selector: 'app-gestion-contats',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './gestion-contats.component.html',
  styleUrls: ['./gestion-contats.component.less']
})
export class GestionContatsComponent {
  //#region local properties
  @Input() formID!: number;
  @Input() componentID: number = -1;
  @Input() isMultiple: boolean = true;
  @Input() folderID!: number;
  @Input() refrechContactSourceEvent?: Observable<void>;
  @Input() showValidationStatus: boolean = false;
  @Input() enableInvitationEmail: boolean = false;

  @Output() selectionChange = new EventEmitter<any>();
  @Output() thereIsAselectedContactEvent = new EventEmitter<any>();
  @Output() allContactsAreValid : EventEmitter<boolean> = new EventEmitter<boolean>();

  //#region gestion contacts form
  initContactForm: boolean = false;
  contactForm!: FormGroup;
  isContactFormEditMode: boolean = false;
  isContactFormSubmitted: boolean = false;
  isRepresentedBy: boolean = false;
  showContactForm: boolean = false;

  //drop down selected item
  selectedODContactRole: any;

  //calculated properties
  nextContactPlaceholder: string = '';
  nextContactRole: any;

  //
  focusedContact: any;
  //#endregion gestion contacts form

  //#region contact component requirements
  compModel?: any;
  selectedContactsMap: any = {};
  //#endregion contact component requirements

  //#region data sources
  //api data sources
  civilities: any[] = [];
  phones: any[] = [];
  mobiles: any[] = [];
  langues: any[] = [];

  availableContactsList: any = [];
  selectedContactsList: any = [];
  availableContactsMap: any = {};

  //#endregion data sources

  newContact: any;

  isSelectedContactsLoaded: boolean = false;
  userID: any;
  emailContact: any;
  //#endregion  local properties

  submitEventSubscription?: Subscription;
  thereIsAselectedContact: boolean = false;
  sendEmailSuccessfully: boolean = false;

  isEmailUnique: boolean = false;

  allContactsAreAllValid : boolean = false;
  //#region ctor
  /**
   *
   */
  constructor(
    public app: AppComponent,
    private formBuilder: FormBuilder,
    private identityManagerService: IdentityManagerService) {

  }
  //#endregion

  //#region handle life cycle hooks
  async ngOnInit() {
    //this.identityManagerService.checkIsUserAuthenticated();

    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
      });
    this.initOnSubmitSubscription();

    await this.getCivilities();
    await this.getLanguages();
    await this.getPhones();
    this.refreshComposition();

  }
  //#endregion

  //#region handle events
  onDetachContact(contact: any) {
    this.app.confirm.confirmDialog(''
      , this.app.translate.instant('racine.confirmMsg.confirmODContactDetach')
      , 'AUTRE'
      , 'confirm'
      , () => {
        this.detachContact(contact);
      }
      , () => { });
  }

  onAttachContact(event: any) {
    let contact = event.value;
    console.log('Component ID : ', this.componentID, 'onAttachContact ', contact);
    this.app.confirm.confirmDialog(''
      , this.app.translate.instant('racine.confirmMsg.confirmODContactAttach')
      , 'AUTRE'
      , 'confirm'
      , () => {
        this.attachContact(contact);
      }
      , () => { });
  }

  onContactFormCancel() {
    this.clearContactForm();
  }

  private async onContactFormSubmit(sendEmail: boolean = false) {
    let mobileControl = this.contactForm.get('Mobile');
    let mobilePrefixControl = this.contactForm.get('MobilePrefixSelected');

    let phoneControl = this.contactForm.get('Phone');
    let phonePrefixControl = this.contactForm.get('PhonePrefixSelected');

    mobileControl?.updateValueAndValidity();
    mobilePrefixControl?.updateValueAndValidity();
    phoneControl?.updateValueAndValidity();
    phonePrefixControl?.updateValueAndValidity();

    this.isContactFormSubmitted = true;
    console.log('[this.contactForm]', this.contactForm);

    if (this.contactForm.invalid) {

      console.log("invalid form ===>", this.contactForm);
      console.log("invalid form ===>", this.contactForm.errors);

      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.contactForm.controls;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log('Invalid form values:', invalidValues);

      if (invalidField && invalidField.length > 1) {
        console.log("invalidField", invalidField)

        setTimeout(() => {
          let el = invalidField[1].offsetTop - 100;
          if (el) window.scroll({ top: el, behavior: "smooth" });
        }, 10);
      }
      //
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
      }, () => { });
    } else {

      let newDo = this.contactForm.getRawValue();

      if (newDo.SelectedCivility) newDo.CivilityID = newDo.SelectedCivility.CivilityID;
      if (newDo.SelectedLanguage) newDo.LanguageID = newDo.SelectedLanguage.LanguageID;

      if (newDo.MobilePrefixSelected) newDo.MobilePrefix = newDo.MobilePrefixSelected.PhoneID;
      if (newDo.MobilePrefixSelected) newDo.MobileCodeIso = newDo.MobilePrefixSelected.CodeISO2;

      if (newDo.PhonePrefixSelected) newDo.PhonePrefix = newDo.PhonePrefixSelected.PhoneID;
      if (newDo.PhonePrefixSelected) newDo.PhoneCodeIso = newDo.PhonePrefixSelected.CodeISO2;


      this.app.sharedTools.deleteParamsInObject(newDo, ['SelectedCivility', 'SelectedLanguage', 'MobilePrefixSelected', 'PhonePrefixSelected'])
      console.log('[newDo]', newDo);

      await this.createOrUpdateVmContact(newDo, sendEmail); // ComponentID = 1
    }
  }

  onNewContact(): void {
    // ? TODO: Ajouter nouveau contact
    console.log('Component ID : ', this.componentID, "Nouveau contact...");

    this.isContactFormEditMode = false;
    this.initializeContactForm(null);

    this.showContactForm = true;
  }

  onToggleOverlay(event: any, op: any, targetEl: any) {
    if (!this.isContactFormEditMode) {
      op.toggle(event, targetEl)
    }
  }

  onEditContact(contact: any) {
    console.log('Component ID : ', this.componentID, "🚀  editContact clicked ");
    console.log('Component ID : ', this.componentID, "🚀  editContact : ", contact);
    this.emailContact = contact?.Email;
    this.userID = contact?.User?.UserID;
    this.showContactForm = true;
    this.isContactFormEditMode = true;
    this.isRepresentedBy = false;

    this.initializeContactForm(contact);

  }

  onSelectedContactChange(contact: any): void {
    this.attachContact(contact, false);
  }

  onFocusContact(contact: any): void {
    this.focusedContact = cloneDeep(contact);
    console.log('Component ID : ', this.componentID, 'focusedContact ', this.focusedContact);
  }

  onFocusOutContact(): void {
    //this.focusedContact = {};
  }

  onAttachNewContact(event: any, selectODHostDropdown: Dropdown): void {
    let contact = event?.value;
    if (contact) {
      this.attachContact(contact, true);
      selectODHostDropdown.clear(event);
    }
  }

  //#endregion

  //#region private methods

  //#region api actions

  //#region api Retrieve actions
  private async getContactSource() {
    let requestObj: any = {
      ComponentID: this.componentID,
      FormID: this.formID,
    }

    try {
      const resp = await this.app.apiService.getContactSource(requestObj);
      console.log('Component ID : ', this.componentID, "🚀  getContactSource response:", resp);

      let contacts: any[] = cloneDeep(resp);
      if (contacts && Array.isArray(contacts)) {
        this.availableContactsList = cloneDeep(contacts);
        console.log('Component ID : ', this.componentID, "🚀  this.availableContactsList:", this.availableContactsList);
      }

      this.getSelectedContactsList();

    } catch (error) {
      console.log('Component ID : ', this.componentID, error);
    }
  }


  private async getSelectedContactsList() {
    let requestObj: any = {
      ComponentID: this.componentID,
      FormID: this.formID,
    }

    const resp = await this.app.apiService.getContactSelected(requestObj);

    if (resp && Array.isArray(resp)) {
      this.selectedContactsList = cloneDeep(resp);
      this.selectedContactsList.forEach((c: any) => {
        c.Language = this.langues.find((l: any) => l.LanguageID === c.LanguageID);
        c.Civility = this.civilities.find((civ: any) => civ.CivilityID === c.CivilityID);
        this.availableContactsMap[c.ContactID] = [c, ...this.availableContactsList];
      });
    }

    this.thereIsAselectedContactEvent.emit(this.selectedContactsList.length > 0);

    console.log('Component ID : ', this.componentID, 'selectedContacts ', this.selectedContactsList);
    this.isSelectedContactsLoaded = true;

    await this.isContactValid(this.selectedContactsList);
  }

  async isContactValid(contactList: any) {
    if (contactList && Array.isArray(contactList)) {
      let allContactsAreValid: boolean = true;
      const promises = contactList.map(async (contact: any) => {
        contact.isValid = await this.reCheckValidity(contact);
        if(!contact.isValid){
          allContactsAreValid = false;
          console.log('Component ID : ', this.componentID, 'allContactsAreValid ', allContactsAreValid);
        }
      });
      await Promise.all(promises);
      console.log('Component ID : ', this.componentID, 'allContactsAreValid ', allContactsAreValid);
      this.allContactsAreAllValid = allContactsAreValid;
      this.allContactsAreValid.emit(allContactsAreValid);
    }
  }

  async getPhones() {
    let response = await firstValueFrom(this.app.apiService.getPhones());
    if (response && Array.isArray(response)) {
      console.log('Component ID : ', this.componentID, "getPhones ==> ", response);
      this.mobiles = cloneDeep(response);
      this.phones = cloneDeep(response);
    }
  }

  async getLanguages() {
    let response = await firstValueFrom(this.app.apiService.getLanguages());
    if (response && Array.isArray(response)) {
      console.log('Component ID : ', this.componentID, "getLanguages ==>", response);
      this.langues = cloneDeep(response);
    }
  }


  async getCivilities() {
    let response = await firstValueFrom(this.app.apiService.getCivilities());
    if (response && Array.isArray(response)) {
      console.log('Component ID : ', this.componentID, "getCivilities ==> ", response);
      this.civilities = cloneDeep(response);
    }
  }
  //#endregion api Retrieve actions

  //#region api Set actions


  private async attachContact(contact: any, newAttach: boolean = true): Promise<any> {
    console.log('Component ID : ', this.componentID, "attachContact ===> contact : ", contact, " focusedContact : ", this.focusedContact);
    try {
      if (contact) {
        let request: any = {
          ComponentID: this.componentID,
          FormID: this.formID,
          ContactID: contact.ContactID
        }

        if (!newAttach) {
          request.LinkAssocationID = this.focusedContact?.LinkAssocationID
        }

        console.log('Component ID : ', this.componentID, 'attachContact request ===>', request);
        const response = await this.app.apiService.contactSelect(request);
        console.log('Component ID : ', this.componentID, 'RESPONSE attachContact ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog(""
            , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
            , 'KO'
            , "alert", () => { }
            , () => { });
          return;
        }

        setTimeout(() => {
          this.app.confirm.confirmDialog(""
            , this.app.translate.instant('racine.successMsg.successODContactAttach')
            , 'OK'
            , "alert"
            , () => {
            }
            , () => {
            });

        }, 200);
        this.refreshComposition();
      }

    } catch (error) {
      console.error(error);
    }
  }

  private async detachContact(contact: any): Promise<any> {
    console.log('Component ID : ', this.componentID, "detachContact ===>", contact);
    try {
      if (contact) {
        let request: any = {
          ComponentID: this.componentID,
          FormID: this.formID,
          ContactID: contact.ContactID,
          LinkAssocationID: contact.LinkAssocationID
        }
        console.log('Component ID : ', this.componentID, "detachContact request ===>", request);

        const response = await this.app.apiService.contactUnSelect(request);
        console.log('Component ID : ', this.componentID, 'RESPONSE detachContact ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog(""
            , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
            , 'KO'
            , "alert", () => { }
            , () => { });
          return;
        }

        setTimeout(() => {
          this.app.confirm.confirmDialog(""
            , this.app.translate.instant('racine.successMsg.successODContactDetach')
            , 'OK'
            , "alert"
            , () => { }
            , () => { });

        }, 200);
        this.refreshComposition();
      }

    } catch (error) {
      console.error(error);
    }
  }

  private async createOrUpdateVmContact(formObj: any, sendEmail: boolean = false): Promise<any> {
    console.log('Component ID : ', this.componentID, "CREATE VM Contact ===>", formObj);
    try {
      formObj.ComponentID = this.componentID;
      formObj.FormID = this.formID;
      //formObj.GroupID = this.odID;

      if (!this.isContactFormEditMode) {
        const response = await this.app.apiService.createVmContact(formObj);
        console.log('Component ID : ', this.componentID, 'RESPONSE CREATE VM Contact ===>', response);
        this.userID = response?.User?.UserID;
        this.emailContact = response?.Email;
        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }

        if (sendEmail) {
          await this.sendEmailForContact();
        }
        else {
          setTimeout(() => {
            this.app.confirm.confirmDialog(""
              , this.app.translate.instant('racine.successMsg.successContactCree')
              , 'OK'
              , "alert"
              , () => { }
              , () => { });
          }, 200);
        }

        this.selectionChange.emit();
      }
      else {
        console.log('Component ID : ', this.componentID, "🚀  formObj:", formObj);
        const response = await this.app.apiService.updateVmContact(formObj);
        console.log('Component ID : ', this.componentID, 'RESPONSE UPDATE Contact ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }

        if (sendEmail) {
          await this.sendEmailForContact();
        }
        else {
          setTimeout(() => {
            this.app.confirm.confirmDialog(""
              , this.app.translate.instant('racine.successMsg.successContactUpdate')
              , 'OK'
              , "alert"
              , () => { }
              , () => { });
          }, 200);
        }

        this.selectionChange.emit();
      }

      this.clearContactForm();
      this.refreshComposition();

    } catch (error) {
      console.error(error);
    }
  }
  //#endregion api Set actions

  //#endregion api actions

  //#region utils
  private async initializeContactForm(contact: any): Promise<void> {
    let SelectedCivility = this.civilities.find((c: any) => c.CivilityID === contact?.CivilityID);
    let SelectedLanguage = this.langues.find((l: any) => l.LanguageID === contact?.LanguageID);
    let MobilePrefixSelected = (contact?.MobileCodeIso) ? this.app.sharedTools.getObjByLabel(contact?.MobileCodeIso, "CodeISO2", this.mobiles) : null;
    let PhonePrefixSelected = (contact?.PhoneCodeIso) ? this.app.sharedTools.getObjByLabel(contact?.PhoneCodeIso, "CodeISO2", this.phones) : null;


    this.isContactFormSubmitted = false;

    if (this.isContactFormEditMode) {
      this.contactForm = new FormGroup({
        LinkAssocationID: new FormControl(contact?.LinkAssocationID ?? null, Validators.required),

        CivilityID: new FormControl(contact?.CivilityID ?? ''),
        SelectedCivility: new FormControl(SelectedCivility, Validators.required),

        ContactID: new FormControl(contact?.ContactID ?? null, Validators.required),

        FirstName: new FormControl(contact?.FirstName, Validators.required),
        LastName: new FormControl(contact?.LastName, Validators.required),
        OtherFunction: new FormControl(contact?.OtherFunction),
        Email: new FormControl(contact?.Email, [Validators.required, Validators.pattern(EmailRegex)]),

        MobilePrefixSelected: new FormControl(MobilePrefixSelected, Validators.required),
        MobileNumber: new FormControl(contact?.MobileNumber ?? '', Validators.required),

        PhonePrefixSelected: new FormControl(PhonePrefixSelected),
        PhoneNumber: new FormControl(contact?.PhoneNumber ?? ''),

        LanguageID: new FormControl(contact?.LanguageID ?? ''),
        SelectedLanguage: new FormControl(SelectedLanguage, Validators.required),
      });
      await this.app.formatTelHelper.phoneFormatValidator(this.contactForm, 'MobileNumber');
    }
    else {
      this.contactForm = new FormGroup({

        CivilityID: new FormControl(''),
        SelectedCivility: new FormControl(null, Validators.required),

        FirstName: new FormControl('', Validators.required),
        LastName: new FormControl('', Validators.required),
        OtherFunction: new FormControl(''),
        Email: new FormControl('', [Validators.required, Validators.pattern(EmailRegex)]),

        MobilePrefixSelected: new FormControl(null, Validators.required),
        MobileNumber: new FormControl('', Validators.required),

        PhonePrefixSelected: new FormControl(null),
        PhoneNumber: new FormControl(''),

        LanguageID: new FormControl(''),
        SelectedLanguage: new FormControl(null, Validators.required),

      });
    }

    this.initContactForm = true;
  }

  private clearContactForm(): void {
    this.isContactFormEditMode = false;
    this.showContactForm = false;
    this.initContactForm = false;
    this.initializeContactForm(null);
  }

  private refreshComposition(): void {
    this.getContactSource();
  }
  //#endregion  utils

  //#endregion private methods

  async sendEmailForContact() {
    let sendInvitationMailRequest: SendInvitationMailRequest = {
      FormID: this.formID,
      UserID: this.userID,
      Email: this.emailContact,
      ComponentID: this.componentID
    }
    let response = await this.app.apiService.sendEmailForContact(sendInvitationMailRequest);

    if (response && response.Result) {
      this.app.confirm.confirmDialog(this.app.translate.instant('')
        , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
        , 'KO'
        , "alert", () => { }
        , () => { })
      return;
    }
    let msg: string = this.isContactFormEditMode ? 'racine.successMsg.successContactUpdateAndSendMail' : 'racine.successMsg.successContactCreeAndSendMail';

    setTimeout(() => {
      this.app.confirm.confirmDialog(""
        , this.app.translate.instant(msg)
        , 'OK'
        , "alert"
        , () => { }
        , () => { });
    }, 200);

  }


  private initOnSubmitSubscription(): void {
    if (this.refrechContactSourceEvent) {
      this.submitEventSubscription = this.refrechContactSourceEvent.subscribe(() => { this.getContactSource(); });
    }
  }

  private unsubscirbeOnSubmitSubscription(): void {
    if (this.submitEventSubscription) {
      this.submitEventSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscirbeOnSubmitSubscription();
  }

  private async reCheckValidity(contact: any): Promise<boolean> {
    let isValid: boolean;
    let selectedCivility = this.civilities.find((c: any) => c.CivilityID === contact?.CivilityID);
    let selectedLanguage = this.langues.find((l: any) => l.LanguageID === contact?.LanguageID);
    let mobilePrefixSelected = (contact?.MobileCodeIso) ? this.app.sharedTools.getObjByLabel(contact?.MobileCodeIso, "CodeISO2", this.mobiles) : null;
    console.log('MobilePrefixSelected ', mobilePrefixSelected);


    let newContactForm = new FormGroup({
      LinkAssocationID: new FormControl(contact?.LinkAssocationID ?? null, Validators.required),
      SelectedCivility: new FormControl(selectedCivility, Validators.required),
      ContactID: new FormControl(contact?.ContactID ?? null, Validators.required),
      FirstName: new FormControl(contact?.FirstName, Validators.required),
      LastName: new FormControl(contact?.LastName, Validators.required),
      Email: new FormControl(contact?.Email, [Validators.required, Validators.pattern(EmailRegex)]),
      MobilePrefixSelected: new FormControl(mobilePrefixSelected, Validators.required),
      MobileNumber: new FormControl(contact?.MobileNumber ?? '', Validators.required),
      SelectedLanguage: new FormControl(selectedLanguage, Validators.required),
    });

    newContactForm.get('SelectedCivility')?.setValidators(Validators.required);
    newContactForm.get('FirstName')?.setValidators(Validators.required);
    newContactForm.get('LastName')?.setValidators(Validators.required);
    newContactForm.get('Email')?.setValidators([Validators.required, Validators.pattern(EmailRegex)]);
    newContactForm.get('MobilePrefixSelected')?.setValidators(Validators.required);
    newContactForm.get('MobileNumber')?.setValidators(Validators.required);
    newContactForm.get('SelectedLanguage')?.setValidators(Validators.required);

    let isMobileNumberValid: boolean = await this.app.formatTelHelper.phoneFormatValidator(newContactForm, 'MobileNumber');

    newContactForm.get('SelectedCivility')?.updateValueAndValidity();
    newContactForm.get('FirstName')?.updateValueAndValidity();
    newContactForm.get('LastName')?.updateValueAndValidity();
    newContactForm.get('Email')?.updateValueAndValidity();
    newContactForm.get('MobilePrefixSelected')?.updateValueAndValidity();
    newContactForm.get('MobileNumber')?.updateValueAndValidity();
    newContactForm.get('SelectedLanguage')?.updateValueAndValidity();

    newContactForm.updateValueAndValidity();

    console.log("MobileNumber : ", newContactForm.get('MobileNumber'));
    console.log("newContactForm : ", newContactForm);

    if (newContactForm.valid && isMobileNumberValid) {
      isValid = true;
    } else {
      isValid = false;
    }

    console.log(">>> isContact insideFunction : ", isValid);

    return await isValid;
  }

  async onSubmitContactForm(sendEmail: boolean) {
    this.isContactFormSubmitted = true;
    if (this.contactForm.invalid) {
      console.log("<>>> Contact is invalid !");
      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.contactForm.controls;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log('Invalid form values:', invalidValues);

      if (invalidField && invalidField.length > 1) {
        console.log("invalidField", invalidField)

        setTimeout(() => {
          let el = invalidField[1].offsetTop - 100;
          if (el) window.scroll({ top: el, behavior: "smooth" });
        }, 10);

        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
        }, () => { });

      }
    } else {
      const ctcObj = {
        Email: this.contactForm.get('Email')?.value,
        ContactID: this.contactForm.get('ContactID')?.value
      }
      
      await this.emailCheck(ctcObj);

      if (this.isEmailUnique == false) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_CONTACTALREADYEXIST'), 'KO', 'alert', () => {
        }, () => { });
      }else{
        this.confirmSendEmail(sendEmail);
      }
    }
  }

  async emailCheck(ctcObj: any){
    const resp = await this.app.apiService.emailCheck(ctcObj);
    
    if (resp) {
      console.log("<<<🚀  resp:", resp);
      this.isEmailUnique = resp.IsEmailUnique;
    }
  }

  confirmSendEmail(sendEmail: any){
    if (this.enableInvitationEmail && (this.isContactFormEditMode == false || sendEmail)) {
      this.app.confirm.confirmDialog("",
        this.app.translate.instant('racine.confirmMsg.confirmSendContact')
        , 'OK'
        , "confirm"
        , () => {
          //true to save and send email
          this.onContactFormSubmit(true);
        }
        , () => { });

    }
    else {
      //false to save only
      this.onContactFormSubmit();
    }
  }
}
