export enum RoleType {
    DP = "DP",
    F18User = "F18_USER",
    Helped = "HELPED",
    Innovation = "INNOVATION",
    F18 = "F18",
    Attendee = "ATTENDEE",
    News = "NEWS",
    Conference = "CONFERENCE",
    Program = "PROG"
}
