import { Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Paginator } from 'primeng/paginator';
import { AppComponent } from 'src/app/app.component';
import { FOSolutionsSearchLocalStorageKey } from 'src/app/consts/utils';
import { APPID } from 'src/app/enums/app_id';
import { defaultAppGlobalConfig } from 'src/app/models/app-global-config';
import { ApiService } from 'src/app/services/api.service';
import { ExhibitorStoreService } from 'src/app/services/exhibitor-store.service';
import { SearchHelperService } from 'src/app/services/search-helper.service';

@Component({
  selector: 'app-welcome-sol',
  templateUrl: './welcome-sol.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./welcome-sol-global.component.less',
    './welcome-sol.component.less',
    './welcome-sol-responsive.component.less'
  ]
})
export class WelcomeSolComponent implements OnInit, OnDestroy {
  private _isPaginationInfoRestaured: boolean = false;
  public rowsPerPageOptions = defaultAppGlobalConfig.rowsPerPageOptions;
  @ViewChild('paginator') paginator?: Paginator;

  // first
  first: number = 0;

  // nbElementsPerPage
  private _nbElementsPerPage: number = defaultAppGlobalConfig.defaultRowsNumberPerPage;
  public get nbElementsPerPage(): number {
    return this._nbElementsPerPage;
  }

  public set nbElementsPerPage(value: number) {
    this._nbElementsPerPage = value;
    this.first = (this._pageIndex ?? 0) * (this.nbElementsPerPage ?? 1)
  }

  // pageIndex
  private _pageIndex: number = 0;
  public get pageIndex(): number {
    return this._pageIndex;
  }

  public set pageIndex(value: number) {
    this._pageIndex = value;
    this.first = (this._pageIndex ?? 0) * (this.nbElementsPerPage ?? 1)
  }
  
  // totalItems
  totalItems: number = 0;

  user: any = null;
  appID: any = null;

  folderGuid: any;
  currentlangue: any;
  search: any = {};
  solution!: any;

  showFilterMobile: boolean = false;

  screenWidth: number = 0;
  isDesktop: boolean = false;
  isMobile: boolean = false;

  columnOneItems: any[] = [];
  columnTwoItems: any[] = [];
  columnThreeItems: any[] = [];

  constructor(
    public app: AppComponent,
    private apiService: ApiService,
    public exhibitorStore: ExhibitorStoreService,
    private renderer: Renderer2,
    private searchHelperService : SearchHelperService
  ) {
    this.initializeConfiguredOptions();
    this.screenWidth = window.innerWidth;
    this.renderer.setStyle(document.body, 'background-color', '#144c96');
  }

  async ngOnInit() {

    this.onResize();

    this.app.sharedTools.getJSON("general_data").subscribe((resG: any) => {
      this.appID = resG.APP_ID;
      this.currentlangue = this.app.translate.currentLang;
    });
    
    //await this.searchSolutions(this.search);
  }

  ngOnDestroy() {
    this.renderer.removeStyle(document.body, 'background-color');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 995) {
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }


  public onFilterSolution(search: any) {
    this.pageIndex = 0;

    this.showFilterMobile = false;
    this.searchSolutions(search);
  }

  public onFilterExhibitor(search: any) {
    this.searchSolutions(search);
  }

  onClearSearch(): void {
    this.pageIndex = 0;
    
    console.log("*** onClearSearch");
    this.search = {};
    this.searchSolutions(this.search);
  }
  clickLink(routerLink: any) {
    this.app.sharedTools.redirectTo(routerLink);
  }

  toggleFilter() {
    this.showFilterMobile = !this.showFilterMobile;
  }


  //#region 

  private splitCardsIntoColumns() {
    this.columnOneItems = [];
    this.columnTwoItems = [];
    this.columnThreeItems = [];
    if (this.solution?.ListDetailsProducts && Array.isArray(this.solution.ListDetailsProducts)) {
      for (let i = 0; i < this.solution?.ListDetailsProducts?.length; i++) {
        if (i % 3 === 0) {
          this.columnOneItems.push(this.solution?.ListDetailsProducts[i]);
        } else if (i % 3 === 1) {
          this.columnTwoItems.push(this.solution?.ListDetailsProducts[i]);
        } else {
          this.columnThreeItems.push(this.solution?.ListDetailsProducts[i]);
        }
      }
    }
  }

  async searchSolutions(search: any) {
    if (!this._isPaginationInfoRestaured) {
      this.restaurePaginatorInfo();
    }

    let searchTemp: any = cloneDeep(search);
    if (!searchTemp) {
      searchTemp = {};
    }
    this.search = searchTemp;

    this.search.CurrentLanguage = this.app.translate.currentLang;
    this.search.NbElementsPerPage = this.nbElementsPerPage;
    this.search.PageIndex = this.pageIndex;
    this.search.GetAll = false;

    // save latest search criteria
    this.searchHelperService.setAdvancedSearch(this.search, FOSolutionsSearchLocalStorageKey);

    let response = await this.apiService.searchSolutions(this.search);    
    this.solution = [];

    if (response) {
        this.solution = response;
        this.totalItems = this.solution.NbProducts;
        console.log("this.solution ==> ", this.solution);
       this.splitCardsIntoColumns();
        this.exhibitorStore.solutionsIds = this.solution.AllIDs;
    }

    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  checkExhibitorMenu(event:any) {
  console.log("🚀  event:", event)

  }

  onPageChange(event: any) {
    console.log('event : ', event);
    this.pageIndex = event.page ?? 0;
    this.nbElementsPerPage = event.rows ?? defaultAppGlobalConfig.defaultRowsNumberPerPage;
    this.searchSolutions(this.search);
  }

  private restaurePaginatorInfo(): void {
    let latestsearch: any = this.searchHelperService.getAdvancedSearch(FOSolutionsSearchLocalStorageKey);

    if (latestsearch) {
      this.pageIndex = latestsearch.PageIndex ? latestsearch.PageIndex : 0;
      this.nbElementsPerPage = latestsearch.NbElementsPerPage ? latestsearch.NbElementsPerPage : defaultAppGlobalConfig.defaultRowsNumberPerPage;

      console.log(this.first, this._pageIndex, this._nbElementsPerPage);
    }

    this._isPaginationInfoRestaured = true;
  }

  private initializeConfiguredOptions(): void{
    this.rowsPerPageOptions = this.app.sharedTools.appGlobalConfig.rowsPerPageOptions;
    this.nbElementsPerPage = this.app.sharedTools.appGlobalConfig.defaultRowsNumberPerPage;
  }
  //#endregion

}
