import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-planning-htmloptions',
  templateUrl: './exportzip-htmloptions.component.html',
  styleUrls: ['./exportzip-htmloptions.component.less']
})
export class ExportZipHtmlOptionsComponent implements OnInit {

  public emails : string[] = [] ;
  public isIncludeNoteChecked : boolean = false ;
  public includeNoteSection : boolean = true ;
  public ValidateLabel: string ="";
  public emailForm:FormGroup;
  submitted: boolean = false;

  constructor( public translateservice:TranslateService, private ref: DynamicDialogRef, private configDialog : DynamicDialogConfig,private fb: FormBuilder) { 

    this.emailForm = this.fb.group({

      emails: ['', [Validators.required, this.emailListValidator()]]
    });
    translateservice.use(this.translateservice.currentLang);
  }
 
  ngOnInit(): void {

    this.includeNoteSection = this.configDialog.data.includeNoteSection;

  }
  
  onValidate(){
    this.submitted=true;
    if(this.emailForm.valid){
      let result : any = {} ;
      const pChipsValue = this.emailForm.get('emails')?.value;
      console.log("-------------------------------->",pChipsValue);

      result['emails'] = pChipsValue;
      setTimeout(() => {
        this.ref.close(result);
      }, 200);
    }
  }
  emailListValidator() {
    const EmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    return (control:any) => {
      const emails: string[] = control.value ? control.value : [];
      console.log('emails',emails);
      const invalidEmails = emails.filter(email => !EmailRegex.test(email.trim()));
      return invalidEmails.length > 0 ? { invalidEmails: true } : null;
    };
    }
  

}
