import { Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { SearchHelperService } from '../services/search-helper.service';
import { DatePipe, KeyValue } from '@angular/common';
import { cloneDeep } from 'lodash';
import { VMFolderSearchLocalStorageKey, VMUserSearchLocalStorageKey } from '../consts/utils';
import { ODPlanningSearch } from '../models/od-planning-search';
import { Table } from 'primeng/table';
import { FolderSearchHelperService } from '../services/folder-search-helper.service';
import { StatusType } from '../enums/status-type';
import { Subscription } from 'rxjs';
import { StatusCode } from '../enums/status-code';
import { IdentityManagerService } from '../services/identity-manager.service';
import { ExtendedCountries } from '../enums/extended-countries';
import { RoleTypeId } from '../enums/role-type-id';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PlanningHTMLOptionsComponent } from '../planning-htmloptions/planning-htmloptions.component';

import { HelperService } from '../services/helper.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HtmlPlanningRapportDoComponent } from '../html-planning-rapport-do/html-planning-rapport-do.component';
import { ReportingService } from '../services/reporting.service';
import { ConfirmDialogService } from '../services/confirm-dialog.service';
import { environment } from 'src/environments/environment';
import { Languages } from '../enums/language';
@Component({
    selector: 'app-planning-do', 
    templateUrl: './planning-do.component.html',
    encapsulation: ViewEncapsulation.None, 
    styleUrls: ['./planning-do.component.less']
})
export class PlanningDOComponent implements OnDestroy {
    private _areDynamicColumnsMapped: boolean = false;

    constructor(
        public app: AppComponent,
        private searchHelperService: SearchHelperService,
        private folderSearchHelperService: FolderSearchHelperService,
        private datePipe: DatePipe,
        public identityService: IdentityManagerService,
        public helperService: HelperService,
        public translatePipe: TranslatePipe,
        public dialogService: DialogService,
        private router : Router,
        private messageService: MessageService,
        public reportingService: ReportingService,
    ) { }
    isModalVisible : boolean = false ;
    isVisible : boolean = false ;
    @ViewChild('container') container: HtmlPlanningRapportDoComponent | undefined;
    planningDo : any ;
    includeNote : boolean = false ;
    planningDoCopy : any ;

    @ViewChild('dtPlannings') dtPlannings!: Table;
    private planningODExportSubscription?: Subscription;
    fastSearchUserKeyword: string = '';
    roleTypeId = RoleTypeId;
    ref: DynamicDialogRef | undefined;

    ngOnDestroy(): void {
    }
    items: any = [];

    search: any = {};
    userDO: boolean = false;
    plannings: any[] = [];
    selectedPlanning: any = [];
    showTable: boolean = true;

    //custom options to append drop downn lists
    planninDOCustomOptions: any = {};

    //#region retrieved data from api
    //checked = false
    userODsTypeCategories: any = [];

    //checked = true
    allODsTypeCategories: any = [];

    //checked = false
    userODs: any[] = [];

    //checked = true
    allODs: any[] = [];

    //checked = false
    userODsCountries: any = [];

    //checked = true
    allODsCountries: any = [];

    //checked = false
    userODsOAContacts: any[] = [];

    //checked = true
    allOAContacts: any[] = [];

    //checked = false
    userODsOZContacts: any[] = [];

    //checked = true
    allOZContacts: any[] = [];

    disponibilityDateList: Date[] = [];

    //
    edition: any;

    //#endregion

    //#region datadources for search engine
    oDTypeCategories: any = [];
    listODs: any[] = [];
    countries: any = [];
    listOAContacts: any[] = [];
    listOZContacts: any[] = [];
    disponibilities: any = [];
    status: any = [];
    stats: any = [];
    userId : any 
    //#endregion

    showAdvancedSearch: boolean = false;

    allGroupedAction : any[]=[]
    actions : any[]=[]
    _selectedAction : any
    langSubscription ?: Subscription
    userEmail : string | undefined

    //columns

    _selectedColumns: any = [];
    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }
    set selectedColumns(val: any[]) {
        // ==> restore original order
        this._selectedColumns = this.colsPlanningDO.filter((col: any) => val.includes(col));
    }

    colsPlanningDO: any = [];

    ngOnInit() {
        Object.assign(this.search, cloneDeep(this.searchHelperService.getAdvancedSearch(VMUserSearchLocalStorageKey)));

        this.fastSearchUserKeyword = this.searchHelperService.getFastSearchKeyword(VMUserSearchLocalStorageKey);
        //get privious advanced search value before routing to other routes
        this.search = cloneDeep(this.searchHelperService.getAdvancedSearch(VMFolderSearchLocalStorageKey));
        let user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log(user);
        if (!user) {
            this.app.router.navigate(['/login']);
            return;
        }
        this.userEmail = user?.Contact?.Email;
        this.userId = user.UserID
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG", resG);

                //getcolumns
                this.colsPlanningDO = resG.colsPlanningDO;

                this._selectedColumns = this.colsPlanningDO;

                //get planning do grouped action 
                this.allGroupedAction = resG.actionPlanningDO
                this.fillGroupedActionCombo(this.allGroupedAction)


                this.planninDOCustomOptions = resG.planninDOCustomOptions;
                this.getStatus();
                this.getEdition();
                this.getAllODsTypeCategories();

                if(this.identityService.haveOnly(this.roleTypeId.VM_Consultant))
                {
                    this.userDO = true
                }
                else{
                    this.userDO = false
                }

                // this.getCountries();
                this.getUserODsCountries();
                this.onAllDOChange();
                this.searchODPlanning(this.search);
                this.langSubscription = this.app.translate.onLangChange.subscribe(
                    data => {
                        this.fillGroupedActionCombo(this.allGroupedAction)
                    }
                )
            });
    }

    getCountries() {
        this.app.apiService.getExtendedCountries(ExtendedCountries.vm).subscribe((data: any) => {
            console.log("getCountries ==> ", data);
            setTimeout(() => { 
                    this.countries = data;
                               }, 1000);
                               
        });
    }
    onRowSelect(event: any) {
        console.log("event.data==>", event.data);
    }

    onAllDOChange(): void {
        if (this.userDO) {
            this.getAllODsTypeCategories();
            this.getAllODs();
            this.getAllODsCountries();
            this.getAllODsOAContacts();
            this.getAllODsOZContacts();
        }
        else {
            this.getUserODsTypeCategories();
            this.getUserODs();
            this.getUserODsCountries();
            this.getUserODsOAContacts();
            this.getUserODsOZContacts();
        }
    }

    getStatus() {
        this.app.apiService.getStatus().subscribe((data: any) => {
            this.status = cloneDeep(data);
            this.status = this.status.filter((s: any) => s.StatusType === StatusType.F18OD);

            this.status = [this.planninDOCustomOptions.allExceptArchivedStatusOption, ...this.status]
        });
    }
    //#region begin get DATA (users, all) => selon check
    //ODS
    async getUserODs() {
        if (!this.userODs || this.userODs.length < 1) {
            this.userODs = await this.app.apiService.getAllDO(false);
        }
        this.listODs = this.userODs;

        console.log('ODS ::: >>> ', this.listODs)
    }

    async getAllODs() {
        if (!this.allODs || this.allODs.length < 1) {
            this.allODs = await this.app.apiService.getAllDO(true);
        }
        this.listODs = this.allODs;
    }

    //COUNTRIES
    async getUserODsCountries() {
        if (!this.userODsCountries || this.userODsCountries.length < 1) {
            this.userODsCountries = await this.app.apiService.getcountries(false);
        }
        this.countries = this.userODsCountries;
    }
    async getAllODsCountries() {

        if (!this.allODsCountries || this.allODsCountries.length < 1) {
            this.allODsCountries = await this.app.apiService.getcountries(true);
        }
        this.countries = this.allODsCountries;
    }

    //TYPE CATEGORY
    async getUserODsTypeCategories() {
        if (!this.userODsTypeCategories || this.userODsTypeCategories.length < 1) {
            this.userODsTypeCategories = await this.app.apiService.getTypeDO(false);
        }

        this.oDTypeCategories = this.userODsTypeCategories;
    }

    async getAllODsTypeCategories() {
        console.log("this.oDTypeCategories",this.oDTypeCategories)
        if (!this.allODsTypeCategories || this.allODsTypeCategories.length < 1) {
            this.allODsTypeCategories = await this.app.apiService.getTypeDO(true);
        }

        this.oDTypeCategories = this.allODsTypeCategories;
        console.log("this.allODsTypeCategories",this.allODsTypeCategories)
    }

    //OZ CONTACTS
    async getUserODsOZContacts() {
        if (!this.userODsOZContacts || this.userODsOZContacts.length < 1) {
            this.userODsOZContacts = await this.app.apiService.getozs(false);
            //this.userODsOZContacts = [ this.planninDOCustomOptions.allOZOption, ...this.userODsOZContacts]
        }

        this.listOZContacts = this.userODsOZContacts;
    }

    async getAllODsOZContacts() {
        if (!this.allODs || this.allODs.length < 1) {
            this.allOZContacts = await this.app.apiService.getozs(true);
            //this.allOZContacts = [ this.planninDOCustomOptions.allOZOption, ...this.allOZContacts]
        }

        this.listOZContacts = this.allOZContacts;
    }

    //OA CONTACTS
    async getUserODsOAContacts() {
        if (!this.userODsOAContacts || this.userODsOAContacts.length < 1) {
            this.userODsOAContacts = await this.app.apiService.getoas(false);
            // this.userODsOAContacts = [ this.planninDOCustomOptions.allOAOption, ...this.userODsOAContacts]
        }

        this.listOAContacts = this.userODsOAContacts;
        console.log('listOAContacts ', this.listOAContacts);
    }

    async getAllODsOAContacts() {
        if (!this.allOAContacts || this.allOAContacts.length < 1) {
            this.allOAContacts = await this.app.apiService.getoas(true);
            //this.allOAContacts = [ this.planninDOCustomOptions.allOAOption, ...this.allOAContacts]
        }

        this.listOAContacts = this.allOAContacts;
        console.log('listOAContacts ', this.listOAContacts);
    }

    // DISPONIBILITY
    private async getEdition() {
        try {
            const response = await this.app.apiService.getEdition();
            console.log("🚀  response getEdition :", response);

            this.edition = response;
            if (this.edition?.AllEditionDates
                && this.edition?.AllEditionDates.length > 0) {
                this.disponibilities = [];
                this.edition.AllEditionDates.forEach((e: any) => {
                    let disponibility: any = {
                        Disponibility: e,
                        LabelEN: this.datePipe.transform(e, 'EEEE dd MMMM', '', Languages.English) ?? '',
                        LabelFR: this.datePipe.transform(e, 'EEEE dd MMMM', '', Languages.French) ?? '',
                        LabelES: this.datePipe.transform(e, 'EEEE dd MMMM', '', Languages.Spanish) ?? '',
                    };

                    this.disponibilities.push(disponibility);
                });
            }

            // this.disponibilities = [this.planninDOCustomOptions.allDisponibilityOption, ...this.disponibilities];


            console.log("🚀  this.disponibilities :", this.disponibilities);
        } catch (error) {
            console.error(error);
        }
    }
    //#endregion

    clearSearch() {
        console.log("clearSearch ==>");
        this.search = new ODPlanningSearch();
        this.searchODPlanning(this.search);
    }
    
    async searchODPlanning(search: any, thenFilterFast: boolean = false) {
        //save search to retrieve it when coming back to BO after redirect from BO
        this.folderSearchHelperService.advancedSearch = cloneDeep(search);

        console.log("search ==> ", search);
        let searchTemp: any = cloneDeep(search);

        if (searchTemp.selectedStatus) searchTemp.StatusID = searchTemp.selectedStatus.StatusID;
        //
        if(searchTemp.StatusID === -1){
            searchTemp.AllStatusExceptArch = true;
            searchTemp.StatusID = null;
        }

        if (searchTemp.selectedCountry) searchTemp.CountryID = searchTemp.selectedCountry.CountryID;
        if (searchTemp.selectedODTypeCategory) searchTemp.ODTypeCategoryID = searchTemp.selectedODTypeCategory.CategoryID;
        if (searchTemp.selectedOD) searchTemp.GroupID = searchTemp.selectedOD.GroupID;
        if (searchTemp.selectedOZContact) searchTemp.OZContactID = searchTemp.selectedOZContact.ContactID;
        if (searchTemp.selectedOAContact) searchTemp.OAContactID = searchTemp.selectedOAContact.ContactID;
        if (searchTemp.selectedDisponibility) searchTemp.Disponibility = searchTemp.selectedDisponibility.Disponibility;
              searchTemp.UserDO = this.userDO;
        if (searchTemp.selectedStatus) delete searchTemp.selectedStatus;
        if (searchTemp.selectedCountry) delete searchTemp.selectedCountry;
        if (searchTemp.selectedODTypeCategory) delete searchTemp.selectedODTypeCategory;
        if (searchTemp.selectedOD) delete searchTemp.selectedOD;
        if (searchTemp.selectedOZContact) delete searchTemp.selectedOZContact;
        if (searchTemp.selectedOAContact) delete searchTemp.selectedOAContact;
        if (searchTemp.selectedDisponibility) delete searchTemp.selectedDisponibility;
         
        console.log("searchTemp ==> ", searchTemp);

        try {
            const response = await this.app.apiService.searchPlannings(searchTemp);
            console.log("searchODPlanning ==> ", response);
            this.plannings = response;

            if(!this._areDynamicColumnsMapped){
                this.mapDynamicColumns();
            }
            this.mapValuesToDynamicColumns();

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                    , 'KO'
                    , "alert"
                    , () => { this.app.sharedTools.redirectTo('/'); }
                    , () => { })
                return;
            }
        } catch (error) {
            console.error(error);
        }
    }

    toggleMenu(event: any, formId: number, items: any) {
        //this.menuAction.toggle(event);

        console.log("formId ID:", formId);
        console.log("items:", items);

        items.forEach((itemMenu: any) => {
            itemMenu.formIdRow = formId;
            itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

            if (itemMenu.ActionID == 1) {
                itemMenu.command = (event: any) => {
                    this.app.sharedTools.redirectTo('/detail-vm-exposant/' + event.item.formIdRow.toString());
                }
            }
            //TODO: @Why: this code commented by @Hassen, @Why: found only one action general_data.json property [actionsRow]
            //TODO: so added second action with ActionID == 2
            // if (itemMenu.ActionID == 2) {
            //     itemMenu.command = (event: any) => {
            //         this.download(event.item.folderIdRow);
            //     }
            // }
            // if (itemMenu.ActionID == 3) {
            //     itemMenu.command = (event: any) => {
            //         // ==> Call function
            //     }
            // }
            if (itemMenu.ActionID == 2) {
                itemMenu.command = (event: any) => {
                    //this.consultDirectExhibitor(event.item.formIdRow);
                }
            }
        });
    }

    getColorStatus(status: any) {
        let classColor = "";
        if (status) {
            switch (status.Code) {
                case StatusCode.SCHEDULED_OD:
                    classColor = "fill-light-blue"
                    break;

                case StatusCode.DATE_CONFIRMED_OD:
                    classColor = "fill-lime-green"
                    break;

                case StatusCode.CANCELLED_OD:
                    classColor = "fill-red"
                    break;

                case StatusCode.ARCHIVED_OD:
                    classColor = "fill-light-grey"
                    break;

                default:
                    classColor = "fill-dark-blue"
                    break;
            }
        }
        return classColor;
    }

    //#region Export
    exportPlanningODToCsv(): void {
        this.PlanningODExport();
    }

    exportCSV() { 
        this.helperService.exportCSV(this.plannings, this.mapColumn(),'planningDO_export_' + new Date().toLocaleDateString(),true);
    }
    mapColumn(){
        const emailList: string[] = [];

        let selectedColumnsExceptAction: any[] = this.selectedColumns.filter((c: any) => c.field !== 'calendarPrint')
        
        return this.plannings.map((row: any) => {
            const filteredColumns: any = {};
            selectedColumnsExceptAction.forEach(column => {
            //   if(this.translalteService.currentLang === "fr"){
                switch (column.field) {
                    case "NbPersonnes": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.NbPersonnes ?? ''
                        break;
                     case "StatusID": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLangByID( row.Status?.StatusID,'StatusID',this.status)
                         break;
                    case "GroupID": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getPropertySwitchLangByID(row.GroupID,"GroupID",this.listODs, 'Name') +` ${row.DO?.Type ?? ''} ${row.DO?.DemandsNumber ?? ''} `/*${row.Chef?.FullName ?? ''} ${row.Chef?.Function ?? ''} ${row.Chef?.InterestCenter?? ''}  */
                        break;
                    case "OZContactID":  filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] =  row.OZUser.map((ozUser: any) => `${ozUser?.Contact?.FullName || ''} ${ozUser?.Contact?.MobilePrefix} ${''} ${ozUser?.Contact?.MobileNumber|| ''} ${ozUser.Contact?.Email || ''}`).join(', ');
                        break;
                     case "OAContactID":  filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.OAUser.map((oaUser: any) => `${oaUser?.Contact?.FullName || ''} ${oaUser?.Contact?.MobilePrefix} ${''} ${oaUser?.Contact?.MobileNumber|| ''} ${oaUser.Contact?.Email || ''}`).join(', ');
                         break;
                    case "Alert": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] ='';
                     break;
                    default: 
                        filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row[column.field] ?? ''
                     
                  
                   
                        break;
                }
            });
            return filteredColumns;
        })
    }
    private PlanningODExport(): void {
        
        this.planningODExportSubscription = this.app.apiService.planningODExport(this.search).subscribe({
            next: (data: any) => {
                console.log('planningODExport', data);
                if (data) {
                    let filesUrl: string = data;

                    window.open(filesUrl);
                }

            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }

    private mapDynamicColumns(): void{
        if(this.plannings && this.plannings.length>0){
            let stats: any = this.plannings[0].PlanningStats;
            //create dynamic cols
            if(stats && Array.isArray(stats) && stats.length > 0){
                let alertColumn = cloneDeep(this.colsPlanningDO[this.colsPlanningDO.length - 2]);
                let actionsPrintPlanning = cloneDeep(this.colsPlanningDO[this.colsPlanningDO.length - 1]);
                this.colsPlanningDO.pop();
                this.colsPlanningDO.pop();
                let lastColID: number = actionsPrintPlanning.ColID;
                stats.forEach((s: any) => {
                    lastColID++;
                    let newCol: any = 
                    {
                        ColID: lastColID,
                        field: s.Date,
                        header: this.datePipe.transform(s.Date, 'dd/MM'),
                        LabelEN: this.datePipe.transform(s.Date, 'dd/MM'),
                        LabelFR: this.datePipe.transform(s.Date, 'dd/MM'),
                        selected: true,
                        filter: false,
                        sort: false,
                        isStat: true
                    }
                    this.colsPlanningDO.push(newCol)
                });
                this.colsPlanningDO.push(alertColumn);
                this.colsPlanningDO.push(actionsPrintPlanning);
                console.log('columns ', this.colsPlanningDO);
                this._areDynamicColumnsMapped = true;
            }
        }
    }   

    private mapValuesToDynamicColumns(): void{
        //prepare properties fot dynamic cols
            this.plannings.forEach((p: any) =>{
                let stats: any = p.PlanningStats;
                //create dynamic cols
                if(stats){
                    stats.forEach((s: any) => {
                        p[s.Date] = `${s.VisitCount}/${s.SlotCount}`
                    });
                }
            });
    }
    //#endregion

    // openModal(planningDo : any){
    //     console.log(planningDo) 
    //     this.planningDoCopy = planningDo;
    //     this.reportingService.getPlanningDo(planningDo.GroupID).subscribe({
    //         next: res => {
    //             if(res.isSuccess === false){
    //                 this.messageService.add({ severity: 'info', summary: 'Info', detail: res.error });
    //                 return;
    //             }
    //             console.log(res)
    //             this.planningDo = res;
    //             this.ref = this.dialogService.open(PlanningHTMLOptionsComponent, {
    //                 header : "Options du rapport",
    //                 width:'50%',
    //                 contentStyle: { overflow: 'auto' },
    //                 baseZIndex: 100,
    //                 maximizable: false,
    //                 styleClass: 'generateplanning'
    //             });

    //             this.ref.onClose.subscribe((val: {emails:string[],includeNote: boolean,preview:boolean}) => {
    //                 console.log('closed ', val); 
    //                 if(val){
    //                     this.includeNote = val.includeNote;
    //                     console.log(this.includeNote)
    //                     if(val.preview === true){ 
    //                         this.isModalVisible = true ;
    //                     }
    //                     else { 
    //                         this.download(`${this.app.sharedTools.getPropertySwitchLang(planningDo, 'GroupName')}_DO`,val.emails); 
    //                     }
    //                 }
    //                 });
    //         }
    //     })
        
    // }

    openModal(planningDo : any){
        let emails : string[] = []
        if(this.userEmail != undefined){
            emails.push(this.userEmail)
        }
        this.ref = this.dialogService.open(PlanningHTMLOptionsComponent, {
            header : "Options du rapport",
            width:'50%',
            contentStyle: { overflow: 'auto' },
            baseZIndex: 100,
            maximizable: false,
            styleClass: 'generateplanning',
            data : {includeNoteSection : true,emailList : emails}
        }); 
        this.ref.onClose.subscribe((val: {emails:string[],includeNote: boolean}) => {
            console.log('closed ', val); 
            if(val){
                let planningList = []
                planningList.push(planningDo.GroupID)
                this.reportingService.getPlanningDo(planningList,val.includeNote,val.emails,this.app.translate.currentLang)
                .subscribe({
                    next : (res:any) =>{
                        console.log('GENERATE** getPlanningDo : ',res);

                        if (res && res.Result) {
                            setTimeout(() => {
                                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + res.Result)
                                    , 'KO'
                                    , "alert"
                                    , () => { /*this.app.sharedTools.redirectTo('/');*/ }
                                    , () => { });
                            }, 200);
                            return;
                        }

                        if(res && res.IsSuccessful === false){
                            setTimeout(() => {
                                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + res.Error)
                                    , 'KO'
                                    , "alert"
                                    , () => { /*this.app.sharedTools.redirectTo('/');*/ }
                                    , () => { });
                            }, 200);
                                return;
                        }

                        if(res && res.Url){
                            window.open(res.Url, '_blank'); 
                        }

                        if(res && res.SendEmailIsSuccessful === false){
                            this.app.confirm.confirmDialog("", this.app.translate.instant('bo.vm-planning-do.sendPlanningEmailPlannedError')
                            , 'KO'
                            , "alert"
                            , () => { /*this.app.sharedTools.redirectTo('/');*/ }
                            , () => { });
                            return;
                        }
                        console.log('getPlanningDo : ',res);
                   if(val.emails && Array.isArray(val.emails)
                    && val.emails.length > 0){
                        this.app.confirm.confirmDialog("", this.app.translate.instant('bo.vm-planning-do.sendPlanningEmailPlannedSuccess')
                        , 'KO'
                        , "alert"
                        , () => { /*this.app.sharedTools.redirectTo('/');*/ }
                        , () => { });
                    }
                    return;
                }
                })
            }
        });

    }


    // download(fileName:string, emails?: string[]){
    //     this.reportingService.downloadAsPdf(
    //         this.container?.container?.nativeElement,`${fileName}_${new Date().toLocaleDateString()}`,
    //         emails)
    //     this.isModalVisible = false;
    // }

    // onCancel(): void {
    //     this.ref?.close();
    // }

    // onValidate(){
        
    // }

    navigateToPlanningHtml(planning : any){
        let showAllDo = this.userDO == false ? 1 : 0
        let currentLang = this.app.translate.currentLang
        let currentUrl = this.router.url.split('/')[1]
        let url = `${environment.planningHtml}?user-id=${this.userId}&do-id=${planning.GroupID}&show-owned-only=${showAllDo}&lang=${currentLang}&from=/${currentUrl}`
        window.open(url,"_blank")
    }

    onActionSelectChange(action : any){
        console.log(action)
        if(this._selectedAction.ActionID == 1){
            let planningList = []
            planningList = this.selectedPlanning.map((x:any)=>{
                return x.GroupID
            })
            console.log(planningList)
            console.log(this.selectedPlanning)
            this.openModalPlanningList(planningList)
        }
    }

    openModalPlanningList(planinngs : any[]){
        let emails : string[] = []
        if(this.userEmail != undefined){
            emails.push(this.userEmail)
        }
        this.ref = this.dialogService.open(PlanningHTMLOptionsComponent, {
            header : "Options du rapport",
            width:'50%',
            contentStyle: { overflow: 'auto' },
            baseZIndex: 100,
            maximizable: false,
            styleClass: 'generateplanning',
            data : {includeNoteSection : true, isEmailRequired: true, emailList : emails}
        });
        
        this.ref.onClose.subscribe((val: {emails:string[],includeNote: boolean}) => {
            this._selectedAction = undefined
            console.log('closed ', val); 
            if(val){
                console.log(planinngs)
                console.log(this.app.translate.currentLang)
                this.reportingService.asyncGetPlanningDo(planinngs,val.includeNote,val.emails,this.app.translate.currentLang)
                .subscribe({
                    next : (res:any) =>{
                        console.log('GENERATE** getPlanningDo : ',res);
                        
                        if (res && res.Result) {
                            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + res.Result)
                                , 'KO'
                                , "alert"
                                , () => { /*this.app.sharedTools.redirectTo('/');*/ }
                                , () => { });
                                return;
                            }

                        if(res && res.IsSuccessful === false){
                            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + res.Error)
                            , 'KO'
                            , "alert"
                            , () => { }
                            , () => { });
                            return;
                        }

                        if(res && res.SendEmailIsSuccessful === false){
                            this.app.confirm.confirmDialog("", this.app.translate.instant('bo.vm-planning-do.sendPlanningEmailPlannedError')
                            , 'KO'
                            , "alert"
                            , () => { }
                            , () => { });
                            return;
                        }
            
                this.app.confirm.confirmDialog("", this.app.translate.instant('bo.vm-planning-do.sendPlanningEmailPlannedSuccess')
                , 'KO'
                , "alert"
                , () => { }
                , () => {});
                return;
            }
                })
            }
        });

    }
    
    fillGroupedActionCombo(data: any) { 
        this.actions = []
        if (this.app.translate.currentLang == "fr") {
            data.forEach((action: any) => {
                let actionModel = {
                    label: action.LabelFR,
                    ActionID: action.ActionID
                }
                this.actions.push(actionModel);
            })
        }
        if (this.app.translate.currentLang == "en") {
            data.forEach((action: any) => {
                let actionModel = {
                    label: action.LabelEN,
                    ActionID: action.ActionID
                }
                this.actions.push(actionModel);
            })
        }
        if (this.app.translate.currentLang == "es") {
            data.forEach((action: any) => {
                let actionModel = {
                    label: action.LabelES,
                    ActionID: action.ActionID
                }
                this.actions.push(actionModel);
            })
        }
    }
}
