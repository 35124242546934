import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';
import { StatusType } from '../enums/status-type';
import { MediasTypes } from '../enums/media-type';
import { firstValueFrom } from 'rxjs';
import { FormCode } from '../enums/form-code';

@Component({
    selector: 'app-liste-products-innov',
    templateUrl: './liste-products-innov.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./liste-products-innov.component.less']
})
export class ListeProductsInnovComponent {
    user: any = null;
    appID: any = null;
    products: any[] = [];
    numbers: any;
    translatedNbProdMessage: any = "";
    showFormProduct: boolean = false;
    solId: any;
    formInnovID!: number;
    innoForm: any;
    status: any[] = [];
    mediaTypes = MediasTypes;
    findImage: any;
    productsLoaded: boolean = false;
    frmData: any


    responsiveOptions: any[] = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    constructor(
        public app: AppComponent,
    ) {
        this.getFormInnovData();
    }

    async getFrmInfo() {
        let request = { frmType: "" }
        request.frmType = FormCode.FRM_INNOV
        let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
        if (data != undefined && data.Id != undefined) {
            this.frmData = data
        }
    }

    async ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log("this.user ==> ", this.user);
        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }
        this.app.route.queryParams
            .subscribe(params => {
                console.log('[params]', params);
                if (params && params['innovid']) {
                    sessionStorage.setItem('innov-form', JSON.stringify({ InnoFormID: params['innovid'] }));
                    this.formInnovID = params['innovid'];


                }
                console.log('[formInnovID]', this.formInnovID)
                if (params && params['folderguid']) {
                    sessionStorage.setItem('folderGuid', JSON.stringify(params['folderguid']));
                }
            });
        // this.app.sharedTools.changeUsrLangInInit();

        // this.app.sharedTools.getCurrentLanguage();

        sessionStorage.removeItem('folderStep');
        sessionStorage.removeItem('activeStep');

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                // console.log("resG ==> ", resG);
                this.appID = resG.APP_ID;
            });

        await this.getStatus();

        this.getInnoForm();
        await this.getFrmInfo()

        const productsLength = this.products.length; // Assuming products is your array
        this.translatedNbProdMessage = this.app.translate.instant('fo.productListCommun.nbProducts').replace('{nb}', productsLength.toString());
    }

    async getInnoForm() {
        this.productsLoaded = false;
        this.innoForm = await this.app.apiService.getAllProducts({ InoFormID: this.formInnovID });
        this.products = this.innoForm?.Solutions ? this.innoForm?.Solutions : [];
        const nbMaxFiles = { NbMaxProducts: this.innoForm.NbMaxProduct, NbTotalProduct: this.innoForm.NbTotalProduct };
        this.numbers = nbMaxFiles;

        console.log("listproducts", this.innoForm)
        this.mapProducts();
    }

    onRefresh(event: any): void {
        this.getInnoForm();
    }

    private getFormInnovData(): void {
        let innovform: any = JSON.parse(sessionStorage.getItem('innov-form') ?? '{}');

        if (innovform) {
            this.formInnovID = innovform.InnoFormID;
        }
    }

    private async getStatus() {
        let data = await firstValueFrom(this.app.apiService.getStatus());

        if (data && Array.isArray(data)) {
            this.status = cloneDeep(data);
            this.status = this.status.filter((s: any) => s.StatusType === StatusType.FicheState);

            console.log("status", this.status)
        }
    }

    private mapSuppPropertiesToProduct(product: any): void {
        console.log("mapSuppPropertiesToProduct", this.products)

        if (product?.Status) {
            let findStatus: any = this.status.find((s: any) => s.StatusID === product.Status);
            product.SelectedStatus = findStatus;
        }

        if (product?.DOC_Documents && product?.DOC_Documents.length > 0) {
            let firstImage: any = product?.DOC_Documents.find((doc: any) => doc.typeDocument && doc.typeDocument.TypeDOCCode === MediasTypes.IMAGE);
            if (firstImage) {
                product.FirstImageUrl = firstImage;
            }
        }

    }

    mapProducts(): void {
        if (!this.productsLoaded) {
            //map other properties to product in products list (solutions)
            this.products.forEach((p: any) => {
                this.mapSuppPropertiesToProduct(p);
            });
            this.productsLoaded = true;
        }
    }

    annuler() {
        let folderGuid = sessionStorage.getItem('folderGuid');
        let guid = JSON.parse(folderGuid!);

        // this.app.sharedTools.goTo('/welcome-f18?folderguid='+guid);
        this.app.sharedTools.goTo(`/welcome-innov?folderguid=${guid}`);
    }
}
