import { Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Menu } from 'primeng/menu';
import { Table, TableFilterEvent } from 'primeng/table';
import { Subscription } from 'rxjs';
import { SearchVMUser } from '../models/search-user';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { cloneDeep } from 'lodash';
import { VMUserSearchLocalStorageKey, hasAnyNonNullProperty } from '../consts/utils';
import { RoleCode } from '../enums/role-code';
import { SearchHelperService } from '../services/search-helper.service';
import { CategoryType } from '../enums/category-type';
import { CategoryCode } from '../enums/category-code';
import { environment } from 'src/environments/environment';
import { RoleType } from '../enums/role-type';
import { HelperService } from '../services/helper.service';
import { Languages } from '../enums/language';

@Component({
  selector: 'app-gestion-vm-user',
  templateUrl: './gestion-vm-user.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./gestion-vm-user.component.less']
})
export class GestionVmUserComponent implements OnDestroy {
  private namescompleteSubscription?: Subscription;
  private usersExportSubscription?: Subscription;

  @ViewChild('menuAction') menuAction!: Menu;
  @ViewChild('dtUsers') dtUsers!: Table;
  @ViewChild('dvUsers') dvUsers!: DataView;

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // ==> restore original order
    this._selectedColumns = this.colsUsers.filter((col: any) => val.includes(col));
  }
  _selectedColumns: any = [];
  fastSearchUserKeyword: string = '';
  showAdvancedSearch: boolean = false;
  search: any = new SearchVMUser();
  showTable: boolean = true;
  roles: any[] = [];
  users: any[] = [];
  colsUsers: any[] = [];
  items: any = [];
  selectedUsers: any[] = [];
  fastFiltersUsers: any = ['RoleEN', 'Grade', 'FirstName', 'LastName', 'Email', 'Mobile'];
  userEtats: any[] = [];
  LastNameSuggestions: string[] = [];
  showTypeOAOZFormControl: boolean = false;
  searchExport:any;
  //all categories with type oaoz
  allOAOZCategories: any[] = [];

  //categories of oa or oz ==> going to change selon role
  oAOZCategories: any[] = [];

  filteredUsers: any[] = [];
  msg ?: string
  showModal : boolean = false 

  @ViewChild('fileInput') fileInput?: ElementRef;

  constructor(
    public app: AppComponent,
    private messageService: MessageService,
    private searchHelperService: SearchHelperService,
    private datePipe: DatePipe,
    private helperService: HelperService) {


  }

  ngOnInit() {
    Object.assign(this.search, cloneDeep(this.searchHelperService.getAdvancedSearch(VMUserSearchLocalStorageKey)));

    this.fastSearchUserKeyword = this.searchHelperService.getFastSearchKeyword(VMUserSearchLocalStorageKey);

    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
        console.log(resG);
        this.userEtats = cloneDeep(resG.vmUserEtats);
        console.log("🚀  this.userEtats:", this.userEtats)
        this.items = resG.actionsUsersRow;

        this.items.forEach((itemMenu: any) => {
          itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
        });
        this.colsUsers = resG.colsVmUsers;
        this._selectedColumns = this.colsUsers;

        this.getRoles();
        this.getCategories();
        this.searchUsers(this.search, true);
      });

  }

  async getCategories() {
    try {
      const response = await this.app.apiService.getCategories();

      let categoriesIni = cloneDeep(response);

      let allOAOZCategories = categoriesIni.filter((cat: any) => {
        return cat.CategoryType == CategoryType.TypeOAOZ;
      });

      this.allOAOZCategories = cloneDeep(allOAOZCategories);

      console.log('get cats ===> ', this.allOAOZCategories);

    } catch (error) {
      console.error(error);
    }
  }

  onSearchName(event: any): void {
    let keyword: string | undefined = event.query;

    if (!keyword || keyword === '') {
      this.LastNameSuggestions = [];
    }
    else {
      this.refreshNamesAutocomplete(keyword);
    }
  }

  filterFast() {
    this.searchHelperService.setFastSearchKeyword(this.fastSearchUserKeyword, VMUserSearchLocalStorageKey);
    console.log("_value", this.fastSearchUserKeyword);

    this.dtUsers.filterGlobal(this.fastSearchUserKeyword, 'contains');
  }

  clearFastSearch(): void {
    this.fastSearchUserKeyword = '';
    this.filterFast();
  }

  toggleAdvancedSearch() {
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  exportUsersToCsv() {
    this.UsersExport();
  }

  getRoles() {
    this.app.apiService.getRoles().subscribe((data: any) => {
      console.log("getRoles ==> ", data);
      this.roles = data; //cloneDeep(data);
      this.roles = this.roles.filter(r => r.RoleType === RoleType.F18User || r.RoleType === RoleType.F18);
    });
  }

  onchangeSelect(_item: any) {
    if (!_item) return;
    // console.log("onchangeSelect", _item);
  }

  clearSearch() {
    console.log("clearSearch ==>");
    this.search = new SearchVMUser();
    this.searchUsers(this.search);
    this.clearFastSearch();
  }

  async searchUsers(search: any, thenFilterFast: boolean = false) {
    //save search to retrieve it when coming back to BO after redirect from BO
    this.searchHelperService.setAdvancedSearch(cloneDeep(search), VMUserSearchLocalStorageKey);

    console.log("search ==> ", search);
    let searchTemp: any = cloneDeep(search);

    // if (searchTemp.selectedRole) {
    //   console.log("selected role Id ==> ", searchTemp.selectedRole);

    //   // let rolesId: string = "";
    //   // searchTemp.selectedRoles.forEach((role: any, index: any) => {
    //   //   rolesId += role.RoleID.toString();
    //   //   if (index != (searchTemp.selectedRoles.length - 1)) {
    //   //     rolesId += ","
    //   //   }
    //   // });
    //   // console.log("rolesId ==> ", rolesId);

    //   searchTemp.RoleID = searchTemp.selectedRole.RoleID;
    // }


    if (searchTemp.selectedRoles) {
      console.log("rolesId ==> ", searchTemp.selectedRoles);

      let rolesId: string = "";
      searchTemp.selectedRoles.forEach((role: any, index: any) => {
        rolesId += role.RoleID.toString();
        if (index != (searchTemp.selectedRoles.length - 1)) {
          rolesId += ","
        }
      });
      console.log("rolesId ==> ", rolesId);

      searchTemp.RoleID = rolesId;
    }

    if (searchTemp.selectedOAOZCategory) searchTemp.OAOZCategoryID = searchTemp.selectedOAOZCategory.CategoryID;
    //if (searchTemp.selectedRoleID) searchTemp.RoleID = searchTemp.selectedRoleID.RoleID;
    if (searchTemp.selectedEtat) searchTemp.LockaccessApp = searchTemp.selectedEtat.LockaccessApp;


    if (searchTemp.selectedRoles) delete searchTemp.selectedRoles;
    //if (searchTemp.selectedRole) delete searchTemp.selectedRole;
    //if (searchTemp.selectedRoleID) delete searchTemp.selectedRoleID;
    if (searchTemp.selectedEtat) delete searchTemp.selectedEtat;
    console.log("searchTemp ==> ", searchTemp);
    this.searchExport=searchTemp;
    try {
      const response = await this.app.apiService.adminUsersSearch(searchTemp);
      console.log("searchUsers==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/users'); }, () => { })
        return;
      }

      this.users = cloneDeep(response)
      console.log("users", this.users);

      setTimeout(() => {
        this.users.forEach((user: any) => {
          user.DateCreatedFormat! = this.datePipe.transform(user.DateCreated, 'dd/MM/yyyy HH:mm:ss');
          user.DateLastLoginFormat! = this.datePipe.transform(user.DateLastLogin, 'dd/MM/yyyy HH:mm:ss');

          if (this.fastFiltersUsers.indexOf('DateCreatedFormat') == -1) this.fastFiltersUsers.push("DateCreatedFormat");
          if (this.fastFiltersUsers.indexOf('DateLastLoginFormat') == -1) this.fastFiltersUsers.push("DateLastLoginFormat");

          user.selectedRolesCategories = [];
          user.selectedRoles = [];
          user.Roles = "";

          if (user.RoleID) {
            let listRoles: any = user.RoleID.split(',').map((roleId: string) => {
              return { RoleID: parseInt(roleId) };
            });
            user.selectedRoles = this.roles.filter((sourceElement: any) =>
              listRoles.some((cibleElement: any) => cibleElement.RoleID === sourceElement.RoleID)
            );
          }

          //user.selectedRoleID = this.roles.filter((role: any) => { return role.RoleID == user.RoleID })[0];
          //user.selectedActive = this.userEtats.filter((etat: any) => { return etat.Active === user.Active })[0];

          if (user.selectedRoles.length > 0) {
            user.selectedRoles.forEach((userRole: any) => {
              this.app.sharedTools.getLabelSwitchLangByID(userRole.RoleID, 'RoleID', this.roles);
              this.app.sharedTools.getLabelSwitchLang(userRole);
              user.Roles += userRole.label + ' ';

              if (this.fastFiltersUsers.indexOf('Roles') == -1) this.fastFiltersUsers.push("Roles");


              let roleCategory = { role: userRole, category: null };

              if (userRole.RoleCode === RoleCode.OA) {
                if (user.OAOZCategory
                  && (user.OAOZCategory.Code === CategoryCode.OA1
                    || user.OAOZCategory.Code === CategoryCode.OA2)) {
                  roleCategory.category = user.OAOZCategory;
                }
              }
              else if (userRole.RoleCode === RoleCode.OZ) {
                if (user.OAOZCategory
                  && (user.OAOZCategory.Code === CategoryCode.OZ3
                    || user.OAOZCategory.Code === CategoryCode.OZ4)) {
                  roleCategory.category = user.OAOZCategory;
                }
              }
              user.selectedRolesCategories.push(roleCategory);
            });
          }

          user.selectedActive = this.userEtats.filter((etat: any) => { return etat.Active === user.Active })[0];

          // if (user.selectedRole) {
          //   this.app.sharedTools.getLabelSwitchLang(user.selectedRole);
          //   if (this.fastFiltersUsers.indexOf('selectedRole.label') == -1) this.fastFiltersUsers.push("selectedRole.label");
          // }

          if (user.selectedActive) {
            this.app.sharedTools.getLabelSwitchLang(user.selectedActive);
            if (this.fastFiltersUsers.indexOf('selectedActive.label') == -1) this.fastFiltersUsers.push("selectedActive.label");
          }

          //map ODTypeCategories into label field
          if(user.ODTypeCategories && user.ODTypeCategories.length > 0){
            user.ODTypeCategoriesLabel = user.ODTypeCategories.map((c: any) => { return c.Code;}).join(' /');
          }
        });



        if (thenFilterFast) {
          this.filterFast();

          if (hasAnyNonNullProperty(this.search)) {
            console.log("hasAnyNonNullProperty", hasAnyNonNullProperty(this.search))
            this.initAdvancedSearch();
            this.showAdvancedSearch = true;
          }
        }
        console.log("after users", this.users);
      }, 100);

    } catch (error) {
      console.error(error);
    }
  }

  onRowSelect(event: any) {
    console.log("event.data==>", event.data);
    console.log("selectedUsers==>", this.selectedUsers);

  }

  getActionsMenuByUser(user: any): any {
    return this.items.filter((item: any) => item.ActionID);

  }

  toggleMenu(event: any, UserID: number, items: any) {
    this.menuAction.toggle(event);

    console.log("User ID:", UserID);
    console.log("items:", items);

    items.forEach((itemMenu: any) => {
      itemMenu.userIdRow = UserID;
      itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

      if (itemMenu.ActionID == 1) {
        itemMenu.command = (event: any) => {
          this.app.sharedTools.redirectTo('/detail-vm-user/' + event.item.userIdRow.toString());
        }
      }

      if (itemMenu.ActionID == 2) {
        itemMenu.command = (event: any) => {
          window.open('/vm-prog-demandes/', '_blank');
        }
      }
    });
  }

  async onchangeEtat(_user: any) {
    console.log("onchangeEtat user ==> ", _user);
    //if (!_user.selectedActive) return;

    let objStatus: any = {
      LockaccessApp: _user.LockaccessApp,
      UserID: _user.UserID
    }

    console.log("objStatus ==> ", objStatus);

    try {
      const response = await this.app.apiService.adminUsersChangelockaccessapp(objStatus);
      console.log("apiService.adminUsersChangelockaccessapp ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
        return;
      }

      // this.userEtats();
      this.searchUsers(this.search);

    } catch (error) {
      console.error(error);
      return;

    }
  }

  ngOnDestroy(): void {
    this.unsubscribeActiveSubscriptions();
  }

  onSelectedRoleChange(value: any): void {
    this.handleSelectedRoleChange(this.search.selectedRoles);
  }


  //#region private methods
  private unsubscribeActiveSubscriptions(): void {
    if (this.usersExportSubscription) {
      this.usersExportSubscription.unsubscribe();
    }
    if (this.namescompleteSubscription) {
      this.namescompleteSubscription.unsubscribe();
    }
  }

  private refreshNamesAutocomplete(keyword: string): void {
    this.namescompleteSubscription = this.app.apiService.adminUsersNamesAutocomplete(keyword).subscribe({
      next: (data: any) => {
        if (data && data != '' && Array.isArray(data)) {
            this.LastNameSuggestions = data;
        }
        else {
            this.LastNameSuggestions = []
        }
    },
    error: (error: any) => {
        console.log(error);
    }
    });
  }
  private UsersExport(): void {
    this.usersExportSubscription = this.app.apiService.exportEP(this.searchExport,environment.visitmanagerUsersCtrl.export).subscribe({
      next: (data: any) => {
        console.log('UsersExport', data);
        if (data) {
          let filesUrl: string = data;

          window.open(filesUrl);
        }

      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  private initAdvancedSearch(): void {
    //let findSelectedRole = this.roles.find((c: any) => c.RoleID === this.search.selectedRoleID?.RoleID);

    let findSelectedRole = this.search.selectedRole
      ? this.roles.find(r => r.RoleID === this.search.selectedRole.RoleID)
      : null;

    let findSelectedOAOZCategory = this.search.selectedOAOZCategory
      ? this.allOAOZCategories.find(c => c.CategoryID === this.search.selectedOAOZCategory.CategoryID)
      : null;

    let findSelectedEtat = this.userEtats.find((c: any) => c.EtatID === this.search.selectedEtat?.EtatID);

    //this.search.selectedRoleID = findSelectedRole;
    this.search.selectedRole = findSelectedRole;
    this.search.selectedEtat = findSelectedEtat;
    this.search.selectedOAOZCategory = findSelectedOAOZCategory;

    this.handleSelectedRoleChange(this.search.selectedRole);
  }

  // private handleSelectedRoleChange(role: any): void {
  //   if (role) {
  //     if (role.LabelEN === this._OARoleNameEN) {
  //       this.oAOZCategories = this.allOAOZCategories.filter((cat: any) => {
  //         return cat.Code === CategoryCode.OA1 || cat.Code === CategoryCode.OA2;
  //       });

  //       this.showTypeOAOZFormControl = true;

  //       return;
  //     }
  //     else if (role.LabelEN === this._OZRoleNameEN) {
  //       this.oAOZCategories = this.allOAOZCategories.filter((cat: any) => {
  //         return cat.Code === CategoryCode.OZ3 || cat.Code === CategoryCode.OZ4;
  //       });

  //       this.showTypeOAOZFormControl = true;

  //       return;
  //     }
  //   }

  //   this.search.selectedOAOZCategory = null;
  //   this.showTypeOAOZFormControl = false;
  //   this.oAOZCategories = [];
  // }


  private handleSelectedRoleChange(roles: any[]): void {
    this.oAOZCategories = [];
    let haveOARole: boolean = false;
    let haveOZRole: boolean = false;

    let findOARole = roles?.find((role: any) => role.RoleCode === RoleCode.OA);
    if (findOARole) {
      haveOARole = true;
    }

    let findOZRole = roles?.find((role: any) => role.RoleCode === RoleCode.OZ);
    if (findOZRole) {
      haveOZRole = true;
    }


    if (haveOARole) {
      this.oAOZCategories = [...this.oAOZCategories, ...this.allOAOZCategories.filter((cat: any) => {
        return cat.Code === CategoryCode.OA1 || cat.Code === CategoryCode.OA2;
      })];
      this.showTypeOAOZFormControl = true;
    }

    if (haveOZRole) {
      this.oAOZCategories = [...this.oAOZCategories, ...this.allOAOZCategories.filter((cat: any) => {
        return cat.Code === CategoryCode.OZ3 || cat.Code === CategoryCode.OZ4;
      })];
      this.showTypeOAOZFormControl = true;
    }

    if (!haveOARole && !haveOZRole) {

      this.showTypeOAOZFormControl = false;
      this.oAOZCategories = [];
    }
  }
  


  exportCSV(){
    this.helperService.exportCSV(this.filteredUsers, this.mapColumn(),'vm_users_export_' + new Date().toLocaleDateString(),true);
}

mapColumn(){
  console.log(this.selectedColumns);
  console.log(this.filteredUsers);
  return this.filteredUsers.map((row: any) => {
      const filteredColumns: any = {};
      this.selectedColumns.forEach(column => {
          switch (column.field) {
              case "RoleName": {
                let roleText: string = "";
                let isFirstRow: Boolean = true;
                if(row.selectedRolesCategories
                  && Array.isArray(row.selectedRolesCategories)
                  && row.selectedRolesCategories.length > 0){
                    row.selectedRolesCategories.forEach((r: any) =>{
                      if(!isFirstRow){ roleText += ' ';}
                      roleText += `${this.app.sharedTools.getLabelSwitchLang(r.role)}`;
                      if(r.category){
                        roleText += ` - ${this.app.sharedTools.getLabelSwitchLang(r.category)}`
                      }

                      isFirstRow = false;
                    });
                  }
                
                filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = roleText ?? '';
              }
                break;
              case "Grade": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Grade ?? '';
                    break;
              case "LastName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.FullName ?? '';
                  break;
              case "Email": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Email ?? '';
                  break;
              case "Mobile":  {
                  let mobileText: string = "";
                  if(row.Mobile){
                      mobileText += `${row.MobilePrefix} ${row.Mobile}`
                  }
                  if(row.Phone){
                      mobileText += ` ${row.PhonePrefix} ${row.Phone}`
                  }
                  filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = mobileText;
              }
                  break;
              case "TYPE_DO": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.ODTypeCategoriesLabel ?? '';
                    break;
              case "LockaccessApp": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(this.userEtats.find((e: any) => e.LockaccessApp == row.LockaccessApp)) ?? ''
                    break;
              case "DateCreated": {
                  let dateText: string = "";
                  if(row.DateCreated){
                      dateText += `${this.app.translate.instant('racine.generalites.dateCreation')} ${this.datePipe.transform(row.DateCreated, 'dd/MM/yyyy HH:mm:ss')}`
                  }
                  if(row.DateModified){
                      dateText += ` ${this.app.translate.instant('racine.generalites.dateModification')} ${this.datePipe.transform(row.DateModified, 'dd/MM/yyyy HH:mm:ss')}`
                  }
                  if(row.DateLastLogin){
                      dateText += ` ${this.app.translate.instant('racine.generalites.lastconnection')} ${this.datePipe.transform(row.DateLastLogin, 'dd/MM/yyyy HH:mm:ss')}`
                  }
                  filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = dateText;
              }
                  break;
              
              default:
                  break;
          }
      });
      return filteredColumns;
  });
}

onFilter(event: TableFilterEvent){
  console.log('onFilter ', event);
  this.filteredUsers = event.filteredValue ?? [];
}

resetFileInput() {
  this.showModal = false
  if (this.fileInput && this.fileInput.nativeElement) {
    this.fileInput.nativeElement.value = ""
  }
}

importCsv(event: any) {
  let selectedFileExtension = event.target.files[0]?.name?.split('.')[1]
  if (selectedFileExtension == "xlsx" || selectedFileExtension == "xls" || selectedFileExtension == "csv") {
    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmImportDGAUser'),
      'AUTRE', 'confirm'
      , () => {
        const file: File = event.target.files[0]
        console.log("sending info => ")
        this.app.apiService.uploadOzCsv(file,this.app.translate.currentLang).subscribe(
          data => {
            if (data && data?.Result) {
              setTimeout(() => {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
              }, 200);
              return;
            }
            else {
              this.msg = data?.Data?.InnerHTML_EN ?? ""
              if (this.app.translate.currentLang == Languages.French) {
                 this.msg = data?.Data?.InnerHTML_FR ?? ""
              }else if(this.app.translate.currentLang == Languages.Spanish){
                this.msg = data?.Data?.InnerHTML_ES ?? ""
              }
              console.log(this.msg)
              this.showModal=true
            }
          }
        )
      }
      , () => {
        this.resetFileInput()
      });

  } else {
    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorInvalidFileFormat'), 'KO', "alert", () => { }, () => { });
    return;
  }

}
}
