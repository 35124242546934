import { PriviGroupChoices } from "../models/privigroup-choices";

export class PriviGroupHelper {
    public static calculateChoices(totalRequestNumber: number): PriviGroupChoices {
        let choices: PriviGroupChoices = {
            Priority1: 0,
            Priority2: 0,
            Priority3: 0
        };
        // Calculate P1, P2, and P3 based on the given percentages
        choices.Priority1 = Math.round(totalRequestNumber * 0.20);  // 20% of Total
        choices.Priority2 = Math.round(totalRequestNumber * 0.30);  // 30% of Total
        choices.Priority3 = totalRequestNumber 
        - choices.Priority1 
        - choices.Priority2;  // Remaining as P3 to ensure the sum equals Total

        // Return the results as an object
        return choices;
    }
}