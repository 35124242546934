import { Component, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { cloneDeep } from 'lodash';
import { Menu } from 'primeng/menu';
import { Subscription } from 'rxjs';
import { GroupSearchHelperService } from '../services/group-search-helper.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-gestion-group',
    templateUrl: './gestion-group.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./gestion-group.component.less']
})
export class GestionGroupComponent {
    private groupsExportSubscription?: Subscription;
    @ViewChild('dtGroups') dtGroups!: Table;
    @ViewChild('menuAction') menuAction!: Menu;

    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }

    set selectedColumns(val: any[]) {
        // ==> restore original order
        this._selectedColumns = this.colsGroups.filter((col: any) => val.includes(col));
    }

    _selectedColumns: any = [];

    groups: any[] = [];
    colsGroups: any[] = [];
    selectedGroups: any[] = [];
    optionsDrpdwn: any[] = [];

    fastSearchGroupKeyword: string = '';
    showAdvancedSearch: boolean = false;
    showTable: boolean = true;
    fastFiltersGroups: any[] = [];
    items: any = [];
    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private groupSearchHelperService: GroupSearchHelperService,
        private datePipe: DatePipe,
    ) { }

    ngOnInit() {
        //get privious advanced search value before routing to other routes
        //this.search = cloneDeep(this.groupSearchHelperService.advancedSearch);
        this.fastSearchGroupKeyword = this.groupSearchHelperService.fastSearchKeyword;
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG", resG);
                this.optionsDrpdwn = cloneDeep(resG.selectGralOptions);
                console.log("this.optionsDrpdwn", this.optionsDrpdwn);
                this.items = resG.actionsGroupsRow;

                this.items.forEach((itemMenu: any) => {
                    itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
                });

                this.colsGroups = resG.colsGroups;

                this.colsGroups.forEach((col: any) => {
                    this.fastFiltersGroups.push(col.field);
                })

                this._selectedColumns = this.colsGroups;
                this.privigroupgetall(true);
            });
    }

    onChangeSelect(group: any) {
        console.log("onChangeSelect group", group);
        this.privigroupupdate(group);
    }

    onChangeBloqueStand(group: any) {
        console.log("onChangeBloqueStand group", group);
        this.changeBloqueStand(group);
    }

    onChangeIsEnabled(group: any) {
        console.log("onChangeIsEnabled group", group);
        this.changeIsEnabled(group);
    }

    onChangePrintPlanning(group: any) {
        console.log("onChangePrintPlanning group", group);
        this.changePrintPlanning(group);
    }

    onChangeExportPlanning(group: any) {
        console.log("onChangeExportPlanning group", group);
        this.changeExportPlanning(group);
    }

    async privigroupupdate(obj: any) {
        console.log("privigroupupdate obj", obj);
        
        const response = await this.app.apiService.privigroupupdate(obj);
        console.log("privigroupupdate==> ", response);

        if (response && response.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
            return;
        }
        this.privigroupgetall(true);
    }

    async privigroupgetall(thenFilterFast: boolean = false) {
        try {
            const response = await this.app.apiService.privigroupgetall();
            console.log("privigroupgetall==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.groups = cloneDeep(response);
            this.groups.forEach((g: any) => 
            {
                if(g.ODTypeCategories && g.ODTypeCategories.length > 0){
                    g.ODTypeCategoriesLabel = g.ODTypeCategories.map((c: any) => { return c.Code;}).join(' /');
                } 
            });
            console.log("groups", this.groups);

            setTimeout(() => {
                this.groups.forEach((grp: any) => {
                    grp.PriviGroupOpeningDateFormat! = this.datePipe.transform(grp.PriviGroupOpeningDate, 'dd/MM/yyyy HH:mm:ss');
                    grp.PriviGroupClosingDateFormat! = this.datePipe.transform(grp.PriviGroupClosingDate, 'dd/MM/yyyy HH:mm:ss');

                    if (this.fastFiltersGroups.indexOf('PriviGroupOpeningDateFormat') == -1) this.fastFiltersGroups.push("PriviGroupOpeningDateFormat");
                    if (this.fastFiltersGroups.indexOf('PriviGroupClosingDateFormat') == -1) this.fastFiltersGroups.push("PriviGroupClosingDateFormat");
                });

                if (thenFilterFast) this.filterFast();
                console.log("after groups", this.groups);

            }, 100);
        } catch (error) {
            console.error(error);
        }
    }

    filterFast() {
        this.groupSearchHelperService.fastSearchKeyword = this.fastSearchGroupKeyword;
        console.log("_value", this.fastSearchGroupKeyword);

        this.dtGroups.filterGlobal(this.fastSearchGroupKeyword, 'contains');
    }
    clearFastSearch(): void {
        this.fastSearchGroupKeyword = '';
        this.filterFast();
    }
    toggleAdvancedSearch() {
        this.showAdvancedSearch = !this.showAdvancedSearch;
    }

    clearSearch() {
        console.log("clearSearch ==>");
        this.privigroupgetall();
        this.clearFastSearch();
    }

    getActionsMenuByGroup(group: any): any {
        return this.items.filter((item: any) => item.ActionID);
    }

    onRowSelect(event: any) {
        console.log("event.data==>", event.data);
        console.log("selectedGroups==>", this.selectedGroups);
    }

    toggleMenu(event: any, PriviGroupID: number, items: any) {
        this.menuAction.toggle(event);

        console.log("PriviGroupID:", PriviGroupID);
        console.log("items:", items);

        items.forEach((itemMenu: any) => {
            itemMenu.groupIdRow = PriviGroupID;
            itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

            if (itemMenu.ActionID == 1) {
                itemMenu.command = (event: any) => {
                    this.app.sharedTools.redirectTo('/detail-group/' + event.item.groupIdRow.toString());
                }
            }
        });
    }
    

    private async changeBloqueStand(PriviGroup: any) {
        let objRequest: any = {
            PriviGroupBlockStand: PriviGroup.PriviGroupBlockStand,
            PriviGroupID: PriviGroup.PriviGroupID
        }

        console.log("objRequest ==> ", objRequest);

        try {
            const response = await this.app.apiService.privigroupblockstand(objRequest);
            console.log("apiService.privigroupblockstand ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                , 'KO'
                , "alert"
                , () => { }
                , () => { });
                return;
            }
            
            this.privigroupgetall();

        } catch (error) {
            console.error(error);
            return;

        }
    }
    

    private async changeIsEnabled(PriviGroup: any) {
        let objRequest: any = {
            PriviGroupIsenabled: PriviGroup.PriviGroupIsenabled,
            PriviGroupID: PriviGroup.PriviGroupID
        }

        console.log("objRequest ==> ", objRequest);

        try {
            const response = await this.app.apiService.privigroupchangeisenabled(objRequest);
            console.log("apiService.privigroupblockstand ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                , 'KO'
                , "alert"
                , () => { }
                , () => { });
                return;
            }
            
            this.privigroupgetall();

        } catch (error) {
            console.error(error);
            return;

        }
    }
    

    private async changePrintPlanning(PriviGroup: any) {
        let objRequest: any = {
            PriviGroupImprPlanDo: PriviGroup.PriviGroupImprPlanDo,
            PriviGroupID: PriviGroup.PriviGroupID
        }

        console.log("objRequest ==> ", objRequest);

        try {
            const response = await this.app.apiService.privigroupchangeprintplanning(objRequest);
            console.log("apiService.privigroupchangeprintplanning ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                , 'KO'
                , "alert"
                , () => { }
                , () => { });
                return;
            }
            
            this.privigroupgetall();

        } catch (error) {
            console.error(error);
            return;

        }
    }
    

    private async changeExportPlanning(PriviGroup: any) {
        let objRequest: any = {
            PriviGroupExpPlanDo: PriviGroup.PriviGroupExpPlanDo,
            PriviGroupID: PriviGroup.PriviGroupID
        }

        console.log("objRequest ==> ", objRequest);

        try {
            const response = await this.app.apiService.privigroupchangeexportplanning(objRequest);
            console.log("apiService.privigroupchangeexportplanning ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                , 'KO'
                , "alert"
                , () => { }
                , () => { });
                return;
            }
            
            this.privigroupgetall();

        } catch (error) {
            console.error(error);
            return;

        }
    }
}
