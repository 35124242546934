import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { StatusCode } from '../enums/status-code';
import { cloneDeep } from 'lodash';
import { AppComponent } from '../app.component';
import { MediasTypes } from '../enums/media-type';

@Component({
  selector: 'app-products-list-dataview-program',
  templateUrl: './products-list-dataview-program.component.html',
  styleUrls: ['./products-list-dataview-program.component.less']
})
export class ProductsListDataviewProgramComponent {

  @Input() products: any[] = [];

  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();

  translatedNbProdMessage: any = "";
  state: any;
  product: any;
  status: any[] = [];
  toBeValidateStatus: any;
  mediaTypes = MediasTypes;
  prod: any

  constructor(
      public app: AppComponent,
  ) {

  }

  ngOnInit() {
      this.app.sharedTools.getJSON('general_data').subscribe(
          resG => {
              this.getStatus();
              console.log("products", this.products)
              const productsLength = this.products ? this.products.length : 0; // Assuming products is your array
              this.translatedNbProdMessage = this.app.translate.instant('fo.productListCommun.nbProducts').replace('{nb}', productsLength.toString());
          });
  }

  getStatus() {
      this.app.apiService.getStatus().subscribe((data: any) => {
          this.status = cloneDeep(data);
          this.toBeValidateStatus = this.status.find((s: any) => s.Code === StatusCode.TOVALIDATE_FICHE);
      });
  }

  openFicheProgram(solutionId : number) {
    this.app.apiService.generateHelpedFiche(solutionId).subscribe(
      data => {
        if(data && data.IsSuccessful && data.Url){
          window.open(data.Url,"_blank")
        }
        if(!data.IsSuccessful){
          setTimeout(() => {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Error)
                , 'KO'
                , "alert"
                , () => { /*this.app.sharedTools.redirectTo('/');*/ }
                , () => { });
        }, 200);
        return;
        }
      }
    )
  }

  getManySwitchStatus(status: string) {
      let color: any = "";
      switch (status) {
          case StatusCode.TOVALIDATE_FICHE:
              color = 'text-orange-400';
              break;
          case StatusCode.DRAFT_FICHE:
              color = 'color-default';
              break;
          case StatusCode.PUBLISHED_FICHE_PUBLISH:
              color = 'color-lime-green';
              break;
          case StatusCode.UNDER_REVIEW_FICHE:
              color = 'color-gray';
              break;
          case StatusCode.VALIDATED_FICHE:
              //color = 'text-teal';
              color = 'text-green-400';
              break;
          case StatusCode.CANCELED_FICHE:
              color = 'color-red';
              break;
          case StatusCode.UNPUBLISHED_FICHE_PUBLISH:
              color = 'text-purple';
              break;
          case StatusCode.SCHEDULED_FICHE_PUBLISH:
              color = 'fill-light-blue';
              break;
      }
      return color;
  }


  onSetStatus(SolutionID: number, CGVaccepted: boolean) {
      this.setStatusProg(SolutionID, CGVaccepted);
  }

  async setStatusProg(SolutionID: number, CGVaccepted: boolean) {
    console.log("this is solutionID ", SolutionID)
    console.log("this is solutionID CGVaccepted", CGVaccepted)
      let request: any = {
          ID: SolutionID,
          Status: this.toBeValidateStatus
      }
      if (CGVaccepted) {
        request = {
            ...request,
            Submitted : true
        }
          this.state = await this.app.apiService.setStatusProg(request);
          this.refresh.emit();
          console.log("set Status Prog", this.state);
      }
      if (!CGVaccepted) {
          let queryparams: any = {
            ID: SolutionID
          }
          
          this.app.sharedTools.redirectToWithParameters('product-helped-recapitulatif/edit', queryparams);
      }


  }

  onDeleteProduct(Solution: any) {

      this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteInnov'), 'AUTRE', 'confirm', () => {
          setTimeout(() => { console.log("this is solution", Solution);this.deleteProductProg(Solution); }, 1000);
      }, () => { });
  }

  async deleteProductProg(Solution: any) {
      console.log("DELETE product program ===>", Solution);
      try {
        let request = {
            ID : Solution.ConfDetailID,
            StatusID : Solution.ConfStatus.StatusID
        }
          //  let request= {ID:Solution.SolID}
          const response = await this.app.apiService.deleteSolutionProg(request);
          console.log('Response API : DELETE Solution ===>', response);

          if (response && response.Result) {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
              return;
          }

          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successInnovDelete'), 'OK', "alert"
              , () => { this.refresh.emit(); }
              , () => { this.refresh.emit(); });


      } catch (error) {
          console.error(error);
      }
  }

  async updateProduct(_solID: number) {
      console.log("update product program !!!! ==> ", _solID);
      let queryparams: any = {
          ID: _solID
      }
      
      this.app.sharedTools.redirectToWithParameters('product-program-generalities/edit', queryparams);
  }

  async getProduct(_solID: number) {
      console.log("getProduct Program ==> ", _solID);
      let queryparams: any = {
          ID: _solID
      }

      this.app.sharedTools.redirectToWithParameters('product-program-recapitulatif/consult', queryparams);
  }

}
