import { Component, OnInit } from '@angular/core';
import { Chips } from 'primeng/chips';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-planning-htmloptions',
  templateUrl: './planning-htmloptions.component.html',
  styleUrls: ['./planning-htmloptions.component.less']
})
export class PlanningHTMLOptionsComponent implements OnInit {

  public emails: string[] = [];
  public isMailChecked: boolean = false;
  public isIncludeNoteChecked: boolean = false;
  public includeNoteSection: boolean = true;
  public isEmailRequired: boolean = false;
  public submitted: boolean = false;
  public sendPlanningsToAllExhibitorsSection: boolean = false;
  public isSendPlanningsToAllExhibitorsChecked: boolean = false;
  public showEmailInput: boolean = false;


  constructor(private ref: DynamicDialogRef, private configDialog: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.isEmailRequired = this.configDialog.data?.isEmailRequired;
    this.sendPlanningsToAllExhibitorsSection = this.configDialog.data?.sendPlanningsToAllExhibitorsSection;
    this.showEmailInput = this.configDialog.data?.showEmailInput;
    // if(this.isEmailRequired){
    //   this.isMailChecked = true;
    // }
    this.includeNoteSection = this.configDialog.data.includeNoteSection;
    this.emails = this.configDialog.data?.emailList
    this.isMailChecked = this.isEmailRequired || (this.emails?.length > 0 && this.showEmailInput)
  }

  onValidate() {
    this.submitted = true;
    if (this.isEmailRequired && !(this.emails && this.emails.length > 0)) {

    }
    else {
      let result: any = {};
      if(this.isMailChecked){
        result['emails'] = this.emails;
      }
      result['includeNote'] = this.isIncludeNoteChecked
      result['sendPlanningsToAllExhibitors'] = this.isSendPlanningsToAllExhibitorsChecked
      // result['preview'] = preview;
      // result['planningDoId'] = this.configDialog.data.planningDoId;

      this.ref.close(result);
    }
  }
  onChipsFocusout(chipRef: Chips) {
    let nonTaggedValue: any = chipRef.inputViewChild.nativeElement.value;
    if (nonTaggedValue && nonTaggedValue !== '') {
      this.emails.push(nonTaggedValue)
    }
    chipRef.inputViewChild.nativeElement.value = null;
  }

}
