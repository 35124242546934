export const FOExternalPagesRoutes = {
    confList: 'welcome-conf',
    confDetail: 'conf-details',
    speakerList: 'welcome-conf-speaker',
    speakerDetail: 'conf-speaker',
    confMap: 'map',
    exhiList: 'welcome-exhibitor',
    exhiDetail: 'welcome-exhibitor-details',
    solList: 'welcome-sol',
    solDetail: 'sol-details',
    exhiMap: 'mapExhi',
};

export const FOExternalPagesQueryParamsNames = {
    confDetail: {
        conferenceID: 'eventID'
    },
    speakerDetail: {
        speakerID: 'speakerID'
    },
    exhiDetail: {
        exhibitorID: 'exhibitorID'
    },
    solDetail: {
        solutionID: 'solutionID'
    }
};

export const FOExternalListPagesRoutes = [
    FOExternalPagesRoutes.confList,
    FOExternalPagesRoutes.speakerList,
    FOExternalPagesRoutes.exhiList,
    FOExternalPagesRoutes.solList
];

export const FOExternalDetailPagesRoutes = [
    FOExternalPagesRoutes.confDetail,
    FOExternalPagesRoutes.speakerDetail,
    FOExternalPagesRoutes.exhiDetail,
    FOExternalPagesRoutes.solDetail
];

export const FOExternalMapPagesRoutes = [
    FOExternalPagesRoutes.confMap,
    FOExternalPagesRoutes.exhiMap,
];