import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { FormCode } from 'src/app/enums/form-code';
import { AwardsRoutes, AwardsRoutesQueryParamsNames } from '../awards.routes';
import { StatusCode } from 'src/app/enums/status-code';

@Component({
  selector: 'app-awards-welcome-page',
  templateUrl: './awards-welcome-page.component.html',
  styleUrls: ['./awards-welcome-page.component.less']
})
export class AwardsWelcomePageComponent {

  frmData: any
  folderGuid: any
  cpRecapCGV: any
  awardFormData: any
  awardFrmId: any
  awardId: any
  submittedForm: boolean = false


  constructor(public app: AppComponent, private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit() {
    this.app.sharedTools.getJSON('general_data').subscribe(
      async resG => {
        this.cpRecapCGV = resG.awardRecapCGV;
      })
    await this.getAwardFormEditionInfo()
    this.activatedRoute.queryParams.subscribe(async data => {
      if (data && data[AwardsRoutesQueryParamsNames.folderGuid]) {
        this.folderGuid = data[AwardsRoutesQueryParamsNames.folderGuid]
        await this.getAwardFormInfo()
        await this.checkAwardSolutions()
      }
    })
  }

  async getAwardFormInfo() {
    let response = await this.app.apiService.getAwardFormWelcom(this.folderGuid)
    if (!response.Result) {
      sessionStorage.setItem("award-form", JSON.stringify(response))
    }
    this.awardFormData = response
    console.log(response)
    this.awardFrmId = response.AwardFormID


    // this.apiService.getAwardSolutionById(solutionId)
  }

  private async checkAwardSolutions(): Promise<void> {
    let response = await this.app.apiService.checkAwardSolutions(this.awardFrmId);

    if (!response || response.Result) {
      console.log(response);
      return;
    }

    console.log(response)

    if (response.NbMaxProduct > 1) {
      // this.nextRoute = AwardsRoutes.productList;
    }
    else {
      if (response.FirstSolutionId) {
        this.awardId = response.FirstSolutionId;
        let awardFormData = await this.app.apiService.getAwardSolutionById(this.awardId)
        console.log(awardFormData)
        this.submittedForm = !(awardFormData.Status.Code === StatusCode.TO_BE_SUBMIT || awardFormData.Status.Code === StatusCode.DRAFT_FICHE)
        console.log(this.submittedForm)
      }
    }
  }



  openFile() {
    let filePath: string = this.app.sharedTools.getPropertySwitchLang(this.cpRecapCGV, 'FilePath');
    window.open(filePath, "_blank");
  }


  async getAwardFormEditionInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_AWARD
    let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
    if (data != undefined && data.Id != undefined) {
      this.frmData = data
    }
  }

  openAwardForm() {
    if (!this.submittedForm) {
      this.router.navigate([AwardsRoutes.companyInfo], {
        queryParams: {
          [AwardsRoutesQueryParamsNames.folderGuid]: this.folderGuid
        }
      })
    }
    else {
      this.router.navigate([AwardsRoutes.recap],{
        queryParams : {
          [AwardsRoutesQueryParamsNames.awardId] : this.awardId
        }
      })
    }
  }
}
