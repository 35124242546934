import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';
import { MessageService } from 'primeng/api';
import { Languages } from '../enums/language';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
    loginForm = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
        rememberMe: new FormControl(false),
    });
    stepFolder: any = null;
    submitted: boolean = false;

    paths = location.pathname.split('/');
    isOldLogin: boolean = false;

    constructor(
        public app: AppComponent,
        private messageService: MessageService,
    ) { }

    ngOnInit(): void {
        sessionStorage.removeItem('userLang');

        this.app.route.queryParams
            .subscribe(params => {
                console.log('[params]', params);
                if (params && params['login']) {
                    let autologin: any = {
                        Login: params['login'],
                        UserToken: params['token'],
                    }
                    console.log('[autologin]', autologin);

                    this.getDirectLogin(autologin);
                }
            }
            );



        //check create or edit role
        console.log("this.path==> ", this.paths);
        let currentPath = this.paths[1];
        var urlId: any = this.app.router.url.split('/').pop();
        if (currentPath == 'old-login') {
            this.isOldLogin = true;
        }
    }

    async getDirectLogin(_autologin: any) {
        console.log(_autologin)
        const response = await this.app.apiService.directlogin(_autologin);
        console.log('[getDirectLogin] API ==>', response);

        if (response && response.Result) {
            if (response.Result == "FOLDERCLOSED") this.app.router.navigate(['/closedfolder']);
            else this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
            }, () => { });

            return;
        }

        // ==> On crée l'objet userData dans le sessionStorage pour le partage avec les data de la réponse
        sessionStorage.setItem('userData', JSON.stringify(response));
        if (response.Contact != null && response.Contact != undefined) {
            if (response.Contact.LanguageCode != null) {
                sessionStorage.setItem("userLang", JSON.stringify(response.Contact.LanguageCode))
                this.app.sharedTools.changeUsrLangInInit()
            }
        }
        console.log("response", response);

        this.getFolderForStatus(response.FolderID);
    }

    async getFolderForStatus(_FolderID: any) {
        const response = await this.app.apiService.getFolder(_FolderID);
        console.log("[getFolder] API ==> ", response);

        if (response && response.Result) {
            this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), ' KO', "alert", () => { }, () => { })
            return;
        }

        let folder: any = cloneDeep(response);
        if (folder && folder.ExtraFieldValues && folder.ExtraFieldValues.length > 0) {
            console.log('folder ==> ', folder);
            let EF_Step: any = folder.ExtraFieldValues.filter((ef: any) => { return ef.ExtraFieldID == 1 })[0];
            if (EF_Step) this.stepFolder = EF_Step.ExtraFieldValueEN;
        }
        console.log("this.stepFolder", this.stepFolder)
        this.redirect(folder.StatusID, parseInt(this.stepFolder));
    }

    redirect(_StatusID: any, _stepID: any) {
        let path: string = "";
        if (_StatusID == 3 && _stepID && _stepID > 0) {
            path = '/infos-exposant';
        } else if (_StatusID > 3) {
            path = '/thanks';
        } else {
            path = '/welcome';
        }

        setTimeout(() => {
            this.getLanguage(JSON.parse(sessionStorage.getItem('userData')!), path);
        }, 500);

    }

    getLanguage(_user: any, _pathToRedirect: any) {
        if (_user.Contact && _user.Contact.LanguageID) {
            this.app.apiService.getLanguages().subscribe(data => {
                console.log('[getLanguages] API ==>', data);
                let codeIsoLang: any = this.app.sharedTools.getParamByID(_user.Contact.LanguageID, 'LanguageID', data, 'CodeISO2', true);
                console.log('[codeIsoLang] ==>', codeIsoLang);
                if (codeIsoLang?.toLowerCase() == Languages.French?.toLowerCase()
                    || codeIsoLang?.toLowerCase() == Languages.Spanish?.toLowerCase()
                    || codeIsoLang?.toLowerCase() == Languages.English?.toLowerCase()) sessionStorage.setItem('userLang', JSON.stringify(codeIsoLang)); //this.app.translate.setDefaultLang(codeIsoLang);
                else sessionStorage.setItem('userLang', JSON.stringify(Languages.Default));

                this.app.router.navigate([_pathToRedirect]);
            });
        }
    }

    onSubmit() {
        this.submitted = true;
        // Logic to handle form submission
        if (this.loginForm.invalid) {
            //this.clrForm.markAsTouched();
            this.messageService.add({ severity: 'error', summary: this.app.translate.instant('racine.errorsOther.errorForm'), detail: this.app.translate.instant('racine.errorsOther.errorFormDetail') });
            console.log("invalid form")
        } else {
            // Do submit logic
            console.log(this.loginForm);
            if (this.isOldLogin) {
                this.setConnexion(this.loginForm.value.username, this.loginForm.value.password);
            }
            else {
                this.setConnexionAndRedirect(this.loginForm.value.username, this.loginForm.value.password);
            }
        }
    }

    async setConnexion(_login: any, _mdp: any) {
        try {
            const response = await this.app.apiService.login(_login, _mdp);
            console.log("login ==> ", response);

            const userLogged: any = cloneDeep(response);

            if (userLogged.Result) {
                //let msg = this.translate.instant('racine.errorsApi.errorsMsgApi_'+ userLogged.Result); //+ userLogged.Result
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + userLogged.Result), 'KO', "alert", () => {
                    this.app.sharedTools.redirectTo('/');
                }, () => { })
                return;
            }

            // ==> On crée l'objet userData dans le sessionStorage pour le partage avec les data de la réponse
            sessionStorage.setItem('userData', JSON.stringify(response));
            console.log("userLogged", userLogged);
            let hasRoleFO: any;
            let listRoles: any = [];

            if (userLogged.RoleID) {
                listRoles = userLogged.RoleID.split(',').map((roleId: string) => {
                    return { RoleID: parseInt(roleId) };
                });
                //console.log("listRoles", listRoles);
                hasRoleFO = listRoles.some((item: any) => item.RoleID === 3 || item.RoleID === 12 || item.RoleID === 14 || item.RoleID === 17);
            }

            console.log("hasRoleFO", hasRoleFO);

            let urlToRedirect = (!hasRoleFO) ? '/' : '/welcome';
            console.log("urlToRedirect", urlToRedirect);

            if (userLogged.Contact && userLogged.Contact.LanguageID) {
                this.app.apiService.getLanguages().subscribe(data => {
                    console.log('[getLanguages] API ==>', data);
                    let codeIsoLang: any = this.app.sharedTools.getParamByID(userLogged.Contact.LanguageID, 'LanguageID', data, 'CodeISO2', true);
                    console.log('[codeIsoLang] ==>', codeIsoLang);
                    if (codeIsoLang?.toLowerCase() == Languages.French?.toLowerCase()
                        || codeIsoLang?.toLowerCase() == Languages.Spanish?.toLowerCase()
                        || codeIsoLang?.toLowerCase() == Languages.English?.toLowerCase()) sessionStorage.setItem('userLang', JSON.stringify(codeIsoLang)); //this.app.translate.setDefaultLang(codeIsoLang);
                    else sessionStorage.setItem('userLang', JSON.stringify(Languages.Default));
                });
            }
            this.app.router.navigate([urlToRedirect]);
        }
        catch (error) {
            console.error(error);
        }
    }



    async setConnexionAndRedirect(_login: any, _mdp: any) {
        try {
            const response = await this.app.apiService.loginRedirect(_login, _mdp);

            console.log("login --------------------------- ==> ", response);

            const userLogged: any = cloneDeep(response);

            if (userLogged.Result) {
                this.setConnexion(_login, _mdp);
            }
            let url: string = cloneDeep(userLogged);
            let index: number = url.indexOf('ssologin');
            let redirectTo: string = url.slice(index);
            this.app.sharedTools.goTo(redirectTo);
        }
        catch (error) {
            console.error(error);
            this.setConnexion(_login, _mdp);
        }
    }
}
