import { Component, Input, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { TreeNode } from 'primeng/api';
import { AppComponent } from '../app.component';
import { MediasTypes } from '../enums/media-type';
import { CategoryType } from '../enums/category-type';

@Component({
  selector: 'app-content-recap-helped',
  templateUrl: './content-recap-helped.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./content-recap-helped.component.less']
})
export class ContentRecapHelpedComponent  {
  paths = location.pathname.split('/');
  currentPath: any = null;
  user: any = null;
  @Input() product: any;// = new ProductInnov();
  document: any;
  isReadOnly: boolean = true;
  timelineSteps: any = [];
  formHelpedID!: number;
  steps: any;
  isEditMode: boolean = false;
  @Input() productId: any;

  categories: any = [];
  tree: any = {};
  orderedCategories!: TreeNode[];
  otherCategories: any[] = [];

  mediaTypes = MediasTypes;

  // #region Video verification
  isVideo: boolean = false;
  isYoutubeVideo: boolean = false;
  videoUrl: any;
  @Input() showEditButtons: boolean = false;
  // #endRegion

  constructor(
      public app: AppComponent,
  ) {
      //this.getFormHelpedData();
  }

  async ngOnInit() {
      this.user = JSON.parse(sessionStorage.getItem('userData')!);
      if (!this.user) {
          this.app.router.navigate(['/login']);
          return;
      }

      this.app.route.queryParams
          .subscribe(params => {
              if (params && params['productid']) {
                  this.productId = params['productid'];
              }
          });

      //get otherCategories from general_data json
      //get from general_data json
      this.app.sharedTools.getJSON('general_data').subscribe(
          resG => {
              this.otherCategories = resG.otherCategories;
              this.timelineSteps = resG.timelineSteps;

              // ==> 
              this.currentPath = this.paths[1];
          });

          await this.getCategories();

          if (this.productId) {
              this.isEditMode = true;
              this.getProduct(this.productId);
          }
          else {
              this.isEditMode = false;

          }
  }

  async getCategories() {
      try {
          const response = await this.app.apiService.getCategories();

          let categoriesIni = cloneDeep(response);

          let categories = categoriesIni.filter((cat: any) => {
              return cat.CategoryType == CategoryType.HELPActivities
          });

          this.categories = cloneDeep(categories);

      } catch (error) {
          console.error(error);
      }
  }

  private buidlOrderedCategoriesTree(categories: any[]): TreeNode[] {
      //map les categories manquantes
      let requiredCategories = [...categories];

      this.categories.forEach((c: any) => {
          let findCategoryAsParent = categories
              .find((sc: any) => sc.ParentCategoryID && sc.ParentCategoryID === c.CategoryID);

          let findCategory = categories
              .find((sc: any) => sc.CategoryID && sc.CategoryID === c.CategoryID);

          if (findCategoryAsParent && !findCategory) {
              requiredCategories.push(c);
          }
      });
      //

      requiredCategories = requiredCategories.sort((a: any, b: any) => { return b.Code > a.Code ? -1 : 1; });

      let tree: any = {};
      let orderedCategories!: TreeNode[];

      requiredCategories.forEach((cat: any) => {
          const parentId = cat.ParentCategoryID;

          if (!tree[parentId]) {
              tree[parentId] = [];
          }
          tree[parentId].push(cat);
      });

      orderedCategories = this.buildTree(tree, null);


      return orderedCategories;

  }


  async updateSolutionHelped(productObj: any): Promise<any> {
      try {
          const response = await this.app.apiService.updateHelpedSolution(productObj);
          console.log('RESPONSE update Helped===>', response);

          if (response && response.Result) {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
              }, () => {
              })
              return;
          }

          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successproduitUpdt'), 'OK', "alert", () => {
              this.app.sharedTools.redirectTo(`/product-helped-recapitulatif/${this.productId}`);
          }, () => { });

      } catch (error) {
          console.error(error);
      }
  }
  private buildTree(tree: any, parentId: any) {
      const children = tree[parentId] || [];

      return children.map((child: any) => {
          const node = {
              key: child.CategoryID,
              label: this.app.sharedTools.getLabelSwitchLang(child),
              icon: '',
              parent: parentId,
              expanded: false,
              children: this.buildTree(tree, child.CategoryID),
              data: child.Code
          };
          return node;
      });
  }

  getOrderedCategoriesTree(product: any): TreeNode[] {
      let cats = product?.Categories ?? [];
      let selectedCategories =
          this.categories.filter((c: any) => cats.indexOf(c.CategoryID) >= 0) ?? [];

      if (this.otherCategories && this.otherCategories.length > 0 && product?.OtherCategory && product?.OtherCategory != '') {
          this.otherCategories[1].LabelFR = product?.OtherCategory;
          this.otherCategories[1].LabelEN = product?.OtherCategory;
          this.otherCategories[1].LabelES = product?.OtherCategory;

          selectedCategories = [...selectedCategories, ...this.otherCategories];
      }

      let orderedCategoriesTree = this.buidlOrderedCategoriesTree(selectedCategories);
      if (this.otherCategories && this.otherCategories.length > 0) {
          this.otherCategories[1].LabelFR = "";
          this.otherCategories[1].LabelEN = "";
          this.otherCategories[1].LabelES = "";
      }

      return orderedCategoriesTree;
  }

  private getFormHelpedData(): void {
      let helpedForm: any = JSON.parse(sessionStorage.getItem('helped-form') ?? '{}');
      console.log("helpedForm", helpedForm)
      if (helpedForm) {
          this.formHelpedID = helpedForm.HelpedpFormID;
      }
  }

  async getProduct(solId: any) {
      let response = await this.app.apiService.getHelpedSolutionById(solId);
      console.log(response);
      this.product = response
      this.document = response.Documents;
  
      this.document.forEach((doc: any) => {
          let isYtb = this.isYtbVideo(doc.DocUrl);
          if (isYtb == true) {
              this.isYoutubeVideo = true;
              this.videoUrl = this.generateEmbedUrl(doc.DocUrl);
          }
      });

      this.orderedCategories = this.getOrderedCategoriesTree(this.product);
  }

  onEditGeneralites() {
      let queryparams: any = {
          productid: this.productId
      }
      this.app.sharedTools.redirectToWithParameters(`product-helped-generalites/edit`, queryparams);
  }

  onEditDescription() {
      let queryparams: any = {
          productid: this.productId
      }
      this.app.sharedTools.redirectToWithParameters(`product-helped-description/edit`, queryparams);
  }

  onEditMedias() {
      let queryparams: any = {
          productid: this.productId
      }
      this.app.sharedTools.redirectToWithParameters(`product-helped-medias/edit`, queryparams);
  }

  onEditContact() {
      let queryparams: any = {
          productid: this.productId
      }
      this.app.sharedTools.redirectToWithParameters(`product-helped-contact/edit`, queryparams);
  }

  //#region
  match?: string;
  selectedFileURL: string | null = null;
  private isYtbVideo(ytbURL: any): boolean {
      const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/watch\?v=([a-zA-Z0-9_-]+)/;
      const regex2 = /^(https?:\/\/)?(www\.)?(youtu\.be)\/([a-zA-Z0-9_-]+)/;

      var match;

      if (ytbURL.search('watch') >= 0) {
          match = ytbURL.match(regex);
      } else {
          match = ytbURL.match(regex2);
      }

      if (match) {
          return true
      } else {
          return false;
      }

  }

  private generateEmbedUrl(youtubeUrl: any) {
      const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/watch\?v=([a-zA-Z0-9_-]+)/;
      const regex2 = /^(https?:\/\/)?(www\.)?(youtu\.be)\/([a-zA-Z0-9_-]+)/;

      if (youtubeUrl.search('watch') >= 0) {
          this.match = youtubeUrl.match(regex);
          console.log("🚀  youtubeUrl.search('watch'):", youtubeUrl.search('watch'))
      } else {
          this.match = youtubeUrl.match(regex2);
      }

      if (this.match) {
          const videoId = this.match[4];

          const embedUrl = `https://www.youtube.com/embed/${videoId}`;

          return embedUrl;
      } else {
          return null;
      }
  }
  //#endregion



}
