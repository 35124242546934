import { Component, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { StatusType } from '../enums/status-type';
import { StatusCode } from '../enums/status-code';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormCode } from '../enums/form-code';

@Component({
  selector: 'app-parametrage-vm',
  templateUrl: './parametrage-vm.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./parametrage-vm.component.less']
})
export class ParametrageVMComponent implements OnInit {


  eventName: string = ""
  status: any[] = []
  editionFormGroup !: FormGroup;
  submitted: boolean = false
  isDisabled: boolean = true
  selectedStatus: any
  frmType: string = ""
  data: any
  langSouscription?: Subscription
  isReady: boolean = false
  dateOuvertureFermeture = { horaireOuverture: '', horaireFermeture: '' }
  constructor(public app: AppComponent, private _formBuilder: FormBuilder) {

  }

  initFormGroupFields(data?: any) {
    if (data) {
      this.editionFormGroup = this._formBuilder.group({
        changeJobModeAt: new FormControl(data.ChangeJobModeAt, Validators.required),
        dureeCrenaux: new FormControl(data.DureeCreneaux, Validators.required),
        nombreCreaux: new FormControl(data.NombreCreneaux,),
        ratioFrancaisEtranger: new FormControl(data.RatioFrancaisEtranger, Validators.required),
        activerEmails: new FormControl(data.ActiverEmails),
        bloqueStand: new FormControl(data.BloqueStand),
        activerPlanning: new FormControl(data.ActiverPlanning),
        activePalnningExposant: new FormControl(data.ActiverPlanningExposant),
        activerPlanningDo: new FormControl(data.ActiverPlanningDo),
        avecDonneEtendue: new FormControl(data.AvecDonneEtendue)
      })
      this.editionFormGroup.get("nombreCreaux")?.disable()
    } else {
      this.editionFormGroup = this._formBuilder.group({
        changeJobModeAt: new FormControl(100, Validators.required),
        dureeCrenaux: new FormControl(30, Validators.required),
        nombreCreaux: new FormControl(null),
        ratioFrancaisEtranger: new FormControl(null, Validators.required),
        activerEmails: new FormControl(null),
        bloqueStand: new FormControl(null),
        activerPlanning: new FormControl(null),
        activePalnningExposant: new FormControl(null),
        activerPlanningDo: new FormControl(null),
        avecDonneEtendue: new FormControl(null)
      })
      this.editionFormGroup.get("nombreCreaux")?.disable()
    }
  }

  ngOnInit(): void {
    this.initFormGroupFields()
    this.getStatus()
    this.getEditionInfo();
    this.langSouscription = this.app.translate.onLangChange.subscribe((value: any) => { this.getEventNameSwitchLang() })
  }

  getStatus() {
    this.app.apiService.getStatus().subscribe((data: any) => {
      if (this.status) {
        this.status = data.filter((s: any) => s.StatusType === StatusType.FormState);
      }
    });
  }

  getEditionInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_VM
    this.frmType = FormCode.FRM_VM
    this.app.apiService.getEditionInfo(request).subscribe(
      data => {
        this.isReady = true
        console.log(data)
        if (data != undefined && data.Id != undefined) {
          this.data = data
          this.initFormGroupFields(data)
          this.getEventNameSwitchLang()
        }
      }
    )
  }

  getEventNameSwitchLang() {
    let currentLang = this.app.translate.currentLang
    if (this.data) {
      if (currentLang == "fr") {
        this.eventName = this.data.LabelFR
      } else if (currentLang == "en") {
        this.eventName = this.data.LabelEN
      } else {
        this.eventName = this.data.LabelES
      }
    }
  }

  verifForm() {
    this.submitted = true
    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
    }, () => { });
  }

  submit($event: any) {
    this.submitted = true
    console.log($event)
    if (this.editionFormGroup.invalid) {
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
      }, () => { });
    } else {
      let request = this.mapValueIntoObject()
      request = {
        ...request,
        ...$event
      }
      console.log(request)
      this.app.apiService.updateEditionFormInfo(request).subscribe(
        data => {
          if (data == true) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('bo.parametrage.successModification'), 'OK', "alert", () => {
              this.getEditionInfo()
              this.submitted = false
            }, () => { })
          }
        }
      )
    }
  }
  annuler() {
    this.app.sharedTools.redirectTo('/');
  }

  DeleteDataVM() {
    this.app.confirm.confirmDialog("", this.app.translate.instant('bo.parametrage.deleteConfirmMessage'),
      "AUTRE", "confirm", () => {
        setTimeout(() => { this.deleteAttachedInfo(); }, 1000);
      }, () => { })
  }

  deleteAttachedInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_VM
    this.app.apiService.deleteAttachedEditionInfo(request).subscribe(
      data => {
        if (data == true) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('bo.parametrage.successDeletion'), 'OK', "alert",
            () => {
              this.getEditionInfo()
            }, () => { })
        }
      }
    )
  }

  calculCreneau(event?: any) {
    if (event) {
      this.dateOuvertureFermeture = { horaireOuverture: event.horaireOuverture, horaireFermeture: event.horaireFermeture }
    } else if (this.data != undefined && this.data.Id != undefined) {
      if (!this.dateOuvertureFermeture.horaireFermeture && !this.dateOuvertureFermeture.horaireOuverture) {
        this.dateOuvertureFermeture = { horaireOuverture: this.data.FormOpeningDate?.split("T")[1], horaireFermeture: this.data.FormClosingDate?.split("T")[1] }
      }
    }
    let horaireOuverture = this.dateOuvertureFermeture.horaireOuverture
    let horaireFermeture = this.dateOuvertureFermeture.horaireFermeture
    let durreCreneau = this.editionFormGroup.get("dureeCrenaux")?.value

    if (durreCreneau != undefined && horaireFermeture != undefined && horaireOuverture != undefined) {
      let minutesHours = (parseInt(horaireFermeture.split(":")[0]) - parseInt(horaireOuverture.split(":")[0])) * 60
      let otherMinutes = parseInt(horaireFermeture.split(":")[1]) - parseInt(horaireOuverture.split(":")[1])
      let difference = Math.round((minutesHours + otherMinutes) / durreCreneau)
      if (difference > 0 && difference != Infinity) {
        this.editionFormGroup.get("nombreCreaux")?.setValue(difference)
      } else {
        this.editionFormGroup.get("nombreCreaux")?.setValue(0)
      }
    }
  }

  calculNombreCrenaux() {

    let durreCreneau = this.editionFormGroup.get("dureeCrenaux")?.value
    let horaireOuverture = this.editionFormGroup.get("formOpeningTime")?.value
    let horaireFermeture = this.editionFormGroup.get("formClosingTime")?.value

    if (durreCreneau != undefined && horaireFermeture != undefined && horaireOuverture != undefined) {
      let minutesHours = (parseInt(horaireFermeture.split(":")[0]) - parseInt(horaireOuverture.split(":")[0])) * 60
      let otherMinutes = parseInt(horaireFermeture.split(":")[1]) - parseInt(horaireOuverture.split(":")[1])
      let difference = Math.round((minutesHours + otherMinutes) / durreCreneau)
      if (difference > 0 && difference != Infinity) {
        this.editionFormGroup.get("nombreCreaux")?.setValue(difference)
      } else {
        this.editionFormGroup.get("nombreCreaux")?.setValue(0)
      }
    }
  }

  mapValueIntoObject() {
    return {
      ChangeJobModeAt: this.editionFormGroup.get("changeJobModeAt")?.value,
      DureeCreneaux: this.editionFormGroup.get("dureeCrenaux")?.value,
      NombreCreneaux: this.editionFormGroup.get("nombreCreaux")?.value,
      RatioFrancaisEtranger: this.editionFormGroup.get("ratioFrancaisEtranger")?.value,
      ActiverEmails: this.editionFormGroup.get("activerEmails")?.value,
      BloqueStand: this.editionFormGroup.get("bloqueStand")?.value,
      ActiverPlanning: this.editionFormGroup.get("activerPlanning")?.value,
      ActiverPlanningExposant: this.editionFormGroup.get("activePalnningExposant")?.value,
      ActiverPlanningDo: this.editionFormGroup.get("activerPlanningDo")?.value,
      AvecDonneEtendue: this.editionFormGroup.get("avecDonneEtendue")?.value,
      frmType: this.frmType
    }
  }

}
