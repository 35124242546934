import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { SharedtoolsService } from '../services/sharedtools.service';
import { ApiService } from '../services/api.service';
import { Languages } from '../enums/language';

@Component({
  selector: 'app-liste-exposants',
  templateUrl: './liste-exposants.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./liste-exposants.component.less']
})
export class ListeExposantsComponent  implements OnInit {

  private companyAutocompleteSubscription?: Subscription;
  companyNameSuggestions: string[] = [];

  exhibitors!: any[];
  search: any = {};

  representatives!: any[];

  countries!: any[];
  categories!: any[];

  loading: boolean = true;

  activityValues: number[] = [0, 100];

  constructor(public sharedTools: SharedtoolsService,
    private apiService: ApiService) { }

  ngOnInit() {
    this.getCountries()
    this.getCategories()
    this.searchExhibitor(this.search);

  
  }

  //#region Autocomplete CompanyName / Raison sociale
  onSearchCompanyName(event: any): void {
    let keyword: string | undefined = event.query;

    if (!keyword || keyword === '') {
      this.companyNameSuggestions = [];
    }
    else {
      this.refreshCompanyNameAutocomplete(keyword);
    }
  }
  private refreshCompanyNameAutocomplete(keyword: string): void {
    this.companyAutocompleteSubscription = this.apiService.exhibitorAutocompleteVmFolder(keyword).subscribe({
      next: (data: any) => {
        if (data && data != '' && Array.isArray(data)) {
          this.companyNameSuggestions = data;
        }
        else {
          this.companyNameSuggestions = []
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }
  //#endregion

  clear(table: Table) {
    table.clear();
  }

  //#region  GET
  async getCategories() {
    try {  
      const response = await this.apiService.categorieListe();
      this.categories = response;
      console.log("categorieListe",this.categories)
    } catch (error) {
      console.error(error);
    }
  }
  async getCountries() {
    try {
      const response = await this.apiService.paysListe();
      this.countries = response;
      console.log("paysListe",this.countries)

    } catch (error) {
      console.error(error);
    }

  }

  onClearSearch(): void {
    this.search = {};
    this.searchExhibitor(this.search);
  }
  //#endregion

  //#region serach
  async searchExhibitor(search: any) {
    search.Lang = Languages.Default;
    console.log("search ==> ", search);
    let searchTemp: any = cloneDeep(search);

    if (searchTemp.selectedCountry) searchTemp.CodePays = searchTemp.selectedCountry.CodeISO2;
    if (searchTemp.selectedCategory) searchTemp.CodeCat = searchTemp.selectedCategory.Code;

    if (searchTemp.selectedCountry) delete searchTemp.selectedCountry;
    if (searchTemp.selectedCategory) delete searchTemp.selectedCategory;

    console.log("searchTemp ==> ", searchTemp);

    try {
      const response = await this.apiService.searchExhibitor(searchTemp);
      console.log("searchExhibitor ==> ", response);

      if (response && response.Result) {
        return;
      }

      this.exhibitors = response;

    } catch (error) {
      console.error(error);
    }
  }
  //#endregion

}