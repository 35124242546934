import { Component } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-admin-delegation',
  templateUrl: './admin-delegation.component.html',
  styleUrls: ['./admin-delegation.component.less']
})
export class AdminDelegationComponent {
  menutabs: any = [];
  paths: any = [];
  currentPath: any = "";
  constructor(
      public app: AppComponent,
  ) { }

  ngOnInit() {
      let user = JSON.parse(sessionStorage.getItem('userData')!);
      console.log(user);
      if (!user) {
          this.app.router.navigate(['/login']);
          return;
      }

      this.paths = location.pathname.split('/');
      console.log("this.paths ==> ", this.paths);
      this.currentPath = this.paths[1];
      this.app.sharedTools.getJSON('general_data').subscribe(
          resG => {
              this.menutabs = resG.tabMenu.filter((tab:any) => {return tab.pathsIn.indexOf(this.currentPath) != -1});
          });
  }

  createNewItem() {
      let indexActive:number = -1;
      indexActive = this.app.sharedTools.getIndexOfTab();
      console.log("indexActive ==> ", indexActive);
      let active: any = null;
      if (indexActive != -1) active = this.menutabs.filter((tab: any) => { return tab.index == indexActive })[0];
      if (active) this.app.sharedTools.redirectTo(active.pathToNew);
  }
}
