export class SearchConference{
    Code: string | undefined;
    Title: string | undefined;
    TitleEN: string | undefined;
    TitleES: string | undefined;
    TitleFR: string | undefined;
    ManagerList: any | undefined;
    DateMin?: string  | null;
    DateMax?: string  | null;
    StatusID: any
    PublishOnWebStatusID: any | null;
    SpeakersList: any | undefined;
    LocationsList: number[] | undefined | any;
    TracksList: any | undefined;
    InterestList: string[]  | undefined;
    ProgrammeList : any;
    Types : any
}