export enum StatusCode {
    DRAFTED_REQ = 'DRAFTED_REQ',
    DELETED_REQ = 'DELETED_REQ',
    SCHEDULED_OD = 'SCHEDULED_OD',
    DATE_CONFIRMED_OD = 'DATE_CONFIRMED_OD',
    CANCELLED_OD = 'CANCELLED_OD',
    ARCHIVED_OD = 'ARCHIVED_OD',
    EDI3 = 'EDI3',
    EDI2 = 'EDI2',
    EDI1 = 'EDI1',
    CONSIDERED_VIS = 'CONSIDERED_VIS',
    WAITING_VIS = 'WAITING_VIS',
    NOTCONSIDERED_VIS = 'NOTCONSIDERED_VIS',
    DELETED_VIS = 'DELETED_VIS',
    DRAFT_FICHE = 'DRAFT',
    TOVALIDATE_FICHE = 'TO_BE_VALIDATE',
    UNDER_REVIEW_FICHE = 'UNDER_REVIEW',
    VALIDATED_FICHE = 'VALIDATED',
    CANCELED_FICHE = 'CANCELED',
    UNPUBLISHED_FICHE_PUBLISH = 'UNPUBLISHED',
    SCHEDULED_FICHE_PUBLISH = 'SCHEDULED',
    PUBLISHED_FICHE_PUBLISH = 'PUBLISHED',
    TO_BE_SUBMIT = 'TO_BE_SUBMIT',
    FORM_OPENED = 'OPEN',
    FORM_CLOSED = 'CLOSE',
    VALIDATED = 'VALIDATED',
    CONFIRMED = 'CONFIRMED',
    SCHEDULED = 'SCHEDULED',
    PUBLISHED = 'PUBLISHED',
    REJECTED ='REJECTED',
    WAITING = 'WAITING',
    EXPIRED = 'EXPIRED',
    FORM_LOCKED = 'LOCK',
    FORM_MASKED = 'MASK',
    DRAFT_NEWS = "DRAFT",
    PUBLISHED_NEWS = "PUBLISHED",
    UNPUBLISHED_NEWS = "UNPUBLISHED",
    INIT ='INIT',
    OKTOCONNECT = 'OKTOCONNECT',
    INPROGRESS = 'INPROGRESS',
    WAITINGSIGN = 'WAITINGSIGN',
    SIGNED = 'SIGNED',
    WINNER = 'WINNER'
}
