import { Component, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Menu } from 'primeng/menu';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { SearchHelperService } from '../services/search-helper.service';
import { OtherVisitSearchLocalStorageKey } from '../consts/utils';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-gestion-other-visit',
  templateUrl: './gestion-other-visit.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./gestion-other-visit.component.less']
})
export class GestionOtherVisitComponent {
  private exportSubscription?: Subscription;

  @ViewChild('menuAction') menuAction!: Menu;
  @ViewChild('dtOtherVisits') dtOtherVisits!: Table;
  @ViewChild('dvOtherVisits') dvOtherVisits!: DataView;

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // ==> restore original order
    this._selectedColumns = this.colsOtherVisits.filter((col: any) => val.includes(col));
  }
  _selectedColumns: any = [];
  fastSearchOtherVisitKeyword: string = '';
  showTable: boolean = true;
  otherVisits: any[] = [];
  colsOtherVisits: any[] = [];
  items: any = [];
  selectedOtherVisits: any[] = [];
  fastFiltersOtherVisits: any = ['LabelEN'];

  constructor(
    public app: AppComponent,
    private messageService: MessageService,
    private searchHelperService: SearchHelperService
  ) { }

  ngOnInit() {
    //get privious fastSearch value before routing to other routes
    this.fastSearchOtherVisitKeyword = this.searchHelperService.getFastSearchKeyword(OtherVisitSearchLocalStorageKey);

    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
        console.log("resG", resG);
        this.items = resG.actionsOtherVisitsRow;

        this.items.forEach((itemMenu: any) => {
          itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
        });

        this.colsOtherVisits = resG.colsOtherVisits;
        this._selectedColumns = this.colsOtherVisits;

        this.othervisitgetall(true);
      });

  }

  onChangeSelect(group: any) {
    console.log("onChangeSelect group", group);
  }

  async othervisitgetall(thenFilterFast: boolean = false) {
    try {
      const response = await this.app.apiService.othervisitgetall();
      console.log("privigroupgetall==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
        return;
      }

      this.otherVisits = cloneDeep(response)
      console.log("otherVisits", this.otherVisits);

        if (thenFilterFast) this.filterFast();
        console.log("after otherVisits", this.otherVisits);
      
    } catch (error) {
      console.error(error);
    }
  }

  filterFast() {
    this.searchHelperService.setFastSearchKeyword(this.fastSearchOtherVisitKeyword, OtherVisitSearchLocalStorageKey);

    console.log("_value", this.fastSearchOtherVisitKeyword);

    this.dtOtherVisits.filterGlobal(this.fastSearchOtherVisitKeyword, 'contains');
  }
  clearFastSearch(): void {
    this.fastSearchOtherVisitKeyword = '';
    this.filterFast();
  }

  clearSearch() {
    console.log("clearSearch ==>");
    this.othervisitgetall();
    this.clearFastSearch();
  }

  getActionsMenuByOtherVisit(otherVisit: any): any {
    return this.items.filter((item: any) => item.ActionID);
  }

  onRowSelect(event: any) {
    console.log("event.data==>", event.data);
    console.log("selectedGroups==>", this.selectedOtherVisits);
  }

  toggleMenu(event: any, OtherVisitID: number, items: any) {
    this.menuAction.toggle(event);

    console.log("PriviGroupID:", OtherVisitID);
    console.log("items:", items);

    items.forEach((itemMenu: any) => {
      itemMenu.otherVisitIdRow = OtherVisitID;
      itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

      if (itemMenu.ActionID == 1) {
        itemMenu.command = (event: any) => {
          this.app.sharedTools.redirectTo('/detail-othervisit/' + event.item.otherVisitIdRow.toString());
        }
      }

      else if (itemMenu.ActionID == 2) {
        itemMenu.command = (event: any) => {
            let deleteRequest: any = {OtherVisitID: event.item.otherVisitIdRow}
          this.onDeleteOtherVisit(deleteRequest);
        }
      }
    });
  }


  onDeleteOtherVisit(_otherVisit: any) {
    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteOtherVisit'), 'AUTRE', 'confirm', () => {
        this.deleteOtherVisit(_otherVisit);
    }, () => { });
}

deleteOtherVisit(_otherVisitObj: any) {
    console.log("DELETE OtherVisit ===>", _otherVisitObj);
    try {
      this.app.apiService.othervisitdelete(_otherVisitObj)
        .then(response => {
          console.log('Response API : DELETE OtherVisit ===>', response);

        if (response && response.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
            return;
        }

        this.messageService.add({ severity: 'success'
        , summary: this.app.translate.instant('racine.successMsg.successDeleteOtherVisitTitle')
        , detail: this.app.translate.instant('racine.successMsg.successOtherVisitDelete') });
        this.app.sharedTools.redirectTo('/othervisits');

            this.othervisitgetall(true); 
        });

    } catch (error) {
        console.error(error);
    }
}
}
