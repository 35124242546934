 const urlClient = 'https://eurosatory.finderr.cloud/';
 const urlApi = urlClient + 'api/';

// const urlApi = "https://eurosatory-rcpt.finderr.cloud/api/";
// const urlClient = "https://eurosatory-rcpt.finderr.cloud/";

// #region local const
// const urlApi = 'https://localhost:44369/';
// const urlClient = 'http://localhost:4200/';
//#endregion local const


export const environment = {
    production: false,
    urlApi: urlApi,
    urlClient: urlClient,
    urlFlags: urlApi + 'Resources/flags/',

    linkedInConfig : {
        clientId: "77z0ucn18dquj5",
        scope: "w_member_social openid profile email",
        redirectUrl : (folderGuid : string) => `${urlClient}welcome-mkt?folderguid=${folderGuid}`,
        url : (folderGuid : string,state : string) => `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77z0ucn18dquj5&redirect_uri=${urlClient}welcome-mkt?folderguid=${folderGuid}&state=${state}&scope=w_member_social openid profile email`
    },
    twitterConfig : {
        clientId: "3j1vsK7zvtTy0RId1Ef37dKj9",
        scope: "tweet.read tweet.write users.read offline.access",
        redirectUrl : (folderGuid : string) => `${urlClient}welcome-mkt?folderguid=${folderGuid}`,
        url : (oauthPairItem1 : string,oauthPairItem2 : string) => `https://api.twitter.com/oauth/authorize?oauth_token=${oauthPairItem1}&oauth_token_secret${oauthPairItem2}&oauth_callback_confirmed=true`,
        issuer: "https://api.twitter.com",
        responseType: "token"
    },

    generalCtrl: {
        status: urlApi + 'general/status',
        categories: urlApi + 'general/categories',
        roles: urlApi + 'general/roles',
        civilities: urlApi + 'general/civilities',
        documenttypes: urlApi + 'general/documenttypes',
        countries: urlApi + 'general/countries',
        languages: urlApi + 'general/languages',
        phones: urlApi + 'general/phones',
        functions: urlApi + 'general/functions',
        companies_EF: urlApi + 'general/companies_EF',
        folders_EF: urlApi + 'general/folders_EF',
        contacts_EF: urlApi + 'general/contacts_EF',
        productsgroups: urlApi + 'general/productsgroups',
        commercials:urlApi+'general/commercials',
        getAutoLoginUrl : urlApi + 'general/get-auto-login'
    },

    usersCtrl: {
        login: urlApi + 'users/login',
        getresetinfofromtoken: urlApi + 'users/getresetinfofromtoken',
        resetpassword: urlApi + 'users/resetpassword',
        sendmailpassword: urlApi + 'users/sendmailpassword',
        sendmailforcontact: urlApi + 'users/sendemailforcontact',
        sendmailforuser: urlApi + 'users/sendemailforuser',
        mailautologin: urlApi + 'users/mailautologin',
        directlogin: urlApi + 'users/directlogin',
        names_autocomplete: urlApi + 'users/names_autocomplete',
        search: urlApi + 'users/search',
        create: urlApi + 'users/create',
        update: urlApi + 'users/update',
        get: urlApi + 'users/get',
        usersexport: urlApi + 'users/export',
        changeetat: urlApi + 'users/changeetat',
        delete: urlApi + 'users/delete',
        autologin: urlApi + 'users/autologin',
        autologinredirect: urlApi + 'users/login_redirect',
        getContactsByRole: urlApi + 'users/contact_byrole',
        getuserAutoLoginUrl : urlApi+'users/autologin_user'
    },

    foldersCtrl: {
        create: urlApi + 'folders/create',
        get: urlApi + 'folders/get',
        listall: urlApi + 'folders/listall',
        sogenasearch: urlApi + 'folders/sogena_search',
        changestatus: urlApi + 'folders/changestatus',
        updateDirect: urlApi + 'folders/update_direct',
        deleteFolder : urlApi + 'folders/delete',
        addIndirect: urlApi + 'folders/add_indirect',
        updateIndirect: urlApi + 'folders/update_indirect',
        deleteIndirect: urlApi + 'folders/delete_indirect',
        addupdateCart: urlApi + 'folders/addupdate_cart',
        getcart: urlApi + '/folders/getcart',
        getfullcart: urlApi + '/folders/getfullcart',
        deletecartitem: urlApi + '/folders/delete_cartitem',
        getindirectnames: urlApi + '/folders/getindirectnames',
        accept_cgv: urlApi + 'folders/accept_cgv',
        send_pdftosign: urlApi + '/folders/send_pdftosign',
        setdpstep: urlApi + 'folders/setdpstep',
        finalize_cart: urlApi + '/folders/finalize_cart',
        getstats: urlApi + 'folders/getstats',
        foldersexport: urlApi + '/folders/export',
        company_autocomplete: urlApi + '/folders/company_autocomplete',
        urlautologin: urlApi + '/folders/urlautologin',
        getPlanningSettings: urlApi + '/folders/PlanningDo',
        sendMailWithAttachment: urlApi + '/folders/SendMailWithAttachment',
        changeWebPublishedStatus:urlApi+'/folders/changeWebPublishedStatus',
        generatePlanningExposantPdf : urlApi+'vm_folders/generateplanningexposantpdf',
        generatePlanningExposantXlsx : urlApi+"vm_folders/generateplanningexposantexcel",
        updateCommercial: urlApi + 'folders/updatecommercial',
        getFirstFolderGuid : urlApi + "vm_folders/get_first_fld_guid",
        updateExhibitorNumber : urlApi + "folders/update_exhibitor_number",
        importDPCsv: urlApi + ''
    },

    referentialCtrl: {
        createCategories : urlApi + 'referenciel/create_category',
        deleteCategory : urlApi+'referential/category_delete',
        phoneCheck: urlApi + 'referential/phone_check',
        role_search: urlApi + 'referential/role_search',
        role_export: urlApi + 'referential/role_export',
        role_create: urlApi + 'referential/role_create',
        role_get: urlApi + 'referential/role_get',
        role_update: urlApi + 'referential/role_update',
        role_delete: urlApi + 'referential/role_delete',
        traduction_content_file_upload: urlApi + 'referential/traduction_content_file_upload',
        chatgpt_translate_JsonData: urlApi + 'referential/chatgpt_translate_jsondata',
        chatgpt_generate_presentation: urlApi + 'referential/chatgpt_generate_presentation',
        chatgpt_translate_text: urlApi + '/referential/chatgpt_translate_text',


    },
    visitmanagerFormCtrl: {
        othervisit_getall: urlApi + 'vm_form/othervisit_getall',
        othervisit_get: urlApi + 'vm_form/othervisit_get',
        othervisit_create: urlApi + 'vm_form/othervisit_create',
        othervisit_update: urlApi + 'vm_form/othervisit_update',
        othervisit_delete: urlApi + 'vm_form/othervisit_delete',
        privigroup_getall: urlApi + 'vm_form/privigroup_getall',
        privigroup_get: urlApi + 'vm_form/privigroup_get',
        privigroup_create: urlApi + 'vm_form/privigroup_create',
        privigroup_update: urlApi + 'vm_form/privigroup_update',
        privigroup_delete: urlApi + 'vm_form/privigroup_delete',
        privigroup_blockstand: urlApi + '/vm_form/privigroup_blockstand',
        privigroup_changeisenabled: urlApi + '/vm_form/privigroup_changeisenabled',
        privigroup_changeprintplanning: urlApi + '/vm_form/privigroup_changeprintplanning',
        privigroup_changeexportplanning: urlApi + '/vm_form/privigroup_changeexportplanning',

        getListDoType: urlApi + 'vm_form/get_list_do_type',
        getListDoCountry: urlApi + 'vm_form/get_list_do_country',
        getListDo: urlApi + 'vm_form/get_list_do',
        getMyDoListGroup: urlApi + 'vm_form/get_my_do_list_group',
        getForm18Etat1Sur2: urlApi + 'vm_form/get_form18',
        updateForm18Etat1Sur2: urlApi + 'vm_form/update_form18',
        welcomegetform: urlApi + 'vm_form/VmWelcome',
        getcounter: urlApi + 'vm_form/get_counter',
        attachhoststand: urlApi + 'vm_form/attach_host_stand',
        InitFedere: urlApi + 'vm_form/init_federe',
    },

    visitmanagerRequestCtrl: {
        editSelected: urlApi + 'vm_request/create'
    },

    visitmanagerUsersCtrl: {
        get: urlApi + 'vm_users/get',
        create: urlApi + 'vm_users/create',
        update: urlApi + 'vm_users/update',
        delete: urlApi + 'vm_users/delete',
        search: urlApi + 'vm_users/search',
        export: urlApi + 'vm_users/export',
        names_autocomplete: urlApi + 'vm_users/names_autocomplete',
        changelockaccessapp: urlApi + 'vm_users/changelockaccessapp'
    },

    visitmanagerRefCtrl: {
        activites_get: urlApi + 'vm_referential/activites_get',
        od_search: urlApi + 'vm_referential/listod_search',
        od_get: urlApi + 'vm_referential/od_get',
        od_create: urlApi + 'vm_referential/od_create',
        od_update: urlApi + 'vm_referential/od_update',
        od_delete: urlApi + 'vm_referential/od_delete',
        od_calculecode: urlApi + 'vm_referential/od_calculecodedo',
        od_setlockimport: urlApi + 'vm_referential/od_setlockimport',
        od_createcontact: urlApi + 'vm_referential/odcontact_create',
        od_changestatus: urlApi + 'vm_referential/od_changestatus',
        getAllDO: urlApi + 'vm_referential/od_getall',
        od_getcountries: urlApi + 'vm_referential/od_getcountries',
        od_getoas: urlApi + 'vm_referential/od_getoas',
        od_getozs: urlApi + 'vm_referential/od_getozs',
        od_typeDO: urlApi + 'vm_referential/od_gettypes',
        od_planningsearch: urlApi + 'vm_referential/od_planningsearch',
        get_edition: urlApi + 'vm_referential/get_edition',
        od_setrepresentedby: urlApi + 'vm_referential/od_setrepresentedby',
        od_planning_exhibitor_search: urlApi + 'vm_folders/searchplanningexhibitor',
        export_planning_od: urlApi + 'vm_referential/do_exportlistplanning',
        export_planning_exhibitor: urlApi + 'vm_folders/export_listeexposant',
        editions: urlApi + 'vm_referential/getalleditions',
        evenements: urlApi + 'vm_referential/getallevents',
        edition_search: urlApi + 'vm_referential/editionsearch',
        edition_byId: urlApi + 'vm_referential/geteditionbyid',
        edition_update: urlApi + 'vm_referential/updateedition',
        edition_create: urlApi + 'vm_referential/createedition',
        edition_status: urlApi + 'vm_referential/updateeditionstatus',
        getallprogusers: urlApi + '/vm_referential/od_getallprogusers',
        getallozusers: urlApi + '/vm_referential/od_getallozusers',
        getalloausers: urlApi + '/vm_referential/od_getalloausers',
        od_createlink: urlApi + 'vm_referential/od_createlink',
        deleteEdition: urlApi + 'vm_referential/od_deleteEdition',
        updateStatutEdition: urlApi + 'vm_referential/updateeditionstatus',
        dolist_export: urlApi + '/vm_referential/do_exportlist',
        getnbdemands: urlApi + 'vm_referential/get_nb_demands',
        exportlistvisit:urlApi + 'vm_referential/exportlistvisites',
        exportlistvisitparact :urlApi + 'vm_referential/exportlistvisitesperactivities',
        statusdemand :urlApi+'vm_referential/get_stats_demands',
        getallvisits:urlApi+'vm_referential/od_getallautrevisit',
        getratio:urlApi+'vm_referential/get_ratio_visit',
        getcountriesod : urlApi+'vm_referential/od_getallcountriesod',
        getcountriesexposant: urlApi +'vm_referential/od_getallcountriesexposant',
        versionAllPlanning :urlApi+"vm_referential/version-all-planning",
        getVmParameter : urlApi+"vm_form/parametrage_vm",
        updateVmParameter : urlApi+"vm_form/update_parametrage_vm",
        deleteAttachedInfo : urlApi+"vm_form/delete_attached_info"
    },

    visitmanagerFolderCtrl: {
        create: urlApi + 'vm_folders/create',
        get: urlApi + 'vm_folders/get',
        update: urlApi + 'vm_folders/update',
        delete: urlApi + 'vm_folders/delete',
        setlockimport: urlApi + 'vm_folders/setlockimport',
        search: urlApi + 'vm_folders/search',
        federatorListe: urlApi + 'vm_folders/federatorliste',
        changeeligibility: urlApi + 'vm_folders/changeeligibility',
        changeprivgroup: urlApi + 'vm_folders/changeprivgroup',
        changebloquestand: urlApi + '/vm_folders/changebloquepointacceuil',
        changeactiveplanning: urlApi + 'vm_folders/changeactiveplanning',
        getmeetingstands: urlApi + 'vm_folders/getmeetingstands',
        company_autocomplete: urlApi + 'vm_folders/company_autocomplete',
        getAllMeetingStands: urlApi + 'vm_folders/get_list_meetingstands_all_f18',
        getexhibitors: urlApi + 'vm_folders/getexhibitors',
        getfederators: urlApi + 'vm_folders/getall_federator_exhibitors_list',
        exportfolderslist: urlApi + '/vm_folders/exportfolderslist',
        exportlisteexposant: urlApi + '/vm_folders/export_listeexposant',
        urlautologin: urlApi + 'vm_folders/urlautologin',
        paysListe: urlApi + 'vm_folders/paysliste',
        categorieListe: urlApi + 'vm_folders/categorieliste',
        searchExhibitor: urlApi + 'vm_folders/searchexhibitor',
        exhibitor_autocomplete: urlApi + 'vm_folders/exhibitor_autocomplete',

    },

    visitmanagerProgCtrl: {
        search: urlApi + 'vm_prog/listreqsearch',
        avaibility: urlApi + 'vm_prog/avaibility',
        getStatusFonctionnel: urlApi + 'vm_prog/getstatusFonctionnel',
        setStatusFonctionnel: urlApi + 'vm_prog/setstatusFonctionnel',
        export_list_demande: urlApi + 'vm_prog/export',
        getAllHall: urlApi + 'vm_prog/getallhall',
        setDemandeOnPlanning: urlApi + 'vm_prog/setdemandeonplanning',
    },

    VisitManagerContactCtrl: {
        getCompInfo1: urlApi + 'vm_contact/getcomponentinfo1',
        getCompInfo2: urlApi + 'vm_contact/getcomponentinfo2',
        getSource: urlApi + 'vm_contact/get_source_contacts',
        getSelected: urlApi + 'vm_contact/get_selected_contacts',
        representedBy: urlApi + 'vm_contact/changecontactrepresentedby',
        get: urlApi + 'vm_contact/get',
        create: urlApi + 'vm_contact/create',
        update: urlApi + 'vm_contact/update',
        delete: urlApi + 'vm_contact/delete',
        select: urlApi + 'vm_contact/selectcontact',
        unSelect: urlApi + 'vm_contact/unselectcontact',
        changecontactrepresentedby: urlApi + 'vm_contact/changecontactrepresentedby',
        emailCheck: urlApi + 'vm_contact/email_check'
    },

    VisitManagerRequestCtrl: {
        get: urlApi + 'vm_request/get',
        create: urlApi + 'vm_request/create',
        update: urlApi + 'vm_request/update',
        delete: urlApi + 'vm_request/delete',
    },
    InnovationFile: {
        welcomeInnovFile: urlApi + 'innv_file/welcome',
        setStatusProduct: urlApi + 'innv_file/setstatus',
        getFicheInnovation: urlApi + 'innv_file/getficheinnovation',
        deleteProduct: urlApi + 'innv_file/delete',
        getAllProducts: urlApi + 'innv_file/get_file',
        changepubnet: urlApi + 'innv_file/changepubnet',
        changestatus: urlApi + 'innv_file/changestatus',
        urlautologin: urlApi + '/innv_file/urlautologin',
        urlautosollogin : urlApi + '/innv_file/urlautosollogin',
        exportcsvzip :urlApi + '/innv_file/exportcsv',
        generateFicheInnovation :urlApi+ 'innv_file/generate-fiche-innovation-pdf/',
        generateFicheInnovationAsync :urlApi+ 'innv_file/async-generate-fiche-innovation-pdf',
        getInvalidFormMaximumSheetNumber : urlApi + 'innv_file/get-invalid-form-sheet-number',
        updateMaxNumberSheetForms : urlApi + 'innv_file/update-max-sheet-number'
    },

    InnovationStep: {
        getProduct: urlApi + 'innv_step/get_step',
        createProduct: urlApi + 'innv_step/save_step',
        updateProduct: urlApi + 'innv_step/update_step',
        saveSolutionLastStep: urlApi + 'innv_step/save_solution_last_step',
    },

    InnovationMediaCtrl: {
        uploadLogo: urlApi + 'innov_media/upload_logo',
        uploadImage: urlApi + 'innov_media/upload_image',
        uploadVideo: urlApi + 'innov_media/upload_video',
        uploadExternalVideoURL: urlApi + 'innov_media/upload_external_url',
        uploadDocument: urlApi + 'innov_media/upload_file',
        download: urlApi + 'innov_media/download',
        downloadImageMedia: urlApi + 'innov_media/downloadMedia',
        getMedia: urlApi + 'innov_media/get_media',
        getMediaSettings: urlApi + 'innov_media/get_all_media_settings',
        getMediaConfigs: urlApi + 'innov_media/get_all_media_configs',
        deleteDocument: urlApi + 'innov_media/delete_document'
    },

    InnovationStepCtrl: {
        getStep: urlApi + 'innv_step/get_step',
        updateStep: urlApi + 'innv_step/update_file',
    },

    GroupCtrl: {
        GeneratePlanningDoPdf: urlApi + 'group/generate_planningpdf',
        asyncGeneratePlanningDoPdf: urlApi + 'group/async_generate_planningpdf',
        asyncGeneratePlanningExhibitorPdf: urlApi + 'group/async_generate_planningpdf_exhibitor',
        generatePlanningDoPdfAsync:urlApi+"group/async-generate-planningpdf-do"
    },

    admin: {
        supprimerFormulaire: urlApi + 'admin/purgedatasformsbyedition',
        supprimerEdition: urlApi + 'admin/purgealldatasbyedition',
        reInitialiserFindeer: urlApi + 'admin/purgealldatas',
        importDoCsv : urlApi + 'admin/import-oa',
        importOzCsv : urlApi + 'admin/import-oz'

    },
    HelpedForm: {
        getFormHelped:  urlApi + 'help_form/getformHelped',
        changepubnet:   urlApi + 'help_form/changepubnet',
        changestatus : urlApi + 'help_form/changestatus',
        getAllProducts:  urlApi + 'help_form/get_file',
        setStatusProduct:  urlApi + 'help_form/setstatus',
        urlautologin: urlApi + 'help_form/urlautologin',
        urlautosollogin:urlApi + 'help_form/urlautologin',
        welcomeHelpedFile:  urlApi + 'help_form/welcome',
        UpdateHelpedSolution:  urlApi + 'helped_solution/update_solution-helped',
        SaveHelpedSolution:  urlApi + 'helped_solution/save_solution-helped',
        getAllSolutions:  urlApi + 'help_form/get_file',
        pdateHelpedParameter : urlApi+"help_form/update_parametrage_hlp",
        updateMaxNumberSheetForms : urlApi+"help_form/update-max-sheet-number"

    },
    planningHtml : urlClient + 'planning-app',
    
    ProgramForm: {
        welcomeProgramFile:  urlApi + 'program_form/welcome',
        getFormProgram : urlApi + 'program_form/get_programForm'
    },

    HelpedSolution:{
        UpdateHelpedSolution:  urlApi + 'helped_solution/update-solution-helped',
        CreateHelpedSolution:  urlApi + 'helped_solution/save-solution-helped',
        getHelpedSolution:  urlApi + 'helped_solution/get-solution-helped',
        setStatusHLP:  urlApi + 'helped_solution/setstatus',
        DeleteHelpedSolution:  urlApi + 'helped_solution/delete-solution-helped',
        saveSolutionLastStep:  urlApi + 'helped_solution/save-solution-last-step',
        generateFicheHelped : urlApi + "helped_solution/generate-fiche-pdf/"
    },
    ProgramSolution: {
        setStatusProg:  urlApi + 'program_solution/setstatus',
        DeleteProgramSolution:  urlApi + 'program_solution/delete-solution-program',
        UpdateProgramSolution:  urlApi + 'program_solution/update-solution-program',
        CreateHelpedSolution:  urlApi + 'program_solution/save-solution-program',
        getProgramSolution : urlApi + 'program_solution/get-solution-program'
    },
     //#region ==========    Marketing   ==========
     MarketingCtrl: {
        getUserInfo: urlApi + 'marketing/user-info',
        generateMedia: urlApi + 'marketing/generate-media',
        createMktForm: urlApi + 'marketing/create-mktform',
        getBanner : urlApi + 'marketing/get-banner',
        addContent : urlApi + 'marketing/add-content',
        getMktCategories : urlApi + 'marketing/get-categories',
        downloadZipFile:urlApi + 'marketing/download-zipfile',
        shareOnLinkedIn:urlApi + 'marketing/share-on-linkedin',
        shareOnTwitter:urlApi + 'marketing/share-on-twitter',
        twitterCredentials:urlApi + 'marketing/twitter-credentials',
    }
    //#endregion
    ,
     //#region ==========    Conference   ==========
     Conference: {
        searchConference: urlApi + 'conf/search',
        getInterests: urlApi + 'conf/get-all-interests',
        getSpeakers: urlApi + 'conf/get-all-speakers',
        getExhibitors: urlApi + 'conf/get-all-exhibitors',
        getRooms: urlApi + 'conf/get-all-rooms',
        getTracks:urlApi + 'conf/get-all-tracks',
        getSpeakerById: urlApi + 'conf/get-speaker-byid',
        getConfByEventId: urlApi + 'conf/get-conference-byeventid',
        searchSpeakers: urlApi + 'conf/speakers-search',
        getNextSessionsBySpeakerId: urlApi + 'conf/get-next-sessions-by-speakerid',
        getAllSessionsBySpeakerId: urlApi + 'conf/getall-sessions-by-speakerid',
        getSpeakersCountries:urlApi+'conf/get-speakers-countries',
        getTypeCategories:urlApi+'conf/get-all-types'
    },
    //#endregion

     //#region ==========    Exhibitor   ==========
     Exhibitor: {
        searchSolution: urlApi + 'catalog/search_solutions',
        getSolutionByLanguageAndId: urlApi + '/catalog/get_product'
    },
    //#endregion

     //#region ==========    Attendees   ==========
     AttendeesCtrl: {
        names_autocomplete: urlApi + 'attendees/name_autocomplete',
        company_autocomplete: urlApi + 'attendees/company_autocomplete',
        setValidationState : urlApi + 'attendees/set_validation_status',
        setNetworkingState : urlApi + 'attendees/set_networking_status',
        specificSearchAttendee : urlApi + 'attendees/search'

    },
    ConferencesCtrl: {
        valid_organisers : urlApi + 'conf/get-all-valid-organisers',
        organisers_list : urlApi + 'conf/get-all-organisers',
        conferences_list : urlApi + 'conf/admin_search',
        speakers_list : urlApi + 'conf/get-all-speakers-bo',
        program_list : urlApi + 'conf/get-all-programs',
        pocs_list : urlApi + 'conf/get-all-pocs',
        interests_list : urlApi + 'conf/get-all-interests-bo',
        changeConfState : urlApi + 'conf/changestatus',
        publishOnWebState : urlApi + 'conf/changepublishonweb',
        exposants_list: urlApi + 'conf/company_autocomplete',
        set_media : urlApi + 'conf/setMedia',
        update_conf : urlApi + 'conf/conf_update',
        save_conf : urlApi + 'conf/conf_save',
        conf_setlockimport: urlApi + 'conf/setlockimport',
        delete_conf: urlApi + 'conf/delete',
        getConfById: urlApi + 'conf/conference-get',
    },
    NewsCtrl: {
        create_news : urlApi+ 'news/create_news',
        update_news : urlApi+'news/update_news',
        news_list : urlApi + 'news/search',
        areasPublications_list : urlApi + 'news/get_all_areas_publications',
        change_news_status : urlApi + 'news/changestatus',
        change_news_web_publish_status : urlApi + 'news/change_web_publish_status',
        news_by_Id : urlApi + 'news/get_by_id',
        get_news_fo : urlApi + 'news/get_news_fo',
        communique_press_wlcm : urlApi+'news/welcome',
        save_news_fo : urlApi + 'news/news_save',
        update_news_fo : urlApi + 'news/news_update',
        delete_news_fo : urlApi + 'news/delete_news_fo',
        get_user_info : urlApi + 'news/get_user_info',
        get_news_categories : urlApi + 'news/get_news_category',
    },
    Sommaire:{
        ExhibitorsForms : urlApi + 'general/get_sommaire'
    },
    Catalog:{
        getAllExhibitors : urlApi + 'catalog/get_all_exhibitors',
        getCategoriesExhibitor : urlApi + 'catalog/get-all-categories',
        SearchExhibitors : urlApi + 'catalog/search_exhibitors',
        getExhibitorByID : urlApi + 'catalog/get_exhibitor',
        getCountriesExhibitor : urlApi + 'catalog/get-all-countries',
        SearchSolutions : urlApi + 'catalog/search_solutions',
        getSolutionByID : urlApi + 'catalog/get_product',
    },
    //#endregion

    //#region Awards Routes
    Awards:{
        getSolutionById : urlApi + "award_solution/get-solution-award",
        getCompanyInfo : urlApi + "award_company/get_company_info",
        updateCompany : urlApi + "award_company/update_company",
        createAwardSolution: urlApi + 'award_solution/save-solution-award',
        updateAwardSolution: urlApi + 'award_solution/update-solution-award',
        updateAwardSolutionLight : urlApi + 'award_solution/update-solution-award-light',
        saveSolutionInfo : urlApi + "award_solution/save-solution-award",
        setStatusAward : urlApi + "award_solution/setstatus",
        getWelcome : urlApi + 'award_form/welcome',
        checkSolutions : urlApi + 'award_form/check-form-solutions',
        publishAwardSolution: urlApi + 'award_solution/publish',

        searchAwardsBo : urlApi + 'award_form/getformAward',
        updateAwardsListStatus : urlApi + 'award_form/changestatus',
        updateAwardParameter : urlApi + "award_form/update_parametrage_awd",
        updateMaxNumberAwardSheetForms : urlApi + "award_form/update-max-sheet-number"
    },

};
