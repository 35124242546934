import { Directive, ElementRef, HostListener } from '@angular/core';
import { NotLetterRegex } from '../consts/utils';

@Directive({
  selector: '[lettersOnly]'
})
export class LettersOnlyDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input')
  onInput() {
    const inputValue = this.el.nativeElement.value;
    this.el.nativeElement.value = inputValue.replace(NotLetterRegex, '');
  }
}
