import { Injectable } from '@angular/core';
import { GlobalSearch } from '../models/global-search';
import { PageInfo } from '../models/page-info';
import { PagePaginationInfo } from '../models/page-pagination-info';

@Injectable({
  providedIn: 'root'
})
export class SearchHelperService {
  private _searchDataSource: { [index: string]: GlobalSearch } = {};
  private _pagePaginationInfo : {[index : string]: PagePaginationInfo} = {}

  constructor() { }

  public getAdvancedSearch(key: string): any {
    this.extractFromLocalStorage(key);
    return this._searchDataSource[key].advancedSearch;
  }

  public getPagePaginationInfo(key : string){
    this.extractFromLocalStorage(key)
    return this._pagePaginationInfo[key]
  }

  public setAdvancedSearch(value: any, key: string): void {
    if (!this._searchDataSource[key]) {
      let searchModel: GlobalSearch = { advancedSearch: {}, fastSearchKeyword: null };
      this._searchDataSource[key] = searchModel;
    }

    this._searchDataSource[key].advancedSearch = value;
    this.saveToLocalStorage(key);
  }

  public setPagePaginationInfo(value:any, key:string){
    if(!this._pagePaginationInfo[key]){
      let pagePaginationInfo : PagePaginationInfo = {pageNumber :1, rowSize : 50}
      this._pagePaginationInfo[key] = pagePaginationInfo
    }
    this._pagePaginationInfo[key] = value
    this.saveToLocalStorage(key);
  }

  public getFastSearchKeyword(key: string): any {
    this.extractFromLocalStorage(key);
    return this._searchDataSource[key].fastSearchKeyword;
  }
  public setFastSearchKeyword(value: any, key: string): void {
    if (!this._searchDataSource[key]) {
      let searchModel: GlobalSearch = { advancedSearch: {}, fastSearchKeyword: null };
      this._searchDataSource[key] = searchModel;
    }

    this._searchDataSource[key].fastSearchKeyword = value;
    this.saveToLocalStorage(key);
  }

  private saveToLocalStorage(key: string): void {
    let searchModel: GlobalSearch =
    {
      advancedSearch: this._searchDataSource[key]?.advancedSearch,
      fastSearchKeyword: this._searchDataSource[key]?.fastSearchKeyword
    };

    let pagePaginationInfo : PagePaginationInfo = {
      pageNumber : this._pagePaginationInfo[key]?.pageNumber,
      rowSize : this._pagePaginationInfo[key]?.rowSize
    }

    let pageInfo : PageInfo = {
      globalSearch : searchModel,
      pagePaginationInfo : pagePaginationInfo
    }

    localStorage.setItem(key, JSON.stringify(pageInfo));
  }

  private extractFromLocalStorage(key: string): void {
    let searchModelJson = localStorage.getItem(key);
    let pageInfo : PageInfo = {globalSearch : {}, pagePaginationInfo : {}}
    let searchModel: GlobalSearch = { advancedSearch: {}, fastSearchKeyword: null };
    let pagePaginationInfo : PagePaginationInfo = {pageNumber :1, rowSize : 50}
    if (searchModelJson && searchModelJson != '') {
      pageInfo = JSON.parse(searchModelJson);
      searchModel = pageInfo.globalSearch ?? {}
      pagePaginationInfo = pageInfo.pagePaginationInfo ?? {}
    }

    this._searchDataSource[key] = searchModel;
    this._pagePaginationInfo[key] = pagePaginationInfo
  }
}
