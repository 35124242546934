export enum StatusType {
    FolderState = 'FOLDER_STATE',
    F18OD = 'F18_OD',
    EditionState = 'EDITION_STATE',
    F18Request = 'F18_REQUEST',
    F18Visite = 'F18_VISITE',
    FicheState = 'FICHE_STATE',
    FichePublishState = 'FICHE_PUBLISH_STATE',
    FicheMediaImage ='FICHE_MEDIA_IMAGE',
    FormState = 'FORM_STATE',
    ATTNetworkState = 'ATT_NETWORKSTATE',
    ATTValidState = 'ATT_VALIDSTATE',
    CONFState = 'CONF_STATE',
    CONFWeb_State = 'CONF_WEB_STATE',
    NEWS_Publish_State = 'NEWS_PUBLISH_STATE',
    NEWS_State = 'NEWS_STATE',
    Award_Jury ='AWARD_JURY'
}
