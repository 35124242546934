import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediasTypes } from '../enums/media-type';
import { AppComponent } from '../app.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MediaCmpComponent } from '../medias-components/media-cmp/media-cmp.component';
import { NewsUploadMediaInfo, UploadMediaInfo } from '../models/media-upload-info';
import { LOADERS } from 'ngx-spinner';
import { StatusType } from '../enums/status-type';
import { StatusCode } from '../enums/status-code';
import { MediaValueChange } from '../models/media-value-change';
import { firstValueFrom } from 'rxjs';
import { Languages } from '../enums/language';
import { DatePipe } from '@angular/common';
import { FormCode } from '../enums/form-code';
import { MediasDegRotation } from '../enums/media-rotation-deg';

@Component({
  selector: 'app-detail-communique-press',
  templateUrl: './detail-communique-press.component.html',
  styleUrls: ['./detail-communique-press.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class DetailCommuniquePressComponent implements OnInit {
  private readonly _defaultImageUrl: string = '/assets/images/communique-press/communique-press-detail-default-image.jpg';
  cpRecapCGV: any

  isEditMode: boolean = false
  newsForm !: FormGroup
  submitted: boolean = false
  @ViewChild('photoInput') photoInput?: MediaCmpComponent;
  @ViewChild('fileInput') fileInput?: MediaCmpComponent;
  newsPhoto: any
  newsFile: any
  initForm: boolean = false
  gcvAccepted: boolean = false
  uploadedPhoto: boolean = false
  uploadedFile: boolean = false
  folderGuid?: string
  newsId: any
  newsObject: any
  enableFields: boolean = false
  isLogoSelectedEN : boolean = false 
  languages : any;
  userInfo : any;
  
  formCode : FormCode = FormCode.FRM_CP;
  imageUrl: string = this._defaultImageUrl;
  rotationStyle: MediasDegRotation = MediasDegRotation._0deg;
  frmData: any;

  constructor(public app: AppComponent) { }


  ngOnInit(): void {
    this.app.route.queryParams.subscribe(async (params: any) => {
      if (params && params["folderguid"]) {
        this.folderGuid = params["folderguid"]
      }
      if (params && params["newsId"]) {
        this.isEditMode = true
        this.newsId = parseInt(params["newsId"])
      }
      
      this.getCPFrmInfo();

      this.app.sharedTools.getJSON('general_data').subscribe(
        async resG => {
          this.cpRecapCGV = resG.cpRecapCGV;
          await this.getLanguages()
          await this.getUserInfo()
          if (this.isEditMode && this.newsId != undefined) {
            await this.getDetailNews()
          } else {
            this.initFormulaire()
          }
        })

    })
  }

  async getDetailNews() {
    this.newsObject = await this.app.apiService.getNewsById(this.newsId)
    this.gcvAccepted = this.newsObject?.GCVAccepted ?? false;
    this.imageUrl = this.getImgUrl(this.newsObject);
    this.initFormulaire()
  }


  saveNews(published : boolean) {
    this.submitted = true
    if (!this.newsForm.valid || !this.uploadedFile || !this.checkIfImageValid()) {
      console.log("invalid form errors ===>", this.newsForm.errors);

      //bloc explore form errors
      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.newsForm.controls;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log('Invalid form values:', invalidValues);

      if (invalidField && invalidField.length > 1) {
        console.log("invalidField", invalidField)

        setTimeout(() => {
          let el = invalidField[1].offsetTop - 100;
          if (el) window.scroll({ top: el, behavior: "smooth" });
        }, 10);
      }
      //
      this.app.confirm.confirmDialog(""
        , this.app.translate.instant('racine.errorsOther.errorForm')
        , 'KO'
        , 'alert'
        , () => { }
        , () => { });
    } else {
      this.createOrUpdateNews(published)
    }
  }

  async createOrUpdateNews(published : boolean) {
    let createOrEditNewsRequest: {
      NewsPublished : any;
      GCVAccepted: any;
      NewsTitleEN: string;
      NewsTitleFR: string;
      NewsTitleES: string;
      NewsContentFR: any;
      NewsContentEN: any;
      NewsContentES: any;
      FolderGuid?: any;
      NewsID?: any,
      isLogoSelectedEN ?: any,
      Language : any
    }
    let newsForm = this.newsForm.value
    if (this.isEditMode) {
      createOrEditNewsRequest = {
        NewsPublished : published,
        GCVAccepted: this.gcvAccepted,
        NewsTitleEN: newsForm.newsTitle,
        NewsTitleES: newsForm.newsTitle,
        NewsTitleFR: newsForm.newsTitle,
        NewsContentFR: newsForm.newsDescritpion,
        NewsContentEN: newsForm.newsDescritpion,
        NewsContentES: newsForm.newsDescritpion,
        FolderGuid: this.folderGuid,
        NewsID: this.newsId,
        isLogoSelectedEN : this.isLogoSelectedEN,
        Language : newsForm?.selectedNewsLanguage
      }
      let mediaUploadInfo = this.constructMedifaUploadInfo()

      const response = await this.app.apiService.updateNewsFO(createOrEditNewsRequest
        , this.photoInput?.selectedFile
        , this.fileInput?.selectedFile
        , mediaUploadInfo);

      console.log('RESPONSE Update News ===>', response);

      setTimeout(() => {
        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }
        else
          this.app.confirm.confirmDialog(""
            , this.app.translate.instant("racine.successMsg.successCpUpdtated")
            , 'OK'
            , "alert"
            , () => { this.redirectToList(); }
            , () => { });
      }, 300);


    } else {
      createOrEditNewsRequest = {
        NewsPublished : published,
        GCVAccepted: this.gcvAccepted,
        NewsTitleEN: newsForm.newsTitle,
        NewsTitleES: newsForm.newsTitle,
        NewsTitleFR: newsForm.newsTitle,
        NewsContentFR: newsForm.newsDescritpion,
        NewsContentEN: newsForm.newsDescritpion,
        NewsContentES: newsForm.newsDescritpion,
        FolderGuid: this.folderGuid,
        Language : newsForm?.selectedNewsLanguage
      }
      let mediaUploadInfo = this.constructMedifaUploadInfo()
      const response = await this.app.apiService.saveNews(createOrEditNewsRequest
        , this.photoInput?.selectedFile
        , this.fileInput?.selectedFile
        , mediaUploadInfo);
      console.log('RESPONSE CREATE News ===>', response);

      setTimeout(() => {
        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }
        else
          this.app.confirm.confirmDialog(""
            , this.app.translate.instant("racine.successMsg.successCpCreated")
            , 'OK'
            , "alert"
            , () => { this.redirectToList(); }
            , () => { });
      }, 300);
    }
  }

  checkIfImageValid(){
    return this.photoInput?.isConformTodimension
  }

  redirectToList() {
    this.app.router.navigate(["communique-press"], {
      queryParams: {
        folderguid: this.folderGuid
      }
    });
  }

  private async getLanguages() {
    const response = await firstValueFrom(this.app.apiService.getLanguages());

    if (response && Array.isArray(response)) {
      this.languages = response
    }
    else {
      this.languages = [];
    }
  }

  private constructMedifaUploadInfo(): NewsUploadMediaInfo {
    console.log(this.photoInput?.degRotation)
    let logoUploadInfo: UploadMediaInfo = new UploadMediaInfo(this.photoInput?.degRotation ?? 0
      , this.newsForm.get('ImageUpdated')?.value
      , 1);



    let fileUploadInfo: UploadMediaInfo = new UploadMediaInfo(this.fileInput?.degRotation ?? 0
      , this.uploadedFile
      , 1);

    let mediaUploadInfo: NewsUploadMediaInfo = new NewsUploadMediaInfo(fileUploadInfo,logoUploadInfo,
      );

      console.log(mediaUploadInfo)

    return mediaUploadInfo;
  }

  initFormulaire() {
    if (this.isEditMode) {
      if(Array.isArray(this.newsObject?.NewsImages) && this.newsObject?.NewsImages?.length > 0){
        this.newsPhoto = this.newsObject.NewsImages[0];
      }
      
      if(Array.isArray(this.newsObject?.NewsFiles) && this.newsObject?.NewsFiles?.length > 0){
        this.newsFile = this.newsObject?.NewsFiles[0]
      }
      
      this.enableFields = (this.newsFile != null && this.newsFile != undefined)
      let selectedLanguage = this.languages?.find((x:any)=>{return x?.LanguageID == this.newsObject?.Language?.LanguageID})
      this.newsForm = new FormGroup({
        newsDescritpion: new FormControl(this.newsObject?.NewsContentEN, [Validators.required, Validators.maxLength(420)]),
        newsTitle: new FormControl(this.newsObject?.NewsTitleEN, [Validators.required, Validators.maxLength(100)]),
        ImageUpdated: new FormControl(false),
        selectedNewsLanguage : new FormControl(selectedLanguage, [Validators.required])
      })
    } else {
      let languageDefaultValue = this.languages?.filter((lng:any)=>{
        return lng?.Code?.toLowerCase() == Languages.English
      })[0]
      this.newsForm = new FormGroup({
        newsDescritpion: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.maxLength(420)]),
        newsTitle: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.maxLength(100)]),
        ImageUpdated: new FormControl(false),
        selectedNewsLanguage : new FormControl(languageDefaultValue, [Validators.required])
      })
    }
    this.initForm = true
  }

  mediasTypes = MediasTypes;

  OpenPDF() {
    let filePath: string = this.app.sharedTools.getPropertySwitchLang(this.cpRecapCGV, 'FilePath');
    window.open(filePath, "_blank");
  }

  openNewsPdf() {
    window.open(this.fileInput?.document?.DocUrl, "_blank");
  }

  mediaFileValueChanged(event: any) {
    let newsDescritpionForm = this.newsForm?.get("newsDescritpion")
    let newsTitleForm = this.newsForm?.get("newsTitle")
    if ((event?.HaveValue && event?.IsFileSelected) || this.enableFields) {
      newsDescritpionForm?.enable()
      newsTitleForm?.enable()
      this.uploadedFile = true
    } else {
      newsDescritpionForm?.disable()
      newsTitleForm?.disable()
      this.uploadedFile = false
    }
    this.newsForm?.updateValueAndValidity()
    this.enableFields = false
  }

  mediaPhotoValueChanged(event: MediaValueChange) {
    let imageUpdatedControl = this.newsForm?.get('ImageUpdated');

    if (imageUpdatedControl && event.IsFileSelected) {
      imageUpdatedControl.setValue(true);
    }
    this.isLogoSelectedEN = event?.HaveValue ?? false;

    this.refreshImageUrl(event);   
  }

  async getUserInfo(){
    this.userInfo = await this.app.apiService.getNewsConnectedUserInfo(this.folderGuid ?? "")
  }  

  getImgUrl(news: any): string {
    return (news
      && news.NewsImages 
      && Array.isArray(news.NewsImages) 
      && news.NewsImages.length > 0 
      && news.NewsImages[0] 
      && news.NewsImages[0].DocUrl)
    ? news.NewsImages[0].DocUrl
    : this._defaultImageUrl;
  }

  refreshImageUrl(mediaValueChange: MediaValueChange): void{
    if(mediaValueChange && mediaValueChange.IsFileSelected){
      if(this.photoInput?.selectedFile){
        console.log(this.photoInput.selectedFile.objectURL.changingThisBreaksApplicationSecurity)
        this.imageUrl = this.photoInput.selectedFile.objectURL?.changingThisBreaksApplicationSecurity
        ?? this._defaultImageUrl;
      }
      else{
        this.imageUrl = this._defaultImageUrl;
      }
    }
  }

  handleRotationStyleChange(event: MediasDegRotation): void{
    this.rotationStyle = event;
  }

  async getCPFrmInfo() {
    let request = { frmType: "" };
    request.frmType = FormCode.FRM_CP;
    let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
    if (data != undefined && data.Id != undefined) {
      this.frmData = data;
    }
  }
}
