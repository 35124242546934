export enum CategoryType {
    CompanyResp = 'COMPANY_RESP',
    SectorDO = 'SECTOR_DO',
    TypeDO = 'TYPE_DO',
    TypeOAOZ = 'TYPE_OA-OZ',
    Catering = 'CATERING_TYPE',
    DOResp = 'DO_RESP',
    AccueilDO = 'ACCUEIL_DO',
    MARKETING = 'MARKETING_FORM',
    ATTInterest = 'ATT_INTEREST',
    ATTExpert = 'ATT_EXPERT',
    BadgeType = 'BADGE_TYPE',
    ConfPlace = 'CONF_PLACE',
    HELPActivities = 'HELP_ACTIVITIES',
    DemoType ="DEMO_TYPE", // not defined yet in confluence
    AcessType =  "CONF_ACCESS_TYPE",
    Activities = "ACTIVITIES",
    ConfTheme = "CONF_THEME",
    SpeakerForm = "SPEAKER_MARKETING_FORM",
    ProgPlace ="PROG_PLACE",
    NewsCategory = "NEWS_CATEGORIES",
    ConfType = "CONF_TYPE",
    AwardCategory = "AWARD_ACTIVITIES"
}
