export class SearchProductHelp {
    public companyName2?: string;
    public companyExternalID1?: string;
    public countryID?: number | null;
    public statusID?: number | null;
    public isPublishOnNet?: boolean | null;
    public submissionDateStart?: string | null;
    public submissionDateEnd?: string | null;
    public solutionNameEN?: string;
    public solutionNameFR?: string;
    public solutionNameES?: string;
    public categoryID?: number | null;
    public SelectedStatus?: any;
}