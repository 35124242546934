import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-mkt-website',
  templateUrl: './mkt-website.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./mkt-website.component.less']
})
export class MktWebsiteComponent implements OnInit {

  newTab3Form!: FormGroup;
  imageUrl: any;
  imageByte: any;
  isBannerExist: boolean = true;
  FolderID!: number;

  @Input() mktFormID!: number;

  @Input() userInfo: any;

  @Input() reloadGetUserInfo: boolean = false;

  @Input() logoSettings: {} = {};
  @Input() isLoadLogoSettings: boolean = false;


  @Input() categoryID: any;

  @Output() canTriggerReloadUserInfo: EventEmitter<any> = new EventEmitter<any>();
  @Input() canRecieveUserInfo: boolean = false;

  @Output() canReloadGetUserInfo: EventEmitter<any> = new EventEmitter<any>();

  isGenerated: boolean = false;
  canRegenerateMedia: boolean = false;

  inputGeneratedLink: string = '';
  mediaGenerationInProgress=false;

  generateWebSiteCodeTemplate: string = '';

  constructor(public app: AppComponent, private clipboard: Clipboard) {

  }


  async ngOnInit() {
    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
          let marketingWebsite = resG.marketingWebsite;
          this.generateWebSiteCodeTemplate = marketingWebsite?.generateCodeTemplate;                
      });

    console.log("> [getUserInfo] from mkt-website-cmp :", this.userInfo);

    this.initTab3Form();

    this.newTab3Form.get('GeneratedUrlCode')?.setValue('');

    await this.getKeysFromStorage();

    this.isGenerated = this.userInfo?.IsGenerated?.WEBSITE;

    if (this.isGenerated === false) {
      await this.getBanner();
    }

    this.handleTriggerReloadEvent(this.reloadGetUserInfo);

  }

  private async getKeysFromStorage() {
    const folderIdString = sessionStorage.getItem("mktFolderID");
    const formIDString = sessionStorage.getItem("mktFormID");

    if (folderIdString !== null) {
      this.FolderID = parseInt(folderIdString);
    }

    if (formIDString !== null) {
      this.mktFormID = parseInt(formIDString);
    }
  }

  async getBanner() {

    console.log("🚀  this.mktFormID:", this.mktFormID)
    let resp = await this.app.apiService.getBanner(this.mktFormID, this.categoryID);
    console.log("==>>getBanner", resp);
    this.imageUrl = resp.ImageUrl;
    this.setGeneratedUrlCode(this.imageUrl);
    this.imageByte = resp.ImageContent;
    // this.isBannerExist = false;
    if (this.imageUrl != null) {
      this.isBannerExist = true;
    } else {
      this.isBannerExist = false;
    }
  }
  async copyBannerCode() {
    let generatedCode = this.newTab3Form?.get('GeneratedUrlCode')?.value;
    if (generatedCode) {
      this.clipboard.copy(generatedCode);
    } else {
      alert('Please enter text before copying.');
    }
    console.log(">>> GeneratedUrlCode : ", generatedCode);
  }
  generateMedias() {

  }

  downloadMediaZIP() {

  }

  writeWithAI() {

  }


  sendEmail() {
    console.log("> Sending email...");
  }

  //#region initialiseForm
  private initTab3Form(): void {
    this.newTab3Form = new FormGroup({
      CompanyName: new FormControl(),
      SelectedLocation: new FormControl(),
      LandingPageUrl: new FormControl(),
      GeneratedUrlCode: new FormControl(),
    });
  }
  //#endregion

  async isMediaGenerated(event: any) {

    this.canRegenerateMedia = false;
    this.isGenerated = event?.WEBSITE ?? false;
    console.log(">>>---  this.isGenerated:", this.isGenerated);
    console.log(">>>--- this.canRegenerateMedia:", this.canRegenerateMedia);
    if (this.canRegenerateMedia === false && this.isGenerated === true) {
      if (!this.mediaGenerationInProgress) {
        this.mediaGenerationInProgress = true;

        await this.getBanner();
  
        this.mediaGenerationInProgress = false;
      }
    }

  }

  handleTriggerReloadEvent(event: boolean) {

    this.canTriggerReloadUserInfo.emit(event);

  }

  handleCanReloadGetUserInfo(event: boolean) {

    this.canReloadGetUserInfo.emit(event);

  }

  handleRegenerateMedia(event: boolean){
    this.canRegenerateMedia = event;
    console.log("🚀  this.canRegenerateMedia:", this.canRegenerateMedia)
  }

  private setGeneratedUrlCode(url: string): void{
    let generatedUrlCodeControl = this.newTab3Form.get('GeneratedUrlCode');
    if(generatedUrlCodeControl){
      let strHtmlCode : string = this.generateWebSiteCodeTemplate.replace('[URL_IMAGE]', url ?? '');

      generatedUrlCodeControl.setValue(strHtmlCode);
    }
  }
}
