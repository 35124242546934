import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { AppComponent } from '../app.component';
import { IdentityManagerService } from '../services/identity-manager.service';
import { APPID } from '../enums/app_id';
import { MediasTypes } from '../enums/media-type';
import { MktCategory } from '../enums/mkt-category';
import { CategoryType } from '../enums/category-type';
import { environment } from 'src/environments/environment';
import { FrmMarketingCodes } from '../enums/form-mkt-codes';
import { FormCode } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-welcome-mkt',
  templateUrl: './welcome-mkt.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./welcome-mkt.component.less']
})
export class WelcomeMktComponent implements OnInit {

  urlApi = environment.urlApi;
  user: any = null;
  appID: any = null;
  welcomeObj: any = null;

  userInfo: any;

  folderGuid: any;
  formID: any;
  folderID: any;

  menuTabs: any;

  selectedFileURL: string | null = null;

  activeIndex = 0;
  categoryId: any;

  isUserInfoLoaded: boolean = false;

  isLogo: boolean = false;
  isImage: boolean = false;

  logoSettings: {} = {};
  imageSettings: {} = {};

  isLoadLogoSettings: boolean = false;
  isLoadImageSettings: boolean = false;

  socialNetworkCatID: any;
  emailSignatureCatID: any;
  websiteCatID: any;
  advertisementCatID: any;

  reloadGetUserInfo: boolean = false;

  canRecieveUserInfo: boolean = true;

  categories: any;
  subCategories: any;
  frmData : any

  constructor(
    public app: AppComponent,
    private identityManagerService: IdentityManagerService
  ) { }


  async ngOnInit() {
    console.log("🚀  mktFormID destroyed !");

    this.activeIndex = this.checkActivePanelTabIndex();

    this.reloadGetUserInfo = true;
    await this.getMarketingFrmInfo()

    await this.getMktCategories();
    await this.getMediaSettings();

    console.log(this.app.route);
    this.app.route.queryParams.subscribe((params: any) => {
      console.log('p ', params)
      this.folderGuid = params['folderguid'];

      sessionStorage.setItem("mktFolderGuid", JSON.stringify(this.folderGuid));

      console.log(`Param Value: folder ${this.folderGuid}`);
    });

    this.identityManagerService.checkIsUserAuthenticated();
    this.user = cloneDeep(this.identityManagerService.currentUser);

    this.app.sharedTools.getJSON('general_data').subscribe(
      (resG: any) => {
        console.log("resG ====> ", resG.menuTabWelcomeMarketing);
        // TODO: Check data from API
        // this.menuTabs = resG.menuTabWelcomeMarketing;
        // this.appID = resG.APP_ID;
        this.appID = APPID.Mkt;
      });

    this.menuTabs = this.categories;

    await this.createMktForm(this.folderGuid);
    await this.getUserInfo(this.formID);
  }

  async getMarketingFrmInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_MARKTNG
    let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
    if (data != undefined && data.Id != undefined) {
        this.frmData = data
    }
}

  private checkActivePanelTabIndex(): number {
    let index = sessionStorage.getItem('activeIndex');
    let activeIndex : number = 0;
    
    if (index) {
      activeIndex = JSON.parse(index);
    }else{
      sessionStorage.setItem('activeIndex', JSON.stringify(this.activeIndex));
    }

    console.log("🚀  activeIndex:", activeIndex)
    return activeIndex;

  }

  async onTabChange(event: any) {
    this.activeIndex = await event.index;

    sessionStorage.setItem('activeIndex', JSON.stringify(this.activeIndex));

    this.canRecieveUserInfo = true;

   // await this.getUserInfo(this.formID);

    console.log("🚀  this.reloadGetUserInfo:", this.reloadGetUserInfo);
    // this.thankYouMessage = `Tab[${this.activeIndex}]`;

    console.log("🚀  onTabChange:", this.activeIndex);

  }

  isActive(index: number) {
    console.log("🚀  this.activeIndex === index:", this.activeIndex === index)
    return this.activeIndex === index;
  }

  // ---  get Medias Settings
  private async getMediaSettings() {
    return this.app.apiService.getMediaSettings().subscribe({
      next: (resp) => {

        resp.forEach(async (m: any) => {
          if (m.Code == MediasTypes.LOGO) {
            this.isLogo = true;
            let frmMarketingSetting = m?.Settings?.filter((x:any)=> x?.FormCode == FormCode.FRM_MARKTNG)
            if(frmMarketingSetting && Array.isArray(frmMarketingSetting) && frmMarketingSetting?.length > 0){
              this.logoSettings = frmMarketingSetting[0];
            }
            this.isLoadLogoSettings = true;
            console.log("<<<>  logoSettings:", this.logoSettings);
          }

          if (m.Code == MediasTypes.IMAGE) {
            this.isImage = true;
            let frmMarketingSetting = m?.Settings?.filter((x:any)=> x?.FormCode == FormCode.FRM_MARKTNG)
            if(frmMarketingSetting && Array.isArray(frmMarketingSetting) && frmMarketingSetting?.length > 0){
              this.imageSettings = frmMarketingSetting[0];
            }
            this.isLoadImageSettings = true;
            console.log("<<<>  imageSettings : ", this.imageSettings);
          }

        });
      },
      error: (err) => {
        console.log("Error getMediaSettings", err);
      }
    });
  }

  // -- getcategories
  private async getMktCategories() {
    // let resp = await this.app.apiService.getMktCategories();
    await this.getCategories();

    this.categories.forEach((cat: any) => {

      if (cat.Code === MktCategory.SOCIALNETWORK) {
        this.socialNetworkCatID = cat.CategoryID;
      }
      if (cat.Code === MktCategory.MAILSIGN) {
        this.emailSignatureCatID = cat.CategoryID;
      }
      if (cat.Code === MktCategory.WEBSITE) {
        this.websiteCatID = cat.CategoryID;
      }
      if (cat.Code === MktCategory.PUBLICITY) {
        this.advertisementCatID = cat.CategoryID;
      }


    });

  }

  private async createMktForm(guid: any) {
    let resp = await this.app.apiService.createMktForm(guid, FrmMarketingCodes.FRM_MARKTNG);
    if (resp?.Result === "NOTFOUND") {
      this.showPopUp("errorInvalidGuid");
    } else {
      this.formID = resp?.FormMKT;
      this.folderID = resp?.FolderID;
      sessionStorage.setItem("mktFolderID", JSON.stringify(this.folderID));
      sessionStorage.setItem("mktFormID", JSON.stringify(this.formID));
      // this.isMktFormCreatedEvent(true);
      console.log("<<< respMKT create:", resp)
      console.log("<<< respMKT formID:", this.formID);
      console.log("<<< respMKT folderID:", this.folderID);
    }
  }

  private showPopUp(errorMsg: any) {
    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.' + errorMsg), 'KO', 'alert', () => {
      this.app.router.navigate(['404']);
    }, () => { });
  }

  async getUserInfo(mktFormId: number) {
    let resp = await this.app.apiService.getUserInfo(mktFormId,CategoryType.MARKETING);
    this.userInfo = resp;
    console.log("> [getUserInfo] from welcome-mkt :", this.userInfo);

    this.isUserInfoLoaded = true;

  }

  async handleTriggerReloadEvent(event: boolean) {

    if (event == true || this.reloadGetUserInfo) {
      await this.getUserInfo(this.formID);
    }

    if (event != null) {
      this.canRecieveUserInfo = event;
    }
  }

  async handleCanReloadGetUserInfo(event: boolean) {
    console.log("🚀  event:", event);
    if (event === true) {
      await this.getUserInfo(this.formID);
    }

  }

  private async getCategories() {
    try {
      const response = await this.app.apiService.getCategories();

      let categoriesIni = cloneDeep(response);

      let categories = categoriesIni.filter((cat: any) => {
        return cat.CategoryType == CategoryType.MARKETING;
      });

      
      const filterCategories = cloneDeep(categories.filter((cat: any) => { return cat.ParentCategoryID == null; }));
      const filterSubCategories = cloneDeep(categories.filter((cat: any) => { return cat.ParentCategoryID != null }));
      
      this.categories = this.sortByViewOrderAsc(filterCategories);
      this.subCategories = this.sortByViewOrderAsc(filterSubCategories);

      console.log("<<<  [categories]:", this.categories);
      console.log("<<<  [categories][subCategories]:", this.subCategories);


    } catch (error) {
      console.error(error);
    }
  }

  private sortByViewOrderAsc(list: any){
    return list.sort((a:any,b:any) => {
      const catA = a.ViewOrder;
      const catB = b.ViewOrder;
      return catA - catB;
    });
  }

}
