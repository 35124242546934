import { Component, ViewEncapsulation } from '@angular/core';
import { MediasTypes } from '../enums/media-type';
import { AppComponent } from '../app.component';
import { firstValueFrom } from 'rxjs';
import { cloneDeep } from 'lodash';
import { StatusType } from '../enums/status-type';
import { FormCode } from '../enums/form-code';

@Component({
    selector: 'app-liste-products-helped',
    templateUrl: './liste-products-helped.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./liste-products-helped.component.less']
})
export class ListeProductsHelpedComponent {
    user: any = null;
    appID: any = null;
    products: any[] = [];
    numbers: any;
    translatedNbProdMessage: any = "";
    showFormProduct: boolean = false;
    formHelpedID!: number;
    helpedForm: any;
    status: any[] = [];
    mediaTypes = MediasTypes;
    findImage: any;
    productsLoaded: boolean = false;
    frmData: any

    responsiveOptions: any[] = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    constructor(
        public app: AppComponent,
    ) {
        this.getFormHelpedData();
    }

    async getHelpedInfo() {
        let request = { frmType: "" }
        request.frmType = FormCode.FRM_HLP
        let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
        if (data != undefined && data.Id != undefined) {
            this.frmData = data
        }
    }

    async ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log("this.user ==> ", this.user);
        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }
        await this.getHelpedInfo()
        this.app.route.queryParams
            .subscribe(params => {
                console.log('[params]', params);
                if (params && params['helpedid']) {
                    sessionStorage.setItem('helped-form', JSON.stringify({ HlpFormID: params['helpedid'] }));
                    this.formHelpedID = params['helpedid'];
                    console.log("this is formHelpedID !!", this.formHelpedID)
                }
                console.log('[formHelpedID]', this.formHelpedID)
                if (params && params['folderguid']) {
                    sessionStorage.setItem('folderGuid', JSON.stringify(params['folderguid']));
                }
            });


        sessionStorage.removeItem('folderStep');
        sessionStorage.removeItem('activeStep');

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                this.appID = resG.APP_ID;
            });

        await this.getStatus();
        this.getHelpedForm();

        const productsLength = this.products.length; // Assuming products is your array
        this.translatedNbProdMessage = this.app.translate.instant('fo.productListCommun.nbProducts').replace('{nb}', productsLength.toString());
    }

    async getHelpedForm() {
        this.productsLoaded = false;
        this.helpedForm = await this.app.apiService.getAllSolutionsHLP({ HlpFormID: this.formHelpedID });
        this.products = this.helpedForm?.Solutions ? this.helpedForm?.Solutions : [];
        const nbMaxFiles = { NbMaxProducts: this.helpedForm.NbMaxProduct, NbTotalProduct: this.helpedForm.NbTotalProduct };
        this.numbers = nbMaxFiles;

        console.log("helpedForm", this.helpedForm)
        this.mapProducts();
    }

    onRefresh(event: any): void {
        this.getHelpedForm();
    }

    private getFormHelpedData(): void {
        let helpedform: any = JSON.parse(sessionStorage.getItem('helped-form') ?? '{}');

        console.log("helpedform", helpedform)

        if (helpedform && helpedform.HelpedFormID) {

            this.formHelpedID = helpedform.HelpedFormID;
        }
        else if (helpedform && helpedform.HlpFormID) {

            this.formHelpedID = helpedform.HlpFormID;

        }
    }

    private async getStatus() {
        let data = await firstValueFrom(this.app.apiService.getStatus());

        if (data && Array.isArray(data)) {
            this.status = cloneDeep(data);
            this.status = this.status.filter((s: any) => s.StatusType === StatusType.FicheState);

            console.log("status", this.status)
        }
    }

    private mapSuppPropertiesToProduct(product: any): void {
        console.log("mapSuppPropertiesToProduct", this.products)

        if (product?.Status) {
            let findStatus: any = this.status.find((s: any) => s.StatusID === product.Status);
            product.SelectedStatus = findStatus;
        }

        if (product?.DOC_Documents && product?.DOC_Documents.length > 0) {
            let firstImage: any = product?.DOC_Documents.find((doc: any) => doc.typeDocument && doc.typeDocument.TypeDOCCode === MediasTypes.IMAGE);
            if (firstImage) {
                product.FirstImageUrl = firstImage;
            }
        }
        console.log("mapSuppPropertiesToProduct product.FirstImageUrl ", product.FirstImageUrl)
        console.log("mapSuppPropertiesToProduct product.SelectedStatus ", product.SelectedStatus)
    }

    mapProducts(): void {
        if (!this.productsLoaded) {
            this.products.forEach((p: any) => {
                this.mapSuppPropertiesToProduct(p);
            });
            this.productsLoaded = true;
        }
    }

    annuler() {
        let folderGuid = sessionStorage.getItem('folderGuid');
        let guid = JSON.parse(folderGuid!);
        this.app.sharedTools.goTo(`/welcome-helped?folderguid=${guid}`);
    }
}

