import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Crenau } from '../models/crenau';
import { Priority } from '../enums/priority';
import { ODRequest, ODRequestActivity } from '../models/od-Request';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-demand-detail',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './demand-detail.component.html',
  styleUrls: ['./demand-detail.component.less'],
})
export class DemandDetailComponent implements OnInit {
  //#region local poperties
  test: any;
  metier: any;
  Name: any;
  indirect: any
  lundi: any;
  jours: [] = [];
  users: [] = [];
  countries: [] = [];

  oDID?: number = 9;
  od: any;
  edition: any;
  folderID?: number;
  formID?: number;
  requestID?: number;
  priority: Priority = Priority.P1;
  request: any;
  exposant: any;
  activities: any[] = [];
  exteriorActivities: any[] = [];
  interiorActivities: any[] = [];
  oDcontacts: any[] = [];

  //Crenaux
  showCrenauxToWish: boolean = false;
  showCrenauxToAvoid: boolean = false;
  allEditionDates: any[] = [];
  crenauxToWishList: Crenau[] = [];
  crenauxToAvoidList: Crenau[] = [];
  periodOfDay: any;
  currentlangue: any;


  selectedCrenauToWish: any = null;

  //form
  isEdit: boolean = false;
  //#endregion

  noteVisible: boolean = false;
  currentActivity: any;
  currentNote: string = '';

  requestActivities: ODRequestActivity[] = [];
  public app!: AppComponent;
  formattedDate: string | null = null;


  disableCrenauWish: boolean = false

  //#region ctor
  constructor(
    private formBuilder: FormBuilder,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private datePipe: DatePipe
  ) {
    //init request
    this.request = {
      RequestID: null,
      GroupID: null,
      RequestFedID: null,
      Priority: null,
      ReqIntAct1: null,
      ReqIntAct1Note: null,
      ReqIntAct2: null,
      ReqIntAct2Note: null,
      ReqIntAct3: null,
      ReqIntAct3Note: null,
      ReqIntAct4: null,
      ReqIntAct4Note: null,
      ReqIntAct5: null,
      ReqIntAct5Note: null,
      ReqExtAct1: null,
      ReqExtAct1Note: null,
      ReqExtAct2: null,
      ReqExtAct2Note: null,
      ReqExtAct3: null,
      ReqExtAct3Note: null,
      ReqExtAct4: null,
      ReqExtAct4Note: null,
      ReqExtAct5: null,
      ReqExtAct5Note: null,
      Motivation: null,
      Comment: null,
      Crenaux: null
    };
  }
  //#endregion

  //#region handle lifecycle hooks
  ngOnInit(): void {
    if (this.config.data) {
      this.app = this.config.data.app;
      this.formID = this.config.data.formID;
      this.oDID = this.config.data.od.GroupID;
      this.folderID = this.config.data.folderID;
      this.priority = this.config.data.priority;
      this.requestID = this.config.data.requestID;
      this.isEdit = this.config.data.isEdit;
    };
    console.log('formID ', this.formID, 'oDID ', this.oDID, 'folderID ', this.folderID, 'priority ', this.priority)
    console.log(this.formID, this.oDID)

    this.app.sharedTools.getJSON('general_data').subscribe((resG: any) => {
      this.periodOfDay = resG.periods;
      this.currentlangue = this.app.translate.currentLang;
      if (this.formID) {
        this.getExposant(this.formID);
      }

      if (this.oDID) {
        this.getOD(this.oDID);
      }
      this.getEdition();
    });
  }
  //#endregion

  //#region handle events
  getColorStatus() {
    let tailleTexte = "{ 'font-size': 5 + 'px' }";
    return tailleTexte;
  }
  getColorFunction() {
    let classColor = "text-cyan-500";
    return classColor;
  }
  getColorLabel() {
    let classColor = "text-blue-900 font-bold";
    return classColor;
  }
  getStyleLabel2() {
    let classColor = "text-blue-500";
    return classColor;
  }

  onCancelNote(): void {
    this.currentActivity = {};
    this.currentNote = '';
  }

  onActivityCheckChange(activity: ODRequestActivity, value: any): void {
    if (value?.checked) {
      this.showNoteDialog(activity);
      this.onActivityCheck(activity);
    }
    else {
      this.onActivityUnCheck(activity);
    }
    console.log('req : ', this.request);
  }

  showNoteDialog(activity: any) {
    let findAct = this.requestActivities.find((a: any) => a.ActivityNum === activity?.ActivityNum);
    if (findAct) {

      this.currentActivity = findAct;
      console.log(activity);
      this.currentNote = this.currentActivity.Note;
      this.noteVisible = true;
    }
  }

  hideNoteDialog() {
    this.noteVisible = false;
  }

  onSaveNote(): void {

    this.hideNoteDialog();
    this.setRequestNote(this.currentActivity, this.currentNote);
  }

  onActivityCheck(activity: any): void {
    this.addActivityToRequest(activity);
  }

  onActivityUnCheck(activity: any): void {
    this.removeActivityFromRequest(activity);
  }

  onSubmit(): void {
    console.log('onSubmit request : ', this.request);

    if (this.request) {

      //set selected Crenaux
      this.request.Crenaux = [
        ...this.crenauxToAvoidList.filter((c: Crenau) => c.isSelected)
      ];

      if (this.selectedCrenauToWish) {
        this.request.Crenaux.push(this.selectedCrenauToWish)
      }
      let selectedActs = this.requestActivities.filter(r => r.IsChecked);

      selectedActs.forEach(act => {
        if (act.IntVisible) {
          this.request[`ReqIntAct${act.ActivityNum}`] = act.IsChecked;
          this.request[`ReqIntAct${act.ActivityNum}Note`] = act.Note;
        }
        else if (act.ExtVisible) {
          this.request[`ReqExtAct${act.ActivityNum}`] = act.IsChecked;
          this.request[`ReqExtAct${act.ActivityNum}Note`] = act.Note;
        }
      })

      if (this.isEdit) {
        this.updateRequest(this.request);
      }
      else {
        //set priority
        this.request.Priority = this.priority;
        this.createRequest(this.request);
      }
    }
  }

  onCancel(): void {
    this.ref?.close();
  }
  //#endregion

  //#region private actions
  private async getOD(vmDoId: any) {
    try {
      const response = await this.app.apiService.getVmDo(vmDoId);
      console.log("getVmDo ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
        }, () => {
        })
        return;
      }
      this.od = cloneDeep(response);
      this.orderCompositionContacts(this.od);
      console.log("🚀  this.doModel:", this.od);

    }
    catch (error) {
      console.error(error);
    }
  }

  private async getEdition() {
    try {
      const response = await this.app.apiService.getEdition();
      console.log("🚀  response getEdition :", response);

      this.edition = response;
      if (this.edition?.AllEditionDates
        && this.edition?.AllEditionDates.length > 0) {
        this.allEditionDates = this.edition.AllEditionDates;

      }

      if (this.isEdit && this.requestID) {
        this.getRequest(this.requestID);
      }
      else {
        this.initCrenauxLists();
      }

      console.log("🚀  allEditionDates :", this.allEditionDates);
    } catch (error) {
      console.error(error);
    }
  }

  private async getExposant(exposantId: any) {

    try {
      const response = await this.app.apiService.getVmFolder(exposantId);
      console.log("getExposant ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
        }, () => {
        })
        return;
      }
      this.exposant = cloneDeep(response);
      if (this.exposant?.PriviGroups?.ExhActivities) {
        this.activities = this.exposant?.PriviGroups?.ExhActivities;
        console.log("this.activities ==> ", this.activities);


        if (this.activities && this.activities.length > 0) {
          this.exteriorActivities
            = this.activities.filter((act: any) => act.ExtVisible);

          this.interiorActivities
            = this.activities.filter((act: any) => act.IntVisible);

          this.activities = [...this.interiorActivities
            , ...this.exteriorActivities];

          console.log("exteriorActivities ==> ", this.exteriorActivities
            , "interiorActivities ==> ", this.interiorActivities);
        }
      }
      this.initRequestActivities();

    }
    catch (error) {
      console.error(error);
    }
  }

  private initCrenauxLists(): void {
    if (this.allEditionDates?.length > 0) {
      this.allEditionDates.forEach((d: any) => {
        let crenau: Crenau = {
          isSelected: false,
          CreDay: d,
          CreDuration: 'FULL'
        };

        let creToWishIsSelected = false;
        let creToWishCreDuration = 'FULL';
        let creToWishID = null;

        let creToAvoidIsSelected = false;
        let creToAvoidCreDuration = 'FULL';
        let creToAvoidID = null;

        if (this.request?.Crenaux) {
          let findToWishCrenau: any
            = this.request.Crenaux.find((c: any) => c.CreDay === d && c.CreToWish);
          if (findToWishCrenau) {
            this.showCrenauxToWish = true;

            creToWishIsSelected = true;
            creToWishCreDuration = findToWishCrenau.CreDuration;
            creToWishID = findToWishCrenau.CrenauID;
          }

          let findToAvoidCrenau: any
            = this.request.Crenaux.find((c: any) => c.CreDay === d && c.CreToAvoid);
          if (findToAvoidCrenau) {
            this.showCrenauxToAvoid = true;

            creToAvoidIsSelected = true;
            creToAvoidCreDuration = findToAvoidCrenau.CreDuration;
            creToAvoidID = findToAvoidCrenau.CrenauID;
          }
        }

        let crenauToWish = cloneDeep(crenau);
        crenauToWish.CreToWish = true;
        crenauToWish.isSelected = creToWishIsSelected;
        crenauToWish.CreDuration = creToWishCreDuration;
        crenauToWish.CrenauID = creToWishID;

        this.crenauxToWishList.push(crenauToWish);

        let crenauToAvoid = cloneDeep(crenau);
        crenauToAvoid.CreToAvoid = true;
        crenauToAvoid.isSelected = creToAvoidIsSelected;
        crenauToAvoid.CreDuration = creToAvoidCreDuration;
        crenauToAvoid.CrenauID = creToAvoidID;

        this.crenauxToAvoidList.push(crenauToAvoid);
      });
    }

    this.selectedCrenauToWish = this.crenauxToWishList.find((c: any) => c.isSelected)

    console.log('crenaux ', this.crenauxToWishList, this.crenauxToAvoidList);
  }

  private async createRequest(request: any) {
    console.log("CREATE request ===>", request);
    try {
      request.FormID = this.formID;
      request.GroupID = this.oDID;
      const response = await this.app.apiService.createRequest(request);
      console.log('RESPONSE CREATE request ===>', response);

      if (response == false) {
        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.confirmMsg.maxDemandeAtteint' + response.Result)
          , 'KO'
          , "alert"
          , () => { }
          , () => { });
        return;
      }

      if ((response && response.Result)) {
        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
          , 'KO'
          , "alert"
          , () => { }
          , () => { });
        return;
      }
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successDemandeDetailCree')
        , 'OK'
        , "alert"
        , () => {
          this.ref?.close(true);/*this.app.sharedTools.redirectTo('/');*/
        }
        , () => { });


    } catch (error) {
      console.error(error);
    }
  }

  private async updateRequest(request: any) {
    try {
      request.RequestID = this.requestID;
      const response = await this.app.apiService.updateRequest(request);
      console.log('RESPONSE UPDATE request ===>', response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
          , 'KO'
          , "alert"
          , () => { }
          , () => { });
        return;
      }
      this.app.confirm.confirmDialog(""
        , this.app.translate.instant('racine.successMsg.successDemaneDetailUpdate')
        , 'OK'
        , "alert"
        , () => {
          this.ref?.close(true);/*this.app.sharedTools.redirectTo('/');*/
        }
        , () => { });
    } catch (error) {
      console.error(error);
    }
  }

  private async getRequest(requestID: number) {
    try {
      const response = await this.app.apiService.getRequest(requestID);
      console.log("getRequest ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog(""
          , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
          , 'KO'
          , "alert"
          , () => { }
          , () => { })
        return;
      }
      this.request = cloneDeep(response);


      setTimeout(() => {
        this.initCrenauxLists();
        this.initRequestActivities();
        //init data edit mode
        //this.initForm();
      }, 500);

    }
    catch (error) {
      console.error(error);
    }
  }

  private addActivityToRequest(activity: any): void {
    this.updateRequestActivity(activity, true);
  }

  private removeActivityFromRequest(activity: any): void {
    this.updateRequestActivity(activity, false);
  }

  private updateRequestActivity(activity: any, value: boolean): void {
    if (activity && activity.ActivityNum) {
      switch (activity.ActivityNum) {
        case 1: {
          if (activity.IntVisible) {
            this.request.ReqIntAct1 = value;
            if (!value) {
              this.request.ReqIntAct1Note = null;
            }
          }
          else if (activity.ExtVisible) {
            this.request.ReqExtAct1 = value;
            if (!value) {
              this.request.ReqExtAct1Note = null;
            }
          }
          break;
        }
        case 2: {
          if (activity.IntVisible) {
            this.request.ReqIntAct2 = value;
            if (!value) {
              this.request.ReqIntAct2Note = null;
            }
          }
          else if (activity.ExtVisible) {
            this.request.ReqExtAct2 = value;
            if (!value) {
              this.request.ReqExtAct2Note = null;
            }
          }
          break;
        }
        case 3: {
          if (activity.IntVisible) {
            this.request.ReqIntAct3 = value;
            if (!value) {
              this.request.ReqIntAct3Note = null;
            }
          }
          else if (activity.ExtVisible) {
            this.request.ReqExtAct3 = value;
            if (!value) {
              this.request.ReqExtAct3Note = null;
            }
          }
          break;
        }
        case 4: {
          if (activity.IntVisible) {
            this.request.ReqIntAct4 = value;
            if (!value) {
              this.request.ReqIntAct4Note = null;
            }
          }
          else if (activity.ExtVisible) {
            this.request.ReqExtAct4 = value;
            if (!value) {
              this.request.ReqExtAct4Note = null;
            }
          }
          break;
        }
        case 5: {
          if (activity.IntVisible) {
            this.request.ReqIntAct5 = value;
            if (!value) {
              this.request.ReqIntAct5Note = null;
            }
          }
          else if (activity.ExtVisible) {
            this.request.ReqExtAct5 = value;
            if (!value) {
              this.request.ReqExtAct5Note = null;
            }
          }
          break;
        }
      }
    }
  }
  formatDateWithLanguage(language: string, crenau: any) {
    return this.datePipe.transform(crenau, 'fullDate', undefined, language);

  }


  private setRequestNote(activity: any, value: string): void {
    let findAct = this.requestActivities.find((a: any) => a.ActivityNum === activity?.ActivityNum);
    if (findAct) {
      findAct.Note = value;
    }

    console.log('setRequestNote ', activity)
    if (activity && activity.ActivityNum) {
      switch (activity.ActivityNum) {
        case 1: {
          if (activity.IntVisible) {
            this.request.ReqIntAct1Note = value;
          }
          else if (activity.ExtVisible) {
            this.request.ReqExtAct1Note = value;
          }
          break;
        }
        case 2: {
          if (activity.IntVisible) {
            this.request.ReqIntAct2Note = value;
          }
          else if (activity.ExtVisible) {
            this.request.ReqExtAct2Note = value;
          }
          break;
        }
        case 3: {
          if (activity.IntVisible) {
            this.request.ReqIntAct3Note = value;
          }
          else if (activity.ExtVisible) {
            this.request.ReqExtAct3Note = value;
          }
          break;
        }
        case 4: {
          if (activity.IntVisible) {
            this.request.ReqIntAct4Note = value;
          }
          else if (activity.ExtVisible) {
            this.request.ReqExtAct4Note = value;
          }
          break;
        }
        case 5: {
          if (activity.IntVisible) {
            this.request.ReqIntAct5Note = value;
          }
          else if (activity.ExtVisible) {
            this.request.ReqExtAct5Note = value;
          }
          break;
        }
      }
    }

    // this.currentActivity = {};
    // this.currentNote = '';
  }

  private initRequestActivities(): void {
    console.log('star initRequestActivities')
    if (this.activities?.length > 0) {
      console.log('initRequestActivities')
      this.requestActivities = [];

      //INTERIOR ACTIVITIES
      this.interiorActivities.forEach((act: any) => {
        let reqAct: ODRequestActivity = {
          //act
          ActivityNum: act.ActivityNum,
          LabelEN: act.IntLabelEN,
          LabelFR: act.IntLabelFR,
          LabelES: act.IntLabelES,
          IntVisible: act.IntVisible,
          ExtVisible: act.ExtVisible,

          //req
          Note: this.request[`ReqIntAct${act.ActivityNum}Note`],
          IsChecked: this.request[`ReqIntAct${act.ActivityNum}`],
        }

        this.requestActivities.push(reqAct);
      });

      //EXTERIOR ACTIVITIES
      this.exteriorActivities.forEach((act: any) => {
        let reqAct: ODRequestActivity = {
          //act
          ActivityNum: act.ActivityNum,
          LabelEN: act.ExtLabelEN,
          LabelFR: act.ExtLabelFR,
          LabelES: act.ExtLabelES,
          IntVisible: act.IntVisible,
          ExtVisible: act.ExtVisible,

          //req
          Note: this.request[`ReqExtAct${act.ActivityNum}Note`],
          IsChecked: this.request[`ReqExtAct${act.ActivityNum}`],
        }

        this.requestActivities.push(reqAct);
      });
    }

    console.log('od request activities', this.requestActivities);
  }


  private orderCompositionContacts(od: any): void {
    let odContactsList: any[] = [];

    if (od.Guest) {
      odContactsList = [od.Guest];
    }

    if (od.Chef) {
      odContactsList = [...odContactsList, od.Chef];
    }

    if (od.Members) {
      odContactsList = [...odContactsList, ...od.Members];
    }

    od.compositionContacts = odContactsList;

    console.log('ctc ', od);
  }
  //#endregion


  selectCrenau(crenau: any) {
    // Désélectionnez tous les autres éléments
    this.crenauxToWishList.forEach(item => {
      item.isSelected = false;
    });

    console.log('selectCrenau ', crenau, this.selectedCrenauToWish, this.selectedCrenauToWish?.CreDay == crenau?.CreDay)

    // Mettez à jour selectedCrenau
    if (this.selectedCrenauToWish?.CreDay == crenau?.CreDay) {
      crenau.isSelected = false;
      this.selectedCrenauToWish = null;
    }
    else {

      // Sélectionnez l'élément actuel
      crenau.isSelected = true;
      this.selectedCrenauToWish = crenau;
    }
  }

  checkSelectedNumber() {
    this.disableCrenauWish = false
    let selectedCreneauNumber = this.crenauxToAvoidList.filter((x: Crenau) => x.isSelected == true).length
    if (selectedCreneauNumber == this.crenauxToAvoidList.length - 1) {
      this.disableCrenauWish = true
    }
  }

}
