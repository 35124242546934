export interface AppGlobalConfig{
    enableMap: boolean;
    enableStandAlone: boolean;
    defaultRowsNumberPerPage: number;
    rowsPerPageOptions: number[];
    mapSettings: any;
}

export const defaultAppGlobalConfig : AppGlobalConfig = {
    enableMap: false,
    enableStandAlone: false,
    defaultRowsNumberPerPage: 50,
    rowsPerPageOptions: [10, 20, 30, 50],
    mapSettings: {
        "imagePath": "./assets",
        "bundleParams": {
            "parameters": {
                "baseURL": "../../assets/VisioMap",
                "hash": ""
            }
        }
    }
}