import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Steps } from 'primeng/steps';
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';
import { FicheOpenMode } from '../enums/fiche-open-mode';

@Component({
  selector: 'app-steps-helped',
  templateUrl: './steps-helped.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./steps-helped.component.less']
})
export class StepsHelpedComponent {
    @ViewChild('stepper', { static: true }) stepper?: Steps;
    user: any;
    stepSwitchPath: any;
    solutionStep: any;
    steps: any = [];
    solutionId: any = null;

    public activeIndex = 5;

    constructor(
        public app: AppComponent,
    ) {
        this.getFormHelpedData();
    }



    ngOnInit() {
        let pathActif = '/' + this.app.router.url.split('/').pop();

        let paths = location.pathname.split('/');

        let currentPath: string = '/' + paths[1];

        console.log('pathActif ==> ', pathActif);
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        this.solutionStep = JSON.parse(sessionStorage.getItem('solutionStep')!);
        console.log("this.user ==> ", this.user);

        this.app.route.queryParams
            .subscribe(params => {
                console.log('[params]', params);
                if (params && params['productid']) {
                    this.solutionId = params['productid'];
                }
            });

        if (this.solutionId)

            this.app.sharedTools.getJSON('general_data').subscribe(
                resG => {
                    this.steps = resG.stepsHelped;

                    if (this.solutionId && this.solutionId != 0) {
                        this.stepSwitchPath = this.steps.filter((stp: any) => { return stp.routerLink == currentPath })[0];
                        console.log('pathActif this.stepSwitchPath  ==> ', this.stepSwitchPath);

                        if (this.stepSwitchPath && this.user) {
                            sessionStorage.setItem('activeSolutionStep', this.stepSwitchPath.StepID);

                            let stepObj: any = {
                                SolutionLastStep: this.stepSwitchPath.StepID - 1,
                                ID: this.solutionId
                            }
                            console.log('stepObj  11 ', stepObj);

                            this.setPreviewStep(stepObj);
                        }
                        else {
                            sessionStorage.removeItem('activeSolutionStep');
                            console.log('stepObj  activeSolutionStep ', this.solutionId);
                            this.getStep(this.solutionId);
                        }
                    }
                });
    }

    async getStep(_solutionID: any) {
        const response = await this.app.apiService.getHelpedSolutionById(_solutionID);
        console.log("[getStep] response API ==> ", response);

        if (response && response.Result) {
            this.app.confirm.confirmDialog(this.app.translate.instant(''), this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
            return;
        }

        let solution: any = cloneDeep(response);
        let stepID: any;

        if (solution && solution.SolutionLastStep) {
            stepID = solution.SolutionLastStep;
            console.log("[getStep] stepID API ==> ", stepID);
            if (stepID != null) sessionStorage.setItem('solutionStep', stepID);
        }
    }

    async setPreviewStep(stepObj: any) {
        
        console.log("[setPreviewStep] stepObj ==> ", stepObj);
        try {
            const response = await this.app.apiService.saveSolutionHelpedLastStep(stepObj);
            console.log("[setPreviewStep] response API ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.getStep(this.solutionId);

        }
        catch (error) {
            console.error(error);
        }
    }

    redirectRoute(step: any) {
        this.solutionStep = JSON.parse(sessionStorage.getItem('solutionStep')!);

        //step.disabled = true;
        console.log("redirectRoute solutionStep ==> ", this.solutionStep);

        if (this.solutionStep >= step.StepID) step.disabled = false;

        console.log("redirectRoute step ==> ", step.disabled);

        if (step.disabled) {
            return;
        }
        else {
            if (this.solutionId) {
                this.routeTo(step.routerLink);
            }
        }
    }

    returnClass(_step: any, _param: any) {
        this.solutionStep = JSON.parse(sessionStorage.getItem('solutionStep')!);
        // console.log("_step ===============>", _step)
        // console.log("_param ===============>", _param)

        if (!_param) return;
        let boolActive: boolean = (this.stepSwitchPath && this.stepSwitchPath.StepID == _step.StepID) ? true : false;
        let boolComplete: boolean = (!this.stepSwitchPath || (this.stepSwitchPath && this.stepSwitchPath.StepID > _step.StepID || (this.solutionStep && this.solutionStep >= _step.StepID))) ? true : false;

        return (_param == "active") ? boolActive : boolComplete;
    }

    //#region private methods
    private getFormHelpedData(): void {
        let helpedform: any = JSON.parse(sessionStorage.getItem('helped-form') ?? '{}');

        if (helpedform) {
            //this.folderID = helpedform.FolderID;
            //console.log('this.solutionId ', this.solutionId)
        }
    }

    private routeTo(route: string): void {
        let queryparams: any = {
            productid: this.solutionId
        }
        this.app.sharedTools.redirectToWithParameters(`${route}/${FicheOpenMode.Edit}`, queryparams);
    }
    //     //extract mode from route (edit or consult)
    //     const url = this.app.router.url;
    //     const segments = url.split('/');
    //     const segmentWithQueryParams = segments[segments.length - 1];
    //     const mode = segmentWithQueryParams.split('?')[0];

    //     console.log('mode ', mode); // This should log 'consult' or 'edit'

    //     if( (mode === FicheOpenMode.Consult || mode === FicheOpenMode.Edit)
    //         && (route === '/product-generalites' || route === '/product-recapitulatif') ){
    //         let queryparams: any = {
    //             productid: this.solutionId
    //         }
    //         this.app.sharedTools.redirectToWithParameters(`${route}/${mode}` , queryparams);
    //     }
    //     else{

    //     }
    //#endregion
}

