import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { StatusCode } from '../enums/status-code';
import { DateHelper } from '../helpers/date.helper';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-parametrage-commune',
  templateUrl: './parametrage-commune.component.html',
  styleUrls: ['./parametrage-commune.component.less']
})
export class ParametrageCommuneComponent implements OnInit, AfterViewInit {

  @Input() status: any

  @Input() parameterData: any

  @Input() submitted: any

  @Output() timeValue = new EventEmitter<any>()

  @Output() cancelEvent = new EventEmitter<any>()
  @Output() isValid = new EventEmitter<any>()
  @Output() isInValid = new EventEmitter<boolean>()



  parameterForm !: FormGroup

  selectedStatus: any



  constructor(public app: AppComponent, private _formBuilder: FormBuilder, private datePipe: DatePipe) { }

  ngAfterViewInit(): void {
    this.initForm(this.parameterData)
  }

  ngOnInit(): void {
    console.log(this.parameterData)
    if (this.parameterData != undefined) {
      this.selectedStatus = this.status.filter((x: any) => x.StatusID == this.parameterData.StatusID)[0]
      this.initForm(this.parameterData)
    } else {
      this.initForm()
    }
  }

  initForm(data?: any) {
    if (data) {
      this.parameterForm = this._formBuilder.group({
        labelFR: new FormControl(data.LabelFR, Validators.required),
        labelEN: new FormControl(data.LabelEN, Validators.required),
        labelES: new FormControl(data.LabelES, Validators.required),
        formNumber: new FormControl(data.FormNumber, Validators.required),
        externalCode: new FormControl(data.CodeExterne),
        selectedStatus: new FormControl(this.selectedStatus, Validators.required),
        formDueDate: new FormControl(new Date(data.FormDueDate), Validators.required),
        isManadatory: new FormControl(data.isManadatory),
        formOpeningDate: new FormControl(new Date(data.FormOpeningDate), Validators.required),
        formOpeningTime: new FormControl(data.FormOpeningDate?.split("T")[1]),
        formClosingDate: new FormControl(new Date(data.FormClosingDate), Validators.required),
        formClosingTime: new FormControl(data.FormClosingDate?.split("T")[1]),
        supportName: new FormControl(data.SupportName),
        supportEmail: new FormControl(data.SupportEmail),
        supportTel: new FormControl(data.SupportTel),
      })
    } else {
      this.parameterForm = this._formBuilder.group({
        labelFR: new FormControl(null, Validators.required),
        labelEN: new FormControl(null, Validators.required),
        labelES: new FormControl(null, Validators.required),
        formNumber: new FormControl(null, Validators.required),
        externalCode: new FormControl(null),
        selectedStatus: new FormControl(null, Validators.required),
        formDueDate: new FormControl(null, Validators.required),
        isManadatory: new FormControl(null),
        formOpeningDate: new FormControl(null, Validators.required),
        formOpeningTime: new FormControl(null),
        formClosingDate: new FormControl(null, Validators.required),
        formClosingTime: new FormControl(null),
        supportName: new FormControl(null),
        supportEmail: new FormControl(null),
        supportTel: new FormControl(null),
      })
    }
  }


  getColorStatus(status: any) {
    let classColor = "";
    if (status) {
      switch (status.Code) {
        case StatusCode.FORM_OPENED:
          classColor = "color-lime-green"
          break;

        case StatusCode.FORM_CLOSED:
          classColor = "color-red"
          break;

          case StatusCode.FORM_LOCKED:
            classColor = "color-orange"
            break;
      }
    }
    return classColor;
  }

  timeChanged() {
    let horaireOuverture = this.parameterForm.get("formOpeningTime")?.value
    let horaireFermeture = this.parameterForm.get("formClosingTime")?.value
    let obj = { horaireOuverture: horaireOuverture, horaireFermeture: horaireFermeture }
    this.timeValue.emit(obj)
  }

  annuler() {
    this.cancelEvent.emit(true)
  }

  submitForm() {
    this.submitted = true
    console.log(this.parameterForm.invalid)
    if (this.parameterForm.invalid) {
      this.isInValid.emit(false)
    } else {
      let object = this.mapFormIntoObject()
      console.log(object)
      this.isValid.emit(object)
    }
  }


  mapFormIntoObject() {
    let dateFin = DateHelper.concatDateOnlyTimeOnly(
      this.datePipe.transform(this.parameterForm.get("formClosingDate")?.value, DateHelper.ApiDateFormat) ?? ''
      , this.parameterForm.get("formClosingTime")?.value)

    let dateDebut = DateHelper.concatDateOnlyTimeOnly(
      this.datePipe.transform(this.parameterForm.get("formOpeningDate")?.value, DateHelper.ApiDateFormat) ?? ''
      , this.parameterForm.get("formOpeningTime")?.value)
    

      let DueDate = this.datePipe.transform(this.parameterForm.get("formDueDate")?.value, DateHelper.ApiDateFormat)

    return {
      LabelFR: this.parameterForm.get("labelFR")?.value,
      LabelEN: this.parameterForm.get("labelEN")?.value,
      LabelES: this.parameterForm.get("labelES")?.value,
      FormNumber: this.parameterForm.get("formNumber")?.value,
      CodeExterne: this.parameterForm.get("externalCode")?.value,
      StatusID: this.parameterForm.get("selectedStatus")?.value?.StatusID,
      FormDueDate: DueDate,
      FormOpeningDate: dateDebut,
      FormClosingDate: dateFin,
      SupportName: this.parameterForm.get("supportName")?.value,
      SupportEmail: this.parameterForm.get("supportEmail")?.value,
      SupportTel: this.parameterForm.get("supportTel")?.value,
      isManadatory: this.parameterForm.get("isManadatory")?.value,
    }
  }

}
