export class DateHelper{
    //#region properties
    public static readonly ApiDateTimeFormat : string = 'yyyy-MM-ddTHH:mm:ss';
    public static readonly ApiDateFormat : string = 'yyyy-MM-dd';
    public static readonly ApiTimeFormat : string = 'HH:mm:ss';

    public static readonly MidnightTime : string = '00:00:00';
    public static readonly DateTimeSeparator : string = 'T';
    //#region properties

    //#region methods
    public static extractTime(date: string): string {
        let hour: string = DateHelper.MidnightTime;
        if (date) {
            const position = date.indexOf(DateHelper.DateTimeSeparator);
            if (position !== -1) {
                // Get the substring after the character T
                hour = date.substring(position + 1);
            }
        }
        return hour;
    }

    public static extractDate(date: string): string {
        let hour: string = DateHelper.MidnightTime;
        if (date) {
            const position = date.indexOf(DateHelper.DateTimeSeparator);
            if (position !== -1) {
                // Get the substring after the character T
                hour = date.substring(0, position);
            }
        }
        return hour;
    }

    public static updateTime(date: string, hour: string): string {
        if (date && hour) {
            const position = date.indexOf(DateHelper.DateTimeSeparator);
            if (position !== -1) {
                // Get the substring after the character T
                date = `${DateHelper.extractDate(date)}${DateHelper.DateTimeSeparator}${hour}`;
            }
        }
        return date;
    }

    public static concatDateTime(date: string, hour: string): string | undefined {
        let dateTime: string | undefined;
        if (date && hour) {
            dateTime = `${DateHelper.extractDate(date)}${DateHelper.DateTimeSeparator}${hour}`;
        }
        return dateTime;
    }

    public static greaterOrEqualTimeStrings(timeString1: string, timeString2: string): boolean {
        if(!timeString1 || !timeString2){
            return true;
        }

        const time1 = new Date(`2000-01-01T${timeString1}`);
        const time2 = new Date(`2000-01-01T${timeString2}`);

        console.log(time1, time2 , time1 >= time2)
    
        return time1 >= time2;
    }

    public static greaterOrEmptyTimeStrings(timeString1: string, timeString2: string): boolean {
        if(!timeString1 || !timeString2 || (timeString1 == DateHelper.MidnightTime && timeString2 == DateHelper.MidnightTime) ){
            return true;
        }

        const time1 = new Date(`2000-01-01T${timeString1}`);
        const time2 = new Date(`2000-01-01T${timeString2}`);

        console.log(time1, time2 , time1 >= time2)
    
        return time1 > time2;
    }


    public static concatDateOnlyTimeOnly(date: string, hour: string): string | undefined {
        let dateTime: string | undefined;
        if (date && hour) {
            dateTime = `${date}${DateHelper.DateTimeSeparator}${hour}`;
        }
        return dateTime;
    }
    //#endregion methods
}