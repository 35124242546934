import { Component } from '@angular/core';

@Component({
  selector: 'app-media-cmp-upgrade',
  templateUrl: './media-cmp-upgrade.component.html',
  styleUrls: ['./media-cmp-upgrade.component.less']
})
export class MediaCmpUpgradeComponent {

}
