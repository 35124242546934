import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { IdentityManagerService } from '../services/identity-manager.service';
import { cloneDeep } from 'lodash';
import { APPID } from '../enums/app_id';
import { TranslatePipe } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { FormCode } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-welcome-helped',
  templateUrl: './welcome-helped.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./welcome-helped.component.less']
})
export class WelcomeHelpedComponent {
    user: any = null;
    appID:any = null;
    welcomeObj: any = null;
    folderGuid: any;
    helpedform: any;
    frmData : any;

    constructor(
        public app: AppComponent,
        private identityManagerService: IdentityManagerService,
        private translatePipe: TranslatePipe,
        private datePipe: DatePipe
    ) { }
    ngOnInit() {
        console.log(this.app.route);
        this.app.route.queryParams.subscribe(params => {
            console.log('p ',params)
            this.folderGuid = params['folderguid'];
          console.log(`Param Value: folder ${this.folderGuid}`);
        });
        this.identityManagerService.checkIsUserAuthenticated();
        this.user = cloneDeep(this.identityManagerService.currentUser);

        // this.app.sharedTools.changeUsrLangInInit();
        // this.app.sharedTools.getCurrentLanguage();

        sessionStorage.removeItem('folderStep');
        sessionStorage.removeItem('activeStep');

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {                
                this.welcomeObj = {
                    HelpFormID: null,
                    Contact : {
                        FirstName: this.user?.Contact?.FirstName,
                        LastName: this.user?.Contact?.LastName
                    },
                    DateFin: null,
                    DateOpen :null,
                    IsOpen: null,
                }
                this.welcomeHelpedForm();
            });

        this.appID = APPID.Helped;
        this.getHelpedInfo();
    
    }
    async welcomeHelpedForm() {
        let request: any = {
            FolderGuid: this.folderGuid
        }
        
        let res = await this.app.apiService.welcomeHelpedForm(request);
        if(res){
            console.log("🚀  welcomeHelpedForm : ", res);
            this.helpedform = res;
            sessionStorage.setItem('helped-form', JSON.stringify(this.helpedform));
            this.welcomeObj.Edition = this.helpedform.Edition;
            this.welcomeObj.ContactLastName = this.helpedform.ContactLastName;
            this.welcomeObj.ContactFirstName = this.helpedform.ContactFirstName;
            this.welcomeObj.ContactFullName = this.helpedform.ContactFullName;
            this.welcomeObj.DateFin = this.helpedform.DateFin;
            this.welcomeObj.DateOpen = this.helpedform.DateOpen;
            this.welcomeObj.IsOpen = this.helpedform.IsOpen;
            this.welcomeObj.DateDeadLine=this.helpedform.DateDeadLine;
            this.welcomeObj.ShowDeadlineDate = this.helpedform.ShowDeadlineDate
            
        }
      }

      next(): void{
        sessionStorage.setItem('folderGuid', JSON.stringify(this.folderGuid));
        this.app.sharedTools.redirectTo('/products-helped');
      }

      public replaceTokens(text: string): string{
        if(!text){
            text = '';
        }

        text = this.translatePipe.transform(text) ?? '';
        text = text.replace('{firstname}', this.welcomeObj.ContactFirstName??'');
        text = text.replace('{lastname}', this.welcomeObj.ContactLastName??'');
        text = text.replace('{fullname}', this.welcomeObj.ContactFullName??'');
        text = text.replace('{edition}',  this.welcomeObj.Edition??'');
        text = text.replace('{openingdate}', this.datePipe.transform(this.welcomeObj.DateOpen, 'dd MMMM yyyy', undefined, this.app.translate.currentLang) ??'');
        text = text.replace('{closingdate}', this.datePipe.transform(this.welcomeObj.DateFin, 'dd MMMM yyyy', undefined, this.app.translate.currentLang) ??'');
        text = text.replace('{echeancedate}',   this.datePipe.transform(this.welcomeObj.DateDeadLine, 'dd MMMM yyyy', undefined, this.app.translate.currentLang) ??'');

        return text;
      }

      async getHelpedInfo() {
          let request = { frmType: "" }
          request.frmType = FormCode.FRM_HLP;
          let data = await firstValueFrom(this.app.apiService.getEditionInfo(request));
          if (data != undefined && data.Id != undefined) {
              this.frmData = data;
          }
      }
}
