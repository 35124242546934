export class SearchParticipant{
    //AttendeeID: number | undefined;
    BadgeNumber : string | undefined;
    BadgeType : any | undefined;
    AttendeeLastName : string | undefined
    Email : string | undefined
    CompanyName : string | undefined
    RoleID : any | undefined
    ValidationStatusID : any | undefined
    ExpertInCategoriesIds : number[] | undefined
    InterestCenterCategoriesIds  : number[] | undefined
    NetworkingStatusID : any | undefined
    NbElementsPerPage : number | undefined
    PageIndex : number | undefined
}