import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData, DatePipe } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
//import { TranslateService } from '@ngx-translate/core';
import { InterceptorService } from './services/interceptor.service';
import { ApiService } from './services/api.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GoogleMapsModule } from '@angular/google-maps'

import { AccordionModule } from 'primeng/accordion';
import { AnimateModule } from 'primeng/animate';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
// import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
// import { CascadeSelectModule } from 'primeng/cascadeselect';
// import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
// import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
// import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule, DataViewLayoutOptions } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
// import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
// import { InplaceModule } from 'primeng/inplace';
// import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
// import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
// import { KeyFilterModule } from 'primeng/keyfilter';
// import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
// import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
// import { OrderListModule } from 'primeng/orderlist';
// import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
// import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
// import { RatingModule } from 'primeng/rating';
// import { RippleModule } from 'primeng/ripple';
// import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { TimelineModule } from 'primeng/timeline';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { StepsModule } from 'primeng/steps';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

import { ToolbarComponent } from './toolbar/toolbar.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { LoginComponent } from './login/login.component';
import { GestionDpComponent } from './gestion-dp/gestion-dp.component';
import { DialogComponent } from './dialog/dialog.component';
import { DetailDpComponent } from './detail-dp/detail-dp.component';
import { CreateDpComponent } from './create-dp/create-dp.component';
import { FoIntroComponent } from './fo-intro/fo-intro.component';
import { FooterComponent } from './footer/footer.component';
import { InfoExposantComponent } from './info-exposant/info-exposant.component';
import { StepsComponent } from './steps/steps.component';
import { Router, RouterModule } from "@angular/router";
import { RappelMdpComponent } from './rappel-mdp/rappel-mdp.component';
import { ResetMdpComponent } from './reset-mdp/reset-mdp.component';
import { InputSwitchModule } from "primeng/inputswitch";
import { ExpoIndirectComponent } from './expo-indirect/expo-indirect.component';
import { PrestationsComponent } from './prestations/prestations.component';
import { SidebarCommandeComponent } from './sidebar-commande/sidebar-commande.component';
import { ContentRecapComponent } from './content-recap/content-recap.component';
import { RecapitulatifComponent } from './recapitulatif/recapitulatif.component';
import { ContratComponent } from './contrat/contrat.component';
import { SignatureComponent } from './signature/signature.component';

//import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { ErrorPageComponent } from './error-page/error-page.component';
import { ThanksPageComponent } from './thanks-page/thanks-page.component';
import { ClosedFolderPageComponent } from './closed-folder-page/closed-folder-page.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { LowerCaseDirective } from './directives/lower-case.directive';
import { LettersOnlyDirective } from './directives/letters-only.directive';
import { GestionUserComponent } from './gestion-user/gestion-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { GestionRoleComponent } from './gestion-role/gestion-role.component';
import { CreateRoleComponent } from './create-role/create-role.component';
import { TraductionsComponent } from './traductions/traductions.component';
import { GestionGroupComponent } from './gestion-group/gestion-group.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { GestionVmUserComponent } from './gestion-vm-user/gestion-vm-user.component';
import { CreateVmUserComponent } from './create-vm-user/create-vm-user.component';
import { GestionOtherVisitComponent } from './gestion-other-visit/gestion-other-visit.component';
import { CreateOtherVisitComponent } from './create-other-visit/create-other-visit.component';
import { TabNavigationComponent } from './tab-navigation/tab-navigation.component';
import { AdminExposantsComponent } from './admin-exposants/admin-exposants.component';
import { AdminExposantsDetailComponent } from './admin-exposants-detail/admin-exposants-detail.component';
import { GestionVmExposantComponent } from './gestion-vm-exposant/gestion-vm-exposant.component';
import { CreateVmExposantComponent } from './create-vm-exposant/create-vm-exposant.component';
import { CreateVmDetailDoComponent } from './create-vm-detail-do/create-vm-detail-do.component';
import { GestionVmDoComponent } from './gestion-vm-do/gestion-vm-do.component';
import { GestionVmManageDoComponent } from './gestion-vm-manage-do/gestion-vm-manage-do.component';
import { WelcomeF18Component } from './welcome-f18/welcome-f18.component';
import { NavbarF18Component } from './navbar-f18/navbar-f18.component';
import { WelcomeHelpedComponent } from './welcome-helped/welcome-helped.component';
import { PlanningDOComponent } from './planning-do/planning-do.component';
import { PlanningExposantsComponent } from './planning-exposants/planning-exposants.component';
import { GestionVmProgDemandesComponent } from './gestion-vm-prog-demandes/gestion-vm-prog-demandes.component';
import { FederateurListWindowComponent } from './federateur-list-window/federateur-list-window.component';
import { FoF18Etat2Sur2Component } from './fo-f18-etat2-sur2/fo-f18-etat2-sur2.component';
import { DemandDetailComponent } from './demand-detail/demand-detail.component';
import { WelcomeInnovComponent } from './welcome-innov/welcome-innov.component';
import { WelcomeStepsSupportsComponent } from './welcome-steps-supports/welcome-steps-supports.component';
import { WelcomeStepsNumbersComponent } from './welcome-steps-numbers/welcome-steps-numbers.component';
import { ListeProductsInnovComponent } from './liste-products-innov/liste-products-innov.component';
import { ProductsCarouselComponent } from './products-carousel/products-carousel.component';
import { ProductsListDataviewComponent } from './products-list-dataview/products-list-dataview.component';
import { StepsInnovComponent } from './steps-innov/steps-innov.component';
import { FormProduitGeneralitesComponent } from './form-produit-generalites/form-produit-generalites.component';
import { FormProduitDescriptionComponent } from './form-produit-description/form-produit-description.component';
import { FormProduitMediasComponent } from './form-produit-medias/form-produit-medias.component';
import { FormProduitContactComponent } from './form-produit-contact/form-produit-contact.component';
import { FormProduitRecapComponent } from './form-produit-recap/form-produit-recap.component';
import { ContentRecapInnovComponent } from './content-recap-innov/content-recap-innov.component';
import { GestionInnovComponent } from './gestion-innov/gestion-innov.component';
import { DetailInnovComponent } from './detail-innov/detail-innov.component';
import { EditSelectedDOComponent } from './edit-selected-do/edit-selected-do.component';
import { DialogService } from 'primeng/dynamicdialog';
import { FoF18Etat1Sur2Component } from './fo-f18-etat1-sur2/fo-f18-etat1-sur2.component';
import { GestionDesEditionsComponent } from './gestion-des-editions/gestion-des-editions.component';
import { CreateEditionComponent } from './create-edition/create-edition.component';
import { AdminDelegationComponent } from './admin-delegation/admin-delegation.component';
import { MediaCmpComponent } from './medias-components/media-cmp/media-cmp.component';
import { ContactInnovComponent } from './contact-innov/contact-innov.component';
import { ListMediaComponent } from './medias-components/list-media/list-media.component';
import { MediaCmpUpgradeComponent } from './medias-components/media-cmp-upgrade/media-cmp-upgrade.component';
import { ListMediaUpgradeComponent } from './medias-components/list-media-upgrade/list-media-upgrade.component';

import { GestionContatsComponent } from './gestion-contats/gestion-contats.component';
import { SSOLoginComponent } from './ssologin/ssologin.component';
import { innovSolutionGuard } from './guards/innov-solution.guard';

import { StatistiquesComponent } from './statistiques/statistiques.component';
import { ChartModule } from 'primeng/chart';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { KnobModule } from 'primeng/knob';
import { AdminBoardComponent } from './admin-board/admin-board.component';
import { NotAuthorizedPageComponent } from './not-authorized-page/not-authorized-page.component';
import { ListeExposantsComponent } from './liste-exposants/liste-exposants.component';
import { PlanningHTMLOptionsComponent } from './planning-htmloptions/planning-htmloptions.component';
import { HtmlPlanningRapportDoComponent } from './html-planning-rapport-do/html-planning-rapport-do.component';
import { CustomDialogComponent } from './custom-dialog/custom-dialog.component';
import { WelcomeMktComponent } from './welcome-mkt/welcome-mkt.component';
import { ParametrageVMComponent } from './parametrage-vm/parametrage-vm.component';
import { ParametrageCommuneComponent } from './parametrage-commune/parametrage-commune.component';
import { ParametrageInnovationComponent } from './parametrage-innovation/parametrage-innovation.component';
import { GestionHelpedComponent } from './gestion-helped/gestion-helped.component';
import { ListeProductsHelpedComponent } from './liste-products-helped/liste-products-helped.component';
import { ProductsCarouselHelpedComponent } from './products-carousel-helped/products-carousel-helped.component';
import { ProductsListDataviewHelpedComponent } from './products-list-dataview-helped/products-list-dataview-helped.component';
import { FormHelpedDescriptionComponent } from './form-helped-description/form-helped-description.component';
import { FormHelpedMediasComponent } from './form-helped-medias/form-helped-medias.component';
import { FormHelpedRecapComponent } from './form-helped-recap/form-helped-recap.component';
import { ContentRecapHelpedComponent } from './content-recap-helped/content-recap-helped.component';
import { FormHelpedGeneralitesComponent } from './form-helped-generalites/form-helped-generalites.component';
import { StepsHelpedComponent } from './steps-helped/steps-helped.component';
import { MemberBoardComponent } from './member-board/member-board.component';
import { RequestDetailComponent } from './request-detail/request-detail.component';
import { FormHelpedContactComponent } from './form-helped-contact/form-helped-contact.component';
import { ParametrageHelpedComponent } from './parametrage-helped/parametrage-helped.component';
import { DetailHelpedComponent } from './detail-helped/detail-helped.component';
import localeEs from '@angular/common/locales/es';
import { Languages } from './enums/language';
import { MktSocialMediaComponent } from './welcome-mkt-cmp/mkt-social-media/mkt-social-media.component';
import { MktEmailSignatureComponent } from './welcome-mkt-cmp/mkt-email-signature/mkt-email-signature.component';
import { MktWebsiteComponent } from './welcome-mkt-cmp/mkt-website/mkt-website.component';
import { MktAdvertisementsComponent } from './welcome-mkt-cmp/mkt-advertisements/mkt-advertisements.component';
import { ExportZipHtmlOptionsComponent } from './exportzip-htmloptions/exportzip-htmloptions.component';
import { LogoCmpComponent } from './medias-components/logo-cmp/logo-cmp.component';
import { ImageCmpComponent } from './medias-components/image-cmp/image-cmp.component';
import { MktInformCmpComponent } from './welcome-mkt-cmp/mkt-inform-cmp/mkt-inform-cmp.component';
import { PreviewSocialMediaPostComponent } from './preview-social-media-post/preview-social-media-post.component';
import { ListeAttendeesComponent } from './liste-attendees/liste-attendees.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ListeConferencesComponent } from './liste-conferences/liste-conferences.component';
import { ListeNewsComponent } from './liste-news/liste-news.component';
import { WelcomeConfComponent } from './welcome-conference/welcome-conf/welcome-conf.component';
import { ConfTabsComponent } from './welcome-conference/welcome-conf-cmp/conf-tabs/conf-tabs.component';
import { ConfFilterComponent } from './welcome-conference/welcome-conf-cmp/conf-filter/conf-filter.component';
import { ConfCardDetailsComponent } from './welcome-conference/welcome-conf-cmp/conf-card-details/conf-card-details.component';
import { ConfDetailsMiniCardComponent } from './welcome-conference/welcome-conf-cmp/conf-details-mini-card/conf-details-mini-card.component';
import { ConfDetailsComponent } from './welcome-conference/conf-details/conf-details.component';
import { WelcomeConfSpeakerComponent } from './welcome-speaker/welcome-conf-speaker/welcome-conf-speaker.component';
import { SpeakerDetailsComponent } from './welcome-speaker/speaker-details/speaker-details.component';
import { SpeakerSessionsComponent } from './welcome-speaker/speaker-sessions/speaker-sessions.component';
import { SpeakerCardDetailsComponent } from './welcome-speaker/speaker-card-details/speaker-card-details.component';
import { CreateVmProgramComponent } from './create-vm-program/create-vm-program.component';
import { WelcomeProgramComponent } from './welcome-program/welcome-program.component';
import { ListeProductsProgramComponent } from './liste-products-program/liste-products-program.component';
import { ProductsCarouselProgramComponent } from './products-carousel-program/products-carousel-program.component';
import { ProductsListDataviewProgramComponent } from './products-list-dataview-program/products-list-dataview-program.component';
import { FormProgramRecapComponent } from './form-program-recap/form-program-recap.component';
import { FormProgramGeneralitiesComponent } from './form-program-generalities/form-program-generalities.component';
import { SommaireEspaceCommunicationComponent } from './sommaire-espace-communication/sommaire-espace-communication.component';
import { WelcomeSpeakerMktComponent } from './welcome-speaker-mkt/welcome-speaker-mkt.component';
import { TagComponent } from './welcome-conference/welcome-conf-cmp/tag/tag.component';
import { MapViewComponent } from './map-view/map-view.component';
import { ConfMapComponent } from './welcome-conference/conf-map/conf-map.component';
import { WelcomeExhibitorComponent } from './welcome-exhibitors/welcome-exhibitor/welcome-exhibitor.component';
import { ExhibitorDetailsComponent } from './welcome-exhibitors/exhibitor-details/exhibitor-details.component';
import { ExhibitorFilterComponent } from './welcome-exhibitors/welcome-exhibitor-cmp/exhibitor-filter/exhibitor-filter.component';
import { MiniCardExhibitorComponent } from './welcome-exhibitors/welcome-exhibitor-cmp/mini-card-exhibitor/mini-card-exhibitor.component';
import { MiniCardExhibitorDetailsComponent } from './welcome-exhibitors/welcome-exhibitor-cmp/mini-card-exhibitor-details/mini-card-exhibitor-details.component';
import { ExhibitorDetailsConferenceComponent } from './welcome-exhibitors/welcome-exhibitor-cmp/exhibitor-details-conference/exhibitor-details-conference.component';
import { ExhibitorDetailsSolutionsComponent } from './welcome-exhibitors/welcome-exhibitor-cmp/exhibitor-details-solutions/exhibitor-details-solutions.component';
import { DetailActualiteComponent } from './detail-actualite/detail-actualite.component';
import { NavMenuComponent } from './welcome-conference/welcome-conf-cmp/nav-menu/nav-menu.component';
import { WelcomeSolComponent } from './welcome-solution/welcome-sol/welcome-sol.component';
import { SolutionDetailsComponent } from './welcome-solution/solution-details/solution-details.component';
import { CardSolComponent } from './welcome-solution/components/card-sol/card-sol.component';
import { CardLocationComponent } from './welcome-solution/components/card-location/card-location.component';
import { CommuniquePressComponent } from './communique-press/communique-press.component';
import { CommuniquePressListDataviewComponent } from './communique-press-list-dataview/communique-press-list-dataview.component';
import { DetailCommuniquePressComponent } from './detail-communique-press/detail-communique-press.component';
import { MiniCardSolutionDetailsComponent } from './welcome-solution/components/mini-card-solution-details/mini-card-solution-details.component';
import { SharedtoolsService } from './services/sharedtools.service';
import { AppConfiguration } from './app.configuration';
import { FlagComponent } from './flag/flag.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AwardsFormDescriptionInfoComponent } from './awards-module/awards-form-description-info/awards-form-description-info.component';
import { StepsAwardsFormComponent } from './awards-module/steps-awards-form/steps-awards-form.component';
import { RecapAwardsFormComponent } from './awards-module/recap-awards-form/recap-awards-form.component';
import { AwardsRecapPageComponent } from './awards-module/awards-recap-page/awards-recap-page.component';
import { FormAwardsCompanyInfoComponent } from './awards-module/form-awards-company-info/form-awards-company-info.component';
import { AwardsWelcomePageComponent } from './awards-module/awards-welcome-page/awards-welcome-page.component';
import { AwardsFormGeneralitesComponent } from './awards-module/awards-form-generalites/awards-form-generalites.component';
import { ListeProductsAwardComponent } from './awards-module/liste-products-award/liste-products-award.component';
import { AwardsToolbarComponent } from './awards-module/awards-toolbar/awards-toolbar.component';
import { AwardsBoListComponent } from './awards-module/awards-bo-list/awards-bo-list.component';
import { AwardsBoDetailFicheComponent } from './awards-module/awards-bo-detail-fiche/awards-bo-detail-fiche.component';
import { ParametrageAwardsComponent } from './awards-module/parametrage-awards/parametrage-awards.component';
import { ConfReplayButtonComponent } from './welcome-conference/welcome-conf-cmp/conf-replay-button/conf-replay-button.component';

registerLocaleData(localeFr);
registerLocaleData(localeEs, Languages.Spanish)
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [
        AppComponent,
        ToolbarComponent,
        SidenavComponent,
        LoginComponent,
        GestionDpComponent,
        DialogComponent,
        DetailDpComponent,
        CreateDpComponent,
        FoIntroComponent,
        FooterComponent,
        InfoExposantComponent,
        StepsComponent,
        RappelMdpComponent,
        ResetMdpComponent,
        RappelMdpComponent,
        ResetMdpComponent,
        PrestationsComponent,
        SidebarCommandeComponent,
        ContentRecapComponent,
        RecapitulatifComponent,
        ResetMdpComponent,
        ExpoIndirectComponent,
        ContratComponent,
        SignatureComponent,
        ErrorPageComponent,
        ThanksPageComponent,
        ClosedFolderPageComponent,
        SafeUrlPipe,
        LowerCaseDirective,
        LettersOnlyDirective,
        GestionUserComponent,
        CreateUserComponent,
        GestionRoleComponent,
        CreateRoleComponent,
        TraductionsComponent,
        GestionGroupComponent,
        CreateGroupComponent,
        GestionVmUserComponent,
        CreateVmUserComponent,
        GestionOtherVisitComponent,
        CreateOtherVisitComponent,
        TabNavigationComponent,
        AdminExposantsComponent,
        AdminExposantsDetailComponent,
        GestionVmExposantComponent,
        CreateVmExposantComponent,
        CreateVmDetailDoComponent,
        GestionVmDoComponent,
        GestionVmManageDoComponent,
        WelcomeF18Component,
        NavbarF18Component,
        WelcomeHelpedComponent,
        PlanningDOComponent,
        PlanningExposantsComponent,
        GestionVmProgDemandesComponent,
        FederateurListWindowComponent,
        FoF18Etat2Sur2Component,
        DemandDetailComponent,
        WelcomeInnovComponent,
        WelcomeStepsSupportsComponent,
        WelcomeStepsNumbersComponent,
        ListeProductsInnovComponent,
        ProductsCarouselComponent,
        ProductsListDataviewComponent,
        FormProduitGeneralitesComponent,
        StepsInnovComponent,
        FormProduitDescriptionComponent,
        FormProduitMediasComponent,
        FormProduitContactComponent,
        FormProduitRecapComponent,
        ContentRecapInnovComponent,
        GestionInnovComponent,
        DetailInnovComponent,
        EditSelectedDOComponent,
        FoF18Etat1Sur2Component,
        GestionDesEditionsComponent,
        CreateEditionComponent,
        AdminDelegationComponent,
        MediaCmpComponent,
        ContactInnovComponent,
        ListMediaComponent,
        MediaCmpUpgradeComponent,
        ListMediaUpgradeComponent,
        GestionContatsComponent,
        SSOLoginComponent,
        StatistiquesComponent,
        AdminBoardComponent,
        NotAuthorizedPageComponent,
        ListeExposantsComponent,
        PlanningHTMLOptionsComponent,
        HtmlPlanningRapportDoComponent,
        CustomDialogComponent,
        WelcomeMktComponent,
        ParametrageVMComponent,
        ParametrageCommuneComponent,
        ParametrageInnovationComponent,
        GestionHelpedComponent,
        ListeProductsHelpedComponent,
        ProductsCarouselHelpedComponent,
        ProductsListDataviewHelpedComponent,
        FormHelpedDescriptionComponent,
        FormHelpedMediasComponent,
        FormHelpedRecapComponent,
        ContentRecapHelpedComponent,
        FormHelpedGeneralitesComponent,
        StepsHelpedComponent,
        MktSocialMediaComponent,
        MktEmailSignatureComponent,
        MktWebsiteComponent,
        MktAdvertisementsComponent,
        MemberBoardComponent,
        RequestDetailComponent,
        FormHelpedContactComponent,
        ParametrageHelpedComponent,
        DetailHelpedComponent,
        ParametrageHelpedComponent,
        DetailHelpedComponent,
        RequestDetailComponent,
        ParametrageHelpedComponent,
        DetailHelpedComponent,
        ParametrageHelpedComponent,
        DetailHelpedComponent,
        RequestDetailComponent,
        ExportZipHtmlOptionsComponent,
        LogoCmpComponent,
        ImageCmpComponent,
        MktInformCmpComponent,
        PreviewSocialMediaPostComponent,
        ListeAttendeesComponent,
        ListeConferencesComponent,
        ListeNewsComponent,
        WelcomeConfComponent,
        ConfTabsComponent,
        ConfFilterComponent,
        ConfCardDetailsComponent,
        ConfDetailsMiniCardComponent,
        ConfDetailsComponent,
        WelcomeConfSpeakerComponent,
        SpeakerDetailsComponent,
        SpeakerSessionsComponent,
        SpeakerCardDetailsComponent,
        CreateVmProgramComponent,
        WelcomeProgramComponent,
        ListeProductsProgramComponent,
        ProductsCarouselProgramComponent,
        ProductsListDataviewProgramComponent,
        FormProgramRecapComponent,
        FormProgramGeneralitiesComponent,
        SommaireEspaceCommunicationComponent,
        WelcomeSpeakerMktComponent,
        TagComponent,
        MapViewComponent,
        ConfMapComponent,
        WelcomeExhibitorComponent,
        ExhibitorDetailsComponent,
        ExhibitorFilterComponent,
        MiniCardExhibitorComponent,
        MiniCardExhibitorDetailsComponent,
        ExhibitorDetailsConferenceComponent,
        ExhibitorDetailsSolutionsComponent,
        DetailActualiteComponent,
        NavMenuComponent,
        WelcomeSolComponent,
        SolutionDetailsComponent,
        CardSolComponent,
        CardLocationComponent,
        CommuniquePressComponent,
        CommuniquePressListDataviewComponent,
        DetailCommuniquePressComponent,
        MiniCardSolutionDetailsComponent,
        FlagComponent,
        NavbarComponent,
        AwardsFormDescriptionInfoComponent,
        StepsAwardsFormComponent,
        RecapAwardsFormComponent,
        AwardsRecapPageComponent,
        FormAwardsCompanyInfoComponent,
        AwardsWelcomePageComponent,
        AwardsFormGeneralitesComponent,
        ListeProductsAwardComponent,
        AwardsToolbarComponent,
        AwardsBoListComponent,
        AwardsBoDetailFicheComponent,
        ParametrageAwardsComponent,
        ConfReplayButtonComponent,
    ],
    imports: [
        //GooglePlaceModule,
        NgxGpAutocompleteModule.forRoot({
            loaderOptions: {
                apiKey: 'AIzaSyAn7EUqxXfNj7OqsbtL7wsrD6skLELU9wY',
                libraries: ['places']
            }
        }),
        BrowserModule,
        RouterModule,
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        AppRoutingModule,
        AccordionModule,
        AnimateModule,
        AutoCompleteModule,
        AvatarModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        DataViewModule,
        DropdownModule,
        MessageModule,
        MessagesModule,
        InputTextModule,
        RadioButtonModule,
        InputNumberModule,
        PasswordModule,
        CheckboxModule,
        MenuModule,
        MenubarModule,
        MultiSelectModule,
        TableModule,
        TabViewModule,
        TagModule,
        ToastModule,
        ToolbarModule,
        SliderModule,
        TimelineModule,
        SidebarModule,
        PanelModule,
        SlideMenuModule,
        PaginatorModule,
        TooltipModule,
        ConfirmDialogModule,
        ChipModule,
        ChipsModule,
        StepsModule,
        NgxSpinnerModule,
        GoogleMapsModule,
        ReactiveFormsModule,
        EditorModule,
        TreeModule,
        FormsModule,
        OAuthModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        InputSwitchModule,
        TreeSelectModule,
        InputTextareaModule,
        PickListModule,
        ListboxModule,
        PanelMenuModule,
        SelectButtonModule,
        DialogModule,
        OverlayPanelModule,
        FileUploadModule,
        ChartModule,
        OrganizationChartModule,
        KnobModule,
        DividerModule,
        DividerModule,
        GalleriaModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: LOCALE_ID, useValue: 'fr' },
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
        DatePipe,
        ApiService,
        NgxSpinnerService,
        MessageService,
        ConfirmationService,
        DataViewLayoutOptions,
        TranslatePipe,
        SafeUrlPipe,
        DialogService,
        innovSolutionGuard,
        { provide: APP_INITIALIZER, useFactory: configFactory, deps: [SharedtoolsService, Router], multi: true },
    ],
    bootstrap: [AppComponent],
    exports: [
      NavbarComponent
    ]
})
export class AppModule { }

function configFactory(sharedTools: SharedtoolsService, router: Router) {
    return async () => {
        let config = await sharedTools.getAppGlobalConfig();
        if (config.enableMap) {
            AppConfiguration.conditionalRoutes = AppConfiguration.mapRoutes;
            router.config.push(...AppConfiguration.conditionalRoutes);
        }
    };
}