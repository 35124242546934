import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ConferenceProgramCode } from 'src/app/enums/conference-program-code';
import { FormCode } from 'src/app/enums/form-code';
import { MediasTypes } from 'src/app/enums/media-type';
import { MediaCmpComponent } from 'src/app/medias-components/media-cmp/media-cmp.component';
import { MarketingGenerateMediaRequest } from 'src/app/models/marketingGenerateMediaRequest';
import { MediaValueChange } from 'src/app/models/media-value-change';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-mkt-inform-cmp',
  templateUrl: './mkt-inform-cmp.component.html',
  styleUrls: ['./mkt-inform-cmp.component.less']
})
export class MktInformCmpComponent implements OnInit {

  @ViewChild('logo') logo?: MediaCmpComponent;
  @ViewChild('picture') picture?: MediaCmpComponent;

  @Input() newForm!: FormGroup;

  locations: any;

  socialMenuTabs: any;

  @Input() logoSettings: {} = {};
  @Input() imageSettings: {} = {};

  @Input() isLoadLogoSettings: boolean = false;
  @Input() isLoadImageSettings: boolean = false;

  @Input() sendBoth: boolean = false;

  //#region
  @Input() showLogo: boolean = true;
  @Input() showImage: boolean = true;

  @Input() showCompanyName: boolean = false;
  @Input() showLocalisation: boolean = false;
  @Input() showUrlLandingPage: boolean = false;

  @Input() categoryID: any;
  @Input() isDisabled: boolean = false;

  @Input() isGenerated: boolean = false;
  @Input() isSpeakerForm: boolean = false
  @Input() showLine1: boolean = false
  @Input() showImageTitle: boolean = true
  @Input() showlogoExtraTitle: boolean = true

  @Output() isMediaGenerated: EventEmitter<any> = new EventEmitter<any>();


  @Output() regenerateMedia: EventEmitter<any> = new EventEmitter<any>();


  @Input() canRecieveUserInfo: any;
  @Output() canTriggerReloadUserInfo = new EventEmitter<any>();

  @Output() canReloadGetUserInfo = new EventEmitter<any>();

  mktFormId!: number;
  @Input() reloadGetUserInfo: any;

  @Input() mediaComponentsOrder: any = [MediasTypes.LOGO, MediasTypes.PHOTOID]

  @Output() reloadUserInfo: EventEmitter<any> = new EventEmitter<any>();

  showAllConfTitles: boolean = false
  showConfTitleCustomInput: boolean = false

  private _userInfo: any;
  @Input() set userInfo(value: any) {
    this._userInfo = value;
    console.log('new user info IN :', this._userInfo);
    this.getUserInfo();
  }
  public get userInfo(): any {
    return this._userInfo;
  }
  mediaType = MediasTypes;
  logoUrl: any;
  logoDocID: any;

  photoUrl: any;
  marketingGenerateMediaRequest = new MarketingGenerateMediaRequest();

  folderGuid: any;
  photoDocID: any;
  mktFolderID: any;
  getSelectedLogo: any;
  getSelectedImage: any;

  // Verify if there is new blob(mediaObj) selection : Logo
  selectedLogo: boolean = false;
  selectedPhotoId: boolean = false;
  dialogVisible: boolean = false

  isLogoDeleted: boolean = false
  isUserPhotoDeleted: boolean = false
  isLogoFirst: boolean = false

  currentProgramCode: string = ConferenceProgramCode.PROG1;
  formInitialized : boolean = false
  isMarketingSpeakerForm : boolean = false
  frmCode : string = FormCode.FRM_MARKTNG

  //#endregion

  constructor(public app: AppComponent, private spinnerService: SpinnerService, private router : Router) {
    this.initMediaComponentsOrder()
  }


  async ngOnInit() {

    console.log(">>>   @Input() isDisabled: boolean =: ", this.isDisabled);

    var guid: any = this.app.route.snapshot.queryParamMap.get('folderguid');
    if (guid == null) {
      this.showPopUp("errorNoGuid");
    } else {
      // await this.createMktForm(guid);
      this.folderGuid = guid;
    }
    this.isMarketingSpeakerForm = this.router.url.indexOf("welcome-mkt-speaker") != -1
    // if (this.reloadGetUserInfo) {

    //   setTimeout(async () => {
    //     console.log("hello from child");
    //     await this.getUserInfo();
    //   }, 100);
    // }

   this.initForm();

  }


  initMediaComponentsOrder() {
    return this.mediaComponentsOrder[0] == MediasTypes.LOGO
  }

  isMediaValid() : boolean {
    
    let isPictureValid = true
    if(this.picture){
        isPictureValid = this.picture?.isConformTodimension ?? false
    }

    let isLogoValid = true
    if(this.logo){
        isLogoValid = this.logo?.isConformTodimension ?? false
    }

    return isLogoValid && isPictureValid
  }


  async generateMedia() {
    if (this.newForm.invalid || (this.isSpeakerForm && this.isUserPhotoDeleted) || !this.isMediaValid() ) {
      this.app.confirm.confirmDialog(""
        , this.app.translate.instant('racine.errorsOther.errorForm')
        , 'KO'
        , 'alert'
        , () => { }
        , () => { });
    }
    else {
      let meetingStand = this.newForm.get('MeetingStand')?.value;

      console.log("<<< GenerateMedias : ", this.newForm.value);
      console.log("<<< meetingStand : ", meetingStand);
      let formValue = this.newForm.getRawValue()
      let text1 = formValue?.CompanyName
      let text2 = meetingStand
      let text3 = null;
      let text4 = undefined
      if (this.isSpeakerForm) {
        if(this.showLine1){
          text1 = formValue?.Line1;
        }else {
          let selectedTitleValue = this.newForm.get("SelectedConfTitle")?.value
          if(selectedTitleValue.ConfDetailID == -1 || !selectedTitleValue){
            text1 = formValue?.ConfTitle
          }
          else {
            text1 = this.app.sharedTools.getPropertySwitchLang(selectedTitleValue,"CnfdName")
          }
        }
          text2 = formValue?.FullName
          text3 = formValue?.Fonction
      }
      this.marketingGenerateMediaRequest.Text1 = text1
      this.marketingGenerateMediaRequest.Text2 = text2
      this.marketingGenerateMediaRequest.Text3 = text3
      this.marketingGenerateMediaRequest.Text4 = text4

      // this.marketingGenerateMediaRequest.CompanyName = this.newForm.get('CompanyName')?.value;
      // this.marketingGenerateMediaRequest.Localisation = meetingStand;
      // this.marketingGenerateMediaRequest.WebsiteUrl = this.newForm.get('LandingPageUrl')?.value;
      // this.marketingGenerateMediaRequest.Function = formValue?.Function
      // this.marketingGenerateMediaRequest.Line1 = formValue?.Line1
      // this.marketingGenerateMediaRequest.FullName = formValue?.FullName
      // this.marketingGenerateMediaRequest.IsSpeakerForm = this.isSpeakerForm

      this.marketingGenerateMediaRequest.Logo
        = this.isLoadLogoSettings
          ? this.logoDocID
          : null;
      this.marketingGenerateMediaRequest.UserPhoto
        = this.isLoadImageSettings
          ? this.photoDocID
          : null;
      this.marketingGenerateMediaRequest.CategoryID = this.categoryID;

      let mediaDocObj: any = [];

      if (this.showLogo == true && this.getSelectedLogo != null) {
        mediaDocObj = [...mediaDocObj,
        {
          mediaBlob: this.getSelectedLogo,
          fileName: this.getSelectedLogo?.name,
          mediaType: this.mediaType?.LOGO
        }];
      }

      if (this.showImage == true && this.getSelectedImage != null) {
        mediaDocObj = [...mediaDocObj,
        {
          mediaBlob: this.getSelectedImage,
          fileName: this.getSelectedImage?.name,
          mediaType: this.mediaType?.PHOTOID
        }];
      }

      if (this.getSelectedLogo != null) {
        this.selectedLogo = true;
      }
      else {
        this.selectedLogo = false;
      }

      if (this.getSelectedImage != null) {
        this.selectedPhotoId = true;
      }
      else {
        this.selectedPhotoId = false

      }


      console.log("🚀  this.folderGuid:", this.folderGuid);

      this.spinnerService.disableSpinner();
      this.dialogVisible = true;
      const timerPromise = new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 15000);
      });
      if (this.isMarketingSpeakerForm){
        this.frmCode = FormCode.FRM_MARKETNG_SPEAKER
      }
      this.app.apiService
        .generateMedia(this.marketingGenerateMediaRequest, this.folderGuid, mediaDocObj, this.selectedLogo,
          this.selectedPhotoId,
          this.isLogoDeleted,
          this.isUserPhotoDeleted,
          this.frmCode
        )
        .subscribe({
          next: (resp) => {
            this.dialogVisible = false
            console.log("🚀  [generateMedia] ==> ", resp);
            this.isGenerated = true;
            this.isMediaGeneratedEvent(this.isGenerated);
            this.canTriggerReloadUserInfo.emit(false);
            this.regenerateMedia.emit(true);

            //if (this.canRecieveUserInfo == true) {
            setTimeout(async () => {
              await this.getUserInfo();
              this.getSelectedLogo = null;
              this.getSelectedImage = null;
              this.spinnerService.enableSpinner()
            }, 100);
            //}
          },
          error: (err) => {
            console.log(err);
          }
        });

    }
  }

  logoValueChanged(mediaInfo: MediaValueChange) {
    this.isLogoDeleted = !mediaInfo.HaveValue ?? true
  }

  imageValueChange(mediaInfo: MediaValueChange) {
    this.isUserPhotoDeleted = !mediaInfo.HaveValue ?? true
  }


  async getUserInfo() {

    this.showLogo = false;
    this.showImage = false;

    this.logoDocID = this.userInfo?.Logo;
    this.photoDocID = this.userInfo?.UserPhoto;
    this.locations = this.userInfo?.MeetingStandList;
    this.isGenerated = this.userInfo?.IsGenerated;

    this.isMediaGeneratedEvent(this.isGenerated);

    let logoDoc = await this.getMktMedia(this.logoDocID);
    let photoDoc = await this.getMktMedia(this.photoDocID);

    if (this.logoDocID != null) { this.logoUrl = logoDoc?.DocUrl; }
    if (this.photoDocID != null) { this.photoUrl = photoDoc?.DocUrl; }

    this.showLogo = true;
    this.showImage = true;

    console.log("> logoUrl :", this.logoUrl);
    console.log("> photoUrl :", this.photoUrl);

    // this.reloadGetUserInfo = false;

    if (this.isDisabled) {
      this.newForm.disable();
      //this.newForm.get("pButton")?.disable();
      // this.newForm.get("CompanyName")?.disable();
      // //this.newForm.get("MeetingStand")?.disable();
      // this.newForm.get("LandingPageUrl")?.disable();
    }

  }

  receivedLogo(mediaObj: Blob) {
    this.getSelectedLogo = mediaObj;
    console.log("[receivedLogo] : ", this.getSelectedLogo);
  }

  receivedImage(mediaObj: Blob) {
    this.getSelectedImage = mediaObj;
    console.log("[receivedImage] : ", this.getSelectedImage);
  }

  onChange(event: any): void {
    this.newForm?.get('MeetingStand')?.setValue(event.value);
    console.log("MeetingStand event.value ", event.value)
    this.newForm.updateValueAndValidity();
  }

  //#region initialiseForm
  private initForm(): void {

    this.newForm = new FormGroup({
      CompanyName: new FormControl(this.userInfo?.CompanyName2),
      MeetingStand: new FormControl(this.userInfo?.MeetingStand),
      LandingPageUrl: new FormControl(this.userInfo?.CompanyURL),
      Logo: new FormControl(this.logoDocID),
      UserPhoto: new FormControl(this.photoDocID),
      CategoryID: new FormControl(this.categoryID),
      Line1: new FormControl(this.userInfo?.Line1),
      FullName: new FormControl(this.userInfo?.UserFullName),
      Fonction: new FormControl(this.userInfo?.UserFunction),
      SelectedConfTitle: new FormControl(null),
      ConfTitle: new FormControl(null),
      DateConf: new FormControl(this.userInfo?.DateHourConf)
    });
    this.formInitialized = true
  }

  initFormData(){
    if (this.isSpeakerForm) {
      let fullNameFormControl = this.newForm.get("FullName")
      if (fullNameFormControl) {
        fullNameFormControl.setValidators([Validators.required])
      }

      // if ((this.showTitle && !this.showDropDownConf) || (!this.showAllConfTitles && this.showTitle)) {
      //   let confTitleControl = this.newForm.get("ConfTitle")
      //   if (confTitleControl) {
      //     confTitleControl.setValidators([Validators.required])
      //   }
      // }
    }
  }

  //#endregion

  private async getMktMedia(docId: number) {
    return await this.app.apiService.getMedia(docId);
  }

  formatDate(selectedDate: Date): string {
    // Format the date to "Tuesday 20 juin - 16h00"
    const formattedDate = formatDate(selectedDate, 'EEEE d MMMM - HH:mm', 'en-US');
    return formattedDate;
  }

  private showPopUp(errorMsg: any) {
    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.' + errorMsg), 'KO', 'alert', () => {
      this.app.router.navigate(['404']);
    }, () => { });
  }

  private isMediaGeneratedEvent(generate: boolean) {
    this.isMediaGenerated.emit(generate);
  }

}
