export class SearchVmFolder  {
    public StatusID?: number | null = null;
    public CompanyName2?: string | null = null;
    public CompanyExternalID1?: string | null = null;
    public CountryID?: number | null = null;
    public FederatorFolderID?: number | null = null;
    public ExternalImportEnabled?: boolean | null = null;
    public PriviGroupID?: number | null = null;
    public ContactEmail?: string | null = null;
    public Eligible?: boolean | null = null;
    public Priority?: string | null = null;
    public NbVisitMiniReached?: boolean | null = null;
    public DateLastVisit?: string | null = null;
    public ImprPlanOd?: boolean | null = null;
    public IsFederator?: boolean | null = null;
    public IsFedere?: boolean | null = null;
    public AlreadyConnected?: boolean | null = null;

    public selectedCountry?: string|null = null;
    public selectedStatus?: boolean|null = null;
    public selectedEligibles?: string|null = null;
    public selectedPriviGroups?: string|null = null;
    public selectedPriorities?: string|null = null;
    public selectedMinVisit?: string|null = null;
    public selectedFederatores?: string|null = null;
    public selectedFedere?: string|null = null;
    public selectedFederatNames?: string|null = null;
    public selectedFederatFolder?: string|null = null;
    public selectedImpressingPlan?: string|null = null;
    public selectedKlispoBloque?: string|null = null;
    public selectedConnected?: string|null = null;
    public selectedRightPrintPlan?: string|null = null;
    public selectedExternalImportEnabled?: string|null = null;
}
