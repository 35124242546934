export class SearchUser {
    public Email?: string |null = null;
    public Login?: string|null = null;
    public FirstName?: string|null = null;
    public LastName?: string|null = null;
    public RoleID?: number | null = null;
    public Active?: boolean | null = null;
    //public selectedRoleID?: number|null = null;
    public selectedRoles?: string|null = null;
    public selectedActive?: boolean|null = null;
    public numExposant?: string|null = null;
}

export class SearchVMUser extends SearchUser {
    public LockaccessApp?: boolean | null = null;
    public selectedRole?: any = null;
    public selectedOAOZCategory?: any = null;
}