import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { FOExhibitorsSearchLocalStorageKey, FOSolutionsSearchLocalStorageKey } from 'src/app/consts/utils';
import { ApiService } from 'src/app/services/api.service';
import { SearchHelperService } from 'src/app/services/search-helper.service';

@Component({
  selector: 'app-exhibitor-filter',
  templateUrl: './exhibitor-filter.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./exhibitor-filter.component.less']
})
export class ExhibitorFilterComponent implements OnInit, OnDestroy {
  langChangeSubs: Subscription = new Subscription();
  
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();

  @Input() hasAIButton: boolean = true;
  @Input() hasCluster: boolean = true;
  @Input() hasCountries: boolean = true;
  @Input() hasCategories: boolean = true;
  @Input() hasRooms: boolean = true;
  @Input() hasOthers: boolean = true;
  @Input() hasRoomsAsCheck: boolean = true;
  @Input() isSolution: boolean = false;

  search: any = {};
  clusters: any[] = [];
  categories: any[] = [];
  rooms: any[] = [];
  countries: any[] = [];
  others: any[] = [];
  
  categoriesTags: number = 0;
  interestTags: number = 0;
  countriesTags: number = 0;
  othersTags: number = 0;
  roomTags: number = 0;
  locationTags: number = 0;
  exhibitorsTags: number = 0;
  IsLabTags: boolean = false;
  IsNewTags: boolean = false;

  selectedRoom: any = null;
  filteredOptions: any[] = [];
  isChecked: boolean = true;
  hasValueInName: string = '';
  mappedInterestsList: any[] = [];
  selectedOptionsRooms: string[] = []; // Pour stocker les valeurs sélectionnées
  selectedOption: number | null = null;

  searchByNameOrCompanyChange = new Subject<string>();

  constructor(
    public app: AppComponent,
    private apiService: ApiService,
    private searchHelperService: SearchHelperService
  ) {
    this.selectedOption = null;
    this.searchByNameOrCompanyChange.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.onSearch.emit(this.search);
      });
  }

  async ngOnInit() {
    //await this.getClusters();
    await this.getCountries();
    // await this.getRooms();
    await this.getCategorieExhibitor();
    await this.mapIntersetList();
    this.restaureSearchInfo();
    this.onSearchClick();
    this.initializeSubscriptions();
  }

  async mapIntersetList() {
    this.mappedInterestsList = this.clusters.map((item) => {
      let obj =
      {
        label: item,
        value: item
      }
      return obj;
    });
    console.log(this.mappedInterestsList);
  }

  async clearFilter() {
    console.log("Clear filter was clicked ...");

    this.hasValueInName = '';
    this.search.IntersetList = [];
    this.search.CategoriesList = [];
    this.search.CountriesList = [];
    this.search.ExhibitorsList = [];
    this.search.RoomsList = [];
    this.search.LocationsList = [];
    this.search.IsNew = null
    this.search.IsLab = null
    this.interestTags = 0;
    this.countriesTags = 0;
    this.locationTags = 0;
    this.categoriesTags = 0;
    this.IsLabTags = false;
    this.IsNewTags = false;
    this.search.SearchByName = '';
    this.othersTags = 0;
    this.onSearchClick();

  }

  async getCountries() {
    try {
      if (this.countries.length == 0) {
      const response = await this.apiService.getCountriesExhibitor();
      this.countries = cloneDeep(response);
      this.countries = this.app.sharedTools.orderByPropertySwitchLang(this.countries);
      }
      console.log("countries", this.countries)
    } catch (error) {
      console.error(error);
    }
  }

  async getCategorieExhibitor() {
    try {
      if (this.categories.length == 0) {
      const response = await this.apiService.getCategoriesExhibitor();
      console.log("response", response)
      this.categories = cloneDeep(response);      
      this.categories = this.app.sharedTools.orderByPropertySwitchLang(this.categories);
      }
      console.log("categories", this.categories)
    } catch (error) {
      console.error(error);
    }
  }


  // async getRooms() {
  //   try {
  //     if (this.rooms.length == 0) {
  //     const response = await this.apiService.getRooms();
  //     this.rooms = response;
  //     }
  //     console.log("rooms", this.rooms)
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  onSearchClick(): void {
    console.log('*** emit search ')
    this.onSearch.emit(this.search);
    console.log("🚀  this.search:", this.search)

  }

  onChangeSelection() {
    this.categoriesTags = this.search?.CategoriesList?.length;
    this.interestTags = this.search?.IntersetList?.length;
    this.countriesTags = this.search?.CountriesList?.length;
    this.locationTags = this.search?.LocationsList?.length;
    this.exhibitorsTags = this.search?.ExhibitorsList?.length;
    this.IsNewTags = this.search?.IsNew?.length;
    this.IsLabTags = this.search?.IsLab?.length;

    this.checkOthers();
  }

  getRoomTags(tag: any) {
    this.roomTags = tag.value.length;
  }

  async onRadioSelect(option: number) {
    console.log(option);
    console.log(this.selectedOption);

    console.log(this.selectedOption)
    if (this.selectedOption === option) {
      this.selectedOption = null;

    }
    else {
      this.selectedOption = option;
      this.selectedOptionsRooms.push(this.search.RoomsList);
      console.log(this.selectedOptionsRooms)
      this.search.RoomsList = this.selectedOptionsRooms;

      this.onSearchClick();
      this.selectedOptionsRooms = [];
    };
  }

  onModelChange(newValue: boolean) {
    if (newValue === true) {
      this.isChecked = false;
    }
  }

  async clearCategoriesList() {
    this.search.CategoriesList = [];
    this.categoriesTags = 0;
    // this.onSearchClick();
  }

  async clearClustersList() {
    this.search.IntersetList = [];
    this.interestTags = 0;
    // this.onSearchClick();
  }

  async clearCountriesList() {
    this.search.CountriesList = [];
    this.countriesTags = 0;
    // this.onSearchClick();
  }


  async clearLocationsList() {
    this.search.LocationsList = [];
    this.locationTags = 0;
    // this.onSearchClick();
  }

  async clearIsNew() {
    this.search.IsNew = false;
    this.search.IsLab = false;
    // this.onSearchClick();
  }

  checkOthers(){
    if ((this.search.IsLab && !this.search.IsNew) || (this.search.IsNew && !this.search.IsLab)) {
      this.othersTags = 1;
    }else if (!this.search.IsLab && !this.search.IsNew) {
      this.othersTags = 0;
    }else{
      this.othersTags = 2;
    }
  }
  
  checkIsLab(isLab: boolean) {
    if (isLab) {
      this.othersTags++;
      
    }else{
    this.othersTags--;
    this.search.IsLab = null;
    }
  }

  checkIsNew(isNew: boolean) {
    if (isNew) {
      this.othersTags++;
    }else{
    this.othersTags--;
    this.search.IsNew = null;
    }
  }

  private restaureSearchInfo(): void{
    let searchStorageKey: string = '';
    if(this.app.router.url.startsWith('/welcome-sol')){
      searchStorageKey = FOSolutionsSearchLocalStorageKey;
    }
    else if (this.app.router.url.startsWith('/welcome-exhibitor')){
      searchStorageKey = FOExhibitorsSearchLocalStorageKey;
    }

    let latestsearch: any = this.searchHelperService.getAdvancedSearch(searchStorageKey);
    console.log(latestsearch)

    if(latestsearch){
      this.search.RoomsList = latestsearch.RoomsList;
      this.search.IsNew = latestsearch.IsNew;
      this.search.IsLab = latestsearch.IsLab;
      this.search.CountriesList = latestsearch.CountriesList;
      this.search.CategoriesList = latestsearch.CategoriesList;
      this.search.SearchByName = latestsearch.SearchByName;
      this.onChangeSelection();
    }
  }

  
  private initializeSubscriptions(): void{
    this.langChangeSubs = this.app.translate.onLangChange.subscribe((v: any) =>{
      this.handleLangChange();
    });
  }

  private destroySubsciptions(): void{
    this.langChangeSubs?.unsubscribe();
  }
  
  private handleLangChange(): void{
    this.categories = this.app.sharedTools.orderByPropertySwitchLang(this.categories);
    this.countries = this.app.sharedTools.orderByPropertySwitchLang(this.countries);
  }

  ngOnDestroy(): void {
    this.destroySubsciptions();
  }
}
