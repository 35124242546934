import { Route } from "@angular/router";
import { ConfMapComponent } from "./welcome-conference/conf-map/conf-map.component";

export class AppConfiguration {
    static mapRoutes : Route[] = [
        { path: 'map', component: ConfMapComponent },
        { path: 'mapExhi', component: ConfMapComponent }
    ];

    static conditionalRoutes : Route[] = [];
}