import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { FOExternalPagesRoutes } from 'src/app/consts/fo-external-pages.routes';
import { ImageModelSuffix } from 'src/app/enums/image-model-suffix';
import { IdentityManagerService } from 'src/app/services/identity-manager.service';

@Component({
  selector: 'app-card-sol',
  templateUrl: './card-sol.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./card-sol.component.less']
})
export class CardSolComponent implements OnInit {

  @Input() solution: any;
  @Input() left: boolean = false;
  @Input() showStand: boolean = true;
  @Input() showDescription: boolean = true;
  @Input() showCompanyName: boolean = true;

  currentLang: any;
  solID: any;
  imageModelSuffix = ImageModelSuffix;

  constructor(
    private _router: Router,
    public app: AppComponent,
    protected ims: IdentityManagerService
    ) {
      
    }
    
    ngOnInit(): void {
      if (this.left == true) {
        this.solution.solutionImageDirection = 'left';
      }
  }
   

  async gotoDetails(solID: any) {
    let queryParams: any = {
      solutionID: solID
    };

    this.app.sharedTools.routeTo(FOExternalPagesRoutes.solDetail, queryParams) 
  }

  goToExhiMap(location: any) {
    this.app.sharedTools.goToExhiMap(location)
  }


}
