import { Injectable } from '@angular/core';
import { Traduction } from '../models/traduction';
import { SharedtoolsService } from './sharedtools.service';
import { TreeNode } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { clearLeafProperties } from '../consts/utils';
import { Languages } from '../enums/language';

@Injectable({
  providedIn: 'root'
})
export class TraductionService {
  private urlDataJson = "./assets/i18n/";
  //aux vars to save json data
  private _jsonEN: any = {};
  private _jsonFR: any = {};
  private _jsonES: any = {};

  //mark traduction laod
  private _loaded: boolean = false;

  private _traduction: Traduction;

  get traduction() {
    return this._traduction;
  }

  private set traduction(value: Traduction) {
    value.traductionENTree = this.toFullTree(value.traductionEN);
    //value.traductionENFullTree = this.toFullTree(value.traductionEN);

    value.traductionFRTree = this.toFullTree(value.traductionFR);
    //value.traductionFRFullTree = this.toFullTree(value.traductionFR);

    value.traductionESTree = this.toFullTree(value.traductionES);
    //value.traductionESFullTree = this.toFullTree(value.traductionES);

    this._traduction = value;
  }

  constructor(private sharedTools: SharedtoolsService) {
    //init empty traduction
    this._traduction = {
      traductionEN: {},
      traductionENTree: [],
      //traductionENFullTree: [],

      traductionFR: {},
      traductionFRTree: [],
      //traductionFRFullTree: [],

      traductionES: {},
      traductionESTree: [],
      //traductionESFullTree: [],
    }

    console.log("Traduction  =====>", this.traduction);
  }

  public getTraduction(): Traduction {
    if (this._loaded) {
      return this.traduction;
    }

    let newTraduction: Traduction = {
      traductionEN: this._jsonEN,
      traductionENTree: [],
      //traductionENFullTree: [],

      traductionFR: this._jsonFR,
      traductionFRTree: [],
      //traductionFRFullTree: [],

      traductionES: this._jsonES,
      traductionESTree: [],
      //traductionESFullTree: [],
    }

    this.traduction = newTraduction;

    this._loaded = true;

    return this.traduction;
  }


  public async getTraductionAsync(): Promise<Traduction> {
    await this.extractFromFiles();
    
    // this.traduction.traductionES = clearLeafProperties(this.traduction.traductionES);

    console.log("Traductions  =====>", this.traduction);

    return this.traduction;
  }

  //#region private methods
  // private toTree(json: any): TreeNode[] {
  //   let nodes: TreeNode[] = [];


  //   return nodes;
  // }

  private toJson(nodes: TreeNode[]): any {
    let json: any = {};

    return json;
  }


  private initTraduction(): void {
    if (!this._loaded) {

      let newTraduction : Traduction = {
        traductionEN: this._jsonEN,
        traductionENTree: [],
        //traductionENFullTree: [],

        traductionFR: this._jsonFR,
        traductionFRTree: [],
        //traductionFRFullTree: [],

        traductionES: this._jsonES,
        traductionESTree: [],
        //traductionESFullTree: [],
      }

      this.traduction = newTraduction;

      this._loaded = true;

      console.log("Traduction =====>", this.traduction);
    }
  }

  private async extractFromFiles(): Promise<void> {
    //retrieve json data from json files (EN, FR and ES)
    try {
      //EN
      this._jsonEN = await firstValueFrom(this.sharedTools.getJSONByPath(this.urlDataJson, Languages.English));

      //FR
      this._jsonFR = await firstValueFrom(this.sharedTools.getJSONByPath(this.urlDataJson, Languages.French));

      //ES
      this._jsonES = await firstValueFrom(this.sharedTools.getJSONByPath(this.urlDataJson, Languages.Spanish));

      this.initTraduction();

    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private toTree(json: any): TreeNode[] {
    //extract first level nodes
    let traductionTreeNodes = Object.keys(json).map(key => {
      let newTreeNode: TreeNode = { key: key, label: key };

      //begin extract second level nodes
      let children: TreeNode[] = Object.keys(json[key]).map(keyLvl2 => {
        let newChildTreeNode: TreeNode = { key: keyLvl2, label: keyLvl2, parent: newTreeNode, icon: 'pi pi-circle-fill' };

        return newChildTreeNode;
      });
      //end extract second level nodes

      newTreeNode.children = children;
      return newTreeNode;
    }
    );

    return traductionTreeNodes;
  }

  private toFullTree(json: any): TreeNode[] {
    //extract first level nodes
    let traductionTreeNodes = Object.keys(json).map(key => {
      let newTreeNode: TreeNode = { key: key, label: key };

      //begin extract second level nodes
      let children: TreeNode[] = Object.keys(json[key]).map(keyLvl2 => {
        let newChildTreeNode: TreeNode = { key: keyLvl2, label: keyLvl2, parent: newTreeNode, icon: 'pi pi-circle-fill' };

        //begin extract third level nodes
        if(typeof json[key][keyLvl2] === 'object' && json[key][keyLvl2] !== null){
          let leafNodes: TreeNode[] = Object.keys(json[key][keyLvl2]).map(keyLvl3 => {
            let newLeafTreeNode: TreeNode = { key: keyLvl3, label: json[key][keyLvl2][keyLvl3], parent: newChildTreeNode, leaf: true };
            newLeafTreeNode.data = 3;
            return newLeafTreeNode;
          });
          //end extract third level nodes
  
          newChildTreeNode.data = leafNodes;
        }
        else{
          newChildTreeNode.leaf = true;
          newChildTreeNode.data = 2;
        }
        return newChildTreeNode;
      });
      //end extract second level nodes

      newTreeNode.children = children.filter(child => !child.leaf);
      newTreeNode.data = children.filter(child => child.leaf);
      return newTreeNode;
    }
    );

    return traductionTreeNodes;
  }
  //#endregion
}
