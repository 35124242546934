import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(public translateService: TranslateService) { }

  exportCSV(dataSource: any[], selectedColumns: any[], fileName: string, isCustomFilterColumn: boolean): void {
    if (isCustomFilterColumn === false) {
      selectedColumns = this.filterColumns(dataSource, selectedColumns);
    }
    console.log(selectedColumns)
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(selectedColumns);
    const csv: string = XLSX.utils.sheet_to_csv(worksheet, { FS: ';', forceQuotes: true });

    // Create a Blob and download the file
    const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(blob));
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  exportExcel(dataSource: any[], selectedColumns: any[], fileName: string, isCustomFilterColumn: boolean) {
    import('xlsx').then((xlsx) => {
      if (isCustomFilterColumn === false) {
        selectedColumns = this.filterColumns(dataSource, selectedColumns);
      }
      const worksheet = xlsx.utils.json_to_sheet(selectedColumns);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, fileName);
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE, });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().toLocaleDateString() + EXCEL_EXTENSION);
  }

  private filterColumns(dataSource: any[], selectedColumns: any[]): any[] {
    return dataSource.map(row => {
      const filteredColumns: any = {};
      selectedColumns.forEach(column => {
        if (this.translateService.currentLang === "fr") {
          filteredColumns[column.LabelFR] = row[column.field] ?? '';
        }
        else if (this.translateService.currentLang === "en") {
          filteredColumns[column.LabelEN] = row[column.field] ?? '';
        }
        else { // currentLang === "es"
          filteredColumns[column.LabelES] = row[column.field] ?? '';
        }
      });
      return filteredColumns;
    });
  }

  public sortTable(data: any[], columnName: string, ascending: boolean, dateColumn: boolean = false, arrayNestedColumnName: string | null = null) {
    return data.sort((a, b) => {
      var aValue = a[columnName];
      var bValue = b[columnName];
      
      let objName = columnName.split(".")[0]
      let propName = columnName.split(".")[1]
      if (columnName.indexOf(".") != -1 && a[objName] && b[objName]) {
        aValue = this.getNestedPropertyValue(a[objName], propName)
        bValue = this.getNestedPropertyValue(b[objName], propName)
      }

      //test if array of objects 
      if(Array.isArray(aValue) && Array.isArray(bValue)){
        if(arrayNestedColumnName){
          aValue = aValue[0] ? aValue[0][arrayNestedColumnName] : null;
          bValue = bValue[0] ? bValue[0][arrayNestedColumnName] : null;
        }
        else{
          aValue = aValue[0];
          bValue = bValue[0];
        }
      }

      if(dateColumn){
        return this.compareDateColumn(aValue, bValue, ascending)
      }

      // Compare the values based on the column
      if (aValue === bValue) {
        return 0;
      } else if (aValue < bValue) {
        return ascending ? -1 : 1;
      } else {
        return ascending ? 1 : -1;
      }
    });
  }

  getNestedPropertyValue(obj: any, propPath: string): any {
    return propPath.split('.').reduce((acc, key) => acc[key], obj);
  }

  compareDateColumn(aValue : any, bValue : any, ascending : boolean){
    if (typeof aValue === 'string') {
      aValue = new Date(aValue);
    }
    if (typeof bValue === 'string') {
      bValue = new Date(bValue);
    }

    // Compare the values based on whether they are Date objects or not
    if (aValue instanceof Date && bValue instanceof Date) {
      if (aValue.getTime() === bValue.getTime()) {
        return 0;
      } else {
        return ascending ? (aValue.getTime() - bValue.getTime()) : (bValue.getTime() - aValue.getTime());
      }
    } else {
      if (aValue === bValue) {
        return 0;
      } else {
        return ascending ? (aValue < bValue ? -1 : 1) : (aValue > bValue ? -1 : 1);
      }
    }
  }

  generateRedirectAndEncodeUri(baseUrl : string, redirectToUri : string){
    return encodeURI(`${baseUrl}&redirectto=${redirectToUri}`)
  }

}
