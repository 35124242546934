const ExhbitiorStorageKey = 'exhibitor-ids';
const SolutionStorageKey = 'solution-ids';


import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExhibitorStoreService {//#region cotr
  constructor(private datePipe: DatePipe){};
  //#endregion ctor

  /************************************ */
  /**** Conference Events Utils ****/
  /************************************ */
  //#region confEventsIds properties
  private _exibitorsIds: number[] = [];
  get exibitorsIds(): number[] {
    if (this._exibitorsIds.length) {
      return this._exibitorsIds;
    }

    let exhibitorIdsStr = sessionStorage.getItem(ExhbitiorStorageKey);
    if (!exhibitorIdsStr) {
      return this._exibitorsIds;
    }

    let exhibitorIdsList = JSON.parse(exhibitorIdsStr);
    if (!(exhibitorIdsList && Array.isArray(exhibitorIdsList))) {
      return this._exibitorsIds;
    }

    this._exibitorsIds = exhibitorIdsList;

    return this._exibitorsIds;
  }

  set exibitorsIds(value: number[] | any) {
    if (value && Array.isArray(value)) {
      this._exibitorsIds = value;

      sessionStorage.setItem(ExhbitiorStorageKey, JSON.stringify(this._exibitorsIds));
    }
  }

  //#endregion exibitorsIds properties

  //#region exibitorsIds functions
  public isFirstExhibitor(currentExhiId: number): boolean {
    let currentExhiIndex: number = this.exibitorsIds.findIndex(e => e == currentExhiId);
    console.log(currentExhiId, currentExhiIndex)
    if (currentExhiIndex < 0) {
      return false;
    }

    return currentExhiIndex == 0;
  }

  public isLastExhibitor(currentExhibitorId: number): boolean {
    let currentExhibitorIndex: number = this.exibitorsIds.findIndex(e => e == currentExhibitorId);
    if (currentExhibitorIndex < 0) {
      return false;
    }

    return currentExhibitorIndex == this.exibitorsIds.length - 1;
  }

  public nextExhibitor(currentExhibitorId: number): number {
    let currentExhibitorIndex: number = this.exibitorsIds.findIndex(e => e == currentExhibitorId);
    if (currentExhibitorIndex < 0) {
      return currentExhibitorId;
    }

    return currentExhibitorIndex == (this.exibitorsIds.length - 1)
      ? currentExhibitorIndex
      : this.exibitorsIds[currentExhibitorIndex + 1];
  }

  public previousExhibitor(currentExhiId: number): number {
    let currentExhibitorIndex: number = this.exibitorsIds.findIndex(e => e == currentExhiId);
    if (currentExhibitorIndex < 0) {
      return currentExhiId;
    }

    return currentExhibitorIndex == 0
      ? currentExhibitorIndex
      : this.exibitorsIds[currentExhibitorIndex - 1];
  }
  //#endregion confEventsIds functions
  

  /************************************ */
  /****** Exhibitor Solution Utils ******/
  /************************************ */
  //#region solutionIds properties
  private _solutionsIds: number[] = [];
  get solutionsIds(): number[] {
    if (this._solutionsIds.length) {
      return this._solutionsIds;
    }

    let solutionsIdsStr = sessionStorage.getItem(SolutionStorageKey);
    if (!solutionsIdsStr) {
      return this._solutionsIds;
    }

    let solutionsIdsList = JSON.parse(solutionsIdsStr);
    if (!(solutionsIdsList && Array.isArray(solutionsIdsList))) {
      return this._solutionsIds;
    }

    this._solutionsIds = solutionsIdsList;

    return this._solutionsIds;
  }

  set solutionsIds(value: number[] | any) {
    if (value && Array.isArray(value)) {
      this._solutionsIds = value;

      sessionStorage.setItem(SolutionStorageKey, JSON.stringify(this._solutionsIds));
    }
  }
  //#endregion solutionIds properties

  //#region solutionIds functions
  public isFirstExhiSolution(currentSolutionId: number): boolean {
    console.log(this.solutionsIds, currentSolutionId)
    let currentSolutionIndex: number = this.solutionsIds.findIndex(e => e == currentSolutionId);
    console.log(currentSolutionId, currentSolutionIndex)
    if (currentSolutionIndex < 0) {
      return false;
    }

    return currentSolutionIndex  == 0;
  }

  public isLastExhiSolution(currentExhibitorId: number): boolean {
    let currentExhibitorIndex: number = this.solutionsIds.findIndex(e => e == currentExhibitorId);
    if (currentExhibitorIndex < 0) {
      return false;
    }

    return currentExhibitorIndex == this.solutionsIds.length - 1;
  }

  public nextExhiSolution(currentSolutionId: number): number {
    let currentSolutionIndex: number = this.solutionsIds.findIndex(e => e == currentSolutionId);
    if (currentSolutionIndex < 0) {
      return currentSolutionId;
    }

    return currentSolutionIndex == (this.solutionsIds.length - 1)
      ? currentSolutionIndex
      : this.solutionsIds[currentSolutionIndex + 1];
  }

  public previousExhiSolution(currentSolutionId: number): number {
    let currentSolutionIndex: number = this.solutionsIds.findIndex(e => e == currentSolutionId);
    if (currentSolutionIndex < 0) {
      return currentSolutionId;
    }

    return currentSolutionIndex == 0
      ? currentSolutionIndex
      : this.solutionsIds[currentSolutionIndex - 1];
  }
  //#endregion solutionIds functions
}
