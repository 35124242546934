import { DateHelper } from "../helpers/date.helper";

export class Plage {
    //#region StartHour
    private _StartHour?: string;
    public get StartHour(): string | undefined {
        return this._StartHour;
    }
    public set StartHour(value: string | undefined) {
        if (value) {
            this._StartHour = value;
            if (this.StartDate) {
                this.StartDate = DateHelper.updateTime(this.StartDate, value);
            }
        }
    }
    //#endregion StartHour

    //#region EndHour
    private _EndHour?: string;
    public get EndHour(): string | undefined {
        return this._EndHour;
    }
    public set EndHour(value: string | undefined) {
        if (value) {
            this._EndHour = value;
            if (this.EndDate) {
                this.EndDate = DateHelper.updateTime(this.EndDate, value);
            }
        }
    }
    //#endregion EndHour

    PlageID: number | undefined;
    StartDate?: string;
    EndDate?: string;

    /**
     *
     */
    constructor(plageID: number | undefined, startDate: string, endDate: string, date: string | undefined = undefined) {
        if (plageID) {
            this.PlageID = plageID;
            this.StartDate = startDate;
            this.EndDate = endDate;
            this.StartHour = DateHelper.extractTime(startDate);
            this.EndHour = DateHelper.extractTime(endDate);
        }
        else if (date) {
            this.StartDate = date;
            this.EndDate = date;
            this.StartHour = DateHelper.extractTime(startDate);
            this.EndHour = DateHelper.extractTime(endDate);
        }
    }
}