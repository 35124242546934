import { Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Menu } from 'primeng/menu';
import { Table } from 'primeng/table';
import { firstValueFrom, Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AppComponent } from '../app.component';
import { DatePipe } from '@angular/common';
import { cloneDeep } from 'lodash';
import { AdminDoAdvancedSearchLocalStorageKey, hasAnyNonNullProperty } from '../consts/utils';
import { SearchVmDo } from '../models/search-VmDo';
import { StatusType } from '../enums/status-type';
import { FolderStatus } from '../enums/folder-status';
import { CategoryType } from '../enums/category-type';
import { StatusCode } from '../enums/status-code';
import { environment } from 'src/environments/environment';
import { ExtendedCountries } from '../enums/extended-countries';
import { TranslatePipe } from '@ngx-translate/core';
import { HelperService } from '../services/helper.service';
import { Languages } from '../enums/language';
import { PagePaginationInfo } from '../models/page-pagination-info';
import { SearchHelperService } from '../services/search-helper.service';

@Component({
  selector: 'app-gestion-vm-do',
  templateUrl: './gestion-vm-do.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./gestion-vm-do.component.less']
})
export class GestionVmDoComponent implements OnDestroy {
  //#region local properties
  //subscriptions
  private vmDosExportSubscription?: Subscription;
  private companyAutocompleteSubscription?: Subscription;

  //ViewChild === elements refs
  @ViewChild('menuAction') menuAction!: Menu;
  @ViewChild('dtVmDo') dtVmDo!: Table;
  @ViewChild('dvVmDo') dvVmDo!: DataView;

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  layout: any = 'grid';
  groupedActions: any;

  vmGroupExternalImportActive: any;
  vmGroupSectorType: any;
  vmDetailGroupNumberPeople: any;

  showTable: boolean = true;
  rangeDates: Date[] = [];
  // search: any = new SearchVmDo();
  search: any;
  exportSearch: any;
  showAdvancedSearch: boolean = false;
  fastFiltersFolder: any = ['GroupNameEN', 'StatusId', 'TypeCategoryID', 'GroupCountries', 'DetailGroupNumberPeople', 'GroupExternalImportActive', 'GroupSectorType'];
  vmDos: any = [];
  countr: any = [];

  status: any = [];
  groupExternalImportActive: any = [];
  countries: any = [];
  typeCategory: any = [];
  detailGroupNumberPeople: any = [];
  groupSectorType: any = [];
  groupNameEn: any = [];

  externalImportActive: any = [];
  typeCategoryID: any = [];

  stats: any = [];

  categories: any = [];


  colsFolders: any = [];
  exportcolsFolders: any = [];
  items: any = [];
  selectedVmDo: any = [];
  selectedAction: any;
  _selectedColumns: any = [];
  selectedEtat: any = [];


  fastSearchFolderKeyword: string = '';
  msgAlertImport: string = ""
  showModal: boolean = false
  rowsCapacity: number = 50
  pagePaginationInfo: PagePaginationInfo = { pageNumber: 1, rowSize: 50 }
  currentPageTable: number = 1
  currentPageIndex: number = 1
  isLoaded: boolean = false

  @ViewChild('fileInput') fileInput?: ElementRef;
  //#endregion

  constructor(
    public app: AppComponent,
    public helperService: HelperService,
    private messageService: MessageService,
    private searchHelperService: SearchHelperService,
    private datePipe: DatePipe,
    public translatePipe: TranslatePipe,
  ) { }

  ngOnDestroy(): void {
    this.unsubscribeActiveSubscriptions();
  }

  onchangeSelect(_item: any) {
    if (!_item) return;
    //console.log("onchangeSelect", _item);
  }

  async onchangeStatus(_od: any) {
    console.log("onchangeStatus OD ==> ", _od);
    if (!_od.selectedStatus) return;

    let objStatus: any = {
      StatusID: _od.selectedStatus.StatusID,
      GroupID: _od.GroupID
    }

    console.log("objStatus ==> ", objStatus);

    try {
      const response = await this.app.apiService.changestatusVmDo(objStatus);
      console.log("apiService.changestatusVmDo ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
        return;
      }

      await this.getstats();
      this.searchVmDo(this.search, true, true);

    } catch (error) {
      console.error(error);
    }
  }

  filterFast() {
    console.log("_value", this.fastSearchFolderKeyword);
    // this.dvVmDo.filter(_value, 'contains');
    this.dtVmDo?.filterGlobal(this.fastSearchFolderKeyword, 'contains');
  }

  async ngOnInit() {
    //get privious advanced search value before routing to other routes
    this.search = cloneDeep(this.searchHelperService.getAdvancedSearch(AdminDoAdvancedSearchLocalStorageKey))
    if (!this.search) {
      this.search = new SearchVmDo()
    }
    // this.fastSearchFolderKeyword = this.searchHelperService.getFastSearchKeyword(VMGestionDOSearchLocalStorageKey);

    let user = JSON.parse(sessionStorage.getItem('userData')!);
    console.log(user);
    if (!user) {
      this.app.router.navigate(['/login']);
      return;
    }
    console.log(this.search)
    // this.colsFolders = this.app.translate.("racine.generalites.columns");
    let resG = await firstValueFrom(this.app.sharedTools.getJSON('general_data'));
    
    this.vmGroupExternalImportActive = resG.vmGroupExternalImportActive;
    this.vmDetailGroupNumberPeople = resG.vmDetailGroupNumberPeople;

    this.pagePaginationInfo = this.searchHelperService.getPagePaginationInfo(AdminDoAdvancedSearchLocalStorageKey)
    this.rowsCapacity = this.pagePaginationInfo.rowSize ?? 50

    this.items = resG.actionsRowGestionVmDo;

    this.items.forEach((itemMenu: any) => {
      itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
    });

    this.colsFolders = resG.colsVmDo;

    this._selectedColumns = this.colsFolders;
    this.exportcolsFolders = this._selectedColumns.map((col: any) => ({ title: col.header, dataKey: col.header }));
    await this.getstats();
    await this.getStatus();
    await this.getCountries();
    await this.getCategories();
    this.initAdvancedSearch()
    this.searchVmDo(this.search, true, true);
  }

  toggleLayout() {
    this.showTable = !this.showTable;
  }

  toggleMenu(event: any, folderId: number, items: any) {
    this.menuAction.toggle(event);

    console.log("Folder ID:", folderId);
    console.log("items:", items);

    items.forEach((itemMenu: any) => {
      itemMenu.folderIdRow = folderId;
      itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

      if (itemMenu.ActionID == 1) {
        itemMenu.command = (event: any) => {
          this.app.sharedTools.redirectTo('/detail-vm-do/' + event.item.folderIdRow.toString());
        }
      }
      // if (itemMenu.ActionID == 2) {
      //   itemMenu.command = (event: any) => {
      //     this.consultDirectExhibitor(event.item.folderIdRow);
      //   }
      // }
    });
  }


  set selectedColumns(val: any[]) {
    // ==> restore original order
    this._selectedColumns = this.colsFolders.filter((col: any) => val.includes(col));
  }



  async getstats() {
    let data = await firstValueFrom(this.app.apiService.getstats())
    console.log("getstats data ==>", data);
    this.stats = cloneDeep(data);
  }

  async getStatus() {
    let data = await firstValueFrom(this.app.apiService.getStatus())
    console.log("getStatus ==> ", data);
    this.status = cloneDeep(data);

    this.status = this.status.filter((s: any) => s.StatusType === StatusType.F18OD)
    console.log("this.status ==> ", this.status);
  }

  async getCountries() {
    let data = await firstValueFrom(this.app.apiService.getExtendedCountries(ExtendedCountries.vm))
    console.log("getCountries ==> ", data);
    this.countries = data;
  }

  async getCategories() {
    try {
      const response = await this.app.apiService.getCategories();
      console.log("🚀  response category :", response);

      let categoriesIni = cloneDeep(response);

      this.typeCategory = cloneDeep(categoriesIni.filter((cat: any) => {
        return cat.CategoryType === CategoryType.TypeDO;
      }));

      this.groupSectorType = cloneDeep(categoriesIni.filter((cat: any) => {
        return cat.CategoryType === CategoryType.SectorDO;
      }));

    } catch (error) {
      console.error(error);
    }
  }

  formatDate(date: any) {
    return
  }

  pageChanged(event: any) {
    let pageIndex = event.first / event.rows + 1
    this.currentPageIndex = pageIndex
    this.updatePaginationInfo(AdminDoAdvancedSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
  }
  rowsChanged(event: any) {
    this.rowsCapacity = event
    this.updatePaginationInfo(AdminDoAdvancedSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
  }

  updatePaginationInfo(key: string, currentPageIndex: number, rowCapacity: number) {
    let pagePaginationInfo: PagePaginationInfo = {
      pageNumber: currentPageIndex,
      rowSize: rowCapacity
    }
    this.searchHelperService.setPagePaginationInfo(pagePaginationInfo, key)
  }

  async searchVmDo(search: any, thenFilterFast: boolean = false, goLatestViewedPage: boolean) {
    //save search to retrieve it when coming back to BO after redirect from BO
    this.searchHelperService.setAdvancedSearch(search, AdminDoAdvancedSearchLocalStorageKey);

    console.log("search ==> ", search);
    let searchTemp: any = cloneDeep(search);

    if (searchTemp.selectedStatus) searchTemp.StatusId = searchTemp.selectedStatus.StatusID;
    if (searchTemp.selectedGroupExternalImportActive) searchTemp.GroupExternalImportActive = searchTemp.selectedGroupExternalImportActive.GroupExternalImportActive;
    // if (searchTemp.selectedCountry) searchTemp.GroupCountries = searchTemp.selectedCountry.CountryID;
    if (searchTemp.selectedTypeCategory) searchTemp.TypeCategoryID = searchTemp.selectedTypeCategory.CategoryID;
    // if (searchTemp.selectedDetailGroupNumberPeople) searchTemp.DetailGroupNumberPeople = searchTemp.selectedDetailGroupNumberPeople.GroupExternalImportActive;
    if (searchTemp.selectedDetailGroupNumberPeople) searchTemp.HaveInviteChef = searchTemp.selectedDetailGroupNumberPeople.GroupExternalImportActive;
    if (searchTemp.selectedGroupSectorType) searchTemp.GroupSectorType = searchTemp.selectedGroupSectorType.CategoryID;

    // if (searchTemp.selectedCategory) delete searchTemp.selectedCountry;
    if (searchTemp.selectedStatus) delete searchTemp.selectedStatus;
    if (searchTemp.selectedGroupExternalImportActive) delete searchTemp.selectedGroupExternalImportActive;
    if (searchTemp.selectedCountry) delete searchTemp.selectedCountry;
    if (searchTemp.selectedTypeCategory) delete searchTemp.selectedTypeCategory;
    if (searchTemp.selectedDetailGroupNumberPeople) delete searchTemp.selectedDetailGroupNumberPeople;
    if (searchTemp.selectedGroupSectorType) delete searchTemp.selectedGroupSectorType;

    console.log("searchTemp ==> ", searchTemp);

    delete searchTemp.selectedStatus;
    delete searchTemp.selectedGroupExternalImportActive;
    delete searchTemp.selectedCountry;
    delete searchTemp.selectedTypeCategory;
    delete searchTemp.selectedDetailGroupNumberPeople;
    delete searchTemp.selectedGroupSectorType;

    searchTemp[this.app.sharedTools.getLangPropertyName('GroupName')] = searchTemp.GroupName;
    searchTemp.GroupCountries = searchTemp?.selectedCountries?.map((country: any) => country.CountryID)
    delete searchTemp.GroupName;

    this.exportSearch = searchTemp;
    console.log("searchTemp ==> ", searchTemp);

    try {
      const response = await this.app.apiService.searchVmDo(searchTemp);
      console.log("searchVmDo ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/'); }, () => { })
        return;
      }

      this.vmDos = cloneDeep(response)

      setTimeout(() => {
        this.vmDos.forEach((folder: any) => {

          folder.CreationDateFormat! = this.datePipe.transform(folder.CreationDate, 'dd/MM/yyyy HH:mm:ss');
          if (this.fastFiltersFolder.indexOf('CreationDateFormat') == -1) this.fastFiltersFolder.push("CreationDateFormat");

          folder.selectedStatus = this.status.find((s: any) => s.StatusID == folder.Status.StatusID);
          folder.selectedGroupExternalImportActive = this.groupExternalImportActive.filter((grpExt: any) => { return grpExt.GroupExternalImportActive == folder.GroupExternalImportActive })[0];
          folder.selectedCountry = this.countries.filter((country: any) => { return country.CountryID == folder.CountryID })[0];
          folder.selectedTypeCategory = this.typeCategory.filter((typCat: any) => { return typCat.TypeCategoryID == folder.TypeCategoryID })[0];
          folder.selectedDetailGroupNumberPeople = this.detailGroupNumberPeople.filter((detGrp: any) => { return detGrp.DetailGroupNumberPeople == folder.DetailGroupNumberPeople })[0];
          folder.selectedGroupSectorType = this.groupSectorType.filter((grpSec: any) => { return grpSec.GroupSectorType == folder.GroupSectorType })[0];

          if (folder.selectedStatus) {
            this.app.sharedTools.getLabelSwitchLang(folder.selectedStatus);
            if (this.fastFiltersFolder.indexOf('selectedStatus.label') == -1) this.fastFiltersFolder.push("selectedStatus.label");
          }
          if (folder.selectedCountry) {
            this.app.sharedTools.getLabelSwitchLang(folder.selectedCountry);
            if (this.fastFiltersFolder.indexOf('selectedCountry.label') == -1) this.fastFiltersFolder.push("selectedCountry.label");
          } if (this.fastFiltersFolder.indexOf('selectedTypeStand.label') == -1) this.fastFiltersFolder.push("selectedTypeStand.label");

        });

        this.isLoaded = true
        if (thenFilterFast) {
          this.filterFast();

          if (hasAnyNonNullProperty(this.search)) {
            console.log("hasAnyNonNullProperty", hasAnyNonNullProperty(this.search))
            // this.initAdvancedSearch();
            this.showAdvancedSearch = true;
          }
        }
      }, 100);

      if (goLatestViewedPage) {
        this.currentPageTable = ((this.pagePaginationInfo.pageNumber ?? 1) - 1) * (this.pagePaginationInfo.rowSize ?? 1);
      }

    } catch (error) {
      console.error(error);
    }
  }

  clear(table: Table) {
    table.clear();
  }

  clearSearch() {
    this.rangeDates = [];
    this.search = new SearchVmDo();
    this.searchVmDo(this.search, false, true);
    this.clearFastSearch();
  }

  clearFastSearch(): void {
    this.fastSearchFolderKeyword = '';
    this.filterFast();
  }

  onRowSelect(event: any) {
    console.log("event.data==>", event.data);
    console.log("selectedVmDo==>", this.selectedVmDo);
    //this.messageService.add({ severity: 'info', summary: 'Folder Selected', detail: event.data.FolderID });
  }

  goToDetail(_folderId: any) {
    console.log("[gotToDetail] ==> ", _folderId);
    this.app.sharedTools.redirectTo('/detail-vm-do/' + _folderId.toString());
  }

  mapColumn() {
    return this.vmDos.map((row: any) => {
      const filteredColumns: any = {};
      console.log(this.selectedColumns);
      this.selectedColumns.forEach(column => {
        //   if(this.translalteService.currentLang === "fr"){
        switch (column.field) {
          case "NbPersonnes": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.NbPersonnes ?? ''
            break;
          case "GroupNameEN": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getPropertySwitchLang(row, 'Name') ?? ''
            break;
          case "ODTypeCategory": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.ODTypeCategory.Code ?? ''
            break;
          case "Country": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(row.Country) ?? ''
            break;
          case "GuestFullName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = (row.Guest?.Grade ?? '') + ' ' + (row.Guest?.FullName ?? '') + ' ' + (row.Guest?.Function ?? '')
            break;
          case "GuestInterestCenter": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Guest?.InterestCenter ?? ''
            break;
          case "ChefFullName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = (row.Chef?.Grade ?? '') + ' ' + (row.Chef?.FullName ?? '') + ' ' + (row.Chef?.Function ?? '')
            break;
          case "InterestCenter": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Chef?.InterestCenter ?? ''
            break;
          case "StatusID": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(row.selectedStatus)
            break;
          case "StartDate": {
            let StartDate = "";
            row.Plages.forEach((plage: any) => {
              StartDate += `${this.datePipe.transform(plage.StartDate, 'EEEE : ', '', this.app.translate.currentLang.toUpperCase())} ${this.datePipe.transform(plage.StartDate, 'shortDate')} `
            })
            filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = StartDate;
          }
            break;
          default:
            break;
        }
      });
      return filteredColumns;
    })
  }

  exportExcel() {
    console.log('exportExcel !!!');

    // import('xlsx').then((xlsx) => {
    //     const worksheet = xlsx.utils.json_to_sheet(this.products);
    //     const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    //     const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    //     this.saveAsExcelFile(excelBuffer, 'products');
    // });
    this.helperService.exportExcel(this.vmDos, this.mapColumn(), 'vmDos_export_' + new Date().toLocaleDateString(), true);
  }
  exportCSV() {
    this.helperService.exportCSV(this.vmDos, this.mapColumn(), 'vmDos_export_' + new Date().toLocaleDateString(), true);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    console.log('saveAsExcelFile !!!');

    // let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    // let EXCEL_EXTENSION = '.xlsx';
    // const data: Blob = new Blob([buffer], {
    //     type: EXCEL_TYPE
    // });
    // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  deleteFoldersSelected(_foldersSelected: any) {
    console.log('deleteFoldersSelected ==> ', _foldersSelected)
    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteFolder'), 'AUTRE', 'confirm', () => {
      this.deleteFolders(_foldersSelected);
    }, () => {
      //this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
    });
  }

  deleteFolders(_foldersSelected: any) {
    console.log('deleteFolders TODO ==> ', _foldersSelected);
    //this.messageService.add({ severity: 'success', summary: 'Folder deleted successfully', detail: "You have accepted" });
  }

  onchangeSelectedAction(selectedAction: any) {
    console.log(selectedAction)
  }

  exportFoldersToCsv(): void {
    this.vmDosExport();
  }


  getActionsMenuByFolder(folder: any): any {
    return this.items.filter((item: any) => item.ActionID);
  }

  //#region private methods
  private unsubscribeActiveSubscriptions(): void {
    if (this.vmDosExportSubscription) {
      this.vmDosExportSubscription.unsubscribe();
    }

    if (this.companyAutocompleteSubscription) {
      this.companyAutocompleteSubscription.unsubscribe();
    }
  }

  private vmDosExport(): void {
    this.vmDosExportSubscription = this.app.apiService.exportEP(this.exportSearch, environment.visitmanagerRefCtrl.dolist_export).subscribe({
      next: (data: any) => {
        console.log('vmDosExport', data);
        if (data) {
          let filesUrl: string = data;

          window.open(filesUrl);
        }

      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }


  private consultDirectExhibitor(folderId: any): void {
    // ==> new code
    this.app.apiService.getUrlAutologin(folderId).subscribe({
      next: (data: any) => {
        console.log('[getUrlAutologin] API =>', data);

        if (data) {
          if (data.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
            return;
          }
          window.open(data);
        }
      },
      error: (error: any) => {
        console.log(error);
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_ERROR'), 'KO', "alert", () => { }, () => { });
        return;
      }
    });
  }

  private initAdvancedSearch(): void {
    let findSelectedStatus = this.status.find((c: any) => c.StatusID == this.search.selectedStatus?.StatusID);
    let findSelectedTypeCategory = this.typeCategory.find((c: any) => c.CategoryID == this.search.selectedTypeCategory?.CategoryID);
    let findSelectedDetailGroupNumberPeople = this.vmDetailGroupNumberPeople.find((c: any) => c.EtatID === this.search.selectedDetailGroupNumberPeople?.EtatID);
    let findSelectedGroupSectorType = this.groupSectorType.find((c: any) => c.CategoryID == this.search.selectedGroupSectorType?.CategoryID);
    let findSelectedGroupExternalImportActive = this.vmGroupExternalImportActive.find((c: any) => c.EtatID === this.search.selectedGroupExternalImportActive?.EtatID);


    this.search.selectedStatus = findSelectedStatus;
    this.search.selectedGroupExternalImportActive = findSelectedGroupExternalImportActive;
    this.search.selectedTypeCategory = findSelectedTypeCategory;
    this.search.selectedDetailGroupNumberPeople = findSelectedDetailGroupNumberPeople;
    this.search.selectedGroupSectorType = findSelectedGroupSectorType;


    let selectedOptions = this.countries.filter((option: any) =>
      this.search?.selectedCountries?.some((selected: any) => selected.CountryID === option.CountryID)
    );
    this.search.selectedCountries = selectedOptions
  }

  getColorStatus(status: any) {
    let classColor = "";
    if (status) {
      switch (status.Code) {
        case StatusCode.SCHEDULED_OD:
          classColor = "color-light-blue"
          break;

        case StatusCode.DATE_CONFIRMED_OD:
          classColor = "color-lime-green"
          break;

        case StatusCode.CANCELLED_OD:
          classColor = "color-red"
          break;

        case StatusCode.ARCHIVED_OD:
          classColor = "color-light-grey"
          break;

        default:
          classColor = "color-dark-blue"
          break;
      }
    }

    return classColor;
  }

  importCsv(event: any) {
    let selectedFileExtension = event.target.files[0]?.name?.split('.')[1]
    if (selectedFileExtension == "xlsx" || selectedFileExtension == "xls" || selectedFileExtension == "csv") {
      this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmImportDo'),
        'AUTRE', 'confirm'
        , () => {
          const file: File = event.target.files[0]
          this.app.apiService.uploadDoCsv(file, this.app.translate.currentLang).subscribe(
            data => {
              if (data && data?.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                return;
              }
              else {
                this.msgAlertImport = data?.Data?.InnerHTML_EN ?? ""
                if (this.app.translate.currentLang == Languages.French) {
                  this.msgAlertImport = data?.Data?.InnerHTML_FR ?? ""
                } else if (this.app.translate.currentLang == Languages.Spanish) {
                  this.msgAlertImport = data?.Data?.InnerHTML_ES ?? ""
                }
                this.showModal = true
              }
            }
          )
        }
        , () => {
          this.resetFileInput()
        });

    } else {
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorInvalidFileFormat'), 'KO', "alert", () => { }, () => { });
      return;
    }

  }

  resetFileInput() {
    this.showModal = false
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = ""
    }
  }

  //#endregion
}
