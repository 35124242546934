import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { FormGroup, Validators, FormBuilder, FormArray, AbstractControl, FormControl } from "@angular/forms";
import { ProductInnov } from '../models/product-innov';
import { WebsiteRegex } from '../consts/utils';
import { TreeNode } from 'primeng/api';
import * as _ from 'lodash';
import { CustomValidators } from '../validators/custom-validators';
import { TranslatePipe } from '@ngx-translate/core';
import { FicheOpenMode } from '../enums/fiche-open-mode';
import { SpinnerService } from '../services/spinner.service';
import { Chips } from 'primeng/chips';
import { Languages } from '../enums/language';
import { FormCode } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-form-produit-description',
    templateUrl: './form-produit-description.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./form-produit-description.component.less']
})
export class FormProduitDescriptionComponent {
    user: any = null;
    newProductForm!: FormGroup;
    submitted: boolean = false;
    product: ProductInnov = new ProductInnov();
    initForm: boolean = false;
    timelineSteps: any = [];
    layout: any = "horizontal";
    nomenclatures!: TreeNode[];
    selectedNodes: any = [];
    categories: any = [];
    tree: any = {};
    productHaveOtherCategory: boolean = false;
    productId: any;
    isEditMode: boolean = false;
    formInnovID!: number;
    paths = location.pathname.split('/');
    currentPath: any = null;
    dialogVisible: boolean = false;
    form: FormGroup;
    activeStepIndex: number = 0;
    IsNormesBy: boolean = false;
    isNomenclatureHaveChild: boolean = true
    frmData: any
    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private translate: TranslatePipe,
        private spinnerService: SpinnerService,
        private fb: FormBuilder
    ) {
        this.getFormInnovData();
        this.form = this.fb.group({
            Normes: ['', [Validators.required]],
        });
    }

    inputText: any;
    onBlurTest(event: any) {

        // if( event.keyCode == 13 || event.key === "Enter" ){
        //     console.log("You press enter key !!==> ", event.keyCode); 
        // }

        let eventKey = new KeyboardEvent('keyup', {
            key: 'Enter',
            keyCode: 13,
            which: 13
        });

        document.dispatchEvent(eventKey);

        // console.log("You press enter key !!==> ", eventKey);
    }
    onBlur(event: any) {
        const normesControl = this.form.get('Normes') as FormControl;
        let tags = normesControl.value as string[];


        const inputText = event.target.value;

        if (inputText && inputText.trim() !== '' && !tags.includes(inputText)) {
            if (!Array.isArray(tags)) {
                tags = [];
            }
            console.log("TAGGGGGGGG======>>>>", inputText)
            tags.push(inputText.trim());
            normesControl.setValue(tags);
        }
    }
    onInput(event: any) {
        const normesControl = this.form.get('Normes') as FormControl;
        let tags = normesControl.value as string[];
        const inputText = event.target.value;

        if (inputText.endsWith('')) {
            const newTag = inputText.trim();
            console.log("TAGGGGGGGG 2======>>>>", newTag)
            if (newTag) {
                if (!Array.isArray(tags)) {
                    tags = [];
                }
                tags.push(newTag);
                normesControl.setValue(tags);
            }
        }
    }


    async getFrmInfo() {
        let request = { frmType: "" }
        request.frmType = FormCode.FRM_INNOV
        let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
        if (data != undefined && data.Id != undefined) {
            this.frmData = data
        }
    }

    async ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log("this.user ==> ", this.user);

        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }

        this.app.route.queryParams
            .subscribe(params => {
                console.log('[params]', params);
                if (params && params['productid']) {
                    this.productId = params['productid'];
                }
            });

        //  this.app.sharedTools.changeUsrLangInInit();

        // this.app.sharedTools.changeUsrLangInInit();

        // this.app.sharedTools.getCurrentLanguage();

        this.app.sharedTools.getJSON('general_data').subscribe(
           async resG => {
                console.log("resG ==> ", resG);

                this.timelineSteps = resG.timelineSteps;

                this.getCategories();

                this.getFrmInfo()

                if (this.productId) {
                    this.isEditMode = true;
                    this.getProduct(this.productId);
                    console.log("this.productId ==> ", this.productId);
                }
                else {
                    this.isEditMode = false;

                }
                setTimeout(() => {
                    this.addNewProductForm();
                }, 500);


            })
    }

    onProductHaveOtherCategoryChange(value: boolean): void {
        const otherActivityFormControl = this.newProductForm.get('OtherCategory')!;
        console.log("this.companyHaveOtherActivity", this.productHaveOtherCategory)

        if (this.productHaveOtherCategory) {
            otherActivityFormControl.setValidators([Validators.required]);
        }
        else {
            otherActivityFormControl.setValidators(null);
            otherActivityFormControl.setValue('');
        }
        otherActivityFormControl.updateValueAndValidity();

        console.log("otherActivityFormControl", otherActivityFormControl)
    }

    async getCategories() {
        try {
            const response = await this.app.apiService.getCategories();

            let categoriesIni = _.cloneDeep(response);
            let categories = categoriesIni.filter((cat: any) => {
                return cat.CategoryType == "ACTIVITIES"
            });
            this.categories = _.cloneDeep(categories);

            categories.sort((a: any, b: any) => {
                return a?.ViewOrder - b?.ViewOrder
            })

            categories.forEach((cat: any) => {
                const parentId = cat.ParentCategoryID;
                if (!this.tree[parentId]) {
                    this.tree[parentId] = [];
                }
                this.tree[parentId].push(cat);
            });

            this.nomenclatures = this.buildTree(null);
            this.isNomenclatureHaveChild = this.nomenclatures.some((x: any) => {
                return x?.children.length > 0
            })
            console.log('this.nomenclatures ==> ', this.nomenclatures);
        } catch (error) {
            console.error(error);
        }
    }

    buildTree(parentId: any) {
        const children = this.tree[parentId] || [];
        return children.map((child: any) => {
            const node = {
                key: child.CategoryID,
                label: this.app.sharedTools.getLabelSwitchLang(child),
                LabelEN: child.LabelEN,
                LabelFR: child.LabelFR,
                LabelES: child.LabelES,
                data: '',
                icon: '',
                children: this.buildTree(child.CategoryID)
            };
            return node;
        });
    }


    addNewProductForm() {
        console.log("addNewProductForm !!!")
        const notRequiredValidators: any = this.product.getListNotRequiredsInStep2();

        const formModel: any = {};

        console.log("addNewProductForm product ==> ", this.product)

        let prod: any = _.cloneDeep(this.product);
        for (const propertyName in this.product) {
            if (this.product.hasOwnProperty(propertyName) && propertyName != 'Categories'
                && propertyName != 'DescriptionFR'
                && propertyName != 'DescriptionEN'
                && propertyName != "InnovationAspectFR"
                && propertyName != "InnovationAspectEN"
                && propertyName != "PotentialEN"
                && propertyName != "PotentialFR") {
                const propertyValue = prod[propertyName];
                let validators: any = [];

                if (notRequiredValidators.indexOf(propertyName) == -1) validators.push(Validators.required);
                if (propertyName == 'Website') validators.push(Validators.pattern(WebsiteRegex));
                formModel[propertyName] = [propertyValue, validators];

            }
        }
        formModel['DescriptionFR'] = [prod.DescriptionFR, [Validators.required, Validators.maxLength(600)]];
        formModel['DescriptionEN'] = [prod.DescriptionEN, [Validators.required, Validators.maxLength(600)]];
        formModel['InnovationAspectFR'] = [prod.InnovationAspectFR, [Validators.required, Validators.maxLength(400)]];
        formModel['InnovationAspectEN'] = [prod.InnovationAspectEN, [Validators.required, Validators.maxLength(400)]];
        formModel['PotentialEN'] = [prod.PotentialEN, [Validators.maxLength(500)]];
        formModel['PotentialFR'] = [prod.PotentialFR, [Validators.maxLength(500)]];


        console.log("-----------form group ------------", formModel)
        this.newProductForm = this.formBuilder.group(formModel);
        this.setConditionalValidators();
        this.newProductForm.get('TlrLader')?.setValidators(CustomValidators.RequiredNotNullOrZero());
        this.newProductForm.get('TlrLader')?.updateValueAndValidity();
        this.newProductForm.addControl('Categories', this.formBuilder.array(this.product?.Categories ?? []));
        this.newProductForm.addValidators(CustomValidators.atLeastHaveOneCategoryValidator());


        console.log("this.newProductForm ==> ", this.newProductForm);
        this.initForm = true;
    }


    nodeUnselect(event: any) {
        if (this.selectedNodes.length == 0) {
            this.submitted = true
        }
    }


    onChangeValueForm(_value: any, _valueParent: any, maxLengthValidator : number | null = null) {
        const value = this.newProductForm.get(_value)!;

        this.newProductForm.get(_valueParent)?.valueChanges
            .subscribe(_valueParent => {
                if (_valueParent) {
                    if(maxLengthValidator != null){
                        value.setValidators([Validators.required, Validators.maxLength(maxLengthValidator)]);
                    }else {
                        value.setValidators([Validators.required]);
                    }
                }
                else {
                    value.setValidators(null);
                    value.setValue('');
                }
                value.updateValueAndValidity();
            });
    }

    setConditionalValidators() {
        this.newProductForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });

        this.onChangeValueForm('IsofficialCommunicationDetail', 'ProductAlreadyPresented');
        this.onChangeValueForm('IsupportedName', 'IsupportedBy');
        this.onChangeValueForm('Normes', 'IsConformTo');
        this.onChangeValueForm('IspowerTransitionDetailFR', 'IspowerTransition', 500);
        this.onChangeValueForm('IspowerTransitionDetailEN', 'IspowerTransition', 500);
    }

    async UpdateProduct(productObj: any): Promise<any> {
        try {
            console.log('UPDATE Product ===>', productObj);
            const response = await this.app.apiService.updateProduct(productObj);
            console.log('RESPONSE UPDATE Product ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                }, () => {
                })
                return;
            }

            this.onNextStep();

        } catch (error) {
            console.error(error);
        }
    }
    setActiveStep(index: number) {
        this.activeStepIndex = index;
        this.newProductForm.get('TlrLader')?.setValue(this.activeStepIndex);

    }
    onSubmit() {
        this.submitted = true;
        this.messageService.clear();

        if (this.newProductForm.get('IspowerTransition')?.value == false) {
            this.newProductForm.get('IspowerTransitionDetailFR')?.setValidators(Validators.nullValidator);
            this.newProductForm.get('IspowerTransitionDetailFR')?.updateValueAndValidity();
        }
        else {
            this.newProductForm.get('IspowerTransitionDetailFR')?.setValidators([Validators.maxLength(500), Validators.required]);
            this.newProductForm.get('IspowerTransitionDetailEN')?.setValidators([Validators.maxLength(500), Validators.required]);
            this.newProductForm.get('IspowerTransitionDetailFR')?.updateValueAndValidity();
            this.newProductForm.get('IspowerTransitionDetailEN')?.updateValueAndValidity();
        }

        // Logic to handle form submission
        if (this.newProductForm.invalid) {
            console.log("Is invalid !!!!", this.newProductForm.invalid);

            const invalidValues: any = {};
            const controls = this.newProductForm.controls;
            console.log('control ------>>>>>', this.newProductForm.controls);
            let idNomenclatures = document.getElementById('nomenclatures');
            if (this.newProductForm.errors?.['atLeastHaveOneActivityRequired']) {
                idNomenclatures?.classList.add('ng-invalid');
            }

            let invalidFields: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('ng-invalid') as HTMLCollectionOf<HTMLElement>;

            for (const controlName in controls) {
                if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                    invalidValues[controlName] = controls[controlName].value;

                }
            }
            console.log(invalidFields)
            console.log('Invalid form values:', invalidValues);
            console.log('this.newProductForm:', this.newProductForm);

            if (invalidFields && invalidFields.length > 1) this.app.formInvalid.addSubmittedInvalidClass(invalidFields);

            this.messageService.add({ key: 'custom', severity: 'error', summary: this.app.translate.instant('racine.errorsOther.errorForm'), detail: '', sticky: true });
        }
        else {

            console.log(this.nomenclatures)

            //
            // ==> Ici on save le step1 du product
            let product: any = this.newProductForm.value;
            this.product.mapFormStep2Form(product);

            this.UpdateProduct(this.product);
        }
    }

    onChipsFocusout(formControlName: string, chipRef: Chips): void {
        if (formControlName && formControlName !== '') {
            let formControl = this.newProductForm.get(formControlName);
            if (formControl) {
                let nonTaggedValue: any = chipRef.inputViewChild.nativeElement.value;
                if (nonTaggedValue && nonTaggedValue !== '') {
                    if (!formControl.value) {
                        formControl.setValue([]);
                    }

                    formControl.value.push(nonTaggedValue);
                    formControl.updateValueAndValidity();
                }
                chipRef.inputViewChild.nativeElement.value = null;
            }
        }
    }

    private getFormInnovData(): void {
        let innovform: any = JSON.parse(sessionStorage.getItem('innov-form') ?? '{}');

        if (innovform) {
            this.formInnovID = innovform.InnoFormID;
            //this.folder = f18form.FolderID;
        }
    }

    async getProduct(solId: any) {
        let response = await this.app.apiService.getProductById(solId);
        console.log('this.product resp: ', response)
        this.product.mapFormGetResponse(response);
        if (this.product.OtherCategory) {
            this.productHaveOtherCategory = true;
        }

        if (this.product.Categories) {
            this.product.Categories.forEach((cat: any) => {
                console.log('cat ==> ', cat);
                setTimeout(() => {
                    this.selectNodesByData(cat, this.nomenclatures);
                }, 500);
            });

        }
        console.log('this.selectNodesByData : ', this.selectedNodes)
        console.log('this.product : ', this.product)
        this.addNewProductForm();

    }

    onPreviousStep(): void {
        this.onPrevStep()
    }

    onCancelStep(): void {
        this.app.sharedTools.goTo('/products-innov')
    }

    refreshFormControlCategories(): void {
        // ==> We format the nodes
        let categories = this.app.sharedTools.getCategoriesFromSelectedNodes(this.categories, this.selectedNodes);
        //set activities to Activities form control
        this.setCategoriesToForm(categories);
    }

    private setCategoriesToForm(newValues: any): void {
        let categoriesControl = this.newProductForm.get('Categories') as FormArray;

        // Clear the existing controls in the FormArray
        categoriesControl.clear();

        // Add the new values to the FormArray as individual controls
        newValues.forEach((value: any) => {
            categoriesControl.push(this.formBuilder.control(value));
        });
    }

    selectNodesByData(data: any, nodes: TreeNode[] = this.nomenclatures) {
        //console.log("this.nomenclatures:", this.nomenclatures);
        //console.log("nodes", nodes);
        try {
            for (const node of nodes) {
                if (node.key === data) {
                    // if (node.parent) this.expandsNodes(node, nodes);
                    this.selectedNodes.push(node);
                    break;
                }
                if (node.children) {
                    this.selectNodesByData(data, node.children);
                }
            }
        } catch (error) {
            console.warn(error);
        }
    }
    //#region Generer les textes
    onGenerateDescriptionClick() {

        this.app.confirm.confirmDialog(''
            , this.app.translate.instant('fo.productFormInnovation.confirmChatgptGeneration')
            , 'AUTRE'
            , 'confirm'
            , () => {
                this.generateChatGptDescription()
            }
            , () => { });

        

    }

    generateChatGptDescription(){
        let requestObj = {
            enTranslationDescription: this.newProductForm.get('DescriptionFR')?.value,
            frTranslationDescription: this.newProductForm.get('DescriptionEN')?.value,
            productUrl: this.product.SolUrl
        }
        console.log("translate", requestObj)

        let requestMsg: string = `${this.translate.transform('productDescriptionGenerator.translate-json-command')} ${JSON.stringify(requestObj)}`;

        let request: any = {
            Message: requestMsg,
            JsonObject: {}
        }

        this.chatgptGeneratePresentation(request);

        console.log("translate", request)
    }

    // private async chatgptGeneratePresentation(request: any){
    //     try {
    //         this.spinnerService.disableSpinner();
    //         this.dialogVisible = true
    //             const response = await this.app.apiService.chatgptGeneratePresentation(request);
    //             console.log('chatgptGeneratePresentation re ===>', response);

    //             if (response && response.Result) {
    //                 this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
    //                 }, () => {
    //                 })
    //                 return;
    //             }

    //             this.mapGenertedDescription(JSON.parse(response));
    //             this.dialogVisible = false;
    //             this.spinnerService.enableSpinner();

    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    private mapGenertedDescription(description: any): void {
        console.log("InnovationAspectENControl", description);
        if (description && Array.isArray(description) && description.length > 0) {
            let descriptionENControl: any = this.newProductForm.get('DescriptionEN');
            let descriptionFRControl: any = this.newProductForm.get('DescriptionFR');


            if (descriptionENControl && description[0].DescriptionEN) {
                descriptionENControl.setValue(description[0].DescriptionEN);
            }

            if (descriptionFRControl && description[0].DescriptionFR) {
                descriptionFRControl.setValue(description[0].DescriptionFR);
            }
            console.log("descriptionENControl1", descriptionENControl)
        }
        else if (description) {
            let descriptionENControl: any = this.newProductForm.get('DescriptionEN');
            let descriptionFRControl: any = this.newProductForm.get('DescriptionFR');

            if (descriptionENControl && description.DescriptionEN) {
                descriptionENControl.setValue(description.DescriptionEN);
            }
            if (descriptionENControl && description.enTranslationDescription) {
                descriptionENControl.setValue(description.enTranslationDescription);
            }
            if (descriptionFRControl && description.DescriptionFR) {
                descriptionFRControl.setValue(description.DescriptionFR);
            }
            if (descriptionFRControl && description.frTranslationDescription) {
                descriptionFRControl.setValue(description.frTranslationDescription);
            }



        }
    }

    onCancelClick() {
        this.dialogVisible = false;
    }
    private async chatgptGeneratePresentation(request: any) {
        try {
            this.spinnerService.disableSpinner();
            this.dialogVisible = true;
            const timerPromise = new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 15000);
            });

            const apiRequestPromise = this.app.apiService.chatgptGeneratePresentation(request);

            const response = await Promise.race([apiRequestPromise, timerPromise]);

            if (response === timerPromise) {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                }, () => {
                });
                this.dialogVisible = false;
                return;
            }

            console.log('chatgptGeneratePresentation response ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', 'alert', () => {
                }, () => {
                });
                return;
            }
            //   console.log('Response Data:', JSON?.parse(response));
            //  if(JSON?.parse(response)!=undefined){
            //     this.mapGenertedDescription(JSON.parse(response));
            //     this.dialogVisible = false;
            //     this.spinnerService.enableSpinner();
            //  }
            //  else{
            //     this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
            //     }, () => {
            //     });
            //     this.dialogVisible = false; 
            //  }
            // } catch (error) {
            //   console.error(error);
            // }
            console.log('Response Data:', response);

            if (response !== undefined) {
                try {
                    const parsedResponse = JSON.parse(response);
                    this.mapGenertedDescription(parsedResponse);
                    this.dialogVisible = false;
                    this.spinnerService.enableSpinner();
                } catch (error) {
                    console.error('Error parsing response:', error);
                    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                    }, () => {
                    });
                    this.dialogVisible = false;
                }
            } else {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                }, () => {
                });
                this.dialogVisible = false;
            }

        } catch (error) {
            console.error('Error parsing response:', error);
            this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
            }, () => {
            });
            this.dialogVisible = false;
        }
    }


    //#endregion

    //#region  traduire le texte
    onTranslateToENClick() {
        let requestObj = {
            DescriptionFR: this.newProductForm.get('DescriptionFR')?.value,
        }
        console.log("translate", requestObj)

        let requestMsg: string = `${this.translate.transform('productTranslateENGenerator.translate-json-command')} '${requestObj.DescriptionFR}'`;

        let request: any = {
            Message: requestMsg,
            JsonObject: {}
        }

        this.chatgptTranslate(request, Languages.English);

        console.log("translate", request)

    }

    onTranslateToFRClick() {
        let requestObj = {
            DescriptionEN: this.newProductForm.get('DescriptionEN')?.value,
        }
        console.log("translate", requestObj)

        let requestMsg: string = `${this.translate.transform('productTranslateFRGenerator.translate-json-command')} '${requestObj.DescriptionEN}'`;

        let request: any = {
            Message: requestMsg,
            JsonObject: {}
        }

        this.chatgptTranslate(request, Languages.French);

        console.log("translate", request)

    }
    onTranslateInnovationToENClick() {
        let requestObj = {
            InnovationAspectFR: this.newProductForm.get('InnovationAspectFR')?.value,
        }
        console.log("translate", requestObj)

        let requestMsg: string = `${this.translate.transform('productTranslateInnovationENGenerator.translate-json-command')} '${requestObj.InnovationAspectFR}'`;

        let request: any = {
            Message: requestMsg,
            JsonObject: {}
        }

        this.chatgptTranslate(request, Languages.English);

        console.log("translate", request)
    }
    onTranslateInnovationToFRClick() {
        let requestObj = {
            InnovationAspectEN: this.newProductForm.get('InnovationAspectEN')?.value,
        }
        console.log("translate", requestObj)

        let requestMsg: string = `${this.translate.transform('productTranslateInnovationFRGenerator.translate-json-command')} '${requestObj.InnovationAspectEN}'`;

        let request: any = {
            Message: requestMsg,
            JsonObject: {}
        }

        this.chatgptTranslate(request, Languages.French);

        console.log("translate", request)
    }
    onTranslateIspowerTransitionToENClick() {
        let requestObj = {
            IspowerTransitionFR: this.newProductForm.get('IspowerTransitionDetailFR')?.value,
        }
        console.log("translate", requestObj)

        let requestMsg: string = `${this.translate.transform('productTranslateIspowerTransitionDetailENGenerator.translate-json-command')} '${requestObj.IspowerTransitionFR}'`;

        let request: any = {
            Message: requestMsg,
            JsonObject: {}
        }

        this.chatgptTranslate(request, Languages.English);

        console.log("translate", request)
    }

    onTranslatePotentialToFRClick() {
        let requestObj = {
            IspowerTransitionFR: this.newProductForm.get('PotentialEN')?.value,
        }
        console.log("translate", requestObj)

        let requestMsg: string = `${this.translate.transform('productTranslatePotentialFRGenerator.translate-json-command')} '${requestObj.IspowerTransitionFR}'`;

        let request: any = {
            Message: requestMsg,
            JsonObject: {}
        }

        this.chatgptTranslate(request, Languages.French);

        console.log("translate", request)
    }

    onTranslatePotentialToENClick() {
        let requestObj = {
            IspowerTransitionFR: this.newProductForm.get('PotentialFR')?.value,
        }
        console.log("translate", requestObj)

        let requestMsg: string = `${this.translate.transform('productTranslatePotentialENGenerator.translate-json-command')} '${requestObj.IspowerTransitionFR}'`;

        let request: any = {
            Message: requestMsg,
            JsonObject: {}
        }

        this.chatgptTranslate(request, Languages.English);

        console.log("translate", request)
    }
    onTranslateIspowerTransitionToFRClick() {
        let requestObj = {
            IspowerTransitionEN: this.newProductForm.get('IspowerTransitionDetailEN')?.value,
        }
        console.log("translate", requestObj)

        let requestMsg: string = `${this.translate.transform('productTranslateIspowerTransitionDetailFRGenerator.translate-json-command')} '${requestObj.IspowerTransitionEN}'`;

        let request: any = {
            Message: requestMsg,
            JsonObject: {}
        }

        this.chatgptTranslate(request, Languages.French);

        console.log("translate", request)
    }

    private async chatgptTranslate(request: any, verslanguage: string) {
        try {
            this.spinnerService.disableSpinner();
            this.dialogVisible = true;
            const timerPromise = new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 15000);
            });

            const apiRequestPromise = this.app.apiService.chatgptTranslateTexte(request);

            const response = await Promise.race([apiRequestPromise, timerPromise]);

            if (response === timerPromise) {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                }, () => {
                });
                this.dialogVisible = false;
                return;
            }
            console.log('chatgptTranslateTexte response ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', 'alert', () => {
                }, () => {
                });
                return;
            }
            console.log('Response Data:', response);


            if (response !== undefined) {
                try {
                    if (verslanguage === Languages.French) {
                        console.log('API Response for FR--->:', response);
                        this.mapTranslateFRTexte(JSON.parse(response));
                        this.dialogVisible = false;
                        this.spinnerService.enableSpinner();
                    }
                    else if (verslanguage === Languages.English) {
                        console.log('API Response for EN--->:', response);
                        this.mapTranslateENTexte(JSON.parse(response));
                        this.dialogVisible = false;
                        this.spinnerService.enableSpinner();
                    }
                } catch (error) {
                    console.error('Error parsing response:', error);
                    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                    }, () => {
                    });
                    this.dialogVisible = false;
                }
            } else {
                this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
                }, () => {
                });
                this.dialogVisible = false;
            }

        } catch (error) {
            console.error('Error parsing response:', error);
            this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
            }, () => {
            });
            this.dialogVisible = false;
        }
    }



    private mapTranslateENTexte(description: any): void {
        if (description && Array.isArray(description) && description.length > 0) {
            let descriptionENControl: any = this.newProductForm.get('DescriptionEN');
            let InnovationAspectENControl: any = this.newProductForm.get('InnovationAspectEN');
            let IspowerTransitionENControl: any = this.newProductForm.get('IspowerTransitionDetailEN');
            let PotentialENControl: any = this.newProductForm.get('PotentialEN');
            if (descriptionENControl && description[0].DescriptionEN) {
                descriptionENControl.setValue(description[0].DescriptionEN);
            }
            if (InnovationAspectENControl && description[0].InnovationAspectEN) {
                InnovationAspectENControl.setValue(description[0].InnovationAspectEN);
            }
            if (IspowerTransitionENControl && description[0].IspowerTransitionDetailEN) {
                IspowerTransitionENControl.setValue(description[0].IspowerTransitionDetailEN);
            }
            if (PotentialENControl && description[0].PotentialEN) {
                PotentialENControl.setValue(description[0].PotentialEN);
            }
        }
        else if (description) {
            let descriptionENControl: AbstractControl<any, any> | null = this.newProductForm.get('DescriptionEN');
            let InnovationAspectENControl: AbstractControl<any, any> | null = this.newProductForm.get('InnovationAspectEN');
            let IspowerTransitionENControl: AbstractControl<any, any> | null = this.newProductForm.get('IspowerTransitionDetailEN');
            let PotentialENControl: AbstractControl<any, any> | null = this.newProductForm.get('PotentialEN');

            if (descriptionENControl && description.DescriptionEN) {
                descriptionENControl.setValue(description.DescriptionEN);
            }
            if (InnovationAspectENControl && description.InnovationAspectEN) {
                InnovationAspectENControl.setValue(description.InnovationAspectEN);
            }
            if (IspowerTransitionENControl && description.IspowerTransitionDetailEN) {
                IspowerTransitionENControl.setValue(description.IspowerTransitionDetailEN);
            } 
            if (PotentialENControl && description.PotentialEN) {
                PotentialENControl.setValue(description.PotentialEN);
            } 
            console.log("IspowerTransitionEN", description);

        }
    }

    private mapTranslateFRTexte(description: any): void {
        if (description && Array.isArray(description) && description.length > 0) {
            let descriptionFRControl: any = this.newProductForm.get('DescriptionFR');
            let InnovationAspectFRControl: any = this.newProductForm.get('InnovationAspectFR');
            let IspowerTransitionFRControl: any = this.newProductForm.get('IspowerTransitionDetailFR');
            let PotentialFRControl: any = this.newProductForm.get('IspowerTransitionDetailFR');

            if (descriptionFRControl && description[0].DescriptionFR) {
                descriptionFRControl.setValue(description[0].DescriptionFR);
            }
            if (InnovationAspectFRControl && description[0].InnovationAspectFR) {
                InnovationAspectFRControl.setValue(description[0].InnovationAspectFR);
            }
            if (IspowerTransitionFRControl && description[0].IspowerTransitionDetailFR) {
                IspowerTransitionFRControl.setValue(description[0].IspowerTransitionDetailFR);
            }
            if (PotentialFRControl && description[0].PotentialFR) {
                PotentialFRControl.setValue(description[0].PotentialFR);
            }
        }
        else if (description) {
            let descriptionFRControl: AbstractControl<any, any> | null = this.newProductForm.get('DescriptionFR');
            let InnovationAspectFRControl: AbstractControl<any, any> | null = this.newProductForm.get('InnovationAspectFR');
            let IspowerTransitionFRControl: AbstractControl<any, any> | null = this.newProductForm.get('IspowerTransitionDetailFR');
            let PotentialFRControl: AbstractControl<any, any> | null = this.newProductForm.get('PotentialFR');

            if (descriptionFRControl && description.DescriptionFR) {
                descriptionFRControl.setValue(description.DescriptionFR);
            }
            if (InnovationAspectFRControl && description.InnovationAspectFR) {
                InnovationAspectFRControl.setValue(description.InnovationAspectFR);
            }
            if (IspowerTransitionFRControl && description.IspowerTransitionDetailFR) {
                IspowerTransitionFRControl.setValue(description.IspowerTransitionDetailFR);
            } 
            if (PotentialFRControl && description.PotentialFR) {
                PotentialFRControl.setValue(description.PotentialFR);
            } 
            console.log("IspowerTransitionFR", description);

        }
    }

    //#endregion


    // onNextStep(): void {
    //     //extract mode from route (edit or consult)
    //     const url = this.app.router.url;
    //     const segments = url.split('/');
    //     const segmentWithQueryParams = segments[segments.length - 1];
    //     const mode = segmentWithQueryParams.split('?')[0];

    //     console.log('mode ', mode); // This should log 'consult' or 'edit'

    //     if(mode === FicheOpenMode.Consult || mode === FicheOpenMode.Edit){
    //         let queryparams: any = {
    //             productid: this.productId
    //         }
    //         this.app.sharedTools.redirectToWithParameters(`product-medias/${mode}` , queryparams);
    //     }
    // }

    onNextStep(): void {
        let queryparams: any = {
            productid: this.productId
        }
        this.app.sharedTools.redirectToWithParameters(`product-medias/edit`, queryparams);
    }

    onPrevStep(): void {
        let queryparams: any = {
            productid: this.productId
        }
        this.app.sharedTools.redirectToWithParameters(`product-generalites/edit`, queryparams);
    }


}
