import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { SearchParticipant } from '../models/search-participant';
import { Subscription } from 'rxjs';
import { CategoryType } from '../enums/category-type';
import { StatusType } from '../enums/status-type';
import { RoleType } from '../enums/role-type';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { HelperService } from '../services/helper.service';
import { DatePipe } from '@angular/common';
import { Menu } from 'primeng/menu';
import { StatusCode } from '../enums/status-code';
import { AttendeesAdvancedSearchLocalStorageKey, CsvExportSameColumnSeparator } from '../consts/utils';
import { ImageModelSuffix } from '../enums/image-model-suffix';
import { PagePaginationInfo } from '../models/page-pagination-info';
import { SearchHelperService } from '../services/search-helper.service';

@Component({
  selector: 'app-liste-attendees',
  templateUrl: './liste-attendees.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./liste-attendees.component.less']
})
export class ListeAttendeesComponent implements OnInit {
  imageModelSuffix = ImageModelSuffix;
  fastSearchAttendeeKeyword: any
  showAdvancedSearch: boolean = false
  search: any = {};
  @ViewChild('dtAttendees') dtAttendees!: Table;
  badgeTypes: any
  roles: any
  roleList: { RoleID: number }[] = [];
  validationState: any
  activities: any[] = []

  interestCenters: any
  expertCategories: any
  networkingState: any;

  participants: any[] = []
  selectedColumns: any[] = []
  listeAttendanceColumns: any[] = []
  get fastFiltersParticipants(): string[] {
    return ['BadgeNumber'
      , `BadgeType.${this.app.sharedTools.getLangPropertyName('Label')}`
      , 'FirstName'
      , 'LastName'
      , 'FullName'
      , 'Email'
      , `Language.${this.app.sharedTools.getLangPropertyName('Label')}`
      , 'CompanyName'
      , 'Fonction'
      , `SelectedNetworkingState.${this.app.sharedTools.getLangPropertyName('Label')}`
      , `SelectedValidationState.${this.app.sharedTools.getLangPropertyName('Label')}`
      , 'Activity.CreationDate'
      , 'Activity.LastSyncDate'
      , 'Activity.ModifiedDate']
  }
  selectedParticpants: any[] = []
  selectedAction: any
  groupedActions: any[] = []
  showModal: boolean = false;

  pagePaginationInfo: PagePaginationInfo = { pageNumber: 1, rowSize: 50 }
  currentPageIndex: number = 1
  rowsCapacity: number = 50
  currentPageTable: number = 1
  totalRowsNmber: number = 0

  @ViewChild('menuAction') menuAction!: Menu;
  menuActionItems: any = [];
  constructor(public app: AppComponent,
    public helperService: HelperService,
    private datePipe: DatePipe, private searchHelperService: SearchHelperService) { }

  async ngOnInit() {


    let user = JSON.parse(sessionStorage.getItem('userData')!);
    if (!user) {
      this.app.router.navigate(['/login']);
      return;
    }

    this.app.sharedTools.getJSON('general_data').subscribe(
      data => {
        this.selectedColumns = data.colsParticipantList;
        this.listeAttendanceColumns = data.colsParticipantList;
        this.groupedActions = data.actionListeParticipant;
        this.menuActionItems = data.actionsAttendeeRow;
        this.menuActionItems.forEach((itemMenu: any) => {
          itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
        });
      }
    );

    this.pagePaginationInfo = this.searchHelperService.getPagePaginationInfo(AttendeesAdvancedSearchLocalStorageKey)
    this.rowsCapacity = this.pagePaginationInfo.rowSize ?? 50
    console.log("selectedColumns", this.selectedColumns);
    this.getRoles();
    await this.getCategoriesTypes();
    this.getStatusTypes();
    this.searchParticipant(this.search, false, true);
  }

  filterFast() {
    this.dtAttendees.filterGlobal(this.fastSearchAttendeeKeyword, 'contains');
  }

  loadData(event: TableLazyLoadEvent) {
    this.pagePaginationInfo.pageNumber = Math.floor((event.first ?? 0) / (event.rows ?? 1)) + 1;
    this.pagePaginationInfo.rowSize = event.rows ?? 10
    this.searchParticipant(this.search)
  }


  onActionSelectChange(action: any) {
    this.showModal = true
    if (action.value.ActionID == 1) {
      this.modalOptions = this.validationState;
    } else if (action.value.ActionID == 2) {
      this.modalOptions = this.networkingState;
    }
  }

  rejectAction() {
    this.showModal = false
  }
  modalOptions: any;
  async acceptAction(event: any) {
    console.log(this.selectedParticpants)
    if (this.selectedAction.ActionID == 2) {
      let body = {
        AttendeeIdList: this.selectedParticpants.map((x: any) => x.AttendeeID),
        StateID: event.StatusID
      };
      try {
        await this.app.apiService.SetNetworkingState(body);
        this.showModal = false;
        this.searchParticipant(this.search, false, true);;
        this.selectedAction = undefined;
        this.selectedParticpants = [];
      } catch (error) {
        console.error(error);
      }
    } else if (this.selectedAction.ActionID == 1) {
      let body = {
        AttendeeIdList: this.selectedParticpants.map((x: any) => x.AttendeeID),
        StateID: event.StatusID
      };
      try {
        await this.app.apiService.SetValidationState(body);
        this.showModal = false;
        this.searchParticipant(this.search);
        this.selectedAction = undefined;
        this.selectedParticpants = [];
      } catch (error) {
        console.error(error);
      }
    }
  }


  clearFastSearch() {
    this.fastSearchAttendeeKeyword = '';
    this.filterFast();
  }

  toggleAdvancedSearch() {
    this.showAdvancedSearch = !this.showAdvancedSearch
  }

  getTheTooltip() {
    return ""
  }
  ngOnDestroy(): void {
    this.unsubscribeActiveSubscriptions();
  }

  private unsubscribeActiveSubscriptions(): void {
    if (this.companiesnamecompleteSubscription) {
      this.companiesnamecompleteSubscription.unsubscribe();
    }

    if (this.namescompleteSubscription) {
      this.namescompleteSubscription.unsubscribe();
    }
  }

  private namescompleteSubscription?: Subscription;
  AttendeeNameSuggestions: string[] = [];
  AttendeeLastName: any;
  onSearchAttendeeName(event: any): void {
    let keyword: string | undefined = event.query;

    if (!keyword || keyword === '') {
      this.AttendeeNameSuggestions = [];
    }
    else {
      this.refreshAttendeeNameAutocomplete();
    }
  }
  refreshAttendeeNameAutocomplete() {

    this.namescompleteSubscription = this.app.apiService.attendeesNameAutocomplete(this.search.AttendeeLastName).subscribe({
      next: (data: any) => {
        console.log("data ===> ", data)
        if (data && data != '' && Array.isArray(data)) {
          this.AttendeeNameSuggestions = data;
        }
        else {
          this.AttendeeNameSuggestions = []
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }


  private companiesnamecompleteSubscription?: Subscription;
  CompanyNameSuggestions: string[] = [];
  CompanySuggestions: string[] = [];
  CompanyName: any;
  onSearchCompanyName(event: any) {
    let keyword: string | undefined = event.query;

    if (!keyword || keyword === '') {
      this.CompanyNameSuggestions = [];
    }
    else {
      this.refreshCompaniesNameAutocomplete();
    }
  }

  refreshCompaniesNameAutocomplete() {
    this.companiesnamecompleteSubscription = this.app.apiService.companiesNameAutocomplete(this.search.CompanyName).subscribe({
      next: (data: any) => {
        console.log("data ===> ", data)
        if (data && data != '' && Array.isArray(data)) {
          this.CompanyNameSuggestions = data;
        }
        else {
          this.CompanyNameSuggestions = []
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  async getRoles() {
    this.app.apiService.getRoles().subscribe((roleResponse: any) => {
      console.log("getRoles ==> ", roleResponse);
      if (roleResponse && roleResponse != '' && Array.isArray(roleResponse)) {
        this.roles = roleResponse.filter(role => role.RoleType === RoleType.Attendee);
        console.log("attendees role", this.roles);
      }
    });
  }

  async getCategoriesTypes() {
    let categoriesResponse = await this.app.apiService.getCategories();
    console.log('categoriesResponse : ', categoriesResponse);
    if (categoriesResponse && categoriesResponse != '' && Array.isArray(categoriesResponse)) {
      this.interestCenters = categoriesResponse.filter(category => category.CategoryType === CategoryType.ATTInterest);
      this.expertCategories = categoriesResponse.filter(category => category.CategoryType === CategoryType.ATTExpert);
      this.badgeTypes = categoriesResponse.filter(category => category.CategoryType == CategoryType.BadgeType)
      console.log('badgeTypes : ', this.badgeTypes);
      console.log('expertCategoriessss : ', this.expertCategories);
    }
    else {
      this.expertCategories = []
      this.interestCenters = []
    }
  }
  async getStatusTypes() {
    let data = await this.app.apiService.getStatus().subscribe((statusResponse: any) => {
      console.log('statusResponse : ', statusResponse);
      if (statusResponse && statusResponse != '' && Array.isArray(statusResponse)) {
        this.networkingState = statusResponse.filter(status => status.StatusType === StatusType.ATTNetworkState);
        this.validationState = statusResponse.filter(status => status.StatusType === StatusType.ATTValidState);
        console.log('networkingState : ', this.networkingState);
      }
      else {
        this.networkingState = []
        this.validationState = []
      }
    });
  }

  getInscriptionState() {

  }

  clearSearch() {
    this.search = new SearchParticipant();
    this.searchParticipant(this.search);
  }

  async searchParticipant(search: any, thenFilterFast: boolean = false, goLatestViewedPage: boolean = false) {
    console.log("search", search);

    let body: SearchParticipant = {
      BadgeNumber: search.BadgeNumber,
      BadgeType: search.BadgeType?.CategoryID,
      AttendeeLastName: search.AttendeeLastName,
      Email: search.Email,
      CompanyName: search.CompanyName,
      RoleID: search.selectedRoleType?.RoleID,
      ValidationStatusID: search.selectedValidation?.StatusID,
      ExpertInCategoriesIds: search.ExpertInCategoriesIds?.map((p: any) => { return p.CategoryID }),
      InterestCenterCategoriesIds: search.InterestCenterCategoriesIds?.map((p: any) => { return p.CategoryID }),
      NetworkingStatusID: search.activatedNetworking?.StatusID,
      NbElementsPerPage: this.pagePaginationInfo.rowSize,
      PageIndex: this.pagePaginationInfo.pageNumber
    };
    console.log("body", body)
    try {
      const response = this.app.apiService.specificSearchAttendee(body)
        .subscribe((data: any) => {
          this.totalRowsNmber = data.NbAttendees
          if (data && Array.isArray(data.ListAttendees)) {
            this.participants = data.ListAttendees;
            this.participants.forEach((p: any) => {
              //p.SelectedNetworkingState = this.networkingState.find((n: any) => n.StatusID == p.NetworkingState);
              p.SelectedNetworkingState = this.networkingState.find((n: any) => n.StatusID == p.NetworkingState);
              p.SelectedValidationState = this.validationState.find((n: any) => n.StatusID == p.ValidationState);
            });
            console.log("participants !!!", this.participants);
            if (goLatestViewedPage) {
              this.currentPageTable = ((this.pagePaginationInfo.pageNumber ?? 1) - 1) * (this.pagePaginationInfo.rowSize ?? 1);
            }
          }
        })
      console.log("setValidation !!!", response);

      // this.participants = response;


    } catch (error) {
      console.error(error);
    }

  }

  pageChanged(event: any) {
    let pageIndex = event.first / event.rows + 1
    this.currentPageIndex = pageIndex
    this.updatePaginationInfo(AttendeesAdvancedSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
  }
  rowsChanged(event: any) {
    this.rowsCapacity = event
    this.updatePaginationInfo(AttendeesAdvancedSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
  }

  updatePaginationInfo(key: string, currentPageIndex: number, rowCapacity: number) {
    let pagePaginationInfo: PagePaginationInfo = {
      pageNumber: currentPageIndex,
      rowSize: rowCapacity
    }
    this.searchHelperService.setPagePaginationInfo(pagePaginationInfo, key)
  }

  getColorStatus(status: any) {
    let classColor = "";
    if (status.StatusType === StatusType.ATTValidState) {
      switch (status.Code) {
        case StatusCode.VALIDATED:
          classColor = "color-lime-green"
          break;

        //uncomment and modify when having new status 
        // case StatusCode.CANCELED:
        //   classColor = "color-red"
        //   break;

        default:
          classColor = "color-black"
          break;
      }
    }
    else if (status.StatusType === StatusType.ATTNetworkState) {
      switch (status.Code) {
        case StatusCode.VALIDATED:
          classColor = "color-lime-green"
          break;
        //uncomment and modify when having new status 
        // case StatusCode.CANCELED:
        //   classColor = "color-red"
        //   break;

        default:
          classColor = "color-black"
          break;
      }
    }

    return classColor;
  }

  async onchangeValidationState(attendeeID: any, stateId: any) {
    let listIds = []
    listIds.push(attendeeID)
    let body = {
      AttendeeIdList: listIds,
      StateId: stateId
    };

    try {
      const response = await this.app.apiService.SetValidationState(body);
      console.log("setValidation !!!", response);
    } catch (error) {
      console.error(error);
    }
  }

  async onchangeNetworkingState(attendeeID: any, stateId: any) {
    let listIds = []
    listIds.push(attendeeID)
    let body = {
      AttendeeIdList: listIds,
      StateID: stateId
    };
    try {
      const response = await this.app.apiService.SetNetworkingState(body);
      console.log("setValidation !!!", response);
    } catch (error) {
      console.error(error);
    }
  }



  mapColumn() {
    console.log("selectedColumns", this.selectedColumns);
    console.log(this.validationState);
    return this.participants.map((row: any) => {
      const filteredColumns: any = {};
      this.selectedColumns.forEach(column => {
        switch (column.field) {
          case "BadgeNumber": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.BadgeNumber ?? '';
            break;

          case "BadgeType": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(row.BadgeType) ?? '';
            break;

          case "FullName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] =
            `${row.FullName ?? ''}${CsvExportSameColumnSeparator}`
            + `${row.Email ?? ''}${CsvExportSameColumnSeparator}`
            + `${row.ProfilePicture ?? ''}${CsvExportSameColumnSeparator}`
            + `${this.app.sharedTools.getLabelSwitchLang(row.Language) ?? ''}`;
            break;

          case "CompanyName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.CompanyName ?? '';
            break;

          case "Fonction": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Fonction ?? '';
            break;

          case "ValidationState": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(row.SelectedValidationState) ?? '';
            break;

          case "NetworkingState": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(row.SelectedNetworkingState) ?? '';
            break;

          case "Activity": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] =
            `${this.app.translate.instant('bo.liste-attendees.DateTimeSync')} ${row.Activity.LastSyncDate
              ? this.datePipe.transform(row.Activity.LastSyncDate, 'dd/MM/yyyy HH:mm:ss')
              : ''}${CsvExportSameColumnSeparator}`

            + `${this.app.translate.instant('bo.liste-attendees.DateTimeCreation')} ${row.Activity.CreationDate
              ? this.datePipe.transform(row.Activity.CreationDate, 'dd/MM/yyyy HH:mm:ss')
              : ''
            }${CsvExportSameColumnSeparator}`

            + `${this.app.translate.instant('bo.liste-attendees.DateTimeModified')} ${row.Activity.ModifiedDate
              ? this.datePipe.transform(row.Activity.ModifiedDate, 'dd/MM/yyyy HH:mm:ss')
              : ''
            }`;
            break;

          default:
            break;
        }
      });
      return filteredColumns;
    })
  }
  exportCSV() {
    let fileName: string = "";
    fileName = this.app.translate.instant('bo.liste-attendees.fileName');
    fileName = fileName && fileName != '' ? fileName : 'list_attendees';
    let dateStr: string = this.datePipe.transform(new Date(), 'dd_MM_yyyy') ?? "";
    let hourStr: string = this.datePipe.transform(new Date(), 'HH_mm_ss') ?? "";
    fileName = fileName.replace('{date}', dateStr);
    fileName = fileName.replace('{hour}', hourStr);
    this.helperService.exportCSV(this.participants, this.mapColumn(), fileName, true);
  }


  toggleMenu(event: any) {
    this.menuAction.toggle(event);

    this.menuActionItems.forEach((itemMenu: any) => {
      itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

      if (itemMenu.ActionID == 1) {
        itemMenu.command = (event: any) => {
        }
      }

      if (itemMenu.ActionID == 2) {
        itemMenu.command = (event: any) => {
        }
      }
    });
  }
}
