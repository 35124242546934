import { Injectable } from '@angular/core';
import { SearchFolder } from '../models/search-folder';
import { FolderSearchLocalStorageKey } from '../consts/utils';

@Injectable({
    providedIn: 'root'
})
export class FolderSearchHelperService {
    private _advancedSearch: any;

    get advancedSearch(): any {
        this.extractFromLocalStorage();
        return this._advancedSearch;
    }

    set advancedSearch(value: any) {
        this._advancedSearch = value;
        this.saveToLocalStorage();
    }

    private _fastSearchKeyword: any;
    get fastSearchKeyword(): any {
        this.extractFromLocalStorage();
        return this._fastSearchKeyword;
    }
    set fastSearchKeyword(value: any) {
        this._fastSearchKeyword = value;
        this.saveToLocalStorage();
    }

    constructor() { this._advancedSearch = new SearchFolder(); }

    private saveToLocalStorage(): void {
        let searchModel: any =
        {
            'advancedSearch': this._advancedSearch,
            'fastSearchKeyword': this._fastSearchKeyword
        };

        localStorage.setItem(FolderSearchLocalStorageKey, JSON.stringify(searchModel));
    }

    private extractFromLocalStorage(): void {
        let searchModelJson = localStorage.getItem(FolderSearchLocalStorageKey);
        if (searchModelJson && searchModelJson != '') {
            let searchModel: any = JSON.parse(searchModelJson);
            this._advancedSearch = Object.assign(new SearchFolder(), searchModel['advancedSearch']);
            this._fastSearchKeyword = searchModel['fastSearchKeyword'];
        }
        else {
            this._advancedSearch = new SearchFolder();
            this._fastSearchKeyword = '';
        }
    }
}
