import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tag.component.less']
})
export class TagComponent {

  @Input() tag: any = "";
  @Input() isDark: boolean = true;
  @Input() isUpper: boolean = false;
  @Input() styleClass?: string = "";

}
