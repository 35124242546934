import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { IdentityManagerService } from '../services/identity-manager.service';
import { cloneDeep } from 'lodash';
import { APPID } from '../enums/app_id';
import { Languages } from '../enums/language';
import { FormCode } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-welcome-f18',
    templateUrl: './welcome-f18.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./welcome-f18.component.less']
})
export class WelcomeF18Component {

    user: any = null;
    appID: any = APPID.F18;
    stepsWelcome: any = [];
    stepsWelcomeSupports: any = [];
    welcomeObj: any = null;
    folderGuid: any;

    f18form: any;
    content: string = ""
    showPublishModal: boolean = false;
    isSubmitButtonDisabled : boolean = false
    frmData : any;
    constructor(
        public app: AppComponent,
        private identityManagerService: IdentityManagerService
    ) { }

    async ngOnInit() {
        this.appID = APPID.F18;
        console.log(this.app.route);
        this.app.route.queryParams.subscribe((params: any) => {
            console.log('p ', params)
            this.folderGuid = params['folderguid'];
            console.log(`Param Value: folder ${this.folderGuid}`);
        });

        await this.getVMFrmInfo()

        this.identityManagerService.checkIsUserAuthenticated();
        this.user = cloneDeep(this.identityManagerService.currentUser);

        //this.content = this.app.translate.instant('racine.fo.welcomeF18Page.labelNotIsAuthorized');
        //this.showPublishModal=true;
        // this.app.sharedTools.changeUsrLangInInit();
        // this.app.sharedTools.getCurrentLanguage();

        sessionStorage.removeItem('folderStep');
        sessionStorage.removeItem('activeStep');

        this.app.sharedTools.getJSON('general_data').subscribe(
            (resG: any) => {
                //console.log("resG", resG);

                this.stepsWelcome = resG.stepsWelcomeF18;

                this.welcomeObj = {
                    FormID: null,
                    Contact: {
                        FirstName: this.user?.Contact?.FirstName,
                        LastName: this.user?.Contact?.LastName
                    },
                    DateFinF18: null,
                    DateOpenF18: null,
                    IsOpen: null,
                }
                this.welcomeGetForm();



            });

    }

    async getVMFrmInfo() {
        let request = { frmType: "" }
        request.frmType = FormCode.FRM_VM
        let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
        if (data != undefined && data.Id != undefined) {
            this.frmData = data
        }
    }

    async welcomeGetForm() {
        let request: any = {
            FolderInternalGuid: this.folderGuid
        }

        let res = await this.app.apiService.welcomeGetForm(request);
        console.log("🚀  welcomeGetForm :  ", res);
        if (res) {
            this.f18form = res;
            console.log(this.f18form)
            sessionStorage.setItem('vm-f18-form', JSON.stringify(this.f18form));
            this.welcomeObj.DateFinF18 = this.f18form?.DateFinF18;
            this.welcomeObj.DateOpenF18 = this.f18form?.DateOpenF18;
            this.welcomeObj.FrmDeadLine = this.f18form?.FrmDeadLine;
            this.welcomeObj.IsOpen = this.f18form?.IsOpen;
            this.welcomeObj.ShowDeadlineDate = this.f18form.ShowDeadlineDate
            this.isSubmitButtonDisabled =( this.f18form?.IsFrmOpenedPerDate || this.f18form?.IsSurfaceLessThanThirty || this.f18form.IsFrmStatusClosed == true) 
            if (this.f18form?.IsOpen == 0 && this.f18form?.IsSurfaceLessThanThirty) {

                this.app.confirm.confirmDialog("", this.app.translate.instant('fo.welcomeF18Page.labelNotIsAuthorized'), 'KO', "alert", () => {
                }, () => {
                })
                return;
            }
        }
    }

    next(): void {
        sessionStorage.setItem('folderGuid', JSON.stringify(this.folderGuid));;
        this.app.sharedTools.redirectTo('/vm-f18-etat1sur2')
    }
    getVideoSourceSwitchLang(){
        if(this.app.translate.currentLang == Languages.French){
            return '/assets/videos/vm-tuto-fr.mp4'
        }
        if(this.app.translate.currentLang == Languages.Spanish){
            return '/assets/videos/vm-tuto-es.mp4'
        }
        return '/assets/videos/vm-tuto-en.mp4';
    }
    closeModal() {
        this.showPublishModal = false
    }

}
