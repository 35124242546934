import { Injectable, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig, TreeNode } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { ApiService } from './api.service';
import { ConfirmDialogService } from './confirm-dialog.service';
import { KeyValue } from '@angular/common';
import { APPID } from '../enums/app_id';
import { Languages } from '../enums/language';
import { AppGlobalConfig, defaultAppGlobalConfig } from '../models/app-global-config';
import { ImageModelSuffix } from '../enums/image-model-suffix';
import { MediaHelper } from '../helpers/media.helper';
import { environment } from 'src/environments/environment';
import { FOExternalPagesRoutes } from '../consts/fo-external-pages.routes';

@Injectable({
    providedIn: 'root'
})

export class SharedtoolsService {
    private readonly _countryFlagImageExtension: string = '.png';
    public appGlobalConfig: AppGlobalConfig = defaultAppGlobalConfig;
    @ViewChild('menuAction') menuAction!: Menu;
    subscription!: Subscription;
    encryptSecretKey: any = 'test12345m';
    urlDataJson = "./assets/data/";

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        public primeNGConfig: PrimeNGConfig,
        private router: Router,
        private apiService: ApiService,
        private confirm: ConfirmDialogService,
        private route: ActivatedRoute
    ) {
        translate.addLangs([Languages.English, Languages.French, Languages.Spanish]);
        this.changeUsrLangInInit();
        this.getAppGlobalConfig();
    }

    changeUsrLangInInit() {
        let browserLang: any = this.translate.getBrowserLang();
        let userLang = JSON.parse(sessionStorage.getItem('userLang')!);
        this.route.queryParams.subscribe((params: Params) => {
            if (params && params["lang"]) {
                let langFromQueryParam = params["lang"]
                if (langFromQueryParam?.toLowerCase() == Languages.French) {
                    this.switchLanguage(Languages.French)
                } else if (langFromQueryParam?.toLowerCase() == Languages.English) {
                    this.switchLanguage(Languages.English)
                }else {
                    this.switchLanguage(Languages.Default)
                }
            } else {
                let currentLangOnSwitchClick = JSON.parse(sessionStorage.getItem('currentLangOnSwitchClick')!);
                if(currentLangOnSwitchClick){
                    this.switchLanguage(currentLangOnSwitchClick);
                }
                else if (!userLang) {
                    if (browserLang != Languages.English && browserLang != Languages.French) {
                        this.switchLanguage(Languages.Default)
                    } else {
                        this.switchLanguage(browserLang)
                    }
                }
                else {
                    this.switchLanguage(userLang?.toLowerCase());
                }
                this.subscription = this.translate.stream('primeng').subscribe(data => {
                    this.primeNGConfig.setTranslation(data);
                });
            }
        })
    }

    getCurrentLanguage() {
        // this.switchLanguage((this.translate.currentLang == 'fr') ? 'en' : 'fr');

        let currLang = JSON.parse(sessionStorage.getItem('currentLangOnSwitchClick')!);

        if (!currLang) {
            // currLang = 'fr';
            currLang = Languages.Default
        }

        // this.switchLanguage((this.translate.currentLang == currLang) ? currLang : 'fr');
        this.switchLanguage((this.translate.currentLang == currLang) ? currLang : currLang);

    }

    getImgWlcmSwitchAppID(_appID: number) {
        let imgSrc: any = "";

        switch (_appID) {
            case 2:
            case APPID.F18:
                imgSrc = '/assets/images/f18/f18-welcome.jpg';
                break;
            case APPID.Helped:
                imgSrc = '/assets/images/helped/hlp-welcome.png';
                break;
            case APPID.Innov:
                imgSrc = '/assets/images/innov/innov-welcome.jpg';
                break;
            case APPID.Mkt:
                imgSrc = '/assets/images/welcome/mkt_wlcm.png';
                break;
            case APPID.Program:
                imgSrc = '/assets/images/program/prog-welcome.jpg';
                break;
            case APPID.Conf:
                imgSrc = '/assets/images/welcome/mkt-speaker-form.jpg';
                break;
            case APPID.CP:
                imgSrc = '/assets/images/communique-press/communique_presse_wlcm_image.png'
                break;
            default:
                break;
        }
        return imgSrc;
    }

    getWidthImageSplash(appID: any) {
        let widthImg: any = "";
        //console.log("_appID", _appID)
        switch (appID) {
            case 2:
                widthImg = 150;
                break;
            case 3:
            case 4:
                widthImg = 80;
                break;
            case 5:
                widthImg = 80;
                break;
            case 6:
                widthImg = 80;
                break;
            default:
                break;
        }
        return widthImg;
    }

    getBgColorSplash(appID: any) {
        let bgColor: any = "";
        //console.log("_appID", _appID)
        switch (appID) {
            case 2:
                bgColor = "bg-dark-blue";
                break;
            case 3:
            case 4:
                bgColor = "bg-default";
                break;
            case 5:
                bgColor = "bg-default";
                break;
            case 6:
                bgColor = "bg-default";
                break;
            default:
                break;
        }
        return bgColor;
    }

    getLogoSwitchAppID(_appID: number, FoOrBo: string = 'fo') {
        let imgSrc: any = "";
        //console.log("_appID", _appID)
        switch (_appID) {
            case 2:
                imgSrc = (FoOrBo == 'fo') ? '/assets/images/euromaritime-logo-light.svg' : '/assets/images/euromaritime-logo.svg';
                break;
            case 3:
            case 4:
                imgSrc = (FoOrBo == 'fo') ? '/assets/images/ESY22_LOGO_BLANC.png' : '/assets/images/ESY_LOGO_BLANC_MINI.png';
                break;
            case 5:
                imgSrc = (FoOrBo == 'fo') ? '/assets/images/ESY22_LOGO_BLANC.png' : '/assets/images/ESY_LOGO_BLANC_MINI.png';
                break;
            case 6:
                imgSrc = (FoOrBo == 'fo') ? '/assets/images/euromaritime-logo-light.svg' : '/assets/images/euromaritime-logo.svg';
                break;
            default:
                break;
        }
        return imgSrc;
    }

    getJSON(nameJson: string): Observable<any> {
        console.log(this.urlDataJson + nameJson + ".json")
        return this.http.get(this.urlDataJson + nameJson + ".json");
    }

    switchLanguage(lang: string) {
        sessionStorage.setItem('currentLangOnSwitchClick', JSON.stringify(lang));
        this.translate.use(lang);
    }

    refreshMenuLabels(menu: any): void {
        console.log("refreshMenuLabels ===> ", menu);

        if (!menu) return;

        for (const item of menu) {
            item.label = this.translate.instant(item.translationKey);
            //console.log("item", item)
        }
    }

    getObjById(_id: any, _param: any, _array: any) {
        let objToReturn: any = _array.filter((item: any) => {
            return item[_param] == _id
        })[0];
        return objToReturn;
    }


    getObjByLabel(_label: any, _param: any, _array: any) {
        let objToReturn: any = _array.filter((item: any) => {
            return item[_param] == _label
        })[0];
        return objToReturn;
    }

    // updateValueInForm(_arrayChamps: any,_form:any) {
    //     _arrayChamps.forEach((field: any) => {
    //         _form.get(field)?.updateValueAndValidity();
    //     });
    // }

    deleteParamsInObject(_folder: any, _listParams: any) {
        _listParams.forEach((param: any) => {
            delete _folder[param];
        });
    }

    getCategoriesFromSelectedNodes(categories: any, selectednodes: any) {
        let filteredCats = categories?.filter((cat: any) => {
            return selectednodes?.filter((node: any) => {
                return node.key == cat.CategoryID
            }).length > 0
        });

        //Seul les IDs sont attendus ici d'où le map
        return (filteredCats.length > 0) ? filteredCats.map((cat: any) => cat.CategoryID) : [];
    }

    getLabelSwitchLang(_item: any): any {
        if (!_item) return;
        //console.log(this.translate.currentLang);
        let currentlang = this.translate.currentLang.toUpperCase();
        //console.log(currentlang);
        let labelToReturn = _item['Label' + currentlang];
        if (_item.ColID) _item.header = labelToReturn;
        if (_item.CategoryID || _item.CountryID || _item.StatusID || _item.EtatID || _item.RoleID || _item.WebPublishedStatus || _item.OptId) _item.label = labelToReturn;
        //console.log("_item", _item);
        return labelToReturn;
    }

    getPropertySwitchLang(_item: any, property: any): string {
        if (!_item) return '';
        //console.log(this.translate.currentLang);
        let currentlang = this.translate.currentLang.toUpperCase();
        //console.log(currentlang);
        let labelToReturn = _item[property + currentlang];
        if (_item.ColID) _item.header = labelToReturn;
        if (_item.CategoryID || _item.CountryID || _item.StatusID || _item.EtatID || _item.RoleID) _item.label = labelToReturn;
        //console.log("_item", _item);
        return labelToReturn ?? '';
    }

    getPropertySwitchLanguage(property : string){
        let currentlang = this.translate.currentLang.toUpperCase();
        return `${property}${currentlang}`
    }

    getLangLabel(_string: any = "") {
        let labelToReturn: any = "";
        let currentlang = this.translate.currentLang.toUpperCase();
        if (_string == 'Prefix') labelToReturn = 'CountryLabel' + currentlang;
        else labelToReturn = 'Label' + currentlang;
        return labelToReturn;
    }

    getLangLabelPre() {
        let labelToReturn: any = "";
        let currentlang = this.translate.currentLang.toUpperCase();
        labelToReturn = 'CountryLabel' + currentlang;
        return labelToReturn;
    }

    getLabelSwitchLangByID(_id: any, param: any, array: any): void {
        if (!_id || !array || array.length < 1) return;
        let itemInArray = array.filter((item: any) => {
            return item[param] == _id
        })[0];
        let currentlang = this.translate.currentLang.toUpperCase();
        let labelToReturn: any = "";
        if (itemInArray) {
            labelToReturn = itemInArray['Label' + currentlang];
        }
        // console.log("getLabelSwitchLangByID", labelToReturn);
        return labelToReturn;
    }

    getParamByID(_id: any, paramID: any, array: any, paramToReturn: any, toLower: boolean = false) {
        if (!_id || !array || array.length < 1) return null;
        let itemInArray = array.filter((item: any) => {
            return item[paramID] == _id
        })[0];
        let toReturn: any = "";
        if (itemInArray) {
            toReturn = itemInArray[paramToReturn];
        }
        // console.log("getCodeISOByID", toReturn);
        if (toLower) toReturn = toReturn.toLowerCase();
        return toReturn;
    }

    getIndexOfTab() {
        let _tabActive = JSON.parse(sessionStorage.getItem('tabActive')!);
        let activeIndex: number = 0;
        if (_tabActive) activeIndex = _tabActive.index;
        //console.log("activeIndex", activeIndex)
        return activeIndex;
    }

    goTo(_path: any) {
        setTimeout(() => {
            location.href = _path;
        }, 1);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getTranslation(string: string): string {
        return this.translate.instant(string);
    }

    redirectTo(path: string) {
        this.router.navigate([path]);
    }

    changeCatalogueName(_form: any) {
        _form.updateValueAndValidity({ onlySelf: false, emitEvent: true });

        const CatalogName = _form.get('CatalogName')!;

        _form.get('CompanyName')?.valueChanges
            .subscribe((CompanyName: any) => {
                CatalogName.setValue(CompanyName.substring(0, 16));
                CatalogName.updateValueAndValidity();
            });

    }

    expandAll(_nomenclatures: any) {
        _nomenclatures.forEach((node: any) => {
            this.expandRecursive(node, true);
        });
    }

    collapseAll(_nomenclatures: any) {
        _nomenclatures.forEach((node: any) => {
            this.expandRecursive(node, false);
        });
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach((childNode: any) => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    nodeSelect(event: any) {
    }

    nodeUnselect(event: any) {
    }

    redirectToWithParameters(path: string, params: any) {
        this.router.navigate([path], { queryParams: params });
    }

    getJSONByPath(path: string, nameJson: string): Observable<any> {
        console.log(path, this.http.get(this.urlDataJson + nameJson + ".json"))
        return this.http.get(path + nameJson + ".json");
    }


    getLangPropertyName(propertyName: string): string {
        let labelToReturn: any = "";
        let currentlang = this.translate.currentLang.toUpperCase();
        labelToReturn = propertyName + currentlang;
        return labelToReturn;
    }

    goToWithParameters(_path: any, queryParams: any) {
        let href: string = _path;

        let params: KeyValue<string, string>[] = [];

        Object.keys(queryParams).forEach(k => {
            let param: KeyValue<string, string> = { key: k, value: queryParams[k] };
            params.push(param);
        })
        // console.log("🚀  params:", params);
        let isFirstParam: boolean = true;

        if (params && Array.isArray(params) && params.length > 0) {
            params.forEach(p => {
                if (isFirstParam) {
                    href += '?';
                }
                else {
                    href += '&';
                }

                href += `${p.key}=${p.value}`;
                isFirstParam = false;
            });
        };

        location.href = href;
    }

    getPropertySwitchLangByID(_id: any, param: any, array: any, propertyName: string): any {
        if (!_id || !array || array.length < 1) return;
        let itemInArray = array.filter((item: any) => {
            return item[param] == _id
        })[0];
        let currentlang = this.translate.currentLang.toUpperCase();
        let labelToReturn: any = "";
        if (itemInArray) {
            labelToReturn = itemInArray[propertyName + currentlang];
        }
        // console.log("getLabelSwitchLangByID", labelToReturn);
        return labelToReturn;
    }

    isNullAndEmpty = (propName: any): boolean => propName !== null && propName !== '';

    isNullOrEmpty = (propName: any): boolean => propName == null || propName == '';

    isNull = (propName: any): boolean => propName !== null;

    isEmpty = (propName: any): boolean => propName !== '';

    isNullAndEmptyParams = (...params: any[]): boolean => params.every(param => this.isNullAndEmpty(param));

    isNullParams = (...params: any[]): boolean => params.every(param => this.isNull(param));

    isEmptyParams = (...params: any[]): boolean => params.every(param => this.isEmpty(param));

    anyParams = (...params: any[]): boolean => params.some(param => param);

    countCharNumber = (ch: string): number => ch.length;

    goToMap(mapPoint: any) {
        if(this.appGlobalConfig?.enableMap){
            this.router.navigate(['map'], { queryParams: { mapPoint: mapPoint } });
        }
    }
    
    goToExhiMap(mapPoint: any) {
        if(this.appGlobalConfig?.enableMap){
            this.router.navigate(['mapExhi'], { queryParams: { mapPoint: mapPoint } });
        }
    }

    openNewTab(_path: any) {
        if(_path){
            window.open(_path, '_blank');
        }
    }

    public async getAppGlobalConfig(): Promise<AppGlobalConfig> {
        let config : AppGlobalConfig = defaultAppGlobalConfig;
        
        let generalData : any = await firstValueFrom(this.getJSON('general_data'));

        if(generalData){
            config.enableMap = generalData.globalConfig.enableMap;
            config.enableStandAlone = generalData.globalConfig.enableStandAlone;
            config.rowsPerPageOptions = generalData.globalConfig.rowsPerPageOptions;
            config.defaultRowsNumberPerPage = generalData.globalConfig.defaultRowsNumberPerPage;
            config.mapSettings = generalData.globalConfig.mapSettings;
        }

        this.appGlobalConfig = config;
        return this.appGlobalConfig;
    }

    public getImageModel(imageUrl: string, imageModelSuffix : ImageModelSuffix): string{
        return MediaHelper.getImageModel(imageUrl, imageModelSuffix);
    }

    public orderByPropertySwitchLang(array: any[], propertyName: string = 'Label'): any[]{
        let sortedArray: any[] = [];
        if(array && Array.isArray(array) && array.length > 0){
           sortedArray = array.sort((a: any, b: any) => { return b[this.getLangPropertyName(propertyName)] > a[this.getLangPropertyName(propertyName)] ? -1 : 1; })
        }

        return sortedArray;
    }

    public getFlagUrl(countryIsoCode: string | undefined | null): string{
        let url: string = environment.urlFlags;

        if(!countryIsoCode){
            return '';
        }

        return `${url}${countryIsoCode}${this._countryFlagImageExtension}`;
    }

    getAvailableCharactersLength(formControlName: string, maxLength: number, formName :FormGroup) {
        let currentLength = formName.get(formControlName)?.value?.length ?? 0
        return maxLength - currentLength
    }

    // inprogress
    public saveFoPreviousRoute() : void {
        sessionStorage.setItem('fo-previous-route', this.router.url);
    }
    
    public foNavigateBack() : void {
    }
    
    public routeTo(route: string, queryParams: any = {}) {
        this.router.navigate([route], {queryParams: queryParams})
    }
    
    public getMapSettings(): any {
        console.log(this.appGlobalConfig);
        if (!(this.appGlobalConfig && this.appGlobalConfig.mapSettings)) {
          console.warn('unable to load mapSettings, running map on default embedded settings : ', defaultAppGlobalConfig.mapSettings);
          return defaultAppGlobalConfig.mapSettings;
        }
    
        return this.appGlobalConfig.mapSettings;
      }

    getHeaderImageSwitchAppId(appID : any){
        return "assets/images/HeaderImages/picto.png"
    }
}

export function MustMatch(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
        const control = formGroup.get(controlName);
        const matchingControl = formGroup.get(matchingControlName);

        if (matchingControl!.errors && !matchingControl!.errors['mustMatch']) return null;

        if (matchingControl && control && matchingControl.value && control.value && control.value !== matchingControl.value) {
            console.log("control")
            matchingControl.setErrors({ mustMatch: true });
            return { mustMatch: true };
        }

        if (matchingControl?.errors?.['mustMatch']) matchingControl.setErrors({ mustMatch: false });

        matchingControl?.setErrors(matchingControl?.errors);

        return null;
    };
}