import { DatePipe } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { FOExternalPagesRoutes } from 'src/app/consts/fo-external-pages.routes';
import { ImageModelSuffix } from 'src/app/enums/image-model-suffix';
import { Languages } from 'src/app/enums/language';
import { IdentityManagerService } from 'src/app/services/identity-manager.service';
import { SharedtoolsService } from 'src/app/services/sharedtools.service';

@Component({
  selector: 'app-mini-card-exhibitor',
  templateUrl: './mini-card-exhibitor.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./mini-card-exhibitor.component.less']
})
export class MiniCardExhibitorComponent {

  @Input() showDescription: boolean = true
  @Input() showAvatar: boolean = true
  @Input() Exhibitor: any;
  IsValidUrl: boolean = false;
  IsfileIsc: boolean = false;

  DayConference: any;
  currentlangue: any;
  interests: any;
  speakers: any;
  exhibitors: any;
  isEnabled: boolean = false;
  calendarObj: any;
  Description: string = '';
  Location: string = '';
  Summary: string = '';
  isActive: boolean = false;
  currentMonth: number = 1;

  get dividerColor(): string {
    return (this.Exhibitor?.ThemeCategory?.Color ?? '#000000');
  };

  languages = Languages;
  imageModelSuffix = ImageModelSuffix;

  constructor(
    public app: AppComponent,
    private datePipe: DatePipe,
    private sharedService: SharedtoolsService,
    protected ims: IdentityManagerService
  ) { }

  ngOnInit(): void {
    if (this.Exhibitor?.Exhi_Id == 979193) {
      console.log(">>> Exhibitor : ", this.Exhibitor);
    }
  }

  formatDayOfWeek(date: any) {
    return this.datePipe.transform(date, 'EEEE', undefined, this.app.translate.currentLang);
  }

  isImageUrl(url: string): boolean {
    console.log(">>>> isImageUrl:", url);
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const extension = url.substring(url.lastIndexOf('.')).toLowerCase();

    console.log(">>>> Extenstion:", extension);

    this.IsValidUrl == imageExtensions.includes(extension);
    console.log(this.IsValidUrl);

    return this.IsValidUrl;
  }

  async getDetail(exhiID: any) {
    let queryParams : any = {
      exhibitorID: exhiID
    }
    this.app.sharedTools.routeTo(FOExternalPagesRoutes.exhiDetail, queryParams)
  }

  goToExhiMap(location: any) {
    this.sharedService.goToExhiMap(location)
  }

}

