export class SearchVmDo {
    public GroupNameEN?: string |null = null;
    public StatusId?: number | null = null;
    public TypeCategoryID?: number | null = null;
    public GroupCountries?: number | null = null;
    public DetailGroupNumberPeople?: number | null = null;
    public GroupExternalImportActive?: boolean | null = null;
    public GroupSectorType?: number | null = null;
    //public selectedRoleID?: number|null = null;
    public selectedStatus?: string|null = null;
    public selectedGroupExternalImportActive?: string|null = null;
    public selectedCountry?: string|null = null;
    public selectedTypeCategory?: string|null = null;
    public selectedDetailGroupNumberPeople?: string|null = null;
    public selectedGroupSectorType?: string|null = null;
}