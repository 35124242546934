import { Component, EventEmitter, ElementRef, Input, OnInit, Output, ViewChild, ViewEncapsulation} from "@angular/core";
import { NullValidationHandler, OAuthService } from "angular-oauth2-oidc";
import { FormControl, FormGroup } from "@angular/forms";
import { AppComponent } from "src/app/app.component";
import { Clipboard } from "@angular/cdk/clipboard";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { DialogService } from "primeng/dynamicdialog";
import { PreviewSocialMediaPostComponent } from "src/app/preview-social-media-post/preview-social-media-post.component";
import { ApiService } from "src/app/services/api.service";
import { MediasTypes } from "src/app/enums/media-type";

@Component({
  selector: "app-mkt-social-media",
  templateUrl: "./mkt-social-media.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./mkt-social-media.component.less"],
})
export class MktSocialMediaComponent implements OnInit {
  newTab1Form!: FormGroup;
  FolderID!: number;
  locations: any;
  imageUrl: any;
  imageByte: any;
  socialMenuTabs: any;
  @ViewChild("imageElement") imageElement?: ElementRef;
  @ViewChild("linkedInImg") linkedInImg: ElementRef<HTMLImageElement>| undefined;
  @ViewChild("twitterImg") twitterImg: ElementRef<HTMLImageElement> | undefined;

  @Input() userInfo: any;
  @Input() reloadGetUserInfo: boolean = false;

  @Input() mktFormID!: number;
  @Input() mktFolderGuid!: number;

  @Input() logoSettings: {} = {};
  @Input() imageSettings: {} = {};

  @Input() isLoadLogoSettings: boolean = false;
  @Input() isLoadImageSettings: boolean = false;

  @Input() categoryID: any;
  @Input() subCategories: any;

  @Input() isSpeakerForm : boolean = false
  @Input() showCompanyName : boolean = true
  @Input() showLocalisation : boolean = true
  @Input() showImageTitle : boolean = true
  @Input() showLogoExtraTitle : boolean = true
  @Input() mediaComponentsOrder : any = [MediasTypes.LOGO,MediasTypes.PHOTOID]
  @Input() showLine1 : boolean = false 

  subCategoryID: any;

  @Output() canTriggerReloadUserInfo: EventEmitter<any> =
    new EventEmitter<any>();
  @Input() canRecieveUserInfo: boolean = false;

  @Output() canReloadGetUserInfo: EventEmitter<any> = new EventEmitter<any>();

  isFormMktCreated: boolean = false;

  // isGenerated: boolean = false;
  canRegenerateMedia: boolean = false;
  isBannerExist: boolean = true;

  PostLinkedIn: string = "";
  PostX: string = "";
  mediaGenerationInProgress=false;
  activeIndex = 0;
  folderGuid: string | undefined;
  code: string | undefined;
  state: string | undefined;
  error: string | undefined;
  error_description: string | undefined;

  oauthPair: any;
  public oauthToken: string = "";
  public oauthTokenSecret: string = "";
  oauth_verifier : string | undefined ;
  isAllSocialMediaGenerated : boolean = false
  isTwitterGenerated : boolean = false
  isLinkedInGenerated : boolean = false
  zipUrl : string = ""

  constructor(
    public app: AppComponent,
    private clipboard: Clipboard,
    private oauthService: OAuthService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dialogService: DialogService,
    public apiService: ApiService
  ) {}

  async ngOnInit() {
    this.configureOAuth();
    this.handleShareOnLinkedIn();
    console.log("> [getUserInfo] from mkt-social-media-cmp :", this.userInfo);
    this.initTab1Form();

    this.socialMenuTabs = this.subCategories;
    console.log("<<<<<  this.socialMenuTabs:", this.socialMenuTabs);

    await this.getKeysFromStorage();

    //this.isGenerated = this.userInfo?.IsGenerated?.SOCIALNETWORK ?? false ;
    this.isAllSocialMediaGenerated = (this.userInfo?.IsGenerated?.LINKEDIN && this.userInfo?.IsGenerated?.TWITTER) ?? false 
    this.isTwitterGenerated = this.userInfo?.IsGenerated?.TWITTER
    this.isLinkedInGenerated = this.userInfo?.IsGenerated?.LINKEDIN
    this.zipUrl = this.userInfo?.ZipUrl 

    if (this.isAllSocialMediaGenerated === false) {
      console.log("the subCategory", this.subCategories.CategoryID);
      await this.getBanner(this.categoryID);
      // await this.getBanner();
    }
    //this.onTabChange();
    this.handleTriggerReloadEvent(this.reloadGetUserInfo);
  }

  private async getKeysFromStorage() {
    const folderIdString = sessionStorage.getItem("mktFolderID");
    const formIDString = sessionStorage.getItem("mktFormID");

    if (folderIdString !== null) {
      this.FolderID = parseInt(folderIdString);
    }

    if (formIDString !== null) {
      this.mktFormID = parseInt(formIDString);
    }
  }

  async getBanner(catID: number) {
    // async getBanner() {

    console.log("🚀  this.mktFormID:", this.mktFormID);
    console.log(" this.categoryId", catID);
    // let resp = await this.app.apiService.getBanner(this.mktFormID, this.categoryID);
    let resp = await this.app.apiService.getBanner(this.mktFormID, catID);
    console.log("==>>getBanner", resp);
    this.imageUrl = resp.ImageUrl;
    this.imageByte = resp.ImageContent;
    console.log("🚀  this.imageUrl:", this.imageUrl);
    // this.isBannerExist = false;
    if (this.imageUrl != null) {
      this.isBannerExist = true;
    } else {
      this.isBannerExist = false;
    }
  }
  

  async onTabChange() {
    console.log("🚀  activatedIndex:", this.activeIndex);

    let category = this.subCategories[this.activeIndex];
    console.log("🚀  category:", category);

    let order = this.subCategories[this.activeIndex]?.ViewOrder;

    this.subCategoryID = this.getCategoryIDByViewOrder(order);
    console.log("🚀  catID:", this.subCategoryID);
   
    await this.getBanner(this.subCategoryID);
     //await this.getBanner();

  
  }
  

  downloadMediaZIP() {
    return this.app.apiService
      .downloadZipFile(this.FolderID, this.categoryID)
      .subscribe({
        next: (res) => {
          let imageData = "data:image/*;base64," + res.FileContent;

          console.log("download Zip File", res);
          var link = document.createElement("a");
          link.setAttribute("href", imageData);
          link.setAttribute("download", res.FileName);
          link.click();
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  writeWithAI() {}

  async copyDataOfThePost() {
    if (this.PostLinkedIn || this.PostX) {
      if (this.PostLinkedIn) {
        await this.copyImageToClipboard(this.PostLinkedIn);
      }
      if (this.PostX) {
        await this.copyImageToClipboard(this.PostX);
      }
    } else {
      this.showError();
    }
  }

  async copyImageToClipboard(Post: string) {
    const dataURL = this.imageByte;
    console.log("dataURL", dataURL);
    const blob = this.dataURLtoBlob(dataURL);
    const textBlob = new Blob([Post], { type: "text/plain" });
    const textContent = Post;
    const imageBase64 = await this.blobToBase64(blob);
    try {
      const combinedContent = `${textContent}<br><img src="data:image/png;base64,${imageBase64}">`;
      const combinedBlob = new Blob([combinedContent], { type: "text/html" });
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/plain": textBlob,
          "text/html": combinedBlob,
        }),
      ]);
      console.log("Image copied to clipboard");
    } catch (imageError) {
      console.error("Error copying image to clipboard:", imageError);
    }
  }

  blobToBase64(blob: Blob) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        const base64Data = result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  dataURLtoBlob(dataURL: string): Blob {
    const bstr = atob(dataURL);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const mime = "image/png";
    return new Blob([u8arr], { type: mime });
  }

  //#region initialiseForm
  private initTab1Form(): void {
    this.newTab1Form = new FormGroup({
      CompanyName: new FormControl(),
      SelectedLocation: new FormControl(),
      PostLinkedIn: new FormControl(),
      PostX: new FormControl(),
    });
  }
  //#endregion

  // async isMediaGenerated(event: any) {
  //   this.isGenerated = event;
  //   this.canRegenerateMedia = false;
  //   console.log(">>>---  this.isGenerated:", this.isGenerated)
  //   console.log(">>>--- this.canRegenerateMedia:", this.canRegenerateMedia)

  //   if (this.canRegenerateMedia == false && this.isGenerated == true) {
  //     await this.getBanner(this.categoryID);
      
  //   }
  // }
  async isMediaGenerated(event: any) {
    this.canRegenerateMedia = false;
    // this.isGenerated = event.SOCIALNETWORK ?? false ;
    this.isAllSocialMediaGenerated = (this.userInfo?.IsGenerated?.LINKEDIN && this.userInfo?.IsGenerated?.TWITTER) ?? false 
    this.isTwitterGenerated = this.userInfo?.IsGenerated?.TWITTER
    this.isLinkedInGenerated = this.userInfo?.IsGenerated?.LINKEDIN
    console.log(">>>---  this.isGenerated:", this.isAllSocialMediaGenerated);
    console.log(">>>--- this.canRegenerateMedia:", this.canRegenerateMedia);
    if (this.canRegenerateMedia === false /*&& this.isAllSocialMediaGenerated === true*/) {
      if (!this.mediaGenerationInProgress) {
        this.mediaGenerationInProgress = true;

        let order = this.subCategories[this.activeIndex]?.ViewOrder;
       
       if(order!=undefined){
        console.log('order',this.subCategories[this.activeIndex]?.ViewOrder)
        this.subCategoryID = this.getCategoryIDByViewOrder(order);
        await this.getBanner(this.subCategoryID);
       }else{
       
        await this.getBanner(this.categoryID);
       }
        
  
        this.mediaGenerationInProgress = false;
      }
    }
  }
  

  private showError() {
    this.app.confirm.confirmDialog(
      "",
      this.app.translate.instant("racine.errorsOther.errorNoTextToCopy"),
      "KO",
      "alert",
      () => {},
      () => {}
    );
  }

  handleTriggerReloadEvent(event: boolean) {
    this.canTriggerReloadUserInfo.emit(event);
  }

  handleCanReloadGetUserInfo(event: boolean) {
    this.canReloadGetUserInfo.emit(event);
  }

  handleRegenerateMedia(event: boolean) {
    this.canRegenerateMedia = event;
    console.log("🚀  this.canRegenerateMedia:", this.canRegenerateMedia);
  }

  private getCategoryIDByViewOrder(order: number): number {
    let catID: number = 0;

    this.subCategories.forEach((subCat: any) => {
      if (subCat.ViewOrder == order) {
        catID = subCat.CategoryID;
      }
    });

    return catID;
  }


  generateRandomString(length: number): string {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  shareToLinkedIn() {
    //A value used to test for possible CSRF attacks.
    let state = this.generateRandomString(10);
    //Save the post state in localStorage so that we can retrieve it after redirecting the page
    localStorage.setItem("state", state);
    localStorage.setItem("postContent", this.PostLinkedIn);
    localStorage.setItem("postImgUrl", this.linkedInImg?.nativeElement.src!);

    this.dialogService.open(PreviewSocialMediaPostComponent, {
      header: this.app.translate.instant("racine.socialMedia.review_post"),
      contentStyle: { overflow: "auto" },
      baseZIndex: 100,
      maximizable: false,
      styleClass: "modal",
      data: {
        url: environment.linkedInConfig.url(this.folderGuid!, state),
        urlImg: this.linkedInImg?.nativeElement.src,
        text: this.PostLinkedIn,
        isForTwitter : false
      },
    });
  }

  shareToTwitter() {
    localStorage.setItem("twitterPostContent", this.PostX);
    localStorage.setItem("twitterImgUrl", this.twitterImg?.nativeElement.src!);

    this.dialogService.open(PreviewSocialMediaPostComponent, {
      header: this.app.translate.instant("racine.socialMedia.review_post"),
      contentStyle: { overflow: "auto" },
      baseZIndex: 100,
      maximizable: false,
      styleClass: "modal",
      data: {
        callBackUrl: environment.twitterConfig.redirectUrl(this.folderGuid!),
        urlImg: this.twitterImg?.nativeElement.src,
        text: this.PostX,
        isForTwitter: true,
      },
    });
  }

  handleShareOnLinkedIn() {
    this.activatedRoute.queryParams.subscribe((param) => {
      //if we can't get the query parameters, we can't continue
      if (!param) {
        this.app.confirm.confirmDialog("",this.app.translate.instant("racine.errorsApi.errorsMsgApi_ERROR"),"KO","alert",
          () => {},
          () => {});
        return;
      }
      this.folderGuid = param["folderguid"];
      //twitter
      this.oauth_verifier = param["oauth_verifier"];
      let twitterDenied = param["denied"];
      //linkedIn
      this.code = param["code"];
      this.state = param["state"];
      this.error = param["error"];
      this.error_description = param["error_description"];

    /****************************Handle twitter callback**********************************/

      //After the user being redirected to linkedIn login page and refuses to login or refuses to grant required permissions
      if (twitterDenied) {
        this.app.confirm.confirmDialog("",this.app.translate.instant("racine.socialMedia.twitter_denied"),"KO","alert",
          () => {},
          () => {});
        return;
      }
      if (this.oauth_verifier) {
        let body: any = {
          oauth_verifier: this.oauth_verifier,
          oauth_token: localStorage.getItem("oauthToken"),
          oauth_token_secret: localStorage.getItem("oauthTokenSecret"),
          imgName : localStorage.getItem("twitterImgUrl"),
          content : localStorage.getItem("twitterPostContent")
        };

        this.apiService.shareOnTwitter(body).subscribe({
          next: (res:any) => {
            if(res.isSuccess === true)
              this.app.confirm.confirmDialog("",this.app.translate.instant("racine.socialMedia.share_success"),"OK","alert",
              () => {},
              () => {});
            else
              this.app.confirm.confirmDialog("",this.app.translate.instant("racine.socialMedia.share_failed"),"KO","alert",
                () => {},
                () => {});
    
            //clear used query params to avoid reusing them
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: { oauth_token: null, oauth_verifier: null },
              queryParamsHandling: "merge",
            });
            return;
          },
          error: (err) => {
            this.app.confirm.confirmDialog("",this.app.translate.instant("racine.socialMedia.share_failed"),"KO","alert",
                () => {},
                () => {});
            return;
          },
        });
      }

    /***************************Handle LinkedIn callback*******************************/

      //After the user being redirected to linkedIn login page and refuses to login
      if (this.error === "user_cancelled_login ") {
        this.app.confirm.confirmDialog("",this.app.translate.instant("racine.socialMedia.user_cancelled_login"),"KO","alert",
          () => {},
          () => {});
        return;
      }
      //After the user being redirected to LinkedIn login page and successfully logged in, but he doesn't grant the required permissions
      if (this.error === "user_cancelled_authorize") {
        this.app.confirm.confirmDialog("",this.app.translate.instant("racine.socialMedia.user_cancelled_authorize"),"KO","alert",
          () => {},
          () => {});
        return;
      }
      //if the received state does not match the sent state, you are probably a victim of CSRF attack
      if (param["state"] && this.state !== localStorage.getItem("state")) {
        this.app.confirm.confirmDialog("",this.app.translate.instant("racine.socialMedia.CSRF"),"KO","alert",
          () => {},
          () => {});
        return;
      }
      //if the user has successfully authenticated and grant access for the required permission
      if (this.code) {
        let body = {
          AuthCode: this.code,
          Redirect_uri: environment.linkedInConfig.redirectUrl(this.folderGuid!),
          TextContent: localStorage.getItem("postContent"),
          ImgName: localStorage.getItem("postImgUrl"),
          visibility: localStorage.getItem("visibility"),
        };
        this.apiService.shareOnLinkedIn(body).subscribe((res: any) => {
          //clear used localStorage item for the next request
          localStorage.removeItem("postContent");
          localStorage.removeItem("postImgUrl");
          localStorage.removeItem("state");

          //clear used query params to avoid reusing them
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { code: null, state: null },
            queryParamsHandling: "merge",
          });

          if (res.isSuccess)
            this.app.confirm.confirmDialog("",this.app.translate.instant("racine.socialMedia.share_success"),"OK","alert",
              () => {},
              () => {});
          else
            this.app.confirm.confirmDialog("",this.app.translate.instant("racine.socialMedia.share_failed"),"OK","alert",
              () => {},
              () => {});
        });
      }
  });
}

  private configureOAuth() {
    this.oauthService.configure({
      issuer: environment.twitterConfig.issuer,
      redirectUri: window.location.origin + "/callback",
      clientId:  environment.twitterConfig.clientId,
      responseType:  environment.twitterConfig.responseType,
      scope:  environment.twitterConfig.scope
    });
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    //this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
}
