import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { Observable, Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { FormCode } from 'src/app/enums/form-code';
import { MediasTypes } from 'src/app/enums/media-type';
import { MediaValueChange } from 'src/app/models/media-value-change';

@Component({
  selector: 'app-list-media-upgrade',
  templateUrl: './list-media-upgrade.component.html',
  styleUrls: ['./list-media-upgrade.component.less']
})
export class ListMediaUpgradeComponent implements OnInit,OnDestroy {
  private _documents : any[] = [];

  @Input() set documents(value: any[]){
    this._documents = value;
    this.generateMediaMap();
  }

  get documents(): any[]{
    return this._documents;
  }
  
  @Input() SolutionID!: number;
  @Input() DocID!: number;

  @Input() maxLogos!: number;
  @Input() maxImages: number = 2;
  @Input() maxVideos!: number;
  @Input() maxFiles!: number;
  @Input() submitEvent!: Observable<void>;

  @Input() componentID!: number;
  @Input() formCode!: FormCode;
  
  submitEventSubscription?: Subscription;

  isLogo: boolean = false;
  isImage: boolean = false;
  isVideo: boolean = false;
  isFile: boolean = false;

  logoIndexes: number[] = [];
  imageIndexes: number[] = [];
  videoIndexes: number[] = [];
  fileIndexes: number[] = [];
  mediaConfig: any;

  mediaTypes = MediasTypes;

  mediaMap: any = {};
  loaded: boolean = false;
  
  @Output() mediaValueChange = new EventEmitter<MediaValueChange>();
  @Output() uploadStatus = new EventEmitter<boolean>();
  @Output() validityStatus = new EventEmitter<boolean>();
  uploadStatusMap: any = {};
  selectedFileValidityMap: any = {};

  constructor(public app: AppComponent) { }
  ngOnDestroy(): void {
    this.unsubscirbeOnSubmitSubscription();
  }

  ngOnInit(): void {
    console.log("Hi! this is app-list-media-upgrade ------------------------------");

    this.getMediaConfigs();
    this.initOnSubmitSubscription();
    this.clearFileValidityStatus();
  }

  selectedFile: any;
  @ViewChild('fileUploader') fileUploader?: FileUpload;
  onFileSelect(event:any){
    this.selectedFile = event.files[0];
    console.log("🚀  File uploader : ", this.fileUploader);

    console.log("🚀  Dragged file : ", this.selectedFile);
  }

  // ---  generate Medias Indexes ---
  generateMediaIndexArrays() {
    this.imageIndexes = [...Array(this.mediaConfig?.MaxImages ?? 0).keys()].map(i => i + 1);
    this.videoIndexes = [...Array(this.mediaConfig?.MaxVideos ?? 0).keys()].map(i => i + 1);
    this.logoIndexes = [...Array(this.mediaConfig?.MaxLogos ?? 0).keys()].map(i => i + 1);
    this.fileIndexes = [...Array(this.mediaConfig?.MaxFiles ?? 0).keys()].map(i => i + 1);
    
    console.log('imageIndexes ', this.imageIndexes);
    console.log('videoIndexes ', this.videoIndexes);
    console.log('logoIndexes ', this.logoIndexes);
    console.log('fileIndexes ', this.fileIndexes);

    this.clearUploadStatus();
    this.clearFileValidityStatus();
  }

  // ---  generate Medias Configs ---
  private getMediaConfigs() {
    return this.app.apiService.getMediaConfigs().subscribe({
      next: (res) => {
        console.log("🚀  getMediaConfigs : ", res);
        this.mediaConfig = res;

        console.log("🚀  this.mediaConfig:", this.mediaConfig);

        this.generateMediaIndexArrays();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  private generateMediaMap(): void{
    if(!this.mediaMap[this.mediaTypes.FILE]){
      this.mediaMap[this.mediaTypes.FILE] = {};
    }
    
    if(!this.mediaMap[this.mediaTypes.IMAGE]){
      this.mediaMap[this.mediaTypes.IMAGE] = {};
    }
    
    if(!this.mediaMap[this.mediaTypes.VIDEO]){
      this.mediaMap[this.mediaTypes.VIDEO] = {};
    }
    
    if(!this.mediaMap[this.mediaTypes.LOGO]){
      this.mediaMap[this.mediaTypes.LOGO] = {};
    }

    if(this.documents && this.documents.length > 0){
      this.documents.forEach((d: any) =>{
        if(d?.typeDocument?.TypeDOCCode){
          this.mediaMap[d.typeDocument.TypeDOCCode][d.ViewOrder ?? 1]= d;
        }
      });
    }


    console.log('generateMediaMap() ', this.mediaMap);
    this.loaded = true;
  }
  
  emitValueChangeInfo(mediaValueChange : MediaValueChange, type: any, index: any) : void {
        this.mediaValueChange.emit(mediaValueChange);
        this.selectedFileValidityMap[type][index] = mediaValueChange.IsValid;
        this.processSelectedFileChange();
}

onUploadResult(success: boolean | null, type: any, index: any): void{
  console.log('med before', this.uploadStatusMap);
  try{
    this.uploadStatusMap[type][index] = success;
  }
  catch(error){

  }
  console.log('med after', this.uploadStatusMap);

  this.processUploadResultChange()
}

private processUploadResultChange(): void {
  console.log('med ', this.uploadStatusMap);
  let success: boolean | null = true;
  this.logoIndexes.forEach((i: number) => {
    if(this.uploadStatusMap[this.mediaTypes.LOGO][i] == false){
      success = false
    }
    else if(this.uploadStatusMap[this.mediaTypes.LOGO][i] == null){
      success = null
    }
  });
  
  this.imageIndexes.forEach((i: number) => {
    if(this.uploadStatusMap[this.mediaTypes.IMAGE][i] == false){
      success = false
    }
    else if(this.uploadStatusMap[this.mediaTypes.IMAGE][i] == null){
      success = null
    }
  });
  
  this.videoIndexes.forEach((i: number) => {
    if(this.uploadStatusMap[this.mediaTypes.VIDEO][i] == false){
      success = false
    }
    else if(this.uploadStatusMap[this.mediaTypes.VIDEO][i] == null){
      success = null
    }
  });
  
  this.fileIndexes.forEach((i: number) => {
    if(this.uploadStatusMap[this.mediaTypes.FILE][i] == false){
      success = false
    }
    else if(this.uploadStatusMap[this.mediaTypes.FILE][i] == null){
      success = null
    }
  });

  console.log('check media validity : ', success);

  if(success != null){
    this.uploadStatus.emit(success);
  }
}

private processSelectedFileChange(): void {
  console.log('check selected file validity : ', this.selectedFileValidityMap);
  let success: boolean = true;
  this.logoIndexes.forEach((i: number) => {
    if(!this.selectedFileValidityMap[this.mediaTypes.LOGO][i]){
      success = false
    }
  });
  
  this.imageIndexes.forEach((i: number) => {
    if(!this.selectedFileValidityMap[this.mediaTypes.IMAGE][i]){
      success = false
    }
  });
  
  this.videoIndexes.forEach((i: number) => {
    if(!this.selectedFileValidityMap[this.mediaTypes.VIDEO][i]){
      success = false
    }
  });
  
  this.fileIndexes.forEach((i: number) => {
    if(!this.selectedFileValidityMap[this.mediaTypes.FILE][i]){
      success = false
    }
  });

  console.log('check selected file validity : ', success);

  this.validityStatus.emit(success);
}

private initOnSubmitSubscription(): void {
  if (this.submitEvent) {
    this.submitEventSubscription = this.submitEvent.subscribe(() => { this.clearUploadStatus(); })
  }
}

private unsubscirbeOnSubmitSubscription(): void {
  if (this.submitEventSubscription) {
    this.submitEventSubscription.unsubscribe();
  }
}

private clearUploadStatus(): void{
  if(!this.uploadStatusMap[this.mediaTypes.FILE]){
    this.uploadStatusMap[this.mediaTypes.FILE] = {};
  }
  
  if(!this.uploadStatusMap[this.mediaTypes.IMAGE]){
    this.uploadStatusMap[this.mediaTypes.IMAGE] = {};
  }
  
  if(!this.uploadStatusMap[this.mediaTypes.VIDEO]){
    this.uploadStatusMap[this.mediaTypes.VIDEO] = {};
  }
  
  if(!this.uploadStatusMap[this.mediaTypes.LOGO]){
    this.uploadStatusMap[this.mediaTypes.LOGO] = {};
  }

  this.logoIndexes.forEach((i: number) =>{
    this.uploadStatusMap[this.mediaTypes.LOGO][i] = null;
  });
  
  this.imageIndexes.forEach((i: number) =>{
    this.uploadStatusMap[this.mediaTypes.IMAGE][i] = null;
  });
  
  this.videoIndexes.forEach((i: number) =>{
    this.uploadStatusMap[this.mediaTypes.VIDEO][i] = null;
  });
  
  this.fileIndexes.forEach((i: number) =>{
    this.uploadStatusMap[this.mediaTypes.FILE][i] = null;
  });
}

private clearFileValidityStatus(): void{
  if(!this.selectedFileValidityMap[this.mediaTypes.FILE]){
    this.selectedFileValidityMap[this.mediaTypes.FILE] = {};
  }
  
  if(!this.selectedFileValidityMap[this.mediaTypes.IMAGE]){
    this.selectedFileValidityMap[this.mediaTypes.IMAGE] = {};
  }
  
  if(!this.selectedFileValidityMap[this.mediaTypes.VIDEO]){
    this.selectedFileValidityMap[this.mediaTypes.VIDEO] = {};
  }
  
  if(!this.selectedFileValidityMap[this.mediaTypes.LOGO]){
    this.selectedFileValidityMap[this.mediaTypes.LOGO] = {};
  }

  this.logoIndexes.forEach((i: number) =>{
    this.selectedFileValidityMap[this.mediaTypes.LOGO][i] = true;
  });
  
  this.imageIndexes.forEach((i: number) =>{
    this.selectedFileValidityMap[this.mediaTypes.IMAGE][i] = true;
  });
  
  this.videoIndexes.forEach((i: number) =>{
    this.selectedFileValidityMap[this.mediaTypes.VIDEO][i] = true;
  });
  
  this.fileIndexes.forEach((i: number) =>{
    this.selectedFileValidityMap[this.mediaTypes.FILE][i] = true;
  });
}
}
