import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';
import * as _ from 'lodash';
@Component({
  selector: 'app-detail-helped',
  templateUrl: './detail-helped.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./detail-helped.component.less']
})
export class DetailHelpedComponent {
  paths = location.pathname.split('/');
  currentPath: any = null;

  // exposant
  product?: any;
  productId: any;
  user: any = null;
  lienAutologin: string = "";
  urlFiche: any = "";
  SubmittedByObject:any = null;
  formID: any;
  // formID?:any;
contacts:any[]=[];
  constructor(
    public app: AppComponent,
  ) { }

  ngOnInit() {
    let user = JSON.parse(sessionStorage.getItem('userData')!);
   // this.formID = JSON.parse(sessionStorage.getItem('helped-form')!).HelpFormID;

    console.log(user);
    if (!user) {
      this.app.router.navigate(['/login']);
      return;
    }
    console.log("this.path==> ", this.paths);
    this.currentPath = this.paths[1];
    var urlId: any = this.app.router.url.split('/').pop();

    this.productId = parseInt(urlId);
    console.log("this.productId ==> ", this.productId);

    if(this.productId){
      this.getProduct(this.productId);
      this.getRedacteurs();
    }

    // if (this.folderId) {
    //   if (this.currentPath == 'detail-fiche') this.getLienAutologin(this.folderId);
    //   console.log("this.folderId ==> ", this.folderId);
    // }
  }

  openPdf(_urlPdf: any) {
    window.open(_urlPdf);
  }

  openUrlAutologin(_url: string) {
    if (!_url) return;
    window.open(_url);
  }

  openHelpedFiche(){
    this.app.apiService.generateHelpedFiche(this.productId).subscribe(
      data => {
        if(data && data.IsSuccessful && data.Url){
          window.open(data.Url,"_blank")
        }
        if(!data.IsSuccessful){
          setTimeout(() => {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Error)
                , 'KO'
                , "alert"
                , () => { /*this.app.sharedTools.redirectTo('/');*/ }
                , () => { });
        }, 200);
        return;
        }
      }
    )
  }

  getLienAutologin(folderId: any) {
    this.app.apiService.getUrlAutologin(folderId).subscribe({
      next: (data: any) => {
        console.log('[getUrlAutologin] API =>', data);

        if (data) {
          if (data.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
            return;
          }

          this.lienAutologin = data;
        }
      },
      error: (error: any) => {
        console.log(error);
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_ERROR'), 'KO', "alert", () => { }, () => { });
        return;
      }
    });
  }

  private async getProduct(ProductId: any) {
    try {
      let response = await this.app.apiService.getHelpedSolutionById(ProductId);
      console.log("getProduct ==> ", response);

      // ==> getFolderHelpedByID (Pour l'instant en dur)
      this.product = response;
      this.formID = this.product.HlpFormID;

      // ==> on recupere le nom et prénom de la personne qui a Submit les CGU by ID
      // let SubmittedByOnject:any = _.filter(this.product?.Contacts? { id: this.product?.SubmittedBy });
      this.SubmittedByObject = _.filter(this.product?.Contacts, { id: this.product?.SubmittedBy })[0];
      this.getRedacteurs();
      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
        }, () => {
        })
        return;
      }

      // this.product = cloneDeep(response);
      // console.log('folder at side bar',this.product)
    }
    catch (error) {
      console.error(error);
    }
  }

  onPrevious(): void{
    this.app.sharedTools.goTo('/help-fiches')
  }
  private async getRedacteurs() {
      let request={
        ComponentID: 6,
        FormeID: this.product.HlpFormID,
        SolutionID:this.productId
        };
        console.log("request ==> ", request);

      let response = await this.app.apiService.getInnovContactSelected(request);
      console.log("getRedacteurs getProduct ==> ", response);

      // ==> getFolderHelpedByID (Pour l'instant en dur)
      this.contacts = response;

      // ==> on recupere le nom et prénom de la personne qui a Submit les CGU by ID
      // let SubmittedByOnject:any = _.filter(this.product?.Contacts? { id: this.product?.SubmittedBy });
      this.SubmittedByObject = _.filter(this.product?.Contacts, { id: this.product?.SubmittedBy })[0];
    
  }

  openHelpedForm(): void {
    // let params = { folderID: folderId }
    console.log("Test---------------------------->", this.formID);
    this.app.apiService.getUrlAutologinSolutionHelped(this.formID, this.productId).subscribe({
        next: (data: any) => {
            console.log('[getUrlAutologin] API =>', data);

            if (data) {
                if (data.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                    return;
                }
                window.open(data);
            }
        },
        error: (error: any) => {
            console.log(error);
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_ERROR'), 'KO', "alert", () => { }, () => { });
            return;
        }
    });
}
}