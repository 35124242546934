import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { MustMatch } from '../services/sharedtools.service';
import { PasswordRegex } from '../consts/utils';

@Component({
    selector: 'app-reset-mdp',
    templateUrl: './reset-mdp.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./reset-mdp.component.less']
})
export class ResetMdpComponent {
    tokenUser: any = "";
    user: any;
    resetMdpForm: any;
    submitted = false;

    constructor(
        public app: AppComponent,
    ) { }

    get f() {
        return this.resetMdpForm.controls;
    }

    ngOnInit(): void {
        // ==> Récuperation du token dans l'url
        this.tokenUser = this.app.router.url.split('?uuid=').pop();
        console.log('this.tokenUser', this.tokenUser);

        if (this.tokenUser) {
            var userTemp: any = {
                UserToken: this.tokenUser,
                Email: ""
            }

            this.app.apiService.getresetinfofromtoken(userTemp).subscribe(
                result => {
                    console.log("getresetinfofromtoken", result);
                    this.user = result;
                }
            )
        }
        else {
            this.app.sharedTools.redirectTo('login');
        }

        this.resetMdpForm = new FormGroup({
            NewMdp: new FormControl('', [Validators.required, Validators.pattern(PasswordRegex)]),
            ConfirmMdp: new FormControl('', [Validators.required, Validators.pattern(PasswordRegex)]),
        }, { validators: MustMatch('NewMdp', 'ConfirmMdp') });
    }

    onSubmit() {
        console.log("this.resetMdpForm.value ==> ", this.resetMdpForm.value, this.resetMdpForm);

        this.submitted = true;

        // Stop here if form is invalid
        if (this.resetMdpForm.invalid) {
            console.log("this.resetMdpForm is invalid ==> ");
            return;
        }

        this.user.UserToken = this.tokenUser;
        this.user.Password = this.resetMdpForm.value.NewMdp;
        console.log("this.user ==> ", this.user);

        this.app.apiService.resetpassword(this.user).subscribe(
            (data: any) => {
                console.log("resetpassword", data);

                if (data && data.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { })
                    return;
                }

                this.app.confirm.confirmDialog("", this.app.translate.instant('fo.login.successResetPassowrd'), 'OK', "alert", () => {
                    this.app.sharedTools.redirectTo('/login');
                }, () => { })
            }
        )
    }
}


