import { Component, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { CategoryType } from '../enums/category-type';
import { AppComponent } from '../app.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ExposantType } from '../enums/exposant-type';
import { Subject, firstValueFrom } from 'rxjs';
import { FormCode } from '../enums/form-code';

@Component({
  selector: 'app-fo-f18-etat1-sur2',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './fo-f18-etat1-sur2.component.html',
  styleUrls: ['./fo-f18-etat1-sur2.component.less']
})
export class FoF18Etat1Sur2Component {
  user: any = null;
  search: any
  formID!: number;
  newLeadForm!: FormGroup;
  submitted: boolean = false;
  initForm: boolean = false;

  isHovered = false;

  isEditMode: boolean = false;

  odContactRoles: any[] = [];

  FedAutorisation: boolean = false;
  OdQuestions: boolean = false;
  OdQuestionsAcceui: boolean = true;
  LunchQuestions: boolean = false;

  meetingstands: any[] = [];
  typeSectorCategory: any = [];
  sectorCategory!: number;
  cateringCategory: any = [];

  typeCategory: any = [];
  countries: any = [];

  target: any[] = [];
  targetPays: any[] = [];

  isTargetEmpty: boolean = true;


  edition: any;
  allEditionDates: any[] = [];

  startDate: Date = new Date(); // 2024-01-01
  endDate: Date = new Date(); // 2024-01-31
  daysArray: any[] = [];
  days: any[] = [];
  daysTest: any[] = [];

  selectedDay!: any;

  // SelectedMeetingStand: any;
  SelectedOdCapacities: any;
  SelectedOdCapacitiesLunch: any;
  SelectedMaxPax: any;
  SelectedCatering: any;

  //#region informations form
  //drop down selected item
  SelectedTypeCategory: any;
  SelectedSectorCategory: any;
  SelectedCountry: any;
  SelectedStatus: any;

  odCapacities: KeyValue<number, any>[] = [];
  oDcapacitiesConfig: any = {};
  paxCapacitiesConfig: any = {};
  MaxCapacities:any[] = [];

  //#region composition form

  odTypeCategories: any[] = [];
  odSectorCategories: any[] = [];

  //calculated properties
  formStep1Of2: any = {};
  SelectedCateringType: any;
  //#endregion

  selectedMeetingStand: any;

  isFederatedMode: boolean = false;

  exposantType = ExposantType;
  folderID: any;

  reloadContactSources: any;
  submitEventSubject1: Subject<void> = new Subject<void>();
  submitEventSubject2: Subject<void> = new Subject<void>();
  frmData : any;

  constructor(
    public app: AppComponent,
    public translate: TranslateService
  ) {

    //test sur route and calculate isFederatedMode
    const routeWithoutQueryParams = app.router.url.split('?')[0];

    if (routeWithoutQueryParams === '/vm-f18-etat1sur2') {
      this.isFederatedMode = false;
    }
    else if (routeWithoutQueryParams === '/vm-f18-etat1sur2/exhibitor') {
      this.isFederatedMode = true;
    }

    this.getForm18Data();
  }

  ngOnInit() {

    this.user = JSON.parse(sessionStorage.getItem('userData')!);
    console.log("this.user ==> ", this.user);
    if (!this.user) {
      this.app.router.navigate(['/login']);
      return;
    }

    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
        this.oDcapacitiesConfig = cloneDeep(resG.odCapacities);
        this.paxCapacitiesConfig = cloneDeep(resG.MaxCapacities);
        this.daysTest = cloneDeep(resG.vmf18Etat2Days);
        this.initODcapacities();
      

        this.getCategories();
        this.getmeetingstands();
        this.getEdition();

        setTimeout(() => {
          this.getEtat1Sur2();
        }, 500);
      }
    );

    this.getVMFrmInfo();
    // this.app.sharedTools.changeUsrLangInInit();

    // this.app.sharedTools.getCurrentLanguage();

    sessionStorage.removeItem('folderStep');
    sessionStorage.removeItem('activeStep');

  }

  onOdQuestionsChange(event: any): void {
    console.log('onOdQuestionsChange ', event)
    this.validatoresControl();
  }

  onLunchQuestionsChange(event: any): void {
    console.log('onLunchQuestionsChange ', event)
    if (event.checked) {
      this.addLunchQuestionsValidator();
    }
    else {
      this.removeLunchQuestionsValidator()
    }
  }

  onchangeSelect(_item: any) {
    if (!_item) return;
    //console.log("onchangeSelect", _item);
  }

  onWichDayClick(event: any): void {

    console.log(event);
    let selectedDays: any[] = event.value;
    if (selectedDays && event.option === '-1') {
      let indexOfSelectAllOption = selectedDays.indexOf('-1');
      let ListOfDatesControl = this.newLeadForm.get('ListOfDates');
      if (ListOfDatesControl) {
        if (indexOfSelectAllOption > -1) {
          ListOfDatesControl.setValue(this.days);
          console.log(ListOfDatesControl.value);
        }
        else {
          ListOfDatesControl.setValue([]);
        }
      }
    }
  }

  private initODcapacities(): void {
    this.odCapacities = [];
    for (let i: number = this.oDcapacitiesConfig.min; i <= this.oDcapacitiesConfig.max; i++) {
      let capacity: KeyValue<number, any> = {
        key: i,
        value: i > 1
          ? this.oDcapacitiesConfig.pluralCapacity
          : this.oDcapacitiesConfig.singularCapacity
      }
      this.odCapacities.push(capacity);
    }
  }


  private async getEdition() {
    try {
      const response = await this.app.apiService.getEdition();
      console.log("🚀  response :", response);

      this.edition = response;
      if (this.edition?.AllEditionDates
        && this.edition?.AllEditionDates.length > 0) {
        this.allEditionDates = this.edition.AllEditionDates.map((e: any) => {
          return e;
          // return new Date();
        });
      }
      this.days = this.allEditionDates;

      //push select all option

      this.days = ['-1', ...this.days];

    } catch (error) {
      console.error(error);
    }
  }

  async getCategories() {
    try {
      const response = await this.app.apiService.getCategories();
      console.log("🚀  response category :", response);

      let categoriesIni = cloneDeep(response);

      this.typeCategory = cloneDeep(categoriesIni.filter((cat: any) => {
        return cat.CategoryType === CategoryType.TypeDO;
      }));

      this.typeSectorCategory = cloneDeep(categoriesIni.filter((cat: any) => {
        return cat.CategoryType === CategoryType.SectorDO;
      }));
      console.log("🚀  this.typeSectorCategory:", this.typeSectorCategory);

      this.cateringCategory = cloneDeep(categoriesIni.filter((cat: any) => {
        return cat.CategoryType === CategoryType.Catering;
      }));
      console.log("🚀  this.cateringCategory:", this.cateringCategory);

    } catch (error) {
      console.error(error);
    }
  }


  getCountries(target: any) {

    this.app.apiService.getCountriesOD().subscribe((data: any) => {
      console.log("🚀==> getCountries ODDDDD==> ", data);

      console.log("🚀==>  target:", target);
      if (target.length == 0) {
        console.log("🚀==>  Target is empty !");

        this.countries = data;
        this.isTargetEmpty = true;
      } else {
        console.log("🚀==>  Target not empty !");

        this.isTargetEmpty = false;

        this.countries = data;

        //
        this.countries = this.countries
          .filter((item: any) => !target.find((c: any) => c.CountryID == item.CountryID));
        //TODO @Nadher, à vérifier apres deploiement.
        // data.forEach((country: any) => {
        //   const index = this.countries.indexOf(country);
        //   target.forEach((pay: any) => {
        //     if (country.CountryID == pay.CountryID) {
        //       this.countries = this.countries.filter((item:any) => item !== country)
        //       console.log("🚀==>  this.countries.filter : ", pay)
        //     }
        //   });
        // });
        console.log("🚀==>  this.countries:", this.countries);

      }
    });
  }

  getmeetingstands() {
    if (this.formID) {
      this.app.apiService.getmeetingstandsVmFolder(this.formID).subscribe((data: any) => {
        console.log("getmeetingstands ==> ", data);
        this.meetingstands = data; //cloneDeep(data);
      });
    }
  }

  annuler() {
    if (!this.isFederatedMode) {
      let folderGuid = sessionStorage.getItem('folderGuid');
      let guid = JSON.parse(folderGuid!);

      this.app.sharedTools.goTo(`/welcome-f18?folderguid=${guid}`);
    }
  }

  onSubmit() {
    //
    this.newLeadForm.get('Cty_ids')?.setValue(this.target?.map((c: any) => c.CountryID));
    this.newLeadForm.get('Cty_ids')?.updateValueAndValidity();
    //
    console.log('target ', this.target);
    this.submitted = true;
    console.log('[this.newLeadForm]', this.newLeadForm);

    if (this.newLeadForm.invalid) {

      console.log("invalid form ===>", this.newLeadForm);
      console.log("invalid form ===>", this.newLeadForm.errors);

      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.newLeadForm.controls;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log("Invalid form values : ", invalidValues);

      // if (invalidField && invalidValues.length > 1) {
      if ((invalidField && invalidField.length > 1) || (this.newLeadForm.get('Cty_ids')?.invalid)) {
        console.log("invalidField", invalidField)

        if ((invalidField && invalidField.length > 1)) {
          setTimeout(() => {
            let el = invalidField[1].offsetTop - 100;
            if (el) window.scroll({ top: el, behavior: "smooth" });
          }, 10);
        }

        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
        }, () => { });

      }
    }
    else {
      console.log('valid ici ')
      let newEtat = this.newLeadForm.value;

      if (newEtat.SelectedOdCapacities) newEtat.CountryID = newEtat.SelectedOdCapacities.OdcapacitiesID;
      if (newEtat.Categories_ids) newEtat.Categories_ids = newEtat.Categories_ids;
      //if (newEtat.OdCapicites) newEtat.OdCapicites = newEtat.OdCapicites;
      if (newEtat.LunchOdsCapcity) newEtat.LunchOdsCapcity = newEtat.LunchOdsCapcity;
      if (newEtat.LunchPepoleCapcity) newEtat.LunchPepoleCapcity = newEtat.LunchPepoleCapcity;
      //if (newEtat.CateringType) newEtat.CateringType = newEtat.CateringType.CategoryID;
      // if (newEtat.SelectedMeetingStand) newEtat.MeetingStandID = newEtat.SelectedMeetingStand.FolderID;

      // let targtPay: any[] = [];
      // this.target.forEach((pay: any) => {
      //   targtPay.push(pay.CountryID);
      // });
      newEtat.Cty_ids = this.target;
      console.log('[newEtat]', newEtat);

      if (newEtat.ListOfDates) {
        newEtat.ListOfDates = newEtat.ListOfDates.filter((i: any) => i != '-1');
      }

      console.log('[newEtat] after', newEtat);

      //

      if (this.isEditMode) {
        if (newEtat.SelectedMeetingStand) {
          newEtat.MeetingStand = newEtat.SelectedMeetingStand.MeetingStandLabel;
          newEtat.MeetingStandFolderID = newEtat.SelectedMeetingStand.FolderID;
        }
        else {
          newEtat.MeetingStand = null;
          newEtat.MeetingStandFolderID = null;
        }
      }

      this.updateEtat(newEtat);
    }
  }


  async getEtat1Sur2() {
    this.isEditMode = true;
    let res = await this.app.apiService.getEtat1Sur2(this.formID);
    console.log("🚀  getEtat1Sur2 : ", res);

    if (res != null) {
      this.folderID = res.FolderID;
      this.formStep1Of2 = res;
      this.formID = res.FormID;
      this.FedAutorisation = res.FedAutorisation ?? false;
      this.OdQuestions = res.OdQuestions ?? false;
      this.LunchQuestions = res.LunchQuestions;
      this.target = res.Cty_ids;
      if (this.target) {
        this.isTargetEmpty = false;
        this.target.forEach((pay: any) => {
          this.targetPays.push({ CountryID: pay.CountryID });
        });
      }
      else {
        this.target = [];
        this.isTargetEmpty = true;
      }

      this.SelectedCateringType = this.cateringCategory.find((c: any) => c.CategoryID == this.formStep1Of2?.CateringType?.CategoryID);
      console.log('selecte ', this.SelectedCateringType);

      console.log('this.meetingstands ', this.meetingstands, this.formStep1Of2.MeetingStand);
      //
      if (this.formStep1Of2.MeetingStand
        && this.meetingstands
        && this.meetingstands.length > 0) {
        this.selectedMeetingStand = this.meetingstands
          .find((m: any) => m.MeetingStandLabel
            && (m.MeetingStandLabel.endsWith('-' + this.formStep1Of2.MeetingStand)
              || m.MeetingStandLabel == this.formStep1Of2.MeetingStand));
        console.log('ici ', this.formStep1Of2.MeetingStand
          , this.selectedMeetingStand)
      }
      //

      this.initFormulaire();
      this.validatoresControl();
      this.getCountries(this.target);
    }
  }

  async updateEtat(formObj: any): Promise<any> {
        formObj.FormID = this.formID;
      try {
        const response = await this.app.apiService.updateEtat1Sur2(formObj);
        console.log('RESPONSE UPDATE Etat1/2 ===>', response);
  
        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }
        // this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successVMF18Etat1Sur2'), 'OK', "alert", () => {
        //   this.app.sharedTools.redirectTo('/vm-f18-etat2sur2');
        // }, () => { });
        this.next();
  
      } catch (error) {
        console.error(error);
      }
  }

  private next(): void {
    if (this.OdQuestions == false) {
      this.showPopUpRequest();
    }
    else {
      if (this.isFederatedMode == false) {
        this.app.sharedTools.goTo('/vm-f18-etat2sur2');
      }
      else if (this.isFederatedMode == true) {
        let queryparams: any = {
          FormID: this.formID
        };

        // this.app.sharedTools.redirectToWithParameters('/vm-f18-etat2sur2/exhibitor', queryparams);

        this.app.sharedTools.goToWithParameters('/vm-f18-etat2sur2/exhibitor', queryparams);
      }
    }
  }

  private initFormulaire(): void {

    this.newLeadForm = new FormGroup(
      {

        FedAutorisation: new FormControl(false),
        OdQuestions: new FormControl(false),

        LunchQuestions: new FormControl(false),

        SelectedCountry: new FormControl(null),
        CountryID: new FormControl(null),

        Cty_ids: new FormControl(this.formStep1Of2?.Cty_ids?.map((c: any) => c.CountryID) ?? null),

        Categories_ids: new FormControl(this.formStep1Of2?.Categories_ids ?? null),
        CategoryID: new FormControl(null),

        SelectedMeetingStand: new FormControl({ value: this.selectedMeetingStand ?? null, disabled: this.formStep1Of2?.BloqueStand }),
        MeetingStand: new FormControl(this.formStep1Of2?.MeetingStand ?? null),
        MeetingStandFolderID: new FormControl(null),

        OdCapicites: new FormControl(this.formStep1Of2?.OdCapicites ?? null),
        LunchOdsCapcity: new FormControl(this.formStep1Of2?.LunchOdsCapcity ?? null),
        LunchPepoleCapcity: new FormControl(this.formStep1Of2?.LunchPepoleCapcity ?? null),

        CateringType: new FormControl(this.SelectedCateringType),
        CateringID: new FormControl(null),
        ListOfDates: new FormControl(this.formStep1Of2?.ListOfDates ?? null),

        //contact validation controls
        AtLeadtOneRespDO: new FormControl(false, Validators.requiredTrue),
        AtLeadtOneAccueilDO: new FormControl(false, Validators.requiredTrue),
        AllResDOAreValid: new FormControl(false, Validators.requiredTrue),
        AllAccueilAreValid: new FormControl(false, Validators.requiredTrue)
      });

    this.initForm = true;
  }

  //#endregion

  //api endpoints calls
  private getForm18Data(): void {
    if (this.isFederatedMode == false) {
      let f18form: any = JSON.parse(sessionStorage.getItem('vm-f18-form') ?? '{}');
      console.log("🚀  f18form:", f18form);

      if (f18form) {
        this.formID = f18form.FormID;
        //this.folder = f18form.FolderID;
      }
    }
    else {
      this.app.route.queryParams
        .subscribe(params => {
          console.log('[params]', params);
          if (params && params['FormID']) {
            this.formID = params['FormID'];
          }
        });
    }
  }

  async attachHostStand(): Promise<any> {
    try {
      let reqObj: any = {
        FormID: this.formID
      };
      console.log("attachHostStand Etat1/2 ===>", reqObj);
      const response = await this.app.apiService.attachHostStand(reqObj);
      console.log('RESPONSE attachHostStand Etat1/2 ===>', response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
          , 'KO'
          , "alert"
          , () => { }
          , () => { });
        return;
      }

      //OdQuestions to true
      this.OdQuestions = true;

    } catch (error) {
      console.error(error);
    }
  }
  //#endregion

  // Enable / Disable Validators
  private addOdQuestionsValidator() {
    console.log("🚀  addOdQuestionsValidator ");

    this.newLeadForm.get('Cty_ids')?.setValidators(Validators.required);
    // this.newLeadForm.get('SelectedCountry')?.setValidators(Validators.required);
    this.newLeadForm.get('Categories_ids')?.setValidators(Validators.required);
    this.newLeadForm.get('OdCapicites')?.setValidators(Validators.required);
    this.newLeadForm.get('SelectedMeetingStand')?.setValidators(Validators.required);

    this.newLeadForm.get('Cty_ids')?.updateValueAndValidity();
    // this.newLeadForm.get('SelectedCountry')?.updateValueAndValidity();
    this.newLeadForm.get('Categories_ids')?.updateValueAndValidity();
    this.newLeadForm.get('OdCapicites')?.updateValueAndValidity();
    this.newLeadForm.get('SelectedMeetingStand')?.updateValueAndValidity();
  }

  private removeOdQuestionsValidator() {
    console.log("🚀  removeOdQuestionsValidator ");

    this.newLeadForm.get('Cty_ids')?.clearValidators();
    this.newLeadForm.get('Categories_ids')?.clearValidators();
    this.newLeadForm.get('OdCapicites')?.clearValidators();
    this.newLeadForm.get('SelectedMeetingStand')?.clearValidators();

    this.newLeadForm.get('Cty_ids')?.updateValueAndValidity();
    this.newLeadForm.get('Categories_ids')?.updateValueAndValidity();
    this.newLeadForm.get('OdCapicites')?.updateValueAndValidity();
    this.newLeadForm.get('SelectedMeetingStand')?.updateValueAndValidity();
  }

  private addLunchQuestionsValidator() {
    console.log("🚀  addLunchQuestionsValidator ");

    this.newLeadForm.get('ListOfDates')?.setValidators(Validators.required);
    this.newLeadForm.get('LunchOdsCapcity')?.setValidators(Validators.required);
    this.newLeadForm.get('LunchPepoleCapcity')?.setValidators(Validators.required);
    this.newLeadForm.get('CateringType')?.setValidators(Validators.required);

    this.newLeadForm.get('ListOfDates')?.updateValueAndValidity();
    this.newLeadForm.get('LunchOdsCapcity')?.updateValueAndValidity();
    this.newLeadForm.get('LunchPepoleCapcity')?.updateValueAndValidity();
    this.newLeadForm.get('CateringType')?.updateValueAndValidity();
  }

  private removeLunchQuestionsValidator() {
    console.log("🚀  removeLunchQuestionsValidator ");

    this.newLeadForm.get('ListOfDates')?.clearValidators();
    this.newLeadForm.get('LunchOdsCapcity')?.clearValidators();
    this.newLeadForm.get('LunchPepoleCapcity')?.clearValidators();
    this.newLeadForm.get('CateringType')?.clearValidators();

    this.newLeadForm.get('ListOfDates')?.updateValueAndValidity();
    this.newLeadForm.get('LunchOdsCapcity')?.updateValueAndValidity();
    this.newLeadForm.get('LunchPepoleCapcity')?.updateValueAndValidity();
    this.newLeadForm.get('CateringType')?.updateValueAndValidity();

  }

  private validatoresControl() {
    if (this.OdQuestions != true) {
      this.removeOdQuestionsValidator();
      this.removeLunchQuestionsValidator();
    }
    else {
      this.addOdQuestionsValidator();
      if (this.LunchQuestions != true) {
        this.removeLunchQuestionsValidator();
      }
      else {
        this.addLunchQuestionsValidator()
      }
    }
  }
  // #endRegion

  private showPopUpRequest() {

    this.app.confirm.confirmDialog("", this.app.translate.instant('fo.vmf18Etat1Sur2.popUpReqMsg')
      , 'KO'
      , "alert"
      , () => { }
      , () => { });
  }

  goToBackToFederatorView() {
    if (this.isFederatedMode) {
      this.app.sharedTools.goTo('/vm-f18-etat2sur2');
    }
  }

  emitSubmitEventContact1() {
    this.submitEventSubject1.next();
  }

  emitSubmitEventContact2() {
    this.submitEventSubject2.next();
  }

  atLeadtOneRespDOIsSelectedHandler(value: any): void {
    let atLeadtOneRespDO : any = this.newLeadForm.get('AtLeadtOneRespDO');
    if(atLeadtOneRespDO)
    {
      atLeadtOneRespDO.setValue(value);
      atLeadtOneRespDO.updateValueAndValidity();
      this.newLeadForm.updateValueAndValidity();
    }
    
    
  }

  atLeadtOneAccueilDOIsSelectedHandler(value: any): void {
    let atLeadtOneAccueilDO : any = this.newLeadForm.get('AtLeadtOneAccueilDO');
    if(atLeadtOneAccueilDO)
    {
      atLeadtOneAccueilDO.setValue(value);
      atLeadtOneAccueilDO.updateValueAndValidity();
      this.newLeadForm.updateValueAndValidity();
    }
  }

  allContactsResDOAreValidHandler(value: boolean) : void {
    let allResDOAreValid : any = this.newLeadForm.get('AllResDOAreValid');
    if(allResDOAreValid)
    {
      allResDOAreValid.setValue(value);
      allResDOAreValid.updateValueAndValidity();
      this.newLeadForm.updateValueAndValidity();
    }
  }

  allContactsAreValidAccueilDOHandler(value: boolean) : void {
    let allAccueilAreValid : any = this.newLeadForm.get('AllAccueilAreValid');
    if(allAccueilAreValid)
    {
      allAccueilAreValid.setValue(value);
      allAccueilAreValid.updateValueAndValidity();
      this.newLeadForm.updateValueAndValidity();
    }
  }
  
  async getVMFrmInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_VM
    let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
    if (data != undefined && data.Id != undefined) {
        this.frmData = data
    }
  }
}
