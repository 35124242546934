import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-exhibitor-details-solutions',
  templateUrl: './exhibitor-details-solutions.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./exhibitor-details-solutions.component.less']
})
export class ExhibitorDetailsSolutionsComponent {

}
