import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { CommandeService } from '../services/commande.service';
import { Subscription } from 'rxjs';
import { ProductsGroup } from '../models/products-group';
import { Product } from '../models/product';
import { FullCart } from '../models/full-cart';
import { CartGroup } from '../models/cart-group';
import { CartItem } from '../models/cart-item';
import { cloneDeep } from 'lodash';
import { FolderStatus } from '../enums/folder-status';

@Component({
    selector: 'app-sidebar-commande',
    templateUrl: './sidebar-commande.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./sidebar-commande.component.less']
})

export class SidebarCommandeComponent implements OnInit, OnDestroy {
    //#region local properties
    private onCommandeChangeSubscription: Subscription | undefined;
    private getCartSubscription: Subscription | undefined;
    private getProductsgroupsSubscription?: Subscription;

    //current Cart
    cart?: FullCart;
   
    //products
    cartItems: CartItem[] = [];

    //groups
    productsGroups: ProductsGroup[] = [];

    //current user
    user: any = null;

    paths = location.pathname.split('/');
    currentPath: any = null;

    // exposant
    folderId: any;
    folder: any;
    //#endregion
 
    pdfFullCart:any = null;

    //#region ctor
    constructor(
        public app: AppComponent,
        private commandeService: CommandeService
    ) {
        //init user
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
    }
    //#endregion

    //#region handle lifecycle hooks
    ngOnDestroy(): void {
        this.unsubscribeActiveSubscriptions();
    }


    ngOnInit() {
        console.log("this.path==> ", this.paths);
        this.currentPath = this.paths[1];
        var urlId: any = this.app.router.url.split('/').pop();
        console.log("urlId ==> ",urlId);

        let directFolderID = this.app.route.snapshot.queryParams['folderID'];
        if (directFolderID) this.folderId = directFolderID;
        else this.folderId = this.user.FolderID;
  
        this.folderId = (this.currentPath == 'recapitulatif'
        || this.currentPath == 'infos-exposant'
        || this.currentPath == 'exposants-indirects'
        || this.currentPath == 'prestations') ? this.folderId : parseInt(urlId);
        console.log("this.folderId ==> ", this.folderId);

        this.initOnCommandeChangeHandler();

        this.getFolder(this.folderId);

        this.getCart();
    }

    showButtonPdf(): boolean{
        return (this.currentPath == 'detail-dp') && this.pdfFullCart && this.pdfFullCart.SignedPDF && this.pdfFullCart.SignedPDFDateTime;
        //return (this.currentPath == 'detail-dp') && this.isCurrentFolderSignedOrValidated();
    }
    //#endregion

    //#downloadPdf Function
    downloadPdf(_urlPdf:any){
        window.open(_urlPdf);
    }
    //#endregion

    //#region privat methods
    private getCart(): void {
        this.getCartSubscription = this.app.apiService
            .getFullCart(this.folderId).subscribe({
                next: (data: FullCart) => {
                    this.pdfFullCart = {
                        SignedPDF : data.SignedPDF,
                        SignedPDFDateTime : data.SignedPDFDateTime,
                    }
                    console.log("pdfFullCart", this.pdfFullCart);
                    
                    this.processGetFullCartSuccess(data);
                    this.getProductsGroups();
                },
                error: (error: any) => {
                    console.log(error);
                }
            })
    }

    private processGetFullCartSuccess(data: FullCart): void {
        //save response data to local property
        let newFullCart: FullCart = new FullCart();

        data.CartGroups = data.CartGroups?.map((cg: CartGroup) => {

            cg.CartItems = cg.CartItems?.map((ci: CartItem) => {
                let newCartItem: CartItem = new CartItem();
                Object.assign(newCartItem, ci);
    
                return newCartItem;
            });

            let newCartGroup: CartGroup = new CartGroup();
            Object.assign(newCartGroup, cg);

            return newCartGroup;
        });

        Object.assign(newFullCart, data);

        this.cart = newFullCart;
    }

    private unsubscribeActiveSubscriptions(): void {
        if (this.onCommandeChangeSubscription) {
            this.onCommandeChangeSubscription.unsubscribe();
        }
        
        if (this.getCartSubscription) {
            this.getCartSubscription.unsubscribe();
        }
        
        if (this.getProductsgroupsSubscription) {
            this.getProductsgroupsSubscription.unsubscribe();
        }
    }

    private initOnCommandeChangeHandler(): void {
        this.onCommandeChangeSubscription = this.commandeService.onCommandeChange()
            .subscribe(() => {
                this.handleCommandeChange()
            });
    }

    private handleCommandeChange(): void {
        this.getCart();
    }
    
  private getProductsGroups(): void {
    if(!this.productsGroups || this.productsGroups.length<1){
        this.getProductsgroupsSubscription = this.app.apiService.getProductsgroups().subscribe({
          next: (data: ProductsGroup[]) => {
            this.processGetProductsGroupsSuccess(data);
          },
          error: (error: any) => {
            console.log(error);
          },
        });
    }
    else{
        this.mapCartItemsRequiredPropertiesFromProducts();
    
        this.mapCartItemsOutOfCartGroups();
    }
  }

  private processGetProductsGroupsSuccess(data: ProductsGroup[]): void {
    //save response data to local property
    this.productsGroups = data.map(pg => {
      let newProductsGroup: ProductsGroup = new ProductsGroup();

      pg.Products = pg.Products?.map((p: Product) => {
        let newProduct: Product = new Product();
        Object.assign(newProduct, p);

        //initialize Product Quantity to Product MinQuantity
        if (!newProduct.Quantity) {
          newProduct.Quantity = newProduct.MinQuantity ?? 0;
        }

        return newProduct;
      });

      Object.assign(newProductsGroup, pg);

      return newProductsGroup;
    });

    console.log('this.productsGroups : ', this.productsGroups);
    console.log('this.cart : ', this.cart);

    this.mapCartItemsRequiredPropertiesFromProducts();

    this.mapCartItemsOutOfCartGroups();

    //map cartitems out of cartgroups

  }

  private mapCartItemsRequiredPropertiesFromProducts(): void{
    this.cart?.CartGroups?.forEach((cg: CartGroup) =>{
        let pg: ProductsGroup | undefined = this
            .productsGroups
            .find(pg => pg.GroupID === cg.ProductGroupID);

            if(pg && pg.Products && pg.Products.length > 0){
                cg.CartItems?.forEach((ci: CartItem)=>{
                    this.mapCartFromProduct(ci, pg?.Products);
                });
            }
    });
  }

  private mapCartFromProduct(ci: CartItem, products: Product[]| undefined): void{
    console.log('ci', ci, 'products', products)
    let prodcut : Product | undefined = products?.find(p => p.ProductID === ci.ProductID);
    if(prodcut){
        ci.NameEN = prodcut.NameEN;
        ci.NameFR = prodcut.NameFR;
        ci.NameES = prodcut.NameES;
        ci.Price = prodcut.getPrice();
    }
    console.log('ci after', ci)
  }

  private mapCartItemsOutOfCartGroups(): void{
    this.cartItems = [];
    this.cart?.CartGroups?.forEach((cg: CartGroup) =>{
        if(cg.CartItems){
            this.cartItems = [...this.cartItems, ...cg.CartItems];
        }
    })
  }

  private async getFolder(folderId: any) {

      try {
          const response = await this.app.apiService.getFolder(folderId);
          console.log("getFolder ==> ", response);

          if (response && response.Result) {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
              }, () => {
              })
              return;
          }

          this.folder = cloneDeep(response);
      }
      catch (error) {
          console.error(error);
      }
  }

  private isCurrentFolderSignedOrValidated(): boolean{
    return this.folder 
    && (this.folder.StatusID == FolderStatus.Signed 
        || this.folder.StatusID== FolderStatus.Validated )
  }
  //#endregion
}
