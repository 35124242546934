import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { StatusType } from '../enums/status-type';
import { StatusCode } from '../enums/status-code';
import { cloneDeep } from 'lodash';
import { DateHelper } from '../helpers/date.helper';


@Component({
  selector: 'app-create-edition',
  templateUrl: './create-edition.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./create-edition.component.less']
})
export class CreateEditionComponent {


  submitted: boolean = false;
  initForm: boolean = false;
  newLeadForm!: FormGroup;
  isEditMode: boolean = false;
  edition?: any;

  evenements: any[] = [];
  status: any[] = []
  paths = location.pathname.split('/');
  currentPath: any = null;
  editionId: any;
  eventName: any


  constructor(
    public app: AppComponent,
    private messageService: MessageService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {

    let cuser = JSON.parse(sessionStorage.getItem('userData')!);
    console.log(cuser);
    if (!cuser) {
      this.app.router.navigate(['/login']);
      return;
    }
    //check create or edit user
    console.log("this.path==> ", this.paths);
    this.currentPath = this.paths[1];
    var urlId: any = this.app.router.url.split('/').pop();
    if (this.currentPath == 'detail-edition') {
      this.editionId = parseInt(urlId);
    }


    this.getStatus()
    this.getEvenement()
    this.initFormulaire();

    if (this.editionId) {
      this.isEditMode = true;
      this.getEditionById(this.editionId);
      console.log("this.editionId ==> ", this.editionId);
    }
    else {
      //
      this.initFormulaire();
      console.log("this.newLeadForm", this.newLeadForm);
    }
  }


  async createEdition(editionObj: any): Promise<any> {
    console.log("CREATE Edition ===>", editionObj);
    try {
      if (!this.isEditMode) {
        const response = await this.app.apiService.createEdition(editionObj);
        console.log('RESPONSE CREATE Edition ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }

        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successEditionCree'), 'OK', "alert", () => {
          this.app.sharedTools.redirectTo('/gestion-des-editions');
        }, () => { });
      }
      else {
        editionObj.EditionID = this.editionId;
        const response = await this.app.apiService.updateEdition(editionObj);
        console.log('RESPONSE UPDATE USER ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }

        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successEditionUpdt'), 'OK', "alert", () => {
          this.app.sharedTools.redirectTo('/gestion-des-editions');
        }, () => { });
      }

    } catch (error) {
      console.error(error);
    }
  }


  onSubmit() {
    this.submitted = true;
    console.log('[this.newLeadForm]', this.newLeadForm);

    if (this.newLeadForm.invalid) {

      console.log("invalid form ===>", this.newLeadForm);
      console.log("invalid form ===>", this.newLeadForm.errors);

      //bloc explore form errors

      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.newLeadForm.controls;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log('Invalid form values:', invalidValues);

      if (invalidField && invalidField.length > 1) {
        console.log("invalidField", invalidField)

        setTimeout(() => {
          let el = invalidField[1].offsetTop - 100;
          if (el) window.scroll({ top: el, behavior: "smooth" });
        }, 10);
      }
      //
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
      }, () => { });
    } else {

      let newEdition = this.newLeadForm.value;

      //format dates
      console.log('newEdition ', newEdition.ExhiOpeningDate , newEdition.ExhiClosingDate , newEdition)

      if(newEdition.ExhiOpeningDate && newEdition.ExhiOpeningTime){
        newEdition.ExhiOpeningDate = DateHelper.concatDateOnlyTimeOnly(
          this.datePipe.transform(newEdition.ExhiOpeningDate, DateHelper.ApiDateFormat) ?? ''
          , newEdition.ExhiOpeningTime)
      }

      if(newEdition.ExhiClosingDate && newEdition.ExhiClosingTime){
        newEdition.ExhiClosingDate = DateHelper.concatDateOnlyTimeOnly(
          this.datePipe.transform(newEdition.ExhiClosingDate, DateHelper.ApiDateFormat) ?? ''
          , newEdition.ExhiClosingTime)
      }

      if(this.edition?.ExhiHalfdayTime && newEdition.ExhiHalfdayTime){
        newEdition.ExhiHalfdayTime = DateHelper.concatDateOnlyTimeOnly(
          this.datePipe.transform(this.edition?.ExhiHalfdayTime, DateHelper.ApiDateFormat) ?? ''
          , newEdition.ExhiHalfdayTime)
      }

      //retrieve selected properties
      if (newEdition.SelectedStatus) newEdition.StatusID = newEdition.SelectedStatus.StatusID;
      if (newEdition.SelectedEvenement) newEdition.EventID = newEdition.SelectedEvenement.EventID;



      this.app.sharedTools.deleteParamsInObject(newEdition
        , ['SelectedStatus'
          , 'SelectedEvenement']);

      console.log('[newEdition]', newEdition);
      this.createEdition(newEdition);
    }
  }

  //#region Get
  getStatus() {
    this.app.apiService.getStatus().subscribe((data: any) => {
      //console.log("getStatus ==> ", data);
      if (this.status) {
        this.status = data.filter((s: any) => s.StatusType === StatusType.EditionState);
        console.log("this.status", this.status)
      }
    });
  }

  getEvenement() {
    this.app.apiService.getEvenements().subscribe((data: any) => {
      console.log("getEvenement ==> ", data);
      if (this.status) {
        this.evenements = data;
      }
    });
  }

  private async getEditionById(editionId: any) {
    try {
      const response = await this.app.apiService.getEditionId(editionId);
      console.log("getEditionId ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
        }, () => {
        })
        return;
      }
      this.edition = cloneDeep(response);

      if (this.edition.EditionID) {
        this.edition.SelectedEvenement = this.evenements.find((ev: any) => ev.EventID === this.edition.EventID)
      }

      if (this.edition.EditionID) {
        this.edition.SelectedStatus = this.status.find((ev: any) => ev.StatusID === this.edition.StatusID)
      }

      this.eventName = this.edition.SelectedEvenement.Name + ' ' + this.edition.Year

      console.log("this.eventName", this.eventName)

      this.initFormulaire();

    }
    catch (error) {
      console.error(error);
    }
  }
  //#endregion


  //#region Button
  onDeleteEdition() {
    console.log("this.editionId", this.editionId)
    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteEdition'), 'AUTRE', 'confirm', () => {
      setTimeout(() => { this.deleteEdition(this.editionId); }, 1000);
    }, () => { });
  }

  async deleteEdition(editionId: any) {
    console.log("DELETE edition ===>", editionId);
    try {

      const response = await this.app.apiService.deleteEdition(editionId);
      console.log('Response API : DELETE edition ===>', response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
        return;
      }

      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successEditionDelete'), 'OK', "alert", () => {
        this.app.sharedTools.redirectTo('/gestion-des-editions');
      }, () => { });


    } catch (error) {
      console.error(error);
    }
  }

  annuler() {
    this.app.sharedTools.redirectTo('/gestion-des-editions');
  }
  //#endregion

  //#region Form
  private initFormulaire(): void {
    if (this.isEditMode) {
      this.newLeadForm = new FormGroup({

        SelectedStatus: new FormControl(this.edition.SelectedStatus ?? null),
        StatusID: new FormControl(this.edition?.StatusID ?? null),

        SelectedEvenement: new FormControl(this.edition.SelectedEvenement, Validators.required),
        EventID: new FormControl(this.edition?.EventID ?? null),

        EditionCode: new FormControl(this.edition?.EditionCode ?? null),
        ExternalEditionCode: new FormControl(this.edition?.ExternalEditionCode ?? null),
        Year: new FormControl(this.edition?.Year ?? null),
        Lieu: new FormControl(this.edition?.Lieu ?? null),


        ExhiOpeningDate: new FormControl(this.edition?.ExhiOpeningDate
          ? new Date(this.edition?.ExhiOpeningDate)
          : null),
        ExhiClosingDate: new FormControl((this.edition?.ExhiClosingDate)
          ? new Date(this.edition?.ExhiClosingDate)
          : null),

        ExhiOpeningTime: new FormControl(this.edition?.ExhiOpeningDate
          ? DateHelper.extractTime(this.edition?.ExhiOpeningDate)
          : DateHelper.MidnightTime),
        ExhiClosingTime: new FormControl((this.edition?.ExhiClosingDate)
          ?  DateHelper.extractTime(this.edition?.ExhiClosingDate)
          : DateHelper.MidnightTime),

        ExhiHalfdayTime: new FormControl((this.edition?.ExhiHalfdayTime)
          ? DateHelper.extractTime(this.edition?.ExhiHalfdayTime)
          : null),
      });
    }
    else {
      this.newLeadForm = new FormGroup(
        {
          SelectedStatus: new FormControl(null),
          StatusID: new FormControl(null),

          SelectedEvenement: new FormControl(null, Validators.required),
          EventID: new FormControl(null),

          Odcapacities: new FormControl(null),
          EditionCode: new FormControl(null),
          ExternalEditionCode: new FormControl(null),
          Year: new FormControl(null),
          Lieu: new FormControl(null),

          ExhiOpeningDate: new FormControl(null),
          ExhiClosingDate: new FormControl(null),
          

        ExhiOpeningTime: new FormControl(DateHelper.MidnightTime),
        ExhiClosingTime: new FormControl(DateHelper.MidnightTime),

        ExhiHalfdayTime: new FormControl(null),
        });
    }

    this.initForm = true;
  }
  //#endregion

  getColorStatus(status: any) {
    let classColor = "";
    if (status) {
      switch (status.Code) {
        case StatusCode.EDI1:
          classColor = "color-lime-green"
          break;

        case StatusCode.EDI2:
          classColor = "color-black"
          break;

        case StatusCode.EDI3:
          classColor = "color-red"
          break;

        default:
          classColor = "color-dark-blue"
          break;
      }
    }
    return classColor;
  }

  //#region attacher api christophe sur edition les 3 boutons
async onSupprimerFormulaire(editionObj: any) {
  console.log("onSupprimerFormulaire edition ==> ", editionObj);
  try {
      const response = await this.app.apiService.supprimerFormulaire(editionObj);
      console.log("response onSupprimerFormulaire ==> ", response);

      if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
          return;
      }

  } catch (error) {
      console.error(error);
  }
}

async onSupprimerEdition(editionObj: any) {
  console.log("onSupprimerEdition edition ==> ", editionObj);
  try {
      const response = await this.app.apiService.supprimerEdition(editionObj);
      console.log("response onSupprimerEdition ==> ", response);

      if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
          return;
      }

  } catch (error) {
      console.error(error);
  }
}

async onreInitialiserFindeer() {
  try {
      const response = await this.app.apiService.reInitialiserFindeer();
      console.log("response onreInitialiserFindeer ==> ", response);

      if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
          return;
      }

  } catch (error) {
      console.error(error);
  }
}



}
