import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { APPID } from '../enums/app_id';
import { StatusType } from '../enums/status-type';
import { FormCode } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-communique-press',
  templateUrl: './communique-press.component.html',
  styleUrls: ['./communique-press.component.less',],
  encapsulation: ViewEncapsulation.None
})
export class CommuniquePressComponent implements OnInit {

  appID: any
  listNews: any
  folderGuid: any
  status: any
  frmData: any
  constructor(public app: AppComponent) { }

  async ngOnInit() {
    await this.getCPFrmInfo()
    this.app.route.queryParams.subscribe(async (params: any) => {
      this.getNewsStatus()
      if (params && params["folderguid"]) {
        this.folderGuid = params["folderguid"]
        await this.getCommuniquePressWelcome(this.folderGuid)
      }
      this.app.sharedTools.getJSON('general_data').subscribe(
        async (resG: any) => {
          this.appID = APPID.CP;

        });

    })
  }

  async getCPFrmInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_CP
    let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
    if (data != undefined && data.Id != undefined) {
      this.frmData = data
    }
  }

  getNewsStatus() {
    this.app.apiService.getStatus().subscribe(
      data => {
        this.status = data?.filter((x: any) => x?.StatusType == StatusType.NEWS_Publish_State)
      }
    )
  }

  async newsPublishStatusChanged(result: any) {
    await this.getCommuniquePressWelcome(this.folderGuid)
  }


  addCommuniquePress() {
    this.app.router.navigate(["communique-press-detail"],
      { queryParams: { folderguid: this.folderGuid } })
  }


  async getCommuniquePressWelcome(folderGuid: any) {
    let response = await this.app.apiService.getCommuniquePressWelcom(folderGuid)
    this.listNews = response?.NewsDetails
  }




}
