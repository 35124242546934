import { Component, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';
import { EmailRegex } from '../consts/utils';

@Component({
    selector: 'app-create-dp',
    templateUrl: './create-dp.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./create-dp.component.less']
})
export class CreateDpComponent {

    submitted: boolean = false;
    initForm: boolean = false;
    newLeadForm!: FormGroup;
    civilities: any = [];
    langues: any = [];
    functions: any = [];
    otherFunction: any = {};

    constructor(
        public app: AppComponent,
    ) {
    }

    ngOnInit() {
        let user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log(user);
        if (!user) {
            this.app.router.navigate(['/login']);
            return;
        }

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG", resG);
                this.otherFunction = cloneDeep(resG.otherFunction);

                this.newLeadForm = new FormGroup({
                    CompanyName: new FormControl('', Validators.required),
                    NumExposant: new FormControl(''),
                    CivilityID: new FormControl(''),
                    SelectedCivility: new FormControl(null, Validators.required),
                    LastName: new FormControl('', Validators.required),
                    FirstName: new FormControl('', Validators.required),
                    FunctionID: new FormControl(''),
                    SelectedFunction: new FormControl(null, Validators.required),
                    FunctionOther: new FormControl(''),
                    Email: new FormControl('', [Validators.required, Validators.pattern(EmailRegex)]),
                    Login: new FormControl(''),
                    LanguageID: new FormControl(''),
                    SelectedLanguage: new FormControl(null, Validators.required),
                });

                this.setConditionalValidators();

                this.initForm = true;
                this.getCivilities();
                this.getFunctions();
                this.getLanguages();
                console.log("this.newLeadForm", this.newLeadForm)
            });

    }


    getLanguages() {
        this.app.apiService.getLanguages().subscribe((data: any) => {
            console.log("getLanguages ==>", data);
            this.langues = data;
        });
    }

    getCivilities() {
        this.app.apiService.getCivilities().subscribe((data: any) => {
            console.log("getCivilities ==> ", data);
            this.civilities = data; //cloneDeep(data);
        });
    }

    getFunctions() {
        this.app.apiService.getFunctions().subscribe((data: any) => {
            console.log("getFunctions ==> ", data);
            this.functions = data; //cloneDeep(data);
            this.functions.push(this.otherFunction);
        });
    }

    async createDp(formObj: any, sendMail: boolean): Promise<any> {
        console.log("CREATE FOLDER ===w>", formObj);

        try {
            const response = await this.app.apiService.createFolder(formObj);
            console.log('RESPONSE CREATE DP ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                }, () => {
                })
                return;
            }

            if (sendMail) {
                let user = {
                    Login: (response.Company.Contacts[0].Login) ? response.Company.Contacts[0].Login : response.Company.Contacts[0].Email,
                    Email: response.Company.Contacts[0].Email,
                    FolderID:response.FolderID,
                    Language: this.app.sharedTools.getParamByID(response.Company.Contacts[0].LanguageID,
                        'LanguageID', this.langues, 'CodeISO2', true) //response.Company.Contacts[0].LanguageID
                }
                console.log('[user]', user);

                const responseMail = await this.app.apiService.mailautologin(user);
                console.log('RESPONSE EMAIL ===>', responseMail);

                if (responseMail && responseMail.Result) {
                    this.app.confirm.confirmDialog(this.app.translate.instant(''), this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + responseMail.Result), 'KO', "alert", () => {
                    }, () => {
                    })
                    return;
                }

                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successDossierCreeSendMail'), 'OK', "alert", () => {
                    this.app.sharedTools.redirectTo('/');
                }, () => { });

            } else {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successDossierCree'), 'OK', "alert", () => {
                    this.app.sharedTools.redirectTo('/');
                }, () => { });
            }

        } catch (error) {
            console.error(error);
        }
    }

    setConditionalValidators() {
        console.log("SelectedFunction", this.newLeadForm.get('SelectedFunction')?.value);

        this.newLeadForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        const FunctionOther = this.newLeadForm.get('FunctionOther')!;

        this.newLeadForm.get('SelectedFunction')?.valueChanges
            .subscribe(SelectedFunction => {
                console.log("SelectedFunction", SelectedFunction)

                if (SelectedFunction && SelectedFunction.FunctionID == -99) {
                    FunctionOther.setValidators([Validators.required]);
                } else {
                    FunctionOther.setValidators(null);
                    FunctionOther.setValue('');
                }

                FunctionOther.updateValueAndValidity();
            });
    }

    annuler() {
        this.app.sharedTools.redirectTo('/');
    }

    onSubmit(sendMail: boolean) {
        this.submitted = true;
        // Logic to handle form submission
        console.log('[this.newLeadForm]', this.newLeadForm);

        if (this.newLeadForm.invalid) {
            console.log("invalid form");
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
            }, () => { });
        } else {
            // Do submit logic
            let newFolder = this.newLeadForm.value;

            if (newFolder.SelectedCivility) newFolder.CivilityID = newFolder.SelectedCivility.CivilityID;
            if (newFolder.SelectedFunction) newFolder.FunctionID = newFolder.SelectedFunction.FunctionID;
            if (newFolder.SelectedLanguage) newFolder.LanguageID = newFolder.SelectedLanguage.LanguageID;

            newFolder.Login = newFolder.Email;

            this.app.sharedTools.deleteParamsInObject(newFolder, ['SelectedCivility', 'SelectedFunction', 'SelectedLanguage'])
            console.log('[newFolder]', newFolder);

            this.createDp(newFolder, sendMail);
        }
    }

}
