import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { AppComponent } from 'src/app/app.component';
import { APPID } from 'src/app/enums/app_id';
import { ConferenceProgramCode } from 'src/app/enums/conference-program-code';
import { ApiService } from 'src/app/services/api.service';
import { ConferenceService } from '../services/conference.service';
import { ConferenceStoreService } from 'src/app/services/conference-store.service';
import { Paginator } from 'primeng/paginator';
import { FOConferencesSearchLocalStorageKey } from 'src/app/consts/utils';
import { SearchHelperService } from 'src/app/services/search-helper.service';
import { defaultAppGlobalConfig } from 'src/app/models/app-global-config';

@Component({
  selector: 'app-welcome-conf',
  templateUrl: './welcome-conf.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './welcome-conf.component.less',
    './welcome-conf.component-responsive.less']
})
export class WelcomeConfComponent implements OnInit {
  private _isPaginationInfoRestaured: boolean = false;
  public rowsPerPageOptions = defaultAppGlobalConfig.rowsPerPageOptions;
  @ViewChild('paginator') paginator?: Paginator;

  // first
  first: number = 0;

  // nbElementsPerPage
  private _nbElementsPerPage: number = defaultAppGlobalConfig.defaultRowsNumberPerPage;
  public get nbElementsPerPage(): number {
    return this._nbElementsPerPage;
  }

  public set nbElementsPerPage(value: number) {
    this._nbElementsPerPage = value;
    this.first = (this._pageIndex ?? 0) * (this.nbElementsPerPage ?? 1)
  }

  // pageIndex
  private _pageIndex: number = 0;
  public get pageIndex(): number {
    return this._pageIndex;
  }

  public set pageIndex(value: number) {
    this._pageIndex = value;
    this.first = (this._pageIndex ?? 0) * (this.nbElementsPerPage ?? 1)
  }
  
  // totalItems
  totalItems: number = 0;
  
  user: any = null;
  appID: any = null;

  folderGuid: any;
  innovform: any;
  conference!: any;
  conferences: any[] = [];
  search: any = {};
  DayConference: any;
  currentlangue: any;
  interests: any;
  speakers: any;
  exhibitors: any;
  isEnabled: boolean = false;
  calendarObj: any;
  Description: string = '';
  Location: string = '';
  Summary: string = '';
  IsfileIsc: boolean = false;
  arrayAfterSearch: any[] = []

  isActive: boolean = false;
  @ViewChildren('buttons') buttons = new QueryList<ElementRef>;

  
  public buttonIndices: number[] = [];
  
  currentDate: Date = new Date();
  formatedDate: any;
  
  activatedTabIndex: number = 0;
  dateOfSearch: any;
  
  activeIndex: any;
  
  showFilterMobile: boolean = false;
  
  currentProgramCode: string = ConferenceProgramCode.PROG1;
  
  listDaysInfo: any[] = [];
  
  screenWidth: number = 0;
  isDesktop: boolean = false;
  isMobile: boolean = false;

  constructor(
    public app: AppComponent,
    private datePipe: DatePipe,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver,
    //private _conferenceService:ConferenceService,
    protected conferenceStore: ConferenceStoreService,
    private searchHelperService : SearchHelperService
  ) {
    this.initializeConfiguredOptions();
    this.screenWidth = window.innerWidth;
  }

  async ngOnInit() {

    this.onResize()

    this.app.route.queryParams.subscribe(params => {
      if (params && params['program'] && params['program'] != '') {
        this.currentProgramCode = params['program'];
        console.log(this.currentProgramCode);
      }
    });

    // this.breakpointObserver.observe([
    //   Breakpoints.HandsetPortrait
    // ]).subscribe(result => {
    //   this.isMobile = result.matches;
    // });

    this.formatedDate = this.datePipe.transform(this.currentDate, 'dd/MM/yyyy');
    console.log(this.app.route);


    this.app.sharedTools.getJSON("general_data").subscribe((resG: any) => {
      this.appID = resG.APP_ID;
      this.currentlangue = this.app.translate.currentLang;
    });

    this.appID = APPID.Conf;
    this.search.DateEdition = this.conferenceStore.currentConferenceDate ?? new Date();
    
    //await this.searchConference(this.search);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 995) {
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }


  triggerButtonClick(index: number) {
    setTimeout(() => {
      console.log("🚀  index:", index)
      const buttonToClick = this.buttons.toArray()[index];

      if (buttonToClick) {
        console.log("*---* btn clicked !");
        buttonToClick.nativeElement.click();
        buttonToClick.nativeElement.focus();
        buttonToClick.nativeElement.classList.add('activated');
        this.activeIndex = { 'background-color': '#46c2f1' }
      }

    }, 2000);
  }

  private getFocusedButtonIndex() {
    const focusedIndex = this.conferences.findIndex(conf => conf.focused === true);
    return focusedIndex !== -1 ? focusedIndex : 0;
  }

  changeFocus() {
    this.conferences.forEach(conf => {
      if (this.isDateEquals(conf?.DateOfSearch, this.currentDate)) {
        conf.focused = false;
      }
    });
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1); // Months are zero-based
    const day = this.padZero(date.getDate());
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  private padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  private isDateEquals(date1: Date, date2: Date) {
    let result: boolean;

    if (date1 < date2) {
      result = false;
    } else if (date1 > date2) {
      result = false;
    } else {
      result = true;
    }

    console.log("🚀  result:", result)

    return result;
  }

  onClearSearch(): void {
    this.pageIndex = 0;
    
    console.log("*** onClearSearch");
    this.search = {};
    this.searchConference(this.search);
  }

  public onFilterConference(search: any) {
    this.pageIndex = 0;

    search.DateEdition = this.search.DateEdition;
    this.searchConference(search);
  }

  public handleSearchButtonClick(event: any): void{
    // Evol close search engine, when : on mobile device and clique on search button
    if(!this.isDesktop && this.isMobile && this.showFilterMobile){
      this.toggleFilter();
    }
  }

  //#region serach
  async searchConference(search: any) {
    if (!this._isPaginationInfoRestaured) {
      this.restaurePaginatorInfo();
    }

    console.log("*** search ==> ", search);
    let searchTemp: any = cloneDeep(search);
    console.log("searchTemp ==> ", searchTemp);
    if (!searchTemp) {
      searchTemp = {};
    }
    this.search = searchTemp;

    // Begin Conf Prog
    this.search.ProgramCode = this.currentProgramCode;
    this.search.CurrentLanguage = this.app.translate.currentLang
    // end Conf Prog

    this.search.NbElementsPerPage = this.nbElementsPerPage;
    this.search.PageIndex = this.pageIndex;
    this.search.GetAll = false;

    // save latest search criteria

    let response = await this.apiService.searchConference(this.search);
    this.conference = {};

    if (response) {
      this.conference = response;
      this.DayConference = cloneDeep(this.conference.ListDays);
      this.search.DateOfSearch = response.DateOfSearch;
      this.conferenceStore.confEventsIds = this.conference.EventsIds;
      console.log(this.DayConference, this.search.DateOfSearch, this.search.DateOfSearch?.substring(0, 10));

      // save latest search criteria
      // this.searchHelperService.setAdvancedSearch(this.search, `${FOConferencesSearchLocalStorageKey}|${this.search.DateOfSearch}`);
      this.searchHelperService.setAdvancedSearch(this.search, FOConferencesSearchLocalStorageKey);

      if(this.DayConference && Array.isArray(this.DayConference)){
        let dateOfSearchInfo = this.DayConference.find((c: any) => c.Date?.substring(0, 10) == this.search.DateOfSearch?.substring(0, 10));

        console.log(dateOfSearchInfo)

        if(!dateOfSearchInfo || !(dateOfSearchInfo?.NbConferences)){
          this.totalItems = 0;
        }
        else{
          this.totalItems = dateOfSearchInfo.NbConferences;
        }
      }
      else{
        this.totalItems = 0;
      }

      console.log('totalItems ', this.totalItems)

    }

    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
  //#endregion

  formatDateWithLanguage(date: any) {
    return this.datePipe.transform(date, 'fullDate', undefined, this.app.translate.currentLang);
  }

  formatDayOfWeek(date: any) {
    return this.datePipe.transform(date, 'EEEE', undefined, this.app.translate.currentLang);
  }

  formatDayOfMonth(date: any) {
    return this.datePipe.transform(date, 'd', undefined, this.app.translate.currentLang);
  }

  clickLink(routerLink: any) {
    this.app.sharedTools.redirectTo(routerLink);
  }

  async getDetailByDate(data: any) {
  }

  async tabChange(tabInfo: any) {
    console.log("***  tabChange:", tabInfo);
    this.activatedTabIndex = tabInfo.index;
    this.search.DateEdition = tabInfo.date;

    await this.searchConference(this.search);
  }

  toggleFilter() {
    this.showFilterMobile = !this.showFilterMobile;
  }
  
  onPageChange(event: any) {
    console.log('event : ', event);
    this.pageIndex = event.page ?? 0;
    this.nbElementsPerPage = event.rows ?? defaultAppGlobalConfig.defaultRowsNumberPerPage;
    this.searchConference(this.search);
  }

  private restaurePaginatorInfo(): void {
    let latestsearch: any = this.searchHelperService.getAdvancedSearch(FOConferencesSearchLocalStorageKey);

    if (latestsearch) {
      //this.pageIndex = latestsearch.PageIndex ? latestsearch.PageIndex : 0;
      this.nbElementsPerPage = latestsearch.NbElementsPerPage ? latestsearch.NbElementsPerPage : defaultAppGlobalConfig.defaultRowsNumberPerPage;

      console.log(this.first, this._pageIndex, this._nbElementsPerPage);
    }

    this._isPaginationInfoRestaured = true;
  }

  private initializeConfiguredOptions(): void{
    this.rowsPerPageOptions = this.app.sharedTools.appGlobalConfig.rowsPerPageOptions;
    this.nbElementsPerPage = this.app.sharedTools.appGlobalConfig.defaultRowsNumberPerPage;
  }
}
