import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { arrayIntersection } from '../consts/utils';
import { Router } from '@angular/router';
import { RoleTypeId } from '../enums/role-type-id';

@Injectable({
  providedIn: 'root'
})
export class IdentityManagerService {
  private _currentUser!: User;

  get currentUser(): User {
    return this._currentUser;
  }

  private set currentUser(value: User) {
    if(value){
      value.Roles = this.extractRolesToArray(value);
    }

    this._currentUser = value;
  }

  constructor(private router: Router) {
    let retrieveUser = JSON.parse(sessionStorage.getItem('userData')!);
    if(retrieveUser){
      this.currentUser = JSON.parse(sessionStorage.getItem('userData')!);
    }
    else {
      console.warn('user not found');
    }
  }

  public haveAuthorizedRole(roles: number[]): boolean{
    if(!roles || roles.length === 0){
      return true;
    }

    if(!this.currentUser?.Roles){
      return false;
    }

    let intersection = arrayIntersection(roles, this.currentUser.Roles);

    if(intersection && intersection.length>0){
      return true;
    }

    return false;
  }

  public checkIsUserAuthenticated(): boolean{
    if (!this.currentUser) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
  
  public get isUserAuthenticated(): boolean{
    if (!this.currentUser) {
      return false;
    }
    return true;
  }

  //#region private methods
  private extractRolesToArray(user: User): number[] {
    let roles: number[] = [];
    if(user){

      roles = JSON.parse("[" + (user.RoleID ?? '') + "]");
  
      if (!roles || roles.length === 0) {
        throw new Error('user roles not found');
      }
    }

    return roles;
  }
  //#endregion

  
  //#region private methods
  public isOzUser(): boolean {
      if(this.currentUser.Roles.includes(RoleTypeId.VM_OZ))
      {return true;}
      return false
  }

  public isProgUser(): boolean {
    if(this.currentUser.Roles.includes(RoleTypeId.VM_Programmer))
    {return true;}
    return false
}

public haveOnly(idTypeRole : number): boolean {
  if(this.currentUser.Roles.every(role => role === idTypeRole))
  {return true;}
  return false
}
  //#endregion
}
