import { Component } from '@angular/core';

@Component({
  selector: 'app-thanks-page',
  templateUrl: './thanks-page.component.html',
  styleUrls: ['./thanks-page.component.less']
})
export class ThanksPageComponent {

}
