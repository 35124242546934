import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { IdentityManagerService } from '../services/identity-manager.service';
import { cloneDeep } from 'lodash';
import { APPID } from '../enums/app_id';
import { TranslatePipe } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { FormCode } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-welcome-innov',
    templateUrl: './welcome-innov.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./welcome-innov.component.less']
})
export class WelcomeInnovComponent {
    user: any = null;
    appID: any = null;
    welcomeObj: any = null;
    
    folderGuid: any;
    innovform: any;
    frmData: any;

    constructor(
        public app: AppComponent,
        private identityManagerService: IdentityManagerService,
        private translatePipe: TranslatePipe,
        private datePipe: DatePipe
    ) { }

    ngOnInit() {
        console.log(this.app.route); 
        this.app.route.queryParams.subscribe((params: any) => {
            console.log('p ',params)
            this.folderGuid = params['folderguid'];
          console.log(`Param Value: folder ${this.folderGuid}`);
        });
        
        this.identityManagerService.checkIsUserAuthenticated();
        this.user = cloneDeep(this.identityManagerService.currentUser);

        // this.app.sharedTools.changeUsrLangInInit();

        // this.app.sharedTools.getCurrentLanguage();

        sessionStorage.removeItem('folderStep');
        sessionStorage.removeItem('activeStep');

        this.app.sharedTools.getJSON('general_data').subscribe(
            (resG: any) => {
                //console.log("resG", resG);
                this.appID = resG.APP_ID;
                
                this.welcomeObj = {
                    FormID: null,
                    Contact : {
                        FirstName: this.user?.Contact?.FirstName,
                        LastName: this.user?.Contact?.LastName
                    },
                    DateFin: null,
                    DateOpen :null,
                    IsOpen: null,
                }
                this.welcomeInnovForm();
            });
            this.appID = APPID.Innov;
            this.getInnovInfo();
    }

    async welcomeInnovForm() {
        let request: any = {
            FolderInternalGuid: this.folderGuid
        }
        
        let res = await this.app.apiService.welcomeInnovForm(request);
        if(res){
            console.log("🚀  welcomeInnovForm : ", res);
            this.innovform = res;
            sessionStorage.setItem('innov-form', JSON.stringify(this.innovform));
            this.welcomeObj.DateFin = this.innovform.DateFin;
            this.welcomeObj.DateOpen = this.innovform.DateOpen;
            this.welcomeObj.IsOpen = this.innovform.IsOpen;
            this.welcomeObj.ContactLastName = this.innovform.ContactLastName;
            this.welcomeObj.ContactFirstName = this.innovform.ContactFirstName;
            this.welcomeObj.ContactFullName = this.innovform.ContactFullName;
            this.welcomeObj.CompanyName1 = this.innovform.CompanyName1;
            this.welcomeObj.CompanyName2 = this.innovform.CompanyName2;
            this.welcomeObj.Edition = this.innovform.Edition;
            this.welcomeObj.FrmDeadLine=this.innovform.FrmDeadLine;
            this.welcomeObj.ShowDeadlineDate = this.innovform.ShowDeadlineDate
        }
      }

      next(): void{
        sessionStorage.setItem('folderGuid', JSON.stringify(this.folderGuid));
        this.app.sharedTools.redirectTo('/products-innov');
      }

      public replaceTokens(text: string): string{
        if(!text){
            text = '';
        }

        text = this.translatePipe.transform(text) ?? '';
        text = text.replace('{firstname}', this.welcomeObj.ContactFirstName??'');
        text = text.replace('{lastname}', this.welcomeObj.ContactLastName??'');
        text = text.replace('{fullname}', this.welcomeObj.ContactFullName??'');
        text = text.replace('{edition}',  this.welcomeObj.Edition??'');
        text = text.replace('{openingdate}', this.datePipe.transform(this.welcomeObj.DateOpen, 'dd MMMM yyyy', undefined, this.app.translate.currentLang) ??'');
        text = text.replace('{closingdate}', this.datePipe.transform(this.welcomeObj.DateFin, 'dd MMMM yyyy', undefined, this.app.translate.currentLang) ??'');
        text = text.replace('{echeancedate}',   this.datePipe.transform(this.welcomeObj.FrmDeadLine, 'dd MMMM yyyy', undefined, this.app.translate.currentLang) ??'');
        return text;
      }
      
      async getInnovInfo() {
          let request = { frmType: "" }
          request.frmType = FormCode.FRM_INNOV;
          let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
          if (data != undefined && data.Id != undefined) {
              this.frmData = data;
          }
      }
}
