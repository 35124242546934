import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { StatusCode } from '../enums/status-code';
import { cloneDeep } from 'lodash';
import { MediasTypes } from '../enums/media-type';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-products-list-dataview',
    templateUrl: './products-list-dataview.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./products-list-dataview.component.less']
})
export class ProductsListDataviewComponent {

    @Input() products: any[] = [];

    @Output() refresh: EventEmitter<void> = new EventEmitter<void>();

    translatedNbProdMessage: any = "";
    state: any;
    product: any;
    status: any[] = [];
    toBeValidateStatus: any;
    mediaTypes = MediasTypes;
    prod: any

    constructor(
        public app: AppComponent,
    ) {

    }

    ngOnInit() {
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                this.getStatus();
                console.log("products", this.products)
                const productsLength = this.products ? this.products.length : 0; // Assuming products is your array
                this.translatedNbProdMessage = this.app.translate.instant('fo.productListCommun.nbProducts').replace('{nb}', productsLength.toString());
            });
    }

    getStatus() {
        this.app.apiService.getStatus().subscribe((data: any) => {
            this.status = cloneDeep(data);

            this.toBeValidateStatus = this.status.find((s: any) => s.Code === StatusCode.TOVALIDATE_FICHE);
        });
    }

    getManySwitchStatus(status: string) {
        let color: any = "";
        switch (status) {
            case StatusCode.TOVALIDATE_FICHE:
                color = 'text-orange-400';
                break;
            case StatusCode.DRAFT_FICHE:
                color = 'color-default';
                break;
            case StatusCode.PUBLISHED_FICHE_PUBLISH:
                color = 'color-lime-green';
                break;
            case StatusCode.UNDER_REVIEW_FICHE:
                color = 'color-gray';
                break;
            case StatusCode.VALIDATED_FICHE:
                //color = 'text-teal';
                color = 'text-green-400';
                break;
            case StatusCode.CANCELED_FICHE:
                color = 'color-red';
                break;
            case StatusCode.UNPUBLISHED_FICHE_PUBLISH:
                color = 'text-purple';
                break;
            case StatusCode.SCHEDULED_FICHE_PUBLISH:
                color = 'fill-light-blue';
                break;
        }
        return color;
    }


    onSetStatus(SolutionID: number, CGVaccepted: boolean) {
        this.setStatus(SolutionID, CGVaccepted);
    }

    async setStatus(SolutionID: number, CGVaccepted: boolean) {
        let request: any = {
            SolID: SolutionID,
            Status: this.toBeValidateStatus.StatusID,
            Submited : true
        }
        if (CGVaccepted) {
            this.state = await this.app.apiService.setStatusProduct(request);
            this.refresh.emit();
            console.log("set Status Product", this.state);
        }
        if (!CGVaccepted) {
            let queryparams: any = {
                productid: SolutionID
            }
            
            this.app.sharedTools.redirectToWithParameters('product-recapitulatif/edit', queryparams);
        }


    }

    onDeleteProduct(Solution: any) {
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteInnov'), 'AUTRE', 'confirm', () => {
            setTimeout(() => { this.deleteProduct(Solution); }, 1000);
        }, () => { });
    }

    async deleteProduct(Solution: any) {
        console.log("DELETE innovForm ===>", Solution);
        try {
            const response = await this.app.apiService.deleteProduct(Solution);
            console.log('Response API : DELETE Inoov ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successInnovDelete'), 'OK', "alert"
                , () => { this.refresh.emit(); }
                , () => { this.refresh.emit(); });


        } catch (error) {
            console.error(error);
        }
    }

    async updateProduct(_solID: number) {
        console.log("[gotToGetProduct] ==> ", _solID);
        let queryparams: any = {
            productid: _solID
        }
        
        this.app.sharedTools.redirectToWithParameters('product-generalites/edit', queryparams);
    }

    // async getProduct(_solID: number) {
    //     console.log("[gotToGetProduct] ==> ", _solID);
    //     let queryparams: any = {
    //         productid: _solID
    //     }

    //     this.app.sharedTools.redirectToWithParameters('product-recapitulatif/consult', queryparams);
    // }
    openFicheInnovation(solutionId : number) {
        let generateFicheInnovation :{
            listSolutions : any,
            isSingleFicheRequest: boolean
          } = {
            listSolutions : [solutionId],
            isSingleFicheRequest: true
          }
        this.app.apiService.generateFicheMedia(generateFicheInnovation).subscribe(
          data => {
            if(data && data.IsSuccessful && data.Url){
              window.open(data.Url,"_blank")
            }
            if(!data.IsSuccessful){
              setTimeout(() => {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Error)
                    , 'KO'
                    , "alert"
                    , () => { /*this.app.sharedTools.redirectTo('/');*/ }
                    , () => { });
            }, 200);
            return;
            }
          }
        )
      }


}
