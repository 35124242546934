import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommandeService {
  //#region properties
  private _commandeSubject = new Subject();
  //#endregion

  //#region ctor
  constructor() { }
  //#endregion

  //#region actions
  public commandeChanged(): void {
    this._commandeSubject.next(undefined);
  }

  public onCommandeChange(): Observable<any> {
    return this._commandeSubject.asObservable();
  }
  //#endregion
}
