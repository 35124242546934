import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';
import { CategoryType } from '../enums/category-type';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormGroup } from '@angular/forms';
import { AccordionTab } from 'primeng/accordion';
import { DemandDetailComponent } from '../demand-detail/demand-detail.component';
import { FederateurListWindowComponent } from '../federateur-list-window/federateur-list-window.component';
import { StatusCode } from '../enums/status-code';
import { ExposantType } from '../enums/exposant-type';
import { HelperService } from '../services/helper.service';
import { ReportingService } from '../services/reporting.service';
import { PlanningHTMLOptionsComponent } from '../planning-htmloptions/planning-htmloptions.component';
import { FormCode } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-fo-f18-etat2-sur2',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './fo-f18-etat2-sur2.component.html',
  styleUrls: ['./fo-f18-etat2-sur2.component.less']
})
export class FoF18Etat2Sur2Component implements OnDestroy {
  user: any = null;
  search: any
  formID!: number;
  folderID: any;

  newLeadForm!: FormGroup;

  typeCategory: any = [];
  listDoCountry: any = [];
  listDo: any = [];
  myDoListGroup: any = [];
  counter: any = [];
  listDOToExport: any[] = [];

  SelectedTypeCategories: any[] = [];
  SelectedContries: any[] = [];
  doTypeIDs: any = [];
  doPaysIDs: any = []; 


  source: any;
  targetP1: any[] = [];
  targetP2: any[] = [];
  targetP3: any[] = [];

  selectedItem!: any;
  selectedTarget1!: any;
  selectedTarget2!: any;
  selectedTarget3!: any;

  ref: DynamicDialogRef | undefined;

  activeIndex: number | undefined;

  //schedule button activity status
  printPlanningDo: boolean = true;
  printPlanningExposant: boolean = true;
  exposant: any;

  isFederatedMode: boolean = false;
  exposantType = ExposantType;

  //#region role VM-Redacteur
  f18form: any;
  isRedacteur: boolean = false;
  groupPeriority: any = [];
  //#endregion

  exportcsvColumns: any[] = [];
  areDemandeDOButtonsDisabled : boolean = false
  frmData : any;

  constructor(
    public app: AppComponent,
    public dialogService: DialogService,
    public messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private helperService: HelperService,
    public reportingService: ReportingService,
  ) {

    //test sur route and calculate isFederatedMode
    const routeWithoutQueryParams = app.router.url.split('?')[0];

    if (routeWithoutQueryParams === '/vm-f18-etat2sur2') {
      this.isFederatedMode = false;
    }
    else if (routeWithoutQueryParams === '/vm-f18-etat2sur2/exhibitor') {
      this.isFederatedMode = true;
    }

    this.getForm18Data();
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  async ngOnInit() {

    this.user = JSON.parse(sessionStorage.getItem('userData')!);
    console.log("this.user ==> ", this.user);
    if (!this.user) {
      this.app.router.navigate(['/login']);
      return;
    }

    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
        console.log("resG", resG);
        this.exportcsvColumns = resG.listeDemandesExhebitor;
        this.groupPeriority = resG.GroupPeriority;

      });
      this.getVMFrmInfo();
    // this.app.sharedTools.changeUsrLangInInit();

    // this.app.sharedTools.getCurrentLanguage();

    sessionStorage.removeItem('folderStep');
    sessionStorage.removeItem('activeStep');

    // this.getCountries();
    await this.getExposant(this.formID);




    this.getListDo();
    this.getMyDoListGroup();
    this.getCounter();
    if (this.exposant?.ExposantType == this.exposantType.Exhibitor && this.exposant?.IsFedere && this.isFederatedMode) {
      this.getCategories();
      this.getCountries();
      this.initFedere();

      setTimeout(() => {
        this.app.confirm.confirmDialog('', this.app.translate.instant('fo.vmf18Etat2Sur2.nomSocieteConnecte') + ' ' + `<div class='flex justify-content-center w-full color-secondary'>${this.exposant?.CompanyName2}</div>` + ' ' + this.app.translate.instant('fo.vmf18Etat2Sur2.revenirDashboard')
          , 'Ok'
          , "alert", () => {
          }, () => {
          });
      }, 300);
    }
    else {
      this.getListDoType();
      this.getListDoCountry();
    }

    this.source = [];

    this.targetP1 = [];
    this.targetP2 = [];
    this.targetP3 = [];

    this.primengConfig.ripple = true;
  }

  onchangeSelect(_item: any) {
    if (!_item) return;
    // console.log("onchangeSelect", _item);
  }

  onSearchCreteriaChange(): void {
    this.doTypeIDs = [];
    this.doPaysIDs = [];

    if (this.SelectedTypeCategories?.length > 0) {
      this.SelectedTypeCategories.forEach((c: any) => {
        this.doTypeIDs.push(c.CategoryID);
      });
    }

    if (this.SelectedContries?.length > 0) {
      this.SelectedContries.forEach((c: any) => {
        this.doPaysIDs.push(c.CountryID);
      });
    }

    console.log('ids search ', this.doPaysIDs, this.doTypeIDs);

    this.getListDo();
  }

  async getListDoType() {
    const resp = await this.app.apiService.getListDoType(this.formID);

    let categoryDO = cloneDeep(resp);

    this.typeCategory = cloneDeep(categoryDO);
  }

  async getCategories() {
    try {
      const response = await this.app.apiService.getCategories();
      console.log("🚀  response category :", response);

      let categoriesIni = cloneDeep(response);

      this.typeCategory = cloneDeep(categoriesIni.filter((cat: any) => {
        return cat.CategoryType === CategoryType.SectorDO;
      }));

    } catch (error) {
      console.error(error);
    }
  }


  async getListDoCountry() {
    const resp = await this.app.apiService.getListDoCountry(this.formID);

    let listCountry = cloneDeep(resp);

    this.listDoCountry = cloneDeep(listCountry);
  }

  private async getListDo() {
    let filterByWhishedCountriesAndCategories: boolean = !(this.exposant?.ExposantType == this.exposantType.Exhibitor && this.exposant?.IsFedere && this.isFederatedMode);
    const resp = await this.app.apiService.getListDo(this.doTypeIDs, this.doPaysIDs, this.formID, filterByWhishedCountriesAndCategories);

    let listDo = cloneDeep(resp);

    listDo.forEach((od: any) => {
      od.isContactToggl = false;
      this.orderCompositionContacts(od);
    });

    this.listDo = cloneDeep(listDo);


    console.log("🚀  this.listDo:", this.listDo);

  }

  async getMyDoListGroup() {
    const resp = await this.app.apiService.getMyDoListGroup(this.formID);

    console.log(resp)
    let listGroup = cloneDeep(resp);

    this.myDoListGroup = cloneDeep(listGroup);
    console.log("🚀  this.myDoListGroup:", this.myDoListGroup);
    this.targetP1 = [];
    this.targetP2 = [];
    this.targetP3 = [];

    if (this.myDoListGroup instanceof Array) {


      this.myDoListGroup.forEach((myDo: any) => {
        console.log('myDo ', myDo)
        if (myDo.Value) {
          myDo.Value.forEach((od: any) => {
            console.log('od ', myDo)
            if (od?.Groups) {
              this.orderCompositionContacts(od.Groups);
            }
          });

          switch (myDo.Key) {
            case "P1":
              // console.log("🚀  myDo P1 : ", myDo);
              this.targetP1 = myDo.Value;
              console.log("target p1 ==> ", myDo.Value)
              break;
            case "P2":
              // console.log("🚀  myDo P2 : ", myDo);
              this.targetP2 = myDo.Value;
              break;
            case "P3":
              this.targetP3 = myDo.Value;
              // console.log("🚀  myDo P3 : ", myDo);

              break;

            default:
              break;
          }
        }
      });
    }

  }
  toggle(index: number, ref: AccordionTab) {
    // ref.accordion.activeIndex = -
    // this.activeState[index] = !this.activeState[index];
  }

  isAccordionOpen = false;
  toggleAccordion(od: any, event: any): void {
    event.stopPropagation();
    event.preventDefault();
    od.isContactToggl = !od.isContactToggl;
    // this.isAccordionOpen = !this.isAccordionOpen;
    // console.log("🚀  this.isAccordionOpen: od", od)
    console.log("od ==> ", od)

  }
  onODItemClick(event: any): void {
    if (this.selectedItem && this.selectedItem == event.value) {
      this.selectedItem = null;
    }
    else {
      this.selectedItem = event.value;
    }
  }

  stopEvent(event: any): void {
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    event.stopPropagation();
    event.preventDefault();
    console.log(event);
  }
  showDialog() {
    this.messageService.clear();
    this.messageService.add({ severity: 'warn', summary: '', detail: 'No selection !' });
  }

  moveToTarget1() {
    console.log("🚀  MoveToTarget 1...");
    console.log("🚀  item:", this.selectedItem);
    if (this.selectedItem) {
      this.ref = this.dialogService.open(DemandDetailComponent, {
        //header: 'Edit selected',
        width: '80%',
        height: '90%',
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        maximizable: false,
        data:
        {
          od: this.selectedItem,
          formID: this.formID,
          app: this.app,
          priority: 'P1',
          folderID: this.folderID
        }
      });

      this.ref.onClose.subscribe((val: any) => {
        console.log('closed ', val);
        if (val === true) {
          this.refresh();
        }
      });
    } else {
      this.showDialog();
    }
  }

  moveToTarget2() {
    console.log("🚀  MoveToTarget 2...");
    if (this.selectedItem) {
      this.ref = this.dialogService.open(DemandDetailComponent, {
        //header: 'Edit selected',
        width: '80%',
        height: '90%',
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        maximizable: false,
        data:
        {
          od: this.selectedItem,
          formID: this.formID,
          app: this.app,
          priority: 'P2',
          folderID: this.folderID
        }
      });

      this.ref.onClose.subscribe((val: any) => {
        console.log('closed ', val);
        if (val === true) {
          this.refresh();
        }
      });
    } else {
      this.showDialog();
    }
  }

  moveToTarget3() {
    console.log("🚀  MoveToTarget 3...");
    if (this.selectedItem) {
      this.ref = this.dialogService.open(DemandDetailComponent, {
        //header: 'Edit selected',
        width: '80%',
        height: '90%',
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        maximizable: false,
        data:
        {
          od: this.selectedItem,
          formID: this.formID,
          app: this.app,
          priority: 'P3',
          folderID: this.folderID
        }
      });

      this.ref.onClose.subscribe((val: any) => {
        console.log('closed ', val);
        if (val === true) {
          this.refresh();
        }
      });
    } else {
      this.showDialog();
    }
  }

  openRequestEdit(request: any) {
    console.log("🚀  edit...");
    console.log("🚀  item:", request);
    if (request) {
      this.ref = this.dialogService.open(DemandDetailComponent, {
        //header: 'Edit selected',
        width: '80%',
        height: '90%',
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        maximizable: false,
        data:
        {
          od: request.Groups,
          formID: this.formID,
          app: this.app,
          priority: request.Priority,
          folderID: this.folderID,
          requestID: request.RequestID,
          isEdit: true
        }
      });

      this.ref.onClose.subscribe((val: any) => {
        console.log('closed ', val);
        if (val === true) {
          this.getListDo();
          this.getMyDoListGroup();
        }
      });
    }
  }
  onDeleteRequest(request: any) {
    console.log("🚀  delete...");
    console.log("🚀  item:", request);
    if (request) {

      let deleteRequest: any = {
        RequestID: request.RequestID
      }

      if (request.HaveVisit) {
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteRequest')
          , 'AUTRE'
          , 'confirm'
          , () => { setTimeout(() => { this.deleteRequest(deleteRequest); }, 500); }
          , () => { });
      }
      else {
        this.deleteRequest(deleteRequest);
      }
    }
  }
  private getForm18Data(): void {
    if (this.isFederatedMode == false) {
      let f18form: any = JSON.parse(sessionStorage.getItem('vm-f18-form') ?? '{}');

      if (f18form) {
        this.formID = f18form.FormID;
      }
    }
    else {
      this.app.route.queryParams
        .subscribe(params => {
          console.log('[params]', params);
          if (params && params['FormID']) {
            this.formID = params['FormID'];
          }
        });
    }
  }


  async deleteRequest(request: any) {
    console.log("DELETE request ===>", request);
    try {
      const response = await this.app.apiService.deleteRequest(request);
      console.log('Response API : DELETE request ===>', response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
        return;
      }

      this.app.confirm.confirmDialog(""
        , this.app.translate.instant('racine.successMsg.successRequestDelete')
        , 'OK'
        , "alert"
        , () => {
          this.refresh();
        }
        , () => {
          this.refresh();
        });


    } catch (error) {
      console.error(error);
    }
  }


  private async getExposant(exposantId: any) {

    try {
      const response = await this.app.apiService.getVmFolder(exposantId);
      console.log("getUser ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
        }, () => {
        })
        return;
      }
      this.exposant = cloneDeep(response);

      this.exposant.stillHaveToMake = this.exposant.NbCanMakeRequest - this.exposant.TotalRequestCount;

      this.folderID = this.exposant.FolderID;

    }
    catch (error) {
      console.error(error);
    }
  }

  private orderCompositionContacts(od: any): void {
    let odContactsList: any[] = [];

    if (od.Guest) {
      odContactsList = [od.Guest];
    }

    if (od.Chef) {
      odContactsList = [...odContactsList, od.Chef];
    }

    if (od.Members) {
      odContactsList = [...odContactsList, ...od.Members];
    }

    od.compositionContacts = odContactsList;

    console.log('ctc ', od);
  }

  async getCounter() {
    const resp = await this.app.apiService.getCounter(this.formID);

    this.counter = cloneDeep(resp);
    console.log("🚀  this.counter:", this.counter);
    this.printPlanningDo = !resp.PrintPlanningDo;
    this.printPlanningExposant = !resp.PrintPlanningExposant;
    this.areDemandeDOButtonsDisabled = (new Date(this.counter.FrmClosingDate) < new Date())
  }

  refresh(): void {
    this.selectedItem = undefined
    this.getListDo();
    this.getMyDoListGroup();
    this.getCounter();
  }

  goToFederateurWindows() {
    console.log("🚀  Open federateur Windows list modal...", this.folderID);

    this.ref = this.dialogService.open(FederateurListWindowComponent, {
      //header: 'Edit selected',
      width: '80%',
      height: '90%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      data:
      {
        folderID: this.folderID
      }
    });

    console.log("REF |---> ", this.ref);

    this.ref.onClose.subscribe((val: any) => {
      console.log('closed ', val);
      if (val === true) {
        this.refresh();
      }
    });
  }

  getColorStatus(status: any) {
    let classColor = "";
    if (status) {
      switch (status.Code) {
        case StatusCode.SCHEDULED_OD:
          classColor = "light-blue"
          break;

        case StatusCode.DATE_CONFIRMED_OD:
          classColor = "lime-green"
          break;

        case StatusCode.CANCELLED_OD:
          classColor = "red"
          break;

        case StatusCode.ARCHIVED_OD:
          classColor = "light-grey"
          break;

        default:
          classColor = "dark-blue"
          break;
      }
    }
    return classColor;
  }




  private showPopUpRequest() {

    this.app.confirm.confirmDialog("", this.app.translate.instant('fo.vmf18Etat1Sur2.popUpReqMsg')
      , 'KO'
      , "alert"
      , () => { }
      , () => { });
  }

  onSendRequest() {
    this.showPopUpRequest();
  }

  goToBackToFederatorView() {
    if (this.isFederatedMode) {
      this.app.sharedTools.goTo('/vm-f18-etat2sur2');
    }
  }


  retour() {
    if (this.isFederatedMode == false) {
      this.app.sharedTools.goTo('/vm-f18-etat1sur2');
    }
    else if (this.isFederatedMode == true) {
      let queryparams: any = {
        FormID: this.formID
      };
      this.app.sharedTools.redirectToWithParameters('/vm-f18-etat1sur2/exhibitor', queryparams)
    }
  }

  private getCountries() {
    this.app.apiService.getCountriesOD().subscribe((data: any) => {
      console.log("🚀==> getCountries ODDDDD==> ", data);
      this.listDoCountry = data;
    });
  }
  mapColumn() {
    this.listDOToExport = this.mapDOListToPlaneList();
    console.log('this.listDOToExport==>', this.listDOToExport);
    return this.listDOToExport.map((row: any) => {



      const filteredColumns: any = {};
      this.exportcsvColumns.forEach((column: any) => {
        //   if(this.translalteService.currentLang === "fr"){
        switch (column.field) {

          case "GroupPeriority": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.getGroupPeriority(row.Group.toString())
            break;
          case "Etat": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.Status[this.app.sharedTools.getLangPropertyName('Label')];
            break;
          case "delegation": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups[this.app.sharedTools.getLangPropertyName('Name')];
            break;
          case "Country": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.Country[this.app.sharedTools.getLangPropertyName('Label')]
            break;
          case "TYPE_DO": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.Categories[0][this.app.sharedTools.getLangPropertyName('Label')] ?? ''
            break;
          case "Sector": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.SectorCategory[this.app.sharedTools.getLangPropertyName('Label')] ?? ''
            break;
          case "ChefFullName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.Chef?.FullName ?? '';
            break;
          case "fonction": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.DetailGroup?.ShortDOName ?? ''
            break;
          case "GuestInterestCenter": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.Contacts?.map((ctc: any) => `${ctc.InterestCenter ? ctc.InterestCenter + ',' : ''}`).join(' ');
            break;
          case "NbPersonnes": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Groups.DetailGroup?.Numberpersons ?? '';
            break;
          default:
            break;
        }
      });
      return filteredColumns;
    })
  }
  exportCSV() {
    this.helperService.exportCSV(this.mapDOListToPlaneList(), this.mapColumn(), 'liste_demandes_export_' + new Date().toLocaleDateString(), true);
  }

  mapDOListToPlaneList(): any[] {
    const filteredColumns: any = [];
    let i = 0;
    this.myDoListGroup.forEach
      (
        (pair: any) => {
          let key = pair.Key;
          pair.Value.forEach
            (
              (row: any) => {
                let fullrow = row;
                fullrow.Group = key;
                filteredColumns[i] = fullrow;
                i++;
              }
            );
        }
      );
    console.log('filteredColumns====> ', filteredColumns)
    return filteredColumns;

  }

  getGroupPeriority(periority: string): string {
    let response = ''
    this.groupPeriority.forEach
      (
        (p: any) => {
          if (periority.toString() == p.field.toString()) {
            response = p[this.app.sharedTools.getLangPropertyName('Label')];
          }
        }
      );
    return response;
  }

  async initFedere() {
    try {
      let objForm: any = {
        FormID: this.formID
      }

      const response = await this.app.apiService.InitFedere(objForm);
      console.log("🚀  InitFedere :", response);

    } catch (error) {
      console.error(error);
    }
  }

  printExposantPlanning() {
    let folderIdList = []
    folderIdList.push(this.folderID)
    this.reportingService.getPlanningExp(folderIdList, false, [], this.app.translate.currentLang).subscribe(
      {
        next: (res: any) => {
          if (res && res.Result) {
            setTimeout(() => {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + res.Result)
                , 'KO'
                , "alert"
                , () => { }
                , () => { });
            }, 200);
            return;
          }
          if (res.IsSuccessful === false || res.ByPass == true) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + res.Error)
              , 'KO'
              , "alert"
              , () => { }
              , () => { });

            return;
          }
          if (res && res.Url) {
            window.open(res.Url, '_blank');
          }
        }
      }
    )
  }

  printExposantPlanningXlsx(){
    this.reportingService.getPlanningExpXlsx(this.folderID, false, [], this.app.translate.currentLang).subscribe(
      {
        next: (res: any) => {
          console.log(res)
          if (res && res.Result) {
            setTimeout(() => {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + res.Result)
                , 'KO'
                , "alert"
                , () => { }
                , () => { });
            }, 200);
            return;
          }
          if (res.IsSuccessful === false) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + res.Error)
              , 'KO'
              , "alert"
              , () => { }
              , () => { });

            return;
          }
          if (res && res.Url) {
            window.open(res.Url, '_blank');
          }
        }
      }
    )
  }

  async PrintDOPlanning() {
    let requestObj: any = {
      ComponentID: 1,
      FormID: this.formID,
    }
    const resp = await this.app.apiService.getContactSelected(requestObj);
    let emailList = []
    emailList = resp.map((x: any) => {
      return x?.Email
    })
    this.ref = this.dialogService.open(PlanningHTMLOptionsComponent, {
      header: "Options du rapport",
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 100,
      maximizable: false,
      styleClass: 'generateplanning',
      data: { includeNoteSection: true, isEmailRequired: true, emailList: emailList, showEmailInput:true }
    });

    this.ref.onClose.subscribe((val: { emails: string[], includeNote: boolean }) => {
      if (val) {

        this.reportingService.getPlanningDoAsync(this.folderID, val.emails, val.includeNote, this.app.translate.currentLang).subscribe(
          {
            next: (data: any) => {
              if(!data || data?.length == 0){
                this.app.confirm.confirmDialog("", this.app.translate.instant('bo.vm-planning-do.sendPlanningEmailPlannedSuccess')
                , 'KO'
                , "alert"
                , () => { }
                , () => {});
              }
            }
          }
        )
      }
    })

  }

  async getVMFrmInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_VM
    let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
    if (data != undefined && data.Id != undefined) {
        this.frmData = data
    }
  }
}

export interface ODListModel {
  codeISO22: string;
  type?: string;
  centerInterest?: string;
  name?: string;
}