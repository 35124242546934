import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subject, Subscription, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { FOConferencesSearchLocalStorageKey, FOSpeakersSearchLocalStorageKey } from 'src/app/consts/utils';
import { ConferenceProgramCode } from 'src/app/enums/conference-program-code';
import { ApiService } from 'src/app/services/api.service';
import { SearchHelperService } from 'src/app/services/search-helper.service';

@Component({
  selector: 'app-conf-filter',
  templateUrl: './conf-filter.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./conf-filter.component.less']
})
export class ConfFilterComponent implements OnDestroy {
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchButtonClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() hasAIButton: boolean = true;
  @Input() hasInterests: boolean = true;
  @Input() hasSpeakers: boolean = true;
  hasExhibitors: boolean = false;
  @Input() hasTracks: boolean = true;
  @Input() hasRooms: boolean = true;
  @Input() hasRoomsAsCheck: boolean = true;
  @Input() hasLocations: boolean = false;
  @Input() hasTypeCategories: boolean = true;



  search: any = {};
  interests: any[] = [];
  speakers: any[] = [];
  exhibitors: any[] = [];
  tracks: any[] = [];
  rooms: any[] = [];
  countries: any[] = [];
  typeCategories: any[] = [];

  tracksTags: number = 0;
  interestTags: number = 0;
  speakrTags: number = 0;
  roomTags: number = 0;
  locationTags: number = 0;
  exhibitorsTags: number = 0;
  roomsTags: number = 0;
  typeCategoriesCount: number = 0;

  selectedRoom: any = null;
  filteredOptions: any[] = [];
  isChecked: boolean = true;
  hasValueInName: string = '';
  mappedInterestsList: any[] = [];
  selectedOptionsRooms: string[] = []; // Pour stocker les valeurs sélectionnées
  selectedOption: number | null = null;

  currentProgramCode: string = ConferenceProgramCode.PROG1;

  searchByNameOrCompanyChange = new Subject<string>();

  confPlaceCategoryHall: any = {};
  langChangeSubs: Subscription = new Subscription();
  constructor(
    public app: AppComponent,
    private apiService: ApiService,
    private searchHelperService: SearchHelperService
  ) {
    this.selectedOption = null;
    // this.searchByNameOrCompanyChange.pipe(
    //   debounceTime(400),
    //   distinctUntilChanged())
    //   .subscribe(value => {
    //     this.search.ProgramCode = this.currentProgramCode;
    //     this.onSearch.emit(this.search);
    //   });
  }
  ngOnDestroy(): void {
    this.destroySubsciptions();
  }

  async ngOnInit() {    
    this.app.route.queryParams.subscribe(params => {
      if (params && params['program'] && params['program'] != '') {
        this.currentProgramCode = params['program'];
        console.log(this.currentProgramCode);
      }

      // explore search criteria
      // if (params && params['room'] && this.app.router.url.startsWith('/welcome-conf')){
      //   this.selectRoom(params['room']);
      // }
    });
    
    let resG = await firstValueFrom(this.app.sharedTools.getJSON('general_data'));
    if(resG && resG.confPlaceCategoryHall){
      this.confPlaceCategoryHall = resG.confPlaceCategoryHall;
    }

    if(this.app.router.url.startsWith('/welcome-conf')){
      this.checkSelectedRoom();
      await this.getRooms();
  
      this.getInterests();
      this.getSpeakers();
      this.getExhibitors();
      this.mapIntersetList();
      this.getTypeCategories();
    }
    this.getTracks();
    
    //await this.getSpeakersCountires();

    this.restaureSearchInfo();
    this.onSearchClick();
    this.initializeSubscriptions();
  }

  mapIntersetList() {
    this.mappedInterestsList = this.interests.map((item) => {
      let obj =
      {
        label: item,
        value: item
      }
      console.log("🚀  obj:", obj)
      return obj;
    });
    console.log(this.mappedInterestsList);
  }

  async clearFilter() {
    console.log("Clear filter was clicked ...");
    //clear all filter criteria
    this.search.SerachByNameOrCompany = "";
    this.clearTracksList();
    this.clearInterestsList();
    this.clearSpeakersList();
    this.clearExhibitorsList();
    this.clearLocationsList();
    this.clearTypeCategories();
    this.clearRoomsList();

    //then search
    this.onSearchClick();

  }

  async getInterests() {
    try {
      if (this.interests.length == 0) {
        const response = await this.apiService.getInterests(this.currentProgramCode);
        this.interests = cloneDeep(response.ConferenceInterestList);
      }
      console.log("interests", this.interests)
      this.mapIntersetList();

    } catch (error) {
      console.error(error);
    }
  }

  async getSpeakers() {
    try {
      if (this.speakers.length == 0) {
        const response = await this.apiService.getSpeakers(this.currentProgramCode);
        this.speakers = cloneDeep(response);
      }
      console.log("speakers", this.speakers)
    } catch (error) {
      console.error(error);
    }
  }

  async getExhibitors() {
    try {
      if (this.exhibitors.length == 0) {
        const response = await this.apiService.getExhibitors(this.currentProgramCode);
        this.exhibitors = cloneDeep(response);;
      }
      console.log("exhibitors", this.exhibitors)
    } catch (error) {
      console.error(error);
    }
  }

  async getRooms() {
    try {
      if (this.rooms.length == 0) { 
        const response = await this.apiService.getRooms(this.currentProgramCode);
        this.rooms = cloneDeep(response);

        this.mapRoomHall();
      }
      console.log("rooms", this.rooms)
    } catch (error) {
      console.error(error);
    }
  }

  onSearchClick(): void {
    this.search.ProgramCode = this.currentProgramCode;
    console.log(this.search)
    this.onSearch.emit(this.search);
  }

  public onSearchButtonClicked(): void{
    this.onSearchClick();    
    this.onSearchButtonClick.emit();
  }

  getRoomTags(tag: any) {
    this.roomTags = tag.value.length;
  }

  async getTracks() {
    try {
      if (this.tracks.length == 0) {
        const response = await this.apiService.getTracks(this.currentProgramCode);
        this.tracks = cloneDeep(response);
        this.tracks = this.app.sharedTools.orderByPropertySwitchLang(this.tracks);
      }
      console.log("tracks", this.tracks)
    } catch (error) {
      console.error(error);
    }
  }

  async getSpeakersCountires() {
    try {
      const response = await this.apiService.getSpeakersCountires();
      this.countries = response;
      console.log("CountriesList", this.countries)
    } catch (error) {
      console.error(error);
    }
  }

  async onRadioSelect(option: number) {
    console.log(option);
    console.log(this.selectedOption);

    console.log(this.selectedOption)
    if (this.selectedOption === option) {
      this.selectedOption = null;

    }
    else {
      this.selectedOption = option;
      this.selectedOptionsRooms.push(this.search.RoomsList);
      console.log(this.selectedOptionsRooms)
      this.search.RoomsList = this.selectedOptionsRooms;

      //this.onSearchClick();
      this.selectedOptionsRooms = [];
    };
  }

  async clearTracksList() {
    this.search.TracksList = [];
    this.tracksTags = 0;
    //this.onSearchClick();
  }

  async clearInterestsList() {
    this.search.IntersetList = [];
    this.interestTags = 0;
    //this.onSearchClick();
  }

  async clearSpeakersList() {
    this.search.SpeakersList = [];
    this.speakrTags = 0;
    //this.onSearchClick();
  }

  async clearExhibitorsList() {
    this.search.ExhibitorsList = [];
    this.exhibitorsTags = 0;
    //this.onSearchClick();
  }

  async clearLocationsList() {
    this.search.LocationsList = [];
    this.locationTags = 0;
    //this.onSearchClick();
  }

  async clearTypeCategories() {
    this.search.TypeCategoriesIDs = [];
    this.typeCategoriesCount = 0;
    //this.onSearchClick();
  }


  async getTypeCategories() {
    try {
      if(this.typeCategories && Array.isArray(this.typeCategories) && this.typeCategories.length > 0){
        return;
      }

      const response = await this.apiService.getTypeCategories(this.currentProgramCode);
      if(response && Array.isArray(response) && response.length > 0){
        this.typeCategories = cloneDeep(response);
        this.typeCategories = this.app.sharedTools.orderByPropertySwitchLang(this.typeCategories);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async clearRoomsList() {
    this.search.RoomsList = [];
    this.search.Rooms = [];
    this.roomsTags = 0;
    //this.onSearchClick();
  }

  onSearchRoomChange(rooms: any[]){
    if(rooms && Array.isArray(rooms) && rooms.length>0){
      this.search.RoomsList = rooms.map((room: any) => room.CategoryID);
    }
    else{
      this.search.RoomsList = [];
    }
    this.roomsTags = this.search?.RoomsList?.length ?? 0;
  }

  onTracksChange(): void{
    this.tracksTags = this.search?.TracksList?.length;
  }
  
  onInterestChange(): void{
    this.interestTags = this.search?.IntersetList?.length;
  }

  onSpeakerChange(): void{
    this.speakrTags = this.search?.SpeakersList?.length;
  }

  onLocationChange(): void{
    this.locationTags = this.search?.LocationsList?.length;
  }

  onExhibitorChange(): void{
    this.exhibitorsTags = this.search?.ExhibitorsList?.length;
  }

  onTypeChange(): void{
    this.typeCategoriesCount = this.search?.TypeCategoriesIDs?.length;
  }

  private mapRoomHall(): void {
    if(this.rooms 
      && Array.isArray(this.rooms)
      && this.rooms.length > 0
      && this.confPlaceCategoryHall){
        this.rooms.forEach((room: any) =>{
          let hallData: any = this.confPlaceCategoryHall.map.find((c: any) =>{
            return c.key == room[this.confPlaceCategoryHall.keyPropertyName]
          });

          if(!hallData){
            return;
          }

          room.FormattedLabelFR = this.confPlaceCategoryHall
          .labelFormatToken
          .replace('{label}', (room.RoomLabelFR ?? '') )
          .replace('{hall}', (hallData.hall ?? '') );
          
          room.FormattedLabelEN = this.confPlaceCategoryHall
          .labelFormatToken
          .replace('{label}', (room.RoomLabelEN ?? '') )
          .replace('{hall}', (hallData.hall ?? '') );
          
          room.FormattedLabelES = this.confPlaceCategoryHall
          .labelFormatToken
          .replace('{label}', (room.RoomLabelES ?? '') )
          .replace('{hall}', (hallData.hall ?? '') );

        });
    
        this.rooms = this.app.sharedTools.orderByPropertySwitchLang(this.rooms, 'FormattedLabel');
      }
  }

  private handleLangChange(): void{
    this.rooms = this.app.sharedTools.orderByPropertySwitchLang(this.rooms, 'FormattedLabel');
    this.typeCategories = this.app.sharedTools.orderByPropertySwitchLang(this.typeCategories);
    this.tracks = this.app.sharedTools.orderByPropertySwitchLang(this.tracks);
  }

  private initializeSubscriptions(): void{
    this.langChangeSubs = this.app.translate.onLangChange.subscribe((v: any) =>{
      this.handleLangChange();
    });
  }

  private destroySubsciptions(): void{
    this.langChangeSubs?.unsubscribe();
  }

  private async checkSelectedRoom(){
    let findSelectedRoom = sessionStorage.getItem('map-selected-room');
    if(!findSelectedRoom){
      return;
    }
    await this.getRooms();

    let selectedRoom = this.rooms.find((r: any) => r.MapPoint === findSelectedRoom);
    if(selectedRoom){
      this.search.Rooms = [selectedRoom];
      this.onSearchRoomChange(this.search.Rooms);
      this.onSearchButtonClicked();
    }

    sessionStorage.removeItem('map-selected-room');
  }
  
  private restaureSearchInfo(): void{
    let searchStorageKey: string = '';
    if (this.app.router.url.startsWith('/welcome-conf-speaker')){
      searchStorageKey = FOSpeakersSearchLocalStorageKey;
    }
    else if(this.app.router.url.startsWith('/welcome-conf')){
      searchStorageKey = FOConferencesSearchLocalStorageKey;
    }

    console.log(searchStorageKey)
    let latestsearch: any = this.searchHelperService.getAdvancedSearch(searchStorageKey);
    console.log('search ', latestsearch)

    if(latestsearch){
      this.search.RoomsList = latestsearch.RoomsList;
      this.search.TracksList = latestsearch.TracksList;
      this.search.IntersetList = latestsearch.IntersetList;
      this.search.SpeakersList = latestsearch.SpeakersList;
      this.search.ExhibitorsList = latestsearch.ExhibitorsList;
      this.search.LocationsList = latestsearch.LocationsList;
      this.search.TypeCategoriesIDs = latestsearch.TypeCategoriesIDs;
      this.search.SerachByNameOrCompany = latestsearch.SerachByNameOrCompany;

      if(this.search.RoomsList && Array.isArray(this.search.RoomsList)
      && this.rooms && Array.isArray(this.rooms)){
        this.search.Rooms = this.rooms.filter((r: any) => this.search.RoomsList.find((id: any) => id == r.CategoryID));
      }

      this.onTypeChange();
      this.onTracksChange();
      this.onInterestChange();
      this.onSpeakerChange();
      this.onExhibitorChange();
      this.onLocationChange();

      this.roomsTags = this.search.RoomsList?.length ?? 0;
    }
  }
}
