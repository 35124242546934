import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { Languages } from 'src/app/enums/language';

@Component({
  selector: 'app-conf-tabs',
  templateUrl: './conf-tabs.component.html',
  styleUrls: ['./conf-tabs.component.less']
})
export class ConfTabsComponent {

  @Input() tabsArray: any[] = [];
  @Output() onTabChange: EventEmitter<any[]> = new EventEmitter();
  @Input() dateOfSearch: any;

  private _activatedTabIndex: number = -1;
  get activatedTabIndex(): number{
    //if(this._activatedTabIndex == -1){
      if(this.dateOfSearch && this.tabsArray && Array.isArray(this.tabsArray)){
        let findDateOfSearch = this.tabsArray.find((t: any) => this.datePipe.transform(t.Date, 'dd/MM/yyyy')  == this.datePipe.transform(this.dateOfSearch, 'dd/MM/yyyy') );
        //console.log('*** ', findDateOfSearch, this.dateOfSearch, this.tabsArray)
        if(findDateOfSearch){
          this._activatedTabIndex = this.tabsArray.indexOf(findDateOfSearch);
        }
      //}
    }
    else{
      this._activatedTabIndex = -1;
    }

    return this._activatedTabIndex;
  }

  set activatedTabIndex(value: number){
    this._activatedTabIndex = value;
  }

  constructor(public app: AppComponent, private datePipe: DatePipe) { }

  ngOnInit() {
    
  }

  getDetailByDate(index: number, dateOfSearch: any) {
    this.activatedTabIndex = index;
    this.dateOfSearch = dateOfSearch;

    let tabInfo: any = {
      index: this.activatedTabIndex
      , date: this.dateOfSearch
    }

    this.onTabChange.emit(tabInfo);
  }

  formatDayOfWeekResponsive(date: any) {
    return this.datePipe.transform(date, 'EEEE', undefined, this.app.translate.currentLang)?.substring(0, 3);
  }
  
  formatMonthNameResponsive(date: any) {
    return this.datePipe.transform(date, 'MMMM', undefined, this.app.translate.currentLang)?.substring(0, 3);
  }

  formatDayOfWeek(date: any) {
    return this.datePipe.transform(date, 'EEEE', undefined, this.app.translate.currentLang)?.toUpperCase();
  }

  formatDayOfMonth(date: any) {
    return this.datePipe.transform(date, 'd', undefined, this.app.translate.currentLang);
  }

  formatMonth(date: any) {
    return this.datePipe.transform(date, 'MMMM', undefined, this.app.translate.currentLang)?.toUpperCase();
  }

  public isDisabled(confDayInfo: any): boolean{
    return confDayInfo.NbConferences <= 0;
  }

  formatDateLg(conf: any): string{
    return this.app.translate.currentLang == Languages.French
    ? `${this.formatDayOfWeek(conf.Date)} ${this.formatDayOfMonth(conf.Date)} ${this.formatMonth(conf.Date)}`
    : `${this.formatMonth(conf.Date)} ${this.formatDayOfWeek(conf.Date)} ${this.formatDayOfMonth(conf.Date)}` ;
  }
}

