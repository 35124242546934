import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { TreeNode } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { CategoryCode } from 'src/app/enums/category-code';
import { CategoryType } from 'src/app/enums/category-type';
import { FormCode } from 'src/app/enums/form-code';
import { MediasDegRotation } from 'src/app/enums/media-rotation-deg';
import { MediasTypes } from 'src/app/enums/media-type';
import { MediaCmpComponent } from 'src/app/medias-components/media-cmp/media-cmp.component';
import { AwardsUploadMediaInfo, UploadMediaInfo } from 'src/app/models/media-upload-info';
import { MediaValueChange } from 'src/app/models/media-value-change';
import { SpinnerService } from 'src/app/services/spinner.service';
import { AwardsRoutes, AwardsRoutesQueryParamsNames, AwardsRoutesStep } from '../awards.routes';
import { Languages } from 'src/app/enums/language';

@Component({
  selector: 'app-awards-form-description-info',
  templateUrl: './awards-form-description-info.component.html',
  styleUrls: ['./awards-form-description-info.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AwardsFormDescriptionInfoComponent {

  @ViewChild("photoInput") photoInput?: MediaCmpComponent
  @ViewChild("fileInput") fileInput?: MediaCmpComponent

  frmData: any;
  mediasTypes = MediasTypes
  nomenclatures!: TreeNode[];
  tree: any = {};
  selectedNodes: any = [];
  categories: any = [];
  awardForm !: FormGroup
  selectedCategories: any
  initializedForm: boolean = false
  submitted: boolean = false
  awardId?: number
  awardData: any
  isFileSelected: boolean = false
  isLogoSelected: boolean = false
  rotationStyle: MediasDegRotation = MediasDegRotation._0deg;
  dialogVisible: boolean = false
  formCode: FormCode = FormCode.FRM_AWARD;
  imageDocument: any
  pdfDocument: any
  returnUrl: string | undefined
  showOtherCategory: boolean = false


  constructor(public app: AppComponent, private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute, private router: Router,
    private spinnerService: SpinnerService, private translate: TranslatePipe
  ) { }

  async ngOnInit() {
    await this.getAwardFormInfo()
    await this.getCategories()
    this.activatedRoute.queryParams.subscribe(async (data: any) => {
      console.log(data)
      if (data && data[AwardsRoutesQueryParamsNames.awardId]) {
        this.awardId = +data[AwardsRoutesQueryParamsNames.awardId]
        console.log(this.awardId)
        await this.getAwardSolutionInfo()
        this.initForm()
      }
    })
    this.returnUrl = `/${AwardsRoutes.generalities}?${AwardsRoutesQueryParamsNames.awardId}=${this.awardId}`
  }

  async getAwardSolutionInfo() {
    this.awardData = await this.app.apiService.getAwardSolutionById(this.awardId)
    console.log(this.awardData)
    let documentImages = this.awardData?.Documents?.filter((doc: any) => {
      return doc?.typeDocument?.TypeDOCCode == MediasTypes.IMAGE
    })
    if (documentImages && Array.isArray(documentImages)) {
      this.imageDocument = documentImages[0]
    }

    let documentFiles = this.awardData?.Documents?.filter((doc: any) => {
      return doc?.typeDocument?.TypeDOCCode == MediasTypes.FILE
    })
    if (documentFiles && Array.isArray(documentFiles)) {
      this.pdfDocument = documentFiles[0]
    }
    console.log(this.imageDocument)
  }

  initForm() {
    console.log(this.awardData)
    this.awardForm = this.formBuilder.group({
      DescriptionFR: new FormControl(this.awardData?.DescriptionFR ?? "", [Validators.required, Validators.maxLength(600)]),
      PitchFR: new FormControl(this.awardData?.PitchFR ?? "", [Validators.required, Validators.maxLength(600)]),
      HasWonAward: new FormControl(this.awardData?.HasWonAward ?? false),
      AwardPrize: new FormControl(this.awardData?.AwardPrize ?? ""),
      AwardEvent: new FormControl(this.awardData?.AwardEvent ?? ""),
      categories: this.formBuilder.array([]),
      OtherCategory: new FormControl(this.awardData?.OtherCategory ?? ""),
      ShowOtherCategory: new FormControl(false),
    })
    this.initFormValueSubscritpion()
    this.selectedCategoryNodes()
    this.checkIfOtherCategorieVisible()
    this.initializedForm = true
  }

  checkIfOtherCategorieVisible() {
    if (this.awardData.OtherCategory != undefined
      && this.awardData.OtherCategory != null
      && this.awardData.OtherCategory != "") {
      let showOtherCategoryControl = this.awardForm.get("ShowOtherCategory")
      showOtherCategoryControl?.setValue(true)
      this.awardForm.updateValueAndValidity()
    }
  }

  initFormValueSubscritpion() {
    let showOtherCategoryControl = this.awardForm.get("ShowOtherCategory")
    let otherCategoryForm = this.awardForm.get("OtherCategory")
    showOtherCategoryControl?.valueChanges.subscribe((newValue: boolean) => {
      if (newValue == true) {
        otherCategoryForm?.addValidators([Validators.required])
      } else {
        otherCategoryForm?.clearValidators()
      }
      otherCategoryForm?.updateValueAndValidity()
    })

    let hasWonAwardControl = this.awardForm.get("HasWonAward")
    let awardPrizeControl = this.awardForm.get("AwardPrize")
    let awardEventControl = this.awardForm.get("AwardEvent")
    hasWonAwardControl?.valueChanges.subscribe((newValue: boolean) => {
      if (newValue == true) {
        awardEventControl?.addValidators([Validators.required])
        awardPrizeControl?.addValidators([Validators.required])
      }
      else {
        awardEventControl?.clearValidators()
        awardPrizeControl?.clearValidators()
      }
      awardPrizeControl?.updateValueAndValidity()
      awardEventControl?.updateValueAndValidity()
    })
  }

  generateChatGptDescription() {
    let requestObj = {
      DescriptionFR: this.awardForm.get('DescriptionFR')?.value,
      productUrl: this.awardData?.SolUrl
    }
    console.log("translate", requestObj)

    let requestMsg: string = `${this.translate.transform('awardFormDescriptionGenerator.generate-description-command')} ${JSON.stringify(requestObj)}`;

    let request: any = {
      Message: requestMsg,
      JsonObject: {}
    }

    this.chatgptGeneratePresentation(request);

    console.log("translate", request)
  }

  onCancelClick() {
    this.dialogVisible = false;
  }

  onCancelStep() {
    this.router.navigate([AwardsRoutes.generalities], {
      queryParams: {
        [AwardsRoutesQueryParamsNames.awardId]: this.awardId
      }
    })
  }

  private async chatgptGeneratePresentation(request: any) {
    try {
      this.spinnerService.disableSpinner();
      this.dialogVisible = true;
      const timerPromise = new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 15000);
      });

      const apiRequestPromise = this.app.apiService.chatgptGeneratePresentation(request);

      const response = await Promise.race([apiRequestPromise, timerPromise]);

      if (response === timerPromise) {
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
        }, () => {
        });
        this.dialogVisible = false;
        return;
      }

      console.log('chatgptGeneratePresentation response ===>', response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', 'alert', () => {
        }, () => {
        });
        return;
      }

      console.log('Response Data:', response);

      if (response !== undefined) {
        try {
          const parsedResponse = JSON.parse(response);
          this.mapGenertedDescription(parsedResponse);
          this.dialogVisible = false;
          this.spinnerService.enableSpinner();
        } catch (error) {
          console.error('Error parsing response:', error);
          this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
          }, () => {
          });
          this.dialogVisible = false;
        }
      } else {
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
        }, () => {
        });
        this.dialogVisible = false;
      }

    } catch (error) {
      console.error('Error parsing response:', error);
      this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_AIPROBLEM'), 'KO', 'alert', () => {
      }, () => {
      });
      this.dialogVisible = false;
    }
  }

  private mapGenertedDescription(description: any): void {
    console.log("InnovationAspectENControl", description);
    if (description) {
      let descriptionFRControl: any = this.awardForm.get('DescriptionFR');

      if(this.app.translate.currentLang == Languages.English){
        descriptionFRControl.setValue(description?.DescriptionEN ?? "");
      }
      else {
        descriptionFRControl.setValue(description?.DescriptionFR ?? "");
      }
    }
  }

  onGenerateDescriptionClick() {
    this.app.confirm.confirmDialog(''
      , this.app.translate.instant('fo.awards.confirmChatgptGeneration')
      , 'AUTRE'
      , 'confirm'
      , () => {
        this.generateChatGptDescription()
      }
      , () => { });

  }

  private constructMedifaUploadInfo(): AwardsUploadMediaInfo {
    let logoUploadInfo: UploadMediaInfo = new UploadMediaInfo(this.photoInput?.degRotation ?? 0
      , true
      , 1);



    let fileUploadInfo: UploadMediaInfo = new UploadMediaInfo(this.fileInput?.degRotation ?? 0
      , true
      , 1);

    let mediaUploadInfo: AwardsUploadMediaInfo = new AwardsUploadMediaInfo(fileUploadInfo, logoUploadInfo,
    );

    console.log(mediaUploadInfo)

    return mediaUploadInfo;
  }

  mediaPhotoValueChanged(event: MediaValueChange) {
    this.isLogoSelected = event?.HaveValue ?? false
  }

  mediaFileValueChanged(event: MediaValueChange) {
    this.isFileSelected = event?.HaveValue ?? false
  }

  handleRotationStyleChange(event: MediasDegRotation) {
    this.rotationStyle = event;
  }

  checkIfMediaAreValid() {
    return this.photoInput?.isConformTodimension && this.fileInput?.isConformTodimension
  }

  async getAwardFormInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_AWARD
    let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
    if (data != undefined && data.Id != undefined) {
      this.frmData = data
    }
  }

  async submit() {
    this.submitted = true
    console.log(this.fileInput?.isConformTodimension)
    if (this.awardForm.invalid || !this.checkIfMediaAreValid()) {
      console.log("invalid form errors ===>", this.awardForm.errors);

      //bloc explore form errors
      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.awardForm.controls;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log('Invalid form values:', invalidValues);

      if (invalidField && invalidField.length > 1) {
        console.log("invalidField", invalidField)

        setTimeout(() => {
          let el = invalidField[1].offsetTop - 100;
          if (el) window.scroll({ top: el, behavior: "smooth" });
        }, 10);
      }

      this.app.confirm.confirmDialog(""
        , this.app.translate.instant('racine.errorsOther.errorForm')
        , 'KO'
        , 'alert'
        , () => { }
        , () => { });
    } else {
      let formValue = this.awardForm.getRawValue()
      let createOrUpdateStepRequest = {
        NameFR: this.awardData?.NameFR,
        DescriptionFR: formValue.DescriptionFR,
        PitchFR: formValue.PitchFR,
        AwardEvent: formValue.AwardEvent,
        AwardPrize: formValue.AwardPrize,
        HasWonAward: formValue.HasWonAward,
        ID: this.awardId,
        IsMultiWriter: this.awardData?.IsMultiWriter ?? false,
        CGVaccepted: this.awardData?.CGVaccepted ?? false,
        SolUrl: this.awardData?.SolUrl,
        Categories: this.selectedCategories.map((x: any) => x.CategoryID) ?? [],
        IsPhotoSelected: this.isLogoSelected,
        IsFileSelected: this.isFileSelected,
        OtherCategory: formValue.OtherCategory,
        SolutionLastStep: AwardsRoutesStep.description
      }
      let mediaUploadInfo = this.constructMedifaUploadInfo()

      const response = await this.app.apiService.updateAwardInnovation(createOrUpdateStepRequest
        , this.photoInput?.selectedFile
        , this.fileInput?.selectedFile
        , mediaUploadInfo);

      if (response == null) {
        this.router.navigate([AwardsRoutes.recap], {
          queryParams: {
            [AwardsRoutesQueryParamsNames.awardId]: this.awardId
          }
        })
      }
    }
  }

  async getCategories() {
    try {
      const response = await this.app.apiService.getCategories();

      console.log(response)
      let categoriesIni = _.cloneDeep(response);
      let categories = categoriesIni.filter((cat: any) => {
        return cat.CategoryType == CategoryType.AwardCategory
      });

      console.log(categories)
      this.categories = _.cloneDeep(categories);

      categories.sort((a: any, b: any) => {
        return a?.ViewOrder - b?.ViewOrder
      })

      categories.forEach((cat: any) => {
        const parentId = cat.ParentCategoryID;
        if (!this.tree[parentId]) {
          this.tree[parentId] = [];
        }
        this.tree[parentId].push(cat);
      });

      this.nomenclatures = this.buildTree(null);
      console.log('this.nomenclatures ==> ', this.nomenclatures);
    } catch (error) {
      console.error(error);
    }
  }

  buildTree(parentId: any) {
    const children = this.tree[parentId] || [];
    return children.map((child: any) => {
      const node = {
        key: child.CategoryID,
        label: this.app.sharedTools.getLabelSwitchLang(child),
        LabelEN: child.LabelEN,
        LabelFR: child.LabelFR,
        LabelES: child.LabelES,
        data: '',
        icon: '',
        children: this.buildTree(child.CategoryID)
      };
      return node;
    });
  }

  selectedCategoryNodes() {
    this.selectedCategories = []
    this.awardData?.Categories?.forEach((cat: any) => {
      let selectedCategory = this.categories.find((c: any) => c.CategoryID == cat)
      if (selectedCategory) {
        this.selectedCategories.push(selectedCategory)
      }
    });
  }

  refreshFormControlCategories(): void {
    // ==> We format the nodes
    let categories = this.app.sharedTools.getCategoriesFromSelectedNodes(this.categories, this.selectedCategories);
    //set activities to Activities form control
    this.setCategoriesToForm(categories);
  }

  private setCategoriesToForm(newValues: any): void {
    let categoriesControl = this.awardForm.get('categories') as FormArray;

    // Clear the existing controls in the FormArray
    categoriesControl.clear();

    // Add the new values to the FormArray as individual controls
    newValues.forEach((value: any) => {
      categoriesControl.push(this.formBuilder.control(value));
    });
    console.log("categoriesControl => ", this.selectedCategories)
  }
}
