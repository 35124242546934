import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, NavigationError, Route } from '@angular/router';

import { LoginComponent } from "./login/login.component";
import { RappelMdpComponent } from './rappel-mdp/rappel-mdp.component';
import { ResetMdpComponent } from './reset-mdp/reset-mdp.component';
import { GestionDpComponent } from "./gestion-dp/gestion-dp.component";
import { DetailDpComponent } from './detail-dp/detail-dp.component';
import { CreateDpComponent } from './create-dp/create-dp.component';
import { PrestationsComponent } from './prestations/prestations.component';
import { FoIntroComponent } from './fo-intro/fo-intro.component';
import { InfoExposantComponent } from "./info-exposant/info-exposant.component";
import { RecapitulatifComponent } from './recapitulatif/recapitulatif.component';
import { ExpoIndirectComponent } from "./expo-indirect/expo-indirect.component";
import { ContratComponent } from "./contrat/contrat.component";
import { SignatureComponent } from "./signature/signature.component";
import { ErrorPageComponent } from './error-page/error-page.component';
import { ThanksPageComponent } from './thanks-page/thanks-page.component';
import { ClosedFolderPageComponent } from './closed-folder-page/closed-folder-page.component';
import { GestionUserComponent } from './gestion-user/gestion-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { GestionRoleComponent } from './gestion-role/gestion-role.component';
import { CreateRoleComponent } from './create-role/create-role.component';
import { TraductionsComponent } from './traductions/traductions.component';
import { AdminExposantsComponent } from './admin-exposants/admin-exposants.component';
import { GestionGroupComponent } from './gestion-group/gestion-group.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { GestionVmExposantComponent } from './gestion-vm-exposant/gestion-vm-exposant.component';
import { CreateVmExposantComponent } from './create-vm-exposant/create-vm-exposant.component';
import { GestionVmUserComponent } from './gestion-vm-user/gestion-vm-user.component';
import { CreateVmUserComponent } from './create-vm-user/create-vm-user.component';
import { GestionOtherVisitComponent } from './gestion-other-visit/gestion-other-visit.component';
import { CreateOtherVisitComponent } from './create-other-visit/create-other-visit.component';
import { AdminExposantsDetailComponent } from './admin-exposants-detail/admin-exposants-detail.component';
import { CreateVmDetailDoComponent } from './create-vm-detail-do/create-vm-detail-do.component';
import { GestionVmDoComponent } from './gestion-vm-do/gestion-vm-do.component';
import { GestionVmManageDoComponent } from './gestion-vm-manage-do/gestion-vm-manage-do.component';
import { WelcomeF18Component } from './welcome-f18/welcome-f18.component';
import { WelcomeHelpedComponent } from './welcome-helped/welcome-helped.component';
import { PlanningDOComponent } from './planning-do/planning-do.component';
import { PlanningExposantsComponent } from './planning-exposants/planning-exposants.component';
import { GestionVmProgDemandesComponent } from './gestion-vm-prog-demandes/gestion-vm-prog-demandes.component';
import { FederateurListWindowComponent } from './federateur-list-window/federateur-list-window.component';
import { FoF18Etat2Sur2Component } from './fo-f18-etat2-sur2/fo-f18-etat2-sur2.component';
import { DemandDetailComponent } from './demand-detail/demand-detail.component';
import { WelcomeInnovComponent } from './welcome-innov/welcome-innov.component';
import { ListeProductsInnovComponent } from './liste-products-innov/liste-products-innov.component';
import { FormProduitGeneralitesComponent } from './form-produit-generalites/form-produit-generalites.component';
import { FormProduitDescriptionComponent } from './form-produit-description/form-produit-description.component';
import { FormProduitMediasComponent } from './form-produit-medias/form-produit-medias.component';
import { FormProduitContactComponent } from './form-produit-contact/form-produit-contact.component';
import { FormProduitRecapComponent } from './form-produit-recap/form-produit-recap.component';
import { GestionInnovComponent } from './gestion-innov/gestion-innov.component';
import { DetailInnovComponent } from './detail-innov/detail-innov.component';
import { FoF18Etat1Sur2Component } from './fo-f18-etat1-sur2/fo-f18-etat1-sur2.component';
import { GestionDesEditionsComponent } from './gestion-des-editions/gestion-des-editions.component';
import { CreateEditionComponent } from './create-edition/create-edition.component';
import { AdminDelegationComponent } from './admin-delegation/admin-delegation.component';
import { SSOLoginComponent } from './ssologin/ssologin.component';
import { innovSolutionGuard } from './guards/innov-solution.guard';
import { FicheOpenMode } from './enums/fiche-open-mode';
import { StatistiquesComponent } from './statistiques/statistiques.component';
import { RoleTypeId, foRoles } from './enums/role-type-id';
import { authGuard } from './guards/auth-guard.guard';
import { AdminBoardComponent } from './admin-board/admin-board.component';
import { NotAuthorizedPageComponent } from './not-authorized-page/not-authorized-page.component';
import { ParametrageVMComponent } from './parametrage-vm/parametrage-vm.component';
import { ListeExposantsComponent } from './liste-exposants/liste-exposants.component';
import { GestionHelpedComponent } from './gestion-helped/gestion-helped.component';
import { FormHelpedDescriptionComponent } from './form-helped-description/form-helped-description.component';
import { ListeProductsHelpedComponent } from './liste-products-helped/liste-products-helped.component';
import { FormHelpedRecapComponent } from './form-helped-recap/form-helped-recap.component';
import { FormHelpedMediasComponent } from './form-helped-medias/form-helped-medias.component';
import { WelcomeMktComponent } from './welcome-mkt/welcome-mkt.component';
import { ParametrageInnovationComponent } from './parametrage-innovation/parametrage-innovation.component';
import { FormHelpedGeneralitesComponent } from './form-helped-generalites/form-helped-generalites.component';
import { helpedSolutionGuard } from './guards/helped-solution.guard.guard';
import { MemberBoardComponent } from './member-board/member-board.component';
import { RequestDetailComponent } from './request-detail/request-detail.component';
import { FormHelpedContactComponent } from './form-helped-contact/form-helped-contact.component';
import { ParametrageHelpedComponent } from './parametrage-helped/parametrage-helped.component';
import { DetailHelpedComponent } from './detail-helped/detail-helped.component';
import { ListeAttendeesComponent } from './liste-attendees/liste-attendees.component';
import { ListeConferencesComponent } from './liste-conferences/liste-conferences.component';
import { ListeNewsComponent } from './liste-news/liste-news.component';
import { WelcomeConfComponent } from './welcome-conference/welcome-conf/welcome-conf.component';
import { ConfDetailsComponent } from './welcome-conference/conf-details/conf-details.component';
import { WelcomeConfSpeakerComponent } from './welcome-speaker/welcome-conf-speaker/welcome-conf-speaker.component';
import { SpeakerDetailsComponent } from './welcome-speaker/speaker-details/speaker-details.component';

import { ConferenceRoutes } from './consts/conference.routes';
import { CreateVmProgramComponent } from './create-vm-program/create-vm-program.component';
import { WelcomeProgramComponent } from './welcome-program/welcome-program.component';
import { ListeProductsProgramComponent } from './liste-products-program/liste-products-program.component';
import { FormProgramRecapComponent } from './form-program-recap/form-program-recap.component';
import { FormProgramGeneralitiesComponent } from './form-program-generalities/form-program-generalities.component';
import { SommaireEspaceCommunicationComponent } from './sommaire-espace-communication/sommaire-espace-communication.component';
import { ConfMapComponent } from './welcome-conference/conf-map/conf-map.component';
import { WelcomeSpeakerMktComponent } from './welcome-speaker-mkt/welcome-speaker-mkt.component';
import { WelcomeExhibitorComponent } from './welcome-exhibitors/welcome-exhibitor/welcome-exhibitor.component';
import { ExhibitorDetailsComponent } from './welcome-exhibitors/exhibitor-details/exhibitor-details.component';
import { DetailActualiteComponent } from './detail-actualite/detail-actualite.component';
import { WelcomeSolComponent } from './welcome-solution/welcome-sol/welcome-sol.component';
import { SolutionDetailsComponent } from './welcome-solution/solution-details/solution-details.component';
import { CommuniquePressComponent } from './communique-press/communique-press.component';
import { DetailCommuniquePressComponent } from './detail-communique-press/detail-communique-press.component';
import { SharedtoolsService } from './services/sharedtools.service';
import { AwardsFormDescriptionInfoComponent } from './awards-module/awards-form-description-info/awards-form-description-info.component';
import { AwardsRecapPageComponent } from './awards-module/awards-recap-page/awards-recap-page.component';
import { FormAwardsCompanyInfoComponent } from './awards-module/form-awards-company-info/form-awards-company-info.component';
import { AwardsFormGeneralitesComponent } from './awards-module/awards-form-generalites/awards-form-generalites.component';
import { AwardsWelcomePageComponent } from './awards-module/awards-welcome-page/awards-welcome-page.component';
import { ListeProductsAwardComponent } from './awards-module/liste-products-award/liste-products-award.component';
import { AwardsRoutes } from './awards-module/awards.routes';
import { AwardGuard } from './guards/award.guard';
import { AwardsBoListComponent } from './awards-module/awards-bo-list/awards-bo-list.component';
import { AwardsBoDetailFicheComponent } from './awards-module/awards-bo-detail-fiche/awards-bo-detail-fiche.component';
import { FOExternalPagesRoutes } from './consts/fo-external-pages.routes';
import { ParametrageAwardsComponent } from './awards-module/parametrage-awards/parametrage-awards.component';


const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'rappel-mdp', component: RappelMdpComponent },
    { path: 'reset-mdp', component: ResetMdpComponent },
    { path: '', redirectTo:'dp', pathMatch: 'full'},
    { path: 'dp', component: GestionDpComponent , canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.DP_Sales]}},
    { path: 'create-dp', component: CreateDpComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.DP_Sales]} },
    { path: 'detail-dp/:idEncrypted', component: DetailDpComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.DP_Sales]} },
    { path: 'welcome', component: FoIntroComponent },
    { path: 'infos-exposant', component: InfoExposantComponent },
    { path: 'exposants-indirects', component: ExpoIndirectComponent },
    { path: 'prestations', component: PrestationsComponent },
    { path: 'recapitulatif', component: RecapitulatifComponent },
    { path: 'contrat', component: ContratComponent },
    { path: 'signature', component: SignatureComponent },
    { path: 'thanks', component: ThanksPageComponent },
    { path: 'closedfolder', component: ClosedFolderPageComponent },
    { path: '404', component: ErrorPageComponent },
    { path: 'users', component:GestionUserComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'create-user', component: CreateUserComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'detail-user/:idEncrypted', component: CreateUserComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'roles', component:GestionRoleComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator, RoleTypeId.DP_Sales]} },
    { path: 'create-role', component: CreateRoleComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator, RoleTypeId.DP_Sales]} },
    { path: 'detail-role/:idEncrypted', component: CreateRoleComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator, RoleTypeId.DP_Sales]} },
    { path: 'traductions', component: TraductionsComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin]} },
    { path: 'admin-exposants', component:AdminExposantsComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
   
    // { path: 'admin-exposants/detail-group/:idEncrypted', component:AdminExposantsDetailComponent},
    // { path: 'admin-exposants/detail-exposant/:idEncrypted', component:AdminExposantsDetailComponent},
    // { path: 'groups', component:GestionGroupComponent},
    { path: 'create-group', component: CreateGroupComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'detail-group/:idEncrypted', component: CreateGroupComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'vm-exposants', component:GestionVmExposantComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'create-vm-exposant', component:CreateVmExposantComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    //{ path: 'create-vm-program', component:CreateVmProgramComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'detail-vm-exposant/:idEncrypted', component:CreateVmExposantComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'vm-users', component:GestionVmUserComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'create-vm-user', component:CreateVmUserComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'detail-vm-user/:idEncrypted', component:CreateVmUserComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'othervisits', component:GestionOtherVisitComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },//
    { path: 'create-othervisit', component:CreateOtherVisitComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },//
    { path: 'detail-othervisit/:idEncrypted', component:CreateOtherVisitComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },//
    { path: 'vm-do', component:GestionVmDoComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]}},
    { path: 'create-vm-do', component:CreateVmDetailDoComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'detail-vm-do/:idEncrypted', component:CreateVmDetailDoComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'vm-manage-do', component:GestionVmManageDoComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]}  },
    { path: 'od-planning', component:PlanningDOComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator, RoleTypeId.VM_Programmer, RoleTypeId.VM_Consultant,RoleTypeId.VM_OZ]} },
    { path: 'planning-exposants', component:PlanningExposantsComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator, RoleTypeId.VM_Programmer]} },
    { path: 'vm-prog-demandes', component:GestionVmProgDemandesComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator, RoleTypeId.VM_Programmer, RoleTypeId.VM_OZ]} },
    { path: 'vm-f18-etat1sur2', component:FoF18Etat1Sur2Component, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator, RoleTypeId.VM_Exhibitor, RoleTypeId.VM_Writer]} },
    { path: 'vm-f18-etat2sur2', component:FoF18Etat2Sur2Component, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator, RoleTypeId.VM_Exhibitor, RoleTypeId.VM_Writer]} },
    { path: 'demand-detail', component:DemandDetailComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator, RoleTypeId.VM_Programmer, RoleTypeId.VM_Exhibitor, RoleTypeId.VM_OZ, RoleTypeId.VM_Writer]} },
    // ====================================> Visit Manager
    { path: 'welcome-f18', component: WelcomeF18Component, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator, RoleTypeId.VM_Exhibitor, RoleTypeId.VM_Writer]} },
    // ====================================> Helped
    { path: 'welcome-helped', component: WelcomeHelpedComponent,canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator, RoleTypeId.HLP_Exhibitor,RoleTypeId.HLP_Manager,RoleTypeId.HLP_Writer] }},
   // ====================================> Program
   { path: 'welcome-program', component: WelcomeProgramComponent,canActivate: [authGuard]},
   { path: 'products-program', component:ListeProductsProgramComponent, canActivate: [authGuard]},
   { path: `product-program-recapitulatif/${FicheOpenMode.Edit}`, component: FormProgramRecapComponent, canActivate: [helpedSolutionGuard, authGuard] },
   { path: `product-program-recapitulatif/${FicheOpenMode.Consult}`, component: FormProgramRecapComponent, canActivate: [authGuard] },
   { path: 'product-program-generalities', component:FormProgramGeneralitiesComponent, canActivate: [authGuard]},
   { path: `product-program-generalities/${FicheOpenMode.Edit}`, component: FormProgramGeneralitiesComponent, canActivate: [helpedSolutionGuard, authGuard]},
   

    // ====================================> Conf
    { path: FOExternalPagesRoutes.confList, component: WelcomeConfComponent},
    { path: FOExternalPagesRoutes.confDetail, component: ConfDetailsComponent},
    { path: FOExternalPagesRoutes.speakerList, component: WelcomeConfSpeakerComponent},
    { path: FOExternalPagesRoutes.speakerDetail, component: SpeakerDetailsComponent},


// ====================================> Exhibitors and solutions
{ path: FOExternalPagesRoutes.solList, component: WelcomeSolComponent},
{ path: FOExternalPagesRoutes.solDetail, component: SolutionDetailsComponent},



    // ====================================> Innovation
    { path: 'welcome-innov', component: WelcomeInnovComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.INNOV_Administrator, RoleTypeId.INNOV_Exhibitor,RoleTypeId.INNOV_Manager,RoleTypeId.INNOV_Writer]} },
    { path: 'products-innov', component:ListeProductsInnovComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.INNOV_Administrator, RoleTypeId.INNOV_Exhibitor,RoleTypeId.INNOV_Manager,RoleTypeId.INNOV_Writer]} },
    { path: 'product-generalites', component:FormProduitGeneralitesComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.INNOV_Administrator, RoleTypeId.INNOV_Exhibitor,RoleTypeId.INNOV_Manager, RoleTypeId.INNOV_Writer]} },
    { path: 'fiches', component: GestionInnovComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.INNOV_Administrator]} },
    { path: 'detail-fiche/:idEncrypted', component: DetailInnovComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.INNOV_Administrator]} },
    { path: 'gestion-des-editions', component:GestionDesEditionsComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin]} },
    { path: 'create-edition', component:CreateEditionComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin]} },
    { path: 'detail-edition/:idEncrypted', component:CreateEditionComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin]} },
    { path: 'admin-delegations', component:AdminDelegationComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'ssologin', component: SSOLoginComponent},
    { path: `product-generalites/${FicheOpenMode.Edit}`, component: FormProduitGeneralitesComponent, canActivate: [innovSolutionGuard, authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.INNOV_Administrator, RoleTypeId.INNOV_Exhibitor,RoleTypeId.INNOV_Manager, RoleTypeId.INNOV_Writer]} },
    { path: `product-description/${FicheOpenMode.Edit}`, component: FormProduitDescriptionComponent, canActivate: [innovSolutionGuard, authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.INNOV_Administrator, RoleTypeId.INNOV_Exhibitor,RoleTypeId.INNOV_Manager, RoleTypeId.INNOV_Writer]} },
    { path: `product-medias/${FicheOpenMode.Edit}`, component: FormProduitMediasComponent, canActivate: [innovSolutionGuard, authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.INNOV_Administrator, RoleTypeId.INNOV_Exhibitor,RoleTypeId.INNOV_Manager, RoleTypeId.INNOV_Writer]} },
    { path: `product-contact/${FicheOpenMode.Edit}`, component: FormProduitContactComponent, canActivate: [innovSolutionGuard, authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.INNOV_Administrator, RoleTypeId.INNOV_Exhibitor,RoleTypeId.INNOV_Manager, RoleTypeId.INNOV_Writer]} },
    { path: `product-recapitulatif/${FicheOpenMode.Edit}`, component: FormProduitRecapComponent, canActivate: [innovSolutionGuard, authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.INNOV_Administrator, RoleTypeId.INNOV_Exhibitor,RoleTypeId.INNOV_Manager, RoleTypeId.INNOV_Writer]} },
    { path: `product-recapitulatif/${FicheOpenMode.Consult}`, component: FormProduitRecapComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.INNOV_Administrator, RoleTypeId.INNOV_Exhibitor,RoleTypeId.INNOV_Manager, RoleTypeId.INNOV_Writer]} },
    { path: 'statistiques', component:StatistiquesComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator]} },
    { path: 'vm-f18-etat1sur2/exhibitor', component:FoF18Etat1Sur2Component, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator, RoleTypeId.VM_Exhibitor, RoleTypeId.VM_Writer]} },
    { path: 'vm-f18-etat2sur2/exhibitor', component:FoF18Etat2Sur2Component, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.VM_Administrator, RoleTypeId.VM_Exhibitor, RoleTypeId.VM_Writer]} },

    {path:'admin-board', component:AdminBoardComponent},
    {path:'notauthorized', component: NotAuthorizedPageComponent},
    { path: 'old-login', component: LoginComponent },
    { path: 'list-of-exhibitors', component: ListeExposantsComponent },
    { path: 'help-fiches', component: GestionHelpedComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator]} },

    { path: 'list-of-exhibitors', component: ListeExposantsComponent },
    { path: 'products-helped', component:ListeProductsHelpedComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator, RoleTypeId.HLP_Exhibitor,RoleTypeId.HLP_Manager,RoleTypeId.HLP_Writer]} },
    { path: 'product-helped-generalites', component:FormHelpedGeneralitesComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator, RoleTypeId.HLP_Exhibitor,RoleTypeId.HLP_Manager, RoleTypeId.HLP_Writer]} },
    { path: `product-helped-generalites/${FicheOpenMode.Edit}`, component: FormHelpedGeneralitesComponent, canActivate: [helpedSolutionGuard, authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator, RoleTypeId.HLP_Exhibitor,RoleTypeId.HLP_Manager, RoleTypeId.HLP_Writer]} },
    { path: `product-helped-description/${FicheOpenMode.Edit}`, component: FormHelpedDescriptionComponent, canActivate: [helpedSolutionGuard, authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator, RoleTypeId.HLP_Exhibitor,RoleTypeId.HLP_Manager, RoleTypeId.HLP_Writer]} },
    { path: `product-helped-medias/${FicheOpenMode.Edit}`, component: FormHelpedMediasComponent, canActivate: [helpedSolutionGuard, authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator, RoleTypeId.HLP_Exhibitor,RoleTypeId.HLP_Manager, RoleTypeId.HLP_Writer]} },
    { path: `product-helped-contact/${FicheOpenMode.Edit}`, component: FormHelpedContactComponent, canActivate: [helpedSolutionGuard, authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator, RoleTypeId.HLP_Exhibitor,RoleTypeId.HLP_Manager, RoleTypeId.HLP_Writer]} },
    { path: `product-helped-recapitulatif/${FicheOpenMode.Edit}`, component: FormHelpedRecapComponent, canActivate: [helpedSolutionGuard, authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator, RoleTypeId.HLP_Exhibitor,RoleTypeId.HLP_Manager, RoleTypeId.HLP_Writer]} },
    { path: `product-helped-recapitulatif/${FicheOpenMode.Consult}`, component: FormHelpedRecapComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator, RoleTypeId.HLP_Exhibitor,RoleTypeId.HLP_Manager, RoleTypeId.HLP_Writer]} },
   
    // ====================================> Marketing
    { path: 'welcome-mkt', component: WelcomeMktComponent },
    
    //#region  ====================================> Marketing
    { path: 'welcome-mkt', component: WelcomeMktComponent, canActivate: [authGuard] },
    //#endregion
    { path: 'list-of-exhibitors', component: ListeExposantsComponent },
    {path:"parametrage-vm",component:ParametrageVMComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.DP_SuperAdmin,RoleTypeId.VM_Administrator]}},
    {path:"parametrage-innov",component:ParametrageInnovationComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.DP_SuperAdmin,RoleTypeId.VM_Administrator,RoleTypeId.INNOV_Administrator]}},
    {path: 'list-of-exhibitors', component: ListeExposantsComponent },
    {path:"parametrage-vm",component:ParametrageVMComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.DP_SuperAdmin,RoleTypeId.VM_Administrator]}},
    {path:"parametrage-innov",component:ParametrageInnovationComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.DP_SuperAdmin,RoleTypeId.VM_Administrator,RoleTypeId.INNOV_Administrator]}},
    { path: "product-helped-description", component: FormHelpedDescriptionComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator, RoleTypeId.HLP_Exhibitor,RoleTypeId.HLP_Manager, RoleTypeId.HLP_Writer]} },
    {path:"parametrage-innov",component:ParametrageInnovationComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.DP_SuperAdmin,RoleTypeId.INNOV_Administrator]}},
    {path:"request-detail",component : RequestDetailComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.MODULE_TECH]}},
    {path:'member-board', component:MemberBoardComponent},
    {path:"request-detail",component : RequestDetailComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.MODULE_TECH]}},
    {path:"parametrage-helped",component:ParametrageHelpedComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.DP_SuperAdmin,RoleTypeId.HLP_Administrator]}},
    { path: 'detail-helped/:idEncrypted', component: DetailHelpedComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator]} },

    {path:"parametrage-helped",component:ParametrageHelpedComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.DP_SuperAdmin,RoleTypeId.HLP_Administrator]}},
    { path: 'detail-helped/:idEncrypted', component: DetailHelpedComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator]} },

    {path:"request-detail",component : RequestDetailComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.MODULE_TECH]}},
    {path : "liste-attendees", component : ListeAttendeesComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.DP_SuperAdmin,RoleTypeId.ADMIN_FINDERR,RoleTypeId.ATT_Administrator,RoleTypeId.ATT_Consultant,RoleTypeId.ATT_Manager]}},
    {path : ConferenceRoutes.confList, component : ListeConferencesComponent, canActivate: [authGuard], data: {roles : [RoleTypeId.DP_SuperAdmin, RoleTypeId.ADMIN_FINDERR, RoleTypeId.CONF_Administrator, RoleTypeId.CONF_Manager, RoleTypeId.CONF_Consultant, RoleTypeId.CONF_Writer, RoleTypeId.CONF_Exhibitor]}},
    {path : "liste-news", component : ListeNewsComponent, canActivate : [authGuard],data:{roles : [RoleTypeId.DP_SuperAdmin,RoleTypeId.ADMIN_FINDERR,RoleTypeId.NEWS_Administrator,RoleTypeId.NEWS_Consultant,RoleTypeId.NEWS_Writter]}},
    {path : ConferenceRoutes.programList, component : ListeConferencesComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.DP_SuperAdmin, RoleTypeId.ADMIN_FINDERR, RoleTypeId.PROG_Administrator, RoleTypeId.PROG_Manager, RoleTypeId.PROG_Writter, RoleTypeId.PROG_Exhibitor]}},
    {path : ConferenceRoutes.createConf, component : CreateVmProgramComponent,canActivate:[authGuard],data:{roles : [RoleTypeId.DP_SuperAdmin, RoleTypeId.ADMIN_FINDERR, RoleTypeId.CONF_Administrator, RoleTypeId.CONF_Manager, RoleTypeId.CONF_Consultant, RoleTypeId.CONF_Writer]}},
    {path : ConferenceRoutes.createProg, component : CreateVmProgramComponent,canActivate: [authGuard], data: {roles : [RoleTypeId.DP_SuperAdmin, RoleTypeId.ADMIN_FINDERR, RoleTypeId.PROG_Administrator, RoleTypeId.PROG_Manager, RoleTypeId.PROG_Writter]}},
    {path : "exhibitor-summary", component : SommaireEspaceCommunicationComponent, canActivate : [authGuard], data:{roles : foRoles}},
    // ====================================> Exhibitors
    { path: FOExternalPagesRoutes.exhiList, component: WelcomeExhibitorComponent},
    { path: FOExternalPagesRoutes.exhiDetail, component: ExhibitorDetailsComponent},
    {path: "welcome-mkt-speaker", component : WelcomeSpeakerMktComponent,canActivate: [authGuard], data: {roles : [RoleTypeId.DP_SuperAdmin, RoleTypeId.ADMIN_FINDERR, RoleTypeId.MKTSP_Administrator, RoleTypeId.MKTSP_Writer, RoleTypeId.MKTSP_Exhibitor,RoleTypeId.MKTSP_Manager]}},
    {path : "detail-actualite", component : DetailActualiteComponent,canActivate: [authGuard], data: {roles : [RoleTypeId.DP_SuperAdmin, RoleTypeId.ADMIN_FINDERR, RoleTypeId.NEWS_Writter, RoleTypeId.NEWS_Administrator, RoleTypeId.NEWS_Consultant]}},
    {path : "communique-press", component : CommuniquePressComponent,canActivate: [authGuard], data: {roles : [RoleTypeId.DP_SuperAdmin, RoleTypeId.ADMIN_FINDERR, RoleTypeId.CP_Writer, RoleTypeId.CP_Administrator, RoleTypeId.CP_Exhibitor,RoleTypeId.CP_Manager]}},
    {path : "communique-press-detail", component : DetailCommuniquePressComponent,canActivate: [authGuard], data: {roles : [RoleTypeId.DP_SuperAdmin, RoleTypeId.ADMIN_FINDERR, RoleTypeId.CP_Writer, RoleTypeId.CP_Administrator, RoleTypeId.CP_Exhibitor,RoleTypeId.CP_Manager]}},
    
    
    //////// to remove this 
    { path: AwardsRoutes.companyInfo, component : FormAwardsCompanyInfoComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.AWD_Administrator, RoleTypeId.AWD_Writer,RoleTypeId.AWD_Exhibitor]}},
    { path: AwardsRoutes.generalities, component : AwardsFormGeneralitesComponent, canActivate: [authGuard, AwardGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.AWD_Administrator, RoleTypeId.AWD_Writer,RoleTypeId.AWD_Exhibitor]}},
    {path : AwardsRoutes.description, component : AwardsFormDescriptionInfoComponent, canActivate: [authGuard, AwardGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.AWD_Administrator, RoleTypeId.AWD_Writer,RoleTypeId.AWD_Exhibitor]} },
    {path : AwardsRoutes.recap, component:  AwardsRecapPageComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.AWD_Administrator, RoleTypeId.AWD_Writer,RoleTypeId.AWD_Exhibitor]} },
    {path: AwardsRoutes.welcome, component : AwardsWelcomePageComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.AWD_Administrator, RoleTypeId.AWD_Writer,RoleTypeId.AWD_Exhibitor]} },
    {path: AwardsRoutes.productList, component : ListeProductsAwardComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.AWD_Administrator, RoleTypeId.AWD_Writer,RoleTypeId.AWD_Exhibitor]} },

    {path : AwardsRoutes.listBO, component: AwardsBoListComponent, canActivate:[authGuard],data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.AWD_Administrator, RoleTypeId.AWD_Writer,RoleTypeId.AWD_Exhibitor]}},
    {path : AwardsRoutes.detailFiche, component: AwardsBoDetailFicheComponent, canActivate:[authGuard],data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.AWD_Administrator, RoleTypeId.AWD_Writer,RoleTypeId.AWD_Exhibitor]}},
    {path: AwardsRoutes.parametrage, component : ParametrageAwardsComponent, canActivate: [authGuard], data:{roles: [RoleTypeId.DP_SuperAdmin, RoleTypeId.AWD_Administrator]} }

    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(private router: Router,
        private sharedTools: SharedtoolsService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationError) {
                this.router.navigate(['404']);
            }
        });
    }
}