import { Component, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { SearchRole } from '../models/search-role';
import { cloneDeep } from 'lodash';
import { hasAnyNonNullProperty } from '../consts/utils';
import { Menu } from 'primeng/menu';
import { Subscription } from 'rxjs';
import { RoleSearchHelperService } from '../services/role-search-helper.service';

@Component({
    selector: 'app-gestion-role',
    templateUrl: './gestion-role.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./gestion-role.component.less']
})
export class GestionRoleComponent implements OnDestroy {
    private rolesExportSubscription?: Subscription;
    @ViewChild('dtRoles') dtRoles!: Table;
    @ViewChild('menuAction') menuAction!: Menu;

    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }

    set selectedColumns(val: any[]) {
        // ==> restore original order
        this._selectedColumns = this.colsRoles.filter((col: any) => val.includes(col));
    }
    _selectedColumns: any = [];

    fastSearchRoleKeyword: string = '';
    showAdvancedSearch: boolean = false;
    search: SearchRole = new SearchRole();
    roles: any[] = [];
    colsRoles: any[] = [];
    showTable: boolean = true;
    fastFiltersRoles: any = ['label', 'NumberUsers'];
    selectedRoles: any[] = [];
    items: any = [];
    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private roleSearchHelperService: RoleSearchHelperService
    ) { }

    ngOnInit() {
        //get privious advanced search value before routing to other routes
        this.search = cloneDeep(this.roleSearchHelperService.advancedSearch);
        this.fastSearchRoleKeyword = this.roleSearchHelperService.fastSearchKeyword;
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG", resG);
                this.items = resG.actionsRolesRow;
                
                this.items.forEach((itemMenu: any) => {
                    itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
                });
                
                this.colsRoles = resG.colsRoles;
                this._selectedColumns = this.colsRoles;
                this.searchRole(this.search, true);

            });

    }
    filterFast() {
        this.roleSearchHelperService.fastSearchKeyword = this.fastSearchRoleKeyword;
        console.log("_value", this.fastSearchRoleKeyword);

        this.dtRoles.filterGlobal(this.fastSearchRoleKeyword, 'contains');
    }
    clearFastSearch(): void {
        this.fastSearchRoleKeyword = '';
        this.filterFast();
    }
    toggleAdvancedSearch() {
        this.showAdvancedSearch = !this.showAdvancedSearch;
    }
    clearSearch() {
        console.log("clearSearch ==>");
        this.search = new SearchRole();
        this.searchRole(this.search,);
        this.clearFastSearch();
    }
    getActionsMenuByRole(role: any): any {
        return this.items.filter((item: any) => item.ActionID);

    }
    async searchRole(search: any, thenFilterFast: boolean = false) {
        //save search to retrieve it when coming back to BO after redirect from BO
        this.roleSearchHelperService.advancedSearch = cloneDeep(search);

        console.log("search ==> ", search);
        let searchTemp: any = cloneDeep(search);

        console.log('Label' + this.app.translate.currentLang.toUpperCase());

        searchTemp['Label' + this.app.translate.currentLang.toUpperCase()] = searchTemp.Label;

        delete searchTemp.Label;

        console.log("searchTemp ==> ", searchTemp);

        try {
            const response = await this.app.apiService.searchRoles(searchTemp);
            console.log("searchRoles==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/welcome'); }, () => { })
                return;
            }

            this.roles = cloneDeep(response)
            console.log("roles", this.roles);

            setTimeout(() => {
                if (thenFilterFast) {
                    this.filterFast();

                    if (hasAnyNonNullProperty(this.search)) {
                        console.log("hasAnyNonNullProperty", hasAnyNonNullProperty(this.search))
                        this.showAdvancedSearch = true;
                    }
                }
                console.log("after roles", this.roles);
            }, 100);

        } catch (error) {
            console.error(error);
        }
    }

    onRowSelect(event: any) {
        console.log("event.data==>", event.data);
        console.log("selectedRoles==>", this.selectedRoles);

    }
    toggleMenu(event: any, RoleID: number, items: any) {
        this.menuAction.toggle(event);

        console.log("Role ID:", RoleID);
        console.log("items:", items);

        items.forEach((itemMenu: any) => {
            itemMenu.roleIdRow = RoleID;
            itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

            if (itemMenu.ActionID == 1) {
                itemMenu.command = (event: any) => {
                    this.app.sharedTools.redirectTo('/detail-role/' + event.item.roleIdRow.toString());
                }
            }
        });
    }
    exportRolesToCsv() {
        this.RolesExport();
    }
    ngOnDestroy(): void {
        this.unsubscribeActiveSubscriptions();
    }
    private unsubscribeActiveSubscriptions(): void {
        if (this.rolesExportSubscription) {
            this.rolesExportSubscription.unsubscribe();
        }
    }
    private RolesExport(): void {
        this.rolesExportSubscription = this.app.apiService.rolesExport({}).subscribe({
            next: (data: any) => {
                console.log('RolesExport', data);
                if (data) {
                    let filesUrl: string = data;

                    window.open(filesUrl);
                }

            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }

}
