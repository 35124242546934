import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild, ViewEncapsulation } from "@angular/core";
import { ReportingService } from "../services/reporting.service";
import { PlanningDoHTML } from "../models/planning-do-html";
import { ActivatedRoute } from "@angular/router";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmDialog } from "primeng/confirmdialog";

@Component({
  selector: "app-html-planning-rapport-do",
  templateUrl: "./html-planning-rapport-do.component.html",
  styleUrls: ["./html-planning-rapport-do.component.less"],
})
export class HtmlPlanningRapportDoComponent implements OnInit {
  @ViewChild('container',{static:true}) container: ElementRef | undefined;

  protected planningDoHTML: PlanningDoHTML | undefined;
  protected includeNotes: boolean = false;

  dateRange: string[] = [];
  timeRange: string[] = [];
  table: any[] | undefined;
  isVisible : boolean = true ;
  notes : any = {};
  planninDoList : any[] | undefined;

  @Input() set planningDo(plaDo: any){
    if(plaDo.isSuccess){
      this.planningDoHTML = plaDo.data;
      this.dateRange = this.getDateRange();
      this.timeRange = this.getTimeRange();
      this.table = this.tableToRender();
      this.notes["OA"] = this.planningDoHTML?.notes?.filter(x => x.role === "OA");
      this.notes["PROG"] = this.planningDoHTML?.notes?.filter(x => x.role === "PROG");
    }
  }

  @Input() set includeNote(includeNote: boolean){
    this.includeNotes = includeNote;
    console.log(this.includeNotes)
  }



  constructor(
    private _reportingService: ReportingService,
    private _activatedRoute : ActivatedRoute) { }

  ngOnInit(): void {
    
  }

  getDateRange(): string[] {
    const formattedDates: string[] = [];
    if(!this.planningDoHTML)
      return formattedDates;
    const start = new Date(this.planningDoHTML.planningSettings.openingDate);
    const end = new Date(this.planningDoHTML.planningSettings.closingDate);

    while (start <= end) {
      const formattedDate = start.toLocaleDateString("fr-FR", {
        weekday: "long",
        month: "numeric",
        day: "numeric",
      });
      formattedDates.push(formattedDate);
      // Increment by 1 day
      start.setDate(start.getDate() + 1);
    }

    formattedDates.push("");
    formattedDates.unshift("");
    return formattedDates;
  }

  getTimeRange(): string[] {
    const timeStrings: string[] = [];
    if(!this.planningDoHTML)
      return timeStrings;
    //to take into account only the Time part so we create new Date objects with a common date
    const startDateTime = new Date(
      `1970-01-01T${this.extractTimeFromDateTime(this.planningDoHTML.planningSettings.openingDate)}`
    );
    const endDateTime = new Date(
      `1970-01-01T${this.extractTimeFromDateTime(this.planningDoHTML.planningSettings.closingDate)}`
    );

    while (startDateTime <= endDateTime) {
      const formattedTime = startDateTime.toLocaleTimeString("fr-FR", {hour: "2-digit", minute: "2-digit"});
      timeStrings.push(formattedTime);
      // Increment by slotDuration minutes in this case
      startDateTime.setMinutes(startDateTime.getMinutes() + this.planningDoHTML.planningSettings.slotDuration);
    }

    return timeStrings;
  }

  tableToRender() {
    this.planninDoList = [];
    let table: any[] = [];
    let slotLength;
    let timeRangeColspan = this.timeRange.map(e => { return { row: e, colspan: 0 } })
    for (let i = 0; i <= this.timeRange.length - 1; i++) {
      let row: any[] = [];
      table.push(row);
      for (let j = 0; j <= this.dateRange.length - 1; j++) {
        let planning = this.doesSlotHavePlanning(this.dateRange[j] + " " + this.timeRange[i])
        if (planning === undefined) {
          if (j === 0 || j === this.dateRange.length - 1) {
            row.push(
              {
                value: this.timeRange[i],
                rowspan: null,
                bgColor : null
              });
          }
          else {
            let rowTime = timeRangeColspan.find(e => e.row === this.timeRange[i]);
            if (rowTime && rowTime.colspan > 0) {
              rowTime.colspan -= 1;
            }
            else {
              row.push({
                value: "",
                rowspan: null,
                bgColor : null
              });
            }
          }
        }
        else { //(planning !== undefined){
          this.planninDoList.push(
            `On ${this.dateRange[j]} from ${this.timeRange[i]} to 
              ${this.extractTimeFromDateTime(planning.vis_hour_end).slice(0, -3)} : 
              ${planning.grp_name_en?? ""} ${planning.cmp_name2?? ""} - Stand ${planning.for_meeting_stand?? ""} - 
              ${planning.civ_name_en?? ""} ${planning.ctc_fullName?? ""} - ${planning.ctc_mobile?? ""} ${planning.ctc_phone? " - " + planning.ctc_phone : ""}`
          )
          slotLength = this.getSlotLength(planning);
          if (slotLength > 1) {
            row.push( 
              {
                value: planning.grp_name_en + " " + planning.cmp_name2 + " " + planning.for_meeting_stand,
                rowspan: slotLength,
                bgColor : true
              })
            for (let k = 1; k < slotLength; k++) {
              let rowTime = timeRangeColspan?.find(e => e.row === this.timeRange[i + k]);
              if (rowTime)
                rowTime.colspan += 1;
            }
          }
          else {
            row.push(
              {
                value: planning.grp_name_en + " " + planning.cmp_name2 + " " + planning.for_meeting_stand,
                rowspan: null,
                bgColor : true
              })
          }
        }
      }
    }
    return table;
  }

  //returns the number of rows(rowspan) to be merged
  private getSlotLength(planning: any) {
    if(!this.planningDoHTML)
      return 0;
    let count = 0;
    //to take into account only the Time part so we create new Date objects with a common date
    const startDateTime = new Date(`1970-01-01T${this.extractTimeFromDateTime(planning.vis_hour_start)}` );
    const endDateTime = new Date(`1970-01-01T${this.extractTimeFromDateTime(planning.vis_hour_end)}` );

    while (startDateTime < endDateTime) {
      count++;
      // Increment by slotDuration minutes in this case
      startDateTime.setMinutes(startDateTime.getMinutes() + this.planningDoHTML.planningSettings.slotDuration );
    }
    return count;
  }

  //returns the element (planningDo) equal to the start date (vis_hour_start) given as a parameter
  private doesSlotHavePlanning(cellDate: string) {
    if(!this.planningDoHTML)
      return;
    
    return this.planningDoHTML.planningDo.find( (x:any) => {
      let d1 = new Date(x.vis_hour_start).toLocaleDateString("fr-FR",
        {
          weekday: "long",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit"
        });
        return d1.trim() === cellDate.trim();
    })
  }

  private extractTimeFromDateTime(dateTime: string): string {
    const dateObj = new Date(dateTime);
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}:00`;
  }

}

