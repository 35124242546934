import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-member-board',
  templateUrl: './member-board.component.html',
  styleUrls: ['./member-board.component.less']
})
export class MemberBoardComponent implements OnInit{
  appID: any = null;
  constructor(public app : AppComponent){}

  ngOnInit(): void {
    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
          console.log("resG", resG);
          this.appID = resG.APP_ID;
      });
  }

}
