export enum ImageModelSuffix {
    Enhanced_h600 = 'enhanced_h600',
    Enhanced_w800_h600 = 'enhanced_w800_h600',
    Square_160 = 'square_160',
    Square_200 = 'square_200',
    Thumb_h300 = 'thumbnail_h300',
    Thumb_w200_h100 = 'w200_h100',
    Thumb_w115_h170 = 'thumbnail_w115_h170',
    Thumb_h200 = 'thumbnail_h200'
}
