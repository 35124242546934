import { Languages } from "../enums/language";
import { CartItem } from "./cart-item";

export class CartGroup {
    ProductGroupID?: number | null;
    SubTotal?: number | null;
    CartItems?: CartItem[] | null;

    //local properties :: not mapped from api (filled locally - clien side)
    LabelEN?: string | null;
    LabelFR?: string | null;
    LabelES?: string | null;

    isTypeSurface: boolean = false;

    public getLabel(language: string): string {
        switch (language) {
            case Languages.English: return this.LabelEN ?? '';
            case Languages.French: return this.LabelFR ?? '';
            case Languages.Spanish: return this.LabelES ?? '';
            default: return this.LabelEN ?? '';
        }
    }
}