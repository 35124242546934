import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { StatusCode } from '../enums/status-code';
import { MediasTypes } from '../enums/media-type';
import { StatusType } from '../enums/status-type';
import { environment } from 'src/environments/environment';
import { FormCode, formsWithFolderGuid } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';
import { AwardsRoutes } from '../awards-module/awards.routes';

@Component({
  selector: 'app-sommaire-espace-communication',
  templateUrl: './sommaire-espace-communication.component.html',
  styleUrls: ['./sommaire-espace-communication.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SommaireEspaceCommunicationComponent {
  state: any;
  product: any;
  status: any[] = [];
  toBeValidateStatus: any;
  mediaTypes = MediasTypes;
  prod: any
  StatusCode = StatusCode;
  StatusType = StatusType;
  FormCode = FormCode;
  folderGuid: any;
  filteredListOfForms: any[] = [];
  
  constructor(
    public app: AppComponent,
  ) {

  }

  async ngOnInit() {
    await this.getListOfForms();
    this.getStatus();
  }

  getStatus() {

    this.app.apiService.getStatus().subscribe((data: any) => {
      this.status = data.filter((s: any) => s.StatusType === StatusType.FormState);
    });
    console.log("liste des status : ", this.status)
  }

  async getListOfForms() {
    let data = await firstValueFrom(this.app.apiService.getSommaire())
      this.folderGuid = data.FolderGuid;
      let listOfForms = data.ExhibitorForms;

      if(listOfForms && Array.isArray(listOfForms) && listOfForms.length > 0){
        this.filteredListOfForms = listOfForms
        
        this.filteredListOfForms.map((f: any) => {
          switch (f.FormCode) {
            case FormCode.FRM_VM: {
              f.formLink = "welcome-f18";
              break;
            }
            case FormCode.FRM_HLP: {
              f.formLink = "welcome-helped";
              break;
            }
            case FormCode.FRM_INNOV: {
              f.formLink = "welcome-innov";
              break;
            }
            case FormCode.FRM_MARKTNG: {
              f.formLink = "welcome-mkt";
              break;
            }
            case FormCode.FRM_MARKETNG_SPEAKER: {
              f.formLink = "welcome-mkt-speaker";
              break;
            }
            case FormCode.FRM_PROGRAM: {
              f.formLink = "liste-programs";
              break;
            }
            case FormCode.FRM_CONF:{
              f.formLink = "liste-conferences"
              break;
            }
            case FormCode.FRM_CP: {
                f.formLink = "communique-press";
                break;
            }
            case FormCode.FRM_AWARD: {
                f.formLink = AwardsRoutes.welcome;
                break;
            }
          }
        });
      }
  }
  parseDate(dateString: string): Date {
    return new Date(dateString);
  }


  goToForm(form: any) {
    if(form.FormCode == FormCode.FRM_PROGRAM || form.FormCode == FormCode.FRM_CONF ){
      this.app.sharedTools.goTo(form.formLink)
    }
    else if(formsWithFolderGuid.indexOf(form.FormCode) >= 0){
      this.app.sharedTools.goTo(`/${form.formLink}?folderguid=${this.folderGuid}`);
    }
    else{
      this.app.confirm.confirmDialog(""
      , this.app.translate.instant('racine.generalites.serviceNotYetAvailable')
      , 'OK'
      , "alert"
      , 
      () => { }, 
      () => { });
    }
  }

  isFormDisabled(form: any): boolean {
    let isDisabled: boolean = true;

    isDisabled = form.Status.StatusType == StatusType.FormState
      && (form.Status.Code == StatusCode.FORM_CLOSED
        || form.Status.Code == StatusCode.FORM_LOCKED)

    return isDisabled;
  }

}
