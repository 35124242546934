import { Contact } from "./contact";

export class ProductInnov {
    SolID?: number | null = null;
    ProductGroupID?: number | null = null;
    SolNameEN?: string | null = "";
    SolNameFR?: string | null = "";
    DescriptionEN?: string | null = "";
    DescriptionFR?: string | null = "";
    SolUrl?: string | null = "";
    PhotoPath?: string | null = "";
    PublishedDate?: string | null = "";
    IsMultiWriter: boolean = false;
    IsOfficialCommunication: boolean = false;
    IsofficialCommunicationDetail?: string | null = "";
    InnovationAspectEN?: string | null = "";
    InnovationAspectFR?: string | null = "";
    TlrLader?: number | null;
    PotentialEN?: string | null = "";
    PotentialFR?: string | null = "";
    IsupportedBy: boolean = false;
    IsupportedName?: string | null = "";
    Normes: string | null = "";
    IsConformTo: boolean = false;
    IspowerTransition: boolean = false;
    IspowerTransitionDetailFR?: string | null = "";
    IspowerTransitionDetailEN?: string | null = "";
    Categories?: any[] | null;
    OtherCategory?: string | null = '';
    Documents?: any[] | null;
    IsPublicationDifferentDate : boolean = false 
    PublicationWishDate ?: Date = new Date();
    innoIsPublicationDelayed : boolean = false 
    editionOpeningDate : Date = new Date() 

    public getListNotRequiredsInStep1(): boolean {
        const notRequiredValidators: any = ['Categories', 'SolID', 'ProductGroupID', 'DescriptionEN', 'DescriptionFR', 'PhotoPath', 'PublishedDate', 'IsMultiWriter', 'IsOfficialCommunication', 'IsofficialCommunicationDetail', 'TlrLader', 'ProductInnovation', 'PotentialFR','PotentialEN', 'IsupportedBy', 'IsupportedName', 'IspowerTransition', 'IspowerTransitionDetailFR','IspowerTransitionDetailEN', 'InnovationAspectEN', 'Normes', 'IsConformTo','OtherCategory', 'SolUrl', 'InnovationAspectFR','IsPublicationDifferentDate','PublicationWishDate', 'innoIsPublicationDelayed'];
        return notRequiredValidators;
    }

    public getListNotRequiredsInStep2(): boolean {
        const notRequiredValidators: any = ['Categories','ProductGroupID', 'SolNameEN', 'SolNameFR', 'SolUrl', 'PhotoPath', 'PublishedDate', 'IsMultiWriter', 'IsOfficialCommunication', 'IsofficialCommunicationDetail', 'PotentialFR','PotentialEN', 'IsupportedBy', 'IsupportedName', 'IspowerTransition', 'Normes', 'IsConformTo','OtherCategory','IsPublicationDifferentDate','PublicationWishDate', 'innoIsPublicationDelayed', 'IspowerTransitionDetailEN'];
        return notRequiredValidators;
    }

    mapFormGetResponse(product: any): void{
        this.SolID = product.SolID;
        this.ProductGroupID = product.ProductGroupID;
        this.SolNameEN = product.SolNameEN;
        this.SolNameFR = product.SolNameFR;
        this.DescriptionEN = product.DescriptionEN;
        this.DescriptionFR = product.DescriptionFR;
        this.SolUrl = product.SolUrl;
        this.PhotoPath = product.PhotoPath;
        this.PublishedDate = product.PublishedDate;
        this.IsMultiWriter = product.IsMultiWriter;
        this.IsOfficialCommunication = product.IsOfficialCommunication;
        this.IsofficialCommunicationDetail = product.IsofficialCommunicationDetail;
        this.InnovationAspectEN = product.InnovationAspectEN;
        this.InnovationAspectFR=product.InnovationAspectFR;
        this.TlrLader = product.TlrLader;
        this.PotentialEN = product.PotentialEN;
        this.PotentialFR = product.PotentialFR;
        this.IsupportedBy = product.IsupportedBy;
        this.IsupportedName = product.IsupportedName;
        this.Normes = product.Normes;
        this.IsConformTo=product.IsConformTo;
        this.IspowerTransition = product.IspowerTransition;
        this.IspowerTransitionDetailFR = product.IspowerTransitionDetailFR;
        this.IspowerTransitionDetailEN = product.IspowerTransitionDetailEN;
        this.OtherCategory = product.OtherCategory;
        this.Categories = product.Categories;
        this.innoIsPublicationDelayed = product.InnoIsPublicationDelayed
        this.editionOpeningDate = new Date(product.EditionOpeningDate)
        this.PublicationWishDate =  product.PublicationWishDate != undefined ? new Date(product.PublicationWishDate) : undefined
    }

    mapFormStep1Form(product: any): void{
        this.SolNameEN = product.SolNameEN;
        this.SolNameFR = product.SolNameFR;
        this.SolUrl = product.SolUrl;
        this.IsMultiWriter = product.IsMultiWriter;
        this.SolID = product.SolID;
        // this.publicationWishDate=product.PublicationFixedDifferentDateValue;
        this.innoIsPublicationDelayed = product.InnoIsPublicationDelayed
    }

    mapFormStep2Form(product: any): void{
        this.IsOfficialCommunication = product.IsOfficialCommunication;
        this.IsofficialCommunicationDetail = product.IsofficialCommunicationDetail;
        this.DescriptionFR = product.DescriptionFR;
        this.DescriptionEN = product.DescriptionEN;
        this.InnovationAspectEN = product.InnovationAspectEN;
        this.InnovationAspectFR= product.InnovationAspectFR;
        this.TlrLader = product.TlrLader;
        this.PotentialFR = product.PotentialFR;
        this.PotentialEN = product.PotentialEN;
        this.IsupportedBy = product.IsupportedBy;
        this.IsupportedName = product.IsupportedName;
        this.Normes = product.Normes;
        this.IsConformTo=product.IsConformTo;
        this.IspowerTransition = product.IspowerTransition;
        this.IspowerTransitionDetailFR = product.IspowerTransitionDetailFR;
        this.IspowerTransitionDetailEN = product.IspowerTransitionDetailEN;
        this.OtherCategory = product.OtherCategory;
        this.Categories = product.Categories;
    }

}