export enum FormCode{
    FRM_VM = "FRM_VM",
    FRM_INNOV ="FRM_INNOV",
    FRM_HLP = "FRM_HLP",
    FRM_MARKTNG = "FRM_MARKTNG",
    FRM_CATALOG = "FRM_CATALOG",
    FRM_PROGRAM = "FRM_PROG",
    FRM_CP = "FRM_CP",
    FRM_CONF = "FRM_SPEAKER",
    FRM_MARKETNG_SPEAKER = "FRM_MARKETNG_SPEAKER",
    FRM_AWARD ="FRM_AWD"
}

export const availableForms : string[] = [
    FormCode.FRM_VM,
    FormCode.FRM_INNOV,
    FormCode.FRM_HLP,
    FormCode.FRM_MARKTNG,
    FormCode.FRM_CP,
    FormCode.FRM_PROGRAM,
    FormCode.FRM_CONF,
    FormCode.FRM_MARKETNG_SPEAKER
]

export const formsWithFolderGuid : string[]=[
    FormCode.FRM_VM,
    FormCode.FRM_INNOV,
    FormCode.FRM_HLP,
    FormCode.FRM_MARKTNG,
    FormCode.FRM_CP,
    FormCode.FRM_MARKETNG_SPEAKER
]
