import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { TreeNode } from 'primeng/api';
import { Table } from 'primeng/table';
import { TraductionService } from '../services/traduction.service';
import { GenerateTraductionRequest, Traduction, UploadTraductionRequest } from '../models/traduction';
import { TranslatePipe } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Languages } from '../enums/language';


@Component({
    selector: 'app-traductions',
    templateUrl: './traductions.component.html',
    styleUrls: ['./traductions.component.less']
})



export class TraductionsComponent implements OnDestroy {
    traductionTreeNodes: TreeNode[] = [];
    selectedNode: any;

    traduction!: Traduction;

    constructor(
        public app: AppComponent,
        private traductionService: TraductionService,
        private translate: TranslatePipe
    ) { }
    async ngOnInit() {

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG", resG);

            });

        this.traduction = await this.traductionService.getTraductionAsync();

        console.log('trad : ', this.traduction);
    }


    onchangeSelect(_item: any) {
        if (!_item) return;
    }

    ngOnDestroy(): void {
    }

    onAddTraductionClick() {
        console.log('onAddTraductionClick', this.traduction);
        //this.uploadAllTraductions();
        
        if(this.selectedNode && this.selectedNode.data && this.selectedNode.data[0]){
            let newNode: TreeNode = cloneDeep(this.selectedNode.data[0]);

            newNode.key = '';
            if(newNode.data === 2){
                this.traduction.traductionEN[this.selectedNode.key][newNode.key]
                this.traduction.traductionFR[this.selectedNode.key][newNode.key]
                this.traduction.traductionES[this.selectedNode.key][newNode.key]
            }
            else if(newNode.data === 3){
                this.traduction.traductionEN[this.selectedNode.parent.key][this.selectedNode.key][newNode.key]
                this.traduction.traductionFR[this.selectedNode.parent.key][this.selectedNode.key][newNode.key]
                this.traduction.traductionES[this.selectedNode.parent.key][this.selectedNode.key][newNode.key]
            }

            newNode.selectable = true;

            this.selectedNode.data = [ newNode, ...this.selectedNode.data];
        }
    }

    onSaveTraductionClick() {
        console.log('save', this.traduction);
        this.uploadAllTraductions();
    }

    onGenerateTraductionClick() {
        let isArray: boolean = false;
        let level: number = -1;
        console.log('save', this.traduction);
        let msg: string = this.translate.transform('translationGenerator.translate-json-command');
        let enTranslationJson: any = null;
        let frTranslationJson: any = null;
        let esTranslationJson: any = null;

        if (this.traduction
            && this.traduction.traductionEN
            && this.traduction.traductionFR
            && this.traduction.traductionES
            && this.selectedNode
            && this.selectedNode.data
            && this.selectedNode.data.length > 0) {
            if (this.selectedNode.data[0].data === 2) {
                level = 2;
                enTranslationJson = this.traduction.traductionEN[this.selectedNode.key];
                frTranslationJson = this.traduction.traductionFR[this.selectedNode.key];
                esTranslationJson = this.traduction.traductionES[this.selectedNode.key];
            }
            else if (this.selectedNode.data[0].data === 3) {
                level = 3;
                enTranslationJson = this.traduction.traductionEN[this.selectedNode.parent.key][this.selectedNode.key];
                frTranslationJson = this.traduction.traductionFR[this.selectedNode.parent.key][this.selectedNode.key];
                esTranslationJson = this.traduction.traductionES[this.selectedNode.parent.key][this.selectedNode.key];
                if (Array.isArray(enTranslationJson)) {
                    isArray = true;
                    enTranslationJson = { Data: enTranslationJson }
                    frTranslationJson = { Data: frTranslationJson }
                    esTranslationJson = { Data: esTranslationJson }
                }
            }
        }

        console.log('go auto trad ', enTranslationJson, frTranslationJson, esTranslationJson)
        this.generateTraduction(msg
            , enTranslationJson
            , frTranslationJson
            , esTranslationJson
            , level
            , isArray);

            this.traductionService.traduction.traductionEN = this.traduction.traductionEN;
            this.traductionService.traduction.traductionFR = this.traduction.traductionFR;
            this.traductionService.traduction.traductionES = this.traduction.traductionES;

            this.traduction = this.traductionService.traduction;
    }

    onRowSelect(event: any) {
        console.log("event.data==>", event.data);
    }

    onSelectNode(event: any) {
        console.log("selectedCodes==>", this.selectedNode);
        console.log("getSelectedNodeLeafs==>", this.selectedNode?.data);
    }

    Annuler() {
        this.app.sharedTools.redirectTo('/');
    }

    onTraductionValueChanged(value: string, node: TreeNode, language: string) {
        if (language === Languages.English) {
            if (node.parent?.parent) {
                this.traduction.traductionEN[this.selectedNode?.parent?.key ?? ''][this.selectedNode?.key ?? ''][node.key ?? ''] = value;
            }
            else {
                this.traduction.traductionEN[this.selectedNode?.key ?? ''][node.key ?? ''] = value;
            }
        }
        else if (language === Languages.French) {
            if (node.parent?.parent) {
                this.traduction.traductionFR[this.selectedNode?.parent?.key ?? ''][this.selectedNode?.key ?? ''][node.key ?? ''] = value;
            }
            else {
                this.traduction.traductionFR[this.selectedNode?.key ?? ''][node.key ?? ''] = value;
            }
        }
        else if (language === Languages.Spanish) {
            if (node.parent?.parent) {
                this.traduction.traductionES[this.selectedNode?.parent?.key ?? ''][this.selectedNode?.key ?? ''][node.key ?? ''] = value;
            }
            else {
                this.traduction.traductionES[this.selectedNode?.key ?? ''][node.key ?? ''] = value;
            }
        }
    }

    //#region private actions

    private async uploadAllTraductions() {
        let responseEN : any = await this.uploadTraduction(this.traduction.traductionEN, Languages.English);
        let responseFR : any = await this.uploadTraduction(this.traduction.traductionFR, Languages.French);
        let responseES : any = await this.uploadTraduction(this.traduction.traductionES , Languages.Spanish);

        this.processUploadResponse(responseFR,responseEN,responseES);
    }

    onInputChange(ref: HTMLInputElement): void{
        
    }

    private async uploadTraduction(traduction: any, languageCode: string) {
        let request: UploadTraductionRequest = {
            LanguageCode: languageCode,
            JsonFileContent: traduction
        };

        console.log("uploadTraduction ===>", request);
        try {
            const response = await this.app.apiService.uploadTraductions(request);
            console.log('Response API : uploadTraduction ===>', response);
            return response;


        } catch (error) {
            console.error(error);
        }
    }

    private processUploadResponse(responseFR: any, responseEN: any, responseES : any): void{
        
        let error = '';
        if (responseFR && responseFR.Result) {
            let responseError = this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + responseFR.Result);
            error=error+'<br/>'+'FR '+responseError;
        }
        if (responseEN && responseEN.Result) {
            let responseError = this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + responseEN.Result);
            error=error+'<br/>'+'EN '+responseError;
        }
        if (responseES && responseES.Result) {
            let responseError = this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + responseES.Result);
            error=error+'<br/>'+'ES '+responseError;
        }
        if(error===''){
            this.app.confirm.confirmDialog(""
            , this.app.translate.instant('racine.successMsg.successTraductionFileUploaded')
            , 'OK'
            , "alert" 
            , () => { /*this.app.sharedTools.redirectTo('/admin-exposants');*/ }
            , () => { });
            return;
        }
        this.app.confirm.confirmDialog(""
            , error
            , 'KO'
            , "alert"
            , () => { }
            , () => { });
        return;
       
    }

    private async generateTraduction(message: any
        , enJsonObject: string
        , frJsonObject: string
        , esJsonObject: string
        , level: number
        , isArray: boolean) {
        let request: GenerateTraductionRequest = {
            Message: message,
            JsonObject: {
                    en: enJsonObject,
                    fr: frJsonObject,
                    es: esJsonObject
            }
        };

        console.log("generateTraduction ===>", request);
        try {
            const response = await this.app.apiService.generateTraduction(request);
            console.log('Response API : generateTraduction ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                    , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                    , 'KO'
                    , "alert"
                    , () => { }
                    , () => { });
                return;
            }

            this.app.confirm.confirmDialog(""
                , this.app.translate.instant('racine.successMsg.successTraductionGeneration')
                , 'OK'
                , "alert"
                , () => { /*this.app.sharedTools.redirectTo('/admin-exposants');*/ }
                , () => { });

            if (level === 2) {
                this.traduction.traductionEN[this.selectedNode.key] = response[Languages.English];
                this.traduction.traductionFR[this.selectedNode.key] = response[Languages.French];
                this.traduction.traductionES[this.selectedNode.key] = response[Languages.Spanish];
            }
            else if (level === 3) {
                if (isArray) {
                    this.traduction.traductionEN[this.selectedNode.parent.key][this.selectedNode.key] = response[Languages.English].Data;
                    this.traduction.traductionFR[this.selectedNode.parent.key][this.selectedNode.key] = response[Languages.French].Data;
                    this.traduction.traductionES[this.selectedNode.parent.key][this.selectedNode.key] = response[Languages.Spanish].Data;
                }
                else {
                    this.traduction.traductionEN[this.selectedNode.parent.key][this.selectedNode.key] = response[Languages.English];
                    this.traduction.traductionFR[this.selectedNode.parent.key][this.selectedNode.key] = response[Languages.French];
                    this.traduction.traductionES[this.selectedNode.parent.key][this.selectedNode.key] = response[Languages.Spanish];

                }
            }
            console.log(this.traduction.traductionEN
                , this.traduction.traductionFR
                , this.traduction.traductionES)
        }
        catch (error) {
            console.error(error);
        }
    }
    //#endregion
}


