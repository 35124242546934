import { Component, ViewEncapsulation } from '@angular/core';
import { MediasTypes } from '../enums/media-type';
import { AppComponent } from '../app.component';
import { firstValueFrom } from 'rxjs';
import { cloneDeep } from 'lodash';
import { StatusType } from '../enums/status-type';
@Component({
  selector: 'app-liste-products-program',
  templateUrl: './liste-products-program.component.html',
  styleUrls: ['./liste-products-program.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ListeProductsProgramComponent {
  user: any = null;
  appID: any = null;
  products: any[] = [];
  numbers:any;
  translatedNbProdMessage: any = "";
  showFormProduct: boolean = false;
  formProgramID!: number;
  programForm: any;
  status: any[] = [];
  mediaTypes = MediasTypes;
  findImage: any;
  productsLoaded: boolean = false;


  responsiveOptions: any[] = [
      {
          breakpoint: '1199px',
          numVisible: 1,
          numScroll: 1
      },
      {
          breakpoint: '991px',
          numVisible: 2,
          numScroll: 1
      },
      {
          breakpoint: '767px',
          numVisible: 1,
          numScroll: 1
      }
  ];

  constructor(
      public app: AppComponent,
  ) {
      this.getFormProgramData();
  }

  async ngOnInit() {
      this.user = JSON.parse(sessionStorage.getItem('userData')!);
      console.log("this.user ==> ", this.user);
      if (!this.user) {
          this.app.router.navigate(['/login']);
          return;
      }
      // this.app.route.queryParams
      // .subscribe(params => {
      //     console.log('[params]', params);
      //     if(params && params['programid']){
      //         sessionStorage.setItem('program-form', JSON.stringify({HlpFormID: params['programid']}));
      //         this.formProgramID=params['programid'];
      //     }
      //     console.log('[formProgramID]', this.formProgramID)
      //     console.log("sessionStorage",sessionStorage)
      //     if(params && params['folderguid']){
      //         sessionStorage.setItem('folderGuid', JSON.stringify(params['folderguid']));                
      //     }
      // });


      sessionStorage.removeItem('folderStep');
      sessionStorage.removeItem('activeStep');

      this.app.sharedTools.getJSON('general_data').subscribe(
          resG => {
              this.appID = resG.APP_ID;
          });

      await this.getStatus();
      this.getProgramForm();

      const productsLength = this.products.length; // Assuming products is your array
      this.translatedNbProdMessage = this.app.translate.instant('fo.productListCommun.nbProducts').replace('{nb}', productsLength.toString());
  }

  async getProgramForm() {
      this.productsLoaded = false;
      this.programForm = await this.app.apiService.getProgramForm({ ProgFormID: this.formProgramID });
      console.log("programForm !! ",this.programForm)
      console.log("formProgramID !! ",this.formProgramID)
      this.products = this.programForm?.Solutions ? this.programForm?.Solutions : [];
      console.log("products !! ",this.products)

      const nbMaxFiles = { NbMaxProducts: this.programForm.NbMaxProduct ,NbTotalProduct:  this.programForm.NbTotalProduct};
      this.numbers=nbMaxFiles;  
      console.log("numbers !! ",this.numbers)
      this.mapProducts();
  }

  onRefresh(event: any): void {
      this.getProgramForm();
  }

  private getFormProgramData(): void {
      let programForm: any = JSON.parse(sessionStorage.getItem('program-form') ?? '{}');

      console.log("programForm", programForm)

      if (programForm && programForm.ProgramFormID) {
        
          this.formProgramID = programForm.ProgramFormID;
      }
      // else if(programForm && programForm.HlpFormID){

      //   this.formProgramID = programForm.HlpFormID;

      // }
  }

  private async getStatus() {
      let data = await firstValueFrom(this.app.apiService.getStatus());

      if (data && Array.isArray(data)) {
          this.status = cloneDeep(data);
          this.status = this.status.filter((s: any) => s.StatusType === StatusType.FicheState);

          console.log("status", this.status)
      }
  }

  private mapSuppPropertiesToProduct(product: any): void {
      console.log("mapSuppPropertiesToProduct", this.products)

      if (product?.ConfStatus) {
        console.log("mapSuppPropertiesToProduct product.ConfStatus", product.ConfStatus)
          let findStatus: any = this.status.find((s: any) => s.StatusID === product.ConfStatus.StatusID);
          product.SelectedStatus = findStatus;
      }

      if (product?.CnfdLogo) {
        //   let firstImage: any = product?.DOC_Documents.find((doc: any) => doc.typeDocument && doc.typeDocument.TypeDOCCode === MediasTypes.IMAGE);
        //   if (firstImage) {
              product.FirstImageUrl = product.CnfdLogo.DocUrl;
          //}
      }

      console.log("mapSuppPropertiesToProduct product.FirstImageUrl ", product.FirstImageUrl)
      console.log("mapSuppPropertiesToProduct product.SelectedStatus ",  product.SelectedStatus)

  }

  mapProducts(): void {
      if (!this.productsLoaded) {
          this.products.forEach((p: any) => {
              this.mapSuppPropertiesToProduct(p);
          });
          this.productsLoaded = true;
      }
  }

  annuler() {
      let folderGuid = sessionStorage.getItem('folderGuid');
      let guid = JSON.parse(folderGuid!);
      this.app.sharedTools.goTo(`/welcome-program?folderguid=${guid}`);
  }
}
