import { Component, Input, OnInit } from '@angular/core';
import { SharedtoolsService } from 'src/app/services/sharedtools.service';

@Component({
  selector: 'app-card-location',
  templateUrl: './card-location.component.html',
  styleUrls: ['./card-location.component.less']
})
export class CardLocationComponent implements OnInit {
  
  @Input() stand : any;


  constructor(private sharedService: SharedtoolsService) {
    
  }

  ngOnInit(): void {

  }

  GoToExhiMap(location: any) {
    this.sharedService.goToExhiMap(location)
  }


}
