import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConferenceHelper } from '@helpers/conference.helper';
import { AppComponent } from 'src/app/app.component';
import { FOExternalPagesRoutes } from 'src/app/consts/fo-external-pages.routes';
import { ImageModelSuffix } from 'src/app/enums/image-model-suffix';
import { IdentityManagerService } from 'src/app/services/identity-manager.service';

@Component({
  selector: 'app-speaker-card-details',
  templateUrl: './speaker-card-details.component.html',
  styleUrls: ['./speaker-card-details.component.less']
})
export class SpeakerCardDetailsComponent {

  @Input() contact: any;
  @Input() withBorderRadius: boolean = false;
  @Input() isMinScreen: boolean = false;
  @Input() isLargScreen: boolean = false;
  @Input() enableClick: boolean = true;
  imageModelSuffix = ImageModelSuffix;  
  conferenceHelper = ConferenceHelper;

  constructor(public app: AppComponent,
    private router: Router,
    protected ims: IdentityManagerService) {
  }

  isImageUrl(url: string) {
    let isValidUrl: boolean = false;

    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const extension = url.substring(url.lastIndexOf('.')).toLowerCase();

    isValidUrl = imageExtensions.includes(extension);

    return isValidUrl;
  }

  speakerDetail(contactID: any): void {
    if(this.enableClick){
      const queryparams = {
        speakerID: contactID
      }
  
      this.app.sharedTools.routeTo(FOExternalPagesRoutes.speakerDetail, queryparams);
    }
  }

  public function(): string{
    if(!this.contact){
      return "";
    }

    if(this.contact.GradeCategory){
      return this.app.sharedTools.getPropertySwitchLang(this.contact.GradeCategory, 'Label');
    }

    return this.app.sharedTools.getPropertySwitchLang(this.contact, 'Function');
  }
}
