import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AwardsRoutes, AwardsRoutesQueryParamsNames } from '../awards.routes';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-awards-bo-detail-fiche',
  templateUrl: './awards-bo-detail-fiche.component.html',
  styleUrls: ['./awards-bo-detail-fiche.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AwardsBoDetailFicheComponent implements OnInit {

  awardId: any
  awardData: any
  folderGuid: any
  submittedByObject: any
  contacts : any

  constructor(private activatedRouter: ActivatedRoute,
    public app: AppComponent, private helperService : HelperService 
  ) { }


  async ngOnInit() {
    this.activatedRouter.queryParams.subscribe(async (data: any) => {
      console.log(data)
      if (data && data[AwardsRoutesQueryParamsNames.awardId]) {
        this.awardId = +data[AwardsRoutesQueryParamsNames.awardId]
        this.folderGuid = data[AwardsRoutesQueryParamsNames.folderGuid]
        await this.getAwardInfo()
      }
    })
  }

  async getAwardInfo() {
    let response = await this.app.apiService.getAwardSolutionById(this.awardId);
    this.awardData = response;
    console.log(this.awardData)
    this.getSelectedContacts(this.awardData.AwardFormID)
  }

  async getSelectedContacts(formId: any) {
    let reqest: any = {
      FormeID: formId,
      SolutionID: this.awardId,
      ComponentID: 8
    }
    const response = await this.app.apiService.getInnovContactSelected(reqest);
    console.log(response)
    if (response && Array.isArray(response)) {
      this.submittedByObject = response[0]
      this.contacts = response
    }
  }

  async autoLoginAwards(){
    let autoLoginUrl = await firstValueFrom(this.app.apiService.getCustomUrlAutoLogin())
    if(autoLoginUrl != undefined && autoLoginUrl != ""){
      let redirectUrl = `${AwardsRoutes.welcome}?${AwardsRoutesQueryParamsNames.folderGuid}=${this.folderGuid}`
      let encodedUrl = this.helperService.generateRedirectAndEncodeUri(autoLoginUrl, redirectUrl)
      window.open(encodedUrl, "_blank")
    }
  }

  previousPage(){
    this.app.router.navigate([AwardsRoutes.listBO])
  }
}
