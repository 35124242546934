import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Paginator } from 'primeng/paginator';
import { AppComponent } from 'src/app/app.component';
import { FOExternalPagesRoutes } from 'src/app/consts/fo-external-pages.routes';
import { FOSpeakersSearchLocalStorageKey } from 'src/app/consts/utils';
import { APPID } from 'src/app/enums/app_id';
import { ConferenceProgramCode } from 'src/app/enums/conference-program-code';
import { defaultAppGlobalConfig } from 'src/app/models/app-global-config';
import { ApiService } from 'src/app/services/api.service';
import { ConferenceStoreService } from 'src/app/services/conference-store.service';
import { SearchHelperService } from 'src/app/services/search-helper.service';

@Component({
  selector: 'app-welcome-conf-speaker',
  templateUrl: './welcome-conf-speaker.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './welcome-conf-speaker.component.less',
    './welcome-conf-speaker-responsive.component.less']
})
export class WelcomeConfSpeakerComponent implements OnInit {
  private _isPaginationInfoRestaured: boolean = false;
  public rowsPerPageOptions = defaultAppGlobalConfig.rowsPerPageOptions;
  @ViewChild('paginator') paginator?: Paginator;

  // first
  first: number = 0;

  // nbElementsPerPage
  private _nbElementsPerPage: number = defaultAppGlobalConfig.defaultRowsNumberPerPage;
  public get nbElementsPerPage(): number {
    return this._nbElementsPerPage;
  }

  public set nbElementsPerPage(value: number) {
    this._nbElementsPerPage = value;
    this.first = (this._pageIndex ?? 0) * (this.nbElementsPerPage ?? 1)
  }

  // pageIndex
  private _pageIndex: number = 0;
  public get pageIndex(): number {
    return this._pageIndex;
  }

  public set pageIndex(value: number) {
    this._pageIndex = value;
    this.first = (this._pageIndex ?? 0) * (this.nbElementsPerPage ?? 1)
  }

  // totalItems
  totalItems: number = 0;

  user: any = null;
  appID: any = null;
  welcomeObj: any = null;

  folderGuid: any;
  innovform: any;

  speakers!: any;
  search: any = {};
  currentlangue: any;
  interests: any;
  exhibitors: any;
  isEnabled: boolean = false;
  speakerslength: number = 0;

  showFilterMobile: boolean = false;

  isLargeScreen: boolean = true;
  isMinScreen: boolean = false;
  currentProgramCode: string = ConferenceProgramCode.PROG1;

  tabIndex = 0;
  activeTab = 0;

  screenWidth: number = 0;
  isDesktop: boolean = false;
  isMobile: boolean = false;

  speakerSearchResult: any = {};

  constructor(
    public app: AppComponent,
    private apiService: ApiService,
    public conferenceStore: ConferenceStoreService,
    private searchHelperService: SearchHelperService
  ) {
    this.initializeConfiguredOptions();
    this.screenWidth = window.innerWidth;
  }

  async ngOnInit() {

    this.onResize();

    this.app.route.queryParams.subscribe(params => {
      if (params && params['program'] && params['program'] != '') {
        this.currentProgramCode = params['program'];
        console.log(this.currentProgramCode);
      }
    });


    this.app.sharedTools.getJSON("general_data").subscribe((resG: any) => {

      this.appID = resG.APP_ID;
      this.currentlangue = this.app.translate.currentLang;
    });
    this.appID = APPID.Conf;

    //this.searchSpeakers(this.search);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 995) {
      this.isMobile = true;
      this.isLargeScreen = false;
      this.isMinScreen = true;
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
      this.isLargeScreen = true;
      this.isMinScreen = false;
      this.isMobile = false;
    }
  }

  // @HostListener('window:resize', ['$event'])
  // onResize() {
  //   this.screenWidth = window.innerWidth;
  //   if (this.screenWidth <= 1300) {
  //     this.isLargeScreen = false;
  //     this.isMinScreen = true;
  //   } else {
  //     this.isLargeScreen = true;
  //     this.isMinScreen = false;
  //   }
  // }

  //#region  GET
  onClearSearch(): void {
    this.pageIndex = 0;
    this.search = {};
    this.searchSpeakers(this.search);
  }

  onSearch(search: any): void{
    console.log('search from ', search);
    this.pageIndex = 0;

    this.searchSpeakers(search);
  }
  //#endregion

  //#region serach
  async searchSpeakers(search: any) {
    if (!this._isPaginationInfoRestaured) {
      this.restaurePaginatorInfo();
    }

    console.log("*** search ==> ", search);
    let searchTemp: any = cloneDeep(search);
    console.log("searchTemp ==> ", searchTemp);
    if (!searchTemp) {
      searchTemp = {};
    }
    this.search = searchTemp;
    try {
      // Begin Conf Prog
      this.search.ProgramCode = this.currentProgramCode;
      this.search.CurrentLanguage = this.app.translate.currentLang
      // end Conf Prog

      this.search.NbElementsPerPage = this.nbElementsPerPage;
      this.search.PageIndex = this.pageIndex;
      this.search.GetAll = false;

      // save latest search criteria
      this.searchHelperService.setAdvancedSearch(this.search, FOSpeakersSearchLocalStorageKey);

      console.log("searchTemp ==> ", this.pageIndex, this.search);
      const response = await this.apiService.searchSpeakers(this.search);
      this.speakerSearchResult = {};
      this.speakers = [];

      if (response) {
        this.speakerSearchResult = response;
        this.speakers = this.speakerSearchResult.ListSpeakers;
        this.speakerslength = this.speakerSearchResult.NbSpeakers;
        this.totalItems = this.speakerSearchResult.NbSpeakers;
        this.conferenceStore.confSpeakersIds = this.speakerSearchResult.AllIDs;
      }

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

    } catch (error) {
      console.error(error);
    }
  }
  //#endregion


  public handleSearchButtonClick(event: any): void {
    // Evol close search engine, when : on mobile device and clique on search button
    if (!this.isDesktop && this.isMobile && this.showFilterMobile) {
      this.toggleFilter();
    }
  }

  getDetail(speakerID: any) {
    let queryParams: any = {
      speakerID: speakerID
    };

    this.app.sharedTools.routeTo(FOExternalPagesRoutes.speakerDetail, queryParams);
    // this.app.router.navigate(['conf-speaker/'] ,{ queryParams: params });
  }

  switchHeaders(tabNumber: any) {
    this.activeTab = tabNumber.index;
  }

  isImageUrl(url: string) {
    let isValidUrl: boolean = false;

    if (url == null || url == undefined) {
      return false;
    }

    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const extension = url.substring(url.lastIndexOf('.')).toLowerCase();

    isValidUrl = imageExtensions.includes(extension);

    return isValidUrl;
  }

  toggleFilter() {
    this.showFilterMobile = !this.showFilterMobile;
    console.log("🚀   this.showFilterMobile:", this.showFilterMobile)
  }

  onPageChange(event: any) {
    console.log('event : ', event);
    this.pageIndex = event.page ?? 0;
    this.nbElementsPerPage = event.rows ?? defaultAppGlobalConfig.defaultRowsNumberPerPage;
    console.log('event : ', this.pageIndex, this.nbElementsPerPage);
    this.searchSpeakers(this.search);
  }

  private restaurePaginatorInfo(): void {
    let latestsearch: any = this.searchHelperService.getAdvancedSearch(FOSpeakersSearchLocalStorageKey);
    console.log('latestsearch ', latestsearch)

    if (latestsearch) {
      this.pageIndex = latestsearch.PageIndex ? latestsearch.PageIndex : 0;
      this.nbElementsPerPage = latestsearch.NbElementsPerPage ? latestsearch.NbElementsPerPage : defaultAppGlobalConfig.defaultRowsNumberPerPage;

      console.log(this.first, this._pageIndex, this._nbElementsPerPage);
    }

    this._isPaginationInfoRestaured = true;
  }

  private initializeConfiguredOptions(): void{
    this.rowsPerPageOptions = this.app.sharedTools.appGlobalConfig.rowsPerPageOptions;
    this.nbElementsPerPage = this.app.sharedTools.appGlobalConfig.defaultRowsNumberPerPage;
  }
}
