export const AwardsRoutes = {
    welcome: 'award/welcome',
    companyInfo: 'award/company-info',
    productList: 'award/products',
    generalities: 'award/generalities',
    description: 'award/description',
    recap: 'award/recap',
    listBO : 'award-bo/list',
    detailFiche : 'award-bo/fiche',
    parametrage : 'award-bo/parametrage'
}

export const AwardsRoutesQueryParamsNames = {
    folderGuid: 'folderguid',
    awardId: 'awardId'
}

export const AwardsRoutesStep = {
    generalities: 1,
    description: 2,
    recap: 3
}