import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Observable, Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { MediaActions } from 'src/app/enums/media-actions';
import { MediasDegRotation } from 'src/app/enums/media-rotation-deg';
import { MediasTypes } from 'src/app/enums/media-type';
import { MedisUnit } from 'src/app/enums/media-unit';
import { ImageDimension } from 'src/app/models/image-dimension';
import { MediaValueChange } from 'src/app/models/media-value-change';

@Component({
  selector: 'app-image-cmp',
  templateUrl: './image-cmp.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./image-cmp.component.less']
})
export class ImageCmpComponent implements OnInit {

  private _document: any;
  @Input() set document(value: any) {
    this._document = value;
    this.extractFileFromDoc();
  }

  get document(): any {
    return this._document;
  }

  //#region ViewChild
  @ViewChild('fileInput') fileInput?: FileUpload;
  // imageBase64: string | null = null;
  //#endregion

  @Output() selectedMediaFile  = new EventEmitter<any>();
  @Output() mediaValueChange = new EventEmitter<MediaValueChange>()

  @Input() submitEvent?: Observable<void>;
  submitEventSubscription?: Subscription;

  //#region INPUTS
  @Input() SolutionID!: number;
  @Input() mediaIndex?: number;
  @Input() mediaSettings: any;
  @Input() isLoadMediaSettings: boolean = false;;
  @Input() creditImage?: string | null;
  @Input() mediaIcon: any | null;
  @Input() mediaType?: any | null;

  @Input() showTitle?: boolean = true;
  @Input() showBorder?: boolean = true;
  @Input() showUpload?: boolean = true;
  @Input() showDownload?: boolean = true;
  @Input() showEmail?: boolean = false;
  @Input() showCredit?: boolean = true;
  @Input() showEye?: boolean = true;
  @Input() showExtraTitle?: boolean = true;

  @Input() showOtherInfo?: boolean = false;
  @Input() solutionName: string = "";
  @Input() relationName: string = "";
  @Input() companyName: string = "";

  @Input() photoUrl: string = "";

  @Input() canRotate: boolean = true;
  @Input() canClear: boolean = true;
  @Input() canDownload: boolean = true;
  //#endregion

  //#region Booleans
  isConformTodimension: boolean = false;
  isMaxDimension: boolean = false;
  isMinDimension: boolean = false;
  isMinHeight: boolean = false;
  isMaxHeight: boolean = false;
  isMinWidth: boolean = false;
  isMaxWidth: boolean = false;
  isMaxDepth: boolean = false;
  isMinDepth: boolean = false;
  isMaxMediaSize: boolean = false;
  isMinMediaSize: boolean = false;
  isEdit: boolean = false;
  isContainMediaFile: boolean = false;
  isValidSize: boolean = true;
  isMediaValidSize: boolean = true;
  isImage: boolean = false;
  isDownloadable: boolean = false;
  isUploaded: boolean = false;
  thereIsAMax: boolean | undefined;
  thereIsAMin: boolean | undefined;
  thereIsMaxSize: boolean | undefined;
  thereIsMinSize: boolean | undefined;
  thereIsMinDepth: boolean | undefined;
  thereIsMaxDepth: boolean | undefined;
  thereIsAMediaFormat: boolean | undefined;
  isConfirmToConvention: boolean = true; // assure that media confirm the size, dimension(W & H)...
  //#endregion


  //#region Strings
  fileType: string | undefined;
  mediaActions = MediaActions.HIDE;
  fileName: string | undefined;
  poids: string | undefined;
  selectedFileURL: string | null = null;
  selectedFileType: string | null = null;
  //#endregion

  //#region NUMBERS
  fileSize!: number;
  //#endregion

  //#region ENUMS
  rotationStyle = MediasDegRotation._0deg;
  medisUnit = MedisUnit;
  //#endregion

  //#region Media settings needs
  rotate: number = 0;
  documentType: string | undefined;
  maximumDepth: number = 0;
  minimumDepth: number = 0;
  maximumHeight: number = 0;
  minimumHeight: number = 0;
  maximumWidth: number = 0;
  minimumWidth: number = 0;
  maximumMediaSize!: number;
  minimumMediaSize!: number;
  //#endregion

  //#region Objects
  // imageDimensions: { width: number; height: number } = { width: 0, height: 0 };
  imageDimensions : any = new ImageDimension();
  imageDpi: any;
  selectedFile: any;
  //#endregion


  /**
   * Ctor
   */
  constructor(public app: AppComponent, private messageService: MessageService) {
  }

  /**
   * OnInit
   */
  ngOnInit() {

    console.log("Hi! this is app-image-cmp ------------------------------");

    this.mediaSettings;

    setTimeout(() => {
      this.getMediaSettings();

      if(this.photoUrl != null){
        this.selectedFileURL = this.photoUrl;
        this.mediaActions = MediaActions.SHOW;
        this.emitValueChangeInfo(true,true)
      }else {
        this.emitValueChangeInfo(false,false)
      }

    }, 300);

    this.getDocument();

    this.initBooleans();

    this.initCheckMediasTypes();

    this.initOnSubmitSubscription();

  }

  // Actionns ==================================================>
  onBrowseFileClick(): void {
    console.log('onBrowseFileClick')
    this.fileInput?.choose();
  }

  onFileSelect(event: any) {
    this.selectedFile = event.files[0];
    this.isContainMediaFile = true;
    console.log("🚀  [selectedFile:]", this.selectedFile);
    this.isEdit = true;
    console.log("🚀  isEditSelected:", this.isEdit)

    this.fileSize = this.byteToMB(this.selectedFile?.size);
    this.fileName = this.selectedFile?.name;
    this.mediaActions = MediaActions.SHOW;
    console.log("🚀  fileSize:", this.fileSize);

    if (this.selectedFile) {
      const fileNameParts = this.selectedFile.name.split('.');

      if (fileNameParts.length > 1) {

        const fileExtension = fileNameParts.pop()!.toLowerCase();

        // Check ImageExtension ==================================================>
        if (this.isImageExtension(fileExtension) === true) {
          console.log("🚀  this.isImageExtension(fileExtension):", this.isImageExtension(fileExtension))
          this.selectedFileType = MediasTypes.IMAGE;
          this.isImage = true;
          // this.isValidSize = (this.fileSize >= this.minimumMediaSize && this.fileSize <= this.maximumMediaSize) ? false : true;
          this.getImageDimensions(this.selectedFile);
        } else {
          this.onFileClear();
        }

      } else {
        this.selectedFileType = MediasTypes.UNKNOWN;
        this.isValidSize = false;
      }

    }

    console.log("<<<<--- FileTytpe :  ", this.fileType);
    console.log("<<<<--- SelectedFileType :  ", this.selectedFileType);
    console.log("<<<<--- SelectedFile.type :  ", this.selectedFile.type);

    const reader = new FileReader();
    reader.onload = () => {
      this.selectedFileURL = reader.result as string;
    };
    reader.readAsDataURL(this.selectedFile);

    this.emitSelectedMediaObj(this.selectedFile);
    if(this.selectedFile){
      this.emitValueChangeInfo(true);
    }
    else{
      this.emitValueChangeInfo(false);
    }

  }

  onFileClear() {
    console.log("🚀  clear image data");
    this.fileInput?.clear();
    this.fileName = "";
    this.imageDimensions.width = 0;
    this.imageDimensions.height = 0;
    this.selectedFileURL = null;
    this.rotate = 0;
    this.rotationStyle = MediasDegRotation._0deg;
    this.isConformTodimension = true;
    this.isMaxDimension = true;
    this.isMinDimension = true;
    this.selectedFile = null;
    this.isImage = false;
    this.isContainMediaFile = false;
    this.mediaActions = MediaActions.HIDE;
    this.creditImage = null;
    this.isValidSize = true;
    this.poids = "";
    this.isDownloadable = false;
    this.isUploaded = false;

    this.isEdit = false;
    this.isMediaValidSize = true;

    this.deleteDocument();

    this.document = null;

    this.emitSelectedMediaObj(this.selectedFile);
    this.emitValueChangeInfo(false);
  }

  //#region API ==================================================
  // UPLOAD MEDIA --------------------------------------------------
  private uploadMedia(sens: number) {

    if(!this.isConformTodimension || !this.isMediaValidSize){
      return;
    }
    let result: any;

    // ---------- IMAGE ----------
    // if (this.isImage && this.mediaType == MediasTypes.IMAGE) {
    console.log("🚀  isImage:", this.isImage);
    console.log("🚀 SelectedFile : ", this.selectedFile);

    let mediaObj = {
      SolutionID: this.SolutionID,
      DocID: this.document?.DocID,
      Rotation: sens,
      CreditImage: this.creditImage,
      ViewOrder: this.mediaIndex,
      OnSelect: this.isEdit
    };
    console.log("🚀  mediaObj:", mediaObj);

    let name: string;

    if (this.document != null) {
      name = this.document?.DocName;
      console.log("🚀  DocName:", name);
    } else {
      name = this.selectedFile.name;
    }

    if (this.isEdit) {
      result = this.app.apiService.uploadImage(mediaObj, name, this.selectedFile).subscribe({
        next: (res) => {
          console.log("🚀  res:", res);
          if (res.Flag_widthError || res.Flag_heighError || res.Flag_SizeError || res.Flag_DpiError) {
            this.isUploaded = false;
            console.log("🚀  this.isUploaded = false:", this.isUploaded);
          } else {
            this.isUploaded = true;
            console.log("🚀  this.isUploaded = true:", this.isUploaded)
          }
        },
        error: (err) => {
          console.log("🚀  err:", err);
        }
      });
    } else {
      result = this.app.apiService.uploadImage(mediaObj, "", new Blob()).subscribe({
        next: (res) => {
          console.log("🚀  res:", res);
          this.isUploaded = true;
          console.log("🚀  this.isUploaded = false:", this.isUploaded);
        },
        error: (err) => {
          this.isUploaded = false;
          console.log("🚀  err:", err);
        }
      });
    }
    console.log("🚀  Image isEdit : ", this.isEdit);
    // }

    // else result = null;

    console.log(">>>>>> DocID : ", this.document?.DocID);

    return result;
  }

  // DOWNLOAD MEDIA --------------------------------------------------
  downloadMedia() {
    return this.app.apiService.downloadImageMedia(this.document?.DocID).subscribe({
      next: (res) => {
        let imageData = "data:image/*;base64," + res;
        var link = document.createElement("a")
        link.setAttribute("href", imageData);
        link.setAttribute("download", this.document.DocName);
        link.click();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  // DELETE MEDIA _--------------------------------------------------
  private deleteDocument() {
    let mediaObj = {
      SolutionID: this.SolutionID,
      DocID: this.document?.DocID
    };
    // console.log("🚀 delete mediaObj:", mediaObj)

    if (this.document != null)
      this.app.apiService.deleteMedia(mediaObj).subscribe({
        next: (res) => {
          console.log("🚀  delete res:", res);
        },
        error: (err) => {
          console.log("🚀  delete err:", err);
        }
      });
  }
  //#endregion

  //#region Asyncs ==================================================>
  async onRotateRight() {
    this.rotate = (this.rotate + 3) % 4;
    await this.switchRotate(this.rotate, -1);
    this.isDownloadable = true;
    console.log("🚀  Right : ", this.rotate);
  }

  async onRotateLeft() {
    this.rotate = (this.rotate + 1) % 4;
    await this.switchRotate(this.rotate, 1);
    this.isDownloadable = true;
    console.log("🚀  Left : ", this.rotate);
  }
  async switchRotate(count: number, sens: number) {
    switch (count) {
      case 0:
        this.rotationStyle = MediasDegRotation._0deg;
        break;
      case 1:
        this.rotationStyle = MediasDegRotation._270deg;
        break;
      case 2:
        this.rotationStyle = MediasDegRotation._180deg;
        break;
      case 3:
        this.rotationStyle = MediasDegRotation._90deg;
        break;
      default:
        break;
    }

    await this.uploadMedia(sens);
    this.isEdit = false;
  }

  private async getDocument() {
    console.log("----- Document : ", this.document);

    if (this.document?.DocPath != null) {

      this.isDownloadable = true;

      this.selectedFileURL = this.document?.DocUrl;
      this.mediaActions = MediaActions.SHOW;

      this.fileName = this.document?.DocFilename;
      this.imageDimensions.width = this.document?.DocWidth;
      this.imageDimensions.height = this.document?.DocHeight;
      this.imageDpi = this.document?.DocDepth;

      if (this.document.typeDocument?.TypeDOCCode == MediasTypes.IMAGE) {
        this.isImage = true;
        this.creditImage = this.document?.DocCredit;
        this.isEdit = false;
      }
    }
  }
  //#endregion

  //#region Privates ==================================================>
  private initBooleans() {

    this.isConformTodimension = true;

    this.isMaxDimension = true;
    this.isMinDimension = true;

    this.isMinHeight = true;
    this.isMaxHeight = true;

    this.isMinWidth = true;
    this.isMaxWidth = true;

    this.isMaxDepth = true;
    this.isMinDepth = true;

    this.isMaxMediaSize = true;
    this.isMinMediaSize = true;

    this.isEdit = false;
  }

  private initCheckMediasTypes() {
    if (this.mediaType == MediasTypes.IMAGE) {
      this.mediaIcon = 'pi pi-image';
      this.fileType = "image/*";
    }
    
    if (this.mediaType == MediasTypes.PHOTOID) {
      this.mediaIcon = 'pi pi-image';
      this.fileType = "image/*";
    }

  }

  private extractFileFromDoc(): void {
    let fileInput: any = document.getElementById('fileInput');
    console.log('fileInput ', fileInput)
  }

  private getImageDimensions(file: File) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = () => {
        this.imageDimensions.width = img.width;
        this.imageDimensions.height = img.height;

        this.isConformTodimension = ((this.imageDimensions.width <= this.maximumWidth) &&
          (this.imageDimensions.height <= this.maximumHeight) &&
          (this.imageDimensions.width >= this.minimumWidth) &&
          (this.imageDimensions.height >= this.minimumHeight));

        this.getMediaConfigsMaxMin();
      };
    };

    reader.readAsDataURL(file);
  }

  private getMediaConfigsMaxMin() {

    this.isMinWidth = (this.imageDimensions.width < this.minimumWidth);
    this.isMinHeight = (this.imageDimensions.height < this.minimumHeight);

    this.isMaxWidth = (this.imageDimensions.width > this.maximumWidth);
    this.isMaxHeight = (this.imageDimensions.height > this.maximumHeight);

    this.isMediaValidSize = this.byteToMB(this.selectedFile.size) <= this.maximumMediaSize && this.byteToMB(this.selectedFile.size) >= this.minimumMediaSize;
    console.log(">>> isValidSize:", this.isMediaValidSize);

    this.isMaxMediaSize = this.byteToMB(this.selectedFile.size) > this.maximumMediaSize;
    this.isMinMediaSize = this.byteToMB(this.selectedFile.size) < this.minimumMediaSize;
  }


  private getMediaSettings() {
    if (this.isLoadMediaSettings) {
      console.log("<<<  IMAGE mediaSettings:", this.mediaSettings);
      this.documentType = this.mediaSettings?.DocumentType;
      this.maximumDepth = this.mediaSettings?.MaximumDepth;
      this.maximumHeight = this.mediaSettings?.MaximumHeight;
      this.maximumMediaSize = this.mediaSettings?.MaximumMediaSize;
      this.maximumWidth = this.mediaSettings?.MaximumWidth;
      this.minimumDepth = this.mediaSettings?.MinimumDepth;
      this.minimumHeight = this.mediaSettings?.MinimumHeight;
      this.minimumMediaSize = this.mediaSettings?.MinimumMediaSize;
      this.minimumWidth = this.mediaSettings?.MinimumWidth;
    }

    this.thereIsAMediaFormat = this.documentType != null && this.documentType != '' ? true : false;
    this.thereIsAMax = (this.maximumWidth != 0 && this.maximumWidth != null) && (this.maximumHeight != 0 && this.maximumHeight != null) ? true : false;
    this.thereIsAMin = (this.minimumWidth != 0 && this.minimumWidth != null) && (this.minimumHeight != 0 && this.minimumHeight != null) ? true : false;
    this.thereIsMaxSize = this.maximumMediaSize != 0 && this.maximumMediaSize !== null ? true : false;
    this.thereIsMinSize = this.minimumMediaSize != 0 && this.minimumMediaSize !== null ? true : false;
    this.thereIsMaxDepth = this.maximumDepth != 0 && this.maximumDepth !== null ? true : false;
    this.thereIsMinDepth = this.minimumDepth != 0 && this.minimumDepth !== null ? true : false;
  }

  private isImageExtension(extension: string): boolean {
    // const imageExtensions = ["jpg", "jpeg", "png", "bmp"];
    const imageExtensions = this.documentType?.split(',') as Array<string>;

    console.log("< Image  imageExtensions:", imageExtensions);
    return imageExtensions.includes(extension);
  }

  private byteToMB(bytes: number): number {
    return (bytes / (1024 * 1024));
  }
  //#endregion

  //#region Submit, Subscribe and UnSubscribe Events
  private initOnSubmitSubscription(): void {
    if (this.submitEvent) {
      this.submitEventSubscription = this.submitEvent.subscribe(() => { this.uploadMedia(0); })
    }
  }

  private unsubscirbeOnSubmitSubscription(): void {
    if (this.submitEventSubscription) {
      this.submitEventSubscription.unsubscribe();
    }
  }
  //#endregion

  /**
 * OnDestroy
 */
  ngOnDestroy(): void {
    this.unsubscirbeOnSubmitSubscription();
  }

  emitSelectedMediaObj(selectedFile: any){
    this.selectedMediaFile.emit(selectedFile);
  }

  emitValueChangeInfo(haveMedia : boolean, isFileSelected: boolean = true) : void {
    let mediaValueChange: MediaValueChange = {
      Type: this.mediaType,
      ViewOrder: this.mediaIndex,
      HaveValue: haveMedia,
      IsFileSelected: isFileSelected
    }

    this.mediaValueChange.emit(mediaValueChange);   
}
}
