export const WebsiteRegex = /^(https?:\/\/(www\.)?|www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
export const EmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const PasswordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$%\^&\*]).{8,}$/;
export const NotLetterRegex = /[^a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\s]/g;
export const LettersOnlyRegex = /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\s]+$/;

export const FolderSearchLocalStorageKey = 'folders_latest_advanced_search';
export const UserSearchLocalStorageKey = 'users_latest_advanced_search';
export const RoleSearchLocalStorageKey = 'roles_latest_advanced_search';
export const GroupSearchLocalStorageKey = 'groups_latest_advanced_search';
export const VMUserSearchLocalStorageKey = 'vm-users_latest_advanced_search';
export const OtherVisitSearchLocalStorageKey = 'othervisit_latest_advanced_search';
export const VMFolderSearchLocalStorageKey = 'vm-folders_latest_advanced_search';
export const VMProgDemandeSearchLocalStorageKey = 'vm-prog-demandes_latest_advanced_search';
// export const VMGestionDOSearchLocalStorageKey = 'vm_gestion_do_latest_search';
export const InnovationAdvancedSearchLocalStorageKey = 'gestion_innov_advanced_search';
export const InnovationMediaLocalStorageKey = 'gestion_innov_media_advanced_search';
export const HelpedAdvancedSearchLocalStorageKey = 'gestion_helped_advanced_search';
export const HelpedMediaLocalStorageKey = 'gestion_helped_media_advanced_search';
export const DPAdvancedSearchLocalStorageKey = 'dp_advanced_search';
export const ListConfAdvancedSearchLocalStorageKey = 'list_conference_advanced_search';
export const ListNewsAdvancedSearchLocalStorageKey = 'list_news_advanced_search';
export const AdminDoAdvancedSearchLocalStorageKey = 'admin_do_advanced_search';
export const FOExhibitorsSearchLocalStorageKey = 'fo_exhibitors_advanced_search';
export const FOSolutionsSearchLocalStorageKey = 'fo_solutions_advanced_search';
export const FOConferencesSearchLocalStorageKey = 'fo_conferences_advanced_search';
export const FOSpeakersSearchLocalStorageKey = 'fo_speakers_advanced_search';
export const AttendeesAdvancedSearchLocalStorageKey = 'attendees_advanced_search';
export const AwardsAdvancedSearchLocalStorageKey = 'awards_advanced_search';
export const AwardsMediaLocalStorageKey = 'awards_media_advanced_search';

//#region methods
export function hasAnyNonNullProperty(obj: any): boolean {
    return Object.values(obj).some(value => value !== null && value !== undefined && value !== '');
}

export function arrayContains(array: any[], element: any): boolean {
    return array.indexOf(element) > -1;
}

export function arrayIntersection(array1: any[], array2: any[]): any[] {
    return array1.filter(value => array2.includes(value));
}

export function clearLeafProperties(jsonObject: any): any {
    if (Array.isArray(jsonObject)) {
        // If the object is an array, clear the leaf properties in each element
        for (let i = 0; i < jsonObject.length; i++) {
            jsonObject[i] = clearLeafProperties(jsonObject[i]);
        }
    } else if (typeof jsonObject === 'object' && jsonObject !== null) {
        // If the object is an object (not an array), clear the leaf properties in its properties
        for (let key in jsonObject) {
            jsonObject[key] = clearLeafProperties(jsonObject[key]);
        }
    } else {
        // Clear leaf property
        jsonObject = ''; // You can set it to an empty value if needed
    }

    return jsonObject;
}
//#endregion

export const CsvExportSameColumnSeparator = ' | ';
export const DefaultColor = '#000000';