import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { FicheOpenMode } from '../enums/fiche-open-mode';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormCode } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-form-produit-contact',
    templateUrl: './form-produit-contact.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./form-produit-contact.component.less']
})
export class FormProduitContactComponent {
    user: any = null;
    productId: any;
    InnovationID: any;
    paths = location.pathname.split('/');
    currentPath: any = null;
    isContactSelected: boolean = false
    isSubmitted: boolean = false
    formGroup !: FormGroup
    frmData : any

    constructor(
        public app: AppComponent, private formBuilder: FormBuilder
    ) {
        this.getFormInnovData();
        this.initFormGroup()
    }

    async getFrmInfo() {
        let request = { frmType: "" }
        request.frmType = FormCode.FRM_INNOV
        let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
        if (data != undefined && data.Id != undefined) {
            this.frmData = data
        }
    }

    async ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log("this.user ==> ", this.user);

        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }

        await this.getFrmInfo()

        this.app.route.queryParams
            .subscribe(params => {
                console.log('[params]', params);
                if (params && params['productid']) {
                    this.productId = params['productid'];
                }
            });

    }
    initFormGroup() {
        this.formGroup = this.formBuilder.group({
            AtLeadtOneAccueilDO: new FormControl(false, Validators.requiredTrue),
        })
    }

    onPreviousStep(): void {
        this.onPrevStep()
    }

    atLeadtOneAccueilDOIsSelectedHandler(value: any): void {
        let atLeadtOneAccueilDO: any = this.formGroup.get('AtLeadtOneAccueilDO');
        if (atLeadtOneAccueilDO) {
            atLeadtOneAccueilDO.setValue(value);
            atLeadtOneAccueilDO.updateValueAndValidity();
            this.formGroup.updateValueAndValidity();
        }
    }

    onCancelStep(): void {
        this.app.sharedTools.goTo('/products-innov')
    }

    onSubmit(): void {
        this.isSubmitted = true
        if (this.formGroup.invalid) {
            const invalidValues: any = {};
            const controls = this.formGroup.controls;

            for (const controlName in controls) {
                if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                    invalidValues[controlName] = controls[controlName].value;
                }
            }

            console.log("Invalid form values : ", invalidValues);
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
            }, () => { });
        }
        else {
            this.onNextStep()
        }
    }

    contactSelected(event: any) {
        this.isContactSelected = event
    }

    // onNextStep(): void {
    //     //extract mode from route (edit or consult)
    //     const url = this.app.router.url;
    //     const segments = url.split('/');
    //     const segmentWithQueryParams = segments[segments.length - 1];
    //     const mode = segmentWithQueryParams.split('?')[0];

    //     console.log('mode ', mode); // This should log 'consult' or 'edit'

    //     if(mode === FicheOpenMode.Consult || mode === FicheOpenMode.Edit){
    //         let queryparams: any = {
    //             productid: this.productId
    //         }

    //         this.app.sharedTools.redirectToWithParameters(`product-recapitulatif/${mode}` , queryparams);
    //     }  
    // }

    onNextStep(): void {
        let queryparams: any = {
            productid: this.productId
        }
        this.app.sharedTools.redirectToWithParameters(`product-recapitulatif/edit`, queryparams);
    }

    onPrevStep(): void {
        let queryparams: any = {
            productid: this.productId
        }
        this.app.sharedTools.redirectToWithParameters(`product-medias/edit`, queryparams);
    }

    private getFormInnovData(): void {
        let innovform: any = JSON.parse(sessionStorage.getItem('innov-form') ?? '{}');

        if (innovform) {
            this.InnovationID = innovform.InnoFormID;
            //this.folder = f18form.FolderID;
        }
    }



}
