import { CartGroup } from "./cart-group";

export class FullCart {
    FolderID?: number | null;
    SubTotal?: number | null;
    Taxes?: number | null;
    Total?: number | null;
    StatusID?: number | null;
    CartGroups?: CartGroup[] | null;
    GPRDAccepted?: boolean| null;
    ShareInfoAccepted?: boolean| null;
    CGVAccepted?: boolean| null;
    PaymentType?: string| null;
    InvoicePDF?: string| null;
    SignedPDF?: string| null;
    SignedPDFDateTime?: string| null;
}