import { Component, Input } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { FormCode } from 'src/app/enums/form-code';
import { MediasTypes } from 'src/app/enums/media-type';

@Component({
  selector: 'app-list-media',
  templateUrl: './list-media.component.html',
  styleUrls: ['./list-media.component.less']
})
export class ListMediaComponent {
  @Input() documents?: any[];
  @Input() SolutionID!: number;
  @Input() DocID!: number;
  @Input() formCode!: FormCode;

  mediasTypes = MediasTypes;

  maxLogos: number = 0;
  maxImages: number = 0;
  maxVideos: number = 0;
  maxFiles: number = 0;

  logos: any[] = Array(this.maxLogos);
  images: any[] = Array(this.maxImages);
  videos: any[] = Array(this.maxVideos);
  files: any[] = Array(this.maxFiles);

  

  constructor(public app: AppComponent) { }

  ngOnInit() {
    this.getMediaConfigs();
  }

  getMediaConfigs() {
    return this.app.apiService.getMediaConfigs().subscribe({
      next: (res) => {

          this.maxLogos = res.MaxLogos;
          this.maxImages = res.MaxImages;
          this.maxVideos = res.MaxVideos;
          this.maxFiles = res.MaxFiles;
        
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}

