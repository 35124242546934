import { Component, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { Menu } from 'primeng/menu';
import { Table } from 'primeng/table';
import { VMFolderSearchLocalStorageKey, hasAnyNonNullProperty } from '../consts/utils';
import { cloneDeep } from 'lodash';
import { SearchVmFolder } from '../models/search-vm-folder';
import { FolderSearchHelperService } from '../services/folder-search-helper.service';
import { StatusType } from '../enums/status-type';
import { FolderStatus } from '../enums/folder-status';
import { SearchHelperService } from '../services/search-helper.service';
import { environment } from 'src/environments/environment';
import { DateHelper } from '../helpers/date.helper';
import { ExtendedCountries } from '../enums/extended-countries';
import { HelperService } from '../services/helper.service';
import { TranslatePipe } from '@ngx-translate/core';


@Component({
  selector: 'app-gestion-vm-exposant',
  templateUrl: './gestion-vm-exposant.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./gestion-vm-exposant.component.less']
})
export class GestionVmExposantComponent implements OnDestroy {
    //#region local properties
    //subscriptions
    private foldersExportSubscription?: Subscription;
    private companyAutocompleteSubscription?: Subscription;

    @ViewChild('menuAction') menuAction!: Menu;
    @ViewChild('dtFolders') dtFolders!: Table;
    @ViewChild('dvFolders') dvFolders!: DataView;

    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    } 

    layout: any = 'grid';
    groupedActions: any;
    selectActiveInactiveOptions: any;
    formPriorities: any;

    showTable: boolean = true;
    rangeDates: Date[] = [];
    maxDate = new Date();
    search: any = new SearchVmFolder();
    showAdvancedSearch: boolean = false;
    fastFiltersFolder: any = ['NumExposant', 'CompanyName2', 'ContactName', 'ContactEmail', 'ContactPhone', 'Surface', 'AmountHT'];
    folders: any = [];
    countries: any = []; 
    
    searchExport:any;
    status: any = [];
    country: any = [];
    federatorFolder: any = [];
    externalImportEnabled: any = [];
    priviGroupID: any = [];
    contactEmail: any = [];
    eligible: any = [];
    priority: any = [];
    nbVisitMiniReached: any = [];
    dateLastVisit: any = [];
    rightPrintPlan: any = [];
    isFederateur: any = [];
    isFedere: any = [];
    alreadyConnected: any = [];

    stats: any = [];
    eligibles: any = [];
    privies: any = [];
    priviesGroup: any = [];
    priorities: any = [];
    nbMinVisit: any = [];
    fedaratNames: any = [];

    categories: any = [];
    catTypeActivities: any = [];
    catTypeNature: any = [];
    catTypeStand: any = [];
    colsFolders: any = [];
    exportcolsFolders: any = [];
    items: any = [];
    selectedFolders: any = [];
    selectedAction: any;
    _selectedColumns: any = [];
    selectedEtat: any = [];
    federators: any[] = [];
    selectYesNoOptions: any;
    selectYesNoAllOptions: any;
    selectYesNoGroupOptions: any;
    selectYesNoWaitingOptions: any;
    

    companyNameSuggestions: string[] = [];

    fastSearchFolderKeyword: string = '';
    // unlockedCustomers: any = [];
    // lockedCustomers: any = [];
    //#endregion

    constructor(
        public app: AppComponent,
        public helperService: HelperService,
        private messageService: MessageService,
        private searchHelperService: SearchHelperService,
        private datePipe: DatePipe,
        public translatePipe : TranslatePipe
    ) { }

    ngOnDestroy(): void {
        this.unsubscribeActiveSubscriptions();
    }

    async onchangeStatus(_folder: any) {
        console.log("onchangeStatus folder ==> ", _folder);
        if (!_folder.selectedStatus) return;

        let objStatus: any = {
            StatusID: _folder.selectedStatus.StatusID,
            FolderID: _folder.FolderID
        }

        console.log("objStatus ==> ", objStatus);

        try {
            const response = await this.app.apiService.changestatus(objStatus);
            console.log("apiService.changestatus ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.getstats();
            this.searchFolders(this.search);

        } catch (error) {
            console.error(error);
        }
    }

    filterFast() {
        this.searchHelperService.setFastSearchKeyword(this.fastSearchFolderKeyword, VMFolderSearchLocalStorageKey) ;
        console.log("_value", this.fastSearchFolderKeyword);
        //this.dvFolders.filter(_value, 'contains');
        this.dtFolders.filterGlobal(this.fastSearchFolderKeyword, 'contains');
    }

    ngOnInit() {
        //get privious advanced search value before routing to other routes
        this.search = cloneDeep(this.searchHelperService.getAdvancedSearch(VMFolderSearchLocalStorageKey));
        this.fastSearchFolderKeyword = this.searchHelperService.getFastSearchKeyword(VMFolderSearchLocalStorageKey);
        let user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log(user);
        if (!user) {
            this.app.router.navigate(['/login']);
            return;
        }

        // this.colsFolders = this.app.translate.("racine.generalites.columns");
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG", resG);
                this.selectActiveInactiveOptions = resG.selectActiveInactiveOptions;
                this.formPriorities = resG.formPriorities;
                this.selectYesNoOptions = resG.selectYesNoOptions;
                this.selectYesNoAllOptions = resG.selectYesNoAllOptions;
                this.selectYesNoGroupOptions = resG.selectYesNoGroupOptions;
                this.selectYesNoWaitingOptions = resG.selectYesNoWaitingOptions;

                this.items = resG.actionsRowAdminExposant;

                this.items.forEach((itemMenu: any) => {
                    itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
                });

                this.colsFolders = resG.colsVmExposant;

                this._selectedColumns = this.colsFolders;
                this.exportcolsFolders = this._selectedColumns.map((col: any) => ({ title: col.header, dataKey: col.header }));
                this.getstats();
                this.getStatus();
                this.getCountries();
                this.getPriviGroups();
                this.getFederators();
                this.searchFolders(this.search, true);
            });
    }

    toggleLayout() {
        this.showTable = !this.showTable;
    }

    toggleMenu(event: any, formId: number, items: any) {
        this.menuAction.toggle(event);

        console.log("formId ID:", formId);
        console.log("items:", items);

        items.forEach((itemMenu: any) => {
            itemMenu.formIdRow = formId;
            itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

            if (itemMenu.ActionID == 1) {
                itemMenu.command = (event: any) => {
                    this.app.sharedTools.redirectTo('/detail-vm-exposant/' + event.item.formIdRow.toString());
                }
            }
            if (itemMenu.ActionID == 2) {
                itemMenu.command = (event: any) => {
                    this.consultDirectExhibitor(event.item.formIdRow);
                }
            }
            //TODO: @Why: this code commented by @Hassen, @Why: found only one action general_data.json property [actionsRow]
            //TODO: so added second action with ActionID == 2
            // if (itemMenu.ActionID == 2) {
            //     itemMenu.command = (event: any) => {
            //         this.download(event.item.folderIdRow);
            //     }
            // }
            // if (itemMenu.ActionID == 3) {
            //     itemMenu.command = (event: any) => {
            //         // ==> Call function
            //     }
            // }
            // if (itemMenu.ActionID == 2) {
            //     itemMenu.command = (event: any) => {
            //         this.app.sharedTools.redirectTo('/welcome-f18/' + event.item.formIdRow.toString());
            //         //this.consultDirectExhibitor(event.item.formIdRow);
            //     }
            // }
        });
    }

    onDateChange(ev: any) {
        if (!ev) return;
        // this.search.DateLastVisitMin = (ev[0]) ? ev[0] : this.search.DateLastVisitMin;
        // this.search.DateLastVisitMax = (ev[1]) ? ev[1] : this.search.DateLastVisitMax;
        this.search.DateLastVisitMin = (ev[0]) ? this.datePipe.transform(ev[0], DateHelper.ApiDateTimeFormat) : null ;
        this.search.DateLastVisitMax = (ev[1]) ? this.datePipe.transform(ev[1], DateHelper.ApiDateTimeFormat)  : null ;
        
        console.log("🚀  this.search.DateLastVisitMin:", this.search.DateLastVisitMin)
        console.log("🚀  this.search.DateLastVisitMax:", this.search.DateLastVisitMax)
    }

    onEligibleChange(event: any, folder: any) {
        if (!folder) return;
        folder.Eligible = event.value;
        this.changeEligibilityFolder(folder);
    }

    onAllowRequestAfterDeadlineChange(event: any, folder: any) {
        if (!folder) return;
        folder.ImprPlanExh = event.value;
        this.changeactiveplanningFolder(folder);
    }

    onPriviGroupChange(folder: any) {
        if (!folder) return;
        this.changePrivgroupFolder(folder);
    }

    onBloqueStandChange(event: any, folder: any) {
        if (!folder) return;
        folder.BloqueStand = event.value;
        this.changeBloqueStandFolder(folder);
    }

    set selectedColumns(val: any[]) {
        // ==> restore original order
        this._selectedColumns = this.colsFolders.filter((col: any) => val.includes(col));
    }

    // TODO finir qaund il y aura tous les états
    getColorStatus(statusID: any) {
        let classColor = "";
        switch (statusID) {
            case 1:
                classColor = "color-dark-blue"
                break;

            case 2:
                classColor = "color-light-grey"
                break;

            case 3:
            case 4:
                classColor = "color-default"
                break;

            case 5:
                classColor = "color-dark-green"
                break;

            case 6:
                classColor = "color-lime-green"
                break;

            case 7:
                classColor = "color-red"
                break;
            default:
                classColor = "color-dark-blue"
                break;
        }
        return classColor;
    }

    goToPdf(_urlPathPdf: any) {
        if (!_urlPathPdf) return;
        window.open(_urlPathPdf);
    }

    getTheTooltip() {
        let infoBulle: string = "";
        infoBulle = '<span class="font-bold">' + this.app.translate.instant('bo.dossiers-dp.tooltipTotalFolders');

        if (this.stats && this.stats.length > 0) {
            let statTotals: any = this.stats.filter((st: any) => { return st.StatusID == -99 })[0] || null;
            if (statTotals) infoBulle += ' : ' + statTotals.NbFolders;

            infoBulle += '<ul class="pl-3">';
            this.stats.forEach((stat: any) => {
                if (stat.StatusID == -99) return;
                infoBulle += '<li class="white-space-nowrap">' + stat.NbFolders + ' x ' + this.app.sharedTools.getLabelSwitchLangByID(stat.StatusID, 'StatusID', this.status) + '</li>'
            });
            infoBulle += '</ul>';
        }
        return infoBulle;
    }

    getstats() {
        this.app.apiService.getstats().subscribe((data: any) => {
            console.log("getstats data ==>", data);
            this.stats = cloneDeep(data);
        })
    }

    getStatus() {
        this.app.apiService.getStatus().subscribe((data: any) => {
            console.log("getStatus ==> ", data);
            this.status = cloneDeep(data);

            this.status = this.status.filter((s: any) => s.StatusType === StatusType.FolderState)
        });
    }


    
    async getPriviGroups() {
        try {
            const response = await this.app.apiService.privigroupgetall();
            let priviGroups = response;
            if(priviGroups){
                this.priviesGroup = priviGroups.filter((prvGrp: any) => { return prvGrp});
            }

        } catch (error) {
            console.error(error);
        }
    }

    getCountries() {
        this.app.apiService.getExtendedCountries(ExtendedCountries.vm).subscribe((data: any) => {
            console.log("getCountries ==> ", data);
            this.countries = data; //cloneDeep(data);
        });
    }

    async searchFolders(search: any, thenFilterFast: boolean = false) {
        //save search to retrieve it when coming back to BO after redirect from BO
        this.searchHelperService.setAdvancedSearch(cloneDeep(search), VMFolderSearchLocalStorageKey);

        console.log("search ==> ", search);
        let searchTemp: any = cloneDeep(search);

        if (searchTemp.ExhibitorID) searchTemp.ExhibitorID = parseInt(searchTemp.ExhibitorID);
        if (searchTemp.selectedCountry) searchTemp.CountryID = searchTemp.selectedCountry.CountryID;
        if (searchTemp.selectedStatus) searchTemp.StatusID = searchTemp.selectedStatus.StatusID;
        if (searchTemp.selectedEligibles) searchTemp.Eligible = searchTemp.selectedEligibles.Value;
        if (searchTemp.selectedPriviGroups) searchTemp.PriviGroupID = searchTemp.selectedPriviGroups.PriviGroupID;
        if (searchTemp.selectedPriorities) searchTemp.Priority = searchTemp.selectedPriorities.Priority;
        if (searchTemp.selectedMinVisit) searchTemp.NbVisitMiniReached = searchTemp.selectedMinVisit.Value;
        if (searchTemp.selectedFederatFolder) searchTemp.FederatorFolderID = searchTemp.selectedFederatFolder.FederatorFolderID;
        if (searchTemp.selectedConnected) searchTemp.AlreadyConnected = searchTemp.selectedConnected.Value;
        if (searchTemp.selectedFederatores) searchTemp.IsFederator = searchTemp.selectedFederatores.Value;
        if (searchTemp.selectedFedere) searchTemp.IsFedere = searchTemp.selectedFedere.Value;
        if (searchTemp.selectedRightPrintPlan) searchTemp.ImprPlanOd = searchTemp.selectedRightPrintPlan.Value;

        if(searchTemp.selectedExternalImportBlocked == null || searchTemp.selectedExternalImportBlocked == undefined){
            searchTemp.ExternalImportEnabled = searchTemp.selectedExternalImportBlocked;
        }
        else{
            searchTemp.ExternalImportEnabled = !searchTemp.selectedExternalImportBlocked.Value;
        }
        
        if (searchTemp.selectedCategory) delete searchTemp.selectedCountry;
        if (searchTemp.selectedStatus) delete searchTemp.selectedStatus;
        if (searchTemp.selectedEligibles) delete searchTemp.selectedEligibles;
        if (searchTemp.selectedPriviGroups) delete searchTemp.selectedPriviGroups;
        if (searchTemp.selectedPriorities) delete searchTemp.selectedPriorities;
        if (searchTemp.selectedMinVisit) delete searchTemp.selectedMinVisit;
        if (searchTemp.selectedFederatFolder) delete searchTemp.selectedFederatFolder;
        if (searchTemp.selectedConnected) delete searchTemp.selectedConnected;
        if (searchTemp.selectedRightPrintPlan) delete searchTemp.selectedConnected;
        delete searchTemp.selectedExternalImportBlocked;
        if (searchTemp.selectedFederatores) delete searchTemp.selectedFederatores;
        if (searchTemp.selectedFedere) delete searchTemp.selectedFedere;
        if (searchTemp.selectedConnected) delete searchTemp.selectedConnected;
        
        this.searchExport=searchTemp;
        try {
            const response = await this.app.apiService.searchVmFolders(searchTemp);
            console.log("response searchadminExposant ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/'); }, () => { })
                return;
            }

            this.folders = cloneDeep(response.filter((fld: any) => { return fld.FolderNatureID != 8 }))//response; //cloneDeep(response); 

            setTimeout(() => {
                this.folders.forEach((folder: any) => {

                    folder.CreationDateFormat! = this.datePipe.transform(folder.CreationDate, 'dd/MM/yyyy HH:mm:ss');
                    if (this.fastFiltersFolder.indexOf('CreationDateFormat') == -1) this.fastFiltersFolder.push("CreationDateFormat");

                    folder.selectedStatus = this.status.filter((etat: any) => { return etat.StatusID == folder.StatusID })[0];
                    folder.selectedCountry = this.countries.filter((country: any) => { return country.CountryID == folder.CountryID })[0];
                    folder.selectedNature = (folder.FolderNatureID) ? this.catTypeNature.filter((cat: any) => { return cat.CategoryID == folder.FolderNatureID })[0] : null;
                    folder.selectedTypeStand = (folder.FolderStandTypeID) ? this.catTypeStand.filter((cat: any) => { return cat.CategoryID == folder.FolderStandTypeID })[0] : null;
                    folder.selected = false;
                    if (folder.Surface) folder.Surface = parseInt(folder.Surface);
                    //console.log("folder ==> ", folder);

                    // ==> On met ces champs dans la liste des filtres rapides. TODO: comment chercher dans les LinkedFolders
                    if (folder.selectedStatus) {
                        this.app.sharedTools.getLabelSwitchLang(folder.selectedStatus);
                        if (this.fastFiltersFolder.indexOf('selectedStatus.label') == -1) this.fastFiltersFolder.push("selectedStatus.label");
                    }
                    if (folder.selectedCountry) {
                        this.app.sharedTools.getLabelSwitchLang(folder.selectedCountry);
                        if (this.fastFiltersFolder.indexOf('selectedCountry.label') == -1) this.fastFiltersFolder.push("selectedCountry.label");
                    }
                    if (folder.selectedNature) {
                        this.app.sharedTools.getLabelSwitchLang(folder.selectedNature);
                        if (this.fastFiltersFolder.indexOf('selectedNature.label') == -1) this.fastFiltersFolder.push("selectedNature.label");
                    }
                    if (folder.selectedTypeStand) {
                        this.app.sharedTools.getLabelSwitchLang(folder.selectedTypeStand);
                        if (this.fastFiltersFolder.indexOf('selectedTypeStand.label') == -1) this.fastFiltersFolder.push("selectedTypeStand.label");
                    }

                    // if (folder.LinkedFolders.length > 0) {
                    //     folder.LinkedFolders.forEach((linkfldr: any, index: any) => {
                    //         if (index == 0) {
                    //             for (const key in linkfldr) {
                    //                 console.log("key ======================>", key)
                    //                 //if (this.fastFiltersFolder.indexOf(key) == -1) this.fastFiltersFolder.push(key);
                    //             }
                    //         }
                    //     });
                    // }
                });

                if (thenFilterFast) {
                    this.filterFast();
                    if (hasAnyNonNullProperty(this.search)) {
                        console.log("hasAnyNonNullProperty", hasAnyNonNullProperty(search))
                        this.initAdvancedSearch();
                        this.showAdvancedSearch = true;
                    }
                }
            }, 100);

        } catch (error) {
            console.error(error);
        }
    }

    clear(table: Table) {
        table.clear();
    }

    clearSearch() {
        console.log("clearSearch ==>");
        this.rangeDates = [];
        this.search = new SearchVmFolder();
        this.searchFolders(this.search);
        this.clearFastSearch();
    }

    clearFastSearch(): void {
        this.fastSearchFolderKeyword = '';
        this.filterFast();
    }

    onRowSelect(event: any) {
        console.log("event.data==>", event.data);
        console.log("selectedFolders==>", this.selectedFolders);
        //this.messageService.add({ severity: 'info', summary: 'Folder Selected', detail: event.data.FolderID });
    }

    goToDetail(_folderId: any) {
        console.log("[gotToDetail] ==> ", _folderId);
        this.app.sharedTools.redirectTo('/detail-vm-exposant/' + _folderId.toString());
    }

    mapColumn(){
        console.log(this.selectedColumns);
        console.log(this.folders);
        return this.folders.map((row: any) => {
            const filteredColumns: any = {};
            this.selectedColumns.forEach(column => {
            //   if(this.translalteService.currentLang === "fr"){
                switch (column.field) {
                    case "NumExposant": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.NumExposant ?? ''
                        break;
                    case "CompanyName2": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = `${row.CompanyName2 ?? ''} ${row.Contact?.FullName ?? ''} ${row.Contact?.MobilePrefix ?? ''} ${row.Contact?.MobileNumber ?? ''} ${row.Contact?.Contact?.Email ?? ''}`
                        break;
                    case "MeetingStand": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.MeetingPoint ?? ''
                        break;
                    case "Nbp1": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Nbp1 ?? ''
                        break;
                    case "Nbp2": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Nbp2 ?? ''
                        break;
                    case "Nbp3": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Nbp3 ?? ''
                        break;
                    case "PriviGroupID" : filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.priviesGroup.find((pg: any) => pg.PriviGroupID == row.PriviGroupID)?.PriviGroupName ?? ''
                        break;
                    case "Eligible" : filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(this.selectYesNoOptions.find((e: any) => e.Value == row.Eligible)) ;
                    break;
                    case "RightPlan" : filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(this.selectYesNoGroupOptions.find((e: any) => e.Value == row.RightPlan)) ;
                    break;
                    case "BloqueStand" : filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(this.selectYesNoGroupOptions.find((e: any) => e.Value == row.BloqueStand)) ;
                    break;
                    case "FldFedId" : filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Federator?.CompanyName2 ?? '' ;
                    break;
                    case "Activities" : {
                        let Activities = "";
                        if(row.FirstConnection === 1) {
                            Activities += `${this.translatePipe.transform("racine.generalites.firstconnection")} : ${this.datePipe.transform(row.FirstConnection, 'dd/MM/yyyy HH:mm:ss')} `
                        }
                        if(row.LastConnection === 4) {
                            Activities += `${this.translatePipe.transform("racine.generalites.lastconnection")} : ${this.datePipe.transform(row.LastConnection, 'dd/MM/yyyy HH:mm:ss')} `
                        }
                    }
                    break;
                
                    default:
                        break;
                }
            });
            return filteredColumns;
        })
    }

    exportExcel() {
        console.log('exportExcel !!!');
        this.helperService.exportExcel(this.folders, this.mapColumn(),'vm_exposants_export_' + new Date().toLocaleDateString(),true);
        
    }

    exportCSV(){
        this.helperService.exportCSV(this.folders, this.mapColumn(),'vm_exposants_export_' + new Date().toLocaleDateString(),true);
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        console.log('saveAsExcelFile !!!');

        // let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        // let EXCEL_EXTENSION = '.xlsx';
        // const data: Blob = new Blob([buffer], {
        //     type: EXCEL_TYPE
        // });
        // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    deleteFoldersSelected(_foldersSelected: any) {
        console.log('deleteFoldersSelected ==> ', _foldersSelected)
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteFolder'), 'AUTRE', 'confirm', () => {
            this.deleteFolders(_foldersSelected);
        }, () => {
            //this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
        });
    }

    deleteFolders(_foldersSelected: any) {
        console.log('deleteFolders TODO ==> ', _foldersSelected);
        //this.messageService.add({ severity: 'success', summary: 'Folder deleted successfully', detail: "You have accepted" });
    }

    onchangeSelectedAction(selectedAction: any) {
        console.log(selectedAction)
    }

    exportFoldersToCsv(): void {
        this.foldersExport();
    }

    onSearchCompanyName(event: any): void {
        let keyword: string | undefined = event.query;

        if (!keyword || keyword === '') {
            this.companyNameSuggestions = [];
        }
        else {
            this.refreshCompanyNameAutocomplete(keyword);
        }
    }


    //#region private methods
    private unsubscribeActiveSubscriptions(): void {
        if (this.foldersExportSubscription) {
            this.foldersExportSubscription.unsubscribe();
        }

        if (this.companyAutocompleteSubscription) {
            this.companyAutocompleteSubscription.unsubscribe();
        }
    }

    private foldersExport(): void {
        console.log("foldersExport======>",this.searchExport);
        this.foldersExportSubscription = this.app.apiService.exportEP(this.searchExport,environment.visitmanagerFolderCtrl.exportfolderslist).subscribe({
            next: (data: any) => {
                console.log('foldersExport', data);
                if (data) {
                    let filesUrl: string = data;

                    window.open(filesUrl);
                }

            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }

    private refreshCompanyNameAutocomplete(keyword: string): void {
        this.companyAutocompleteSubscription = this.app.apiService.companyAutocompleteVmFolder(keyword).subscribe({
            next: (data: any) => {
                if (data && data != '' && Array.isArray(data)) {
                    this.companyNameSuggestions = data;
                }
                else {
                    this.companyNameSuggestions = []
                }
            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }

   

     consultDirectExhibitor(formIdRow: any): void {
        this.app.apiService.getUrlAutologinForm18(formIdRow).subscribe({
            next: (data: any) => {
                console.log('[getUrlAutologin] API =>', data);

                if (data) {
                    if (data.Result) {
                        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                        return;
                    }
                    window.open(data);
                }
            },
            error: (error: any) => {
                console.log(error);
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_ERROR'), 'KO', "alert", () => { }, () => { });
                return;
            }
        });
    }

    private initAdvancedSearch(): void {
        this.search = cloneDeep(this.searchHelperService.getAdvancedSearch(VMFolderSearchLocalStorageKey));
        let findSelectedStatus = this.status.find((c: any) => c.StatusID === this.search.selectedStatus?.StatusID);
        let findSelectedCountry = this.countries.find((c: any) => c.CountryID === this.search.selectedCountry?.CountryID);
        let findSelectedPriviGroup = this.priviesGroup.find((c: any) => c.PriviGroupID === this.search.selectedPriviGroups?.PriviGroupID);
        let findSelectedContactEmail = this.contactEmail.find((c: any) => c.ContactEmail === this.search.ContactEmail);
        let findSelectedEligible = this.eligible.find((c: any) => c.Eligible === this.search.eligible);
        let findSelectedPriority = this.formPriorities.find((c: any) => c.EtatID === this.search.selectedPriorities?.EtatID);
        let findSelectedNbVisitMiniReached = this.nbVisitMiniReached.find((c:any) => c.VisitMiniReached === this.search.VisitMiniReached);
        let findSelectedDateLastVisit = this.dateLastVisit.find((c:any) => c.DateLastVisit === this.search.DateLastVisit);
        let findSelectedRightPrintPlan = this.rightPrintPlan.find((c:any) => c.RightPrintPlan === this.search.ImprPlanOd);
        let findSelectedIsFederateur = this.isFederateur.find((c:any) => c.IsFederator === this.search.IsFederator);
        let findSelectedIsFedere = this.isFedere.find((c:any) => c.IsFedere === this.search.IsFedere);
        let findSelectedAlreadyConnected = this.alreadyConnected.find((c:any) => c.AlreadyConnected === this.search.AlreadyConnected);

        this.search.selectedStatus =  findSelectedStatus;
        this.search.selectedCountry =  findSelectedCountry;
        // this.search.selectedStatus =  findSelectedFederatorFolder;
        // this.search.selectedStatus =  findSelectedExternalImportEnabled;
        this.search.selectedPriviGroups =  findSelectedPriviGroup;
        this.search.ContactEmail =  findSelectedContactEmail;
        this.search.Eligible =  findSelectedEligible;
        this.search.selectedPriorities =  findSelectedPriority;
        this.search.NbVisitMiniReached =  findSelectedNbVisitMiniReached;
        this.search.DateLastVisit =  findSelectedDateLastVisit;
        this.search.ImprPlanOd =  findSelectedRightPrintPlan;
        this.search.IsFederator =  findSelectedIsFederateur;
        this.search.IsFedere =  findSelectedIsFedere;
        this.search.AlreadyConnected =  findSelectedAlreadyConnected;


        this.rangeDates = [];

        if (this.search.DateLastVisitMin) {
            this.rangeDates.push(new Date(this.search.DateLastVisitMin));
        }

        if (this.search.DateLastVisitMax) {
            this.rangeDates.push(new Date(this.search.DateLastVisitMax));
        }
    }
    
    async getFederators() {
        try {
            const response = await this.app.apiService.federatorListe();
            console.log("getFederators ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/'); }, () => { })
                return;
            }

            this.federators = cloneDeep(response); 

        } catch (error) {
            console.error(error);
        }
    }

    private async changeEligibilityFolder(folder: any) {
        let objRequest: any = {
            Eligible: folder.Eligible,
            FormID: folder.FormID
        }

        console.log("objRequest ==> ", objRequest);

        try {
            const response = await this.app.apiService.changeEligibilityVmFolder(objRequest);
            console.log("apiService.changeEligibilityVmFolder ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                , 'KO'
                , "alert"
                , () => { }
                , () => { });
                return;
            }
            
            this.searchFolders(this.search);

        } catch (error) {
            console.error(error);
            return;

        }
    }

    private async changePrivgroupFolder(folder: any) {
        let objRequest: any = {
            PriviGroupID: folder.PriviGroupID,
            FormID: folder.FormID
        }

        console.log("objRequest ==> ", objRequest);

        try {
            const response = await this.app.apiService.changePrivgroupVmFolder(objRequest);
            console.log("apiService.changePrivgroupVmFolder ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                , 'KO'
                , "alert"
                , () => { }
                , () => { });
                return;
            }
            
            this.searchFolders(this.search);

        } catch (error) {
            console.error(error);
            return;

        }
    }

    private async changeactiveplanningFolder(folder: any) {
        let objRequest: any = {
            AllowRequestAfterDeadline: folder.AllowRequestAfterDeadline,
            FormID: folder.FormID
        }

        console.log("objRequest ==> ", objRequest);

        try {
            const response = await this.app.apiService.changeactiveplanningVmFolder(objRequest);
            console.log("apiService.changeactiveplanningFolder ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                , 'KO'
                , "alert"
                , () => { }
                , () => { });
                return;
            }
            
            this.searchFolders(this.search);

        } catch (error) {
            console.error(error);
            return;

        }
    }

    private async changeBloqueStandFolder(folder: any) {
        let objRequest: any = {
            BloqueStand: folder.BloqueStand,
            FormID: folder.FormID
        }

        console.log("objRequest ==> ", objRequest);

        try {
            const response = await this.app.apiService.changeBloqueStandVmFolder(objRequest);
            console.log("apiService.changeBloqueStandVmFolder ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog(""
                , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
                , 'KO'
                , "alert"
                , () => { }
                , () => { });
                return;
            }
            
            this.searchFolders(this.search);

        } catch (error) {
            console.error(error);
            return;

        }
    }
    //#endregion
}
