import { Languages } from "../enums/language";

export class Product {
    ProductID?: number;
    ProductGroupID?: number;
    Code?: string | null;
    LabelEN?: string | null;
    LabelFR?: string | null;
    LabelES?: string | null;
    DescriptionEN?: string | null;
    DescriptionFR?: string | null;
    DescriptionES?: string | null;
    PhotoPath?: string | null;
    Price?: number;
    PromoPrice?: number;
    PromoStartDate?: string | null;
    PromoEndDate?: string | null;
    HelpTextEN?: string | null;
    HelpTextFR?: string | null;
    HelpTextES?: string | null;
    NameEN?: string;
    NameFR?: string;
    NameES?: string;
    MaxQuantity?: number;
    MinQuantity?: number;
    IsPromoAvailable?: boolean;
    ViewOrder?: any;
    Quantity?: number;

    public getLabel(language: string): string {
        switch (language) {
            case Languages.English: return this.LabelEN ?? '';
            case Languages.French: return this.LabelFR ?? '';
            case Languages.Spanish: return this.LabelES ?? '';
            default: return this.LabelEN ?? '';
        }
    }

    public getDescription(language: string): string {
        switch (language) {
            case Languages.English: return this.DescriptionEN ?? '';
            case Languages.French: return this.DescriptionFR ?? '';
            case Languages.Spanish: return this.DescriptionES ?? '';
            default: return this.DescriptionEN ?? '';
        }
    }

    public getHelpText(language: string): string {
        switch (language) {
            case Languages.English: return this.HelpTextEN ?? '';
            case Languages.French: return this.HelpTextFR ?? '';
            case Languages.Spanish: return this.HelpTextES ?? '';
            default: return this.HelpTextEN ?? '';
        }
    }

    public getName(language: string): string {
        switch (language) {
            case Languages.English: return this.NameEN ?? '';
            case Languages.French: return this.NameFR ?? '';
            case Languages.Spanish: return this.NameES ?? '';
            default: return this.NameEN ?? '';
        }
    }

    public getPrice(): number | undefined{
        return this.IsPromoAvailable ? this.PromoPrice : this.Price;
    }
}