export class SearchNews {
    DateMin?: string | null;
    DateMax?: string | null;
    LanguageList?: any[] | undefined;
    StatusID?: number | undefined;
    PublicationArea?: number[] | undefined;
    Categories ?:number[] | undefined

    //Added proprities
    Title: string | undefined;
    selectedRoleType : string | undefined ;
    selectedState : any | undefined ;
  }