import { Component, Input } from '@angular/core';
import { SharedtoolsService } from '../services/sharedtools.service';

@Component({
  selector: 'app-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.less']
})
export class FlagComponent {
  @Input() isoCode?: string;

  get flagUrl(): string | undefined {
    return this.sharedTools.getFlagUrl(this.isoCode);
  }

  constructor(public sharedTools: SharedtoolsService){

  }

}
