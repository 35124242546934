import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { IdentityManagerService } from '../services/identity-manager.service';
import { RoleTypeId } from '../enums/role-type-id';

@Component({
    selector: 'app-products-carousel',
    templateUrl: './products-carousel.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./products-carousel.component.less']
})
export class ProductsCarouselComponent {
    @Input() products: any[] = [];
    @Input() numbers: any;
    listInnov: any[] = [];
    formInnovID!: number;
    innoForm: any;
    IsRoleWriter:boolean=false;
    user: any;


    responsiveOptions: any[] = [
        {
            breakpoint: '1199px',
            numVisible: 2,
            numScroll: 1 
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    constructor(
        public app: AppComponent,
        private ims : IdentityManagerService
    ) { }

    ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
 
            });
        console.log("****this number", this.numbers)
        console.log(this.user.RoleID);
        if(this.ims.haveAuthorizedRole([RoleTypeId.INNOV_Writer])
        && !this.ims.haveAuthorizedRole([RoleTypeId.DP_SuperAdmin, RoleTypeId.INNOV_Administrator, RoleTypeId.INNOV_Exhibitor])){
            this.IsRoleWriter=true;
         }
        }


    async getInnoForm() {
        this.innoForm = await this.app.apiService.getAllProducts({ InoFormID: this.formInnovID });
        this.products = this.innoForm?.Solutions ? this.innoForm?.Solutions : [];
        

        //map other properties to product in products list (solutions)
         this.products.forEach((p: any) => {
        //     //this.mapSuppPropertiesToProduct(p);
         })

        
    }
}