import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { FormCode } from 'src/app/enums/form-code';
import { Router } from '@angular/router';
import { AwardsRoutes, AwardsRoutesQueryParamsNames } from '../awards.routes';

@Component({
  selector: 'app-form-awards-company-info',
  templateUrl: './form-awards-company-info.component.html',
  styleUrls: ['./form-awards-company-info.component.less']
})
export class FormAwardsCompanyInfoComponent {
  user: any = null;
  frmData: any;
  submitted: boolean = false;
  companyData: any
  initializedForm: boolean = false;
  companyForm!: FormGroup;
  awardId: any = null;
  folderGuid: any
  formData: any
  nextRoute: string = AwardsRoutes.generalities;
  awardFrmId: any;
  returnUrl ?: string

  constructor(public app: AppComponent, public formBuilder: FormBuilder, public router: Router) {
    this.getFormAwardData();
  }
  async ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('userData')!);
    console.log("this.user", this.user);
    if (!this.user) {
      this.app.router.navigate(['/login']);
      return;
    }
    await this.getFrmInfo();
    this.formData = JSON.parse(sessionStorage.getItem("award-form") ?? "")
    this.app.route.queryParams
    .subscribe(async params => {
        if (params && params[AwardsRoutesQueryParamsNames.folderGuid]) 
        {
          this.folderGuid = params[AwardsRoutesQueryParamsNames.folderGuid]
        }
        else {
          this.folderGuid = this.formData.FolderGuid
        }
        
    await this.getCompanyInfo();
    this.initForm();
    });
    
    this.checkAwardSolutions();
    this.returnUrl = `/${AwardsRoutes.welcome}?${AwardsRoutesQueryParamsNames.folderGuid}=${this.folderGuid}`
  }
  onPreviousStep() {
    let queryparams: any = {
      [AwardsRoutesQueryParamsNames.folderGuid]: this.folderGuid
    }
    this.router.navigate([AwardsRoutes.welcome], {
      queryParams: queryparams
    })
  }
  async getFrmInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_AWARD
    let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
    if (data != undefined && data.Id != undefined) {
      this.frmData = data
    }
  }
  async getCompanyInfo() {
    this.companyData = await this.app.apiService.getCompanyInfo(this.folderGuid);
    console.log(this.companyData)
  }
  initForm() {
    console.log(this.companyData)
    this.companyForm = this.formBuilder.group({
      Description: new FormControl(this.companyData?.Description ?? "", [Validators.required, Validators.maxLength(600)]),
    })
    this.initializedForm = true
  }

  async submit() {
    this.submitted = true;
    if (this.companyForm.invalid) {
      console.log("invalid form errors ===>", this.companyForm.errors);

    } else {
      let formValue = this.companyForm.getRawValue()
      let CompanyRequest = {
        FolderID: this.companyData.FolderID,
        Description: formValue.Description
      }
      let response = await this.app.apiService.updateCompany(CompanyRequest);
      if (!response.error) {

        this.onNextStep();
      }
    }
  }
  onNextStep(): void {
      let queryparams: any = {
      };

      if(this.awardId){
        queryparams[AwardsRoutesQueryParamsNames.awardId] = this.awardId;
      }

      this.app.sharedTools.redirectToWithParameters(this.nextRoute, queryparams);
    
  }  

  private async checkAwardSolutions(): Promise<void> {
    let response = await this.app.apiService.checkAwardSolutions(this.awardFrmId);

    if(!response || response.Result){
      console.log(response);
      return;
    }

    console.log(response)

    if(response.NbMaxProduct > 1){
      this.nextRoute = AwardsRoutes.productList;
    }
    else{
      if(response.FirstSolutionId){
        this.awardId = response.FirstSolutionId;
      }
    }
  }
  
  private getFormAwardData() {
    console.log(sessionStorage.getItem('award-form'))
    let awardform: any = JSON.parse(sessionStorage.getItem('award-form') ?? '{}');
    console.log(awardform)
    if (awardform && awardform.AwardFormID) {
      this.awardFrmId = awardform.AwardFormID;
    }
    console.log( this.awardFrmId)
  }
}
