import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// TODO commented when uninstall js pdf and its depencies
// keep it for developper information in case needeed to generate pdf client side
//import { jsPDF } from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(private http: HttpClient) { }

  getAutentication() {
    const user: any = JSON.parse(sessionStorage.getItem('userData')!);
    const JwtToken: any = (user) ? user.JwtToken : null;
    return JwtToken;
  }

  getPlanningDo(groupIdList: number[], includeNote: boolean, emails: string[], currentLanguage: string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.getAutentication()
    });
    let options = { headers: headers };

    return this.http.post<any>(environment.GroupCtrl.GeneratePlanningDoPdf, {
      groupIdList,
      includeNote,
      emails,
      currentLanguage
    }, options);
  }


  asyncGetPlanningDo(groupIdList: number[], includeNote: boolean, emails: string[], currentLanguage: string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.getAutentication()
    });
    let options = { headers: headers };

    return this.http.post<any>(environment.GroupCtrl.asyncGeneratePlanningDoPdf, {
      groupIdList,
      includeNote,
      emails,
      currentLanguage
    }, options);
  }

  getPlanningExp(FolderIdList: any[], includeNote: boolean, emails: string[], CurrentLanguage: string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.getAutentication()
    });
    let options = { headers: headers };
    return this.http.post<any>(environment.foldersCtrl.generatePlanningExposantPdf,
      { folderIdList: FolderIdList, emails: emails, currentLanguage: CurrentLanguage, IncludeNote: includeNote }
      , options);
  }

  downloadAsPdf(container: HTMLElement, fileName: string, emails: string[] | undefined) {
    
// TODO commented when uninstall js pdf and its depencies
// keep it for developper information in case needeed to generate pdf client side
    // const doc = new jsPDF('l', 'pt', 'a3');
    // doc.html(container, {
    //   callback: (pdf) => {
    //     pdf.save(fileName + '.pdf');
    //     const pdfData = pdf.output('dataurlstring');
    //     return this.http.post(environment.foldersCtrl.sendMailWithAttachment, { PDFData: pdfData, Emails: emails }).subscribe(res => {
    //       console.log(res)
    //     });
    //   },

    // });
  }

  asyncGeneratePlanningExhibitorPdf(FolderIdList : any[]
    , includeNote : boolean
    , emails : string[]
    , CurrentLanguage : string
    , sendPlanningsToAllExhibitors: boolean = false) : Observable<any>{
    let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });
    let options = { headers: headers };
    return this.http.post<any>(environment.GroupCtrl.asyncGeneratePlanningExhibitorPdf,
      { folderIdList: FolderIdList,emails: emails,currentLanguage:CurrentLanguage ,IncludeNote : includeNote, SendMailsToExhibitors: sendPlanningsToAllExhibitors}
      , options);
  }

  getPlanningDoAsync(folderId: any, emailList: any[], includeNote: boolean, currentlanguage: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.getAutentication()
    });
    let options = { headers: headers };

    return this.http.post<any>(environment.GroupCtrl.generatePlanningDoPdfAsync,{
      folderId : folderId,
      emails : emailList,
      includeNote : includeNote,
      currentlanguage : currentlanguage
    },options)
  }

  getPlanningExpXlsx(FolderId : any, includeNote: boolean, emails: string[], CurrentLanguage: string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.getAutentication()
    });
    let options = { headers: headers };
    return this.http.post<any>(environment.foldersCtrl.generatePlanningExposantXlsx,
      { folderId: FolderId, emails: emails, currentLanguage: CurrentLanguage, IncludeNote: includeNote }
      , options);
  }
}
