export class SearchFolder {
    public CountryID?: number | null = null;
    public CompanyName?: string | null = null;
    public ExhibitorID?: number | null = null;
    public StatusID?: number | null = null;
    public CategoryID?: number | null = null;
    public ExhibitorTypeID?: number | null = null;
    public StandTypeID?: number | null = null;
    public SubmissionDateStart?: Date | null = null;
    public SubmissionDateEnd?: Date | null = null;
    public selectedCountry?: any;
    public selectedStatus?: any;
    public selectedCategory?: any;
    public selectedNature?: any;
    public selectedTypeStand?: any;
    public getAllDP ?: boolean = false
}