import { Component } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { AwardsRoutesQueryParamsNames } from '../awards.routes';
import { StatusType } from 'src/app/enums/status-type';
import { StatusCode } from 'src/app/enums/status-code';

@Component({
  selector: 'app-steps-awards-form',
  templateUrl: './steps-awards-form.component.html',
  styleUrls: ['./steps-awards-form.component.less']
})
export class StepsAwardsFormComponent {
  steps: any = [];
  solutionId: any;
  public solutionLastStep = 0;
  currentRoute : string = '';
  solution: any;

  constructor(public app: AppComponent
  ) { }

  ngOnInit() {
    this.app.route.queryParams.subscribe(params => {
        if (params && params[AwardsRoutesQueryParamsNames.awardId]) {
          this.solutionId = params[AwardsRoutesQueryParamsNames.awardId];
        }
        this.currentRoute = this.app.router.url.split('?')[0];
        console.log(this.currentRoute);
        
      this.initialize();
      });
  }

  private async initialize(): Promise<any> {
    let generaldata : any = await firstValueFrom(this.app.sharedTools.getJSON('general_data'));
    this.steps = generaldata.stepsAwards;

    if (this.solutionId) {
      this.solution = await this.app.apiService.getAwardSolutionById(this.solutionId);
      console.log(this.solution)
      if (!this.solution || this.solution.Result) {
        this.app.confirm.confirmDialog(this.app.translate.instant('')
        , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + this.solution.Result)
        , 'KO'
        , "alert"
        , () => { }
        , () => { });

        return;
      }

      this.solutionLastStep = this.solution.SolutionLastStep ?? 0;
    }

    if(!this.solutionId || this.solution.Status.StatusType == StatusType.Award_Jury 
      && 
      (this.solution.Status.Code == StatusCode.DRAFT_FICHE || this.solution.Status.Code == StatusCode.TO_BE_SUBMIT))
      {
        this.steps.forEach((step: any) =>{
          step.completed = step.StepID <= this.solutionLastStep;
          step.canActivate = step.StepID <= (this.solutionLastStep + 1);
          
          step.active = step.routerLink == this.currentRoute;
        });
      }
      else{
        this.steps.forEach((step: any) =>{
          step.completed = step.StepID <= this.solutionLastStep;
          step.canActivate = false;
          step.active = step.routerLink == this.currentRoute;
        });
      }


    console.log(this.steps);
  }

  onStepClicked(step: any): void{
    if(!step.canActivate || step.active){
      return;
    }
    
    let queryparams: any = {
      awardId: this.solutionId
    }
    this.app.sharedTools.redirectToWithParameters(step.routerLink, queryparams);
  }
}
