import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-fo-intro',
    templateUrl: './fo-intro.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./fo-intro.component.less']
})

export class FoIntroComponent {
    user: any = null;

    constructor(
        public app: AppComponent,
    ) { }

    ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log("this.user ==> ", this.user);
        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }

        // this.app.sharedTools.changeUsrLangInInit();

        // this.app.sharedTools.getCurrentLanguage();
        
        sessionStorage.removeItem('folderStep');
        sessionStorage.removeItem('activeStep');
    }
}
