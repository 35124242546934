import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { TranslatePipe } from '@ngx-translate/core';
import { EmailRegex } from '../consts/utils';
import { cloneDeep } from 'lodash';
import { CategoryType } from '../enums/category-type';

@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./signature.component.less']
})
export class SignatureComponent implements OnInit, OnDestroy {
    //#region local properties
    //subscriptions
    private sendPdftosignSubscription?: Subscription;

    //current user
    user: any = null;

    // exposant direct
    folderId: any;
    folder: any;

    formSignature!: FormGroup;
    initForm: boolean = false;
    submitted: boolean = false;
    stepSignature: any = [];
    //#endregion

    constructor(public app: AppComponent,
        private messageService: MessageService,
        private translatePipe: TranslatePipe
    ) {
        //init user
        this.user = JSON.parse(sessionStorage.getItem('userData')!);

    }

    //#region handle lifecycle hooks

    ngOnInit() {

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                //console.log("resG", resG);
                this.stepSignature = resG.stepsSignature;
            });

        this.formSignature = new FormGroup({
            email: new FormControl(null, [Validators.required, Validators.pattern(EmailRegex)]),
        });

        this.getFolder(this.user.FolderID);
    }

    ngOnDestroy(): void {
        this.unsubscribeActiveSubscriptions();
    }
    //#endregion

    onSubmit() {
        this.submitted = true;
        // Logic to handle form submission
        if (this.formSignature.invalid) {
            console.log("invalid form");
            this.messageService.add({ severity: 'error', summary: this.app.translate.instant('racine.errorsOther.errorForm'), detail: this.app.translate.instant('racine.errorsOther.errorFormDetail') });
        } else {
            // Do submit logic
            this.sendPdftosign();
        }
    }


    private async getFolder(folderId: any) {

        try {
            const response = await this.app.apiService.getFolder(folderId);
            console.log("getFolder ==> ", response);
  
            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'pi pi-exclamation-triangle', "alert", () => {
                }, () => {
                })
                return;
            }
  
            this.folder = cloneDeep(response);

            this.initDefaultEmail();
        }
        catch (error) {
            console.error(error);
        }
    }
    //#region private methods
    private unsubscribeActiveSubscriptions(): void {
        if (this.sendPdftosignSubscription) {
            this.sendPdftosignSubscription.unsubscribe();
        }
    }

    private sendPdftosign(): void {
        let email: string = this.formSignature.get('email')?.value;
        if (email && email != '') {
            console.log('sendingcontrat', this.user.FolderID, email)
            this.sendPdftosignSubscription = this.app.apiService
                .sendPdftosign(this.user.FolderID, email).subscribe({
                    next: (data: any) => {
                        console.log("sendPdftosign API ==> ", data);

                        if (data && data.Result) {
                            this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => {}, () => { })
                            return;
                        }

                        this.app.sharedTools.goTo('/thanks');

                        // this.messageService.add({
                        //     severity: 'success'
                        //     , summary: this.translatePipe.transform('fo.signaturePage.requestSuccessHeader')
                        //     , detail: this.translatePipe.transform('fo.signaturePage.sendPdftosignSuccess')
                        // });
                    },
                    error: (error: any) => {
                        console.error(error);
                        // this.messageService.add({
                        //     severity: 'error'
                        //     , summary: this.translatePipe.transform('fo.signaturePage.requestErrorHeader')
                        //     , detail: this.translatePipe.transform('fo.signaturePage.sendPdftosignError')
                        // });
                    }
                });
        }
    }

    private initDefaultEmail(): void{

        let companyRespContact = this.folder?.Company?.Contacts?.find((c: any) => this.isCompanyRespContact(c));

        if(companyRespContact){
            console.log('companyRespContact', companyRespContact);
        
            //init default email
            let defaultEmail: string = companyRespContact.Email;
            this.formSignature.get('email')?.setValue(defaultEmail);
        }
    }

    private isCompanyRespContact(contact: any): boolean{
        let isCompanyRespContact: boolean = false;

        let findCompanyRespCategory = contact?.Categories?.find((c: any)=> c.CategoryType === CategoryType.CompanyResp);

        if(findCompanyRespCategory){
            isCompanyRespContact = true;
        }

        return isCompanyRespContact;
    }
    //#endregion


}
