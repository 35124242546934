export enum RoleTypeId {
    DP_SuperAdmin = 1,
    DP_Sales = 2,
    DP_Customer = 3,
    VM_Programmer = 8,
    VM_OZ = 9,
    VM_OA = 10,
    VM_Consultant = 11,
    VM_Exhibitor = 12,
    VM_Administrator = 13,
    HLP_Exhibitor = 14,
    HLP_Manager = 15,
    HLP_Administrator = 16,
    INNOV_Exhibitor = 17,
    INNOV_Manager = 18,
    INNOV_Administrator = 19,
    VM_Writer = 20,
    INNOV_Writer = 21,
    HLP_Writer = 22,
    CONF_Writer = 23,
    MODULE_TECH = 24,
    ADMIN_FINDERR = 25,
    MKT_Exhibitor = 26,
    MKT_Manager = 27,
    MKT_Administrator = 28,
    MKT_Writer = 29,
    ATT_Administrator = 30,
    ATT_Consultant = 31,
    ATT_Manager = 32,
    CONF_Administrator = 35,
    CONF_Consultant = 36,
    DP_Administrator = 37,
    NEWS_Administrator = 38,
    NEWS_Consultant = 39,
    NEWS_Writter = 40,
    PROG_Administrator = 41,
    PROG_Writter = 44,
    PROG_Exhibitor = 45,
    PROG_Manager = 47,
    CONF_Manager = 49,
    CONF_Exhibitor = 51,
    CP_Writer = 52,
    CP_Exhibitor = 53,
    CP_Administrator = 54,
    CP_Manager = 55,
    MKTSP_Administrator = 56,
    MKTSP_Writer = 57,
    MKTSP_Exhibitor = 58,
    MKTSP_Manager = 59,
    AWD_Exhibitor = 61,
    AWD_Manager = 62,
    AWD_Administrator = 63,
    AWD_Writer = 65
}

export const foRoles: number[] = [
    // Admin
    RoleTypeId.DP_SuperAdmin, RoleTypeId.ADMIN_FINDERR,

    // VM
    RoleTypeId.VM_Exhibitor, RoleTypeId.VM_Writer,

    // HELPED
    RoleTypeId.HLP_Exhibitor, RoleTypeId.HLP_Writer,

    // INNOVATION
    RoleTypeId.INNOV_Exhibitor, RoleTypeId.INNOV_Writer,

    // MARKETING
    RoleTypeId.MKT_Exhibitor, RoleTypeId.MKT_Writer,

    // PROG
    RoleTypeId.PROG_Exhibitor, RoleTypeId.PROG_Writter,

    // CONFERENCE
    RoleTypeId.CONF_Exhibitor, RoleTypeId.CONF_Writer];