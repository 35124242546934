import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { StatusType } from '../enums/status-type';
import { FormCode } from '../enums/form-code';

@Component({
  selector: 'app-parametrage-helped',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './parametrage-helped.component.html',
  styleUrls: ['./parametrage-helped.component.less']
})
export class ParametrageHelpedComponent  implements OnInit {

  eventName : string =""
  isReady: boolean = false
  status: any[] = []
  submitted: boolean = false
  data: any
  helpedFormGroup !: FormGroup
  frmType : string = ""
  receivedDataFromChildComponent : any
  newMaximumNumber : any
  content : string = ""
  showAlert : boolean = false 


  constructor(private _formBuilder: FormBuilder, private app: AppComponent) { }

  ngOnInit(): void {
    this.initFormGroupFields()
    this.getStatus()
    this.getHelpedInfo()
  }

  getHelpedInfo(){
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_HLP
    this.frmType = FormCode.FRM_HLP
    this.app.apiService.getEditionInfo(request).subscribe(
      data => {
        this.isReady = true
        console.log(data)
        if (data != undefined && data.Id != undefined) {
          this.data = data
          this.initFormGroupFields(data)
          this.getEventNameSwitchLang()
        }
      }
    )
  }

  getEventNameSwitchLang() {
    let currentLang = this.app.translate.currentLang
    if (this.data != undefined  && this.data.Result != "ERROR") {
      if (currentLang == "fr") {
        this.eventName = this.data.LabelFR
      } else if (currentLang == "en") {
        this.eventName = this.data.LabelEN
      } else {
        this.eventName = this.data.LabelES
      }
    }
  }

  initFormGroupFields(data?: any) {
    if (data != undefined) {
      this.helpedFormGroup = this._formBuilder.group({
        numberFicheMax: new FormControl(data.ChangeJobModeAt, Validators.required),
        activerEmails: new FormControl(data.ActiverEmails),
      })
    } else {
      this.helpedFormGroup = this._formBuilder.group({
        numberFicheMax: new FormControl(null, Validators.required),
        activerEmails: new FormControl(null),
      })
    }
  }


  getStatus() {
    this.app.apiService.getStatus().subscribe((data: any) => {
      if (this.status) {
        this.status = data.filter((s: any) => s.StatusType === StatusType.FormState);
      }
    });
  }



  submit($event: any) {
    this.submitted = true
    this.receivedDataFromChildComponent = $event
    console.log($event)
    if (this.helpedFormGroup.invalid) {
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
      }, () => { });
    }else {
      if (this.data?.Id != null && this.data?.Id != undefined) {
        this.newMaximumNumber = this.helpedFormGroup.get("numberFicheMax")?.value
        let getInvalidFormRequest: {
          frm_id: string,
          frm_type: string,
          newMaximumNumber: number
        } = {
          frm_id: this.data.Id,
          frm_type: this.frmType,
          newMaximumNumber: this.newMaximumNumber
        }
        this.app.apiService.getInvalidFormMaximumSheetNumber(getInvalidFormRequest).subscribe(
          data => {
            if (data && !data.Result && data > 0) {
              console.log(data)
              let firstPart = this.app.translate.instant('bo.parametrage.attention');
              let secondPart = this.app.translate.instant('bo.parametrage.alertInvalidFormMaximumSheet');
              let thirdPart = this.app.translate.instant("bo.parametrage.appliquerNombreAunouveauForms");
              let fourthPart = this.app.translate.instant("bo.parametrage.appliquerAuNouveaux");

              this.content = `${firstPart} ${data} ${secondPart} ${thirdPart} ${this.newMaximumNumber} ${fourthPart}`;
              this.showAlert = true
            }else if(data == 0) {
              this.updateParameters()
            }
          })
      }
      
    }
  }

  appliquerNouveau() {
    this.showAlert = false
    this.updateParameters()
  }

  appliquerTous() {
    let getInvalidFormRequest: {
      frm_id: string,
      frm_type: string,
      newMaximumNumber: number
    } = {
      frm_id: this.data.Id,
      frm_type: this.frmType,
      newMaximumNumber: this.newMaximumNumber
    }
    this.showAlert = false
    this.app.apiService.updateMaxNumberSheetFormsHelped(getInvalidFormRequest).subscribe(
      data => {
        console.log(data)
        this.updateParameters()
      }
    )
  }

  updateParameters(){
    let request = this.mapValueIntoObject()
      request = {
        ...request,
        ...this.receivedDataFromChildComponent
      }
      console.log(request)
      this.app.apiService.updateEditionFormHelpedInfo(request).subscribe(
        data => {
          console.log(data)
          if (data == true) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('bo.parametrage.successModification'), 'OK', "alert", () => {
              this.getHelpedInfo()
              this.submitted = false
            }, () => { })
          }
        }
      )
  }

  mapValueIntoObject() {
    return {
    ActiverEmails: this.helpedFormGroup.get("activerEmails")?.value,
    ChangeJobModeAt: this.helpedFormGroup.get("numberFicheMax")?.value,
    frmType: this.frmType
    }
}

  verifForm(){
    this.submitted = true
    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
    }, () => { });
  }

  annuler() {
    this.app.sharedTools.redirectTo('/');
  }

  DeleteDataHelp() {
    this.app.confirm.confirmDialog("", this.app.translate.instant('bo.parametrage.deleteConfirmMessage'),
      "AUTRE", "confirm", () => {
        let request = { frmType: "" }
        request.frmType = FormCode.FRM_HLP
        this.app.apiService.deleteAttachedEditionInfo(request).subscribe(
          data => {
            this.app.confirm.confirmDialog("", this.app.translate.instant('bo.parametrage.successDeletion'), 'OK', "alert", () => {
              this.getHelpedInfo()
            }, () => { })
          }
        )
      }, () => { })
  }

}
