import { Component, ViewEncapsulation, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, AbstractControl, ValidatorFn } from "@angular/forms";
import { TreeNode } from 'primeng/api';
import { AppComponent } from '../app.component';
import { Subscription, count } from "rxjs";
import { cloneDeep } from 'lodash';
import { MessageService } from 'primeng/api';
import { WebsiteRegex, EmailRegex, LettersOnlyRegex } from '../consts/utils';
import { CustomValidators } from '../validators/custom-validators';
import { Location } from '@angular/common';
//import { Folder } from '../models/folder';

@Component({
    selector: 'app-info-exposant',
    templateUrl: './info-exposant.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./info-exposant.component.less']
})
export class InfoExposantComponent {
    options: any = {
        types: ['geocode'],
    };

    user: any = null;
    folder: any = null;
    categories: any = [];
    nomenclatures!: TreeNode[]; // Initialize as null
    selectedNodes: any = [];
    countries: any = [];
    submitted: boolean = false;
    initForm: boolean = false;
    newLeadForm!: FormGroup;
    isAdresseManuelle: boolean = false;
    isAdresseFactuManuelle: boolean = false;
    civilities: any = [];
    phones: any = [];
    langues: any = [];
    functions: any = [];
    tree: any = {};
    otherFunction: any = {};

    //check if user redirected from BO ==> do not show steps 
    redirectedFromBO: boolean = false;

    //current FolderID 
    //can be from user if FO
    //can be from route query parameters if from BO
    folderId: any = null;
    valueChangesSubscription: Subscription | null = null;
    companyHaveOtherActivity: boolean = false;

    formatNbError: string = "";

    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private formBuilder: FormBuilder,
        private location: Location,
        private cdRef: ChangeDetectorRef
    ) { }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach((childNode) => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    async ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log("this.user ==> ", this.user);
        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }

        // this.app.sharedTools.changeUsrLangInInit();

        // this.app.sharedTools.getCurrentLanguage();

        this.findFolderID();

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG", resG);
                this.otherFunction = cloneDeep(resG.otherFunction);

                this.getPhones();
                this.getCategories();
                this.getFunctions();
                this.getCivilities();
                this.getCountries();
                this.getLanguages();

                setTimeout(() => {
                    this.getFolder(this.folderId);
                }, 300);
            });
    }

    // expandsNodes(nodePass: any, nodes: TreeNode[] = this.nomenclatures) {
    //     let parentKey: any = nodePass.parent ? nodePass.parent : null;
    //     console.log("parentKey", parentKey);

    //     try {
    //         for (const node of nodes) {
    //             if (node.key === parentKey) {
    //                 node.expanded = true;
    //                 console.log("node", node);
    //                 break;
    //             }
    //             if (node.parent) {
    //                 console.log('node', node)
    //                 this.expandsNodes(node, node.children);
    //             }

    //         }
    //     } catch (error) {
    //         console.warn(error);
    //     }
    // }

    selectNodesByData(data: any, nodes: TreeNode[] = this.nomenclatures) {
        //console.log("this.nomenclatures:", this.nomenclatures);
        //console.log("nodes", nodes);
        try {
            for (const node of nodes) {
                if (node.key === data) {
                    // if (node.parent) this.expandsNodes(node, nodes);
                    this.selectedNodes.push(node);
                    break;
                }
                if (node.children) {
                    this.selectNodesByData(data, node.children);
                }
            }
        } catch (error) {
            console.warn(error);
        }
    }

    onChangeCountry(_countryObj: any) {
        console.log('_countryObj', _countryObj);
        //console.log('this.phones', this.phones);

        if (!_countryObj) return;

        let getPrefix: any = this.phones.filter((prefix: any) => { return prefix.RelatedCountryID == _countryObj.CountryID })[0];
        console.log('getPrefix', getPrefix)

        if (getPrefix) {
            if (!this.newLeadForm.get('PhonePrefixSelected')?.value && !this.newLeadForm.get('PhoneNumber')?.value)
                this.newLeadForm.get('PhonePrefixSelected')?.setValue(getPrefix);

            if (!this.newLeadForm.get('LegalMobilePrefixSelected')?.value && !this.newLeadForm.get('Legal_MobileNumber')?.value)
                this.newLeadForm.get('LegalMobilePrefixSelected')?.setValue(getPrefix);

            if (!this.newLeadForm.get('DPPhonePrefixSelected')?.value && !this.newLeadForm.get('DP_PhoneNumber')?.value)
                this.newLeadForm.get('DPPhonePrefixSelected')?.setValue(getPrefix);

            if (!this.newLeadForm.get('DPMobilePrefixSelected')?.value && !this.newLeadForm.get('DP_MobileNumber')?.value)
                this.newLeadForm.get('DPMobilePrefixSelected')?.setValue(getPrefix);
        }
    }

    async getFolder(folderId: any) {
        try {
            const response = await this.app.apiService.getFolder(folderId);
            console.log("getFolder ==> ", response);

            let folder: any = cloneDeep(response);
            this.folder = cloneDeep(response);
            let adrPrincipale: any = null;
            let adrBilling: any = null;
            let IsBillingAddressSameAsPrincipal: boolean = false;
            let contactCompanyResp: any = null;
            let contactFolderResp: any = null;
            let IsDPManagerSameAsLegal: boolean = false;

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                }, () => {
                })
                return;
            }

            if (folder.Company) {
                folder.Company.Categories.forEach((cat: any) => {
                    //console.log('cat ==> ', cat);
                    setTimeout(() => {
                        this.selectNodesByData(cat.CategoryID, this.nomenclatures);
                    }, 500);
                });

                folder.Company.selectedPhonePrefix = (folder.Company.PhoneCodeIso) ? this.app.sharedTools.getObjByLabel(folder.Company.PhoneCodeIso, "CodeISO2", this.phones) : null;

                if (folder.Company.Contacts && folder.Company.Contacts.length > 0) {
                    folder.Company.Contacts.forEach((contact: any) => {
                        contact.selectedLanguage = (contact.LanguageID) ? this.app.sharedTools.getObjById(contact.LanguageID, "LanguageID", this.langues) : null;
                        contact.selectedCivility = (contact.CivilityID) ? this.app.sharedTools.getObjById(contact.CivilityID, "CivilityID", this.civilities) : null;
                        contact.selectedFunction = (contact.FunctionID) ? this.app.sharedTools.getObjById(contact.FunctionID, "FunctionID", this.functions) : null;
                        contact.selectedPhonePrefix = (contact.PhoneCodeIso) ? this.app.sharedTools.getObjByLabel(contact.PhoneCodeIso, "CodeISO2", this.phones) : null;
                        contact.selectedMobilePrefix = (contact.MobileCodeIso) ? this.app.sharedTools.getObjByLabel(contact.MobileCodeIso, "CodeISO2", this.phones) : null;

                        if (contact.Categories && contact.Categories.length > 0) {
                            contact.Categories.forEach((cat: any) => {
                                if (cat.CategoryType == "FOLDER_RESP") contactFolderResp = contact;
                                if (cat.CategoryType == "COMPANY_RESP") contactCompanyResp = contact;
                            });
                        }

                        // ==> On verifie que les deux contacts sont le meme
                        //let isSameContact: boolean = (contactFolderResp && contactCompanyResp && contactFolderResp.LastName == contactCompanyResp.LastName && contactFolderResp.Email == contactCompanyResp.Email) ? true : false;
                        IsDPManagerSameAsLegal = (contactFolderResp && contactCompanyResp && contactFolderResp.LastName == contactCompanyResp.LastName && contactFolderResp.Email == contactCompanyResp.Email) ? true : false;
                    });
                }

                if (folder.Company.Addresses && folder.Company.Addresses.length > 0) {

                    folder.Company.Addresses.forEach((adr: any) => {
                        setTimeout(() => {
                            adr.selectedCountry = (adr.CountryID) ? this.app.sharedTools.getObjById(adr.CountryID, "CountryID", this.countries) : null;
                        }, 500);
                    });

                    adrPrincipale = folder.Company.Addresses.filter((adr: any) => {
                        return adr.IsMainAddress;
                    })[0];
                    adrBilling = folder.Company.Addresses.filter((adr: any) => {
                        return adr.IsBillingAddress;
                    })[0];

                    this.isAdresseManuelle = (adrPrincipale && adrPrincipale.Line1) ? true : false;
                    this.isAdresseFactuManuelle = (adrBilling && adrBilling.Line1) ? true : false;

                    IsBillingAddressSameAsPrincipal = (adrBilling && adrBilling.Line1 && adrBilling.Line1 != adrPrincipale.Line1) ? false : true;
                }
            }

            setTimeout(() => {
                this.newLeadForm = this.formBuilder.group({
                    FolderID: [folder.FolderID, Validators.required],
                    CompanyName: [(folder.Company && folder.Company.CompanyName1) ? folder.Company.CompanyName1 : '', Validators.required],
                    CatalogName: [(folder.Company && folder.Company.CompanyName2) ? folder.Company.CompanyName2 : (folder.Company && folder.Company.CompanyName1 && folder.Company.CompanyName1.length < 17) ? folder.Company.CompanyName1 : '', Validators.required],
                    adresseComplete: [''], //Validators.required //TODELETE avant envoi
                    AddressLine1: [(adrPrincipale && adrPrincipale.Line1) ? adrPrincipale.Line1 : '', Validators.required],
                    AddressLine2: [(adrPrincipale && adrPrincipale.Line2) ? adrPrincipale.Line2 : ''],
                    AddressLine3: [(adrPrincipale && adrPrincipale.Line3) ? adrPrincipale.Line3 : ''],
                    ZipCode: [(adrPrincipale && adrPrincipale.ZipCode) ? adrPrincipale.ZipCode : '', Validators.required],
                    City: [(adrPrincipale && adrPrincipale.City) ? adrPrincipale.City : '', Validators.required],
                    CountryID: [(adrPrincipale && adrPrincipale.CountryID) ? adrPrincipale.CountryID : ''], //TOFORMAT from CountrySelected
                    CountrySelected: [(adrPrincipale && adrPrincipale.selectedCountry) ? adrPrincipale.selectedCountry : null, Validators.required], //TODELETE avant envoi
                    IsBillingAddressSameAsPrincipal: [IsBillingAddressSameAsPrincipal],
                    Email: [(folder.Company && folder.Company.Email) ? folder.Company.Email : '', [Validators.required, Validators.pattern(EmailRegex)]],
                    PhonePrefixSelected: [folder.Company.selectedPhonePrefix, Validators.required],
                    PhonePrefix: [(folder.Company && folder.Company.PhonePrefix) ? folder.Company.PhonePrefix : ''], //TOFORMAT  from PhonePrefixSelected
                    PhoneCodeIso: [(folder.Company && folder.Company.PhoneCodeIso) ? folder.Company.PhoneCodeIso : ''], //TOFORMAT  from PhonePrefixSelected
                    PhoneNumber: [(folder.Company && folder.Company.PhoneNumber) ? folder.Company.PhoneNumber : '', Validators.required],
                    Website: [(folder.Company && folder.Company.Website) ? folder.Company.Website : '', [Validators.pattern(WebsiteRegex)]],
                    areYouAnAssociationMember: [(folder.Company && folder.Company.LegalNumber3) ? true : false],
                    LegalNumber1: [(folder.Company && folder.Company.LegalNumber1) ? folder.Company.LegalNumber1 : ''],
                    LegalNumber2: [(folder.Company && folder.Company.LegalNumber2) ? folder.Company.LegalNumber2 : ''],
                    LegalNumber3: [(folder.Company && folder.Company.LegalNumber3) ? folder.Company.LegalNumber3 : ''],
                    LegalNumber4: [(folder.Company && folder.Company.LegalNumber4) ? folder.Company.LegalNumber4 : '', Validators.required],
                    LegalNumber5: [(folder.Company && folder.Company.LegalNumber5) ? folder.Company.LegalNumber5 : '', Validators.required],
                    Legal_CivilityID: [(contactCompanyResp && contactCompanyResp.CivilityID) ? contactCompanyResp.CivilityID : ''], //TOFORMAT  from selectedLegalCivility
                    selectedLegalCivility: [(contactCompanyResp && contactCompanyResp.selectedCivility) ? contactCompanyResp.selectedCivility : null, Validators.required], //TODELETE avant envoi
                    Legal_FirstName: [(contactCompanyResp && contactCompanyResp.FirstName) ? contactCompanyResp.FirstName : '', [Validators.required, Validators.pattern(LettersOnlyRegex)]],
                    Legal_LastName: [(contactCompanyResp && contactCompanyResp.LastName) ? contactCompanyResp.LastName : '', [Validators.required, Validators.pattern(LettersOnlyRegex)]],
                    Legal_FunctionID: [(contactCompanyResp && contactCompanyResp.FunctionID) ? contactCompanyResp.FunctionID : ''], //TOFORMAT  from selectedLegalFunction
                    selectedLegalFunction: [(contactCompanyResp && contactCompanyResp.selectedFunction) ? contactCompanyResp.selectedFunction : null, Validators.required],
                    Legal_OtherFunction: [(contactCompanyResp && contactCompanyResp.OtherFunction) ? contactCompanyResp.OtherFunction : ''], //TOFORMAT : Empty if Legal_FunctionID != -99
                    Legal_Email: [(contactCompanyResp && contactCompanyResp.Email) ? contactCompanyResp.Email : '', [Validators.required, Validators.pattern(EmailRegex)]],
                    LegalMobilePrefixSelected: [(contactCompanyResp && contactCompanyResp.selectedMobilePrefix) ? contactCompanyResp.selectedMobilePrefix : null, Validators.required],
                    Legal_MobilePrefix: [(contactCompanyResp && contactCompanyResp.MobilePrefix) ? contactCompanyResp.MobilePrefix : ''], //TOFORMAT  from LegalMobilePrefixSelected
                    Legal_MobileCodeIso: [(contactCompanyResp && contactCompanyResp.MobileCodeIso) ? contactCompanyResp.MobileCodeIso : ''], //TOFORMAT  from LegalMobilePrefixSelected
                    Legal_MobileNumber: [(contactCompanyResp && contactCompanyResp.MobileNumber) ? contactCompanyResp.MobileNumber : '', Validators.required],
                    selectedLegalLanguage: [(contactCompanyResp && contactCompanyResp.selectedLanguage) ? contactCompanyResp.selectedLanguage : null, Validators.required],
                    Legal_LanguageID: [(contactCompanyResp && contactCompanyResp.LanguageID) ? contactCompanyResp.LanguageID : ''], //TOFORMAT  from selectedLegalLanguage
                    IsDPManagerSameAsLegal: [IsDPManagerSameAsLegal],
                    // Actvities (list of CategoryID from Categories list with type "ACTIVITIES")
                    //  **************************************************** Optional :
                    DP_CivilityID: [(contactFolderResp && contactFolderResp.CivilityID) ? contactFolderResp.CivilityID : ''], //TOFORMAT  from selectedDPCivility
                    selectedDPCivility: [contactFolderResp.selectedCivility, Validators.required], //TODELETE avant envoi
                    DP_FirstName: [(contactFolderResp && contactFolderResp.FirstName) ? contactFolderResp.FirstName : '', [Validators.required, Validators.pattern(LettersOnlyRegex)]],
                    DP_LastName: [(contactFolderResp && contactFolderResp.LastName) ? contactFolderResp.LastName : '', [Validators.required, Validators.pattern(LettersOnlyRegex)]],
                    DP_FunctionID: [(contactFolderResp && contactFolderResp.FunctionID) ? contactFolderResp.FunctionID : ''], //TOFORMAT  from selectedDpFunction
                    selectedDpFunction: [contactFolderResp.selectedFunction, Validators.required],
                    DP_OtherFunction: [(contactFolderResp && contactFolderResp.OtherFunction) ? contactFolderResp.OtherFunction : ''], //TOFORMAT : Empty if DP_FunctionID != -99
                    DP_Email: [(contactFolderResp && contactFolderResp.Email) ? contactFolderResp.Email : '', [Validators.required, Validators.pattern(EmailRegex)]],
                    DPPhonePrefixSelected: [contactFolderResp.selectedPhonePrefix, Validators.required],
                    DP_PhonePrefix: [(contactFolderResp && contactFolderResp.PhonePrefix) ? contactFolderResp.PhonePrefix : ''], //TOFORMAT  from DPPhonePrefixSelected
                    DP_PhoneCodeIso: [(contactFolderResp && contactFolderResp.PhoneCodeIso) ? contactFolderResp.PhoneCodeIso : ''], //TOFORMAT  from DPPhonePrefixSelected
                    DP_PhoneNumber: [(contactFolderResp && contactFolderResp.PhoneNumber) ? contactFolderResp.PhoneNumber : '', Validators.required],
                    DPMobilePrefixSelected: [contactFolderResp.selectedMobilePrefix, Validators.required],
                    DP_MobilePrefix: [(contactFolderResp && contactFolderResp.MobilePrefix) ? contactFolderResp.MobilePrefix : ''], //TOFORMAT  from DPMobilePrefixSelected
                    DP_MobileCodeIso: [(contactFolderResp && contactFolderResp.MobileCodeIso) ? contactFolderResp.MobileCodeIso : ''], //TOFORMAT  from DPMobilePrefixSelected
                    DP_MobileNumber: [(contactFolderResp && contactFolderResp.MobileNumber) ? contactFolderResp.MobileNumber : '', Validators.required],
                    selectedDPLanguage: [contactFolderResp.selectedLanguage, Validators.required],
                    DP_LanguageID: [(contactFolderResp && contactFolderResp.LanguageID) ? contactFolderResp.LanguageID : ''], //TOFORMAT  from selectedDPLanguage
                    adresseFactuComplete: [''], //Validators.required //TODELETE avant envoi
                    Billing_AddressName: [(adrBilling && adrBilling.Alias) ? adrBilling.Alias : (folder.Company && folder.Company.CompanyName1) ? folder.Company.CompanyName1 : '', Validators.required],
                    Billing_AddressLine1: [(adrBilling && adrBilling.Line1) ? adrBilling.Line1 : '', Validators.required],
                    Billing_AddressLine2: [(adrBilling && adrBilling.Line2) ? adrBilling.Line2 : ''],
                    Billing_AddressLine3: [(adrBilling && adrBilling.Line3) ? adrBilling.Line3 : ''],
                    Billing_ZipCode: [(adrBilling && adrBilling.ZipCode) ? adrBilling.ZipCode : '', Validators.required],
                    Billing_City: [(adrBilling && adrBilling.City) ? adrBilling.City : '', Validators.required],
                    Billing_CountryID: [(adrBilling && adrBilling.CountryID) ? adrBilling.CountryID : ''], //TOFORMAT from Billing_CountrySelected
                    Billing_CountrySelected: [(adrBilling && adrBilling.selectedCountry) ? adrBilling.selectedCountry : null, Validators.required], //TODELETE avant envoi

                    Activities: this.formBuilder.array(
                        (folder?.Company?.Categories) ? folder.Company.Categories.map((c: any) => c.CategoryID) : []
                        //, CustomValidators.arrayLengthGreaterThanZeroValidator()
                    ),
                    OtherActivity: [(folder.Company && folder.Company.OtherActivity) ? folder.Company.OtherActivity : ''],
                }
                    //set formgroup validator
                    //this Validator was added to validate two form contols of exhibitor formgroup
                    //at least one activity must be provided
                    //either on Activities Control (array)
                    //either on OtherActivity Control(text input)
                    , { validator: CustomValidators.atLeastHaveOneActivityValidator() });

                this.companyHaveOtherActivity = folder?.Company?.OtherActivity && folder?.Company?.OtherActivity != '';

                console.log("this.newLeadForm", this.newLeadForm);

                setTimeout(() => {
                    this.setConditionalValidators();
                }, 100);

                this.initForm = true;
            }, 500);
        } catch (error) {
            console.error(error);
        }
    }

    onchangeSelect(val: any) {
        if (!val) return;
    }

    setConditionalValidators() {
        this.newLeadForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });

        const Legal_OtherFunction = this.newLeadForm.get('Legal_OtherFunction')!;
        console.log("Legal_OtherFunction ==> ", Legal_OtherFunction);

        this.newLeadForm.get('selectedLegalFunction')?.valueChanges
            .subscribe(selectedLegalFunction => {
                console.log("selectedLegalFunction", selectedLegalFunction);
                if (selectedLegalFunction && selectedLegalFunction.FunctionID == -99) {
                    Legal_OtherFunction.setValidators([Validators.required]);
                }
                else {
                    Legal_OtherFunction.setValidators(null);
                    Legal_OtherFunction.setValue('');
                }
                Legal_OtherFunction.updateValueAndValidity();
            });

        const DP_OtherFunction = this.newLeadForm.get('DP_OtherFunction')!;
        console.log("DP_OtherFunction ==> ", DP_OtherFunction);

        this.newLeadForm.get('selectedDpFunction')?.valueChanges
            .subscribe(selectedDpFunction => {
                console.log("selectedDpFunction", selectedDpFunction);
                if (selectedDpFunction && selectedDpFunction.FunctionID == -99) {
                    DP_OtherFunction.setValidators([Validators.required]);
                }
                else {
                    DP_OtherFunction.setValidators(null);
                    DP_OtherFunction.setValue('');
                }
                DP_OtherFunction.updateValueAndValidity();
            });

        const LegalNumber3 = this.newLeadForm.get('LegalNumber3')!;

        this.newLeadForm.get('areYouAnAssociationMember')?.valueChanges
            .subscribe(areYouAnAssociationMember => {
                console.log("areYouAnAssociationMember", areYouAnAssociationMember)

                if (areYouAnAssociationMember) {
                    LegalNumber3.setValidators([Validators.required]);
                } else {
                    LegalNumber3.setValidators(null);
                    LegalNumber3.setValue('');
                }
                LegalNumber3.updateValueAndValidity();
            });

        const Billing_AddressName = this.newLeadForm.get('Billing_AddressName')!;
        const Billing_AddressLine1 = this.newLeadForm.get('Billing_AddressLine1')!;
        const Billing_ZipCode = this.newLeadForm.get('Billing_ZipCode')!;
        const Billing_City = this.newLeadForm.get('Billing_City')!;
        const Billing_CountrySelected = this.newLeadForm.get('Billing_CountrySelected')!;
        const adresseFactuComplete = this.newLeadForm.get('adresseFactuComplete')!;

        this.newLeadForm.get('IsBillingAddressSameAsPrincipal')?.valueChanges
            .subscribe(IsBillingAddressSameAsPrincipal => {
                console.log("IsBillingAddressSameAsPrincipal", IsBillingAddressSameAsPrincipal);

                if (!IsBillingAddressSameAsPrincipal) {
                    Billing_AddressName.setValidators([Validators.required]);
                    Billing_AddressLine1.setValidators([Validators.required]);
                    Billing_ZipCode.setValidators([Validators.required]);
                    Billing_City.setValidators([Validators.required]);
                    Billing_CountrySelected.setValidators([Validators.required]);
                    this.cdRef.detectChanges();
                } else {
                    Billing_AddressName.setValidators(null);
                    Billing_AddressLine1.setValidators(null);
                    Billing_ZipCode.setValidators(null);
                    Billing_City.setValidators(null);
                    Billing_CountrySelected.setValidators(null);
                    adresseFactuComplete.setValidators(null);

                    Billing_AddressName.setValue('');
                    Billing_AddressLine1.setValue('');
                    Billing_ZipCode.setValue('');
                    Billing_City.setValue('');
                    Billing_CountrySelected.setValue(null);
                    adresseFactuComplete.setValue('');
                    this.cdRef.detectChanges();
                }
                Billing_AddressName.updateValueAndValidity();
                Billing_AddressLine1.updateValueAndValidity();
                Billing_ZipCode.updateValueAndValidity();
                Billing_City.updateValueAndValidity();
                Billing_CountrySelected.updateValueAndValidity();
            });

        const selectedDPCivility = this.newLeadForm.get('selectedDPCivility')!;
        const DP_FirstName = this.newLeadForm.get('DP_FirstName')!;
        const DP_LastName = this.newLeadForm.get('DP_LastName')!;
        const selectedDpFunction = this.newLeadForm.get('selectedDpFunction')!;
        const DP_Email = this.newLeadForm.get('DP_Email')!;
        const DPPhonePrefixSelected = this.newLeadForm.get('DPPhonePrefixSelected')!;
        const DP_PhoneNumber = this.newLeadForm.get('DP_PhoneNumber')!;
        const DPMobilePrefixSelected = this.newLeadForm.get('DPMobilePrefixSelected')!;
        const DP_MobileNumber = this.newLeadForm.get('DP_MobileNumber')!;
        const selectedDPLanguage = this.newLeadForm.get('selectedDPLanguage')!;

        this.newLeadForm.get('IsDPManagerSameAsLegal')?.valueChanges
            .subscribe(IsDPManagerSameAsLegal => {
                console.log("IsDPManagerSameAsLegal", IsDPManagerSameAsLegal)

                if (!IsDPManagerSameAsLegal) {
                    selectedDPCivility.setValidators([Validators.required]);
                    DP_FirstName.setValidators([Validators.required, Validators.pattern(LettersOnlyRegex)]);
                    DP_LastName.setValidators([Validators.required, Validators.pattern(LettersOnlyRegex)]);
                    selectedDpFunction.setValidators([Validators.required]);
                    DP_Email.setValidators([Validators.required]);
                    DPPhonePrefixSelected.setValidators([Validators.required]);
                    DP_PhoneNumber.setValidators([Validators.required]);
                    DPMobilePrefixSelected.setValidators([Validators.required]);
                    DP_MobileNumber.setValidators([Validators.required]);
                    selectedDPLanguage.setValidators([Validators.required]);
                } else {
                    selectedDPCivility.setValidators(null);
                    DP_FirstName.setValidators(null);
                    DP_LastName.setValidators(null);
                    selectedDpFunction.setValidators(null);
                    DP_Email.setValidators(null);
                    DPPhonePrefixSelected.setValidators(null);
                    DP_PhoneNumber.setValidators(null);
                    DPMobilePrefixSelected.setValidators(null);
                    DP_MobileNumber.setValidators(null);
                    selectedDPLanguage.setValidators(null);
                }
                selectedDPCivility.updateValueAndValidity();
                DP_FirstName.updateValueAndValidity();
                DP_LastName.updateValueAndValidity();
                selectedDpFunction.updateValueAndValidity();
                DP_Email.updateValueAndValidity();
                DPPhonePrefixSelected.updateValueAndValidity();
                DP_PhoneNumber.updateValueAndValidity();
                DPMobilePrefixSelected.updateValueAndValidity();
                DP_MobileNumber.updateValueAndValidity();
                selectedDPLanguage.updateValueAndValidity();
            });
    }

    onSubmit() {
        this.submitted = true;
        this.messageService.clear();

        let folder = this.newLeadForm.value;

        // Logic to handle form submission
        if (this.newLeadForm.invalid) {
            const invalidValues: any = {};
            const controls = this.newLeadForm.controls;

            let idNomenclatures = document.getElementById('nomenclatures');
            if (this.newLeadForm.errors?.['atLeastHaveOneActivityRequired']) idNomenclatures?.classList.add('ng-invalid');

            let invalidFields: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('ng-invalid') as HTMLCollectionOf<HTMLElement>;

            for (const controlName in controls) {
                if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                    invalidValues[controlName] = controls[controlName].value;
                }
            }

            console.log('Invalid form values ==> ', invalidValues);
            console.log('this.newLeadForm:', this.newLeadForm);

            if (invalidFields && invalidFields.length > 1) this.app.formInvalid.addSubmittedInvalidClass(invalidFields);
            this.messageService.add({ key: 'custom', severity: 'error', summary: this.app.translate.instant('racine.errorsOther.errorForm'), detail: '', sticky: true });
            // this.messageService.add({ severity: 'error', summary: this.app.translate.instant('racine.errorsOther.errorForm'), detail: this.app.translate.instant('racine.errorsOther.errorFormDetail') });
            // if (!folder.LegalNumber1 && !folder.LegalNumber2) this.messageService.add({ severity: 'info', summary: this.app.translate.instant('racine.errorsOther.errorForm'), detail: this.app.translate.instant('racine.errorsOther.errorFormNitTva') });
        }
        else {
            // Do submit logic
            console.log(this.newLeadForm);

            // ==> Format fields select dropdowns pour les id
            folder.CountryID = (folder.CountrySelected) ? folder.CountrySelected.CountryID : null;
            folder.PhonePrefix = (folder.PhonePrefixSelected) ? folder.PhonePrefixSelected.Prefix : null;
            folder.PhoneCodeIso = (folder.PhonePrefixSelected) ? folder.PhonePrefixSelected.CodeISO2 : null;
            folder.Legal_CivilityID = (folder.selectedLegalCivility) ? folder.selectedLegalCivility.CivilityID : null;
            folder.Legal_FunctionID = (folder.selectedLegalFunction) ? folder.selectedLegalFunction.FunctionID : null;
            folder.Legal_MobilePrefix = (folder.LegalMobilePrefixSelected) ? folder.LegalMobilePrefixSelected.Prefix : null;
            folder.Legal_MobileCodeIso = (folder.LegalMobilePrefixSelected) ? folder.LegalMobilePrefixSelected.CodeISO2 : null;
            folder.Legal_LanguageID = (folder.selectedLegalLanguage) ? folder.selectedLegalLanguage.LanguageID : null;
            folder.Legal_OtherFunction = (folder.Legal_FunctionID && folder.Legal_FunctionID != -99) ? '' : folder.Legal_OtherFunction;

            if (folder.IsBillingAddressSameAsPrincipal) {
                // ==> If is same address principal and billing, we delete the fields from billing address
                this.app.sharedTools.deleteParamsInObject(folder, [
                    'Billing_AddressName',
                    'Billing_AddressLine1',
                    'Billing_AddressLine2',
                    'Billing_AddressLine3',
                    'Billing_ZipCode',
                    'Billing_City',
                    'Billing_CountryID'
                ]);
            } else {
                // ==> If not, on save the Billing_CountryID
                folder.Billing_CountryID = (folder.Billing_CountrySelected) ? folder.Billing_CountrySelected.CountryID : null;
            }

            if (folder.IsDPManagerSameAsLegal) {
                // ==> If is same person legal and dp, we delete the optional fields from DP person
                this.app.sharedTools.deleteParamsInObject(folder, [
                    'DP_CivilityID',
                    'DP_FirstName',
                    'DP_LastName',
                    'DP_FunctionID',
                    'DP_OtherFunction',
                    'DP_Email',
                    'DP_PhonePrefix',
                    'DP_PhoneCodeIso',
                    'DP_PhoneNumber',
                    'DP_MobilePrefix',
                    'DP_MobileCodeIso',
                    'DP_MobileNumber',
                    'DP_LanguageID',
                ]);
            } else {
                folder.DP_CivilityID = (folder.selectedDPCivility) ? folder.selectedDPCivility.CivilityID : null;
                folder.DP_FunctionID = (folder.selectedDpFunction) ? folder.selectedDpFunction.FunctionID : null;
                folder.DP_PhonePrefix = (folder.DPPhonePrefixSelected) ? folder.DPPhonePrefixSelected.Prefix : null;
                folder.DP_PhoneCodeIso = (folder.DPPhonePrefixSelected) ? folder.DPPhonePrefixSelected.CodeISO2 : null;
                folder.DP_MobilePrefix = (folder.DPMobilePrefixSelected) ? folder.DPMobilePrefixSelected.Prefix : null;
                folder.DP_MobileCodeIso = (folder.DPMobilePrefixSelected) ? folder.DPMobilePrefixSelected.CodeISO2 : null;
                folder.DP_LanguageID = (folder.selectedDPLanguage) ? folder.selectedDPLanguage.LanguageID : null;
                folder.DP_OtherFunction = (folder.DP_FunctionID && folder.DP_FunctionID != -99) ? '' : folder.DP_OtherFunction;
            }

            // ==> We delete the selects fields
            this.app.sharedTools.deleteParamsInObject(folder, [
                'CountrySelected',
                'PhonePrefixSelected',
                'selectedLegalCivility',
                'selectedLegalFunction',
                'LegalMobilePrefixSelected',
                'selectedLegalLanguage',
                'selectedDPCivility',
                'selectedDpFunction',
                'selectedDPLanguage',
                'Billing_CountrySelected',
                'adresseComplete',
                'adresseFactuComplete',
                'DPPhonePrefixSelected',
                'DPMobilePrefixSelected',
                'areYouAnAssociationMember'
            ]);

            console.log("[updateExposant] folder", folder);

            this.updateExposant(folder);
        }
    }

    async updateExposant(_folder: any) {
        console.log("[updateExposant] folder", _folder);

        try {
            const response = await this.app.apiService.updateDirect(_folder);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.goToNext();

        } catch (error) {
            console.error(error);
        }
    }

    buildTree(parentId: any) {
        const children = this.tree[parentId] || [];
        //console.log("children==>", children)

        return children.map((child: any) => {
            // console.log("child==>", child)
            const node = {
                key: child.CategoryID,
                label: this.app.sharedTools.getLabelSwitchLang(child),
                LabelEN: child.LabelEN,
                LabelFR: child.LabelFR,
                LabelES: child.LabelES,
                data: '',
                icon: '',
                parent: parentId,
                expanded: false,
                children: this.buildTree(child.CategoryID)
            };
            //console.log("node==>", node)
            return node;
        });
    }

    async getCategories() {
        try {
            const response = await this.app.apiService.getCategories();

            let categoriesIni = cloneDeep(response);
            let categories = categoriesIni.filter((cat: any) => {
                return cat.CategoryType == "ACTIVITIES"
            });
            this.categories = cloneDeep(categories);

            categories.forEach((cat: any) => {
                const parentId = cat.ParentCategoryID;
                //console.log("parentId : ", parentId)
                if (!this.tree[parentId]) {
                    this.tree[parentId] = []; // Crea un nuevo array si no existe para ese ParentCategoryId
                }
                this.tree[parentId].push(cat); // Agrega el objeto al array correspondiente al ParentCategoryId

                //console.log('this.tree ==> ', this.tree[parentId])

            });

            this.nomenclatures = this.buildTree(null);
            console.log('this.nomenclatures ==> ', this.nomenclatures);
        } catch (error) {
            console.error(error);
        }
    }

    getFunctions() {
        this.app.apiService.getFunctions().subscribe((data: any) => {
            console.log("getFunctions ==> ", data);
            this.functions = data; //cloneDeep(data);
            this.functions.push(this.otherFunction);
        });
    }


    getCivilities() {
        this.app.apiService.getCivilities().subscribe((data: any) => {
            console.log("getCivilities ==> ", data);
            this.civilities = data; //cloneDeep(data);
        });
    }

    getLanguages() {
        this.app.apiService.getLanguages().subscribe((data: any) => {
            console.log("getLanguages ==>", data);
            this.langues = data;
        });
    }

    getCountries() {
        this.app.apiService.getCountries().subscribe((data: any) => {
            console.log("getCountries ==> ", data);
            this.countries = data; //cloneDeep(data);
        });
    }

    getPhones() {
        this.app.apiService.getPhones().subscribe((data: any) => {
            console.log("getPhones ==> ", data);
            this.phones = data; //cloneDeep(data);
        });
    }

    selectGPAddress(address: google.maps.places.PlaceResult, isNotAddressFacturation: boolean): void {
        if (!address) return;
        // Place object from API
        console.log("selectGPAddress ==> ", address);

        if (isNotAddressFacturation) this.newLeadForm.patchValue({ adresseComplete: address.formatted_address })
        else this.newLeadForm.patchValue({ adresseFactuComplete: address.formatted_address })

        if (address.address_components!.length > 0) {
            console.log("address.address_components ==> ", address.address_components);
            let Address1: string = "";
            let ZipCode: string = "";
            let City: string = "";
            let Country: string = "";
            let currentCountry: any = null;
            address.address_components!.forEach((item: any) => {
                if (item.types.length > 0) {
                    if (item.types.indexOf("street_number") != -1) Address1 += item.long_name;
                    if (item.types.indexOf("route") != -1) Address1 += ' ' + item.long_name;
                    if (item.types.indexOf("locality") != -1) City = item.long_name;
                    if (item.types.indexOf("postal_code") != -1) ZipCode = item.long_name;
                    if (item.types.indexOf("country") != -1) Country = item.short_name;

                    if (Country) currentCountry = this.countries.filter((c: any) => {
                        return c.CodeISO2 == Country;
                    })[0];
                }
            });

            if (isNotAddressFacturation) {
                this.newLeadForm.patchValue({
                    AddressLine1: Address1,
                    City: City,
                    ZipCode: ZipCode,
                    CountrySelected: (currentCountry) ? currentCountry : '',
                });

                if (!Address1 || !City || !ZipCode || !currentCountry) {
                    this.isAdresseManuelle = true;
                }
                this.onChangeCountry(this.newLeadForm.get('CountrySelected')?.value);

            } else {
                this.newLeadForm.patchValue({
                    Billing_AddressLine1: Address1,
                    Billing_City: City,
                    Billing_ZipCode: ZipCode,
                    Billing_CountrySelected: (currentCountry) ? currentCountry : '',
                });

                if (!Address1 || !City || !ZipCode || !currentCountry) {
                    this.isAdresseFactuManuelle = true;
                }
            }

            console.log("this.newLeadForm", this.newLeadForm.value);
        }
    }

    adresseManuellement(isNotAddressFacturation: boolean) {
        if (isNotAddressFacturation) {
            this.isAdresseManuelle = !this.isAdresseManuelle;
            this.cdRef.detectChanges();
        } else {
            this.isAdresseFactuManuelle = !this.isAdresseFactuManuelle;
            this.cdRef.detectChanges();
        }
    }

    protected readonly count = count;

    formControlHavePatternError(name: string): boolean {
        let havePatternError: boolean = false;

        let control = this.newLeadForm.get(name);
        if (control?.errors && control.errors['pattern']) {
            havePatternError = true;
        }
        return havePatternError;
    }

    refreshFormControlActivities(): void {
        // ==> We format the nodes
        let activities = this.app.sharedTools.getCategoriesFromSelectedNodes(this.categories, this.selectedNodes);
        //set activities to Activities form control
        this.setActivitiesToForm(activities);
    }

    onCompanyHaveOtherActivityChange(value: boolean): void {
        const otherActivityFormControl = this.newLeadForm.get('OtherActivity');
        if (this.companyHaveOtherActivity) {
            otherActivityFormControl?.setValidators([Validators.required]);
        }
        else {
            otherActivityFormControl?.setValidators([]);
            otherActivityFormControl?.setValue('');
        }
    }

    //#region private methods
    private findFolderID(): void {
        //check if route have query params === redirected from BO
        let directFolderID = this.app.route.snapshot.queryParams['folderID'];
        if (directFolderID) {
            this.folderId = directFolderID;
            this.redirectedFromBO = true;
        }
        else {
            this.folderId = this.user.FolderID;
        }
    }

    private goToNext(): void {
        if (this.redirectedFromBO) {
            // this.app.sharedTools.goTo(`detail-dp/${this.folderId}`);
            this.location.back();
        }
        else {
            this.app.sharedTools.goTo('/exposants-indirects');
        }
    }

    private setActivitiesToForm(newValues: any): void {
        let activitiesControl = this.newLeadForm.get('Activities') as FormArray;

        // Clear the existing controls in the FormArray
        activitiesControl.clear();

        // Add the new values to the FormArray as individual controls
        newValues.forEach((value: any) => {
            activitiesControl.push(this.formBuilder.control(value));
        });
    }
    //#endregion
}
