import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';
import { IdentityManagerService } from '../services/identity-manager.service';
import { Injectable } from '@angular/core';
import { RoleTypeId } from '../enums/role-type-id';

@Injectable({
  providedIn: 'root'
})
export class authGuard {
  constructor(
    private router: Router,
    private ims: IdentityManagerService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.ims.checkIsUserAuthenticated()){
        return false;
      }

      //user is connected
      if(!route.data || !route.data['roles']){
        return true;
      }

      let roles : number[] = route.data['roles'];

      console.log('here guard ', this.ims.currentUser, roles);

      let isAuthorized: boolean = this.ims.haveAuthorizedRole(roles);
      if(isAuthorized){
        return true;
      }
      else{
        this.routeToNotAuthorized();
        return false;
      }
  }

  routeToNotAuthorized(): void {
    if(this.ims.haveAuthorizedRole([RoleTypeId.VM_Administrator, RoleTypeId.VM_Programmer, RoleTypeId.VM_OZ,  RoleTypeId.VM_Consultant])){
      this.router.navigate(['/admin-board']);
    }
    else{
      this.router.navigate(['/notauthorized']);
    }
  }
  
}

