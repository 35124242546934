import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { ProductInnov } from '../models/product-innov';
import { firstValueFrom, Subject } from 'rxjs';
import { componentID } from '../enums/compenet';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MediaValueChange } from '../models/media-value-change';
import { MediasTypes } from '../enums/media-type';
import { FormCode } from '../enums/form-code';

@Component({
  selector: 'app-form-helped-medias',
  templateUrl: './form-helped-medias.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./form-helped-medias.component.less']
})
export class FormHelpedMediasComponent {
  user: any = null;
  product: any = new ProductInnov();
  paths = location.pathname.split('/');
  currentPath: any = null;
  productId: any;
  formHelpedID: any
  docRetrieved: boolean = false;
  componentID: any = componentID.HELPED


  productName: string = "";

  mediaDoc: any[] = [];
  submitEventSubject: Subject<void> = new Subject<void>();

  
  mediaForm?: FormGroup;
  submitted: boolean = false;
  
  formCode : FormCode = FormCode.FRM_HLP;
  frmData : any
  
  constructor(public app: AppComponent) {
    this.getFormHelpedData();
  }

   async ngOnInit() {
    this.initiliazeMediaForm();

    console.log("Hi! this is app-form-produit-medias ------------------------------");
    this.user = JSON.parse(sessionStorage.getItem('userData')!);
    console.log("this.user ==> ", this.user);

    if (!this.user) {
      this.app.router.navigate(['/login']);
      return;
    }

    await this.getHelpedInfo()

    this.app.route.queryParams
      .subscribe(params => {
        console.log('[params]', params);
        if (params && params['productid']) {
          this.productId = params['productid'];

          console.log("this.productId ", this.productId )
        }
      });

    this.getStep(this.productId);
    console.log("componentID HLP", this.componentID)

  }

  async getHelpedInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_HLP
    let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
    if (data != undefined && data.Id != undefined) {
        this.frmData = data
    }
}

  async getStep(solID: number) {
    let res = await this.app.apiService.getHelpedSolutionById(solID);
    console.log("🚀  getStep response : ", res);

    if (res) {
      console.log("🚀  getStep : ", res);
      this.product = res;
      this.mediaDoc = res.Documents;
      this.productName = res.NameEN;
      console.log("🚀  mediaDoc:", this.mediaDoc);
      this.docRetrieved = true;

      console.log("🚀  product:", this.product);
    }
  }
  private getFormHelpedData(): void {
    let helpedform: any = JSON.parse(sessionStorage.getItem('helped-form') ?? '{}');
    console.log("helpedform", helpedform)
    if (helpedform) {
      console.log("helpedform", helpedform)
      this.formHelpedID = helpedform.HelpedFormID;
      console.log("this.formHelpedID", this.formHelpedID)
    }
  }

  async getProduct(solId: any) {
    let response = await this.app.apiService.getHelpedSolutionById(solId);
    console.log('this.product resp: ', response)
    this.product.mapFormGetResponse(response);

  }

  onPreviousStep(): void {
    this.onPrevStep()
  }

  onCancelStep(): void {
    this.app.sharedTools.goTo('/products-helped')
  }

  onSubmit() {
    this.emitSubmitEventToChildMediaComponents();
  }

  private processSubmitOnMediaValidated(): void{    
    this.submitted = true;
    if(this.mediaForm?.valid){
      this.onNextStep();
    }
    else{
      console.log("invalid form ===>", this.mediaForm);
      console.log("invalid form ===>", this.mediaForm?.errors);

      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.mediaForm?.controls;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log("Invalid form values : ", invalidValues);

      // if (invalidField && invalidValues.length > 1) {
      if ((invalidField && invalidField.length > 1)) {
        console.log("invalidField", invalidField)

        if ((invalidField && invalidField.length > 1)) {
          setTimeout(() => {
            let el = invalidField[1].offsetTop - 100;
            if (el) window.scroll({ top: el, behavior: "smooth" });
          }, 10);
        }
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', "alert", () => {
      }, () => {
      })
      return;
    }
  }
  }
  // youCanGo: boolean = false;
  async onNextStep(): Promise<void> {
    let queryparams: any = {
      productid: this.productId
    }
    this.app.sharedTools.goToWithParameters(`product-helped-contact/edit`, queryparams);
  }

  onPrevStep(): void {
    let queryparams: any = {
      productid: this.productId
    }

    this.app.sharedTools.redirectToWithParameters(`product-helped-description/edit`, queryparams);
  }

  emitSubmitEventToChildMediaComponents() {
    this.submitEventSubject.next();
    //this.youCanGo = true;
  }

  
  handleMediaValueChange(mediaValueChange: MediaValueChange): void{
    if(mediaValueChange.Type == MediasTypes.LOGO){
      let atLeastHaveOneLogoControl = this.mediaForm?.get('AtLeastHaveOneLogo');
      if(atLeastHaveOneLogoControl){
        atLeastHaveOneLogoControl.setValue(mediaValueChange.HaveValue);
        atLeastHaveOneLogoControl.updateValueAndValidity();
      }
    }
    else if(mediaValueChange.Type == MediasTypes.IMAGE && mediaValueChange.ViewOrder == 1){
      let atLeastHaveOneImageControl = this.mediaForm?.get('AtLeastHaveOneImage');
      if(atLeastHaveOneImageControl){
        atLeastHaveOneImageControl.setValue(mediaValueChange.HaveValue);
        atLeastHaveOneImageControl.updateValueAndValidity();
      }
    }

    this.mediaForm?.updateValueAndValidity();
  }


  handleUploadStatusChange(status: boolean): void {
    console.log('sts ', status);
    let allMediaAreValidControl = this.mediaForm?.get('allMediaAreValid');
    if (allMediaAreValidControl) {
      allMediaAreValidControl.setValue(status);
      allMediaAreValidControl.updateValueAndValidity();
    }

    this.processSubmitOnMediaValidated();
  }

  handleValidityStatusChange(status: boolean): void {
    let allMediaAreValidControl = this.mediaForm?.get('allMediaAreValid');
    if (allMediaAreValidControl) {
      allMediaAreValidControl.setValue(status);
      allMediaAreValidControl.updateValueAndValidity();
    }
  }

  private initiliazeMediaForm(): void{
    this.mediaForm = new FormGroup({
      AtLeastHaveOneLogo: new FormControl(false, Validators.requiredTrue),
      AtLeastHaveOneImage: new FormControl(false, Validators.requiredTrue),
      allMediaAreValid: new FormControl(false, Validators.requiredTrue),
    })
  }
}
