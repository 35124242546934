import { Component, HostListener, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { FOExternalPagesQueryParamsNames, FOExternalPagesRoutes } from 'src/app/consts/fo-external-pages.routes';
import { ImageModelSuffix } from 'src/app/enums/image-model-suffix';
import { ApiService } from 'src/app/services/api.service';
import { ConferenceStoreService } from 'src/app/services/conference-store.service';
import { ExhibitorStoreService } from 'src/app/services/exhibitor-store.service';
import { IdentityManagerService } from 'src/app/services/identity-manager.service';
import { SharedtoolsService } from 'src/app/services/sharedtools.service';

@Component({
  selector: 'app-solution-details',
  templateUrl: './solution-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./solution-details-global.component.less',
    './solution-details.component.less',
    './solution-details-responsive.component.less'
  ]
})
export class SolutionDetailsComponent implements OnInit, OnDestroy {

  appID: any = null;
  currentlangue: any;

  responsiveOptions: any[] | undefined;

  numVisibleCard: number = 3;
  screenWidth: number = 0;

  isDesktop: boolean = false;
  isMobile: boolean = false;

  products: any[] = [];

  solution: any;

  imageModelSuffix = ImageModelSuffix;

  showNextPrevBtn: boolean = true;

  isFirstElem: boolean = false;
  isLastElem: boolean = false;
  solutionID: any;

  constructor(
    public app: AppComponent,
    private apiService: ApiService,
    private router: Router,
    public exhibitorStore: ExhibitorStoreService,
    private sharedService: SharedtoolsService,
    private renderer: Renderer2,
    protected ims: IdentityManagerService
  ) {
    this.screenWidth = window.innerWidth;

  }

  async ngOnInit() {

    this.onResize();

    this.responsiveOptions = [
      {
        breakpoint: '1400px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '1220px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '1100px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '995px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
      }
    ];

    this.app.sharedTools.getJSON("general_data").subscribe((resG: any) => {
      this.appID = resG.APP_ID;
      this.currentlangue = this.app.translate.currentLang;
      console.log("current language Solution ==> ", this.currentlangue);
    });

    this.currentlangue = this.app.translate.currentLang;
    console.log("current language Solution ==> ", this.currentlangue);
    
    this.app.route.queryParams.subscribe((params) => {
      if (params && params[FOExternalPagesQueryParamsNames.solDetail.solutionID]) {
        this.solutionID = params[FOExternalPagesQueryParamsNames.solDetail.solutionID];

        this.retrieveSolution();
      }
    });


  }

  private retrieveSolution(): void{
    this.currentlangue = this.app.translate.currentLang;
    this.getSolutionByID(this.solutionID, this.currentlangue)

    console.log("current Solution ==> ", this.solution);

    let solutionIds = sessionStorage.getItem('solution-ids');
    if (solutionIds != null && solutionIds != '') {
      this.showNextPrevBtn = JSON.parse(solutionIds).length > 1 ? true : false;
    }

    this.getLastOrFirstElement();
  }

  getLastOrFirstElement() {
    this.isFirstElem = this.exhibitorStore.isFirstExhiSolution(this.solutionID);
    this.isLastElem = this.exhibitorStore.isLastExhiSolution(this.solutionID);
  }

  ngOnDestroy() {
    this.renderer.removeStyle(document.body, 'background-color');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 995) {
      this.renderer.setStyle(document.body, 'background-color', '#f6f6f6');
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.renderer.setStyle(document.body, 'background-color', '#fff');
      this.isDesktop = true;
      this.isMobile = false;
    }
  }



  isImageUrl(url: string) {
    let isValidUrl: boolean = false;

    if (url == null || url == undefined) { return false; }

    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const extension = url.substring(url.lastIndexOf('.')).toLowerCase();

    isValidUrl = imageExtensions.includes(extension);
    return isValidUrl;
  }

  goBack() {
    this.app.location.back();
  }

  goPrevious() {
    let previousSolID: any = this.exhibitorStore.previousExhiSolution(this.solutionID);
    if (previousSolID) {
      let queryParams: any = {
        solutionID: previousSolID
      };

      this.app.sharedTools.routeTo(FOExternalPagesRoutes.solDetail, queryParams)
    }
  }

  goNext() {
    let nextSolID: any = this.exhibitorStore.nextExhiSolution(this.solutionID);
    if (nextSolID) {
      let queryParams: any = {
        solutionID: nextSolID
      };

      this.app.sharedTools.routeTo(FOExternalPagesRoutes.solDetail, queryParams)
    }
  }

  async getSolutionByID(lang: any, solID: any) {
    const response = await this.app.apiService.getSolutionById(solID, lang)
    this.solution = response;
    console.log("solution", this.solution)
  }

  openNewTab(url: string): void {
    window.open(url, "_blank")
  }

  async GoToMap(location: any) {
    this.sharedService.goToExhiMap(location)
  }

}
