import { Component } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-ssologin',
  templateUrl: './ssologin.component.html',
  styleUrls: ['./ssologin.component.less']
})
export class SSOLoginComponent {
  //#region properties
  //#endregion properties

  //#region ctor
  constructor(
    public app: AppComponent
  ) { }
  //#endregion ctor

  //#region handle lifecycle hooks
  ngOnInit(): void {
    sessionStorage.removeItem('userLang');

    this.app.route.queryParams
      .subscribe(params => {
        console.log('[params]', params);
        if (params && params['login']) {
          let autologin: any = {
            Login: params['login'],
            UserToken: params['token'],
          }
          console.log('[autologin]', autologin);

          let redirectTo = params['redirectto'];
          console.log('[redirectTo]', redirectTo);

          this.autoLogin(autologin, redirectTo);
        }
      }
      );
  }
  //#endregion

  //#region private methods

  //#region api calls


  private async autoLogin(autologin: any, redirectTo: any) {
    console.log(autologin)
    const response = await this.app.apiService.autologin(autologin);
    console.log('[getDirectLogin] API ==>', response);

    if (response && response.Result) {
      this.app.confirm.confirmDialog(''
        , this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result)
        , 'KO'
        , "alert"
        , () => { }
        , () => { });

      return;
    }

    // ==> On crée l'objet userData dans le sessionStorage pour le partage avec les data de la réponse
    sessionStorage.setItem('userData', JSON.stringify(response));
    console.log("response", response);
    if (response.Contact != null && response.Contact != undefined) {
      if (response.Contact.LanguageCode != null) {
        sessionStorage.setItem("userLang", JSON.stringify(response.Contact.LanguageCode))
        this.app.sharedTools.changeUsrLangInInit()
      }
    }

    sessionStorage.setItem("userHome", redirectTo);

    this.redirectTo(redirectTo);
  }
  //#endregion api calls

  //#region utils

  private redirectTo(redirectTo: any) {
    if (redirectTo) {
      this.app.sharedTools.goTo(redirectTo);
    }

  }
  //#endregion utils

  //#endregion
}
