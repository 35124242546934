import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    private _enabled: boolean = true;

    constructor() { }

    public spinnerVisible: boolean = false;

    showSpinner(): void {
        if(this._enabled){
            this.spinnerVisible = true;
        }
    }

    hideSpinner(): void {
        this.spinnerVisible = false;
    }

    isSpinnerVisible(): boolean {
        return this.spinnerVisible;
    }

    disableSpinner(): void {
        this._enabled = false;
    }

    enableSpinner(): void {
        this._enabled = true;
    }
}
