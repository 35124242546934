import { Component, ViewEncapsulation } from '@angular/core';
import { StatusCode } from '../enums/status-code';
import { cloneDeep } from 'lodash';
import { AppComponent } from '../app.component';
@Component({
  selector: 'app-form-program-recap',
  templateUrl: './form-program-recap.component.html',
  styleUrls: ['./form-program-recap.component.less']
})
export class FormProgramRecapComponent {
  user: any = null;
  product: any = null;
  productId: any;
  isEditMode: boolean = false;
  state: any;
  paths = location.pathname.split('/');
  currentPath: any = null;
  status: any[] = [];
  toBeValidateStatus: any;
  toBeSubmitStatus: any;
  gotocgv: boolean = false;
  helpedRecapCGV: any;
  constructor(
      public app: AppComponent,
     ) { }

  ngOnInit() {

      this.user = JSON.parse(sessionStorage.getItem('userData')!);
      if (!this.user) {
          this.app.router.navigate(['/login']);
          return;
      }

      this.app.route.queryParams
      .subscribe(params => {
          console.log('[params]', params);
          if (params && params['productid']) {
              this.productId = params['productid'];
              this.gotocgv = params['gotocgv'];
          }
      });

      this.app.sharedTools.getJSON('general_data').subscribe(
          resG => {
              this.helpedRecapCGV = resG.innovRecapCGV;
              this.product = this.getInfosRecapProduct();

              if (this.productId) {
                  this.getStatus();
                  this.isEditMode = true;
                  this.getProduct(this.productId);
              }
              else {
                  this.isEditMode = false;

              }

          })
  }
  OpenPDF(){
      let filePath: string = this.app.sharedTools.getPropertySwitchLang(this.helpedRecapCGV, 'FilePath');
       window.open(filePath, "_blank" );
  }
  
  getInfosRecapProduct() {
      let product: any = {
          IsPublicationDelayed: true,
          WebPublicationDate: "",
          CGVaccepted: false
      }

      if (!product) return;
      return product;
  }

  onSubmit(soumettre: boolean) {
      if (this.product.IsPublicationDelayed && !this.product.WebPublicationDate) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorWebPublishedDate'), 'KO', "alert", () => { }, () => { });
          return;
      }
      // ==> Ici on save
      this.UpdateProduct(this.product, soumettre)
  }

  onPreviousStep(): void {
      this.onPrevStep()
  }

  onCancelStep(): void {
      this.app.sharedTools.goTo('/products-program')
  }

  onSetStatus(SolutionID: number, soumettre: boolean) {
          this.setStatus(SolutionID, soumettre);
  }

  async setStatus(SolutionID: number, soumettre: boolean) {
      try {
          let reqToBeValidateObj : any = {
              ID: SolutionID,
              Status: this.toBeValidateStatus,
              Submitted : soumettre
          }
          let reqToBeSubmitObj : any = {
              ID: SolutionID,
              Status:this.toBeSubmitStatus,
              Submitted : soumettre
          }
          console.log(">>>> soumettre", soumettre )
          if(soumettre)
          {
              console.log(">>>> reqToBeValidateObj", reqToBeValidateObj )
              const response = await this.app.apiService.setStatusHLP(reqToBeValidateObj);
              console.log("response setStatusHLP" , response)
              setTimeout(() => {
                  if (response && response.Result) {
                      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                      }, () => {
                      })
                      return;
                  }
                  this.state = response
  
                  this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successproduitSetState'), 'OK', "alert", () => {
                      this.onCancelStep();
                  }, () => { });
              }, 700);
          }
          if(!soumettre)
          {
              const response = await this.app.apiService.setStatusHLP(reqToBeSubmitObj);
              console.log("response setStatusHLP", response)
              setTimeout(() => {
                  if (response && response.Result) {
                      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                      }, () => {
                      })
                      return;
                  }
                  this.state = response
  
                  
                      this.onCancelStep();

              }, 700);
          }
          

      } catch (error) {
          console.error(error);
      }
  }

  async UpdateProduct(productObj: any, soumettre: boolean): Promise<any> {
      try {
          const response = await this.app.apiService.updateHelpedSolution(productObj);
          console.log('RESPONSE UPDATE Product ===>', response);

          if (response && response.Result) {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
              }, () => {
              })
              return;
          }
          //et step

          let stepObj: any = {
              SolutionLastStep: 5,
              ID: this.productId
          }
          console.log('stepObj  11 ', stepObj);

          this.setPreviewStep(stepObj);
          //

          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successproduitUpdt'), 'OK', "alert"
          , () => {
              console.log(">>>> soumettre update Product 2", soumettre )
              setTimeout(() => {
                  if(soumettre){
                      this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmSoumettre'), 'AUTRE', 'confirm', () => {
                          this.onSetStatus(this.product.ID,soumettre)
                      }, () => { });
                  }
                  if(!soumettre){
                      this.onSetStatus(this.product.ID,soumettre);

                  }
                  
                      
                  
              }, 500);
           }
          , () => {
              setTimeout(() => {
                  if(soumettre){
                      this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmSoumettre'), 'AUTRE', 'confirm', () => {
                          this.onSetStatus(this.product.ID, soumettre)
                      }, () => { });
                  }
                  if(!soumettre){
                      this.onSetStatus(this.product.ID,soumettre)
                  }
              }, 500);
           });

      } catch (error) {
          console.error(error);
      }
  }
  

  async getProduct(solId: any) {
      let response = await this.app.apiService.getHelpedSolutionById(solId);
      this.product = response;
      console.log('this.product resp: ', response)

      
      if(this.gotocgv){
          // Faites défiler la page vers l'élément cible
          const element: any = document.getElementById('scrollBottom');
          console.log('elementCible 01', element);
          if (element) {
              console.log('elementCible 02', element);
              let el = element.scrollHeight + 300;
              if (el) window.scroll({ top: el, behavior: "smooth" });
          }
      }

  }


  getStatus() {
      this.app.apiService.getStatus().subscribe((data: any) => {
          this.status = cloneDeep(data);
          
          this.toBeValidateStatus = this.status.find((s: any) => s.Code === StatusCode.TOVALIDATE_FICHE);
          this.toBeSubmitStatus = this.status.find((s: any) => s.Code === StatusCode.TO_BE_SUBMIT);
          console.log(this.status, this.toBeValidateStatus, this.toBeSubmitStatus)
      });
  }

  onPrevStep(): void {
      let queryparams: any = {
          productid: this.productId
      }

      this.app.sharedTools.redirectToWithParameters(`product-helped-contact/edit`, queryparams);
  }


  async setPreviewStep(stepObj: any) {
      try {
          const response = await this.app.apiService.saveSolutionHelpedLastStep(stepObj);

          if (response && response.Result) {
              this.app.confirm.confirmDialog('', this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
              return;
          }

      }
      catch (error) {
          console.error(error);
      }
  }

}
