import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-edit-selected-do',
  templateUrl: './edit-selected-do.component.html',
  styleUrls: ['./edit-selected-do.component.less']
})
export class EditSelectedDOComponent implements OnInit {
  /**
   *
   */
  constructor(private conf: DynamicDialogConfig) {


  }

  @Input() model?: any;


  ngOnInit(): void {
    this.model = this.conf.data;
  }

}
