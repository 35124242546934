import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-welcome-steps-supports',
  templateUrl: './welcome-steps-supports.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./welcome-steps-supports.component.less']
})
export class WelcomeStepsSupportsComponent {
    @Input() stepsWelcomeObject : any
    stepsWelcomeSupports: any = [];

    constructor(
        public app: AppComponent,
    ) { }

    ngOnInit() {
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                // console.log("resG ==> ", resG);
                this.stepsWelcomeSupports = resG[this.stepsWelcomeObject]?.filter((x:any)=>x?.isEnabled == "true");                
            });

    }
}
