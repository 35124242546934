export class GroupNew {
    PriviGroupID?: number | null = null;
    PriviGroupName?: string | null = '';
    PriviGroupIsenabled: number | null = null;
    PriviGroupBlockStand: number | null = null;
    //PriviGroupWeightsRequest: number | null = null;
    PriviGroupTotalRequest: number | null = null;
    PriviGroupChoice1: number | null = null;
    PriviGroupChoice2: number | null = null;
    PriviGroupChoice3: number | null = null;
    PriviGroupOpeningDate: Date | null = null;
    PriviGroupClosingDate: Date | null = null;
    PriviGroupImprPlanDo: number | null = null;
    PriviGroupExpPlanDo: number | null = null;
    //PriviGroupTypesDo?: string | null = '';
    ODTypeCategoriesIDs?: [] = [];
    ExhActivities?: [] = [];
    SelectedCountries : []=[];
    SurfaceMin : number | null = null
    SurfaceMax : number | null = null

    // ==> Other params and functions
    rangeDates: Date[] = [];
    //PriviGroupTypesDoList?: [] = [];


    public pushDates(): void {
        if (this.PriviGroupOpeningDate) {
            this.rangeDates.push(new Date(this.PriviGroupOpeningDate));
        }
        
        if (this.PriviGroupClosingDate) {
            this.rangeDates.push(new Date(this.PriviGroupClosingDate));
        }
    }

    public getListNotRequireds(): boolean {
        const notRequiredValidators: any = ['PriviGroupOpeningDate', 'PriviGroupClosingDate', 'PriviGroupTypesDo', 'PriviGroupID','rangeDates', 'PriviGroupWeightsRequest', 'ODTypeCategoriesIDs','SelectedCountries', 'SurfaceMin','SurfaceMax'];
        return notRequiredValidators;
    }
}