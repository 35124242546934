import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { SearchHelperService } from '../services/search-helper.service';
import { DatePipe } from '@angular/common';
import { VMUserSearchLocalStorageKey, hasAnyNonNullProperty } from '../consts/utils';
import { Table } from 'primeng/table';
import { cloneDeep } from 'lodash';
import { SearchVMUser } from '../models/search-user';
import { Menu } from 'primeng/menu';
import { StatusType } from '../enums/status-type';
import { FormGroup } from '@angular/forms';
import { StatusCode } from '../enums/status-code';

@Component({
  selector: 'app-gestion-des-editions',
  templateUrl: './gestion-des-editions.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./gestion-des-editions.component.less']
})
export class GestionDesEditionsComponent implements OnInit {
  @ViewChild('dtEditions') dtEditions!: Table;
  @ViewChild('menuAction') menuAction!: Menu;

  newForm!: FormGroup;
  SelectedStatus: any;

  //#region var
  fastSearchUserKeyword: string = '';
  showAdvancedSearch: boolean = false;
  initForm: boolean = false;
  edition: any = {};
  showTable: boolean = true;
  isEditMode: boolean = false;
  editionCustomOptions: any = {};

  //#endregion

  //#region List
  status: any = [];
  items: any = [];
  colsEditions: any[] = [];
  editions: any[] = []
  editionYears: any[] = []
  etats: any[] = []
  evenements: any[] = []
  selectedEditions: any[] = [];
  _selectedColumns: any = [];
  //TOdO sANA
  fastFiltersEditions: any = ['CodeSalon', 'EventName', 'EditionLieu', 'StatusId', 'EditionDate', 'StartDate', 'EndDate'];
  //#endregion

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    // ==> restore original order
    this._selectedColumns = this.colsEditions.filter((col: any) => val.includes(col));
  }


  constructor(
    public app: AppComponent,
    private searchHelperService: SearchHelperService,
    private datePipe: DatePipe) { }




ngOnInit(): void {

    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {

        //getcolumns
        this.colsEditions = resG.colsEditions;
        this._selectedColumns = this.colsEditions;
        this.editionCustomOptions = resG.editionCustomOptions;
        this.items = resG.actionsEditionRow;
        //        this.getStatus();
        this.getStatus()
        this.getEditionYears()
        this.getEvenement()
        this.searchEditions(this.edition)
      });

  }

//#region Filter Fast
  filterFast() {
    this.searchHelperService.setFastSearchKeyword(this.fastSearchUserKeyword, VMUserSearchLocalStorageKey);
    this.dtEditions.filterGlobal(this.fastSearchUserKeyword, 'contains');
  }

  clearFastSearch(): void {
    this.fastSearchUserKeyword = '';
    this.filterFast();
  }

  toggleAdvancedSearch() {
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  private initAdvancedSearch(): void {
    let findSelectedStatus = this.status.find((c: any) => c.StatusID === this.edition.selectedStatus?.StatusID);
    let findSelectedEdition = this.editionYears.find((c: any) => c.EditionDate === this.edition.selectedEdition?.EditionDate);
    let findSelectedEvent = this.evenements.find((c: any) => c.EventID === this.edition.selectedEvenement?.EventID);

    this.edition.selectedStatus = findSelectedStatus;
    this.edition.selectedEdition = findSelectedEdition;
    this.edition.selectedEvenement = findSelectedEvent;

}
  //#endregion

//#region  Get
getStatus() {
    this.app.apiService.getStatus().subscribe((data: any) => {
      console.log("getStatus ==> ", data);
      if (this.status) {
        this.status = data.filter((s: any) => s.StatusType === StatusType.EditionState);
      }
    });
  }
getEditionYears() {
    this.app.apiService.getEditions().subscribe((data: any) => {
      console.log("getEditions ==> ", data);
      this.editionYears = [];
      let editions = cloneDeep(data);
      editions.forEach((e: any) => {this.editionYears.push(e.Year)});
    });
  }
getEvenement() {
    this.app.apiService.getEvenements().subscribe((data: any) => {
      console.log("getEvenement ==> ", data);

      this.evenements = cloneDeep(data);
    });
}
//#endregion

//#region Search
clearSearch() {
    console.log("clearSearch ==>");
    this.edition = new SearchVMUser();
    this.searchEditions(this.edition);
    this.clearFastSearch();
}

async searchEditions(search: any, thenFilterFast: boolean = false) {
    //save search to retrieve it when coming back to BO after redirect from BO
    this.searchHelperService.setAdvancedSearch(cloneDeep(search), VMUserSearchLocalStorageKey);

    console.log("search ==> ", search);
    let searchTemp: any = cloneDeep(search);

    if (searchTemp.selectedEdition) searchTemp.EditionDate = searchTemp.selectedEdition.EditionDate;
    if (searchTemp.selectedStatus) searchTemp.StatusID = searchTemp.selectedStatus.StatusID;
    if (searchTemp.selectedEvenement) searchTemp.EventID = searchTemp.selectedEvenement.EventID;
    if (searchTemp.selectedEdition) delete searchTemp.selectedEdition;
    if (searchTemp.selectedStatus) delete searchTemp.selectedStatus;
    if (searchTemp.selectedEvenement) delete searchTemp.selectedEvenement;
    console.log("searchTemp ==> ", searchTemp);

    try {
      const response = await this.app.apiService.editionsSearch(searchTemp);
      console.log("searchEditions==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/users'); }, () => { })
        return;
      }

      this.editions = cloneDeep(response)
      console.log("editions", this.editions);
      this.editions.forEach(edition => {
        console.log("-> edition : ", edition);
      });


      if (thenFilterFast) {
        this.filterFast();

        if (hasAnyNonNullProperty(this.edition)) {
          console.log("hasAnyNonNullProperty", hasAnyNonNullProperty(this.edition))
          this.initAdvancedSearch();
          this.showAdvancedSearch = true;
        }
      }

      this.editions.forEach((e: any) =>{
        e.selectedStatus = this.status.find((s: any) => s.StatusID === e.StatusID);
      });

      console.log('editions ', this.editions);

    } catch (error) {
      console.error(error);
    }
}
//#endregion

async onchangeStatus(_edition: any) {
    console.log("onchangeStatus edition ==> ", _edition);
    if (! _edition.StatusId) return;

    let req: any = {
      EditionID: _edition.EditionID,
      StatusID: _edition.StatusId
    }
    try {
        const response = await this.app.apiService.updateStatutEdition(req);
        console.log("apiService.changestatusReq ==> ", response);

        if (response && response.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
            return;
        }

        this.getStatus();
        this.searchEditions(this.edition);

    } catch (error) {
        console.error(error);
    }
}

getActionsMenuByEdition(edition : any): any {
  return this.items.filter((item: any) => item.ActionID);
}

toggleMenu(event: any, EditionID: number, items: any) {
  this.menuAction.toggle(event);

  console.log("Edition ID:", EditionID);
  console.log("items:", items);

  items.forEach((itemMenu: any) => {
      itemMenu.userIdRow = EditionID;
      itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

      if (itemMenu.ActionID == 1) {
          itemMenu.command = (event: any) => {
              this.app.sharedTools.redirectTo('/detail-edition/' + event.item.userIdRow.toString());
          }
      }

      // if (itemMenu.ActionID == 2) {
      //     itemMenu.command = (event: any) => {
      //         this.app.sharedTools.redirectTo('/detail-edition/' + event.item.userIdRow.toString());
      //     }
      // }
  });
}

getColorStatus(status: any) {
      let classColor = "";
      if (status) {
          switch (status.Code) {
              case StatusCode.EDI1:
                  classColor = "color-lime-green"
                  break;
    
              case StatusCode.EDI2:
                  classColor = "color-black"
                  break;
    
              case StatusCode.EDI3:
                  classColor = "color-red"
                  break;
    
              default:
                  classColor = "color-dark-blue"
                  break;
          }
      }
      return classColor;
}

goToDetail(_editionId: any) {
    console.log("[gotToDetailEdition] ==> ", _editionId);
    this.app.sharedTools.redirectTo('/detail-edition/' + _editionId.toString());
}

}
