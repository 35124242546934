import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';
import { StatusCode } from '../enums/status-code';

@Injectable({
  providedIn: 'root'
})
export class helpedSolutionGuard implements CanActivate {

  solutionId: any;
  paths = location.pathname.split('/');
  currentPath: any = null;

  constructor(
    public apiService: ApiService,
    public router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    //get ptoduct id from
    this.solutionId = route.queryParams['productid'];
    //get sol by id
    console.log(" >>>> solutionId ==> ", this.solutionId);
    if (this.solutionId) {
      let can: any = this.apiService.getHelpedSolutionById(this.solutionId)
        .then(
          (result: any) => {
            if (result) {
              console.log(" >>>> product ==> ", result);

              if (result && result.Status.Code === StatusCode.TO_BE_SUBMIT || result.Status.Code === StatusCode.DRAFT_FICHE) {
                return true
              }
              else{
                this.navigateToInitialPage(result?.HlpFormID);
                return false;
              }
            }
            else {
              this.navigateToInitialPage();
              return false;
            }
          },
          (reason) => {
            this.navigateToInitialPage();
            return false;
          }
        );
      return can;

      // if status == draft or to be submit return true
      // else no ===> return false
    }
    else{
      this.navigateToInitialPage();
      return false;
    }
  }

  navigateToInitialPage( hlpFormId ?: any): void {
    const queryParams = {helpedid : hlpFormId}
    this.router.navigate(['products-helped'],{queryParams:queryParams});
  }
}