import { DatePipe } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { FOExternalPagesRoutes } from 'src/app/consts/fo-external-pages.routes';
import { Languages } from 'src/app/enums/language';

@Component({
  selector: 'app-exhibitor-details-conference',
  templateUrl: './exhibitor-details-conference.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./exhibitor-details-conference.component.less']
})
export class ExhibitorDetailsConferenceComponent {
  @Input() showDescription :boolean = true
  @Input() showAvatar :boolean = true
  @Input() Session: any;
  IsValidUrl: boolean = false;
  IsfileIsc: boolean = false;

  DayConference: any;
  currentlangue: any;
  interests: any;
  speakers: any;
  exhibitors: any;
  isEnabled: boolean = false;
  calendarObj: any;
  Description: string = '';
  Location: string = '';
  Summary: string = '';
  isActive: boolean = false;
  currentMonth: number = 1;
  
  get dividerColor(): string{
    return (this.Session?.ThemeCategory?.Color ?? '#000000');
  };

  languages = Languages;

  constructor(
    public app: AppComponent,
    private datePipe: DatePipe,

  ) { }

  ngOnInit(): void {
  }

  formatDayOfWeek(date: any) {
    return this.datePipe.transform(date, 'EEEE', undefined, this.app.translate.currentLang);
  }

  isImageUrl(url: string): boolean {
    console.log(">>>> isImageUrl:", url);
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const extension = url.substring(url.lastIndexOf('.')).toLowerCase();

    console.log(">>>> Extenstion:", extension);

    this.IsValidUrl == imageExtensions.includes(extension);
    console.log(this.IsValidUrl);

    return this.IsValidUrl;
  }

  getDetail(confEventID: any) {
    let queryParams: any = {
      eventID: confEventID
    };

    this.app.sharedTools.routeTo(FOExternalPagesRoutes.confDetail, queryParams);
  }

  async AddToCalendar(confEventID: any): Promise<void> {
    this.IsfileIsc = true;
    console.log(confEventID);
    let calendarData: string = '';
    const response = await this.app.apiService.getConfByEventId(confEventID)
    this.calendarObj = response;
    console.log("calendarObj", this.calendarObj);
    if (this.calendarObj) {
      if (this.app.translate.currentLang == 'en') {
        this.Description = this.calendarObj?.CnfdDescriptionEN;
        this.Location = this.calendarObj?.ConferenceEvent?.CatgeoryEventsDetails?.LabelEN;
        if (this.calendarObj?.ConferenceProgram?.CnfprNameEN != null && this.calendarObj?.ConferenceProgram?.CnfprNameEN != '') {
          this.Summary = this.calendarObj?.ConferenceProgram?.CnfprNameEN + ' : ' + this.calendarObj?.CnfdNameEN

        } else {
          this.Summary = this.calendarObj?.CnfdNameEN
        }
      }
      else if (this.app.translate.currentLang == 'fr') {
        this.Description = this.calendarObj?.CnfdDescriptionFR;
        this.Location = this.calendarObj?.ConferenceEvent?.CatgeoryEventsDetails?.LabelFR;
        if (this.calendarObj?.ConferenceProgram?.CnfprNameFR != null && this.calendarObj?.ConferenceProgram?.CnfprNameFR != '') {
          this.Summary = this.calendarObj?.ConferenceProgram?.CnfprNameFR + ' : ' + this.calendarObj?.CnfdNameFR

        }
        else {
          this.Summary = this.calendarObj?.CnfdNameFR
        }

      }
      else if (this.app.translate.currentLang == 'es') {
        this.Description = this.calendarObj?.CnfdDescriptionES;
        this.Location = this.calendarObj?.ConferenceEvent?.CatgeoryEventsDetails?.LabelES;
        if (this.calendarObj?.ConferenceProgram?.CnfprNameES != null && this.calendarObj?.ConferenceProgram?.CnfprNameES != '') {
          this.Summary = this.calendarObj?.ConferenceProgram?.CnfprNameES + ' : ' + this.calendarObj?.CnfdNameES

        } else {
          this.Summary = this.calendarObj?.CnfdNameES
        }
      }
      calendarData = [
        'data:text/calendar;charset=utf8,',
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'DESCRIPTION:' + this.Description,
        'DTSTART:' + this.calendarObj?.ConferenceEvent?.CnfeHourStart,
        'DTEND:' + this.calendarObj?.ConferenceEvent?.CnfeHourEnd,
        'LOCATION:' + this.Location,
        'SUMMARY:' + this.Summary,
        'TRANSP:TRANSPARENT',
        'END:VEVENT',
        'END:VCALENDAR',
        'UID:' + this.calendarObj?.ConfDetailID,
        'DTSTAMP:' + this.calendarObj?.ConferenceEvent?.Duration,
        'PRODID:website-1.0'
      ].join('\n');
      console.log(calendarData);
      this.downloadCalendar(calendarData);
    }
    this.IsfileIsc = false;
  }

  downloadCalendar(data: any) {

    let dtStart = this.calendarObj?.ConferenceEvent?.CnfeHourStart;
    let formatedDtStart = this.datePipe.transform(dtStart, 'dd/MM/yyyy');
    let fileName = formatedDtStart?.toString();

    var link = document.createElement("a");

    link.setAttribute("href", data);
    link.setAttribute("download", fileName!);
    link.click();
  }
}


