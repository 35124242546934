import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './api.service';
import { ConfirmDialogService } from './confirm-dialog.service';
import { cloneDeep } from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class FormatTelHelperService {

    constructor(private translate: TranslateService, 
        private apiService: ApiService,
        private confirm: ConfirmDialogService,) { }

    async phoneFormatValidator(_currentForm: any, controlName: string): Promise<boolean> {
        console.log('Control name:', controlName); // Aquí puedes acceder al nombre del control
        const prefix = _currentForm.get(this.getPrefixParent(controlName)!)!.value;
        const phoneNumber = _currentForm.get(controlName)!.value;
        if (!prefix || !phoneNumber) { return false; }

        let phoneCheckObj: any = {
            CodeISO2: prefix.CodeISO2,
            PhoneNumber: phoneNumber
        }

        console.log("[phoneFormatValidator] phoneCheckObj ==> ", phoneCheckObj);

        try {
            const response = await this.apiService.phoneCheck(phoneCheckObj);
            console.log("[validatePhoneCheck] response API ==> ", controlName, ' ==> ', response);

            if (response && response.Result) {
                this.confirm.confirmDialog('', this.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return false;
            }

            let respApi: any = cloneDeep(response);
            let item = _currentForm.get(controlName)!;

            //this.formatNbError = this.app.sharedTools.getParamByID();
            let currentlang = this.translate.currentLang.toUpperCase();
            let errorMsgTxt: any = respApi["ErrorMessage" + currentlang];
            console.log("errorMsgTxt error", errorMsgTxt);

            if (respApi.IsValidNumber)
                item.setValue(respApi.PhoneNumber);
            else
                item.setErrors({ invalidFormat: true, errorMsg: errorMsgTxt });

                return respApi.IsValidNumber;

        } catch (error) {
            console.error(error);
            return false;
        }
    }

    getPrefixParent(controlName: any) {
        let parentName: any = null;
        switch (controlName) {
            case 'PhoneNumber':
                parentName = 'PhonePrefixSelected';
                break;
            case 'DP_PhoneNumber':
                parentName = 'DPPhonePrefixSelected';
                break;
            case 'DP_MobileNumber':
                parentName = 'DPMobilePrefixSelected';
                break;
            case 'Legal_MobileNumber':
                parentName = 'LegalMobilePrefixSelected';
                break;
            case 'Mobile':
                parentName = 'MobilePrefixSelected';
            break;
            case 'MobileNumber':
                parentName = 'MobilePrefixSelected';
            break;
            case 'ContactPressPhone':
                parentName = 'ContactPressPhonePrefixSelected';
            break;
            case 'ContactCommunicationPhone':
                parentName = 'ContactCommunicationPhonePrefixSelected';
            break;
            default:
                parentName = null
                break;
        }
        return parentName;
    }
}
