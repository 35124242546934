import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { IdentityManagerService } from '../services/identity-manager.service';
import { cloneDeep } from 'lodash';
import { APPID } from '../enums/app_id';
import { TranslatePipe } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { FormCode } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';
@Component({
  selector: 'app-welcome-program',
  templateUrl: './welcome-program.component.html',
  styleUrls: ['./welcome-program.component.less']
})
export class WelcomeProgramComponent {
  user: any = null;
  appID:any = null;
  welcomeObj: any = null;
  folderGuid: any;
  programForm: any;
  frmData : any

  constructor(
      public app: AppComponent,
      private identityManagerService: IdentityManagerService,
      private translatePipe: TranslatePipe,
      private datePipe: DatePipe
  ) { }
  async ngOnInit() {
      console.log(this.app.route);
      this.app.route.queryParams.subscribe(params => {
          console.log('paramssssssss ',params)
          this.folderGuid = params['folderguid'];
        console.log(`Param Value: folder ${this.folderGuid}`);
      });
      await this.getProgFrmInfo()
      this.identityManagerService.checkIsUserAuthenticated();
      this.user = cloneDeep(this.identityManagerService.currentUser);

      // this.app.sharedTools.changeUsrLangInInit();
      // this.app.sharedTools.getCurrentLanguage();

      sessionStorage.removeItem('folderStep');
      sessionStorage.removeItem('activeStep');

      this.app.sharedTools.getJSON('general_data').subscribe(
          resG => {               
              this.welcomeObj = {
                  ProgramFormID: null,
                  Contact : {
                      FirstName: this.user?.Contact?.FirstName,
                      LastName: this.user?.Contact?.LastName
                  },
                  DateFin: null,
                  DateOpen :null,
                  IsOpen: null,
              }
              this.welcomeProgramForm();
          });

      this.appID = APPID.Program;
  
  }

  async getProgFrmInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_PROGRAM
    let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
    if (data != undefined && data.Id != undefined) {
        this.frmData = data
    }
}

  async welcomeProgramForm() {
      let request: any = {
          FolderGuid: this.folderGuid
      }
      
      let res = await this.app.apiService.welcomeProgramForm(request);
      if(res){
          console.log("🚀  welcomeProgramForm : ", res);
          this.programForm = res;
          sessionStorage.setItem('program-form', JSON.stringify(this.programForm));
          this.welcomeObj.Edition = this.programForm.Edition;
          this.welcomeObj.ContactLastName = this.programForm.ContactLastName;
          this.welcomeObj.ContactFirstName = this.programForm.ContactFirstName;
          this.welcomeObj.ContactFullName = this.programForm.ContactFullName;
          this.welcomeObj.DateFin = this.programForm.DateFin;
          this.welcomeObj.DateOpen = this.programForm.DateOpen;
          this.welcomeObj.IsOpen = this.programForm.IsOpen;
          this.welcomeObj.DateDeadLine=this.programForm.DateDeadLine;
      }
    }

    next(): void{
      sessionStorage.setItem('folderGuid', JSON.stringify(this.folderGuid));
      this.app.sharedTools.redirectTo('/products-program');
    }

    public replaceTokens(text: string): string{
      if(!text){
          text = '';
      }

      text = this.translatePipe.transform(text) ?? '';
      text = text.replace('{firstname}', this.welcomeObj.ContactFirstName??'');
      text = text.replace('{lastname}', this.welcomeObj.ContactLastName??'');
      text = text.replace('{fullname}', this.welcomeObj.ContactFullName??'');
      text = text.replace('{edition}',  this.welcomeObj.Edition??'');
      text = text.replace('{openingdate}', this.datePipe.transform(this.welcomeObj.DateOpen, 'dd MMMM yyyy', undefined, this.app.translate.currentLang) ??'');
      text = text.replace('{closingdate}', this.datePipe.transform(this.welcomeObj.DateFin, 'dd MMMM yyyy', undefined, this.app.translate.currentLang) ??'');
      text = text.replace('{echeancedate}',   this.datePipe.transform(this.welcomeObj.DateDeadLine, 'dd MMMM yyyy', undefined, this.app.translate.currentLang) ??'');

      return text;
    }
}
