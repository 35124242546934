import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-create-other-visit',
  templateUrl: './create-other-visit.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./create-other-visit.component.less']
})
export class CreateOtherVisitComponent {
  submitted: boolean = false;
  initForm: boolean = false;
  newLeadForm!: FormGroup;
  isEditMode: boolean = false;
  paths = location.pathname.split('/');
  currentPath: any = null;
  otherVisitID: any;
  otherVisit?: any;
  constructor(
      public app: AppComponent,
      private messageService: MessageService,
  ) {
  }
  async ngOnInit() {
      let user = JSON.parse(sessionStorage.getItem('userData')!);
      console.log(user);
      if (!user) {
          this.app.router.navigate(['/login']);
          return;
      }

      //check create or edit otherVisit
      console.log("this.path==> ", this.paths);
      this.currentPath = this.paths[1];
      var urlId: any = this.app.router.url.split('/').pop();
      if (this.currentPath == 'detail-othervisit') {
          this.otherVisitID = parseInt(urlId);
      }
      //
      if (this.otherVisitID) {
          this.isEditMode = true;
          this.getOtherVisit(this.otherVisitID);
          console.log("this.otherVisitId ==> ", this.otherVisitID);
      }
      else {
          //
          this.InitForm();
          console.log("this.newLeadForm", this.newLeadForm);
      }

  }
  onSubmit() {
      this.submitted = true;
      console.log('[this.newLeadForm]', this.newLeadForm);

      if (this.newLeadForm.invalid) {

          console.log("invalid form ===>", this.newLeadForm);
          console.log("invalid form ===>", this.newLeadForm.errors);

          //bloc explore form errors

          let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
          const invalidValues: any = {};
          const controls = this.newLeadForm.controls;

          for (const controlName in controls) {
              if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                  invalidValues[controlName] = controls[controlName].value;
              }
          }

          console.log('Invalid form values:', invalidValues);

          if (invalidField && invalidField.length > 1) {
              console.log("invalidField", invalidField)

              setTimeout(() => {
                  let el = invalidField[1].offsetTop - 100;
                  if (el) window.scroll({ top: el, behavior: "smooth" });
              }, 10);
          }
          //
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
          }, () => { });
      } else {

          let newOtherVisit = this.newLeadForm.value;

          console.log('[newOtherVisit]', newOtherVisit);

          this.createOtherVisit(newOtherVisit);
      }
  }

  annuler() {
      this.app.sharedTools.redirectTo('/othervisits');
  }

  onDeleteOtherVisit(_otherVisit: any) {
      this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteOtherVisit'), 'AUTRE', 'confirm', () => {
          this.deleteOtherVisit(_otherVisit);
      }, () => { });
  }

  async deleteOtherVisit(_otherVisitObj: any) {
      console.log("DELETE OtherVisit ===>", _otherVisitObj);
      try {
          const response = await this.app.apiService.othervisitdelete(_otherVisitObj);
          console.log('Response API : DELETE OtherVisit ===>', response);

          if (response && response.Result) {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
              return;
          }

          this.messageService.add({ severity: 'success'
          , summary: this.app.translate.instant('racine.successMsg.successDeleteOtherVisitTitle')
          , detail: this.app.translate.instant('racine.successMsg.successOtherVisitDelete') });
          this.app.sharedTools.redirectTo('/othervisits');

      } catch (error) {
          console.error(error);
      }
  }

  async createOtherVisit(formObj: any): Promise<any> {
      console.log("CREATE OtherVisit ===>", formObj);
      try {
          if (!this.isEditMode) {
              const response = await this.app.apiService.othervisitcreate(formObj);
              console.log('Response API : CREATE OtherVisit ===>', response);

              if (response && response.Result) {
                  this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                  }, () => {
                  })
                  return;
              }

              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successOtherVisitCree'), 'OK', "alert", () => {
                  this.app.sharedTools.redirectTo('/othervisits');
              }, () => { });
          }

          else {
              formObj.OtherVisitID = this.otherVisitID;
              const response = await this.app.apiService.othervisitupdate(formObj);
              console.log('Response API : UPDATE OtherVisit ===>', response);

              if (response && response.Result) {
                  this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { });
                  return;
              }

              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successOtherVisitUpdate'), 'OK', "alert", () => {
                  this.app.sharedTools.redirectTo('/othervisits');
              }, () => { });

          }
      } catch (error) {
          console.error(error);
      }
  }
  private async getOtherVisit(otherVisitId: any) {

      try {
          const response = await this.app.apiService.othervisitget(otherVisitId);
          console.log("getOtherVisit ==> ", response);

          if (response && response.Result) {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
              }, () => {
              })
              return;
          }
          this.otherVisit = cloneDeep(response);
          this.InitForm();
      }
      catch (error) {
          console.error(error);
      }
  }
  private InitForm(): void {
      if (this.isEditMode) {
          this.newLeadForm = new FormGroup({
              LabelEN: new FormControl(this.otherVisit.LabelEN ?? '', Validators.required),
              LabelFR: new FormControl(this.otherVisit.LabelFR ?? ''),
              LabelES: new FormControl(this.otherVisit.LabelES ?? ''),
          });
      }
      else {
          this.newLeadForm = new FormGroup({
              LabelEN: new FormControl('', Validators.required),
              LabelFR: new FormControl(''),
              LabelES: new FormControl(''),
          });

      }

      this.initForm = true;
  }
}
