import { Languages } from "../enums/language";
import { Product } from "./product";

export class ProductsGroup {
    GroupID?: number;
    ApplicationID?: number;
    LabelEN?: string | null;
    LabelFR?: string | null;
    LabelES?: string | null;
    IsMultiChoice?: boolean;
    Products?: Product[];
    ViewOrder: any;    

    public getLabel(language: string): string {
        switch(language){
            case Languages.English: return this.LabelEN ?? '';
            case Languages.French: return this.LabelFR ?? '';
            case Languages.Spanish: return this.LabelES ?? '';
            default: return this.LabelEN ?? '';
        }
    }
}