import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { WebsiteRegex } from 'src/app/consts/utils';
import { FormCode } from 'src/app/enums/form-code';
import { AwardsRoutes, AwardsRoutesQueryParamsNames, AwardsRoutesStep } from '../awards.routes';

@Component({
  selector: 'app-awards-form-generalites',
  templateUrl: './awards-form-generalites.component.html',
  styleUrls: ['./awards-form-generalites.component.less']
})
export class AwardsFormGeneralitesComponent {
  frmData: any;
  initializedForm: boolean = false;
  awardForm!: FormGroup;
  submitted: boolean = false;
  isPublicationDelayed: boolean = false
  showFixPublicationDate: boolean = false
  editionOpeningDate: Date = new Date()
  isEditMode: boolean = false;
  AwardFormID!: number;
  awardData: any;
  awardId: any;
  folderGuid: any

  awardFrmId: any;
  previousRoute: string = AwardsRoutes.companyInfo;

  returnUrl : string | undefined

  constructor(public app: AppComponent,
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private messageService: MessageService,
  ) {
  }

  async ngOnInit() {
    this.activatedRouter.queryParams.subscribe(async (data: any) => {
      if (data && data[AwardsRoutesQueryParamsNames.folderGuid]) {
        this.folderGuid = data[AwardsRoutesQueryParamsNames.folderGuid];
        let awardFormData: any = {
          FolderGuid: this.folderGuid
        }
        sessionStorage.setItem(JSON.stringify(awardFormData),this.folderGuid);
       await this.getAwardFormInfo();
      }      
      else {
        this.getFormAwardData();
      }

      if (data && data[AwardsRoutesQueryParamsNames.awardId]) {
        this.awardId = data[AwardsRoutesQueryParamsNames.awardId]
        console.log("this.awardId", this.awardId);
        await this.getAwardSolutionInfo();
        this.isEditMode=true;
      }

      this.initForm();
    });
    let awardFormData = JSON.parse(sessionStorage.getItem("award-form") ?? "{}")
    if (awardFormData) {
      this.awardFrmId = awardFormData?.AwardFormID
      this.folderGuid = awardFormData?.FolderGuid
    }
    await this.getFrmAwardInfo();
    this.checkAwardSolutions();
    this.returnUrl = `/${AwardsRoutes.companyInfo}?${AwardsRoutesQueryParamsNames.folderGuid}=${this.folderGuid}`;
  }

  async getFrmAwardInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_AWARD
    let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
    if (data != undefined && data.Id != undefined) {
      this.frmData = data
    }
  }
  
  async getAwardSolutionInfo() {
    console.log("this.awardId", this.awardId);
    this.awardData = await this.app.apiService.getAwardSolutionById(this.awardId)
    console.log(this.awardData)
  }

  initForm() {
    console.log("awardData", this.awardData);
    if(this.awardData == undefined || this.awardData == null){
      this.awardData = {}
    }
    this.awardForm = this.formBuilder.group({
      NameFR: new FormControl(this.awardData?.NameFR ?? "", [Validators.required, Validators.maxLength(60)]),
      SolUrl: new FormControl(this.awardData?.SolUrl ?? "", [Validators.pattern(WebsiteRegex)]),
      IsMultiWriter: new FormControl(this.awardData?.IsMultiWriter ?? ""),
    });
    this.initializedForm = true;
  }

  onSolUrlInput() {
    const solNameFRElement = this.awardForm.get('NameFR');
    const solNameENElement = this.awardForm.get('NameFR');

    if (solNameFRElement && solNameFRElement.value === '' && solNameENElement) {
      solNameFRElement.setValue(solNameENElement.value);
    }
  }

  onIsMultiWriterChange(event: any): void {
    this.submit(false);
  }

  submit(andRedirect: boolean = true) {
    this.submitted = true;
    this.messageService.clear();
    if (this.awardForm.invalid) {
      const invalidValues: any = {};
      const controls = this.awardForm.controls;
      let invalidFields: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('ng-invalid') as HTMLCollectionOf<HTMLElement>;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log('Invalid form values:', invalidValues);
      console.log('this.newProductForm:', this.awardForm);

      if (invalidFields && invalidFields.length > 1) this.app.formInvalid.addSubmittedInvalidClass(invalidFields);

      this.messageService.add({ key: 'custom', severity: 'error', summary: this.app.translate.instant('racine.errorsOther.errorForm'), detail: '', sticky: true });
    }
    else {
      console.log("this.awardForm", this.awardForm);
      let awardForm: any = this.awardForm.value;
      console.log("awardForm",awardForm);
      this.createAward(awardForm, andRedirect);
    }
  }
  onPreviousStep() {
    let queryparams: any = {
      [AwardsRoutesQueryParamsNames.folderGuid] : this.folderGuid
    }
    this.router.navigate([this.previousRoute], {
      queryParams: queryparams
    })
  }
  async createAward(awardObj: any, andRedirect: boolean): Promise<any> {
    try {
      if (!this.isEditMode) {
        awardObj.AwardFormID = this.awardFrmId;
        awardObj.SolutionLastStep = AwardsRoutesStep.generalities;
        console.log("🚀 ~ AwardsFormGeneralitesComponent ~ createAward ~ this.awardFrmId:", this.awardFrmId)
        console.log(
          awardObj.AwardFormID
        )
        const response = await this.app.apiService.createAwardSolution(awardObj);
        console.log(response)
        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }
        this.awardId = response.ID;
        sessionStorage.setItem("awardId",JSON.stringify(this.awardId));
        console.log(this.awardId)
        this.awardForm.get('ID')?.setValue(this.awardId);
        this.awardForm.get('ID')?.updateValueAndValidity();
        this.isEditMode = true;
        if (andRedirect) {
          this.onNextStep();
        }
      }
      else {
        awardObj.ID = this.awardId;
        this.awardData.SolUrl = awardObj.SolUrl;
        this.awardData.IsMultiWriter = awardObj.IsMultiWriter
        this.awardData.NameFR = awardObj.NameFR;
        awardObj.SolutionLastStep = AwardsRoutesStep.generalities;
        const response = await this.app.apiService.updateAwardSolution(this.awardData);
        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }
        if (andRedirect) {
          this.onNextStep();
        }
      }

    } catch (error) {
      console.error(error);
    }
  }
  onNextStep(): void {
    let queryparams: any = {
      [AwardsRoutesQueryParamsNames.awardId] : this.awardId
    }
    console.log('query params', this.awardId);
    this.app.sharedTools.redirectToWithParameters(AwardsRoutes.description, queryparams);
  }
  private getFormAwardData() {
    console.log(sessionStorage.getItem('award-form'))
    let awardform: any = JSON.parse(sessionStorage.getItem('award-form') ?? '{}');
    console.log(awardform)
    if (awardform && awardform.AwardFormID) {
      this.awardFrmId = awardform.AwardFormID;
    }
  }
  
  async getAwardFormInfo() {
    let response = await this.app.apiService.getAwardFormWelcom(this.folderGuid)
    if (!response.Result) {
      sessionStorage.setItem("award-form", JSON.stringify(response));
      this.awardFrmId = response.AwardFormID;
    }
  }
  

  private async checkAwardSolutions(): Promise<void> {
    let response = await this.app.apiService.checkAwardSolutions(this.awardFrmId);

    if(!response || response.Result){
      console.log(response);
      return;
    }

    console.log(response)

    if(response.NbMaxProduct > 1){
      this.previousRoute = AwardsRoutes.productList;
    }
  }
}
