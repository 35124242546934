import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { Subscription } from 'rxjs';
import { ProductsGroup } from '../models/products-group';
import { Product } from '../models/product';
import { ExhibitorTypeCode } from '../enums/exhibitor-type-code';
import { ProductsGroupsResponseOrder } from '../consts/products-groups-response-order';
import { MessageService } from 'primeng/api';
import { CommandeService } from '../services/commande.service';
import { DatePipe } from '@angular/common';
import { Cart } from '../models/cart';
import { TranslatePipe } from '@ngx-translate/core';
import { CartItem } from '../models/cart-item';
import { Nullable } from 'primeng/ts-helpers';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-prestations',
    templateUrl: './prestations.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./prestations.component.less']
})
export class PrestationsComponent implements OnInit, OnDestroy {
    //#region local properties
    //subscriptions
    private onLangChangeSubscription?: Subscription;
    private getProductsgroupsSubscription?: Subscription;
    private getCartSubscription?: Subscription;
    private addupdateCartSubscription?: Subscription;
    private getIndirectNamesSubscription?: Subscription;

    // products of each group
    attendanceFeesProducts: Product[] = [];
    surfacesProducts: Product[] = [];
    optionsProducts: Product[] = [];

    // Exhibitors products
    directExhibitorProduct: Product = new Product();
    indirectExhibitorProduct: Product = new Product();

    //groups
    productsGroups: ProductsGroup[] = [];

    attendanceFeesProductsGroup: ProductsGroup = new ProductsGroup();
    surfacesProductsGroup: ProductsGroup = new ProductsGroup();
    optionsProductsGroup: ProductsGroup = new ProductsGroup();

    // indirectExhibitor of current folder list
    indirectExhibitorsOfCurrentFolder: string[] = [];

    //current language : 'fr', 'en', 'es
    currentLanguage: string;

    //selected product : useful when group.IsMultiChoice is false ===> single choice
    selectedSurfaceProduct: Product = new Product();
    selectedOptionProduct: Product = new Product();

    //selected products : useful when group.IsMultiChoice is true ===> single choice
    selectedSurfaceProducts: Product[] = [];
    selectedOptionProducts: Product[] = [];

    //current Cart
    cart?: Cart;

    //
    haveNonSavedCartItem: boolean = false;

    //current user
    user: any = null;

    products: any = [];
    selectedSurface: any = null;
    //#endregion

    //#region ctor
    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private commandeService: CommandeService,
        private datePipe: DatePipe,
        private translatePipe: TranslatePipe
    ) {
        //init user
        this.user = JSON.parse(sessionStorage.getItem('userData')!);

        //init Cart Items
        // this.surfaceReservationCarteItem = new CartItem(this.user.FolderID);
        // this.optionReservationCarteItem = new CartItem(this.user.FolderID);

        //init currentLanguage
        this.currentLanguage = app.translate.currentLang;
    }
    //#endregion

    //#region handle lifecycle hooks
    ngOnInit() {
        console.log("this.user ==> ", this.user);
        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }

        //retrieve products groups from remote api
        this.getProductsGroups();
        this.getIndirectExhibitorsNames();

        this.initOnLangChangeHandler();
    }

    ngOnDestroy(): void {
        this.unsubscribeActiveSubscriptions();
    }
    //#endregion

    //#region handle events
    onSurfaceQuantityFocusOut(product: Product, value: Nullable<number>): void {
        console.log("onSurfaceQuantityFocusOut product ==> ", product);
        let newQuantity: number | undefined = value?.valueOf();
        this.handleSurfaceQuantityFocusOut(product, newQuantity);
    }

    onSelectedSurfaceChange(product: Product): void {
        if (this.selectedSurfaceProduct
            && this.selectedSurfaceProduct.ProductID != product.ProductID) {
            let cartItem = this.createCartItem(product);
            this.addupdateCartItem(cartItem);
            this.selectedSurfaceProduct = product;
        }
    }

    onOptionQuantityFocusOut(product: Product, value: Nullable<number>): void {
        let newQuantity: number | undefined = value?.valueOf();
        this.handleOptionQuantityFocusOut(product, newQuantity);
    }

    onSelectedOptionChange(product: Product): void {
        if (this.selectedOptionProduct
            && this.selectedOptionProduct.ProductID != product.ProductID) {
            let cartItem = this.createCartItem(product);
            this.addupdateCartItem(cartItem);
            this.selectedOptionProduct = product;
        }
    }

    formatDate(date: string | undefined | null): string | null {
        return this.datePipe.transform(date, 'd MMMM y', undefined, this.currentLanguage)
    }

    isSurfaceProductQuantityInputDisabled(prodcut: Product): boolean {
        return !this.surfacesProductsGroup.IsMultiChoice
            && prodcut.ProductID != this.selectedSurfaceProduct.ProductID;
    }

    isOptionProductQuantityInputDisabled(prodcut: Product): boolean {
        return !this.optionsProductsGroup.IsMultiChoice
            && prodcut.ProductID != this.selectedOptionProduct.ProductID;
    }

    onQuantityKeyUp(product: Product, value: Nullable<number>): void {
        let newQuantity: number | undefined = value?.valueOf();
        if (newQuantity === product.Quantity) {
            this.endEditingCartItem();
        }
        else {
            this.startEditingCartItem();
        }
    }

    getNextActionButtonLabel(): string {
        return this.translatePipe.transform(this.haveNonSavedCartItem ? 'racine.generalites.validerBtn' : 'racine.generalites.continueBtn');
    }

    getQuantityLabel(quantity: number | undefined | null): string {
        return (quantity && quantity > 0) ? `${quantity} x ` : '';
    }

    onNextClick(): void {
        if (this.haveAtLeastOnePrestation()) {
            this.app.sharedTools.goTo('/recapitulatif');
        }
        else {

            this.app.confirm.confirmDialog(""
                , this.translatePipe.transform('fo.prestationsPage.mustOrderAtLeastOneSurfaceProduct')
                , 'KO'
                , "alert"
                , () => { }
                , () => { });
        }
    }
    //#endregion

    //#region private methods
    private getProductsGroups(): void {
        this.getProductsgroupsSubscription = this.app.apiService.getProductsgroups().subscribe({
            next: (data: ProductsGroup[]) => {
                console.log('getProductsgroupsSubscription', data)
                // this.messageService.add({
                //   severity: 'success'
                //   , summary: this.translatePipe.transform('fo.prestationsPage.requestSuccessHeader')
                //   , detail: this.translatePipe.transform('fo.prestationsPage.retrievedSuccessfully')
                // });
                this.processGetProductsGroupsSuccess(data);
            },
            error: (error: any) => {
                console.log(error);
                // this.messageService.add({
                //   severity: 'error'
                //   , summary: this.translatePipe.transform('fo.prestationsPage.requestErrorHeader')
                //   , detail: this.translatePipe.transform('fo.prestationsPage.errorRetrieving')
                // });
            },
        })
    }

    private processGetProductsGroupsSuccess(data: ProductsGroup[]): void {
        //save response data to local property
        this.productsGroups = data.map(pg => {
            let newProductsGroup: ProductsGroup = new ProductsGroup();

            pg.Products = pg.Products?.map((p: Product) => {
                let newProduct: Product = new Product();
                Object.assign(newProduct, p);

                //initialize Product Quantity to Product MinQuantity
                if (!newProduct.Quantity) {
                    newProduct.Quantity = newProduct.MinQuantity ?? 0;
                }

                return newProduct;
            });

            Object.assign(newProductsGroup, pg);

            return newProductsGroup;
        });

        //parse groups to get products of each group
        if (this.productsGroups) {
            //retrieve attendanceFees group
            this.attendanceFeesProductsGroup = this.productsGroups[ProductsGroupsResponseOrder.AttendanceFeesProductsGroup];

            //retrieve attendanceFeesProducts from attendanceFees group
            if (this.attendanceFeesProductsGroup) {
                this.attendanceFeesProducts = this.attendanceFeesProductsGroup.Products ?? [];

                //retrieve directExhibitorProduct
                let findDirectExhibitorProduct = this.attendanceFeesProducts
                    .find(p => p.Code === ExhibitorTypeCode.DirectExhibitor);

                if (findDirectExhibitorProduct) {
                    this.directExhibitorProduct = findDirectExhibitorProduct;
                }

                //retrieve indirectExhibitorProduct
                let findIndirectExhibitorProduct = this.attendanceFeesProducts
                    .find(p => p.Code === ExhibitorTypeCode.IndirectExhibitor);

                if (findIndirectExhibitorProduct) {
                    this.indirectExhibitorProduct = findIndirectExhibitorProduct;
                }
            }

            //retrieve surfaces group
            this.surfacesProductsGroup = this.productsGroups[ProductsGroupsResponseOrder.SurfacesProductsGroup];

            //retrieve surfacesProducts from surfaces group
            if (this.surfacesProductsGroup) {
                let surfacesProducts = this.surfacesProductsGroup.Products ?? [];

                this.FormatSurfacesProducts(surfacesProducts);

                // this.surfacesProducts = this.surfacesProductsGroup.Products ?? [];
                // for testing purposes u can enable this code to see no promo product
                // this.surfacesProducts[1].IsPromoAvailable = false;
            }

            //retrieve options group
            this.optionsProductsGroup = this.productsGroups[ProductsGroupsResponseOrder.OptionsProductsGroup];

            //retrieve optionsProducts from options group
            if (this.optionsProductsGroup) {
                this.optionsProducts = this.optionsProductsGroup.Products ?? [];
            }
        }

        console.log('getProductsGroups: '
            , this.productsGroups
            , this.attendanceFeesProducts
            , this.surfacesProducts
            , this.optionsProducts);

        this.getCartAndInitPrestations();
    }

    async FormatSurfacesProducts(surfacesProducts: any) {
        const promises = surfacesProducts.map(async (prd: Product) => {
            if (prd.Code == "SURF2") {
                try {
                    const data: any = await this.app.apiService.getIndirectNames(this.user.FolderID).toPromise();
                    const indirectExhibitorsOfCurrentFolder = data;
                    return this.formatProduct(indirectExhibitorsOfCurrentFolder, prd);
                } catch (error) {
                    console.log(error);
                }
            }
            return prd;
        });

        try {
            const updatedProducts = await Promise.all(promises);
            console.log("onSurfaceQuantityFocusOut updatedProducts ==> ", updatedProducts);
            this.surfacesProducts = updatedProducts; //this.surfacesProductsGroup.Products ?? [];
        } catch (error) {
            console.log(error);
        }
    }

    formatProduct(_list: any, _product: Product) {
        let prd: Product = cloneDeep(_product);
        let minQtyOld: any = cloneDeep(prd.MinQuantity);
        let newMinQty: number = 12;

        if (_list.length > 1) {
            prd.MinQuantity = newMinQty;
            prd.Quantity = (prd.Quantity && prd.Quantity < newMinQty) ? newMinQty : prd.Quantity;
            prd.HelpTextEN = prd.HelpTextEN?.replace(minQtyOld.toString(), newMinQty.toString());
            prd.HelpTextFR = prd.HelpTextFR?.replace(minQtyOld.toString(), newMinQty.toString());
            prd.HelpTextES = prd.HelpTextES?.replace(minQtyOld.toString(), newMinQty.toString());
        }

        //console.log('[prd]', prd);
        return prd;
    }

    private handleSurfaceQuantityFocusOut(product: Product, newQuantity: number | undefined): void {

        this.handleQuantityFocusOut(product
            , this.selectedSurfaceProduct
            , this.surfacesProductsGroup
            , this.selectedSurfaceProducts
            , newQuantity);
    }

    private handleOptionQuantityFocusOut(product: Product, newQuantity: number | undefined): void {
        this.handleQuantityFocusOut(product
            , this.selectedOptionProduct
            , this.optionsProductsGroup
            , this.selectedOptionProducts
            , newQuantity);
    }

    private handleQuantityFocusOut(product: Product
        , selectedProduct: Product
        , productsGroup: ProductsGroup
        , selectedProducts: Product[]
        , newQuantity: number | undefined): void {
        //when ProductsGroup IsMultiChoice is true (wich means can select many products)
        // ===>add/update/delete cart item
        if (productsGroup.IsMultiChoice) {
            let findProduct = selectedProducts.find(p => p.ProductID === product.ProductID);

            //when product is found and quantity is updated ===> update or delete
            if (findProduct && findProduct.Quantity != newQuantity) {
                //when quantity is 0 ===> delete
                if (!newQuantity || newQuantity === 0) {
                    product.Quantity = newQuantity;
                    this.deleteCartItemFromProduct(product);
                }
                //when quantity != 0 ===> update
                else {
                    findProduct.Quantity = newQuantity;
                    this.addupdateCartItemFromProduct(findProduct);
                }
            }
            //when product is new and have quantity > 0 ===> add
            else if (!findProduct && newQuantity && newQuantity > 0) {
                product.Quantity = newQuantity;
                this.addupdateCartItemFromProduct(product);
                this.selectedOptionProducts.push(product);
            }
        }
        //when ProductsGroup IsMultiChoice is false (wich means can select only one product)
        // ===> addupdate cart item
        else {
            //when product is selected and quantity is updated ===> addupdate
            //and api is going to handle single cart item
            if (product.ProductID === selectedProduct.ProductID
                && selectedProduct.Quantity != newQuantity) {
                product.Quantity = newQuantity;
                this.addupdateCartItemFromProduct(product);
            }
        }
    }

    private addupdateCartItemFromProduct(product: Product): void {
        let cartItem: CartItem = this.createCartItem(product);

        this.addupdateCartItem(cartItem);
    }

    private deleteCartItemFromProduct(product: Product): void {
        let cartItem: CartItem = this.createCartItem(product);

        this.deleteCartItem(cartItem);
    }

    private addupdateCartItem(cart: CartItem): void {

        if (cart.ProductID && cart.ProductID != 0) {
            console.log('addupdateCart ', cart);
            this.addupdateCartSubscription = this.app.apiService.addupdateCartItem(cart).subscribe({
                next: (data: any) => {

                    console.log('Cart item added ', data);

                    //must update cart property after delete or update 
                    //it is used to validate if user can go next step
                    this.processGetCartSuccess(data);

                    this.messageService.add({
                        severity: 'success'
                        , summary: this.translatePipe.transform('fo.prestationsPage.requestSuccessHeader')
                        , detail: this.translatePipe.transform('fo.prestationsPage.addupdateCartItemSuccessfully')
                    });

                    this.updateCurrentCommande();

                },
                error: (error: any) => {
                    console.log(error);
                    this.messageService.add({
                        severity: 'error'
                        , summary: this.translatePipe.transform('fo.prestationsPage.requestErrorHeader')
                        , detail: this.translatePipe.transform('fo.prestationsPage.errorAddUpdateCartItem')
                    });
                },
                complete: () => {
                    this.endEditingCartItem();
                }
            });
        }

    }

    private deleteCartItem(cart: CartItem): void {

        if (cart.ProductID && cart.ProductID != 0) {
            this.addupdateCartSubscription = this.app.apiService.deleteCartItem(cart).subscribe({
                next: (data: any) => {

                    console.log('Cart item deleted ', data);

                    //must update cart property after delete or update 
                    //it is used to validate if user can go next step
                    this.processGetCartSuccess(data);

                    this.messageService.add({
                        severity: 'success'
                        , summary: this.translatePipe.transform('fo.prestationsPage.requestSuccessHeader')
                        , detail: this.translatePipe.transform('fo.prestationsPage.deleteCartItemSuccessfully')
                    });

                    this.updateCurrentCommande();

                },
                error: (error: any) => {
                    console.log(error);
                    this.messageService.add({
                        severity: 'error'
                        , summary: this.translatePipe.transform('fo.prestationsPage.requestErrorHeader')
                        , detail: this.translatePipe.transform('fo.prestationsPage.errorDeleteCartItem')
                    });
                },
                complete: () => {
                    this.endEditingCartItem();
                }
            });
        }

    }

    private initOnLangChangeHandler(): void {
        this.onLangChangeSubscription = this.app.translate.onLangChange.subscribe((vale: any) => { this.handleLangChange() });
    }

    private handleLangChange(): void {
        this.currentLanguage = this.app.translate.currentLang;
    }

    private unsubscribeActiveSubscriptions(): void {
        if (this.onLangChangeSubscription) {
            this.onLangChangeSubscription.unsubscribe();
        }

        if (this.getProductsgroupsSubscription) {
            this.getProductsgroupsSubscription.unsubscribe();
        }

        if (this.getCartSubscription) {
            this.getCartSubscription.unsubscribe();
        }

        if (this.addupdateCartSubscription) {
            this.addupdateCartSubscription.unsubscribe();
        }

        if (this.getIndirectNamesSubscription) {
            this.getIndirectNamesSubscription.unsubscribe();
        }
    }

    private updateCurrentCommande(): void {
        this.commandeService.commandeChanged();
    }

    private createCartItem(product: Product): CartItem {
        let cartItem: CartItem = new CartItem();

        cartItem.FolderID = this.user.FolderID;
        cartItem.ProductID = product.ProductID;
        cartItem.Quantity = product.Quantity;

        return cartItem;
    }

    private getCartAndInitPrestations(): void {
        this.getCartSubscription = this.app.apiService
            .getCart(this.user.FolderID).subscribe({
                next: (data: Cart) => {
                    this.processGetCartSuccess(data);

                    this.initPrestationsFromCart();
                },
                error: (error: any) => {
                    console.log(error);
                }
            });
    }

    private processGetCartSuccess(data: Cart): void {
        //save response data to local property
        let newCart: Cart = new Cart();

        data.CartItems = data.CartItems?.map((ci: CartItem) => {
            let newCartItem: CartItem = new CartItem();
            Object.assign(newCartItem, ci);

            return newCartItem;
        });

        Object.assign(newCart, data);

        this.cart = newCart;
    }

    private initPrestationsFromCart(): void {
        if (this.cart?.CartItems && this.cart?.CartItems.length > 0) {
            //init surfaces products
            this.surfacesProducts.forEach(p => {
                let findRelativeCartItem: CartItem | undefined
                    = this.cart?.CartItems?.find(ci => ci.ProductID === p.ProductID);

                if (findRelativeCartItem) {
                    p.Quantity = findRelativeCartItem.Quantity;

                    //add product to selected list products if IsMultipleChoice is true 
                    if (this.surfacesProductsGroup.IsMultiChoice) {
                        this.selectedSurfaceProducts.push(p);
                    }
                    //init selected product if IsMultipleChoice is false 
                    else {
                        this.selectedSurfaceProduct = p;
                    }
                }
            });

            //init options products
            this.optionsProducts.forEach(p => {
                let findRelativeCartItem: CartItem | undefined
                    = this.cart?.CartItems?.find(ci => ci.ProductID === p.ProductID);

                if (findRelativeCartItem) {
                    p.Quantity = findRelativeCartItem.Quantity;

                    //add product to selected list products if IsMultipleChoice is true 
                    if (this.optionsProductsGroup.IsMultiChoice) {
                        this.selectedOptionProducts.push(p);
                    }
                    //init selected product if IsMultipleChoice is false 
                    else {
                        this.selectedOptionProduct = p;
                    }
                }
            });
        }

        console.log('after init : '
            , this.selectedSurfaceProduct
            , this.selectedSurfaceProducts
            , this.selectedOptionProduct
            , this.selectedOptionProducts)
    }

    private startEditingCartItem(): void {
        this.haveNonSavedCartItem = true;
    }

    private endEditingCartItem(): void {
        this.haveNonSavedCartItem = false;
    }

    private getIndirectExhibitorsNames(): void {
        this.getIndirectNamesSubscription = this.app.apiService
            .getIndirectNames(this.user.FolderID).subscribe({
                next: (data: Array<string>) => {
                    this.indirectExhibitorsOfCurrentFolder = data;
                },
                error: (error: any) => {
                    console.log(error);
                }
            });
    }

    private haveAtLeastOnePrestation(): boolean {
        let haveAtLeastOnePrestation: boolean = false;

        let orderedSurfacesProducts = this.cart?.CartItems?.filter((c: CartItem) => this.isSurfaceProduct(c.ProductID));
        if (orderedSurfacesProducts && orderedSurfacesProducts.length > 0) {
            haveAtLeastOnePrestation = true;
        }

        return haveAtLeastOnePrestation;
    }

    private isSurfaceProduct(productID?: number): boolean {
        if (productID) {
            let findSurfaceProduct: Product | undefined = this.surfacesProducts.find((p: Product) => p.ProductID === productID);

            if (findSurfaceProduct) {
                return true;
            }
        }
        return false;
    }
    //#endregion
}
