import { Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Table } from 'primeng/table';
import { Menu } from 'primeng/menu';
import { AppComponent } from '../app.component';
import { SearchFolder } from '../models/search-folder';
import { MessageService } from 'primeng/api';
import { DataView, DataViewLayoutOptions } from 'primeng/dataview';
import { cloneDeep, forEach } from 'lodash';
import { Subscription, firstValueFrom } from 'rxjs';
import { StatusType } from '../enums/status-type';
import { FolderStatus } from '../enums/folder-status';
import { FolderSearchHelperService } from '../services/folder-search-helper.service';
import { DPAdvancedSearchLocalStorageKey, hasAnyNonNullProperty } from '../consts/utils';
import { DatePipe } from '@angular/common';
import { HelperService } from '../services/helper.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { StatusCode } from '../enums/status-code';
import { RoleTypeId } from '../enums/role-type-id';
import { IdentityManagerService } from '../services/identity-manager.service';
import { PagePaginationInfo } from '../models/page-pagination-info';
import { SearchHelperService } from '../services/search-helper.service';
import { Languages } from '../enums/language';

@Component({
    selector: 'app-gestion-dp',
    templateUrl: './gestion-dp.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./gestion-dp.component.less']
})
export class GestionDpComponent implements OnDestroy {
    //#region local properties
    //subscriptions
    private foldersExportSubscription?: Subscription;
    private companyAutocompleteSubscription?: Subscription;

    @ViewChild('menuAction') menuAction!: Menu;
    @ViewChild(Table) dtFolders!: Table;
    @ViewChild('dvFolders') dvFolders!: DataView;

    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }
    exportSearch: any;
    layout: any = 'grid';
    groupedActions: any;
    showTable: boolean = true;
    rangeDates: Date[] = [];
    maxDate = new Date();
    search: any = new SearchFolder();
    showAdvancedSearch: boolean = false;
    fastFiltersFolder: any = ['NumExposant', 'CompanyName', 'ContactName', 'ContactEmail', 'ContactPhone',
     'Surface', 'AmountHT','EmbdedExhibitors'];
    folders: any = [];
    status: any = [];
    webPublisheStatus: any = []
    stats: any = [];
    categories: any = [];
    catTypeActivities: any = [];
    catTypeNature: any = [];
    catTypeStand: any = [];
    countries: any = [];
    colsFolders: any = [];
    exportcolsFolders: any = [];
    items: any = [];
    selectedFolders: any = [];
    selectedAction: any;
    _selectedColumns: any = [];

    companyNameSuggestions: string[] = [];

    fastSearchFolderKeyword: string = '';
    showPublishModal: boolean = false
    content: string = ""
    acceptBtnLabel: string = ""
    rejectBtnLabel: string = ""
    customBtnLabel: string = ""
    actions: any[] = []
    _selectedAction: any
    commercials: any[] = []
    isCheckboxCommercialDisabled : boolean = false 
    // autorisedUserCommercialChange : any[]=[1,25,37]
    autorisedUserCommercialChange : any[]=[RoleTypeId.DP_SuperAdmin, RoleTypeId.ADMIN_FINDERR, RoleTypeId.DP_Administrator]

    currentPageTable : number = 1
    currentPageIndex : number = 1
    rowsCapacity : number = 50
    pagePaginationInfo : PagePaginationInfo = {pageNumber : 1, rowSize : 50}
    isLoaded : boolean = false
    showModal: boolean = false;
    msgAlertImport: string = "";
    @ViewChild('fileInput') fileInput?: ElementRef;


  private onLangChangeSubscription?: Subscription;
    // unlockedCustomers: any = [];
    // lockedCustomers: any = [];
    //#endregion

    constructor(
        public app: AppComponent,
        public helperService: HelperService,
        public translalteService: TranslateService,
        public translatePipe: TranslatePipe,
        private messageService: MessageService,
        private folderSearchHelperService: FolderSearchHelperService,
        private datePipe: DatePipe,
        private ims: IdentityManagerService,
        private searchHelperService : SearchHelperService
    ) { }
    ngOnDestroy(): void {
        this.unsubscribeActiveSubscriptions();
    }

    onchangeSelect(_item: any) {
        if (!_item) return;
        //console.log("onchangeSelect", _item);
    }

    async onchangeStatus(_folder: any) {
        console.log("onchangeStatus folder ==> ", _folder);
        if (!_folder.selectedStatus) return;

        let objStatus: any = {
            StatusID: _folder.selectedStatus.StatusID,
            FolderID: _folder.FolderID
        }

        console.log("objStatus ==> ", objStatus);

        try {
            const response = await this.app.apiService.changestatus(objStatus);
            console.log("apiService.changestatus ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.getstats();
            this.searchFolders(this.search, false, true);

        } catch (error) {
            console.error(error);
        }
    }

    pageChanged(event : any){
        let pageIndex = event.first/event.rows + 1
        this.currentPageIndex = pageIndex
        this.updatePaginationInfo()
    }
    rowsChanged(event : any){
        this.rowsCapacity = event
        this.updatePaginationInfo()
    }

    updatePaginationInfo(){
        let pagePaginationInfo : PagePaginationInfo={
            pageNumber : this.currentPageIndex,
            rowSize : this.rowsCapacity
        }
        this.searchHelperService.setPagePaginationInfo(pagePaginationInfo,DPAdvancedSearchLocalStorageKey)
    }

    onchangePublishStatus(folder: any) {
        console.log("selected folder from publish status ==>", folder)
        if (!folder.webPublishedSelectedStatus) {
            return;
        }
        let folderStatus: any = {
            StatusID: folder.webPublishedSelectedStatus.StatusID,
            FolderID: folder.FolderID
        }
        let foldersStatusArray = []
        foldersStatusArray.push(folderStatus);
        console.log("array sended to api ==>", foldersStatusArray);
        this.app.apiService.changeWebPublishedFolderState(foldersStatusArray).subscribe(
            data => {
                this.getstats();
                this.searchFolders(this.search, false, true);
            }
        )
    }

    filterFast() {
        this.folderSearchHelperService.fastSearchKeyword = this.fastSearchFolderKeyword;
        //this.dvFolders.filter(_value, 'contains');
        this.dtFolders?.filterGlobal(this.fastSearchFolderKeyword, 'contains');
        const filteredFolders = this.folders.filter((fld:any) => {
            return fld?.LinkedFolders.some((x:any) => {
                return this.fastSearchFolderKeyword !== "" && (x?.CompanyName?.toUpperCase()?.indexOf(this.fastSearchFolderKeyword?.toUpperCase()) !== -1 || x?.CatalogName?.toUpperCase()?.indexOf(this.fastSearchFolderKeyword?.toUpperCase()) !== -1 );
            });
        });
        this.autoExpandRows(filteredFolders);
        this.dtFolders.first = this.currentPageTable
    }

    ngOnInit() {
        //get privious advanced search value before routing to other routes
        this.search = cloneDeep(this.folderSearchHelperService.advancedSearch) ?? {};
        this.fastSearchFolderKeyword = this.folderSearchHelperService.fastSearchKeyword;

        this.pagePaginationInfo = this.searchHelperService.getPagePaginationInfo(DPAdvancedSearchLocalStorageKey)
        this.rowsCapacity = this.pagePaginationInfo.rowSize ?? 50
        this.ims.checkIsUserAuthenticated();
        this.isCheckboxCommercialDisabled = !this.ims.haveAuthorizedRole(this.autorisedUserCommercialChange);     

        // this.colsFolders = this.app.translate.("racine.generalites.columns");
        this.app.sharedTools.getJSON('general_data').subscribe(
            async (resG) => {
                console.log("resG", resG);
                this.groupedActions = resG.groupedActions;
                this.items = resG.actionsRow;

                this.items.forEach((itemMenu: any) => {
                    itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
                });

                this.colsFolders = resG.colsFolders;

                this._selectedColumns = this.colsFolders;
                this.exportcolsFolders = this._selectedColumns.map((col: any) => ({ title: col.header, dataKey: col.header }));
                this.getstats();
                await this.getStatus();
                this.getCategories();
                this.getCountries();
                this.getCommercialList()
                this.searchFolders(this.search, false, true);
            });
        this.getFolderActionFromJson(); 
        this.onLangChangeSubscription = this.app.translate.onLangChange.subscribe((vale: any) => { this.handleLangChange() });
    }
    private handleLangChange(): void {
        this.getFolderActionFromJson()
      }

    getFolderActionFromJson() {
        this.app.sharedTools.getJSON('general_data').subscribe(
            data => {
                console.log("current language ==> ", this.translalteService.currentLang)
                console.log("json data ", data)
                this.actions = []
                if (this.translalteService.currentLang == "fr") {
                    data.actionFolderTable.forEach((action: any) => {
                        let actionModel = {
                            label: action.LabelFR,
                            ActionID: action.ActionID
                        }
                        this.actions.push(actionModel);
                    })
                }
                if (this.translalteService.currentLang == "en") {
                    data.actionFolderTable.forEach((action: any) => {
                        let actionModel = {
                            label: action.LabelEN,
                            ActionID: action.ActionID
                        }
                        this.actions.push(actionModel);
                    })
                }
                if (this.translalteService.currentLang == "es") {
                    data.actionFolderTable.forEach((action: any) => {
                        let actionModel = {
                            label: action.LabelES,
                            ActionID: action.ActionID
                        }
                        this.actions.push(actionModel);
                    })
                }

                console.log("actions of dropdown btn ==> ", this.actions)
            }
        )
    }


    onActionSelectChange(event: any) {
        if (event.value.ActionID == 1) {
            this.content = this.app.translate.instant('bo.dossiers-dp.publishSelectedFoldersConfirm')
            this.acceptBtnLabel = this.app.translate.instant('bo.dossiers-dp.acceptPublishBtnLabel')
            this.rejectBtnLabel = this.app.translate.instant("bo.dossiers-dp.rejectPublishBtnLabel")
            this.customBtnLabel = this.app.translate.instant("bo.dossiers-dp.customPublishBtnLabel")
            this.showPublishModal = true
        }
    }

    toggleLayout() {
        this.showTable = !this.showTable;
    }

    toggleMenu(event: any, folderId: number, items: any) {
        this.menuAction.toggle(event);

        console.log("Folder ID:", folderId);
        console.log("items:", items);

        items.forEach((itemMenu: any) => {
            itemMenu.folderIdRow = folderId;
            itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

            if (itemMenu.ActionID == 1) {
                itemMenu.command = (event: any) => {
                    this.app.sharedTools.redirectTo('/detail-dp/' + event.item.folderIdRow.toString());
                }
            }
            //TODO: @Why: this code commented by @Hassen, @Why: found only one action general_data.json property [actionsRow]
            //TODO: so added second action with ActionID == 2
            // if (itemMenu.ActionID == 2) {
            //     itemMenu.command = (event: any) => {
            //         this.download(event.item.folderIdRow);
            //     }
            // }
            // if (itemMenu.ActionID == 3) {
            //     itemMenu.command = (event: any) => {
            //         // ==> Call function
            //     }
            // }
            if (itemMenu.ActionID == 2) {
                itemMenu.command = (event: any) => {
                    this.consultDirectExhibitor(event.item.folderIdRow);
                }
            }
        });
    }

    onDateChange(ev: any) {
        if (!ev) return;
        this.search.SubmissionDateStart = (ev[0]) ? ev[0] : this.search.SubmissionDateStart;
        this.search.SubmissionDateEnd = (ev[1]) ? ev[1] : this.search.SubmissionDateEnd;
    }

    set selectedColumns(val: any[]) {
        // ==> restore original order
        this._selectedColumns = this.colsFolders.filter((col: any) => val.includes(col));
    }

    // TODO finir qaund il y aura tous les états
    getColorStatus(statusID: any) {
        let classColor = "";
        switch (statusID) {
            case 1:
                classColor = "color-dark-blue"
                break;

            case 2:
                classColor = "color-light-grey"
                break;

            case 3:
            case 4:
                classColor = "color-orange"
                break;

            case 5:
                classColor = "color-dark-green"
                break;

            case 6:
                classColor = "color-lime-green"
                break;

            case 7:
                classColor = "color-red"
                break;
            default:
                classColor = "color-dark-blue"
                break;
        }
        return classColor;
    }

    goToPdf(_urlPathPdf: any) {
        if (!_urlPathPdf) return;
        window.open(_urlPathPdf);
    }

    getTheTooltip() {
        let infoBulle: string = "";
        infoBulle = '<span class="font-bold">' + this.app.translate.instant('bo.dossiers-dp.tooltipTotalFolders');

        if (this.stats && this.stats.length > 0) {
            let statTotals: any = this.stats.filter((st: any) => { return st.StatusID == -99 })[0] || null;
            if (statTotals) infoBulle += ' : ' + statTotals.NbFolders;

            infoBulle += '<ul class="pl-3">';
            this.stats.forEach((stat: any) => {
                if (stat.StatusID == -99) return;
                infoBulle += '<li class="white-space-nowrap">' + stat.NbFolders + ' x ' + this.app.sharedTools.getLabelSwitchLangByID(stat.StatusID, 'StatusID', this.status) + '</li>'
            });
            infoBulle += '</ul>';
        }
        return infoBulle;
    }

    getstats() {
        this.app.apiService.getstats().subscribe((data: any) => {
            console.log("getstats data ==>", data);
            this.stats = cloneDeep(data);
        })
    }

    async getStatus() {
        const data = await firstValueFrom(this.app.apiService.getStatus())
        console.log("getStatus ==> ", data);
        let status = cloneDeep(data);

        this.status = status.filter((s: any) => s.StatusType === StatusType.FolderState)
        this.webPublisheStatus = status.filter((status: any) => status.StatusType === StatusType.FichePublishState)
        this.initSelectedStatus()
    }

    initSelectedStatus(){
        let selectedStatus = this.status?.filter((sts: any) => {
            return [StatusCode.INIT, StatusCode.OKTOCONNECT, StatusCode.INPROGRESS, 
                    StatusCode.WAITINGSIGN, StatusCode.SIGNED, StatusCode.VALIDATED]
                    .includes(sts?.Code);
        });
        
        this.search.selectedStatus = selectedStatus
    }

    async getCategories() {
        try {
            const response = await this.app.apiService.getCategories();
            let categories = response;

            this.catTypeActivities = categories.filter((cat: any) => { return cat.CategoryType == "ACTIVITIES" });
            this.catTypeNature = categories.filter((cat: any) => { return cat.CategoryType == "EXHI_NATURE" });
            this.catTypeStand = categories.filter((cat: any) => { return cat.CategoryType == "STAND_TYPE" });
        } catch (error) {
            console.error(error);
        }
    }
    getCountries() {
        this.app.apiService.getCountries().subscribe((data: any) => {
            console.log("getCountries ==> ", data);
            this.countries = data; //cloneDeep(data);
        });
    }

    async searchFolders(search: any, thenFilterFast: boolean = false, goLatestViewedPage : boolean = false) {
        //save search to retrieve it when coming back to BO after redirect from BO
        this.isLoaded = false
        this.folderSearchHelperService.advancedSearch = cloneDeep(search);

        console.log("search ==> ", search);
        let searchTemp: any = cloneDeep(search);

        if (searchTemp.selectedCategory) searchTemp.CategoryID = searchTemp.selectedCategory.CategoryID;
        if (searchTemp.selectedCountry) searchTemp.CountryID = searchTemp.selectedCountry.CountryID;
        //if (searchTemp.selectedNature) searchTemp.FolderNatureID = searchTemp.selectedNature.CategoryID;
        if (searchTemp.selectedTypeStand) searchTemp.FolderStandTypeID = searchTemp.selectedTypeStand.CategoryID;
        if (searchTemp.selectedStatus) searchTemp.SelectedStatus = searchTemp.selectedStatus?.map((sts:any)=>{
            return sts?.StatusID
        });
        if (searchTemp.selectedCommercial) searchTemp.CommercialID = searchTemp.selectedCommercial.UserId

        if (searchTemp.selectedCategory) delete searchTemp.selectedCountry;
        if (searchTemp.selectedCountry) delete searchTemp.selectedCountry;
        //if (searchTemp.selectedNature) delete searchTemp.selectedNature;
        if (searchTemp.selectedTypeStand) delete searchTemp.selectedTypeStand;
        if (searchTemp.selectedCommercial) delete searchTemp.selectedCommercial;

        console.log("searchTemp ==> ", searchTemp);

        delete searchTemp.selectedCategory;
        delete searchTemp.selectedCountry;
        delete searchTemp.selectedStatus
        delete searchTemp.selectedTypeStand;
        delete searchTemp.selectedCommercial
        this.exportSearch = searchTemp;
        try {
            const response = await this.app.apiService.searchFolders(searchTemp);
            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/'); }, () => { })
                return;
            }
            if(searchTemp.CompanyName){
                const filteredFolders = response.filter((fld:any)=>{
                    return fld?.LinkedFolders.some((x:any)=>{
                        return searchTemp?.CompanyName !== "" && (x?.CompanyName?.indexOf(searchTemp?.CompanyName) !== -1 || x?.CatalogName?.indexOf(searchTemp?.CompanyName) !== -1 ) 
                    })
                }) 
                this.autoExpandRows(filteredFolders)
            }

            this.folders = cloneDeep(response.filter((fld: any) => { return fld.FolderNatureID != 8 }))//response; //cloneDeep(response); 

            setTimeout(() => {
                this.folders.forEach((folder: any) => {

                    folder.CreationDateFormat! = this.datePipe.transform(folder.CreationDate, 'dd/MM/yyyy HH:mm:ss');
                    if (this.fastFiltersFolder.indexOf('CreationDateFormat') == -1) this.fastFiltersFolder.push("CreationDateFormat");

                    folder.selectedStatus = this.status.filter((etat: any) => { return etat.StatusID == folder.StatusID })[0];
                    folder.selectedCommercial = this.commercials.filter((user: any) => { return user?.ContactId == folder?.Folders_Contacts?.Ctc_id })[0]
                    folder.webPublishedSelectedStatus = this.webPublisheStatus.filter((etat: any) => { return etat.StatusID == folder.WebPublishedStatus })[0];
                    folder.selectedCountry = this.countries.filter((country: any) => { return country.CountryID == folder.CountryID })[0];
                    folder.selectedNature = (folder.FolderNatureID) ? this.catTypeNature.filter((cat: any) => { return cat.CategoryID == folder.FolderNatureID })[0] : null;
                    folder.selectedTypeStand = (folder.FolderStandTypeID) ? this.catTypeStand.filter((cat: any) => { return cat.CategoryID == folder.FolderStandTypeID })[0] : null;
                    folder.selected = false;
                    if (folder.Surface) folder.Surface = parseInt(folder.Surface);
                    //console.log("folder ==> ", folder);

                    // ==> On met ces champs dans la liste des filtres rapides. TODO: comment chercher dans les LinkedFolders
                    if (folder.selectedStatus) {
                        this.app.sharedTools.getLabelSwitchLang(folder.selectedStatus);
                        if (this.fastFiltersFolder.indexOf('selectedStatus.label') == -1) this.fastFiltersFolder.push("selectedStatus.label");
                    }

                    if (folder.selectedCountry) {
                        this.app.sharedTools.getLabelSwitchLang(folder.selectedCountry);
                        if (this.fastFiltersFolder.indexOf('selectedCountry.label') == -1) this.fastFiltersFolder.push("selectedCountry.label");
                    }
                    if (folder.selectedNature) {
                        this.app.sharedTools.getLabelSwitchLang(folder.selectedNature);
                        if (this.fastFiltersFolder.indexOf('selectedNature.label') == -1) this.fastFiltersFolder.push("selectedNature.label");
                    }
                    if (folder.selectedTypeStand) {
                        this.app.sharedTools.getLabelSwitchLang(folder.selectedTypeStand);
                        if (this.fastFiltersFolder.indexOf('selectedTypeStand.label') == -1) this.fastFiltersFolder.push("selectedTypeStand.label");
                    }

                    if(folder.LinkedFolders?.length > 0){
                        let embdedExhibitors = JSON.stringify(folder.LinkedFolders)
                        folder.EmbdedExhibitors = embdedExhibitors
                    }


                    // if (folder.LinkedFolders.length > 0) {
                    //     folder.LinkedFolders.forEach((linkfldr: any, index: any) => {
                    //         if (index == 0) {
                    //             for (const key in linkfldr) {
                    //                 console.log("key ======================>", key)
                    //                 //if (this.fastFiltersFolder.indexOf(key) == -1) this.fastFiltersFolder.push(key);
                    //             }
                    //         }
                    //     });
                    // }
                });
                this.isLoaded = true
                if(goLatestViewedPage){
                    this.currentPageTable = ((this.pagePaginationInfo.pageNumber ?? 1) - 1) * (this.pagePaginationInfo.rowSize ?? 1);
                    this.dtFolders.first = this.dtFolders.value.length > this.currentPageTable ? this.currentPageTable : 1

                }
                if (thenFilterFast) {
                    this.filterFast();

                    if (hasAnyNonNullProperty(this.search)) {
                        console.log("hasAnyNonNullProperty", hasAnyNonNullProperty(this.search))
                        this.initAdvancedSearch();
                        // this.showAdvancedSearch = true;
                    }
                }
            }, 100);

            
        } catch (error) {
            console.error(error);
        }
    }

    autoExpandRows(folders : any){
        let expandedRowKeys : any = {};
        folders.forEach((f: any) =>  expandedRowKeys[`${f.FolderID}`]= true );
        this.dtFolders.expandedRowKeys = expandedRowKeys;
    }

    clear(table: Table) {
        table.clear();
    }

    //   filterExpert(event: any) {
    //     let filtered: any[] = [];
    //     let query = event.query;

    //     for (let i = 0; i < this.experts.length; i++) {
    //       let expert = this.experts[i];
    //       if (expert.toLowerCase().indexOf(query.toLowerCase()) == 0) {
    //         filtered.push(expert);
    //       }
    //     }

    //     this.filteredExperts = filtered;
    //   }

    clearSearch() {
        console.log("clearSearch ==>");
        this.rangeDates = [];
        let getAllDP = this.search.getAllDP
        this.search = new SearchFolder();
        this.search.getAllDP= getAllDP
        this.initSelectedStatus()
        this.searchFolders(this.search);
        this.clearFastSearch();
    }

    clearFastSearch(): void {
        this.fastSearchFolderKeyword = '';
        this.filterFast();
    }

    toggleAdvancedSearch() {
        this.showAdvancedSearch = !this.showAdvancedSearch;
    }

    onRowSelect(event: any) {
        console.log("event.data==>", event.data);
        console.log("selectedFolders==>", this.selectedFolders);

        //this.messageService.add({ severity: 'info', summary: 'Folder Selected', detail: event.data.FolderID });
    }

    doAction() { }

    goToDetail(_folderId: any) {
        console.log("[gotToDetail] ==> ", _folderId);
        this.app.sharedTools.redirectTo('/detail-dp/' + _folderId.toString());
    }

    download(_folderId: any) {
        console.log("download ==> ", _folderId);
        //this.messageService.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted' });
    }

    mapColumn() {
        return this.folders.map((row: any) => {
            const filteredColumns: any = {};
            this.selectedColumns.forEach(column => {
                //   if(this.translalteService.currentLang === "fr"){
                switch (column.field) {
                    case "NbIndirects": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.NbIndirects ?? ''
                        break;
                    case "NumExposant": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.NumExposant ?? ''
                        break;
                    case "CompanyName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = `${row.CatalogName ?? ''} ${row.CompanyName ?? ''} ${row.ContactName ?? ''} ${row.ContactPhone ?? ''}`
                        break;
                    case "FolderStandTypeID": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(row.selectedTypeStand) ?? ''
                        break;
                    case "Surface": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Surface ?? ''
                        break;
                    case "AmountHT": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.AmountHT ?? ''
                        break;
                    case "StatusID": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(row.selectedStatus) ?? ''
                        break;
                    case "WebPublishedStatus": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(row.selectedStatus) ?? ''
                        break;
                    case "HistoricStatus": {
                        let historicStatus = "";
                        row.HistoricStatus.forEach((hs: any) => {
                            if (hs.StatusID === 1)
                                historicStatus += `${this.translatePipe.transform("racine.generalites.creation")} : ${hs.DateCreation} `
                            else if (hs.StatusID === 4)
                                historicStatus += `${this.translatePipe.transform("racine.generalites.soumission")} : ${hs.DateCreation} `
                            else if (hs.StatusID === 5)
                                historicStatus += `${this.translatePipe.transform("racine.generalites.signe")} : ${hs.DateCreation} `
                            else if (hs.StatusID === 6)
                                historicStatus += `${this.translatePipe.transform("racine.generalites.valide")} : ${hs.DateCreation}`

                            filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = historicStatus;

                        })
                    }
                        break;

                    default:
                        break;
                }
            });
            return filteredColumns;
        })
    }
    exportCSV() {
        this.helperService.exportCSV(this.folders, this.mapColumn(), 'folders_export_' + new Date().toLocaleDateString(), true);
    }
    exportExcel() {
        this.helperService.exportExcel(this.folders, this.mapColumn(), 'folders_export_' + new Date().toLocaleDateString(), true);
    }

    deleteFoldersSelected(_foldersSelected: any) {
        console.log('deleteFoldersSelected ==> ', _foldersSelected)
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteFolder'), 'AUTRE', 'confirm', () => {
            this.deleteFolders(_foldersSelected);
        }, () => {
            //this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
        });
    }

    deleteFolders(_foldersSelected: any) {
        console.log('deleteFolders TODO ==> ', _foldersSelected);
        //this.messageService.add({ severity: 'success', summary: 'Folder deleted successfully', detail: "You have accepted" });
    }

    onchangeSelectedAction(selectedAction: any) {
        console.log(selectedAction)
    }

    exportFoldersToCsv(): void {
        this.foldersExport();
    }

    onSearchCompanyName(event: any): void {
        let keyword: string | undefined = event.query;

        if (!keyword || keyword === '') {
            this.companyNameSuggestions = [];
        }
        else {
            this.refreshCompanyNameAutocomplete(keyword);
        }
    }

    getActionsMenuByFolder(folder: any): any {
        if (this.showConsultDirectExhibitorOption(folder)) {
            return this.items;
        }
        else {
            //ActionID = 2 is for View direct exhibitor Action
            return this.items.filter((item: any) => item.ActionID != 2);
        }
    }

    //#region private methods
    private unsubscribeActiveSubscriptions(): void {
        if (this.foldersExportSubscription) {
            this.foldersExportSubscription.unsubscribe();
        }

        if (this.companyAutocompleteSubscription) {
            this.companyAutocompleteSubscription.unsubscribe();
        }
    }

    private foldersExport(): void {
        this.foldersExportSubscription = this.app.apiService.foldersExport(this.exportSearch).subscribe({
            next: (data: any) => {
                console.log('foldersExport', data);
                if (data) {
                    let filesUrl: string = data;

                    window.open(filesUrl);
                }

            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }

    private refreshCompanyNameAutocomplete(keyword: string): void {
        this.companyAutocompleteSubscription = this.app.apiService.companyAutocomplete(keyword).subscribe({
            next: (data: any) => {
                if (data && data != '' && Array.isArray(data)) {
                    this.companyNameSuggestions = data;
                }
                else {
                    this.companyNameSuggestions = []
                }
            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }

    private showConsultDirectExhibitorOption(folder: any): boolean {
        if (folder?.StatusID) {
            return folder.StatusID == FolderStatus.InProgress
                || folder.StatusID == FolderStatus.WaitingSign
                || folder.StatusID == FolderStatus.Signed
                || folder.StatusID == FolderStatus.Validated
        }

        return false;
    }

    consultDirectExhibitor(folderId: any): void {
        // let params = { folderID: folderId }
        // console.log(params);
        //this.app.sharedTools.redirectToWithParameters('/infos-exposant', params);
        // ==> new code
        this.app.apiService.getUrlAutologin(folderId).subscribe({
            next: (data: any) => {
                console.log('[getUrlAutologin] API =>', data);

                if (data) {
                    if (data.Result) {
                        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                        return;
                    }
                    window.open(data);
                }
            },
            error: (error: any) => {
                console.log(error);
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_ERROR'), 'KO', "alert", () => { }, () => { });
                return;
            }
        });
    }

    private initAdvancedSearch(): void {
        let findSelectedCountry = this.countries.find((c: any) => c.CountryID === this.search.selectedCountry?.CountryID);
        // let findSelectedStatus = this.status.find((c: any) => c.StatusID === this.search.selectedStatus?.StatusID);
        let findSelectedCategory = this.catTypeActivities.find((c: any) => c.CategoryID === this.search.selectedCategory?.CategoryID);
        let findSelectedTypeStand = this.catTypeStand.find((c: any) => c.CategoryID === this.search.selectedTypeStand?.CategoryID);

        // console.log('recup latest search ', findSelectedCountry
        // , findSelectedStatus
        // , findSelectedCategory
        // , findSelectedTypeStand);
        // this.search.selectedStatus = this.search?.map((sts:any)=>{
        //     return sts?.StatusID
        // });

        this.search.selectedCountry = findSelectedCountry;
       
        this.search.selectedCategory = findSelectedCategory;
        this.search.selectedTypeStand = findSelectedTypeStand;

        this.rangeDates = [];

        if (this.search.SubmissionDateStart) {
            this.rangeDates.push(new Date(this.search.SubmissionDateStart));
        }

        if (this.search.SubmissionDateEnd) {
            this.rangeDates.push(new Date(this.search.SubmissionDateEnd));
        }
    }
    //#endregion

    publishSelectedFolders() {
        this.content = this.app.translate.instant('bo.dossiers-dp.publishSelectedFoldersConfirm')
        this.acceptBtnLabel = this.app.translate.instant('bo.dossiers-dp.acceptPublishBtnLabel')
        this.rejectBtnLabel = this.app.translate.instant("bo.dossiers-dp.rejectPublishBtnLabel")
        this.customBtnLabel = this.app.translate.instant("bo.dossiers-dp.customPublishBtnLabel")
        this.showPublishModal = true
    }

    unpublishFolders() {
        if (this.selectedFolders.length > 0) {
            let publishedStatus = this.webPublisheStatus.filter((x: any) => x.Code == StatusCode.UNPUBLISHED_FICHE_PUBLISH)[0]
            this.selectedFolders.forEach((folder: any) => {
                folder.StatusID = publishedStatus.StatusID
            })
            this.app.apiService.changeWebPublishedFolderState(this.selectedFolders).subscribe(
                data => {
                    this.getstats();
                    this.searchFolders(this.search, false, true);
                    this.showPublishModal = false
                    this._selectedAction = {}

                }
            )
        } else {
            this._selectedAction = {}
        }
    }

    publishFolders() {
        if (this.selectedFolders.length > 0) {
            let publishedStatus = this.webPublisheStatus.filter((x: any) => x.Code == StatusCode.PUBLISHED_FICHE_PUBLISH)[0]
            this.selectedFolders.forEach((folder: any) => {
                folder.StatusID = publishedStatus.StatusID
            })
            this.app.apiService.changeWebPublishedFolderState(this.selectedFolders).subscribe(
                data => {
                    this.getstats();
                    this.searchFolders(this.search, false, true);
                    this.showPublishModal = false
                    this._selectedAction = {}
                }
            )
        }else {
            this._selectedAction = {}
        }
    }
    closeModal(){
        this._selectedAction = {}
        this.showPublishModal = false
    }

    getCommercialList() {
        this.app.apiService.getCommercialList().subscribe(
            data => {
                this.commercials = data
            }
        )
    }

    onChangeCommercial(folder: any) {
        let objectCommercial: any = {
            CommercialID: null,
            FolderID: null
        }
        if (!folder.selectedCommercial) {
            objectCommercial = {
                CommercialID: null,
                FolderID: folder.FolderID
            }
        } else {
            objectCommercial = {
                CommercialID: folder.selectedCommercial.ContactId,
                FolderID: folder.FolderID
            }
        }

        this.app.apiService.updateCommercial(objectCommercial).subscribe(
            data => {
                if (data == true) {
                    this.getstats();
                    this.searchFolders(this.search, false, true);
                } else {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_FOLDERNOTUPDATED'), 'KO', "alert", () => { }, () => { })
                    return;
                }
            }
        )
    }
    importCsv(event: any) {
        let selectedFileExtension = event.target.files[0]?.name?.split('.')[1]
        if (selectedFileExtension == "xlsx" || selectedFileExtension == "xls" || selectedFileExtension == "csv") {
          this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmImportDo'),
            'AUTRE', 'confirm'
            , () => {
              const file: File = event.target.files[0]
              this.app.apiService.uploadDPCsv(file, this.app.translate.currentLang).subscribe(
                data => {
                  if (data && data?.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                    return;
                  }
                  else {
                    this.msgAlertImport = data?.Data?.InnerHTML_EN ?? ""
                    if (this.app.translate.currentLang == Languages.French) {
                      this.msgAlertImport = data?.Data?.InnerHTML_FR ?? ""
                    } else if (this.app.translate.currentLang == Languages.Spanish) {
                      this.msgAlertImport = data?.Data?.InnerHTML_ES ?? ""
                    }
                    this.showModal = true
                  }
                }
              )
            }
            , () => {
              this.resetFileInput()
            });
    
        } else {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorInvalidFileFormat'), 'KO', "alert", () => { }, () => { });
          return;
        }
    
      }
      resetFileInput() {
        this.showModal = false
        if (this.fileInput && this.fileInput.nativeElement) {
          this.fileInput.nativeElement.value = ""
        }
      }
}
