import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { Subscription } from 'rxjs';
import { ExportVisitModel } from '../models/exportvisit';
import { cloneDeep } from 'lodash';
import { DatePipe } from '@angular/common';
import { DateHelper } from '../helpers/date.helper';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ExtendedCountries } from '../enums/extended-countries';
import { FormCode } from '../enums/form-code';




@Component({
  selector: 'app-statistiques',
  templateUrl: './statistiques.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./statistiques.component.less']
})
export class StatistiquesComponent implements OnInit {
  lineChartData: { datasets: any[], labels: any[] } = { datasets: [], labels: [] };
  private onLangChangeSubscription?: Subscription;
  pieChartData: any;
  allstatus: any;
  allvisit: any;
  allODsType: any = [];
  oDType: any = [];
  AllVisit: any = [];
  allODsCountries: any = [];
  exportListVisit = new ExportVisitModel();
  countries: any = [];
  selectedCountry: any;
  selectedDO: any;
  selectedDOs: any;
  selectedVisit: any;
  pieChartOptions: any;
  value: any;
  FileUrl: any;
  nbRequest: any;
  Rate: any
  stats: any = {};
  statisticsCustomOptions: any = {};
  lineChartOptions: any = {
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        borderColor: 'blue',
        fill: {
          target: 'origin',

        },
      },
    },
  };
  private vistExportSubscription?: Subscription;
  statistics: any;
  Dates: any[] = [];
  DemandStats: number[] = [];
  fileName2: any;
  fileName1: any;
  pieChartPlugin = [ChartDataLabels]
  maxKnobValue : number = 100
  ratioFrancaisEtranger : number = 0
  knobRangeColor : string = "orange"
  constructor(
    public app: AppComponent,
    private datePipe: DatePipe

  ) {

  }

  ngOnInit(): void {
    this.lineChartData = {
      labels: this.Dates,
      datasets: [
        {
          label: '%',
          data: this.DemandStats,
          fill: true,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      ],
    };



    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
        console.log("resG", resG);
        this.statisticsCustomOptions = resG.statisticsCustomOptions;
        this.getAllODsType();
        this.getAllODsCountries();
        this.getnbdemand();
        this.getAllVisit();
        this.getAllStatusRequest();
        this.getVmParameter()
        // this.getRatio();
        this.onLangChangeSubscription = this.app.translate.onLangChange.subscribe((vale: any) => { this.handleLangChange() });
      });
  }
  private handleLangChange(): void {
    this.getAllStatusRequest()
  }
  getnbdemand() {
 
    this.app.apiService.getnbDemand().subscribe({
      next: (data: any) => {

        console.log("data======>>>>", data);

        this.nbRequest = data[0]?.totalRequestNumber ?? 0;
        this.Rate = Number((data[data.length - 1].IncreaseRate * 100).toFixed(2));
        this.lineChartData = {
          datasets: [{
            data: data.map((a: any) => Number((a.DemandsNumber))),
            fill: true,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1,
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }],
          labels: data.map((a: any) => `${a.Date}\n${Number((a.IncreaseRate * 100).toFixed(2))} %`)
        }

        //  this.Rate = Number(this.Rate.toFixed(2));
        console.log("Dates======>>>>", this.Dates);
      },
    });
    console.log("this.lineChartData====>", this.lineChartData);
    console.log("this.Rate====>", this.Rate);
    console.log("this.nbRequest====>", this.nbRequest);
  }
  getRatio() {

    if (this.exportListVisit?.TypeDoID && this.selectedDOs?.CategoryID)
      var x = this.selectedDOs?.CategoryID;
    this.exportListVisit.DO = this.selectedDOs?.CategoryID;
    this.exportListVisit.alltypeDOs = this.selectedDOs?.alltypeDOs;
    this.app.apiService.getRatio(this.exportListVisit).subscribe({
      next: (data: any) => {
        this.value = data.toFixed(0);
        if(this.value > 100){
          this.maxKnobValue = parseInt(this.value) 
        }
        if(this.value >= this.ratioFrancaisEtranger){
          this.knobRangeColor = "green"
        }
        else {
          this.knobRangeColor = "orange"
        }
      }
    })
  }
  getVmParameter(){
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_VM
    this.app.apiService.getEditionInfo(request).subscribe(
      data => {
        if (data != undefined && data.Id != undefined) {
          this.ratioFrancaisEtranger = data.RatioFrancaisEtranger
        }
        this.getAllODsType()
      }
    )
  }
  getAllVisit() {
    this.app.apiService.getAllVisit().subscribe({
      next: (data: any) => {
        this.allvisit = data;
        this.AllVisit = this.allvisit;

        this.AllVisit = [this.statisticsCustomOptions.allVisit, ...this.AllVisit];
        console.log('All Visit ------->', this.AllVisit);
      }
    })
  }
  exportListVisitToCsv(): void {
    this.exportVisit();
  }
  private exportVisit(): void {
    if (this.exportListVisit?.countryID && this.selectedCountry.CountryID)
      var countryID = this.selectedCountry.CountryID;
    this.exportListVisit.countryID = this.selectedCountry.CountryID;

    this.exportListVisit.allPaysDOs = this.selectedCountry?.allPaysDOs;
    console.log('exportvisit', this.exportListVisit?.countryID);

    this.vistExportSubscription = this.app.apiService.listVisteExport(this.exportListVisit).subscribe({
      next: (data: any) => {
        console.log('ListVisitExport', data);
        if (data) {
          let filesUrl: string = data;

          // window.open(filesUrl);
        }
        this.FileUrl = data;
        this.fileName1 = data.replace(data.substring(0, data.lastIndexOf('/') + 1), "");
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }
  downloadListVisitToCsvFile() {
    window.open(this.FileUrl)
  }
  exportListVisitParActToCsv(): void {
    this.exportVisitParAct();
  }
  private exportVisitParAct(): void {

    if (this.exportListVisit?.TypeDoID && this.selectedDO?.CategoryID)
      var x = this.selectedDO.CategoryID;
    this.exportListVisit.TypeDoID = this.selectedDO?.CategoryID;
    this.exportListVisit.AutreActID = this.selectedVisit?.AutreVistID;
    this.exportListVisit.alltypeDOs = this.selectedDO?.alltypeDOs;

    this.exportListVisit.allAutVis = this.selectedVisit?.allAutVis;



    this.vistExportSubscription = this.app.apiService.listVisiteparActExport(this.exportListVisit).subscribe({
      next: (data: any) => {
        console.log('ListVisitExport', data);
        if (data) {
          let filesUrl: string = data;

          window.open(filesUrl);
        }
        this.FileUrl = data;
        this.fileName2 = data.replace(data.substring(0, data.lastIndexOf('/') + 1), "")

      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }



  getRandomColor(): string {
    const allColors = ['green', 'orange', 'red', 'grey'];
    const availableColors = allColors.filter((color) => !allColors.includes(color));

    if (availableColors.length === 0) {
      return "No more unique colors available";
    }

    const randomIndex = Math.floor(Math.random() * availableColors.length);
    return availableColors[randomIndex];
  }


  parseStatusLabels(data: any) {
    let result: { labels: any[], values: any[], colors: any[] } = {
      labels: [],
      values: [],
      colors: []
    };
    result.labels = []
    data.forEach((element: any) => {
      result.labels.push(`${this.app.sharedTools.getLabelSwitchLang(element)}  \n ${element.totalVisitNumber} `)

      result.values.push(element.Rate)
      if (element.Code == "NOTCONSIDERED_VIS") {
        result.colors.push("red")
      }
      else if (element.Code == "WAITING_VIS") {
        result.colors.push("orange")
      }
      else if (element.Code == "CONSIDERED_VIS") {
        result.colors.push("green")
      }
      else if (element.Code == "DRAFTED_REQ") {
        result.colors.push("gray")
      } else {
        result.colors.push(this.getRandomColor())
      }

    })
    return result
  }




  getAllStatusRequest() {
    this.allstatus = this.app.apiService.getAllStatusRequest().subscribe({
      next: (data: any) => {
        console.log('All Request Status ', data)
        let result = this.parseStatusLabels(data)
        const newData = {
          labels: result.labels,
          datasets: [
            {
              data: result.values,
              backgroundColor: result.colors,
            },
          ],
        };

        this.pieChartData = newData;
        this.pieChartOptions = {
          plugins: {
            datalabels: {
              formatter: (value: any, context: any) => {
                return value + ' %';
              },
              color: 'white', // Font color for data labels
            },
            legend: {
              position: 'left',
              labels: {
                boxWidth: 10,
                fontColor: 'black',
              },
            },
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  return context.label.split('\n')[0] + ": " + context.parsed + "%";
                },
              },
            },
          },
        };


      },
    });
  }

  async getAllODsType() {
    console.log("this.oDType", this.oDType)
    if (!this.allODsType || this.allODsType.length < 1) {
      this.allODsType = await this.app.apiService.getTypeDO(true);
    }

    this.oDType = this.allODsType;
    this.oDType = [this.statisticsCustomOptions.allTypeDo, ...this.oDType];
    this.selectedDOs = this.oDType.find((x:any)=>x?.alltypeDOs == true)
    this.getRatio()
    console.log("this.allODsTypeCategories", this.oDType)
  }
  async getAllODsCountries() {
    this.app.apiService.getExtendedCountries(ExtendedCountries.vm).subscribe({
      next: (data: any) => {
        console.log('All Exposant Countries ==>> ', data)
        this.allODsCountries = data;
        this.countries = this.allODsCountries;

        this.countries = [this.statisticsCustomOptions.allcountriesDo, ...this.countries];
        console.log("all Countries", this.countries);
      }
    });


  }


  versionnerTousPlanning() {
    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.confirmMsg.confirmVersionPlannig'),
      "AUTRE", "confirm", () => {
        this.app.apiService.versionAllPlanning().subscribe(
          data => {
            if (data && (data.Result || data.length > 0)) {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_ERRORWHILEVERSIONPLANNING')
                , 'KO'
                , "alert"
                , () => { }
                , () => { });
              return;
            }
            if (data && data.length == 0) {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successVersionAllPlanning'), 'OK', "alert", () => {

              }, () => { })
            }
          }
        )
      }
      , () => { })
  }

}
