import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { EmailRegex, PasswordRegex } from '../consts/utils';
import { MustMatch } from '../services/sharedtools.service';
import { cloneDeep } from 'lodash';
import { RoleCode } from '../enums/role-code';
import { CategoryType } from '../enums/category-type';
import { CategoryCode } from '../enums/category-code';
import { RoleType } from '../enums/role-type';

@Component({
  selector: 'app-create-vm-user',
  templateUrl: './create-vm-user.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./create-vm-user.component.less']
})
export class CreateVmUserComponent {
  submitted: boolean = false;
  initForm: boolean = false;
  newLeadForm!: FormGroup;
  civilities: any[] = [];

  langues: any[] = [];
  roles: any[] = [];
  phones: any[] = [];
  user?: any;
  newUser?: any;
  isEditMode: boolean = false;
  SelectedCivility: any;
  SelectedGrades: any;
  SelectedRole: any;
  SelectedRoles: any = [];
  SelectedOAOZCategory: any;
  SelectedType: any;
  SelectedTypes: any = [];
  SelectedLanguage: any;
  selectedListTypeDo: any[] = [];
  MobilePrefixSelected: any;
  paths = location.pathname.split('/');
  currentPath: any = null;
  userId: any;
  oDTypeCategories: any[] = [];

  //all categories with type oaoz
  allOAOZCategories: any[] = [];

  //categories of oa or oz ==> going to change selon role
  oAOZCategories: any[] = [];

  // oACategories: any[] = [];
  // oZCategories: any[] = [];
  checkedODTypeCategories: any = {};
  showTypeOAOZFormControl: boolean = false;

  constructor(
    public app: AppComponent,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
  ) {
  }

  async ngOnInit() {
    let cuser = JSON.parse(sessionStorage.getItem('userData')!);
    console.log(cuser);
    if (!cuser) {
      this.app.router.navigate(['/login']);
      return;
    }
    //check create or edit user
    console.log("this.path==> ", this.paths);
    this.currentPath = this.paths[1];
    var urlId: any = this.app.router.url.split('/').pop();
    if (this.currentPath == 'detail-vm-user') {
      this.userId = parseInt(urlId);

    }

    this.getCivilities();
    this.getRoles();
    this.getLanguages();
    this.getPhones();
    this.getCategories();

    //
    if (this.userId) {
      this.isEditMode = true;
      this.getVmUser(this.userId);
      console.log("this.userId ==> ", this.userId);
    }
    else {
      //
      this.InitForm();
      console.log("this.newLeadForm", this.newLeadForm);
    }

  }
 
  onchangeSelect(val: any) {
    if (!val) return;
    //console.log("onchangeSelect", val);
  }

  onCategorySelectionChange(event: any, cat: any): void {
    console.log(event.checked);
    if (event.checked) {
      this.insertCategoryToForm(cat);
    }
    else {
      this.popCategoryFromForm(cat);
    }
    console.log('after cat selected ===>', this.newLeadForm.value);
  }

  getPhones() {
    this.app.apiService.getPhones().subscribe((data: any) => {
      console.log("getPhones ==> ", data);
      this.phones = data; //cloneDeep(data);
    });
  }

  getRoles() {
    this.app.apiService.getRoles().subscribe((data: any) => {
      console.log("getRoles ==> ", data);
      this.roles = data; //cloneDeep(data);
      this.roles = this.roles.filter(r => r.RoleType === RoleType.F18User || r.RoleType === RoleType.F18);
    });
  }

  getLanguages() {
    this.app.apiService.getLanguages().subscribe((data: any) => {
      console.log("getLanguages ==>", data);
      this.langues = data;
    });
  }

  getCivilities() {
    this.app.apiService.getCivilities().subscribe((data: any) => {
      console.log("getCivilities ==> ", data);
      this.civilities = data; //cloneDeep(data);
    });
  }

  

  onDeleteUser(user: any) {
    this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteUser'), 'AUTRE', 'confirm', () => {
        setTimeout(() => { this.deleteUser(user); }, 1000);
    }, () => { });
}

async deleteUser(user: any) {
    console.log("DELETE User ===>", user);
    try {
        const response = await this.app.apiService.adminUsersDelete(user);
        console.log('Response API : DELETE User ===>', response);

        if (response && response.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
            return;
        }

        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successUserDelete'), 'OK', "alert", () => {
            this.app.sharedTools.redirectTo('/vm-users');
        }, () => { });


    } catch (error) {
        console.error(error);
    }
}

  annuler() {
    this.app.sharedTools.redirectTo('/vm-users');
  }

  emailChanged(){
    let userEmail = this.newLeadForm.get("Email")?.value
    let userLogin = this.newLeadForm.get("Login")?.value
    if(userEmail != undefined && userEmail != "" && (userLogin == undefined || userLogin == "")){
      this.newLeadForm.get("Login")?.setValue(userEmail)
      this.newLeadForm.updateValueAndValidity()
    }
  }

  isODTypeCategoryChecked(category: any): boolean {
    let categoriesControl = this.newLeadForm.get('ODTypeCategoriesIDs') as FormArray;
    if (categoriesControl) {
      let checkedCategories = categoriesControl.value;
      return checkedCategories.indexOf(category.CategoryID) > -1 ? true : false;
    }
    return false;
  }

  isCheckedODTypeCategoryOAOZ(category: any): boolean {
    let categoriesControl = this.newLeadForm.get('ODTypeCategoriesIDs') as FormArray;
    if (categoriesControl) {
      let checkedCategories = categoriesControl.value;
      return checkedCategories.indexOf(category.CategoryID) > -1 ? true : false;
    }
    return false;
  }

  async createVmUser(formObj: any, sendMail: boolean): Promise<any> {

    console.log("CREATE VM USER ===>", formObj);
    try {
      if (!this.isEditMode) {
        const response = await this.app.apiService.adminUsersCreate(formObj);
        console.log('RESPONSE CREATE VM USER ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }
        this.userId = response.UserID;
        
        if (sendMail) {
          // let user = {
          //   Login: (response.Login) ? response.Login : response.Email,
          //   Email: response.Email,
          //   Language: this.app.sharedTools.getParamByID(response.LanguageID,
          //     'LanguageID', this.langues, 'CodeISO2', true)
          // }
          // console.log('[user]', user);
          let lang = sessionStorage.getItem("currentLangOnSwitchClick")?.substring(1,3);
          const responseMail = await this.app.apiService.sendEmailForUser(this.userId,lang);
          console.log('RESPONSE EMAIL ===>', responseMail);

          if (responseMail && responseMail.Result) {
            this.app.confirm.confirmDialog(this.app.translate.instant(''), this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + responseMail.Result), 'KO', "alert", () => {
            }, () => {
            })
            return;
          }

          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successUserCreePasswordSendMail'), 'OK', "alert", () => {
            this.app.sharedTools.redirectTo('/vm-users');
          }, () => { });

        }
        else {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successUserCree'), 'OK', "alert", () => {
            this.app.sharedTools.redirectTo('/vm-users');
          }, () => { });

        }
      }
      else {
        formObj.UserID = this.userId;
        const response = await this.app.apiService.adminUsersUpdate(formObj);
        console.log('RESPONSE UPDATE USER ===>', response);

        if (response && response.Result) {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
          }, () => {
          })
          return;
        }
        if (sendMail) {
          // let user = {
          //   Login: (response.Login) ? response.Login : response.Email,
          //   Email: response.Email,
          //   Language: this.app.sharedTools.getParamByID(response.LanguageID,
          //     'LanguageID', this.langues, 'CodeISO2', true)
          // }
          // console.log('[user]', user);
          let lang = sessionStorage.getItem("currentLangOnSwitchClick")?.substring(1,3);
          const responseMail = await this.app.apiService.sendEmailForUser(this.userId,lang);
          console.log('RESPONSE EMAIL ===>', responseMail);

          if (responseMail && responseMail.Result) {
            this.app.confirm.confirmDialog(this.app.translate.instant(''), this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + responseMail.Result), 'KO', "alert", () => {
            }, () => {
            })
            return;
          }

          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successUserUpdatePasswordSendMail'), 'OK', "alert", () => {
            this.app.sharedTools.redirectTo('/vm-users');
          }, () => { });

        }
        else {
          this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successUserUpdate'), 'OK', "alert", () => {
            this.app.sharedTools.redirectTo('/vm-users');
          }, () => { });

        }
      }

    } catch (error) {
      console.error(error);
    }
  }

  onSubmit(sendMail: boolean) {
    
    // get controls
    let mobileControl = this.newLeadForm.get('Mobile');
    let mobilePrefixControl = this.newLeadForm.get('MobilePrefixSelected');
    mobileControl?.updateValueAndValidity();
    mobilePrefixControl?.updateValueAndValidity();
    this.submitted = true;
    console.log('[this.newLeadForm]', this.newLeadForm);

    if (this.newLeadForm.invalid) {

      console.log("invalid form ===>", this.newLeadForm);
      console.log("invalid form ===>", this.newLeadForm.errors);

      //bloc explore form errors

      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.newLeadForm.controls;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log('Invalid form values:', invalidValues);

      if (invalidField && invalidField.length > 1) {
        console.log("invalidField", invalidField)

        setTimeout(() => {
          let el = invalidField[1].offsetTop - 100;
          if (el) window.scroll({ top: el, behavior: "smooth" });
        }, 10);
      }
      //
      this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
      }, () => { });
    } else {
        console.log('this.newLeadForm', this.newLeadForm);
      let newUser = this.newLeadForm.value;
      if (newUser.SelectedCivility) newUser.CivilityID = newUser.SelectedCivility.CivilityID;
      //if (newUser.SelectedRole) newUser.RoleID = newUser.SelectedRole.RoleID.toString();
      if (newUser.SelectedRoles && newUser.SelectedRoles.length > 0) {
        newUser.RoleID = '';
        newUser.SelectedRoles.forEach((roleSelect: any, index: any) => {
          newUser.RoleID += roleSelect.RoleID;
          if (index != newUser.SelectedRoles.length - 1) newUser.RoleID += ',';
        });
      }

      if (newUser.SelectedLanguage) newUser.LanguageID = newUser.SelectedLanguage.LanguageID;
      if (newUser.SelectedOAOZCategory) newUser.OAOZCategoryID = newUser.SelectedOAOZCategory.CategoryID;
      if(newUser.MobilePrefixSelected){
        newUser.MobilePrefix = newUser.MobilePrefixSelected.Prefix;
        newUser.MobileCodeIso = newUser.MobilePrefixSelected.CodeISO2;
      }
      newUser.Login = newUser.Login;

      // this.app.sharedTools.deleteParamsInObject(newUser, ['SelectedCivility', 'SelectedRole', 'SelectedLanguage','MobilePrefixSelected'])
      this.app.sharedTools.deleteParamsInObject(newUser, ['SelectedCivility'/*, 'SelectedRole'*/, 'SelectedLanguage', 'MobilePrefixSelected', 'SelectedOAOZCategory'])
      console.log('[newUser]', newUser);

      this.createVmUser(newUser, sendMail);
    }
  }

  private async getVmUser(userId: any) {

    try {
      const response = await this.app.apiService.adminUsersGet(userId);
      console.log("getVmUser ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
        }, () => {
        })
        return;
      }
      this.user = cloneDeep(response);

      setTimeout(() => {
        this.SelectedCivility = (this.user.CivilityID) ? this.app.sharedTools.getObjById(this.user.CivilityID, "CivilityID", this.civilities) : null;
        this.SelectedLanguage = (this.user.LanguageID) ? this.app.sharedTools.getObjById(this.user.LanguageID, "LanguageID", this.langues) : null;
        this.SelectedOAOZCategory = (this.user.OAOZCategoryID) ? this.allOAOZCategories.find(c => c.CategoryID === this.user.OAOZCategoryID) : null;
        //this.SelectedRole = (this.user.RoleID) ? this.roles.find(r => r.RoleID == this.user.RoleID) : null;

        let listRoles: any = this.user.RoleID.split(',').map((roleId: string) => {
          return { RoleID: parseInt(roleId) };
        });

        this.SelectedRoles = this.roles.filter((sourceElement: any) =>
          listRoles.some((cibleElement: any) => cibleElement.RoleID === sourceElement.RoleID)
        );
        console.log('s roles', this.SelectedRoles)

        //this.SelectedRole = (this.user.RoleID) ? this.app.sharedTools.getObjById(this.user.RoleID, "RoleID", this.roles) : null;
        this.MobilePrefixSelected = (this.user.MobileCodeIso) ? this.app.sharedTools.getObjByLabel(this.user.MobileCodeIso, "CodeISO2", this.phones) : null;

        this.InitForm();
      }, 500);

    }
    catch (error) {
      console.error(error);
    }
  }

  private InitForm(): void {
    if (this.isEditMode) {
      this.newLeadForm = new FormGroup({
        CivilityID: new FormControl(this.user?.CivilityID ?? ''),
        SelectedCivility: new FormControl(this.SelectedCivility),
        Grade: new FormControl(this.user?.Grade),
        LastName: new FormControl(this.user?.LastName ?? '', Validators.required),
        FirstName: new FormControl(this.user?.FirstName ?? '', Validators.required),
        Email: new FormControl(this.user?.Email ?? '', [Validators.pattern(EmailRegex)]),
        LanguageID: new FormControl(this.user?.LanguageID ?? ''),
        SelectedLanguage: new FormControl(this.SelectedLanguage),
        RoleID: new FormControl(this.user?.RoleID ?? ''),
        SelectedRoles: new FormControl(this.SelectedRoles, Validators.required),
        MobilePrefixSelected: new FormControl(this.MobilePrefixSelected),
        Mobile: new FormControl(this.user?.Mobile ?? ''),
        Password: new FormControl(this.user?.password ?? '', Validators.pattern(PasswordRegex)),
        ConfirmPassword: new FormControl(this.user?.ConfirmPassword ?? '', Validators.pattern(PasswordRegex)),
        LockaccessApp: new FormControl(this.user?.LockaccessApp),
        LockaccessWebApp: new FormControl(this.user?.LockaccessWebApp),
        Option: new FormControl(this.user?.Option),
        ODTypeCategoriesIDs: this.formBuilder.array(
          this.user?.ODTypeCategoriesIDs ? this.user?.ODTypeCategoriesIDs : []
          //, CustomValidators.arrayLengthGreaterThanZeroValidator()
        ),
        SelectedOAOZCategory: new FormControl(this.SelectedOAOZCategory),
        OAOZCategoryID: new FormControl(this.user?.OAOZCategoryID ? this.user?.OAOZCategoryID : null),
        Login : new FormControl(this.user?.Login ?? "" , Validators.required)
      }
        , { validators: MustMatch('Password', 'ConfirmPassword') });

      //this.initCheckedODTypeCategories();
    }
    else {
      this.newLeadForm = new FormGroup({
        CivilityID: new FormControl(''),
        SelectedCivility: new FormControl(null),
        Grade: new FormControl(''),
        LastName: new FormControl('', Validators.required),
        FirstName: new FormControl('', Validators.required),
        Email: new FormControl('', [Validators.pattern(EmailRegex)]),
        LanguageID: new FormControl(''),
        SelectedLanguage: new FormControl(null),
        RoleID: new FormControl(''),
        SelectedRoles: new FormControl([], Validators.required),
        MobilePrefixSelected: new FormControl(null),
        Mobile: new FormControl(null),
        Password: new FormControl('', Validators.pattern(PasswordRegex)),
        ConfirmPassword: new FormControl('', Validators.pattern(PasswordRegex)),
        LockaccessApp: new FormControl(true),
        LockaccessWebApp: new FormControl(false),
        Option: new FormControl(false),
        ODTypeCategoriesIDs: this.formBuilder.array([]),
        SelectedOAOZCategory: new FormControl([]),
        OAOZCategoryID: new FormControl(null),
        Login : new FormControl(null , Validators.required)
      },
        { validators: MustMatch('Password', 'ConfirmPassword') }
      );
    }

    this.initOnChangeHandlers();
    if (this.SelectedRoles) {
      this.handleSelectedRoleChange(this.SelectedRoles);
    }
    this.initForm = true;
    console.log('initForm ', this.newLeadForm);
  }


  async getCategories() {
    try {
      const response = await this.app.apiService.getCategories();

      let categoriesIni = cloneDeep(response);

      let oDTypeCategories = categoriesIni.filter((cat: any) => {
        return cat.CategoryType == CategoryType.TypeDO;
      });

      let allOAOZCategories = categoriesIni.filter((cat: any) => {
        return cat.CategoryType == CategoryType.TypeOAOZ;
      });

      this.oDTypeCategories = cloneDeep(oDTypeCategories);
      this.allOAOZCategories = cloneDeep(allOAOZCategories);

      console.log('get cats ===> ', this.allOAOZCategories, this.oDTypeCategories);

    } catch (error) {
      console.error(error);
    }
  }

  private insertCategoryToForm(cat: any): void {
    let categoriesControl = this.newLeadForm.get('ODTypeCategoriesIDs') as FormArray;
    categoriesControl.push(this.formBuilder.control(cat.CategoryID));
  }

  private popCategoryFromForm(cat: any): void {
    let categoriesControl = this.newLeadForm.get('ODTypeCategoriesIDs') as FormArray;
    let index: number = categoriesControl.value.indexOf(cat.CategoryID);
    categoriesControl.removeAt(index)//(this.formBuilder.control(cat.CategoryID));
  }

  private initOnChangeHandlers(): void {
    //selected Role changes
    let SelectedRoleControl = this.newLeadForm.get('SelectedRoles');
    if (SelectedRoleControl) {
      SelectedRoleControl.valueChanges.subscribe((value: any) => {
        console.log('change ', value);
        this.handleSelectedRoleChange(value);
      });
    }

    //Mobile controls changes
    this.initMobileControlsChangeHandler();
  }

  private handleSelectedRoleChange(roles: any[]): void {
    console.log('handleSelectedRoleChange ', roles)
    this.oAOZCategories = [];
    let SelectedOAOZCategoryControl = this.newLeadForm.get('SelectedOAOZCategory');
    let OAOZCategoryControl = this.newLeadForm.get('OAOZCategoryID');
    console.log('SelectedOAOZCategoryControl before', SelectedOAOZCategoryControl);
    let haveOARole : boolean = false;
    let haveOZRole : boolean = false;

    let findOARole = roles.find((role: any) => role.RoleCode === RoleCode.OA);
    if(findOARole){
      haveOARole = true;
    }

    let findOZRole = roles.find((role: any) => role.RoleCode === RoleCode.OZ);
    if(findOZRole){
      haveOZRole = true;
    }

    
    if (haveOARole) {
      this.oAOZCategories = [...this.oAOZCategories, ...this.allOAOZCategories.filter((cat: any) => {
        return cat.Code === CategoryCode.OA1 || cat.Code === CategoryCode.OA2;
      })];

      if (SelectedOAOZCategoryControl) {
        SelectedOAOZCategoryControl.setValidators(Validators.required);
      }
      this.showTypeOAOZFormControl = true;
    }
    
    if (haveOZRole) {
      this.oAOZCategories = [...this.oAOZCategories, ...this.allOAOZCategories.filter((cat: any) => {
        return cat.Code === CategoryCode.OZ3 || cat.Code === CategoryCode.OZ4;
      })];

      if (SelectedOAOZCategoryControl) {
        SelectedOAOZCategoryControl.setValidators(Validators.required);
      }
      this.showTypeOAOZFormControl = true;
    }

    if(!haveOARole && !haveOZRole){
      if (SelectedOAOZCategoryControl) {
        SelectedOAOZCategoryControl.clearValidators();
        SelectedOAOZCategoryControl.setValue(null);
      }
  
      if (OAOZCategoryControl) {
        OAOZCategoryControl.setValue(null);
      }
  
      this.showTypeOAOZFormControl = false;
      this.oAOZCategories = [];
    }

    console.log('SelectedOAOZCategoryControl after', SelectedOAOZCategoryControl);
  }

  initMobileControlsChangeHandler(): void{
    // get controls
    let mobileControl = this.newLeadForm.get('Mobile');
    let mobilePrefixControl = this.newLeadForm.get('MobilePrefixSelected');

    if(mobileControl && mobilePrefixControl){
        //handle mobileControl changes
        mobileControl.valueChanges.subscribe((value: any )=> {
            console.log(value);
            if(value && value != ''){
                mobilePrefixControl?.addValidators(Validators.required);
            }
            else{
                mobilePrefixControl?.clearValidators();
            }
        });

        //handle mobilePrefixControl changes
        mobilePrefixControl.valueChanges.subscribe((value: any )=> {
            console.log(value);
            if(value && value != ''){
                mobileControl?.addValidators(Validators.required);
            }
            else{
                mobileControl?.clearValidators();
            }
        });
    }
  }
  // private initCheckedODTypeCategories(): void {
  //   if(this.user?.ODTypeCategoriesIDs && this.oDTypeCategories){
  //     this.oDTypeCategories.forEach((category: any) => {
  //       if(this.user?.ODTypeCategoriesIDs.find((catID: number) => catID === category.CategoryID)){
  //         this.checkedODTypeCategories[category.CategoryID] = category;
  //       }
  //     });
  //   }

  //   console.log('checkedODTypeCategories : ', this.checkedODTypeCategories);
  // }
}
