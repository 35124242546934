import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, firstValueFrom, map } from 'rxjs';
import { ProductsGroup } from '../models/products-group';
import { Cart } from '../models/cart';
import { FullCart } from '../models/full-cart';
import { CartItem } from '../models/cart-item';
import { FinalizeCart } from '../models/finalize-cart';
import { GenerateTraductionRequest, UploadTraductionRequest } from '../models/traduction';
import { SendInvitationMailRequest } from '../models/send-invitation-mail-request';
import { result } from 'lodash';
import { ConferenceUploadMediaInfo, NewsDocUploadMediaInfo, NewsUploadMediaInfo, UploadMediaInfo } from '../models/media-upload-info';
import { ConferenceProgramCode } from '../enums/conference-program-code';
import { Languages } from '../enums/language';
import { MarketingGenerateMediaRequest } from '../models/marketingGenerateMediaRequest';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(
        private http: HttpClient,
    ) {
    }

    getAutentication() {
        const user: any = JSON.parse(sessionStorage.getItem('userData')!);
        const JwtToken: any = (user) ? user.JwtToken : null;
        return JwtToken;
    }

    // =================================================================================== General
    getStatus(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.generalCtrl.status, options);
    }

    async createCategory(model : any){
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        try {
            const response = await firstValueFrom(this.http.post(environment.referentialCtrl.createCategories, model,options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async deleteCategory(id : any,withChilds : boolean = false) : Promise<any>{
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        try {
            const response = await firstValueFrom(this.http.delete(`${environment.referentialCtrl.deleteCategory}/${id}/${withChilds}`, options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getCategories(): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        try {
            const response = await firstValueFrom(this.http.get<any>(environment.generalCtrl.categories, options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    getRoles(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.generalCtrl.roles, options);
    }

    getCivilities(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.generalCtrl.civilities, options);
    }

    getDocumenttypes(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.generalCtrl.documenttypes, options);
    }

    getCountries(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.generalCtrl.countries, options);
    }

    getLanguages(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.generalCtrl.languages, options);
    }
    shareOnLinkedIn(body : any){
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getAutentication(),
          });
        let options = { headers: headers };
        return this.http.post(environment.MarketingCtrl.shareOnLinkedIn, body,options);
    }
    shareOnTwitter(body : any){
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getAutentication(),
          });
        let options = { headers: headers };
        return this.http.post(environment.MarketingCtrl.shareOnTwitter, body,options);
    }
    getTwitterCredentials(callBackUrl : string) : Observable<[string, string]> {
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getAutentication(),
        });
        let options = { headers: headers };
        return this.http.get<[string, string]>(environment.MarketingCtrl.twitterCredentials + `?callBackUrl=${callBackUrl}`,options);
    }

    getPhones(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.generalCtrl.phones, options);
    }

    getFunctions(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.generalCtrl.functions, options);
    }

    getCompanies_EF(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.generalCtrl.companies_EF, options);
    }

    getFolders_EF(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.generalCtrl.folders_EF, options);
    }

    getContacts_EF(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.generalCtrl.contacts_EF, options);
    }

    getProductsgroups(): Observable<ProductsGroup[]> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<ProductsGroup[]>(environment.generalCtrl.productsgroups, options);
    }

    // =================================================================================== User
    async login(email: string, password: string): Promise<any> {
        const body = { Login: email, Password: password };
        try {
            const response = await firstValueFrom(this.http.post(environment.usersCtrl.login, body));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    async loginRedirect(email: string, password: string): Promise<any> {
        const body = { Login: email, Password: password };
        try {
            const response = await firstValueFrom(this.http.post(environment.usersCtrl.autologinredirect, body));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    async mailautologin(user: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.usersCtrl.mailautologin, user, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async directlogin(autologinUser: any): Promise<any> {
        try {
            const response = await firstValueFrom(this.http.post(environment.usersCtrl.directlogin, autologinUser));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getUserAutoLoginUrl(autologinUser : any) : Promise<any>{
        try {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            const response = await firstValueFrom(this.http.post(environment.usersCtrl.getuserAutoLoginUrl, autologinUser,{headers}));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async sendPasswordChoiceEmail(user: any): Promise<any> {
        try {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });

            const response = await firstValueFrom(this.http.post(environment.usersCtrl.sendmailpassword, user, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
        //return this.http.post(environment.usersCtrl.sendmailpassword, user);
    }

    async sendEmailForContact(SendInvitationMailRequest: SendInvitationMailRequest): Promise<any> {
        try {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });

            const response = await firstValueFrom(this.http.post(environment.usersCtrl.sendmailforcontact,
                SendInvitationMailRequest, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
        //return this.http.post(environment.usersCtrl.sendmailpassword, user);
    }
    async sendEmailForUser(UserID: any, Language: any): Promise<any> {
        try {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });

            const response = await firstValueFrom(this.http.post(environment.usersCtrl.sendmailforuser,
                 {UserID, Language}, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
        //return this.http.post(environment.usersCtrl.sendmailpassword, user);
    }

    getresetinfofromtoken(user: any) {
        return this.http.post(environment.usersCtrl.getresetinfofromtoken, user);
    }


    resetpassword(user: any) {
        return this.http.post(environment.usersCtrl.resetpassword, user);
    }


    // =================================================================================== Folder
    // async getstats(): Promise<any> {
    //     let headers = new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + this.getAutentication()
    //     });
    //     try {
    //         const response = await firstValueFrom(this.http.post(environment.foldersCtrl.getstats, { headers }));
    //         return response;
    //     } catch (error) {
    //         console.error(error);
    //         throw error;
    //     }
    //     let options = { headers: headers };
    //     return this.http.post<any>(environment.foldersCtrl.getstats, options);
    // }


    getUrlAutologin(folderId: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        const response = this.http.post(environment.foldersCtrl.urlautologin, { FolderID: folderId }, { headers });
        return response;
    }

    getCustomUrlAutoLogin(): Observable<any>{
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        return this.http.get(environment.generalCtrl.getAutoLoginUrl,{headers})
    }

    getUrlAutologinInnov(InnovationID: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        const response = this.http.post<any>(environment.InnovationFile.urlautologin, { InnovationID: InnovationID }, { headers });
        return response;
    }
    getUrlAutologinSolution(InnovationID:any,SolID: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        const response = this.http.post<any>(environment.InnovationFile.urlautosollogin, { SolID: SolID, InnovationID:InnovationID}, { headers });
        return response;
    }
    getstats(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        return this.http.post<any>(environment.foldersCtrl.getstats, null, { headers });
    }

    async searchFolders(searchObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            //const response = await this.http.post(environment.foldersCtrl.search, searchObj, { headers }).toPromise();
            const response = await firstValueFrom(this.http.post(environment.foldersCtrl.sogenasearch, searchObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async changestatus(objStatus: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            //const response = await this.http.post(environment.foldersCtrl.search, searchObj, { headers }).toPromise();
            const response = await firstValueFrom(this.http.post(environment.foldersCtrl.changestatus, objStatus, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async changestatusInnovation(objStatus: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            //const response = await this.http.post(environment.foldersCtrl.search, searchObj, { headers }).toPromise();
            const response = await firstValueFrom(this.http.post(environment.InnovationFile.changestatus, objStatus, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getFolder(folderId: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.foldersCtrl.get, { FolderID: folderId }, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }

        //return this.http.post<any>(environment.foldersCtrl.get, {FolderID: folderId}, {headers});
    }

    async createFolder(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.foldersCtrl.create, folderObj, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }
    async updateExihibitorNumber(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.foldersCtrl.updateExhibitorNumber, folderObj, { headers }));
            return response;
        } 
        catch (error) {
            console.error();
            throw error;
        }
    }            

    uploadDPCsv(file : File, lang : any = Languages.Default) : Observable<any>{
        const headers = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        let formData = new FormData()
        formData.append('file',file,file.name)
        let url = `${environment.foldersCtrl.importDPCsv}?lang=${lang}`
        return this.http.post<any>(url,formData,options)
    }
    
    async deleteFolder(folderId : any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            let url = `${environment.foldersCtrl.deleteFolder}?folderID=${folderId}`
            const response = await firstValueFrom(this.http.post(url,{}, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    } 

    async updateDirect(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.foldersCtrl.updateDirect, folderObj, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }

    async addIndirect(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.foldersCtrl.addIndirect, folderObj, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }

    async updateIndirect(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.foldersCtrl.updateIndirect, folderObj, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }

    async deleteIndirect(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.foldersCtrl.deleteIndirect, folderObj, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }

    addupdateCartItem(cartObj: CartItem): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post(environment.foldersCtrl.addupdateCart, cartObj, { headers });
        return response;
    }

    getCart(folderId: number): Observable<Cart> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post(environment.foldersCtrl.getcart
            , { FolderID: folderId }
            , { headers });
        return response;
    }

    getFullCart(folderId: number): Observable<FullCart> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post(environment.foldersCtrl.getfullcart
            , { FolderID: folderId }
            , { headers });
        return response;
    }

    deleteCartItem(cartItem: CartItem): Observable<Cart> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post(environment.foldersCtrl.deletecartitem
            , cartItem
            , { headers });
        return response;
    }

    getIndirectNames(folderId: number): Observable<Array<string>> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<Array<string>>(environment.foldersCtrl.getindirectnames
            , { FolderID: folderId }
            , { headers });
        return response;
    }

    acceptCGV(folderId: number): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.foldersCtrl.accept_cgv
            , { FolderID: folderId }
            , { headers });
        return response;
    }

    sendPdftosign(folderId: number, signEmail: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.foldersCtrl.send_pdftosign
            , { FolderID: folderId, SignEmail: signEmail }
            , { headers });
        return response;
    }

    finalizeCart(finalizeCart: FinalizeCart): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.foldersCtrl.finalize_cart
            , finalizeCart
            , { headers });
        return response;
    }

    async setdpstep(_stepObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            console.log('_stepObj ', _stepObj)
            const response = await firstValueFrom(this.http.post(environment.foldersCtrl.setdpstep, _stepObj, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }

    foldersExport(model: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.foldersCtrl.foldersexport
            , model
            , { headers });
        return response;
    }

    companyAutocomplete(companyNameKeyword?: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.foldersCtrl.company_autocomplete
            , { CompanyName: companyNameKeyword ?? '' }
            , { headers });
        return response;
    }

    userNamesAutocomplete(NameKeyword?: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.usersCtrl.names_autocomplete
            , { LastName: NameKeyword ?? '' }
            , { headers });
        return response;
    }

    async searchUsers(searchObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            //const response = await this.http.post(environment.foldersCtrl.search, searchObj, { headers }).toPromise();
            const response = await firstValueFrom(this.http.post(environment.usersCtrl.search, searchObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async createUser(userObj: any, photoFile:any, uploadMediaInfo : UploadMediaInfo): Promise<any> {
        const headers = new HttpHeaders({
            // 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            let formData = new FormData()
            if(photoFile){
                formData.append("photoFile",photoFile,photoFile.fileName)
            }
            formData.append("formData",JSON.stringify(userObj))
            formData.append("mediaUploadInfo",JSON.stringify(uploadMediaInfo))
            const response = await firstValueFrom(this.http.post(environment.usersCtrl.create, formData, { headers }));
            return response;

        } catch (error) {
            console.error();
            throw error;
        }
    }

    async getUser(userId: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.usersCtrl.get, { UserID: userId }, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }

        //return this.http.post(environment.usersCtrl.get, { UserID: userId }, { headers });
    }

    async updateUser(userObj: any, photoFile:any, uploadMediaInfo : UploadMediaInfo): Promise<any> {
        const headers = new HttpHeaders({
            // 'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            let formData = new FormData()
            if(photoFile){
                formData.append("photoFile",photoFile,photoFile.fileName)
            }
            formData.append("formData",JSON.stringify(userObj))
            formData.append("mediaUploadInfo",JSON.stringify(uploadMediaInfo))

            const response = await firstValueFrom(this.http.post(environment.usersCtrl.update, formData, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
        //return this.http.post(environment.usersCtrl.update, userObj, { headers });
    }

    //TODO: api endpoint is not created, check when api endpoint is created
    usersExport(model: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.usersCtrl.usersexport
            , model
            , { headers });
        return response;
    }

    //TODO: api endpoint is not created, check when api endpoint is created
    async changeActive(objActive: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.usersCtrl.changeetat, objActive, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    async searchRoles(searchObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.referentialCtrl.role_search, searchObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    //TODO: api endpoint is not created, check when api endpoint is created
    rolesExport(model: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.referentialCtrl.role_export
            , model
            , { headers });
        return response;
    }
    async createRole(roleObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.referentialCtrl.role_create, roleObj, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }
    async getRole(roleId: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.referentialCtrl.role_get, { RoleID: roleId }, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    async updateRole(roleObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.referentialCtrl.role_update, roleObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async deleteUser(user: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.usersCtrl.delete, user, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }

    async deleteRole(roleObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.referentialCtrl.role_delete, roleObj, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }

    async phoneCheck(_PhoneCheckObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.referentialCtrl.phoneCheck, _PhoneCheckObj, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }

    async privigroupgetall(): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.privigroup_getall, null, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async privigroupget(privigroup: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.privigroup_get, privigroup, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async privigroupcreate(privigroup: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.privigroup_create, privigroup, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async privigroupupdate(privigroup: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.privigroup_update, privigroup, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async privigroupdelete(privigroup: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.privigroup_delete, privigroup, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async privigroupblockstand(privigroup: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.privigroup_blockstand
                , privigroup
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async privigroupchangeisenabled(privigroup: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.privigroup_changeisenabled
                , privigroup
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async privigroupchangeprintplanning(privigroup: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.privigroup_changeprintplanning
                , privigroup
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async privigroupchangeexportplanning(privigroup: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.privigroup_changeprintplanning
                , privigroup
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async activitesget(privigroup: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.activites_get, privigroup, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async adminUsersSearch(searchObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            //const response = await this.http.post(environment.foldersCtrl.search, searchObj, { headers }).toPromise();
            const response = await firstValueFrom(this.http.post(environment.visitmanagerUsersCtrl.search, searchObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    adminUsersNamesAutocomplete(NameKeyword?: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.visitmanagerUsersCtrl.names_autocomplete
            , { LastName: NameKeyword ?? '' }
            , { headers });
        return response;
    }

    async adminUsersCreate(userObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerUsersCtrl.create, userObj, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }

    async adminUsersGet(userId: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerUsersCtrl.get, { UserID: userId }, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }

        //return this.http.post(environment.usersCtrl.get, { UserID: userId }, { headers });
    }

    async adminUsersUpdate(userObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerUsersCtrl.update, userObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
        //return this.http.post(environment.usersCtrl.update, userObj, { headers });
    }

    async adminUsersChangelockaccessapp(objStatus: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            //const response = await this.http.post(environment.foldersCtrl.search, searchObj, { headers }).toPromise();
            const response = await firstValueFrom(this.http.post(environment.visitmanagerUsersCtrl.changelockaccessapp, objStatus, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    async adminUsersDelete(user: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerUsersCtrl.delete, user, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }

    async othervisitgetall(): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.othervisit_getall, null, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async othervisitcreate(othervisit: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.othervisit_create, othervisit, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async othervisitupdate(othervisit: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.othervisit_update, othervisit, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async othervisitdelete(othervisit: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.othervisit_delete, othervisit, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    async othervisitget(othervisitId: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.othervisit_get
                , { OtherVisitID: othervisitId }, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // Exposant: Vm_Folders =================================================================

    async createVmFolder(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFolderCtrl.create, folderObj, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }

    async getVmFolder(formId: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFolderCtrl.get, { FormID: formId }, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }

        //return this.http.post(environment.usersCtrl.get, { UserID: userId }, { headers });
    }

    async updateVmFolder(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFolderCtrl.update, folderObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
        //return this.http.post(environment.usersCtrl.update, userObj, { headers });
    }

    async setLockImportVmFolder(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFolderCtrl.setlockimport, folderObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async searchVmFolders(searchObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFolderCtrl.search, searchObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    exportEP(searchObj: any,endpoint : string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(endpoint
            , searchObj
            , { headers });
        return response;
    }



    async changeEligibilityVmFolder(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFolderCtrl.changeeligibility, folderObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async changePrivgroupVmFolder(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFolderCtrl.changeprivgroup, folderObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async changeBloqueStandVmFolder(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFolderCtrl.changebloquestand, folderObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async changeactiveplanningVmFolder(folderObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFolderCtrl.changeactiveplanning, folderObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    getmeetingstandsVmFolder(formID: number): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = this.http.post(environment.visitmanagerFolderCtrl.getmeetingstands
                , { FormID: formID }
                , { headers });
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    companyAutocompleteVmFolder(companyNameKeyword?: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.visitmanagerFolderCtrl.company_autocomplete
            , { CompanyName2: companyNameKeyword ?? '' }
            , { headers });
        return response;
    }

    confAutocompleteExposant(companyNameKeyword?: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.ConferencesCtrl.exposants_list
            , { CompanyName2: companyNameKeyword ?? '' }
            , { headers });
        return response;
    }


    async createConf(conferenceObj: any
        , logoFile: any
        , photoFile: any
        , uploadMediaInfo: ConferenceUploadMediaInfo): Promise<any> {
        const headers = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const formData = new FormData();
            if(logoFile){
                formData.append('logoFile', logoFile, logoFile.name);
            }

            if(photoFile){
                formData.append('photoFile', photoFile, photoFile.name);
            }

            formData.append('formData', JSON.stringify(conferenceObj));

            console.log('uploadMediaInfo ', uploadMediaInfo);

            formData.append('mediaUploadInfo', JSON.stringify(uploadMediaInfo));

            const response = await firstValueFrom(this.http.post(environment.ConferencesCtrl.save_conf
                , formData
                , { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }


    async updateConference(conferenceObj: any
        , logoFile: any
        , photoFile: any
        , uploadMediaInfo: ConferenceUploadMediaInfo): Promise<any> {
        const headers = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const formData = new FormData();
            if(logoFile){
                formData.append('logoFile', logoFile, logoFile.name);
            }

            if(photoFile){
                formData.append('photoFile', photoFile, photoFile.name);
            }

            formData.append('formData', JSON.stringify(conferenceObj));

            formData.append('mediaUploadInfo', JSON.stringify(uploadMediaInfo));
            console.log('uploadMediaInfo ', uploadMediaInfo);

            const response = await firstValueFrom(this.http.post(environment.ConferencesCtrl.update_conf
                , formData
                , { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }


    setImageMedia(docId: number): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        let url: string = environment.ConferencesCtrl.set_media;
         
        const response = this.http.post<any>(url, { DocID: docId }, options);
        return response;
    }



    async deleteVmFolder(folder: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFolderCtrl.delete
                , folder
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    // ==============================   VisitManager_Prog   ==============================

    async searchVmProgReq(searchObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerProgCtrl.search, searchObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    getAllMeetingStandsVmFolder(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.post<any>(environment.visitmanagerFolderCtrl.getAllMeetingStands, {}, options);
    }

    async getAllDO(isAllDO: boolean): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            let url: string = environment.visitmanagerRefCtrl.getAllDO;
            url = `${url}?allODs=${isAllDO === true ? 'true' : 'false'}`
            const response = await firstValueFrom(this.http.get<any>(url
                , options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async uploadTraductions(request: UploadTraductionRequest): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.referentialCtrl.traduction_content_file_upload
                , request
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async generateTraduction(request: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.referentialCtrl.chatgpt_translate_JsonData
                , request
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    // ========================= VisitManager_Referential =========================
    async searchVmDo(searchObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            //const response = await this.http.post(environment.foldersCtrl.search, searchObj, { headers }).toPromise();
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.od_search, searchObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getActivitesVmDo(grpId: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.activites_get, grpId, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    async createVmDo(vmDoObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.od_create, vmDoObj, { headers }));
            return response;
        } catch (error) {
            console.log(error);
            console.error();
            throw error;
        }
    }

    async getVmDo(vmDoId: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.od_get, { GroupID: vmDoId }, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async updateVmDo(vmDoObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.od_update, vmDoObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async deleteVmDo(vmDo: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.od_delete, vmDo, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async calculeCodeVmDo(grpId: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.od_calculecode, grpId, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    setLockImportVmDo(vmDo: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.od_setlockimport, vmDo, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async createContactVmDo(vmDo: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.od_createcontact, vmDo, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async changestatusVmDo(vmDo: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            //const response = await this.http.post(environment.foldersCtrl.search, searchObj, { headers }).toPromise();
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.od_changestatus
                , vmDo
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getcountries(isAllDO: boolean): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            let url: string = environment.visitmanagerRefCtrl.od_getcountries;
            url = `${url}?allODs=${isAllDO === true ? 'true' : 'false'}`
            const response = await firstValueFrom(this.http.get<any>(url, options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getoas(isAllDO: boolean): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            let url: string = environment.visitmanagerRefCtrl.od_getoas;
            url = `${url}?allODs=${isAllDO === true ? 'true' : 'false'}`
            const response = await firstValueFrom(this.http.get<any>(url, options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    async getozs(isAllDO: boolean): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            let url: string = environment.visitmanagerRefCtrl.od_getozs;
            url = `${url}?allODs=${isAllDO === true ? 'true' : 'false'}`
            const response = await firstValueFrom(this.http.get<any>(url, options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    async getTypeDO(isAllDO: boolean): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            let url: string = environment.visitmanagerRefCtrl.od_typeDO;
            url = `${url}?allODs=${isAllDO === true ? 'true' : 'false'}`
            const response = await firstValueFrom(this.http.get<any>(url
                , options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async searchPlannings(searchObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.od_planningsearch
                , searchObj
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getExposants(folderID: number): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            let url: string = environment.visitmanagerFolderCtrl.getexhibitors;
            url = `${url}?folderID=${folderID}`
            const response = await firstValueFrom(this.http.get<any>(url, options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    // ========================= VisitManager_Contact =========================
    async createVmContact(vmContactObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.create, vmContactObj, { headers }));
            return response;
        } catch (error) {
            console.log(error);
            console.error();
            throw error;
        }
    }

    async getVmContact(componentID: any, groupID: any, contactID: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.get,
                {
                    ComponentID: componentID,
                    GroupID: groupID,
                    ContactID: contactID
                },
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async updateVmContact(vmContactObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.update, vmContactObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async deleteVmContact(vmCotact: any): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.delete, vmCotact, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getVmContactSource(componentID: any, groupID: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.getSource,
                {
                    ComponentID: componentID,
                    GroupID: groupID
                },
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getVmContactSelected(componentID: any, groupID: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.getSelected,
                {
                    ComponentID: componentID,
                    GroupID: groupID
                },
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getVmF18ContactSelected(componentID: any, formID: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.getSelected,
                {
                    ComponentID: componentID,
                    FormID: formID
                },
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async contactSelect(request: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.select,
                request,
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async contactUnSelect(request: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.unSelect,
                request,
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    
    async emailCheck(request: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.emailCheck,
                {
                    Email : request.Email,
                    ContactID : request?.ContactID
                },
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getEdition(): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.get(environment.visitmanagerRefCtrl.get_edition
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    async setrepresentedbyVmDo(vmDo: any): Promise<any> {
        try {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });

            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.changecontactrepresentedby
                , vmDo
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    //========= Request =============//
    async createRequest(request: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });


        try {
            const response = await firstValueFrom(
                this.http.post(environment.VisitManagerRequestCtrl.create
                    , request
                    , { headers }));
            return response;
        } catch (error) {
            console.log(error);
            console.error();
            throw error;
        }
    }

    async updateRequest(request: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(
                this.http.post(environment.VisitManagerRequestCtrl.update
                    , request
                    , { headers }));
            return response;
        } catch (error) {
            console.log(error);
            console.error();
            throw error;
        }
    }

    async getRequest(requestID: number): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(
                this.http.post(environment.VisitManagerRequestCtrl.get
                    , { RequestID: requestID }
                    , { headers }));
            return response;
        } catch (error) {
            console.log(error);
            console.error();
            throw error;
        }
    }


    async searchPlanningsExhibitor(searchObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.od_planning_exhibitor_search
                , searchObj
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    planningODExport(model: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.visitmanagerRefCtrl.export_planning_od
            , model
            , { headers });
        return response;
    }
    planningExposantExport(model: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.visitmanagerRefCtrl.export_planning_exhibitor
            , model
            , { headers });
        return response;
    }
    listDemandeExport(model: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const response = this.http.post<any>(environment.visitmanagerProgCtrl.export_list_demande
            , model
            , { headers });
        return response;
    }

    // ========================= FO: visitmanager_Form & visitmanager_Request =========================

    async getListDoType(formID: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.getListDoType,
                { FormID: formID },
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getListDoCountry(formID: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.getListDoCountry,
                { FormID: formID },
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getListDo(doTypeIDs: any, doPaysIDs: any, formID: any, filterByWhishedCountriesAndCategories: boolean = true): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.getListDo,
                {
                    DoTypeIDS: doTypeIDs,
                    DoPaysIDS: doPaysIDs,
                    FormID: formID,
                    FilterByWhishedCountriesAndCategories: filterByWhishedCountriesAndCategories
                },
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getMyDoListGroup(formID: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.getMyDoListGroup,
                { FormID: formID },
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    //#region Editions
    getEditions(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.visitmanagerRefCtrl.editions, options);
    }
    getEvenements(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.visitmanagerRefCtrl.evenements, options);
    }

    async editionsSearch(searchObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.edition_search, searchObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

     async createEdition(editionObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.edition_create, editionObj, { headers }));
            return response;
        } catch (error) {
            console.error();
            throw error;
        }
    }

    async getEditionId(editionId: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            let url : string = environment.visitmanagerRefCtrl.edition_byId;
            url = `${url}?editionID=${editionId}`
            const response = await firstValueFrom(this.http.get<any>(url, options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async updateEdition(editionObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.edition_update, editionObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    getEditionStatus(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.get<any>(environment.visitmanagerRefCtrl.edition_status, options);
    }
    //#endregion

    // ========================= FO: VmF18-Etat1/2 =========================
    async getEtat1Sur2(formID: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.getForm18Etat1Sur2,
                { FormID: formID },
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async updateEtat1Sur2(formObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.updateForm18Etat1Sur2, formObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async welcomeGetForm(formObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.welcomegetform, formObj, { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

   
    getAllHall(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.post<any>(environment.visitmanagerProgCtrl.getAllHall, options, { headers: headers });
    }
    async setStatusFonctionnel(req: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            //const response = await this.http.post(environment.foldersCtrl.search, searchObj, { headers }).toPromise();
            const response = await firstValueFrom(this.http.post(environment.visitmanagerProgCtrl.setStatusFonctionnel
                , req
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getalloausers(): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            const response = await firstValueFrom(
                this.http.get<any>(environment.visitmanagerRefCtrl.getalloausers
                    , options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getallozusers(): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            const response = await firstValueFrom(
                this.http.get<any>(environment.visitmanagerRefCtrl.getallozusers
                    , options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getallprogusers(): Promise<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            const response = await firstValueFrom(
                this.http.get<any>(environment.visitmanagerRefCtrl.getallprogusers
                    , options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async createODLink(formObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await
                firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.od_createlink
                    , formObj
                    , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async setDemandeOnPlanning(reqID: number): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            let url: string = environment.visitmanagerProgCtrl.setDemandeOnPlanning;
            url = `${url}?vmRequestID=${reqID}`
            //const response = await this.http.post(environment.foldersCtrl.search, searchObj, { headers }).toPromise();
            const response = await firstValueFrom(this.http.post(url
                , {}
                , { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    setDemandOnPlanning(requestList : any) : Observable<any>{
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
      return this.http.post(environment.visitmanagerProgCtrl.setDemandeOnPlanning,{requestList},{headers})   
    }

    async getVmF18ContactSource(componentID: any, formID: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.getSource, 
                {
                    ComponentID: componentID,
                    FormID: formID
                },
                { headers }));return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
    // ========================= INNOV: innov-fo-ecran-step3sur5 =========================

    async getStep(solID: number): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        let url: string = environment.InnovationStepCtrl.getStep;

        const response = await firstValueFrom(this.http.post(url, { SolID: solID }, options));
        return response;
    }

    // --- MEDIA CMP
    uploadLogo(mediaObj: any, fileName: string, mediaFile: Blob): Observable<any> {
        const headers = new HttpHeaders({
            // 'Content-Type': 'application/file',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        let url: string = environment.InnovationMediaCtrl.uploadLogo;
        url = `${url}?SolutionID=${mediaObj.SolutionID}&DocID=${mediaObj.DocID}&Rotation=${mediaObj.Rotation}&ViewOrder=${mediaObj.ViewOrder}&OnSelect=${mediaObj.OnSelect}&ComponentID=${mediaObj.ComponentID}`;

        const formData = new FormData();
        formData.append('file', mediaFile, fileName);

        const response = this.http.post(url, formData, options);
        return response;
    }

    uploadImage(mediaObj: any, fileName: string, mediaFile: Blob): Observable<any> {
        const headers = new HttpHeaders({
            // 'Content-Type': 'application/file',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        let url: string = environment.InnovationMediaCtrl.uploadImage;
        url = `${url}?SolutionID=${mediaObj.SolutionID}&DocID=${mediaObj.DocID}&Rotation=${mediaObj.Rotation}&CreditImage=${mediaObj.CreditImage}&ViewOrder=${mediaObj.ViewOrder}&OnSelect=${mediaObj.OnSelect}&ComponentID=${mediaObj.ComponentID}`;

        const formData = new FormData();
        formData.append('file', mediaFile, fileName);

        const response = this.http.post(url, formData, options);
        return response;
    }

    async deleteRequest(request: any): Promise<any> {
        const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });      
        try {
        const response = await firstValueFrom(
            this.http.post(environment.VisitManagerRequestCtrl.delete
            , request
            , { headers }));
        return response;
    } catch (error) {
        console.log(error);
        console.error();
        throw error;
    }
}
    
uploadVideo(mediaObj: any, fileName: string, mediaFile: Blob): Observable<any> {
    const headers = new HttpHeaders({
        // 'Content-Type': 'application/file',
        'Authorization': 'Bearer ' + this.getAutentication()
    });

    let options = { headers: headers };
    let url: string = environment.InnovationMediaCtrl.uploadVideo;
    url = `${url}?SolutionID=${mediaObj.SolutionID}&DocID=${mediaObj.DocID}&Url=${mediaObj.Url}&ViewOrder=${mediaObj.ViewOrder}&ComponentID=${mediaObj.ComponentID}`;

    const formData = new FormData();
    formData.append('file', mediaFile, fileName);

    const response = this.http.post(url, formData, options);
    return response;
}

uploadExternalVideoURL(mediaObj: any): Observable<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });

    let options = { headers: headers };

    const response = this.http.post(environment.InnovationMediaCtrl.uploadExternalVideoURL, mediaObj, options);
    return response;
}


     //#region Innovation
     async welcomeInnovForm(formObj: any): Promise<any> {
        const headers = new HttpHeaders({
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + this.getAutentication()
           });
   
           try {
               const response = await firstValueFrom(this.http.post(environment.InnovationFile.welcomeInnovFile, formObj, { headers }));
               return response;
           } catch (error) {
               console.error(error);
               throw error;
           }
       }
   
       async setStatusProduct(reqObj: any): Promise<any> {
           const headers = new HttpHeaders({
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + this.getAutentication()
           });
   
           try {
               const response = await firstValueFrom(this.http.post(environment.InnovationFile.setStatusProduct
                   , reqObj
                   , { headers }));
               return response;
           } catch (error) {
               console.error(error);
               throw error;
           }
       }
   
       async getProductById(solID: number): Promise<any> {
           const headers = new HttpHeaders({
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + this.getAutentication()
           });
   
           try {
               const response = await firstValueFrom(this.http.post(environment.InnovationStep.getProduct
                   , { SolID : solID }
                   , { headers }));
               return response;
           } catch (error) {
               console.error(error);
               throw error;
           }
       }
   
       async getAllProducts(obj: any): Promise<any> {
           let headers = new HttpHeaders({
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + this.getAutentication()
           });
   
           try {
               const response = await firstValueFrom(this.http.post(environment.InnovationFile.getAllProducts, obj, { headers }));
               return response;
           } catch (error) {
               console.error();
               throw error;
           }
       }
   
       updatePublishOnNet(request: any): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        return this.http.post<any>(environment.InnovationFile.changepubnet, request, options);
    }
       getFicheInnovation(filter: any): Observable<any> {
           let headers = new HttpHeaders({
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + this.getAutentication()
           });
           let options = { headers: headers };
           return this.http.post<any>(environment.InnovationFile.getFicheInnovation, filter, options);
       }
      
       async deleteProduct(SolutionID: any): Promise<any> {
           const headers = new HttpHeaders({
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + this.getAutentication()
           });
   
           try {
               const response = await firstValueFrom(this.http.post(environment.InnovationFile.deleteProduct, SolutionID, { headers }));
               return response;
           } catch (error) {
               console.error();
               throw error;
           }
       }
   
       async createProduct(solObj: any): Promise<any> {
           const headers = new HttpHeaders({
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + this.getAutentication()
           });
           try {
               const response = await firstValueFrom(this.http.post(environment.InnovationStep.createProduct, solObj, { headers }));
               return response;
           } catch (error) {
               console.error();
               throw error;
           }
       }
   
       async updateProduct(productObj: any): Promise<any> {
           const headers = new HttpHeaders({
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + this.getAutentication()
           });
   
           try {
               const response = await firstValueFrom(this.http.post(environment.InnovationStep.updateProduct, productObj, { headers }));
               return response;
           } catch (error) {
               console.error(error);
               throw error;
           }
       }
   
   
       //#endregion


    
       uploadFile(mediaObj: any, fileName: string, mediaFile: Blob): Observable<any> {
        const headers = new HttpHeaders({
            // 'Content-Type': 'application/file',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        let url: string = environment.InnovationMediaCtrl.uploadDocument;
        url = `${url}?SolutionID=${mediaObj.SolutionID}&DocID=${mediaObj.DocID}&ViewOrder=${mediaObj.ViewOrder}&ComponentID=${mediaObj.ComponentID}`;

        const formData = new FormData();
        formData.append('file', mediaFile, fileName);

        const response = this.http.post(url, formData, options);
        return response;
    }

    downloadMedia(docId: number): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        let url: string = environment.InnovationMediaCtrl.download;

        const response = this.http.post(url, { DocID: docId }, options);
        return response;
    }

    downloadImageMedia(docId: number, docUrl ?: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        let url: string = environment.InnovationMediaCtrl.downloadImageMedia;
         
        const response = this.http.post<any>(url, { DocID: docId, DocUrl : docUrl }, options);
        return response;
    }

    getMediaSettings(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };

        const response = this.http.get<any>(environment.InnovationMediaCtrl.getMediaSettings, options);
        return response;
    }

    getMediaConfigs(): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };

        const response = this.http.get<any>(environment.InnovationMediaCtrl.getMediaConfigs, options);
        return response;
    }

    exportFile(url: string): Observable<any> {
        let headers = new HttpHeaders({
            'Content-Type': 'image/png',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        const httpOptions = {
            'responseType': 'arraybuffer' as 'json',
            headers: headers
        };

        return this.http.get(url, httpOptions);
    }



    deleteMedia(mediaObj: any): Observable<any> {
        const headers = new HttpHeaders({
            // 'Content-Type': 'application/file',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };

        const response = this.http.post(environment.InnovationMediaCtrl.deleteDocument, mediaObj, options);
        return response;
    }

    async getInnovContactSelected(reqObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.getSelected,
                reqObj,
                { headers }));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getInnovContactSource(reqObj: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        try {
            const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.getSource, 
                reqObj,
                { headers }));return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async getFederators(federateurId: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });

            let options = { headers: headers };
            try {
                let url : string = environment.visitmanagerFolderCtrl.getfederators;
                url = `${url}?id=${federateurId}`
                const response = await firstValueFrom(this.http.get<any>(url, options));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
    
    
    
    
        async deleteEdition(editionId: number): Promise<any> {
    
            const headers = new HttpHeaders({
    
                'Content-Type': 'application/json',
    
                'Authorization': 'Bearer ' + this.getAutentication()
    
            });
    
           
    
            let options = { headers: headers };
    
    
    
    
            try {
    
                let url : string = environment.visitmanagerRefCtrl.deleteEdition;
    
                url = `${url}?editionID=${editionId}`
    
                const response = await firstValueFrom(this.http.post<any>(url, {}, options));
    
                return response;
    
            } catch (error) {
    
                console.error();
    
                throw error;
    
            }
    
        }
    
    
    
    
        async updateStatutEdition(req: any): Promise<any> {
    
            const headers = new HttpHeaders({
    
                'Content-Type': 'application/json',
    
                'Authorization': 'Bearer ' + this.getAutentication()
    
            });
    
    
    
    
            try {
    
                const response = await firstValueFrom(this.http.post(environment.visitmanagerRefCtrl.updateStatutEdition
    
                    , req
    
                    , { headers }));
    
                return response;
    
            } catch (error) {
    
                console.error(error);
    
                throw error;
    
            }
    
        }



        async getCounter(formID: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {
                const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.getcounter,
                    { FormID: formID },
                    { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async chatgptGeneratePresentation(request: any): Promise<any> {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {
                const response = await firstValueFrom(this.http.post(environment.referentialCtrl.chatgpt_generate_presentation
                    , request
                    , { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
        async chatgptTranslateTexte(request: any): Promise<any> {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {
                const response = await firstValueFrom(this.http.post(environment.referentialCtrl.chatgpt_translate_text
                    , request
                    , { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
        

        async getContactSource(reqObj: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            
            try {
                const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.getSource, 
                    reqObj,
                    { headers }));return response;
                } 
                catch (error) {
                    console.error(error);
                    throw error;
                }
        }
    
        async getContactSelected(reqObj: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {
                const response = await firstValueFrom(this.http.post(environment.VisitManagerContactCtrl.getSelected,
                    reqObj,
                    { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
    
        async attachHostStand(reqObj: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {
                const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.attachhoststand,
                    reqObj,
                    { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async saveSolutionLastStep(solObj: any): Promise<any> {
            const headers = new HttpHeaders({
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + this.getAutentication()
               });

               try {
                   const response = await firstValueFrom(this.http.post(environment.InnovationStep.saveSolutionLastStep, solObj, { headers }));
                   return response;
               } catch (error) {
                   console.error(error);
                   throw error;
               }
           }

           async supprimerFormulaire(editionObj: any): Promise<any> {
            const headers = new HttpHeaders({
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + this.getAutentication()
               });
       
               try {
                   const response = await firstValueFrom(this.http.post(environment.admin.supprimerFormulaire, editionObj, { headers }));
                   return response;
               } catch (error) {
                   console.error(error);
                   throw error;
               }
           }
           
           async supprimerEdition(editionObj: any): Promise<any> {
            const headers = new HttpHeaders({
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + this.getAutentication()
               });
       
               try {
                   const response = await firstValueFrom(this.http.post(environment.admin.supprimerEdition, editionObj, { headers }));
                   return response;
               } catch (error) {
                   console.error(error);
                   throw error;
               }
           }

           async reInitialiserFindeer(): Promise<any> {
            const headers = new HttpHeaders({
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + this.getAutentication()
               });
       
               try {
                   const response = await firstValueFrom(this.http.post(environment.admin.reInitialiserFindeer,{ },{ headers }));
                   return response;
               } catch (error) {
                   console.error(error);
                   throw error;
               }
           }
           async autologin(autologinRequest: any): Promise<any> {
            try {
                const response = await firstValueFrom(this.http.post(environment.usersCtrl.autologin, autologinRequest));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        getnbDemand(date?: any): Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            let url : string = environment.visitmanagerRefCtrl.getnbdemands;
            url = `${url}?toCalculateDate=${date}`
                const response = this.http.get(url,options);
                return response; 
        }
        

        listVisteExport(model: any): Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            const response = this.http.post<any>(environment.visitmanagerRefCtrl.exportlistvisit
                , model
                , { headers });
            return response;
        }
        listVisiteparActExport(model: any): Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            const response = this.http.post<any>(environment.visitmanagerRefCtrl.exportlistvisitparact
                , model
                , { headers });
            return response;
        }

        getAllStatusRequest(): Observable<any> {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            return this.http.get<any>(environment.visitmanagerRefCtrl.statusdemand, options);
        }

        getAllVisit(): Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
                const response = this.http.get(environment.visitmanagerRefCtrl.getallvisits,options);
                return response; 
        }
        getRatio(model:any): Observable<any>{
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            const response = this.http.post<any>(environment.visitmanagerRefCtrl.getratio
                , model
                , { headers });
            return response;
        }

        getExtendedCountries(extCountry?: string): Observable<any> {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            let options = { headers: headers };
    
            let url: string = environment.generalCtrl.countries;
    
            url = `${url}?request=${extCountry}`;
            return this.http.get<any>(url, options);
        }
        getUrlAutologinForm18(formId: any): Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            const response = this.http.post(environment.visitmanagerFolderCtrl.urlautologin, { FormID: formId }, { headers });
            return response;
        }
    
        getCountriesOD(): Observable<any> {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            return this.http.get<any>(environment.visitmanagerRefCtrl.getcountriesod, options);
        }
        getCountriesExposant():Observable<any>{
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            return this.http.get<any>(environment.visitmanagerRefCtrl.getcountriesexposant, options);
            
        }

        versionAllPlanning():Observable<any>{
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            return this.http.post<any>(environment.visitmanagerRefCtrl.versionAllPlanning,{},{headers})
        }

        changeWebPublishedFolderState(folderStatus : any) : Observable<any>{
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            return this.http.post<any>(environment.foldersCtrl.changeWebPublishedStatus,folderStatus,{headers})
        }

        async InitFedere(obj: any): Promise<any> {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {
                const response = await firstValueFrom(this.http.post(environment.visitmanagerFormCtrl.InitFedere
                    , obj
                    , { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        } 
        
        async searchExhibitor(obj: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {

                const response = await firstValueFrom(this.http.post(environment.visitmanagerFolderCtrl.searchExhibitor, obj, { headers }));
                return response;
                
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async categorieListe(): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {
                let options = { headers: headers };
                let url: string = environment.visitmanagerFolderCtrl.categorieListe;
                const response = await firstValueFrom(this.http.get<any>(url, options))
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async paysListe(): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {

                let options = { headers: headers };
                let url: string = environment.visitmanagerFolderCtrl.paysListe;
                const response = await firstValueFrom(this.http.get<any>(url, options));
                return response;

            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async federatorListe(): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {

                let options = { headers: headers };
                let url: string = environment.visitmanagerFolderCtrl.federatorListe;
                const response = await firstValueFrom(this.http.get<any>(url, options));
                return response;

            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        exhibitorAutocompleteVmFolder(companyNameKeyword?: string): Observable<any> {
            const response = this.http.post<any>(environment.visitmanagerFolderCtrl.exhibitor_autocomplete
                , { CompanyName2: companyNameKeyword ?? '' }
                , { });
            return response;
        }
        async welcomeHelpedForm(formObj: any): Promise<any> {
            const headers = new HttpHeaders({
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + this.getAutentication()
               });
                try {
                   const response = await firstValueFrom(this.http.post(environment.HelpedForm.welcomeHelpedFile, formObj, { headers }));
                   return response;
                 } catch (error) {
                   console.error(error);
                   throw error;
                 }
           }
        

        getEditionInfo(request : any) : Observable<any>{
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            return this.http.post(environment.visitmanagerRefCtrl.getVmParameter,request,{headers})
        }

        updateEditionFormInfo(request : any) : Observable<any>{
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            return this.http.post(environment.visitmanagerRefCtrl.updateVmParameter,request,{headers})
        }

        deleteAttachedEditionInfo(request : any) : Observable<any>{
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            return this.http.post(environment.visitmanagerRefCtrl.deleteAttachedInfo,request,{headers})
        }

        updatePublishOnNetHelped(request: any): Observable<any> {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            return this.http.post<any>(environment.HelpedForm.changepubnet, request, options);
        }
        getFicheHelped(filter: any): Observable<any> {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            return this.http.post<any>(environment.HelpedForm.getFormHelped, filter, options);
        }
        async changestatusHelped(objStatus: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {
                //const response = await this.http.post(environment.foldersCtrl.search, searchObj, { headers }).toPromise();
                const response = await firstValueFrom(this.http.post(environment.HelpedForm.changestatus, objStatus, { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
        getUrlAutologinSolutionHelped(HelpedID:any,SolID: any): Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            const response = this.http.post<any>(environment.HelpedForm.urlautosollogin, { ID: SolID, HlpFormID:HelpedID}, { headers });
            return response;
        }
        async createHelpedSolution(solObj: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {
                const response = await firstValueFrom(this.http.post(environment.HelpedSolution.CreateHelpedSolution, solObj, { headers }));
                return response;
            } catch (error) {
                console.error();
                throw error;
            }
        }
    
        async updateHelpedSolution(productObj: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {
                const response = await firstValueFrom(this.http.post(environment.HelpedSolution.UpdateHelpedSolution, productObj, { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async getHelpedSolutionById(solID: number): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {
                const response = await firstValueFrom(this.http.post(environment.HelpedSolution.getHelpedSolution
                    , { ID : solID }
                    , { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async getAllSolutionsHLP(obj: any): Promise<any> {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {
                const response = await firstValueFrom(this.http.post(environment.HelpedForm.getAllSolutions, obj, { headers }));
                return response;
            } catch (error) {
                console.error();
                throw error;
            }
        }

        async setStatusHLP(reqObj: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {
                const response = await firstValueFrom(this.http.post(environment.HelpedSolution.setStatusHLP
                    , reqObj
                    , { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async deleteSolutionHLP(Solution: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            
            try {
                const response = await firstValueFrom(this.http.post(environment.HelpedSolution.DeleteHelpedSolution, Solution, { headers }));
                return response;
            } catch (error) {
                console.error();
                throw error;
            }
        }
    
        async saveSolutionHelpedLastStep(solObj: any): Promise<any> {
            const headers = new HttpHeaders({
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + this.getAutentication()
               });

               try {
                   const response = await firstValueFrom(this.http.post(environment.HelpedSolution.saveSolutionLastStep, solObj, { headers }));
                   return response;
               } catch (error) {
                   console.error(error);
                   throw error;
               }
           }
           updateEditionFormHelpedInfo(request : any) : Observable<any>{
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            return this.http.post(environment.HelpedForm.pdateHelpedParameter,request,{headers})
        }


        getCommercialList() : Observable<any>{
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            return this.http.get<any>(environment.generalCtrl.commercials,{headers})
        }
    
        updateCommercial(objCommercial: any) : Observable<any>{
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            return this.http.post(environment.foldersCtrl.updateCommercial,objCommercial,{headers});
        }
        
        fichesExportZip(model: any): Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            console.log(model);
            const response = this.http.post<any>(environment.InnovationFile.exportcsvzip
                , model
                , { headers });
            return response;
        }

        getInvalidFormMaximumSheetNumber(model: any): Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            const response = this.http.post<any>(environment.InnovationFile.getInvalidFormMaximumSheetNumber
                , model
                , { headers });
            return response;
        }

        updateMaxNumberSheetForms(model: any): Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            const response = this.http.post<any>(environment.InnovationFile.updateMaxNumberSheetForms
                , model
                , { headers });
            return response;
        }

        updateMaxNumberSheetFormsHelped(model: any): Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            const response = this.http.post<any>(environment.HelpedForm.updateMaxNumberSheetForms
                , model
                , { headers });
            return response;
        }

        updateEditionFormAwardInfo(request : any) : Observable<any>{
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            return this.http.post(environment.Awards.updateAwardParameter,request,{headers})
        }

        updateMaxNumberSheetFormsAwards(model: any): Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            const response = this.http.post<any>(environment.Awards.updateMaxNumberAwardSheetForms
                , model
                , { headers });
            return response;
        }


        //#region ==========    Marketing   ==========
        async getUserInfo(mktFormID : any, formType : any) : Promise<any>{
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });

            try {
                let options = { headers: headers };
                let url: string = environment.MarketingCtrl.getUserInfo;
                url = `${url}?MktFormID=${mktFormID}&FormCatType=${formType}`;
                const response = await firstValueFrom(this.http.post<any>(url, {} ,options));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
        
        generateMedia(mktMediaObj:MarketingGenerateMediaRequest, folderGuid: any, mediaDocObj: any, selectedLogo: boolean,
             selectedPhotoId: boolean,
             isLogoDeleted : boolean,
             isUserPhotoDeleted : boolean,
             frmCode : string
             ): Observable<any> {
            let headers = new HttpHeaders({
                //'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
                let options = { headers: headers };
                let url: string = environment.MarketingCtrl.generateMedia;
                url = `${url}?Text1=${mktMediaObj.Text1 ?? ""}&Text2=${mktMediaObj.Text2 ?? ""}&Text3=${mktMediaObj.Text3 ?? ""}&Text4=${mktMediaObj.Text4 ?? ""}&Logo=${mktMediaObj.Logo}&UserPhoto=${mktMediaObj.UserPhoto}&WebsiteUrl=${mktMediaObj.WebsiteUrl}&CategoryID=${mktMediaObj.CategoryID}&folderGuid=${folderGuid}&selectedLogo=${selectedLogo}&selectedPhotoId=${selectedPhotoId}&isLogoDeleted=${isLogoDeleted}&isUserPhotoDeleted=${isUserPhotoDeleted}&frmCode=${frmCode}`
                            

                const formData = new FormData();
                mediaDocObj.forEach((obj:any) => {
                    formData.append('file', obj.mediaBlob,obj.mediaType);
                    //formData.append('mediaType', obj.mediaType)
                });

                const response = this.http.post<any>(url, formData ,options);
                return response;
        }

        async getMktCategories(): Promise<any> {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });

            try {
                let options = { headers: headers };
                let url: string = environment.MarketingCtrl.getMktCategories;
                const response = await firstValueFrom(this.http.post<any>(url, {} ,options));
                return response;

            } catch (error) {
                console.error(error);
                throw error;
            }
        }
        
        async getMedia(mediaDocID:number): Promise<any> {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });

            try {

                let options = {headers: headers};

                let url: string = environment.InnovationMediaCtrl.getMedia;

                const response = await firstValueFrom(this.http.post<any>(url, {DocID: mediaDocID}, options));

                return response
            } catch (error) {
                console.log(error);
                throw error;
                
            }
        }
        
        async createMktForm(folderGuid:string, formType : any): Promise<any> {
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });

            try {

                let options = {headers: headers};
                let url: string = environment.MarketingCtrl.createMktForm;

                let request = {
                    FolderInternalGuid: folderGuid,
                    FrmCode : formType
                }

                const response = await firstValueFrom(this.http.post<any>(url, request, options));

                return response
            } catch (error) {
                console.log(error);
                throw error;
                
            }

        }

        //#endregion

        
          downloadZipFile(folderID:number,categoryID:number): Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            let options = { headers: headers };
            let url: string = environment.MarketingCtrl.downloadZipFile;
    
            const response = this.http.post(url, {
                FolderID: folderID,
                CategoryID: categoryID
            }, options);
            return response;
        }
        async getBanner(formMkt:number,categoryID:number): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {

                let options = { headers: headers };

                let url: string = environment.MarketingCtrl.getBanner;
                url = `${url}?MktFormID=${formMkt}&CategoryID=${categoryID}`;
                const response = await firstValueFrom(this.http.post<any>(url, {}, options));

                return response
            } catch (error) {
                console.log(error);
                throw error;
                
            }
        }

        async searchConference(obj: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {

                const response = await firstValueFrom(this.http.post(environment.Conference.searchConference, obj, { headers }));
                return response;
                
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async getSpeakers(program: string): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            try {
                
                let url: string = `${environment.Conference.getSpeakers}?program=${program ?? ConferenceProgramCode.PROG1}`
                const response = await firstValueFrom(this.http.get<any>(url
                    , options));
                return response;

            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async getExhibitors(program: string): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            try {
                let url: string = `${environment.Conference.getExhibitors}?program=${program ?? ConferenceProgramCode.PROG1}`;                
                const response = await firstValueFrom(this.http.get<any>(url
                    , options));
                return response;

            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async searchSolution(obj: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {

                const response = await firstValueFrom(this.http.post(environment.Exhibitor.searchSolution, obj, { headers }));
                return response;
                
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async getSolutionByLanguageAndId(language: string, id: string): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            try {

                let url: string = `${environment.Exhibitor.getSolutionByLanguageAndId}/${language}/${id}`
                const response = await firstValueFrom(this.http.get<any>(url
                    , options));
                return response;
                
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async getInterests(program: string): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            try {
                let url: string = `${environment.Conference.getInterests}?program=${program ?? ConferenceProgramCode.PROG1}`
                const response = await firstValueFrom(this.http.get<any>(url
                    , options));
                return response;

            } catch (error) {
                console.error(error);
                throw error;
            }
        }
        generateFicheMedia(generateFicheRequest : any) : Observable<any>{
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            return this.http.post<any>(environment.InnovationFile.generateFicheInnovation,generateFicheRequest,options)
        }

        generateFicheMediaAsync(generateFicheRequest : any, frmCode : string) : Observable<any>{
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            let url = `${environment.InnovationFile.generateFicheInnovationAsync}?frmCode=${frmCode}`
            return this.http.post<any>(url,generateFicheRequest,options)
        }
        generateHelpedFiche(solutionId : number) : Observable<any>{
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            return this.http.get<any>(environment.HelpedSolution.generateFicheHelped + solutionId,options)
        }

        uploadDoCsv(file : File, lang : any = Languages.Default) : Observable<any>{
            const headers = new HttpHeaders({
                //'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            let formData = new FormData()
            formData.append('file',file,file.name)
            let url = `${environment.admin.importDoCsv}?lang=${lang}`
            return this.http.post<any>(url,formData,options)
        }

        uploadOzCsv(file : File, currentLang : any = Languages.Default) : Observable<any>{
            const headers = new HttpHeaders({
                //'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let formData = new FormData()
            formData.append('file',file,file.name)
            let url = `${environment.admin.importOzCsv}?lang=${currentLang}`
            return this.http.post<any>(url,formData,{headers})
        }

        async getRooms(program: string): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            try {

                let url: string = `${environment.Conference.getRooms}?program=${program ?? ConferenceProgramCode.PROG1}`;
                const response = await firstValueFrom(this.http.get<any>(url, options));
                return response;

            } catch (error) {
                console.error(error);
                throw error;
            }
        }
        async getTracks(program: string): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            try {
                let url: string = `${environment.Conference.getTracks}?program=${program ?? ConferenceProgramCode.PROG1}`;            
                const response = await firstValueFrom(this.http.get<any>(url, options));
                return response;

            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        
    async getSpeakerById(speakerId: any, program: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            let url : string = environment.Conference.getSpeakerById;
            url = `${url}?speakerID=${speakerId}&program=${program}`
            const response = await firstValueFrom(this.http.get<any>(url, options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getConfByEventId(confEventID: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            let url : string = environment.Conference.getConfByEventId;
            url = `${url}?confEventID=${confEventID}`
            const response = await firstValueFrom(this.http.get<any>(url, options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getConfById(confId: any): Promise<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        let options = { headers: headers };
        try {
            let url : string = environment.ConferencesCtrl.getConfById;
            url = `${url}?confID=${confId}`
            const response = await firstValueFrom(this.http.get<any>(url, options));
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    
    async searchSpeakers(obj:any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            try {

                const response = await firstValueFrom(this.http.post<any>(environment.Conference.searchSpeakers,obj, options));
                return response;

            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async getFirstFolderGuid() : Promise<any>{
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };

            try{
                const response = await firstValueFrom(this.http.get<any>(environment.foldersCtrl.getFirstFolderGuid,options))
                return response
            }catch (error){
                console.error(error);
                throw error;
            }
        }
        async getNextSessionsBySpeakerId(obj:any): Promise<any> {

            try {

                const response = await firstValueFrom(this.http.post<any>(environment.Conference.getNextSessionsBySpeakerId,obj));
                return response;

            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async getAllSessionsBySpeakerId(speakerID: any, program: string): Promise<any> {

            try {
                let url : string = environment.Conference.getAllSessionsBySpeakerId;
                url = `${url}?speakerID=${speakerID}&program=${program}`
                const response = await firstValueFrom(this.http.get<any>(url));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
        async getSpeakersCountires(): Promise<any> {

            try {

                const response = await firstValueFrom(this.http.get<any>(environment.Conference.getSpeakersCountries));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        //#region ==========    Attendees   ==========

        
    attendeesNameAutocomplete(NameKeyword?: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        let url: string = `${environment.AttendeesCtrl.names_autocomplete}?attendeeLastName=${NameKeyword}`;

          return  this.http.get<any>(url, options);
    }

    companiesNameAutocomplete(CompanyNameKeyword?: any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };
        let url: string = `${environment.AttendeesCtrl.company_autocomplete}?companyName=${CompanyNameKeyword}`;

          return  this.http.get<any>(url, options);
    }

    async SetValidationState(body: any){
        try {
            let headers = new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getAutentication(),
            });
            let options = { headers: headers };
            let url: string = environment.AttendeesCtrl.setValidationState;
    
            const response = await  firstValueFrom(this.http.post(url,body, options));
    
            // Process the response as needed
            return response;
        } catch (error) {
            console.error('Error in SetValidationState:', error);
            // Handle the error as needed
            throw error;
        }
    }
    async SetNetworkingState(body: any){
        try {
            let headers = new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getAutentication(),
            });
            let options = { headers: headers };
            let url: string = environment.AttendeesCtrl.setNetworkingState;
    
            const response = await firstValueFrom(this.http.post(url,body, options));
    
            // Process the response as needed
            return response;
        } catch (error) {
            console.error('Error in SetValidationState:', error);
            // Handle the error as needed
            throw error;
        }
    }

    specificSearchAttendee(body:any){
        try {
            let headers = new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getAutentication(),
            });
            let options = { headers: headers };
            let url: string = environment.AttendeesCtrl.specificSearchAttendee;
    
            return  this.http.post(url,body, options);
    
            // Process the response as needed
           // return response;
        } catch (error) {
            console.error('Error in SetValidationState:', error);
            // Handle the error as needed
            throw error;
        }
    }



      //#region ==========   BO Conferences   ==========

      async getAllOrganisers(IsProgram : any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };

        const response = await firstValueFrom(this.http.get<any>(`${environment.ConferencesCtrl.organisers_list}/${IsProgram}`
            , options));

          return  response
    }

    getAllValidOrganisers(IsProgram : any): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };

          return  this.http.get<any>(`${environment.ConferencesCtrl.valid_organisers}/${IsProgram}`,options);
    }



    getAllSpeakers(): Observable<any>{
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };

          return  this.http.get<any>(environment.ConferencesCtrl.speakers_list,options);
    }

    getAllPrograms(): Observable<any>{
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };

          return  this.http.get<any>(environment.ConferencesCtrl.program_list,options);
    }

    getAllInterests(): Observable<any>{
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });
        let options = { headers: headers };

          return  this.http.get<any>(environment.ConferencesCtrl.interests_list,options);
    }



    async GetFilteredConferences(body:any){
        try {
            let headers = new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getAutentication(),
            });
            let options = { headers: headers };
            let url: string = environment.ConferencesCtrl.conferences_list;
    
            return  this.http.post(url,body, options);
        } catch (error) {
            console.error('Error in GetFilteredConferences:', error);
            throw error;
        }
    }


    async SetConferenceState(body: any){
        try {
            let headers = new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getAutentication(),
            });
            let options = { headers: headers };
            let url: string = environment.ConferencesCtrl.changeConfState;
    
            const response = await firstValueFrom(this.http.post(url,body, options));
            return response;
        } catch (error) {
            console.error('Error in changeConfState:', error);
            // Handle the error as needed
            throw error;
        }
    }

    async SetPublishOnWebState(body: any){
        try {
            let headers = new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getAutentication(),
            });
            let options = { headers: headers };
            let url: string = environment.ConferencesCtrl.publishOnWebState;
    
            const response = await firstValueFrom(this.http.post(url,body, options));
            return response;
        } catch (error) {
            console.error('Error in changeConfState:', error);
            throw error;
        }
    }
    //#endregion ==========  BO Conferences   ==========


 //#region ==========   BO News   ==========

 async updateNews(model: any, photoFileFR: any, photoFileEN: any, photoFileES: any, uploadMediaInfo: NewsDocUploadMediaInfo) : Promise<any>{
    const headers = new HttpHeaders({
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });
    try {
        const formData = new FormData();
        if(photoFileFR){
            formData.append('photoFileFR', photoFileFR, photoFileFR.name);
        }
        if(photoFileEN){
            formData.append('photoFileEN', photoFileEN, photoFileEN.name);
        }
        if(photoFileES){
            formData.append('photoFileES', photoFileES, photoFileES.name);
        }
        formData.append('formData', JSON.stringify(model));

        formData.append('MediaNewsUploadInfo', JSON.stringify(uploadMediaInfo));

        const response = await firstValueFrom(this.http.post(environment.NewsCtrl.update_news
            , formData
            , { headers }));
        return response;
    } catch (error) {
        console.error();
        throw error;
    }
 }

 async getNewsConnectedUserInfo(folderGuid : string){
    let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getAutentication(),
    });

    let options = { headers: headers };
    let url: string = `${environment.NewsCtrl.get_user_info}/${folderGuid}`;

    return await firstValueFrom(this.http.get(url,options))

 }


 getNewsCategories() : Observable<any>{
    let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getAutentication(),
    });

    let options = { headers: headers };
    let url: string = `${environment.NewsCtrl.get_news_categories}`;

    return this.http.get(url,options)
 }

 async createNews(model: any
    , photoFileFR: any, photoFileEN: any, photoFileES: any, uploadMediaInfo: NewsDocUploadMediaInfo): Promise<any> {
    const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.getAutentication()
    });
    try {
        const formData = new FormData();

        if(photoFileFR){
            formData.append('photoFileFR', photoFileFR, photoFileFR.name);
        }
        if(photoFileEN){
            formData.append('photoFileEN', photoFileEN, photoFileEN.name);
        }
        if(photoFileES){
            formData.append('photoFileES', photoFileES, photoFileES.name);
        }

        formData.append('formData', JSON.stringify(model));

        formData.append('MediaNewsUploadInfo', JSON.stringify(uploadMediaInfo));


        const response = await firstValueFrom(this.http.post(environment.NewsCtrl.create_news
            , formData
            , { headers }));
        return response;
    } catch (error) {
        console.error();
        throw error;
    }
}

 async getNewsById(id : any){
    let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getAutentication(),
    });

    let options = { headers: headers };
    let url: string = `${environment.NewsCtrl.news_by_Id}/${id}`;

    return await firstValueFrom(this.http.get(url,options))

 }

 async searchNews(body:any){
    try {
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getAutentication(),
        });
        let options = { headers: headers };
        let url: string = environment.NewsCtrl.news_list;

        return  this.http.post(url,body, options);

        // Process the response as needed
       // return response;
    } catch (error) {
        console.error('Error in SetValidationState:', error);
        // Handle the error as needed
        throw error;
    }
}

GetAllAreasPublications(){
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });
    let options = { headers: headers };

      return  this.http.get<any>(environment.NewsCtrl.areasPublications_list,options);
}

async ChangeNewsStatus(body : any){
    try {
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getAutentication(),
        });
        let options = { headers: headers };
        let url: string = environment.NewsCtrl.change_news_status;

        const response = await firstValueFrom(this.http.post(url,body, options));
        return response;
    } catch (error) {
        console.error('Error in changeConfState:', error);
        throw error;
    }
}

async deleteNewsFo(newsId : any) : Promise<any>{
    try {
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getAutentication(),
        });
        let options = { headers: headers };
        let url: string = `${environment.NewsCtrl.delete_news_fo}/${newsId}`;

        const response = await firstValueFrom(this.http.delete(url, options));
        return response;
    } catch (error) {
        console.error('Error in delete news fo:', error);
        throw error;
    }
}


//#endregion ==========  BO News   ==========



 //#region ==========   FO News   ==========

 async saveNews(newsRequest: any
    , photoFileEN: any
    , pdfFileEN: any
    , uploadMediaInfo: NewsUploadMediaInfo): Promise<any> {
    const headers = new HttpHeaders({
        //'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });
    try {
        const formData = new FormData();

        if(photoFileEN){
            formData.append('photoFileEN', photoFileEN, photoFileEN.name);
        }
        if(pdfFileEN){
            formData.append('pdfFileEN',pdfFileEN,pdfFileEN.name)
        }

        formData.append('formData', JSON.stringify(newsRequest));

        console.log('uploadMediaInfo ', uploadMediaInfo);

        formData.append('mediaUploadInfo', JSON.stringify(uploadMediaInfo));

        const response = await firstValueFrom(this.http.post(environment.NewsCtrl.save_news_fo
            , formData
            , { headers }));
        return response;
    } catch (error) {
        console.error();
        throw error;
    }
}

async updateNewsFO(newsRequest: any
    , photoFileEN: any
    , pdfFileEN: any
    , uploadMediaInfo: NewsUploadMediaInfo): Promise<any>{
        const headers = new HttpHeaders({
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getAutentication()
        });

        const formData = new FormData();
            if(photoFileEN){
                formData.append('photoFileEN', photoFileEN, photoFileEN.name);
            }

            if(pdfFileEN){
                formData.append('pdfFileEN', pdfFileEN, pdfFileEN.name);
            }

            formData.append('formData', JSON.stringify(newsRequest));

            formData.append('mediaUploadInfo', JSON.stringify(uploadMediaInfo));
            console.log('uploadMediaInfo ', uploadMediaInfo);

            const response = await firstValueFrom(this.http.post(environment.NewsCtrl.update_news_fo
                , formData
                , { headers }));
            return response;
    }

async getListNews(){
    let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getAutentication(),
    });

    let options = { headers: headers };
    let url: string = `${environment.NewsCtrl.get_news_fo}`;

    return await firstValueFrom(this.http.get(url,options))
}

async getCommuniquePressWelcom(folderGuid :any) : Promise<any>{
    let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getAutentication(),
    });

    let options = { headers: headers };
    let url: string = `${environment.NewsCtrl.communique_press_wlcm}`;
    let request = {
        FolderGuid : folderGuid
    }
    return await firstValueFrom(this.http.post(url,request,options))
}


async ChangeNewsWebPublishedStatus(body : any){
    try {
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.getAutentication(),
        });
        let options = { headers: headers };
        let url: string = environment.NewsCtrl.change_news_web_publish_status;

        const response = await firstValueFrom(this.http.post(url,body, options));
        return response;
    } catch (error) {
        console.error('Error in changeConfState:', error);
        throw error;
    }
}



//#endregion ==========  FO News   ==========


conferenceSetLockImport(conference: any): Promise<any> {
    let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });
    try {
        const response = firstValueFrom(this.http.post(environment.ConferencesCtrl.conf_setlockimport
            , conference, { headers }));
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

async conferenceDelete(conference: any): Promise<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });

    try {
        const response = await firstValueFrom(this.http.post(environment.ConferencesCtrl.delete_conf
            , conference
            , { headers }));
        return response;
    } catch (error) {
        console.error();
        throw error;
    }
}

async getContactsByRole(roleid: number): Promise<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });
    let options = { headers: headers };
    try {
        let url: string = `${environment.usersCtrl.getContactsByRole}?roleid=${roleid}`;
        const response = await firstValueFrom(this.http.get<any>(url
            , options));
        return response;

    } catch (error) {
        console.error(error);
        throw error;
    }
}

//#region =========== Program =========
async welcomeProgramForm(formObj: any): Promise<any> {
    const headers = new HttpHeaders({
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + this.getAutentication()
       });
        try {
           const response = await firstValueFrom(this.http.post(environment.ProgramForm.welcomeProgramFile, formObj, { headers }));
           return response;
         } catch (error) {
           console.error(error);
           throw error;
         }
   }
   async getProgramForm(obj: any): Promise<any> {
    let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });

    try {
        const response = await firstValueFrom(this.http.post(environment.ProgramForm.getFormProgram, obj, { headers }));
        return response;
    } catch (error) {
        console.error();
        throw error;
    }
}
async getProgramSolutionById(solID: number): Promise<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });

    try {
        const response = await firstValueFrom(this.http.post(environment.ProgramSolution.getProgramSolution
            , { ID : solID }
            , { headers }));
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

async setStatusProg(reqObj: any): Promise<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });

    try {
        const response = await firstValueFrom(this.http.post(environment.ProgramSolution.setStatusProg
            , reqObj
            , { headers }));
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

async deleteSolutionProg(Solution: any): Promise<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });
    
    try {
        const response = await firstValueFrom(this.http.post(environment.ProgramSolution.DeleteProgramSolution, Solution, { headers }));
        return response;
    } catch (error) {
        console.error();
        throw error;
    }
}

async updateProgramSolution(productObj: any): Promise<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });

    try {
        const response = await firstValueFrom(this.http.post(environment.ProgramSolution.UpdateProgramSolution, productObj, { headers }));
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
//#endregion =========== Program =========



//#endregion =========== SOMMAIRE =========
getSommaire(): Observable<any> {
    let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });
    let options = { headers: headers };
    return this.http.get<any>(environment.Sommaire.ExhibitorsForms, options);
}
//#endregion =========== SOMMAIRE =========


//#region =========== Catalog =========

async getCountriesExhibitor(): Promise<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });
    let options = { headers: headers };
    try {
        let url: string = `${environment.Catalog.getCountriesExhibitor}`;            
        const response = await firstValueFrom(this.http.get<any>(url, options));
         return response;

    } catch (error) {
        console.error(error);
        throw error;
    }
}

async getCategoriesExhibitor(): Promise<any>{
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });
    let options = { headers: headers };
    try {
        let url: string = `${environment.Catalog.getCategoriesExhibitor}`;                
        const response = await firstValueFrom(this.http.get<any>(url, options));
         return response;

    } catch (error) {
        console.error(error);
        throw error;
    }
}

async searchExhibitors(obj: any): Promise<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });
    try {

        const response = await firstValueFrom(this.http.post(environment.Catalog.SearchExhibitors, obj, { headers }));
        return response;
        
    } catch (error) {
        console.error(error);
        throw error;
    }
}

async getExhibitorById(exhiID: any,lang: any): Promise<any> {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getAutentication()
    });

    let options = { headers: headers };
    try {
        let url : string = environment.Catalog.getExhibitorByID;
        url = `${url}/${exhiID}/${lang}`
        const response = await firstValueFrom(this.http.get<any>(url, options));
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
//#endregion =========== Catalog =========
        
        async getTypeCategories(program: string): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            let options = { headers: headers };
            try {
                let url: string = `${environment.Conference.getTypeCategories}?program=${program ?? ConferenceProgramCode.PROG1}`;            
                const response = await firstValueFrom(this.http.get<any>(url, options));
                return response;

            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async getSolutionById(solID: any,lang: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
        
            let options = { headers: headers };
            try {
                let url : string = environment.Catalog.getSolutionByID;
                url = `${url}/${solID}/${lang}`
                const response = await firstValueFrom(this.http.get<any>(url, options));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async searchSolutions(obj: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {
        
                const response = await firstValueFrom(this.http.post(environment.Catalog.SearchSolutions, obj, { headers }));
                return response;
                
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
        //#region Awards //
        async getAwardSolutionById(solID ?: number): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {
                const response = await firstValueFrom(this.http.post(environment.Awards.getSolutionById
                    , { ID : solID }
                    , { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async getCompanyInfo(folderGuid :any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {
                let params = new HttpParams()
                .set('FolderGUID', folderGuid);
                let headers = new HttpHeaders()
                  .set('Authorization', 'Bearer ' + this.getAutentication());
                const response = await firstValueFrom( this.http.post(environment.Awards.getCompanyInfo,null, { params: params, headers: headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
        async updateCompany(companyObj :any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try {
                const response = await firstValueFrom(this.http.post(environment.Awards.updateCompany, companyObj, { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
        async createAwardSolution(awardObj :any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try 
            {
                const response = await firstValueFrom(this.http.post(environment.Awards.createAwardSolution,awardObj, {headers}));
                return response;
            } 
            catch (error)
            {
                console.error(error);
                throw error;
            }
        }
        async updateAwardSolution(awardObj :any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            try 
            {
                const response = await firstValueFrom(this.http.post(environment.Awards.updateAwardSolutionLight,awardObj, {headers}));
                return response;
            } 
            catch (error)
            {
                console.error(error);
                throw error;
            }
        }
        async setStatusAward(req: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {
                const response = await firstValueFrom(this.http.post(environment.Awards.setStatusAward
                    , req
                    , { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }      

            async updateAwardInnovation(request: any
                , photo: any
                , pdfFile: any
                , uploadMediaInfo: NewsUploadMediaInfo): Promise<any>{
                    const headers = new HttpHeaders({
                        //'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getAutentication()
                    });
            
                    const formData = new FormData();
                        if(photo){
                            formData.append('photoFile', photo, photo.name);
                        }
            
                        if(pdfFile){
                            formData.append('pdfFile', pdfFile, pdfFile.name);
                        }
            
                        formData.append('formData', JSON.stringify(request));
            
                        formData.append('MediaAwardUploadInfo', JSON.stringify(uploadMediaInfo));
                        console.log('uploadMediaInfo ', uploadMediaInfo);
            
                        const response = await firstValueFrom(this.http.post(environment.Awards.updateAwardSolution
                            , formData
                            , { headers }));
                        return response;
                }

            async getAwardFormWelcom(folderGuid :any) : Promise<any>{
                let headers = new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + this.getAutentication(),
                });
            
                let options = { headers: headers };
                let url: string = `${environment.Awards.getWelcome}`;
                let request = {
                    FolderGuid : folderGuid
                }
                return await firstValueFrom(this.http.post(url,request,options))
            }
            
        async checkAwardSolutions(formID: number): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {
                let url: string = `${environment.Awards.checkSolutions}?AwardFormID=${formID}`
                const response = await firstValueFrom(this.http.get(url
                    , { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async publishAwardSolution(req: any): Promise<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
    
            try {
                const response = await firstValueFrom(this.http.post(environment.Awards.publishAwardSolution
                    , req
                    , { headers }));
                return response;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        getAwardsList(req : any) : Observable<any> {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            return this.http.post<any>(environment.Awards.searchAwardsBo,
                req, {headers}
            )
        }

        updateAwardStatusList(request : any) : Observable<any>{
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.getAutentication()
            });
            return this.http.post<any>(environment.Awards.updateAwardsListStatus,
                request, {headers}
            )
        }
}




        


