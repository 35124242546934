import { Component, Input } from '@angular/core';
import { SharedtoolsService } from 'src/app/services/sharedtools.service';

@Component({
  selector: 'app-conf-replay-button',
  templateUrl: './conf-replay-button.component.html',
  styleUrls: ['./conf-replay-button.component.less']
})
export class ConfReplayButtonComponent {
  @Input() conferenceDetail: any;
  @Input() styleClass: string = '';

  constructor(public sharedService: SharedtoolsService){}
}
