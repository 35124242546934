export class SearchProductInnov {
    public companyName2?: string;
    public companyExternalID1?: string;
    public countryID?: number | null;
    public statusID?: number | null;
    public isPublishOnNet?: boolean | null;
    public submissionDateStart?: string | null;
    public submissionDateEnd?: string | null;
    public solutionNameEN?: string;
    public solutionNameFR?: string;
    public solutionNameES?: string;
    public categoryID?: number | null;
    public SelectedStatus?: any;
    public EmailForCSV?:string;
    public LanguageForCSV?:string;
    public SelectedSolutionsIds?:number[];
    // public CountryID?: number | null = null;
    // public CompanyName?: string | null = null;
    // //public ExhibitorID?: number | null = null;
    // public StatusID?: number | null = null;
    // public CategoryID?: number | null = null;
    // public ExhibitorTypeID?: number | null = null;
    // public StandTypeID?: number | null = null;
    // public SubmissionDateStart?: Date | null = null;
    // public SubmissionDateEnd?: Date | null = null;
    // public selectedCountry?: any;
    // public selectedCategory?: any;
    // public selectedNature?: any;
    // public selectedTypeStand?: any;
}