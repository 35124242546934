import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaHelper } from '@helpers/media.helper';
import { split } from 'lodash';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Observable, Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { FormCode } from 'src/app/enums/form-code';
import { MediasDegRotation } from 'src/app/enums/media-rotation-deg';
import { MediasTypes } from 'src/app/enums/media-type';
import { MedisUnit } from 'src/app/enums/media-unit';
import { MediasSizes } from 'src/app/enums/medias-size';
import { MediaValueChange } from 'src/app/models/media-value-change';

@Component({
  selector: 'app-media-cmp',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './media-cmp.component.html',
  styleUrls: ['./media-cmp.component.less']
})
export class MediaCmpComponent implements OnInit, OnDestroy {
  private _document: any;

  @Input() set document(value: any) {
    this._document = value;

    this.extractFileFromDoc();
  }

  get document(): any {
    return this._document;
  }


  @ViewChild('fileInput') fileInput?: FileUpload;
  @ViewChild('myImage') imgElementRef?: ElementRef;
  imageBase64: string | null = null;

  //@Input() document?: any; 
  @Input() mediaType?: any | null;
  @Input() mediaIcon?: any | null;
  @Input() creditImage?: string | null;
  @Input() SolutionID!: number;
  //@Input() DocID?: number;
  @Input() mediaIndex?: number;

  // #startRegion Settings
  @Input() showTitle?: boolean = true;
  @Input() showBorder?: boolean = true;
  @Input() showUpload?: boolean = true;
  @Input() showDownload?: boolean = true;
  @Input() showEmail?: boolean = false;
  @Input() showCredit?: boolean = true;

  @Input() showOtherInfo?: boolean = false;
  @Input() solutionName: string = "" // Label = "NRBC"
  @Input() relationName: string = "" // NumExposant = "111111";
  @Input() companyName: string = "" // ProductGroupName = "Navy System group";

  @Input() submitEvent?: Observable<void>;


  @Input() componentID!: number;
  @Input() showImageInfo?: boolean = true;

  @Input() synchronizeMediaInApi?: boolean = true
  @Input() documentUrl?: string
  @Input() formCode: FormCode = FormCode.FRM_INNOV;

  @Output() uploadResult: EventEmitter<boolean | null> = new EventEmitter<boolean | null>();
  @Output() rotationStyleChange: EventEmitter<MediasDegRotation> = new EventEmitter<MediasDegRotation>();
  // #endRegion

  medisUnit = MedisUnit;

  title: string = "";
  isContainMediaFile: boolean = false;
  isUploaded: boolean = false;
  isDownload: boolean = false;
  isEdit: boolean = false;

  getMediasSizes = MediasSizes;
  mediasTypes = MediasTypes;

  selectedFile: any;
  fileName: string = "";
  poids: string = "";
  errorVideo: string = "";
  selectedFileURL: string | null = null;
  selectedFileType: string | null = null;
  fileType: string | undefined;

  mediaActions = 'hide';
  mediaActionsVideo = 'hide';
  isValidSize: boolean = true;
  isMediaValidSize: boolean = true;

  // Media : Image
  isLogo: boolean = false;
  isImage: boolean = false;
  imageDimensions: { width: number; height: number } = { width: 0, height: 0 };
  imageDpi: any;
  rotate: number = 0;

  private _rotationStyle: MediasDegRotation = MediasDegRotation._0deg;
  public set rotationStyle(value: MediasDegRotation) {
    this._rotationStyle = value;
    this.rotationStyleChange.emit(this._rotationStyle);
  }
  public get rotationStyle(): MediasDegRotation {
    return this._rotationStyle;
  }

  isMinWidthValid: boolean | undefined;
  isMinHeightValid: boolean | undefined;
  isMaxWidthValid: boolean | undefined;
  isMaxHeightValid: boolean | undefined;
  isMaxMediaSizeValid: boolean | undefined;
  isMinMediaSizeValid: boolean | undefined;
  isMinMediaDepthValid: boolean | undefined;
  isMaxMediaDepthValid: boolean | undefined;

  isMaxFileSizeValid: boolean | undefined;
  isMinFileSizeValid: boolean | undefined;

  isConformTodimension: boolean | undefined;
  isMaxDimension: boolean | undefined;
  isMinDimension: boolean | undefined;
  isMaxDepth: boolean | undefined;
  isMinDepth: boolean | undefined;

  thereIsAMax: boolean | undefined;
  thereIsAMin: boolean | undefined;
  thereIsMaxSize: boolean | undefined;
  thereIsMinSize: boolean | undefined;
  thereIsMinDepth: boolean | undefined;
  thereIsMaxDepth: boolean | undefined;
  thereIsAMediaFormat: boolean | undefined;

  fileSize!: number;
  // ----------

  // Media : Video
  isVideo: boolean = false;
  isYoutubeVideo: boolean = false;
  youtubeURL: string = '';
  selectedFrameURL: string = '';
  showBorderForVideo: boolean = true;
  // ----------

  // Media : File
  isDoc: boolean = false;
  docName: string | undefined;
  // ----------

  uploadedFiles: any[] = [];
  submitEventSubscription?: Subscription;

  theMediaType: any;

  // Region MediaSettings
  //documentTypeLogo: string[] = [];
  //documentTypeImage: string[] = [];
  //documentTypeVideo: string[] = [];
  //documentTypeFile: string[] = [];

  documentTypeLogo: string = "";
  documentTypeImage: string = "";
  documentTypeVideo: string = "";
  documentTypeFile: string = "";


  // documentType: any[] = [];
  documentType: string = "";

  maximumDepth: number = 0;
  minimumDepth: number = 0;

  maximumHeight: number = 0;
  minimumHeight: number = 0;

  maximumWidth: number = 0;
  minimumWidth: number = 0;

  maximumMediaSize!: number;
  minimumMediaSize!: number;
  // #endRegion

  match?: string;
  cmpTypeFormat: string = "";
  degRotation: number = 0;
  imageDepth : number = 0

  @Output() mediaValueChange = new EventEmitter<MediaValueChange>();

  private readonly defaultUploadResult: boolean = true;

  constructor(public app: AppComponent, private messageService: MessageService) { }

  ngOnDestroy(): void {
    this.unsubscirbeOnSubmitSubscription();
  }

  ngOnInit(): void {
    console.log("Hi! this is app-media-cmp ------------------------------");

    this.getDocument();

    this.getMediaSettings();

    this.initBooleans();

    this.initCheckMediasTypes();

    this.initOnSubmitSubscription();
  }

  initBooleans() {
    this.isConformTodimension = true;
    this.isMaxDimension = true;
    this.isMinDimension = true;

    this.isMaxDepth = true;
    this.isMinDepth = true;

    // this.thereIsAMax = true;
    // this.thereIsAMin = true;

    this.isEdit = false;
  }

  initCheckMediasTypes() {
    if (this.mediaType == MediasTypes.LOGO) {
      this.mediaIcon = 'pi pi-image ';
      this.fileType = "image/*";
      this.theMediaType = MediasTypes.LOGO;
    }

    if (this.mediaType == MediasTypes.IMAGE) {
      this.mediaIcon = 'pi pi-image';
      this.fileType = "image/*";
      this.theMediaType = MediasTypes.IMAGE;
    }

    if (this.mediaType == MediasTypes.FILE) {
      this.mediaIcon = 'pi pi-file-pdf';
      this.fileType = ".pdf, .docx , .doc";
      this.theMediaType = MediasTypes.FILE;
    }

    if (this.mediaType == MediasTypes.VIDEO) {
      // this.mediaIcon = 'pi pi-video';
      this.mediaIcon = 'pi pi-caret-right';
      this.fileType = "video/*";
      this.theMediaType = MediasTypes.VIDEO;
    }
  }

  getTheTooltip() {
    let infoBulle: string = "";

    infoBulle = `
      <div class="mt-0 lg:w-full">
          <span class="font-bold" >${this.app.translate.instant('fo.cmpMedia.infoPhoto')}</span>
          <span class="font-bold" *ngIf=" ${this.minimumHeight} > 0">${this.app.translate.instant('fo.cmpMedia.infoMinHeight')} : ${this.minimumHeight} px</span>
          <span class="font-bold" *ngIf=" ${this.maximumHeight} > 0">${this.app.translate.instant('fo.cmpMedia.infoMaxHeight')} : ${this.maximumHeight} px</span>
          <span class="font-bold" *ngIf=" ${this.minimumWidth} > 0">${this.app.translate.instant('fo.cmpMedia.infoMinWidth')} : ${this.minimumWidth} px</span>
          <span class="font-bold" *ngIf=" ${this.maximumWidth} > 0">${this.app.translate.instant('fo.cmpMedia.infoMaxWidth')} : ${this.maximumWidth} px</span>
          <span class="font-bold" *ngIf=" ${this.minimumDepth} > 0">${this.app.translate.instant('fo.cmpMedia.infoMinDefinition')} :${this.minimumDepth} DPI</span>
          <span class="font-bold" *ngIf=" ${this.maximumDepth} > 0">${this.app.translate.instant('fo.cmpMedia.infoMaxDefinition')} :${this.maximumDepth} DPI</span>
          <span class="font-bold" *ngIf=" ${this.minimumMediaSize} > 0">${this.app.translate.instant('fo.cmpMedia.infoPoidsMin')} : ${this.minimumMediaSize} ${MedisUnit.MB}</span>
          <span class="font-bold" *ngIf=" ${this.maximumMediaSize} > 0">${this.app.translate.instant('fo.cmpMedia.infoPoidsMax')} : ${this.maximumMediaSize} ${MedisUnit.MB}</span>
      </div>
    `;
    return infoBulle;
  }

  onToggleOverlay(event: any, op: any, targetEl: any) {
    if (!this.isConformTodimension) {
      op.toggle(event, targetEl)
    }
  }

  onYoutubeUrlEnter() {
    this.isYoutubeVideo = true;
    this.isVideo = true;
    console.log("🚀  this.isYoutubeVideo:", this.isYoutubeVideo);
    this.selectedFileURL = this.generateEmbedUrl(this.youtubeURL);
    console.log("🚀  this.selectedFileURL:", this.selectedFileURL)
    this.isContainMediaFile = true;
    this.mediaActions = "show";
    this.showBorderForVideo = false;
  }

  validExtensions: string[] = [];

  getMediaSettings() {
    return this.app.apiService.getMediaSettings().subscribe({
      next: (res) => {
        console.log("getMediaSettings :", res, this.formCode)
        res.forEach((s: any) => {
          if (s.Code == this.mediaType && this.formCode && s.Settings
            && Array.isArray(s.Settings)) {
            let mediaSettings: any = s.Settings.find((f: any) => f.FormCode == this.formCode);
            console.log("mediaSettings :", mediaSettings)
            if (mediaSettings) {
              this.cmpTypeFormat = mediaSettings.DocumentType;
              this.maximumDepth = mediaSettings.MaximumDepth;
              this.minimumDepth = mediaSettings.MinimumDepth;
              this.maximumHeight = mediaSettings.MaximumHeight;
              this.minimumHeight = mediaSettings.MinimumHeight;
              this.maximumWidth = mediaSettings.MaximumWidth;
              this.minimumWidth = mediaSettings.MinimumWidth;
              this.maximumMediaSize = mediaSettings.MaximumMediaSize;
              this.minimumMediaSize = mediaSettings.MinimumMediaSize;

              this.thereIsAMediaFormat = this.cmpTypeFormat != null && this.cmpTypeFormat != '' ? true : false;
              this.thereIsAMax = (this.maximumWidth != 0 && this.maximumWidth != null) && (this.maximumHeight != 0 && this.maximumHeight != null) ? true : false;
              this.thereIsAMin = (this.minimumWidth != 0 && this.minimumWidth != null) && (this.minimumHeight != 0 && this.minimumHeight != null) ? true : false;
              this.thereIsMaxSize = this.maximumMediaSize != 0 && this.maximumMediaSize !== null ? true : false;
              this.thereIsMinSize = this.minimumMediaSize != 0 && this.minimumMediaSize !== null ? true : false;
              this.thereIsMaxDepth = this.maximumDepth != 0 && this.maximumDepth !== null ? true : false;
              this.thereIsMinDepth = this.minimumDepth != 0 && this.minimumDepth !== null ? true : false;
              if (mediaSettings.DocumentType) {
                let mediaSettingsArray = mediaSettings.DocumentType.split(MediaHelper.mediaSettingsDocTypesSeparator);
                if (mediaSettingsArray
                  && Array.isArray(mediaSettingsArray)
                  && mediaSettingsArray.length > 0) {
                  this.validExtensions = mediaSettingsArray.filter((t: string) => t && t.trim());
                }
              }
            }
          }
        });
      },
      error: (err) => {
        console.log("🚀  err:", err);
      }
    });


  }

  getDocument() {
    console.log("----- Document : ", this.document);

    if (this.document?.DocUrl != null) {

      this.isDownload = true;
      this.isUploaded = false;

      this.selectedFileURL = this.document.DocUrl;
      this.mediaActions = 'show';
      // console.log("🚀  DocUrL:", this.selectedFileURL);
      this.fileName = this.document?.DocFilename;
      this.imageDimensions.width = this.document?.DocWidth;
      this.imageDimensions.height = this.document?.DocHeight;
      this.imageDpi = this.document?.DocDepth;

      let isYTB = this.isYtbVideo(this.selectedFileURL);

      if (isYTB == true) {
        this.isYoutubeVideo = true;
        this.selectedFileURL = this.generateEmbedUrl(this.document.DocUrl);
        this.youtubeURL = this.document.DocUrl;
        console.log("this.youtubeURL", this.youtubeURL)
      }

      if (this.document.typeDocument?.TypeDOCCode == this.mediasTypes.LOGO) {
        this.isLogo = true;
        this.isEdit = false;
      }

      if (this.document.typeDocument?.TypeDOCCode == this.mediasTypes.IMAGE) {
        this.isImage = true;
        this.creditImage = this.document?.DocCredit;
        this.isEdit = false;
      }

      if (this.document.typeDocument?.TypeDOCCode == this.mediasTypes.VIDEO) {
        this.isVideo = true;
        this.showBorderForVideo = false;
      }

      if (this.document.typeDocument?.TypeDOCCode == this.mediasTypes.FILE) {
        this.isDoc = true;
        // this.poids = `${Math.round(this.document?.DocSize * 100) / 100} Mo`;
        this.poids = `${this.document?.DocSize} ${MedisUnit.MB}`;
      }
    }
  }

  onBrowseFileClick(): void {
    console.log('onBrowseFileClick')
    this.fileInput?.choose();
  }

  onFileSelect(event: any) {
    console.log("🚀  [selectedFile:]", this.selectedFile);

    this.selectedFile = event.files[0];
    this.isContainMediaFile = true;
    this.isEdit = true;

    this.fileSize = this.byteToMB(this.selectedFile?.size);
    this.fileName = this.selectedFile?.name;
    this.mediaActions = "show"

    if (this.selectedFile) {
      const fileNameParts = this.selectedFile.name.split('.');

      if (fileNameParts.length > 1) {

        const fileExtension = fileNameParts.pop()!.toLowerCase();

        if (!this.isValidExtension(fileExtension)) {
          this.onFileClear();
          return;
        }

        switch (this.mediaType) {
          case MediasTypes.LOGO: {
            this.processIsLogo();
            break;
          }

          case MediasTypes.IMAGE: {
            this.processIsImage();
            break;
          }

          case MediasTypes.VIDEO: {
            this.processIsVideo();
            break;
          }

          case MediasTypes.FILE: {
            this.processIsFile();
            break;
          }

          default: {
            break;
          }
        }

      } else {
        this.selectedFileType = MediasTypes.UNKNOWN;
        this.isValidSize = false;
      }

      const reader = new FileReader();
      reader.onload = () => {
        this.selectedFileURL = reader.result as string;
      };
      reader.readAsDataURL(this.selectedFile);
    }
    else {
      this.emitValueChangeInfo(false);
    }
  }

  checkZeroOrNullValues(nameField: keyof this) {
    if (this.hasOwnProperty(nameField)) {
      return this[nameField] != null && this[nameField] != undefined && this[nameField] != 0
    }
    return false
  }

  checkImageValidity(imageSize: number) {
    if (this.checkZeroOrNullValues("maximumWidth") ||
      this.checkZeroOrNullValues("maximumHeight") ||
      this.checkZeroOrNullValues("minimumWidth") ||
      this.checkZeroOrNullValues("minimumHeight") ||
      this.checkZeroOrNullValues("minimumMediaSize") ||
      this.checkZeroOrNullValues("maximumMediaSize") ||
      this.checkZeroOrNullValues("minimumDepth") || 
      this.checkZeroOrNullValues("maximumDepth")) {
      this.isConformTodimension = (
        (!this.checkZeroOrNullValues("maximumWidth") || (this.checkZeroOrNullValues("maximumWidth") && this.imageDimensions.width <= this.maximumWidth))
        &&
        (!this.checkZeroOrNullValues("maximumHeight") || (this.checkZeroOrNullValues("maximumHeight") && this.imageDimensions.height <= this.maximumHeight))
        &&
        (!this.checkZeroOrNullValues("minimumWidth") || (this.checkZeroOrNullValues("minimumWidth") && this.imageDimensions.width >= this.minimumWidth))
        &&
        (!this.checkZeroOrNullValues("minimumHeight") || (this.checkZeroOrNullValues("minimumHeight") && this.imageDimensions.height >= this.minimumHeight))
        &&
        (!this.checkZeroOrNullValues("minimumMediaSize") || (this.checkZeroOrNullValues("minimumMediaSize") && imageSize > this.minimumMediaSize))
        &&
        (!this.checkZeroOrNullValues("maximumMediaSize") || (this.checkZeroOrNullValues("maximumMediaSize") && imageSize < this.maximumMediaSize))

        &&
        (!this.checkZeroOrNullValues("maximumDepth") || (this.checkZeroOrNullValues("maximumDepth") && this.imageDepth < this.maximumDepth ))

        && 
        (!this.checkZeroOrNullValues("minimumDepth") || this.checkZeroOrNullValues("minimumDepth") && this.imageDepth > this.minimumDepth)

      );

    }

    this.isMaxWidthValid = true
    if (this.checkZeroOrNullValues("maximumWidth")) {
      if (this.imageDimensions.width > this.maximumWidth) {
        this.isMaxWidthValid = false
      }
    }

    this.isMinWidthValid = true
    if (this.checkZeroOrNullValues("minimumWidth")) {
      if (this.imageDimensions.width < this.minimumWidth) {
        this.isMinWidthValid = false
      }
    }

    this.isMinHeightValid = true
    if (this.checkZeroOrNullValues("minimumHeight")) {
      if (this.imageDimensions.height < this.minimumHeight) {
        this.isMinHeightValid = false
      }
    }

    this.isMaxHeightValid = true
    if (this.checkZeroOrNullValues("maximumHeight")) {
      if (this.imageDimensions.height > this.maximumHeight) {
        this.isMaxHeightValid = false
      }
    }

    this.isMaxMediaSizeValid = true
    if (this.checkZeroOrNullValues("maximumMediaSize")) {
      if (imageSize > this.maximumMediaSize) {
        this.isMaxMediaSizeValid = false
      }
    }

    this.isMinMediaSizeValid = true
    if (this.checkZeroOrNullValues("minimumMediaSize")) {
      if (imageSize < this.minimumMediaSize) {
        this.isMinMediaSizeValid = false
      }
    }

    this.isMediaValidSize = this.isMinMediaSizeValid && this.isMaxMediaSizeValid

    this.isMaxMediaDepthValid = true
    if (this.checkZeroOrNullValues("maximumDepth")) {
      if (this.imageDepth > this.maximumDepth) {
        this.isMaxMediaDepthValid = false
      }
    }

    this.isMinMediaDepthValid = true
    if (this.checkZeroOrNullValues("minimumDepth")) {
      if (this.imageDepth < this.minimumDepth) {
        this.isMinMediaDepthValid = false
      }
    }

  }

  getImageDepth(img: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
  
    const context = canvas.getContext('2d');
    if (context) {
      context.drawImage(img, 0, 0, img.width, img.height);
      const imageData = context.getImageData(0, 0, img.width, img.height);
      this.imageDepth = this.analyzeImageDepth(imageData);
      console.log('Image Depth:', this.imageDepth);
    } else {
      console.error('Could not get canvas context');
    }
  }

  analyzeImageDepth(imageData: ImageData): number {
    const data = imageData.data;
    let depth = 0;
    if (data.length / (imageData.width * imageData.height) === 4) {
      depth = 32;
    } else if (data.length / (imageData.width * imageData.height) === 3) {
      depth = 24;
    }
  
    return depth;
  }

  getImageDimensions(file: File) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = () => {
        this.imageDimensions.width = img.width;
        this.imageDimensions.height = img.height;
        let imageSize = this.byteToMB(this.selectedFile.size)

        this.getImageDepth(img)

        this.checkImageValidity(imageSize)
        this.emitValueChangeInfo(true);
      };
    };

    reader.readAsDataURL(file);
    console.log('med ', this.mediaType, this.isConformTodimension
      , this.imageDimensions.width
      , this.maximumWidth)

    setTimeout(() => {

      console.log('med ', this.mediaType, this.isConformTodimension
        , this.imageDimensions.width
        , this.maximumWidth)
    }, 2000);
  }



  onFileClear() {
    console.log("🚀  clear image data");
    this.fileInput?.clear();
    this.fileName = "";
    this.imageDimensions.width = 0;
    this.imageDimensions.height = 0;
    this.selectedFileURL = null;
    this.rotate = 0;
    this.rotationStyle = MediasDegRotation._0deg;
    this.isConformTodimension = true;
    this.isMaxDimension = true;
    this.isMinDimension = true;
    this.selectedFile = "";
    this.youtubeURL = "";
    this.isYoutubeVideo = false;
    this.isVideo = false;
    this.isImage = false;
    this.isDoc = false;
    this.isContainMediaFile = false;
    this.mediaActions = "hide";
    this.creditImage = "";
    this.isValidSize = true;
    this.poids = "";
    this.isUploaded = false;
    this.isDownload = false;
    this.errorVideo = "";
    this.isEdit = false;
    this.isMediaValidSize = true;
    this.showBorderForVideo = true;
    this.degRotation = 0
    if (this.synchronizeMediaInApi) {
      this.deleteDocument();
    }
    this.document = null;

    this.emitValueChangeInfo(false);
  }


  onRotateRight() {
    this.rotate = (this.rotate + 3) % 4;
    this.switchRotate(this.rotate, -1);
    // this.isEdit = false;
    this.isDownload = false;
    this.isUploaded = true;
    console.log("🚀  Right : ", this.rotate);
    //this.uploadMedia();
  }

  onRotateLeft() {
    this.rotate = (this.rotate + 1) % 4;
    this.switchRotate(this.rotate, 1);
    // this.isEdit = false;
    this.isDownload = false;
    this.isUploaded = true;
    console.log("🚀  Left : ", this.rotate);
  }

  async switchRotate(count: number, sens: number) {
    switch (count) {
      case 0:
        this.rotationStyle = MediasDegRotation._0deg;
        break;
      case 1:
        this.rotationStyle = MediasDegRotation._270deg;
        break;
      case 2:
        this.rotationStyle = MediasDegRotation._180deg;
        break;
      case 3:
        this.rotationStyle = MediasDegRotation._90deg;
        break;
      default:
        break;
    }
    this.degRotation = sens;
    if (this.synchronizeMediaInApi == true) {
      await this.uploadMedia(sens);
    }
    else {
      this.degRotation = this.convertRotationToDegrees(this.rotationStyle)
      this.degRotation = this.convertDegreesToNumber()
    }
    this.isEdit = false;
  }

  convertDegreesToNumber(): number {
    switch (this.degRotation) {
      case 0:
        return 0

      case -270:
      case 90:
        return 1

      case 180:
      case -180:
        return -1

      case 270:
      case -90:
        return 2

      default:
        return 0
    }
  }

  convertRotationToDegrees(rotation: MediasDegRotation): number {
    switch (rotation) {
      case MediasDegRotation._0deg:
        return 0;
      case MediasDegRotation._270deg:
        return 270;
      case MediasDegRotation._180deg:
        return 180;
      case MediasDegRotation._90deg:
        return 90;
      default:
        return 0;
    }
  }

  // UPLOAD MEDIA ==================================================
  uploadMedia(sens: number) {
    console.log(this.documentType, this.isDoc, this.mediaType)
    let result !: any;

    // ---------- LOGO ----------
    if (this.isLogo && this.mediaType == this.mediasTypes.LOGO) {
      console.log("🚀  isLogo:", this.isLogo);
      console.log("🚀 SelectedFile : ", this.selectedFile);

      let mediaObj = {
        SolutionID: this.SolutionID,
        DocID: this.document?.DocID,
        Rotation: sens,
        ViewOrder: this.mediaIndex,
        OnSelect: this.isEdit,
        ComponentID: this.componentID
      };

      if (this.isEdit == true && this.selectedFile != null) {
        result = this.app.apiService.uploadLogo(mediaObj, this.selectedFile.name, this.selectedFile).subscribe({
          next: (res) => {
            if (res && res.DocID) {
              this.document = res;
            }
            this.uploadResult.emit(res ? (res.Flag_ValiditySuccess ?? this.defaultUploadResult) : false);
            console.log("🚀  res:", res);
            if (res.Flag_widthError || res.Flag_heighError || res.Flag_SizeError || res.Flag_DpiError) {
              this.isUploaded = false;
              console.log("🚀  this.isUploaded = false:", this.isUploaded);
            } else {
              this.isUploaded = true;
              console.log("🚀  this.isUploaded = true:", this.isUploaded)
            }
          },
          error: (err) => {
            this.uploadResult.emit(false);
            this.isUploaded = false;
            console.log("🚀  err:", err);
          }
        });
      } else {
        result = this.app.apiService.uploadLogo(mediaObj, "", new Blob()).subscribe({
          next: (res) => {
            if (res && res.DocID) {
              this.document = res;
            }
            this.uploadResult.emit(res ? (res.Flag_ValiditySuccess ?? this.defaultUploadResult) : false);
            console.log("🚀  res:", res);
            this.isUploaded = true;
            console.log("🚀  this.isUploaded = false:", this.isUploaded);
          },
          error: (err) => {
            this.uploadResult.emit(false);
            this.isUploaded = false;
            console.log("🚀  err:", err);
          }
        });
      }
      console.log("🚀  Logo isEdit : ", this.isEdit);
    }

    // ---------- IMAGE ----------
    else if (this.isImage && this.mediaType == this.mediasTypes.IMAGE) {
      console.log("🚀  isImage:", this.isImage);
      console.log("🚀 SelectedFile : ", this.selectedFile);

      let mediaObj = {
        SolutionID: this.SolutionID,
        DocID: this.document?.DocID,
        Rotation: sens,
        ViewOrder: this.mediaIndex,
        OnSelect: this.isEdit,
        ComponentID: this.componentID,
        CreditImage: "",
      };
      if (this.creditImage) {
        mediaObj = {
          ...mediaObj,
          CreditImage: this.creditImage,
        }
      }
      console.log("🚀  mediaObj:", mediaObj);

      let name: string;

      if (this.document != null) {
        name = this.document?.DocName;
        console.log("🚀  DocName:", name);
      } else {
        name = this.selectedFile.name;
      }

      if (this.isEdit) {
        result = this.app.apiService.uploadImage(mediaObj, name, this.selectedFile).subscribe({
          next: (res) => {
            if (res && res.DocID) {
              this.document = res;
            }
            this.uploadResult.emit(res ? (res.Flag_ValiditySuccess ?? this.defaultUploadResult) : false);
            console.log("🚀  res:", res);
            if (res.Flag_widthError || res.Flag_heighError || res.Flag_SizeError || res.Flag_DpiError) {
              this.isUploaded = false;
              console.log("🚀  this.isUploaded = false:", this.isUploaded);
            } else {
              this.isUploaded = true;
              console.log("🚀  this.isUploaded = true:", this.isUploaded)
            }
          },
          error: (err) => {
            this.uploadResult.emit(false);
            this.isUploaded = false;
            console.log("🚀  err:", err);
          }
        });
      } else {
        result = this.app.apiService.uploadImage(mediaObj, "", new Blob()).subscribe({
          next: (res) => {
            if (res && res.DocID) {
              this.document = res;
            }
            this.uploadResult.emit(res ? (res.Flag_ValiditySuccess ?? this.defaultUploadResult) : false);
            console.log("🚀  res:", res);
            this.isUploaded = true;
            console.log("🚀  this.isUploaded = false:", this.isUploaded);
          },
          error: (err) => {
            this.uploadResult.emit(false);
            this.isUploaded = false;
            console.log("🚀  err:", err);
          }
        });
      }
      console.log("🚀  Image isEdit : ", this.isEdit);
    }

    // ---------- VIDEO ----------
    else if (this.isVideo && this.mediaType == this.mediasTypes.VIDEO) {
      console.log("🚀  isVideo:", this.isVideo);

      if (this.isYoutubeVideo) {

        let ytbVideo = {
          SolutionID: this.SolutionID,
          DocID: this.document?.DocID,
          ExternalURLVideo: this.youtubeURL,
          ViewOrder: this.mediaIndex,
          ComponentID: this.componentID
        };

        console.log("🚀  res:");
        result = this.app.apiService.uploadExternalVideoURL(ytbVideo).subscribe({
          next: (res) => {
            if (res && res.DocID) {
              this.document = res;
            }
            this.uploadResult.emit(res ? (res.Flag_ValiditySuccess ?? this.defaultUploadResult) : false);
            console.log("🚀  res:", res);
            this.isUploaded = true;
            this.showBorderForVideo = false;
          },
          error: (err) => {
            this.uploadResult.emit(false);
            this.isUploaded = false;
            console.log("🚀  err:", err);
          }
        });
      }

      else if (!this.isYoutubeVideo && this.selectedFile) {
        let mediaObj = {
          SolutionID: this.SolutionID,
          DocID: this.document?.DocID,
          ViewOrder: this.mediaIndex,
          ComponentID: this.componentID
        };

        console.log("🚀  res:");
        console.log(this.selectedFile)
        result = this.app.apiService.uploadVideo(mediaObj, this.selectedFile.name, this.selectedFile).subscribe({
          next: (res) => {
            if (res && res.DocID) {
              this.document = res;
            }
            this.uploadResult.emit(res ? (res.Flag_ValiditySuccess ?? this.defaultUploadResult) : false);
            console.log("🚀  res:", res);
            this.isUploaded = true;
          },
          error: (err) => {
            this.uploadResult.emit(false);
            console.log("🚀  err:", err);
          }
        });

      }
      else {
        this.uploadResult.emit(this.defaultUploadResult);
        console.log("🚀  res:");
      }
    }

    // ---------- DOCUMENT/FILE/PDF/DOC ----------
    else if (this.isDoc && this.mediaType == this.mediasTypes.FILE) {
      if (this.selectedFile) {
        console.log("🚀 resp isDoc--------->:", this.isDoc);

        let mediaObj = {
          SolutionID: this.SolutionID,
          DocID: this.document?.DocID,
          ViewOrder: this.mediaIndex,
          ComponentID: this.componentID
        };
        console.log("🚀 resp mediaObj--------->:", mediaObj, this.selectedFile);

        result = this.app.apiService.uploadFile(mediaObj, this.selectedFile.name, this.selectedFile).subscribe({
          next: (res) => {
            if (res && res.DocID) {
              this.document = res;
            }
            console.log("🚀  res:", res);
            this.uploadResult.emit(res ? (res.Flag_ValiditySuccess ?? this.defaultUploadResult) : false);
            this.isUploaded = true;
          },
          error: (err) => {
            console.log("🚀  err:", err);
            this.uploadResult.emit(false);
            this.isUploaded = false;
          }
        });
      }
      else {
        this.uploadResult.emit(this.defaultUploadResult);
      }
    }

    else {
      result = null;
      this.uploadResult.emit(this.defaultUploadResult)
    }

    console.log(">>>>>> DocID : ", this.document?.DocID);

    return result;
  }

  // DOWNLOAD MEDIA ==================================================
  downloadMedia() {
    console.log("🚀  this.document?.DocID:", this.document?.DocID)

    return this.app.apiService.downloadImageMedia(this.document?.DocID, this.documentUrl).subscribe({
      next: (res) => {
        let imageData = "data:image/*;base64," + res;
        var link = document.createElement("a")
        link.setAttribute("href", imageData);
        let fileName = null
        if (this.documentUrl != undefined && this.documentUrl != null) {
          fileName = this.documentUrl.substring(this.documentUrl.lastIndexOf('/') + 1);
        } else {
          fileName = this.document.DocName
        }
        link.setAttribute("download", fileName);
        link.click();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getImgElementRef() {
    // const imgSrc = this.imgElementRef?.nativeElement.getAttribute('src');
    const imgSrc = this.imgElementRef?.nativeElement.src;
    console.log("🚀  Image Source |-----> ", imgSrc);

    this.convertImageToBase64(imgSrc);

  }

  downloadFileFromUrl(url: string) {
    this.app.apiService.exportFile(url)
      .subscribe({
        next: (value: any) => {
          this.downloadFile(value)
        },
        error: (err: any) => {

        }
      });
  }

  downloadFile(data: any) {
    const blob = new Blob([data], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  deleteDocument() {
    let mediaObj = {
      SolutionID: this.SolutionID,
      DocID: this.document?.DocID,
      ComponentID: this.componentID
    };
    // console.log("🚀 delete mediaObj:", mediaObj)

    if (this.document != null)
      this.app.apiService.deleteMedia(mediaObj).subscribe({
        next: (res) => {
          console.log("🚀  delete res:", res);
        },
        error: (err) => {
          console.log("🚀  delete err:", err);
        }
      });
  }

  // PRIVATE FN ==================================================
  private downloadImage(url: string) {
    fetch(url, {
      mode: 'no-cors',
    })
      .then(resp => resp.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = url.replace(/^.*[\\\/]/, '');
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
  }

  private downloadDocFile(url: any) {
    const link = document.createElement('a');
    link.href = url;
    const fNameMatch = url.match(/\/([^\/?#]+)[^\/]*$/);
    const fName = fNameMatch && fNameMatch[1];
    console.log("🚀  fName:", fName);
    link.download = fName;
    link.click();
    link.remove();
  }


  private generateEmbedUrl(youtubeUrl: any) {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/watch\?v=([a-zA-Z0-9_-]+)/;
    const regex2 = /^(https?:\/\/)?(www\.)?(youtu\.be)\/([a-zA-Z0-9_-]+)/;

    if (youtubeUrl.search('watch') >= 0) {
      this.match = youtubeUrl.match(regex);
      console.log("🚀  youtubeUrl.search('watch'):", youtubeUrl.search('watch'))
    } else {
      this.match = youtubeUrl.match(regex2);
    }

    if (this.match) {
      const videoId = this.match[4];

      const embedUrl = `https://www.youtube.com/embed/${videoId}`;

      return embedUrl;
    } else {
      return null;
    }
  }

  private isYtbVideo(ytbURL: any): boolean {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/watch\?v=([a-zA-Z0-9_-]+)/;
    const regex2 = /^(https?:\/\/)?(www\.)?(youtu\.be)\/([a-zA-Z0-9_-]+)/;

    var match;

    if (ytbURL.search('watch') >= 0) {
      match = ytbURL.match(regex);
    } else {
      match = ytbURL.match(regex2);
    }

    if (match) {
      return true
    } else {
      return false;
    }

  }

  private byteToMB(bytes: number): number {
    return (bytes / (1024 * 1024));
  }

  private showError(size: any) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: `${this.app.translate.instant('fo.cmpMedia.maxMediasSize')} ${size} ${MedisUnit.MB} !`
    });

    this.selectedFileURL = null;
    this.isValidSize = false;
    this.selectedFile = null;
    this.mediaActions = "hide";
    this.mediaActionsVideo = "show";
    this.fileInput?.clear();
  }

  private extractFileFromDoc(): void {

    this.getDocument();
    // if (this.document) {

    //   // const blob = new Blob([data], { type: 'text/csv' });
    //   this.selectedFile = this.document?.DocUrl;
    // }
    let fileInput: any = document.getElementById('fileInput');
    console.log('fileInput ', fileInput)
    // setTimeout(() => {
    //   console.log('è_files ', this.fileInput)
    //   this.selectedFile = this.fileInput?._files[0]
    // }, 5000);

    if (this.document) {
      this.emitValueChangeInfo(true, false);
    }
    else {
      this.emitValueChangeInfo(false, false);
    }
  }

  private convertImageToBase64(imgSrc: any) {
    if (imgSrc) {
      this.fetchImageAndConvert(imgSrc)
        .then(base64Data => {
          this.imageBase64 = base64Data;
        })
        .catch(error => {
          console.error('Error converting image:', error);
        });
    } else {
      console.error('Image source is empty.');
    }
  }

  private async fetchImageAndConvert(url: string): Promise<string> {
    try {
      const resp = await fetch(url, {
        mode: 'no-cors',
      });
      if (!resp.ok) {
        throw new Error(`Failed to fetch :${resp.status} ${resp.statusText}`);
      }

      const imgBlob = await resp.blob();
      const reader = new FileReader();

      return new Promise<string>((resolve) => {
        reader.onloadend = () => {
          const base64Data = reader.result as string;
          resolve(base64Data);
        };
        reader.readAsDataURL(imgBlob);
      });
    } catch (error) {
      throw error;
    }
  }

  private initOnSubmitSubscription(): void {
    if (this.submitEvent) {
      this.submitEventSubscription = this.submitEvent.subscribe(() => { this.uploadMedia(0); })
    }
  }

  private unsubscirbeOnSubmitSubscription(): void {
    if (this.submitEventSubscription) {
      this.submitEventSubscription.unsubscribe();
    }
  }

  // region Extensions ==================================================
  //#region old extensions methods
  // private isLogoExtension(extension: string): boolean {
  //   const logoExtensions = ["jpg", "jpeg", "png", "bmp"];
  //   return logoExtensions.includes(extension);
  // }

  // private isImageExtension(extension: string): boolean {
  //   const imageExtensions =  ["jpg", "jpeg", "png", "bmp"];
  //   return imageExtensions.includes(extension);
  // }

  // private isVideoExtension(extension: string): boolean {
  //   const videoExtensions = ['mp4', 'avi', 'mov', 'mkv', 'wmv'];
  //   return videoExtensions.includes(extension);
  // }

  // private isFileExtension(extension: string): boolean {
  //   const fileExtensions = ['pdf', 'doc', 'docx'];
  //   return fileExtensions.includes(extension);
  // }
  //#endregion old extensions methods

  private isValidExtension(extension: string): boolean {
    console.log('ex ', extension, this.validExtensions, this.validExtensions.includes(extension));
    return this.validExtensions.includes(extension);
  }
  // endRegion

  emitValueChangeInfo(haveMedia: boolean, isFileSelected: boolean = true): void {
    console.log('her ', this.mediaType, this.isConformTodimension, this.isValidSize)
    let mediaValueChange: MediaValueChange = {
      Type: this.mediaType,
      ViewOrder: this.mediaIndex,
      HaveValue: haveMedia,
      IsFileSelected: isFileSelected,
      IsValid: (this.isConformTodimension ?? true) && this.isValidSize
    }

    this.mediaValueChange.emit(mediaValueChange);
  }

  //#region  processMediaTypes

  // Check LogoExtension ==================================================>
  private processIsLogo(): void {
    this.selectedFileType = MediasTypes.LOGO;
    this.isLogo = true;
    this.getImageDimensions(this.selectedFile);
  }

  // Check ImageExtension ==================================================>
  private processIsImage(): void {
    this.selectedFileType = MediasTypes.IMAGE;
    this.isImage = true;
    this.getImageDimensions(this.selectedFile);
  }

  // Check VideoExtension ==================================================>
  private processIsVideo(): void {
    this.isVideo = true;
    this.selectedFileType = MediasTypes.VIDEO;
    this.showBorderForVideo = false;

    if (this.fileSize >= this.minimumMediaSize && this.fileSize <= this.maximumMediaSize) {
      this.isValidSize = true;
    } else {
      this.isValidSize = false;
      this.selectedFileURL = null;
      this.errorVideo = `${this.app.translate.instant('fo.cmpMedia.maxMediasSize')} ${this.maximumMediaSize} ${MedisUnit.MB}!`;
    }
    this.emitValueChangeInfo(true);
  }

  // Check FileExtension ==================================================>
  private processIsFile(): void {
    this.selectedFileType = MediasTypes.FILE;
    this.isDoc = true;
    this.docName = this.selectedFile.name;
    let size = this.fileSize.toFixed(2);

    this.checkFileValidity(Number(size))

    if (this.thereIsMaxSize || this.thereIsMinSize) {
      if (this.fileSize >= this.minimumMediaSize && this.fileSize <= this.maximumMediaSize) {
        this.isValidSize = true;
        this.poids = `${this.app.translate.instant('fo.cmpMedia.poids')} : ${size} ${MedisUnit.MB}`;
      } else {
        this.isValidSize = false;
        this.selectedFileURL = null;
        this.poids = `${this.app.translate.instant('fo.cmpMedia.poids')} : ${size} ${MedisUnit.MB}`;
      }
      this.isConformTodimension = this.isValidSize
    } else {
      this.isValidSize = false;
    }
    this.emitValueChangeInfo(true);
  }

  checkFileValidity(fileSize : number){
    this.isMaxFileSizeValid = true
    console.log(fileSize)
    console.log(this.maximumMediaSize)
    if (this.checkZeroOrNullValues("maximumMediaSize")) {
      if (fileSize > this.maximumMediaSize) {
        this.isMaxFileSizeValid = false
      }
    }

    this.isMinFileSizeValid = true
    if (this.checkZeroOrNullValues("minimumMediaSize")) {
      if (fileSize < this.minimumMediaSize) {
        this.isMinFileSizeValid = false
      }
    }

    console.log(this.isMinFileSizeValid)
    console.log(this.isMaxFileSizeValid)
    this.isConformTodimension = this.isMinFileSizeValid && this.isMaxFileSizeValid
  }
  //#endregion
}
