import { DateHelper } from "../helpers/date.helper";


export class ProductHelped {
    ID?: number | null = null;
    ProductGroupID?: number | null = null;
    NameEN?: string | null = "";
    NameFR?: string | null = "";
    DescriptionEN?: string | null = "";
    DescriptionFR?: string | null = "";
    SolUrl?: string | null = "";
    PhotoPath?: string | null = "";
    PublishedDate?: string | null = "";
    IsMultiWriter: boolean = false;
    Normes: string | null = "";
    Categories?: any[] | null;
    Documents?: any[] | null;
    Authorizepublishnormes: boolean = false;
    hlpIsPublicationDelayed : boolean = false 
    IsPublicationDifferentDate : boolean = false 
    PublicationWishDate ?: any = undefined;

    public getListNotRequiredsInStep1(): boolean {
        const notRequiredValidators: any = ['Categories', 'ID', 'ProductGroupID', 'DescriptionEN', ,'ID', 'SolUrl', 'Normes', 'DescriptionFR', 'PhotoPath', 'PublishedDate', 'IsMultiWriter', 'Authorizepublishnormes', 'hlpIsPublicationDelayed', 'PublicationWishDate'];
        return notRequiredValidators;
    }

    public getListNotRequiredsInStep2(): boolean {
        const notRequiredValidators: any = ['Categories','ProductGroupID', 'NameEN', 'NameFR', 'SolUrl', 'PhotoPath', 'PublishedDate', 'IsMultiWriter',  'Normes', 'Authorizepublishnormes', 'hlpIsPublicationDelayed', 'PublicationWishDate'];
        return notRequiredValidators;
    }

    mapFormGetResponse(product: any): void{
        this.ID = product.ID;
        this.ProductGroupID = product.ProductGroupID;
        this.NameEN = product.NameEN;
        this.NameFR = product.NameFR;
        this.DescriptionEN = product.DescriptionEN;
        this.DescriptionFR = product.DescriptionFR;
        this.SolUrl = product.SolUrl;
        this.PhotoPath = product.PhotoPath;
        this.PublishedDate = product.PublishedDate;
        this.IsMultiWriter = product.IsMultiWriter;
        this.Normes = product.Normes;
        this.Categories = product.Categories;
        this.Authorizepublishnormes = product.Authorizepublishnormes;
        this.hlpIsPublicationDelayed = product.HlpIsPublicationDelayed;
        this.PublicationWishDate =  product.PublicationWishDate != undefined ? new Date(product.PublicationWishDate) : undefined
    }

    mapFormStep1Form(product: any): void{
        this.NameEN = product.NameEN;
        this.NameFR = product.NameFR;
        this.SolUrl = product.SolUrl;
        this.IsMultiWriter = product.IsMultiWriter;
        this.ID = product.ID;
        this.hlpIsPublicationDelayed = product.HlpIsPublicationDelayed;
        this.PublicationWishDate = product.PublicationWishDate;
    }

    mapFormStep2Form(product: any): void{
        this.DescriptionFR = product.DescriptionFR;
        this.DescriptionEN = product.DescriptionEN;
        this.Normes = product.Normes;
        this.Authorizepublishnormes = product.Authorizepublishnormes;
        this.Categories = product.Categories;
        
    }

}