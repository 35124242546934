import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SharedtoolsService } from '../services/sharedtools.service';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../enums/language';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./navbar.component.less']
})
export class NavbarComponent implements OnInit, OnDestroy {
  private _onLangChangeSub?: Subscription;

  @Input() title: string = "Titre";

  @Output() onLangChange: EventEmitter<void> = new EventEmitter<void>();

  constructor(public sharedtoolsService: SharedtoolsService,
    public translate: TranslateService
  ) {
    
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.destroySubscriptions();
  }

  closeTab() {
    // console.log("🚀>>  U Want to close me !");
    window.close();
  }

  public switchLanguage(): void{
    if(!this.translate.currentLang){
      this.sharedtoolsService.switchLanguage(Languages.Default);
    }

    if(this.translate.currentLang.toLowerCase() == Languages.English.toLowerCase()){
      this.sharedtoolsService.switchLanguage(Languages.French);
    }
    else {
      this.sharedtoolsService.switchLanguage(Languages.English);
    }
  }

  getLangFlag(): string{
    if(!this.translate.currentLang){
      return '';
    }

    if (this.translate.currentLang.toLowerCase() == Languages.English.toLowerCase()){
      return '/assets/images/icons/gb_round_flag.png';
    }
    else if(this.translate.currentLang.toLowerCase() == Languages.French.toLowerCase()){
      return '/assets/images/icons/fr_round_flag.png';
    }

    return '';
  }

  private initSubscriptions(): void{
    this._onLangChangeSub = this.translate.onLangChange.subscribe((value: any) =>{
      this.onLangChange.emit();
    })
  }

  private destroySubscriptions(): void{
    this._onLangChangeSub?.unsubscribe();
  }
}
