import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { finalize, delay, tap } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
// import { ConfirmDialogService } from './confirm-dialog.service';
import { SpinnerService } from './../services/spinner.service';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
    count = 0;
    user: any;

    constructor(
        public router: Router,
        private spinner: NgxSpinnerService,
        private spinnerService: SpinnerService,
        // public dialog: ConfirmDialogService,
    ) { }

    // ==>  Interceptor pour session expirée
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //this.spinner.show();
        this.spinnerService.showSpinner();

        this.count++;
        return next.handle(req)
            .pipe(
                tap({
                    next: (event: any) => {
                        // Logic to be executed on next
                        //console.log("event =>", event);
                    },
                    error: (error: any) => {
                        // Logic to be executed on error
                        if (error.status !== 401) {
                            if (error.status == 404) {
                                this.router.navigate(['/']);
                                // this.dialog.confirmThis("", "Une erreur est survenue.", "alert", "", () => {
                                //   this.router.navigate(['/']);
                                // }, () => { });
                            }
                            return;
                        }
                        //==> If session expirée and user is in sessionstorage
                        this.user = JSON.parse(sessionStorage.getItem('userData')!);
                        console.log("user !!!!!!", this.user)
                        if (this.user) {
                            // this.dialog.confirmThis("", "Votre session a expiré. Merci de vous re-connecter.", "alert", "", () => { sessionStorage.clear(); this.router.navigate(['/login']); }, () => { });
                            sessionStorage.clear();
                            localStorage.clear();
                            this.router.navigate(['/login']);
                        }
                    },
                    complete: () => {
                    }
                }),
                finalize(() => {
                    this.count--;
                    if (this.count == 0) this.spinnerService.hideSpinner(); //this.spinner.hide();
                })
            )
    }
}

