const ConfEventsStorageKey = 'conf-event-ids';
const ConfSpeakersStorageKey = 'speaker-ids';
const ConfCurrentDateStorageKey = 'conf-event-date';

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DatePipe } from '@angular/common';
import { DateHelper } from '../helpers/date.helper';

@Injectable({
  providedIn: 'root'
})
export class ConferenceStoreService {
  //#region cotr
  constructor(private datePipe: DatePipe){};
  //#endregion ctor

  /************************************ */
  /**** Conference Events Utils ****/
  /************************************ */
  //#region confEventsIds properties
  private _confEventsIds: number[] = [];
  get confEventsIds(): number[] {
    if (this._confEventsIds.length) {
      return this._confEventsIds;
    }

    let confEventsIdsStr = sessionStorage.getItem(ConfEventsStorageKey);
    if (!confEventsIdsStr) {
      return this._confEventsIds;
    }

    let confEventsIdsList = JSON.parse(confEventsIdsStr);
    if (!(confEventsIdsList && Array.isArray(confEventsIdsList))) {
      return this._confEventsIds;
    }

    this._confEventsIds = confEventsIdsList;

    return this._confEventsIds;
  }

  set confEventsIds(value: number[] | any) {
    if (value && Array.isArray(value)) {
      this._confEventsIds = value;

      sessionStorage.setItem(ConfEventsStorageKey, JSON.stringify(this._confEventsIds));
    }
  }

  private _currentConferenceDate: string | null = null;

  public set currentConferenceDate(value: any){
    this._currentConferenceDate = this.datePipe.transform(value, DateHelper.ApiDateTimeFormat);
    
    if(this._currentConferenceDate){
      sessionStorage.setItem(ConfCurrentDateStorageKey, this._currentConferenceDate);
    }
  }

  public get currentConferenceDate(): string | null {
    if(!this._currentConferenceDate){
      this._currentConferenceDate = sessionStorage.getItem(ConfCurrentDateStorageKey);
    }
    
    return this._currentConferenceDate;
  }
  //#endregion confEventsIds properties

  //#region confEventsIds functions
  public isFirstConfEvent(currentConfEventId: number): boolean {
    let currentConfEventIndex: number = this.confEventsIds.findIndex(e => e == currentConfEventId);
    console.log(currentConfEventId, currentConfEventIndex)
    if (currentConfEventIndex < 0) {
      return false;
    }

    return currentConfEventIndex == 0;
  }

  public isLastConfEvent(currentConfEventId: number): boolean {
    let currentConfEventIndex: number = this.confEventsIds.findIndex(e => e == currentConfEventId);
    if (currentConfEventIndex < 0) {
      return false;
    }

    return currentConfEventIndex == this.confEventsIds.length - 1;
  }

  public nextConfEvent(currentConfEventId: number): number {
    let currentConfEventIndex: number = this.confEventsIds.findIndex(e => e == currentConfEventId);
    if (currentConfEventIndex < 0) {
      return currentConfEventId;
    }

    return currentConfEventIndex == (this.confEventsIds.length - 1)
      ? currentConfEventIndex
      : this.confEventsIds[currentConfEventIndex + 1];
  }

  public previousConfEvent(currentConfEventId: number): number {
    let currentConfEventIndex: number = this.confEventsIds.findIndex(e => e == currentConfEventId);
    if (currentConfEventIndex < 0) {
      return currentConfEventId;
    }

    return currentConfEventIndex == 0
      ? currentConfEventIndex
      : this.confEventsIds[currentConfEventIndex - 1];
  }
  //#endregion confEventsIds functions
  

  /************************************ */
  /****** Conference Speakers Utils ******/
  /************************************ */
  //#region confSpeakersIds properties
  private _confSpeakersIds: number[] = [];
  get confSpeakersIds(): number[] {
    if (this._confSpeakersIds.length) {
      return this._confSpeakersIds;
    }

    let confSpeakersIdsStr = sessionStorage.getItem(ConfSpeakersStorageKey);
    if (!confSpeakersIdsStr) {
      return this._confSpeakersIds;
    }

    let confSpeakersIdsList = JSON.parse(confSpeakersIdsStr);
    if (!(confSpeakersIdsList && Array.isArray(confSpeakersIdsList))) {
      return this._confSpeakersIds;
    }

    this._confSpeakersIds = confSpeakersIdsList;

    return this._confSpeakersIds;
  }

  set confSpeakersIds(value: number[] | any) {
    if (value && Array.isArray(value)) {
      this._confSpeakersIds = value;

      sessionStorage.setItem(ConfSpeakersStorageKey, JSON.stringify(this._confSpeakersIds));
    }
  }
  //#endregion confSpeakersIds properties

  //#region confSpeakersIds functions
  public isFirstConfSpeaker(currentConfSpeakerId: number): boolean {
    let currentConfSpeakerIndex: number = this.confSpeakersIds.findIndex(e => e == currentConfSpeakerId);
    console.log(currentConfSpeakerId, currentConfSpeakerIndex)
    if (currentConfSpeakerIndex < 0) {
      return false;
    }

    return currentConfSpeakerIndex == 0;
  }

  public isLastConfSpeaker(currentConfSpeakerId: number): boolean {
    let currentConfSpeakerIndex: number = this.confSpeakersIds.findIndex(e => e == currentConfSpeakerId);
    if (currentConfSpeakerIndex < 0) {
      return false;
    }

    return currentConfSpeakerIndex == this.confSpeakersIds.length - 1;
  }

  public nextConfSpeaker(currentConfSpeakerId: number): number {
    let currentConfSpeakerIndex: number = this.confSpeakersIds.findIndex(e => e == currentConfSpeakerId);
    if (currentConfSpeakerIndex < 0) {
      return currentConfSpeakerId;
    }

    return currentConfSpeakerIndex == (this.confSpeakersIds.length - 1)
      ? currentConfSpeakerIndex
      : this.confSpeakersIds[currentConfSpeakerIndex + 1];
  }

  public previousConfSpeaker(currentConfSpeakerId: number): number {
    let currentConfSpeakerIndex: number = this.confSpeakersIds.findIndex(e => e == currentConfSpeakerId);
    if (currentConfSpeakerIndex < 0) {
      return currentConfSpeakerId;
    }

    return currentConfSpeakerIndex == 0
      ? currentConfSpeakerIndex
      : this.confSpeakersIds[currentConfSpeakerIndex - 1];
  }
  //#endregion confSpeakersIds functions
}
