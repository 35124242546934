import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppComponent } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { ApiService } from '../services/api.service';
import { callback } from 'chart.js/dist/helpers/helpers.core';

@Component({
  selector: 'app-preview-social-media-post',
  templateUrl: './preview-social-media-post.component.html',
  styleUrls: ['./preview-social-media-post.component.less']
})
export class PreviewSocialMediaPostComponent implements OnInit {

  constructor(private configDialog: DynamicDialogConfig,
    public translate: TranslateService,
    private apiService: ApiService) { }

  isLoading: boolean = false;

  text: string | undefined;
  urlImg: string | undefined;
  url: string | undefined;
  isForTwitter: boolean = false;

  oauthPair: any;


  visibility: any[] = [
    { name: this.translate.instant('racine.socialMedia.vis_public'), code: 'PUBLIC' },
    { name: this.translate.instant('racine.socialMedia.vis_connections'), code: "CONNECTIONS" },
  ];
  selectedVisibility: any | undefined;

  ngOnInit(): void {
    this.urlImg = this.configDialog.data.urlImg;
    this.text = this.configDialog.data.text;
    this.isForTwitter = this.configDialog.data.isForTwitter;
    console.log(this.isForTwitter)
  }

  shareToLinkedIn() {
    console.log(this.url);
    if (this.isForTwitter == false) {
      localStorage.setItem("visibility", this.selectedVisibility.code);
    }
    this.url = this.configDialog.data.url;
    window.location.href = this.url!;
  }

  shareToTwitter() {
    
    this.apiService.getTwitterCredentials(this.configDialog.data.callBackUrl).subscribe({
      next: (response: any) => {
        this.oauthPair = response
        localStorage.setItem("oauthToken", this.oauthPair.m_Item1)
        localStorage.setItem("oauthTokenSecret", this.oauthPair.m_Item2)
        console.log("response ====> ", response)
        window.location.href = environment.twitterConfig.url(response.m_Item1, response.m_Item2);
      },
      error: (error:any) => {
        console.error('Error getting access token:', error);
      }
    });
  }

}
