import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { cloneDeep } from 'lodash';
import { VMFolderSearchLocalStorageKey, VMUserSearchLocalStorageKey } from '../consts/utils';
import { SearchVmDo } from '../models/search-VmDo';
import { AppComponent } from '../app.component';
import { Table } from 'primeng/table';
import { SearchHelperService } from '../services/search-helper.service';;
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ExtendedCountries } from '../enums/extended-countries';
import { HelperService } from '../services/helper.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReportingService } from '../services/reporting.service';
import { PlanningHTMLOptionsComponent } from '../planning-htmloptions/planning-htmloptions.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-planning-exposants',
  templateUrl: './planning-exposants.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./planning-exposants.component.less']
})
export class PlanningExposantsComponent {

  @ViewChild('dtPlannings') dtPlannings!: Table;
  private _areDynamicColumnsMapped: boolean = false;
  ColsStats: any = [];
  ref: DynamicDialogRef | undefined;
  userEmail : string | undefined


  constructor(
    public app: AppComponent,
    private searchHelperService: SearchHelperService,
    private datePipe: DatePipe,
    public helperService: HelperService,
    public translalteService: TranslateService,
    public translatePipe: TranslatePipe,
    public dialogService: DialogService,
    public reportingService: ReportingService,
    private messageService: MessageService,
  ) { }

  private companyAutocompleteSubscription?: Subscription;
  private planningExposantExportSubscription?: Subscription;

  fastSearchUserKeyword: string = '';
  showTable: boolean = true;
  allDO: boolean = false;
  search: any = {};
  showAdvancedSearch: boolean = false;

  companyNameSuggestions: string[] = [];
  planningExposants: any[] = [];
  selectedPlanningExpo: any = [];
  items: any = [];
  priorities: any = [];
  federators: any = [];
  priviesGroups: any = [];
  countries: any = [];
  planninEcposantCustomOptions: any = {};



  //#endregion



  //#region  columns
  _selectedColumns: any = [];
  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    this._selectedColumns = this.colsPlanningExposants.filter((col: any) => val.includes(col));
  }
  colsPlanningExposants: any = [];
  _selectedAction: any
  actions: any[] = []
  groupedActions: any[] = []
  langSubscription?: Subscription
  //#endregion 

  ngOnInit() {
    //get privious advanced search value before routing to other routes
    this.search = cloneDeep(this.searchHelperService.getAdvancedSearch(VMFolderSearchLocalStorageKey));
    let user = JSON.parse(sessionStorage.getItem('userData')!);
    console.log(user);
    if (!user) {
      this.app.router.navigate(['/login']);
      return;
    }
    this.userEmail = user?.Contact?.Email;
    // this.colsFolders = this.app.translate.("racine.generalites.columns");
    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
        console.log("resG", resG);
        this.planninEcposantCustomOptions = resG.planninEcposantCustomOptions;
        this.priorities = resG.formPriorities;

        this.groupedActions = resG.actionPlanningExposant
        this.fillGroupedActionCombo(this.groupedActions)

        //getcolumns
        this.colsPlanningExposants = resG.colsPlanningExposants;
        this._selectedColumns = this.colsPlanningExposants;

        this.getFederators()
        this.getCountries();
        this.getPriviGroups();
        this.search.Eligible = false;
        this.searchODPlanningExhibitor(this.search);

        this.langSubscription = this.app.translate.onLangChange.subscribe(
          data => {
            this.fillGroupedActionCombo(this.groupedActions)
          }
        )
      });
  }

  //#region  Get
  async getPriviGroups() {
    try {
      const response = await this.app.apiService.privigroupgetall();
      let priviGroups = response;
      if (priviGroups) {
        this.priviesGroups = priviGroups.filter((prvGrp: any) => { return prvGrp });
      }

    } catch (error) {
      console.error(error);
    }
  }

  getCountries() {
    this.app.apiService.getExtendedCountries(ExtendedCountries.vm).subscribe((data: any) => {
      console.log("getCountries ==> ", data);
      this.countries = data; //cloneDeep(data);
    });
  }

  async getFederators() {
    try {
      const response = await this.app.apiService.federatorListe();
      console.log("getFederators ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/'); }, () => { })
        return;
      }

      this.federators = cloneDeep(response);
      console.log("federators ==> ", this.federators)

    } catch (error) {
      console.error(error);
    }
  }
  //#endregion


  //#region Search
  clearSearch() {
    console.log("clearSearch ==>");
    this.search = new SearchVmDo();
    this.searchODPlanningExhibitor(this.search);
  }
  async searchODPlanningExhibitor(search: any) {
    console.log("search ==> ", search);
    let searchTemp: any = cloneDeep(search);

    if (searchTemp.selectedCountry) searchTemp.CountryID = searchTemp.selectedCountry.CountryID;
    if (searchTemp.selectedFederator) searchTemp.FederatorFolderID = searchTemp.selectedFederator.FolderID;
    if (searchTemp.selectedPriviGroup) searchTemp.PriviGroupID = searchTemp.selectedPriviGroup.PriviGroupID;
    if (searchTemp.selectedPriority) searchTemp.Priority = searchTemp.selectedPriority.Priority;

    if (searchTemp.selectedCountry) delete searchTemp.selectedCountry;
    if (searchTemp.selectedFederator) delete searchTemp.selectedFederator;
    if (searchTemp.selectedPriviGroup) delete searchTemp.selectedPriviGroup;
    if (searchTemp.selectedPriority) delete searchTemp.selectedPriority;

    console.log("searchTemp ==> ", searchTemp);

    try {
      const response = await this.app.apiService.searchPlanningsExhibitor(searchTemp);
      console.log("searchPlanningsExhibitor ==> ", response);

      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/'); }, () => { })
        return;
      }

      this.planningExposants = response;

      if (!this._areDynamicColumnsMapped) {
        this.mapDynamicColumns();
      }
      this.mapValuesToDynamicColumns();

    } catch (error) {
      console.error(error);
    }
  }

  toggleMenu(event: any, formId: number, items: any) {
    //this.menuAction.toggle(event);

    console.log("formId ID:", formId);
    console.log("items:", items);

    items.forEach((itemMenu: any) => {
      itemMenu.formIdRow = formId;
      itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

      if (itemMenu.ActionID == 1) {
        itemMenu.command = (event: any) => {
          this.app.sharedTools.redirectTo('/detail-vm-exposant/' + event.item.formIdRow.toString());
        }
      }

      if (itemMenu.ActionID == 2) {
        itemMenu.command = (event: any) => {

        }
      }
    });
  }
  //#endregion

  //#region Autocomplete CompanyName / Raison sociale
  onSearchCompanyName(event: any): void {
    let keyword: string | undefined = event.query;

    if (!keyword || keyword === '') {
      this.companyNameSuggestions = [];
    }
    else {
      this.refreshCompanyNameAutocomplete(keyword);
    }
  }
  private refreshCompanyNameAutocomplete(keyword: string): void {
    this.companyAutocompleteSubscription = this.app.apiService.companyAutocompleteVmFolder(keyword).subscribe({
      next: (data: any) => {
        if (data && data != '' && Array.isArray(data)) {
          this.companyNameSuggestions = data;
        }
        else {
          this.companyNameSuggestions = []
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }
  //#endregion

  onAllDOChange() {
  }

  //#region Export
  exportPlanningExposantToCsv(): void {
    this.PlanningExposantExport();
  }

  private PlanningExposantExport(): void {
    this.planningExposantExportSubscription = this.app.apiService.planningExposantExport(this.search).subscribe({
      next: (data: any) => {
        console.log('planningExposantExport', data);
        if (data) {
          let filesUrl: string = data;

          window.open(filesUrl);
        }

      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  private mapDynamicColumns(): void {
    if (this.planningExposants && this.planningExposants.length > 0) {
      let stats: any = this.planningExposants[0].PlanningStats;
      console.log('this.stats ', stats)
      //create dynamic cols
      if (stats) {
        let lastCol = cloneDeep(this.colsPlanningExposants[this.colsPlanningExposants.length - 1]);
        let lastColID: number = lastCol.ColID;

        stats.forEach((s: any) => {
          lastColID++;
          let newCol: any =
          {
            ColID: lastColID,
            field: s.Date,
            header: this.datePipe.transform(s.Date, 'dd/MM'),
            LabelEN: this.datePipe.transform(s.Date, 'dd/MM'),
            LabelFR: this.datePipe.transform(s.Date, 'dd/MM'),
            selected: true,
            filter: false,
            sort: false,
            isStat: true,
            width: 67
          }
          this.colsPlanningExposants.push(newCol)
          let planningFormat = /^([0-9]{1}\/[0-9]{2})$/;
          if (planningFormat.test(newCol.header) !== null) {
            this.ColsStats.push(newCol);
          }
        });
      }

    }
    console.log('this.ColsStats ', this.ColsStats)

    console.log('this.colsPlanningExposants ', this.colsPlanningExposants)
    this._areDynamicColumnsMapped = true;
  }

  private mapValuesToDynamicColumns(): void {
    //prepare properties fot dynamic cols
    this.planningExposants.forEach((p: any) => {
      let stats: any = p.PlanningStats;
      //create dynamic cols
      if (stats) {
        stats.forEach((s: any) => {
          p[s.Date] = `${s.VisitCount}/${s.SlotCount}`
        });
      }
    });
  }
  //#endregion
  mapColumn() {
    // if(!this.selectedPlanningExpo){
    //   return [];
    // }
    return this.planningExposants.map((row: any) => {
      const filteredColumns: any = {};
      this.selectedColumns.forEach(column => {
        //   if(this.translalteService.currentLang === "fr"){
        switch (column.field) {

          case "NumExposant": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Company?.CompanyExternalID1 ?? ''
            break;
          case "CompanyName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = ` ${row.Company.CompanyName2 ?? ''} ${row.Company.FullName ?? ''} ${row.Company.PhoneNumber ?? ''} ${row.Company.ContactEmail ?? ''}`
            break;
          case "Priority": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Priority?.substring(0, 2) ?? ''
            break;
          case "Hall": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.MeetingStand ?? ''
            break;
          case "Odcapacities": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Odcapacities ?? ''
            break;
          case "categName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.ODSectorCategories?.map((cat: any) => `${this.app.sharedTools.getLabelSwitchLang(cat)}`).join(', ');
            break;
          case "PriviGroupName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Group?.PriviGroupName ?? ''
            break;





          default: filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row[column.field]
            // this.ColsStats.forEach((col:any) => {
            //   switch (column.field) {
            //     case col.field: filteredColumns[column.field] = row[col.field] ?? ''
            //   }
            // });
            // console.log("row[column.field].header========sqsqsqsqsq=====",row[column.field].toString() )

            break;
        }
      });
      return filteredColumns;
    })
  }
  exportCSV() {
    console.log(this.planningExposants);
    this.helperService.exportCSV(this.planningExposants, this.mapColumn(), 'planning_exposant_export_' + new Date().toLocaleDateString(), true);
  }

  openModal(planningExp: any) {
    console.log(planningExp)
    let userEmails : string[] = []
    if(this.userEmail != undefined){
      userEmails.push(this.userEmail)
    }
    this.ref = this.dialogService.open(PlanningHTMLOptionsComponent, {
      header: "Options du rapport",
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 100,
      maximizable: false,
      styleClass: 'generateplanning',
      data: { includeNoteSection: false ,emailList : userEmails}
    });

    this.ref.onClose.subscribe((val: { emails: string[] ,includeNote: boolean}) => {
      console.log('closed ', val);
      if (val) {
        let folderIdList = []
        folderIdList.push(planningExp.FolderID)
        this.reportingService.getPlanningExp(folderIdList,val.includeNote, val.emails,this.app.translate.currentLang).subscribe({
          next: (res: any) => {
            console.log('GENERATE** getPlanningEX : ',res);

            if (res && res.Result) {
                setTimeout(() => {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + res.Result)
                        , 'KO'
                        , "alert"
                        , () => { }
                        , () => { });
                }, 200);
                return;
            }

            if (res.IsSuccessful === false || res.ByPass == true) {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + res.Error)
                  , 'KO'
                  , "alert"
                  , () => { }
                  , () => { });
                  
              return;
            }

            if(res && res.Url){
                window.open(res.Url, '_blank'); 
            }

            if(res && res.SendEmailIsSuccessful === false){
              this.app.confirm.confirmDialog("", this.app.translate.instant('bo.vm-planning-exposants.sendPlanningEmailPlannedError')
              , 'KO'
              , "alert"
              , () => { }
              , () => { });
              return;
          }
          
          if(val.emails && Array.isArray(val.emails)
          && val.emails.length > 0){
              this.app.confirm.confirmDialog("", this.app.translate.instant('bo.vm-planning-exposants.sendPlanningEmailPlannedSuccess')
              , 'KO'
              , "alert"
              , () => { }
              , () => { });
          }
          return;

          }
        })
      }
    });

  }


  fillGroupedActionCombo(data: any) {
    this.actions = []
    if (this.app.translate.currentLang == "fr") {
      data.forEach((action: any) => {
        let actionModel = {
          label: action.LabelFR,
          ActionID: action.ActionID
        }
        this.actions.push(actionModel);
      })
    }
    if (this.app.translate.currentLang == "en") {
      data.forEach((action: any) => {
        let actionModel = {
          label: action.LabelEN,
          ActionID: action.ActionID
        }
        this.actions.push(actionModel);
      })
    }
    if (this.app.translate.currentLang == "es") {
      data.forEach((action: any) => {
        let actionModel = {
          label: action.LabelES,
          ActionID: action.ActionID
        }
        this.actions.push(actionModel);
      })
    }
  }

  onActionSelectChange(action: any) {
    if (this._selectedAction.ActionID == 1) {
      let planningList = []
      planningList = this.selectedPlanningExpo.map((x: any) => {
        return x.FolderID
      })
      this.openModalPlanningList(planningList)
    }
  }

  openModalPlanningList(folderIdList: any) {
    let userEmails : string[] = []
    if(this.userEmail != undefined){
      userEmails.push(this.userEmail)
    }
    this.ref = this.dialogService.open(PlanningHTMLOptionsComponent, {
      header: "Options du rapport",
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 100,
      maximizable: false,
      styleClass: 'generateplanning',
      data: { includeNoteSection: false, isEmailRequired: true,emailList : userEmails, sendPlanningsToAllExhibitorsSection: true }
    });
 
    this.ref.onClose.subscribe((val: { emails: string[],includeNote: boolean, sendPlanningsToAllExhibitors:boolean }) => {
      this._selectedAction = undefined
      console.log('closed ', val);
      if (val) {
        this.reportingService.asyncGeneratePlanningExhibitorPdf(folderIdList
          , val.includeNote
          , val.emails
          , this.app.translate.currentLang
          , val.sendPlanningsToAllExhibitors).subscribe({
          next: (res: any) => {
            console.log('GENERATE** getPlanningEx : ',res);
                        
            if (res && res.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + res.Result)
                    , 'KO'
                    , "alert"
                    , () => { }
                    , () => { });
                    return;
                }
            
            if (res && res.IsSuccessful === false) {
              this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + res.Error)
              , 'KO'
              , "alert"
              , () => { }
              , () => { });
              return;
            }
            
            if(res && res.SendEmailIsSuccessful === false){
              this.app.confirm.confirmDialog("", this.app.translate.instant('bo.vm-planning-exposants.sendPlanningEmailPlannedError')
              , 'KO'
              , "alert"
              , () => { }
              , () => { });
              return;
          }
          
          this.app.confirm.confirmDialog("", this.app.translate.instant('bo.vm-planning-exposants.sendPlanningEmailPlannedSuccess')
          , 'KO'
          , "alert"
          , () => { }
          , () => {});
          return;

          }
        })
      }
    });
  }

}

