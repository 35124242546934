import { Component, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from "@angular/forms";
import { TreeNode, Message } from 'primeng/api';
import { AppComponent } from '../app.component';
import { cloneDeep, forEach, groupBy } from 'lodash';
import { FolderIndirectNew } from '../models/folder-indirect-new';
import { WebsiteRegex, EmailRegex, LettersOnlyRegex } from '../consts/utils';
import { CustomValidators } from '../validators/custom-validators';
import { CartItem } from '../models/cart-item';
import { ProductsGroup } from '../models/products-group';

@Component({
    selector: 'app-expo-indirect',
    templateUrl: './expo-indirect.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./expo-indirect.component.less']
})
export class ExpoIndirectComponent {
    options: any = {
        types: ['geocode'],
    };
    user: any = null;
    folder: any = null;
    categories: any = [];
    nomenclatures!: TreeNode[]; // Initialize as null
    selectedNodes: any = [];
    exposantTotal: number = 0;
    currentExposantToEdit: any = null;
    showFormIndirect: boolean = false;
    initForm: boolean = false;
    isAdresseManuelle: boolean = false;
    isAdresseFactuManuelle: boolean = false;
    countries: any = [];
    civilities: any = [];
    langues: any = [];
    phones: any = [];
    submitted: boolean = false;
    newLeadForm!: FormGroup;
    functions: any = [];
    tree: any = {};
    expoIndForm!: FormGroup;
    otherFunction: any = {};
    folderIndirectNew = new FolderIndirectNew();
    //check if user redirected from BO ==> do not show steps 
    redirectedFromBO: boolean = false;

    //current FolderID 
    //can be from user if FO
    //can be from route query parameters if from BO
    folderId: any = null;

    //@param selectedIndirectExhibitorOrder
    //for edit we take order of IndirectExhibitor in folder.LinkedFolders
    //for add we take lengh of folder.LinkedFolders + 1
    selectedIndirectExhibitorOrder: number = 1;

    companyHaveOtherActivity: boolean = false;
    invalidLabels: string[] | null = null;

    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private formBuilder: FormBuilder
    ) {
    }

    selectGPAddress(address: google.maps.places.PlaceResult, isNotAddressFacturation: boolean): void {
        if (!address) return;
        // Place object from API
        console.log("selectGPAddress ==> ", address);

        if (isNotAddressFacturation) this.newLeadForm.patchValue({ adresseComplete: address.formatted_address })
        else this.newLeadForm.patchValue({ adresseFactuComplete: address.formatted_address })

        if (address.address_components!.length > 0) {
            console.log("address.address_components ==> ", address.address_components);
            let Address1: string = "";
            let ZipCode: string = "";
            let City: string = "";
            let Country: string = "";
            let currentCountry: any = null;

            address.address_components!.forEach((item: any) => {
                if (item.types.length > 0) {
                    if (item.types.indexOf("street_number") != -1) Address1 += item.long_name;
                    if (item.types.indexOf("route") != -1) Address1 += ' ' + item.long_name;
                    if (item.types.indexOf("locality") != -1) City = item.long_name;
                    if (item.types.indexOf("postal_code") != -1) ZipCode = item.long_name;
                    if (item.types.indexOf("country") != -1) Country = item.short_name;

                    if (Country) currentCountry = this.countries.filter((c: any) => {
                        return c.CodeISO2 == Country;
                    })[0];
                }
            });

            if (isNotAddressFacturation) {
                this.newLeadForm.patchValue({
                    AddressLine1: Address1,
                    City: City,
                    ZipCode: ZipCode,
                    CountrySelected: (currentCountry) ? currentCountry : '',
                });

                if (!Address1 || !City || !ZipCode || !currentCountry) {
                    this.isAdresseManuelle = true;
                }

                this.onChangeCountry(this.newLeadForm.get('CountrySelected')?.value);

            } else {
                this.newLeadForm.patchValue({
                    Billing_AddressLine1: Address1,
                    Billing_City: City,
                    Billing_ZipCode: ZipCode,
                    Billing_CountrySelected: (currentCountry) ? currentCountry : '',
                });

                if (!Address1 || !City || !ZipCode || !currentCountry) {
                    this.isAdresseFactuManuelle = true;
                }
            }

            console.log("this.newLeadForm", this.newLeadForm.value);
        }
    }


    adresseManuellement(isNotAddressFacturation: boolean) {
        if (isNotAddressFacturation) {
            this.isAdresseManuelle = !this.isAdresseManuelle;
        } else {
            this.isAdresseFactuManuelle = !this.isAdresseFactuManuelle;
        }
        console.log("this.newLeadForm", this.newLeadForm.value);
    }

    async ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log("this.user ==> ", this.user);
        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }

        this.findFolderID();

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG", resG);
                this.otherFunction = cloneDeep(resG.otherFunction);

                this.getPhones();
                this.getCategories();
                this.getFunctions();
                this.getCivilities();
                this.getLanguages();
                this.getCountries();

                setTimeout(() => {
                    this.getFolderInit(this.folderId);
                }, 300);
            });
    }

    buildTree(parentId: any) {
        const children = this.tree[parentId] || [];
        //console.log("children==>", children)

        return children.map((child: any) => {
            // console.log("child==>", child)
            const node = {
                key: child.CategoryID,
                label: this.app.sharedTools.getLabelSwitchLang(child),
                LabelEN: child.LabelEN,
                LabelFR: child.LabelFR,
                LabelES: child.LabelES,
                data: '',
                icon: '',
                children: this.buildTree(child.CategoryID)
            };
            //console.log("node==>", node)
            return node;
        });
    }

    async getCategories() {
        try {
            const response = await this.app.apiService.getCategories();

            let categoriesIni = cloneDeep(response);
            let categories = categoriesIni.filter((cat: any) => {
                return cat.CategoryType == "ACTIVITIES"
            });
            this.categories = cloneDeep(categories);

            categories.forEach((cat: any) => {
                const parentId = cat.ParentCategoryID;
                //console.log("parentId : ", parentId)
                if (!this.tree[parentId]) {
                    this.tree[parentId] = []; // Crea un nuevo array si no existe para ese ParentCategoryId
                }
                this.tree[parentId].push(cat); // Agrega el objeto al array correspondiente al ParentCategoryId

                //console.log('this.tree ==> ', this.tree[parentId])

            });

            this.nomenclatures = this.buildTree(null);
            console.log('this.nomenclatures ==> ', this.nomenclatures);
        } catch (error) {
            console.error(error);
        }
    }

    getFunctions() {
        this.app.apiService.getFunctions().subscribe((data: any) => {
            console.log("getFunctions ==> ", data);
            this.functions = data; //cloneDeep(data);
            this.functions.push(this.otherFunction);
        });
    }

    getCivilities() {
        this.app.apiService.getCivilities().subscribe((data: any) => {
            console.log("getCivilities ==> ", data);
            this.civilities = data; //cloneDeep(data);
        });
    }

    getPhones() {
        this.app.apiService.getPhones().subscribe((data: any) => {
            console.log("getPhones ==> ", data);
            this.phones = data; //cloneDeep(data);
        });
    }

    getLanguages() {
        this.app.apiService.getLanguages().subscribe((data: any) => {
            console.log("getLanguages ==>", data);
            this.langues = data;
        });
    }

    getCountries() {
        this.app.apiService.getCountries().subscribe((data: any) => {
            console.log("getCountries ==> ", data);
            this.countries = data; //cloneDeep(data);
        });
    }

    onEditExposant(_item: any) {
        console.log("onEditExposant _item ==> ", _item);
        this.getFormIndirect(_item);
        this.showFormIndirect = true;

        //
        this.refreshIndirectExhibitorOrder(true, _item.FolderID);
        //this.currentExposantToEdit = _item;
        //console.log("onEditExposant this.currentExposantToEdit ==> ", this.currentExposantToEdit);
    }

    onChangeCountry(_countryObj: any) {
        console.log('_countryObj', _countryObj);
        console.log('this.phones', this.phones);

        if (!_countryObj) return;

        let getPrefix: any = this.phones.filter((prefix: any) => { return prefix.RelatedCountryID == _countryObj.CountryID })[0];
        console.log('getPrefix', getPrefix)

        if (getPrefix) {
            if (!this.newLeadForm.get('PhonePrefixSelected')?.value && !this.newLeadForm.get('PhoneNumber')?.value)
                this.newLeadForm.get('PhonePrefixSelected')?.setValue(getPrefix);

            if (!this.newLeadForm.get('DPPhonePrefixSelected')?.value && !this.newLeadForm.get('DP_PhoneNumber')?.value)
                this.newLeadForm.get('DPPhonePrefixSelected')?.setValue(getPrefix);

            if (!this.newLeadForm.get('DPMobilePrefixSelected')?.value && !this.newLeadForm.get('DP_MobileNumber')?.value)
                this.newLeadForm.get('DPMobilePrefixSelected')?.setValue(getPrefix);
        }
    }

    addNewExposant() {
        // ==> On cree le formulaire new
        const notRequiredValidators: any = this.folderIndirectNew.getListNotRequireds();
        //console.log("notRequiredValidators ==> ", notRequiredValidators);


        const formModel: any = {};
        const folderIndirectNew: any = new FolderIndirectNew();

        for (const propertyName in folderIndirectNew) {
            if (folderIndirectNew.hasOwnProperty(propertyName) && propertyName != 'Activities') {
                const propertyValue = folderIndirectNew[propertyName];
                let validators: any = [];

                if (notRequiredValidators.indexOf(propertyName) == -1) validators.push(Validators.required);
                //if (propertyName == 'PhoneNumber' || propertyName == 'DP_PhoneNumber' || propertyName == 'DP_MobileNumber') validators.push(Validators.pattern('[- +()0-9]+'));
                if (propertyName == 'DP_Email' || propertyName == 'Email') validators.push(Validators.pattern(EmailRegex));
                if (propertyName == 'Website') validators.push(Validators.pattern(WebsiteRegex));
                formModel[propertyName] = [propertyValue, validators];
            }
        }

        this.newLeadForm = this.formBuilder.group(formModel);

        this.newLeadForm.addControl('Activities', this.formBuilder.array([]));
        this.newLeadForm.addValidators(CustomValidators.atLeastHaveOneActivityValidator());

        console.log("this.newLeadForm", this.newLeadForm);

        this.companyHaveOtherActivity = false;
        this.isAdresseManuelle = false;
        this.initForm = true;

        // ==> On met lle showIndirect à true
        this.showFormIndirect = true;

        //
        this.refreshIndirectExhibitorOrder(false);
    }

    getFormIndirect(_folder: any) {
        let folder: any = cloneDeep(_folder);
        let adrPrincipale: any = null;
        let adrBilling: any = null;
        let contactFolderResp: any = null;

        if (folder.Company) {
            folder.Company.Categories.forEach((cat: any) => {
                //console.log('cat ==> ', cat);
                setTimeout(() => {
                    this.selectNodesByData(cat.CategoryID, this.nomenclatures);
                }, 500);
            });

            folder.Company.selectedPhonePrefix = (folder.Company.PhoneCodeIso) ? this.app.sharedTools.getObjByLabel(folder.Company.PhoneCodeIso, "CodeISO2", this.phones) : null;

            if (folder.Company.Contacts && folder.Company.Contacts.length > 0) {
                folder.Company.Contacts.forEach((contact: any) => {
                    contact.selectedLanguage = (contact.LanguageID) ? this.app.sharedTools.getObjById(contact.LanguageID, "LanguageID", this.langues) : null;
                    contact.selectedCivility = (contact.CivilityID) ? this.app.sharedTools.getObjById(contact.CivilityID, "CivilityID", this.civilities) : null;
                    contact.selectedFunction = (contact.FunctionID) ? this.app.sharedTools.getObjById(contact.FunctionID, "FunctionID", this.functions) : null;
                    contact.selectedPhonePrefix = (contact.PhoneCodeIso) ? this.app.sharedTools.getObjByLabel(contact.PhoneCodeIso, "CodeISO2", this.phones) : null;
                    contact.selectedMobilePrefix = (contact.MobileCodeIso) ? this.app.sharedTools.getObjByLabel(contact.MobileCodeIso, "CodeISO2", this.phones) : null;

                    if (contact.Categories && contact.Categories.length > 0) {
                        contact.Categories.forEach((cat: any) => {
                            if (cat.CategoryType == "FOLDER_RESP") contactFolderResp = contact;
                        });
                    }
                });
            }

            if (folder.Company.Addresses && folder.Company.Addresses.length > 0) {

                folder.Company.Addresses.forEach((adr: any) => {
                    adr.selectedCountry = (adr.CountryID) ? this.app.sharedTools.getObjById(adr.CountryID, "CountryID", this.countries) : null;
                });

                adrPrincipale = folder.Company.Addresses.filter((adr: any) => {
                    return adr.IsMainAddress
                })[0];
                adrBilling = folder.Company.Addresses.filter((adr: any) => {
                    return adr.IsBillingAddress
                })[0];

                this.isAdresseManuelle = (adrPrincipale && adrPrincipale.Line1) ? true : false;
                this.isAdresseFactuManuelle = (adrBilling && adrBilling.Line1) ? true : false;
            }
        }

        setTimeout(() => {
            this.newLeadForm = this.formBuilder.group({
                ParentFolderID: [this.folderId],
                FolderID: [folder.FolderID, Validators.required],
                CompanyName: [(folder.Company && folder.Company.CompanyName1) ? folder.Company.CompanyName1 : '', Validators.required],
                CatalogName: [(folder.Company && folder.Company.CompanyName2) ? folder.Company.CompanyName2 : '', Validators.required],
                adresseComplete: [''], //Validators.required //TODELETE avant envoi
                AddressLine1: [(adrPrincipale && adrPrincipale.Line1) ? adrPrincipale.Line1 : '', Validators.required],
                AddressLine2: [(adrPrincipale && adrPrincipale.Line2) ? adrPrincipale.Line2 : ''],
                AddressLine3: [(adrPrincipale && adrPrincipale.Line3) ? adrPrincipale.Line3 : ''],
                ZipCode: [(adrPrincipale && adrPrincipale.ZipCode) ? adrPrincipale.ZipCode : '', Validators.required],
                City: [(adrPrincipale && adrPrincipale.City) ? adrPrincipale.City : '', Validators.required],
                CountryID: [(adrPrincipale && adrPrincipale.CountryID) ? adrPrincipale.CountryID : ''], //TOFORMAT from CountrySelected
                CountrySelected: [(adrPrincipale && adrPrincipale.selectedCountry) ? adrPrincipale.selectedCountry : null, Validators.required], //TODELETE avant envoi
                IsBillingAddressSameAsPrincipal: [true], //IsBillingAddressSameAsPrincipal
                Email: [(folder.Company && folder.Company.Email) ? folder.Company.Email : '', [Validators.required, Validators.pattern(EmailRegex)]],
                PhonePrefixSelected: [folder.Company.selectedPhonePrefix, Validators.required],
                PhonePrefix: [(folder.Company && folder.Company.PhonePrefix) ? folder.Company.PhonePrefix : ''], //TOFORMAT  from PhonePrefixSelected
                PhoneCodeIso: [(folder.Company && folder.Company.PhoneCodeIso) ? folder.Company.PhoneCodeIso : ''], //TOFORMAT  from PhonePrefixSelected
                PhoneNumber: [(folder.Company && folder.Company.PhoneNumber) ? folder.Company.PhoneNumber : '', [Validators.required]],
                Website: [(folder.Company && folder.Company.Website) ? folder.Company.Website : '', [Validators.pattern(WebsiteRegex)]],
                IsDPManagerSameAsLegal: [true], //IsDPManagerSameAsLegal
                DP_CivilityID: [(contactFolderResp && contactFolderResp.CivilityID) ? contactFolderResp.CivilityID : ''], //TOFORMAT  from selectedDPCivility
                selectedDPCivility: [(contactFolderResp && contactFolderResp.selectedCivility) ? contactFolderResp.selectedCivility : null, Validators.required], //TODELETE avant envoi
                DP_FirstName: [(contactFolderResp && contactFolderResp.FirstName) ? contactFolderResp.FirstName : '', [Validators.required, Validators.pattern(LettersOnlyRegex)]],
                DP_LastName: [(contactFolderResp && contactFolderResp.LastName) ? contactFolderResp.LastName : '', [Validators.required, Validators.pattern(LettersOnlyRegex)]],
                DP_FunctionID: [(contactFolderResp && contactFolderResp.FunctionID) ? contactFolderResp.FunctionID : ''], //TOFORMAT  from selectedDpFunction
                selectedDpFunction: [(contactFolderResp && contactFolderResp.selectedFunction) ? contactFolderResp.selectedFunction : null, Validators.required],
                DP_OtherFunction: [(contactFolderResp && contactFolderResp.OtherFunction) ? contactFolderResp.OtherFunction : ''], //TOFORMAT : Empty if DP_FunctionID != -99
                DP_Email: [(contactFolderResp && contactFolderResp.Email) ? contactFolderResp.Email : '', [Validators.required, Validators.pattern(EmailRegex)]],
                DPPhonePrefixSelected: [(contactFolderResp && contactFolderResp.selectedPhonePrefix) ? contactFolderResp.selectedPhonePrefix : null, Validators.required],
                DP_PhonePrefix: [(contactFolderResp && contactFolderResp.PhonePrefix) ? contactFolderResp.PhonePrefix : ''], //TOFORMAT  from DPPhonePrefixSelected
                DP_PhoneCodeIso: [(contactFolderResp && contactFolderResp.PhoneCodeIso) ? contactFolderResp.PhoneCodeIso : ''], //TOFORMAT  from DPPhonePrefixSelected
                DP_PhoneNumber: [(contactFolderResp && contactFolderResp.PhoneNumber) ? contactFolderResp.PhoneNumber : '', [Validators.required]],
                DPMobilePrefixSelected: [(contactFolderResp && contactFolderResp.selectedMobilePrefix) ? contactFolderResp.selectedMobilePrefix : null, Validators.required],
                DP_MobilePrefix: [(contactFolderResp && contactFolderResp.MobilePrefix) ? contactFolderResp.MobilePrefix : ''], //TOFORMAT  from DPMobilePrefixSelected
                DP_MobileCodeIso: [(contactFolderResp && contactFolderResp.MobileCodeIso) ? contactFolderResp.MobileCodeIso : ''], //TOFORMAT  from DPMobilePrefixSelected
                DP_MobileNumber: [(contactFolderResp && contactFolderResp.MobileNumber) ? contactFolderResp.MobileNumber : '', [Validators.required]],
                selectedDPLanguage: [(contactFolderResp && contactFolderResp.selectedLanguage) ? contactFolderResp.selectedLanguage : null, Validators.required],
                DP_LanguageID: [(contactFolderResp && contactFolderResp.LanguageID) ? contactFolderResp.LanguageID : ''], //TOFORMAT  from selectedDPLanguage

                Activities: this.formBuilder.array(
                    (folder?.Company?.Categories) ? folder.Company.Categories.map((c: any) => c.CategoryID) : []
                    // , CustomValidators.arrayLengthGreaterThanZeroValidator()
                ),
                OtherActivity: [(folder.Company && folder.Company.OtherActivity) ? folder.Company.OtherActivity : ''],
            }
                //set formgroup validator
                //this Validator was added to validate two form contols of exhibitor formgroup
                //at least one activity must be provided
                //either on Activities Control (array)
                //either on OtherActivity Control(text input)
                , { validator: CustomValidators.atLeastHaveOneActivityValidator() });

            //this.companyHaveOtherActivity = folder?.Company?.OtherActivity && folder?.Company?.OtherActivity != '';
            this.companyHaveOtherActivity = (folder?.Company?.OtherActivity) ? true : false;

            this.setConditionalValidators();

            console.log("this.newLeadForm", this.newLeadForm);

            this.initForm = true;
        }, 500);

    }

    setConditionalValidators() {
        this.newLeadForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });

        const DP_OtherFunction = this.newLeadForm.get('DP_OtherFunction')!;
        console.log("DP_OtherFunction ==> ", DP_OtherFunction);

        this.newLeadForm.get('selectedDpFunction')?.valueChanges
            .subscribe(selectedDpFunction => {
                console.log("selectedDpFunction", selectedDpFunction);
                if (selectedDpFunction && selectedDpFunction.FunctionID == -99) {
                    DP_OtherFunction.setValidators([Validators.required]);
                }
                else {
                    DP_OtherFunction.setValidators(null);
                    DP_OtherFunction.setValue('');
                }
                DP_OtherFunction.updateValueAndValidity();
            });

        const otherActivityFormControl = this.newLeadForm.get('OtherActivity')!;
        console.log("otherActivityFormControl ==> ", otherActivityFormControl);

        this.newLeadForm.get('companyHaveOtherActivity')?.valueChanges
            .subscribe(companyHaveOtherActivity => {
                console.log("companyHaveOtherActivity", companyHaveOtherActivity);
                if (companyHaveOtherActivity) {
                    otherActivityFormControl.setValidators([Validators.required]);
                }
                else {
                    otherActivityFormControl.setValidators(null);
                    otherActivityFormControl.setValue('');
                }
                otherActivityFormControl.updateValueAndValidity();
            });
    }

    selectNodesByData(data: any, nodes: TreeNode[] = this.nomenclatures) {
        //console.log("this.nomenclatures:", this.nomenclatures);

        try {
            for (const node of nodes) {
                if (node.key === data) {
                    this.selectedNodes.push(node);
                    break;
                }
                if (node.children) {
                    this.selectNodesByData(data, node.children);
                }
            }
        } catch (error) {
            console.warn(error);
        }
    }

    async getFolderInit(folderId: any) {
        try {
            const response = await this.app.apiService.getFolder(folderId);
            console.log("getFolder ==> ", response);
            this.folder = cloneDeep(response);

            //check for indirect folder id
            console.log(this.folder, 'processIndirectFolderFromRoute')
            this.findInirectFolderID();
        }
        catch (error) {
            console.error(error);
        }
    }


    anulateEditOrCreate() {
        if (this.redirectedFromBO) {
            this.app.sharedTools.goTo(`detail-dp/${this.folderId}`);
        }
        else {
            this.showFormIndirect = false;
        }
    }

    onSubmit() {
        this.submitted = true;
        this.messageService.clear();

        // Logic to handle form submission
        if (this.newLeadForm.invalid) {
            const invalidValues: any = {};
            const controls = this.newLeadForm.controls;

            let idNomenclatures = document.getElementById('nomenclatures');
            if (this.newLeadForm.errors?.['atLeastHaveOneActivityRequired']) idNomenclatures?.classList.add('ng-invalid');

            let invalidFields: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName('ng-invalid') as HTMLCollectionOf<HTMLElement>;

            for (const controlName in controls) {
                if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                    invalidValues[controlName] = controls[controlName].value;
                }
            }

            //if(this.newLeadForm.errors?.['atLeastHaveOneActivityRequired']) //invalidFields.appendChild(idNomenclatures);
            console.log('Invalid form values:', invalidValues);
            console.log('this.newLeadForm:', this.newLeadForm);

            console.log(this.newLeadForm.errors?.['atLeastHaveOneActivityRequired'])

            if (invalidFields && invalidFields.length > 1) this.app.formInvalid.addSubmittedInvalidClass(invalidFields);

            this.messageService.add({ key: 'custom', severity: 'error', summary: this.app.translate.instant('racine.errorsOther.errorForm'), detail: '', sticky: true });
        }
        else {
            // Do submit logic
            let folder = this.newLeadForm.value;

            // ==> Format fields select dropdowns
            folder.ParentFolderID = this.folderId;
            folder.CountryID = (folder.CountrySelected) ? folder.CountrySelected.CountryID : null;
            folder.PhonePrefix = (folder.PhonePrefixSelected) ? folder.PhonePrefixSelected.Prefix : null;
            folder.PhoneCodeIso = (folder.PhonePrefixSelected) ? folder.PhonePrefixSelected.CodeISO2 : null;
            folder.DP_CivilityID = (folder.selectedDPCivility) ? folder.selectedDPCivility.CivilityID : null;
            folder.DP_FunctionID = (folder.selectedDpFunction) ? folder.selectedDpFunction.FunctionID : null;
            folder.DP_PhonePrefix = (folder.DPPhonePrefixSelected) ? folder.DPPhonePrefixSelected.Prefix : null;
            folder.DP_PhoneCodeIso = (folder.DPPhonePrefixSelected) ? folder.DPPhonePrefixSelected.CodeISO2 : null;
            folder.DP_MobilePrefix = (folder.DPMobilePrefixSelected) ? folder.DPMobilePrefixSelected.Prefix : null;
            folder.DP_MobileCodeIso = (folder.DPMobilePrefixSelected) ? folder.DPMobilePrefixSelected.CodeISO2 : null;
            folder.DP_LanguageID = (folder.selectedDPLanguage) ? folder.selectedDPLanguage.LanguageID : null;
            folder.DP_OtherFunction = (folder.DP_FunctionID && folder.DP_FunctionID != -99) ? '' : folder.DP_OtherFunction;

            // ==> We delete the selects fields
            this.app.sharedTools.deleteParamsInObject(folder, [
                'CountrySelected',
                'PhonePrefixSelected',
                'selectedDPCivility',
                'selectedDpFunction',
                'selectedDPLanguage',
                'adresseComplete',
                'DPPhonePrefixSelected',
                'DPMobilePrefixSelected',
            ]);

            console.log("[folder]", folder);

            this.addOrUpdateExposantIndirect(folder);
        }
    }

    async addOrUpdateExposantIndirect(_folder: any) {
        console.log("[addOrUpdateExposantIndirect] folder", _folder);
        try {
            let response: any;
            let message: any = '';

            if (!_folder.FolderID) {
                response = await this.app.apiService.addIndirect(_folder);
                message = 'racine.successMsg.successDossierCree';
            }
            else {
                response = await this.app.apiService.updateIndirect(_folder);
                message = 'racine.successMsg.successDossierUpdt';
            }

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.app.confirm.confirmDialog("", this.app.translate.instant(message), 'OK', "alert", () => {
                console.log("[addOrUpdateExposantIndirect] response", response);
                this.verifyListSupperieurUn(response.LinkedFolders, true);
                //this.locationReloadAfterAddUpdate();
            }, () => { })

        } catch (error) {
            console.error(error);
        }
    }

    addUpdateCart(_cartItem: CartItem, _redirectFct: boolean) {
        this.app.apiService.addupdateCartItem(_cartItem).subscribe({
            next: (data: any) => {
                console.log('Cart item added ', data);

                if (data && data.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                    return;
                }

                if (_redirectFct) this.locationReloadAfterAddUpdate();
                else location.reload();
            },
            error: (error: any) => {
                console.log(error);

            },
            complete: () => {
            }
        });
    }

    getFullCart(_lengthInd: number, _redirectFct: boolean) {
        this.app.apiService
            .getFullCart(this.folderId).subscribe({
                next: (data: any) => {
                    console.log('[getFullCart] => ', data);

                    if (data && data.Result) {
                        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + data.Result), 'KO', "alert", () => { }, () => { });
                        return;
                    }
                    let getFullCart = cloneDeep(data);
                    let productSrfGroup: any = getFullCart.CartGroups.filter((grp: any) => { return grp.ProductGroupID == 8 })[0];
                    if (productSrfGroup) {
                        console.log('[productSrfGroup] => ', productSrfGroup);
                        if (productSrfGroup.CartItems && productSrfGroup.CartItems.length > 0) {
                            let standEquipe: any = productSrfGroup.CartItems.filter((prd: any) => { return prd.ProductID == 15 })[0];
                            if (standEquipe) {
                                let oldMin: number = cloneDeep(standEquipe.Quantity);
                                let newMin: number = (_lengthInd > 1) ? 12 : oldMin;
                                standEquipe.Quantity = (standEquipe.Quantity < newMin) ? newMin : standEquipe.Quantity;
                                standEquipe.FolderID = this.folder.FolderID;
                                console.log('[standEquipe] => ', standEquipe);
                                this.addUpdateCart(standEquipe, _redirectFct);
                                return;
                            }

                            if (_redirectFct) this.locationReloadAfterAddUpdate();
                            else location.reload();
                            return;
                        }
                        else {
                            if (_redirectFct) this.locationReloadAfterAddUpdate();
                            else location.reload();
                        }
                    }
                    else {
                        if (_redirectFct) this.locationReloadAfterAddUpdate();
                        else location.reload();
                    }
                },
                error: (error: any) => {
                    console.log(error);
                }
            })
    }

    verifyListSupperieurUn(_list: any, redirectFct: boolean = false) {
        this.getFullCart(_list.length, redirectFct)
    }

    onDeleteExposant(_item: any) {
        console.log("onEditExposant _item ==> ", _item);

        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteExposant'), 'AUTRE', 'confirm', () => {
            this.deleteExposant(_item);
        }, () => {
            //this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'You have cancelled' });
        });
    }

    deleteExposant(_item: any) {
        console.log('deleteClaims TODO ==> ', _item);
        this.messageService.add({ severity: 'success', summary: 'Done !', detail: "Exposant deleted successfully" });
    }


    deleteFolderIndirect(_folderID: any) {
        // ==> Ici modal pour demander si on est sur.es
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.confirmMsg.confirmDeleteExposant'), 'AUTRE', "confirm", () => {
            // SI OUI
            let folderIndirect: any = {
                ParentFolderID: this.folderId,
                FolderID: _folderID
            }
            console.log("folderIndirect ==> ", folderIndirect)
            // ==> ici appel à deleteIndirect
            this.deleteIndirect(folderIndirect);
        }, () => { });

        //
        this.refreshIndirectExhibitorOrder(false);
    }

    async deleteIndirect(_folder: any) {
        console.log("[deleteIndirect] folder", _folder);

        try {
            const response = await this.app.apiService.deleteIndirect(_folder);
            console.log("[deleteIndirect] response", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            console.log("[deleteIndirect] response", response);
            this.verifyListSupperieurUn(response.LinkedFolders, false);

            this.messageService.add({ severity: 'success', summary: this.app.translate.instant('racine.successMsg.successDeleteIndirectTitle'), detail: this.app.translate.instant('racine.successMsg.successDeleteIndirectContent') });
            //location.reload();

        } catch (error) {
            console.error(error);
        }
    }

    formControlHavePatternError(name: string): boolean {
        let havePatternError: boolean = false;

        let control = this.newLeadForm.get(name);
        if (control?.errors && control.errors['pattern']) {
            havePatternError = true;
        }
        return havePatternError;
    }

    refreshFormControlActivities(): void {
        // ==> We format the nodes
        let activities = this.app.sharedTools.getCategoriesFromSelectedNodes(this.categories, this.selectedNodes);
        //set activities to Activities form control
        this.setActivitiesToForm(activities);
    }

    onCompanyHaveOtherActivityChange(value: boolean): void {
        const otherActivityFormControl = this.newLeadForm.get('OtherActivity')!;
        console.log("this.companyHaveOtherActivity", this.companyHaveOtherActivity)

        if (this.companyHaveOtherActivity) {
            otherActivityFormControl.setValidators([Validators.required]);
        }
        else {
            otherActivityFormControl.setValidators(null);
            otherActivityFormControl.setValue('');
        }
        otherActivityFormControl.updateValueAndValidity();

        console.log("otherActivityFormControl", otherActivityFormControl)
    }

    //#region private methods
    private refreshIndirectExhibitorOrder(isEditIndirectExhibitor: boolean
        , selectedIndirectExhibitorFolderID: any = null): void {

        if (isEditIndirectExhibitor) {
            if (selectedIndirectExhibitorFolderID) {
                let findSelectedIndirectExhibitor = this.folder
                    .LinkedFolders
                    .find((folder: any) => folder.FolderID === selectedIndirectExhibitorFolderID);
                if (findSelectedIndirectExhibitor) {
                    this.selectedIndirectExhibitorOrder
                        = this.folder.LinkedFolders
                            .indexOf(findSelectedIndirectExhibitor) + 1;
                }
            }
        }
        else {
            this.selectedIndirectExhibitorOrder
                = (this.folder?.LinkedFolders?.length) + 1;
        }
    }
    private findInirectFolderID(): void {
        let indirectFolderID = this.app.route.snapshot.queryParams['indirectFolderID'];

        if (indirectFolderID) {
            let findExposant: any = this.folder.LinkedFolders.find((folder: any) => folder.FolderID == indirectFolderID);
            if (findExposant) {
                this.onEditExposant(findExposant);
            }
        }
    }

    private findFolderID(): void {
        //check if route have query params === redirected from BO
        let directFolderID = this.app.route.snapshot.queryParams['folderID'];
        if (directFolderID) {
            this.folderId = directFolderID;
            this.redirectedFromBO = true;
        }
        else {
            this.folderId = this.user.FolderID;
        }
    }

    locationReloadAfterAddUpdate(): void {
        if (this.redirectedFromBO) {
            //if redirectedFromBO go back to BO
            this.app.sharedTools.goTo(`detail-dp/${this.folderId}`)
        }
        else {
            //else === from FO go back to exposants-indirects list (step2)
            this.app.sharedTools.goTo('exposants-indirects');
        }
    }

    private setActivitiesToForm(newValues: any): void {
        let activitiesControl = this.newLeadForm.get('Activities') as FormArray;

        // Clear the existing controls in the FormArray
        activitiesControl.clear();

        // Add the new values to the FormArray as individual controls
        newValues.forEach((value: any) => {
            activitiesControl.push(this.formBuilder.control(value));
        });
    }
    //#endregion
}
