import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppComponent } from '../app.component';
import { StatusType } from '../enums/status-type';
import { StatusCode } from '../enums/status-code';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'app-communique-press-list-dataview',
  templateUrl: './communique-press-list-dataview.component.html',
  styleUrls: ['./communique-press-list-dataview.component.less']
})
export class CommuniquePressListDataviewComponent implements OnInit {
  
  public get listNews() : any[] {
    return this._listNews
  }
  
  @Input()  public set listNews(v : any) {
    this._listNews = v;
  }

  
  
  @Output() mustReloadData = new EventEmitter<any>()


 @Input() status: any

  _listNews : any


  constructor(public app: AppComponent) { }

  ngOnInit(): void {
  }


  getStatus() {
    this.app.apiService.getStatus().subscribe(
      data => {
        this.status = data?.filter((x: any) => x?.StatusType == StatusType.NEWS_Publish_State)
      }
    )
  }

  getComboStyleClass(news : any){
    if(news?.NewsWebPublishedStatus?.Code === 'PUBLISHED') return 'hilight-green' 
    return  'hilight-orange'
  }

  confirmDeleteNews(news :any){
    this.app.confirm.confirmDialog("", this.app.translate.instant('fo.welcomeCommuniquePressPage.confirm-delete-fiche'), "AUTRE", "confirm", async () => {
      await this.deleteNews(news?.NewsID)
    }, () => {  })
  }

  async deleteNews(newsId : any){
    const response = await this.app.apiService.deleteNewsFo(newsId)
    this.mustReloadData.emit(response)
  }

  editNews(news: any) {
    this.app.router.navigate(["communique-press-detail"],
      { queryParams: { folderguid: news.FolderGuid, newsId: news?.NewsID } })
  }

  newsWebPublishedStatus(news: any, event: DropdownChangeEvent) {
    event.originalEvent.stopPropagation(); 
    let newsPublishStatus: any =  event.value;
    let body = {
      NewsDetailIDs: [news.NewsID],
      StatusID: newsPublishStatus?.StatusID
    };
    let msg = 'fo.welcomeCommuniquePressPage.'
    if (newsPublishStatus?.Code == StatusCode.UNPUBLISHED_NEWS) {
      msg += 'alert-change-publish-state-to-unpublish'
    } else if (newsPublishStatus?.Code == StatusCode.PUBLISHED_NEWS) {
      msg += 'alert-change-publish-state-to-publish'
    }

    this.app.confirm.confirmDialog("", this.app.translate.instant(`${msg}`), "AUTRE", "confirm", async () => {
      await this.updateNewsPublishStatus(body)
      // event.stopImmediatePropagation
    }, () => {  })

  }

  IsEditNewsButtonVisible(news : any){
    return !news.NewsWebPublishedStatus || news.NewsWebPublishedStatus?.Code != 'PUBLISHED'
  }


  async updateNewsPublishStatus(request: any) {
    try {
      const response = await this.app.apiService.ChangeNewsWebPublishedStatus(request);
      this.mustReloadData.emit(response)
      this.getStatus()
    } catch (error) {
      console.error(error);
    }
  }

  openPdf(news : any){
    window.open(news?.NewsFile?.DocUrl, "_blank");
  }

  getImgUrl(news: any): string {
    return (news
      && news.NewsImages 
      && Array.isArray(news.NewsImages) 
      && news.NewsImages.length > 0 
      && news.NewsImages[0] 
      && news.NewsImages[0].DocUrl)
    ? news.NewsImages[0].DocUrl
    : '/assets/images/communique-press/communique_presse_default_image.jpg'
  }
}
