import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { IdentityManagerService } from '../services/identity-manager.service';
import { RoleTypeId } from '../enums/role-type-id';

@Component({
  selector: 'app-products-carousel-helped',
  templateUrl: './products-carousel-helped.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./products-carousel-helped.component.less']
})
export class ProductsCarouselHelpedComponent  {
  @Input() products: any[] = [];
  @Input() numbers: any;
  listInnov: any[] = [];
  formHelpedID!: number;
  helpedForm: any;
  IsRoleWriter:boolean=false;
  user: any;


  responsiveOptions: any[] = [
      {
          breakpoint: '1199px',
          numVisible: 2,
          numScroll: 1 
      },
      {
          breakpoint: '991px',
          numVisible: 2,
          numScroll: 1
      },
      {
          breakpoint: '767px',
          numVisible: 1,
          numScroll: 1
      }
  ];

  constructor(
      public app: AppComponent,
      private ims : IdentityManagerService
  ) { }

  ngOnInit() {
      this.user = JSON.parse(sessionStorage.getItem('userData')!);
      this.app.sharedTools.getJSON('general_data').subscribe(
          resG => {

          });
      console.log("****this number", this.numbers)
      console.log(this.user.RoleID);
      if(this.ims.haveAuthorizedRole([RoleTypeId.HLP_Writer])
      && !this.ims.haveAuthorizedRole([RoleTypeId.DP_SuperAdmin, RoleTypeId.HLP_Administrator, RoleTypeId.HLP_Exhibitor])){
          this.IsRoleWriter=true;
       }
      }


  async getHelpedForm() {
      this.helpedForm = await this.app.apiService.getAllSolutionsHLP({ HlpFormID: this.formHelpedID });
      this.products = this.helpedForm?.Solutions ? this.helpedForm?.Solutions : [];
      

      //map other properties to product in products list (solutions)
       this.products.forEach((p: any) => {
      //     //this.mapSuppPropertiesToProduct(p);
       })

      
  }
}