import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';
import { IdentityManagerService } from '../services/identity-manager.service';
import { APPID } from '../enums/app_id';
import { MediasTypes } from '../enums/media-type';
import { MktCategory } from '../enums/mkt-category';
import { CategoryType } from '../enums/category-type';
import { environment } from 'src/environments/environment';
import { FrmMarketingCodes } from '../enums/form-mkt-codes';
import { FormCode } from '../enums/form-code';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-welcome-speaker-mkt',
  templateUrl: './welcome-speaker-mkt.component.html',
  styleUrls: ['./welcome-speaker-mkt.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class WelcomeSpeakerMktComponent implements OnInit {
  appID: any
  folderGuid: any
  user: any
  reloadGetUserInfo: boolean = false
  isLogo: boolean = false;
  isImage: boolean = false;

  logoSettings: {} = {};
  imageSettings: {} = {};

  isLoadLogoSettings: boolean = false;
  isLoadImageSettings: boolean = false;

  categories: any;
  bannierSpeakerSubCategories :any

  isUserInfoLoaded: boolean = false
  userInfo: any
  formID: any;
  menuTabs: any;

  folderID: any;
  activeIndex = 0

  canRecieveUserInfo: boolean = true

  urlApi = environment.urlApi;
  socialNetworkCatID : any
  mediaComponentsOrder : any = [MediasTypes.PHOTOID,MediasTypes.LOGO]
  catIdSocialNetwork : any
  frmData : any

  constructor(public app: AppComponent,
    private identityManagerService: IdentityManagerService) { }

  async ngOnInit() {

    this.activeIndex = this.checkActivePanelTabIndex();

    this.reloadGetUserInfo = true;

    await this.getMarketingSpeakerFrmInfo()

    await this.getMktCategories();
    await this.getMediaSettings();

    this.app.route.queryParams.subscribe((params: any) => {
      this.folderGuid = params['folderguid'];

      sessionStorage.setItem("mktFolderGuid", JSON.stringify(this.folderGuid));
    });

    this.identityManagerService.checkIsUserAuthenticated();
    this.user = cloneDeep(this.identityManagerService.currentUser);

    this.app.sharedTools.getJSON('general_data').subscribe(
      (resG: any) => {
        this.appID = APPID.Conf;
      });

    await this.createMktForm(this.folderGuid);
    await this.getUserInfo(this.formID);

  }

  private checkActivePanelTabIndex(): number {
    let index = sessionStorage.getItem('activeIndex');
    let activeIndex : number = 0;
    
    if (index) {
      activeIndex = JSON.parse(index);
    }else{
      sessionStorage.setItem('activeIndex', JSON.stringify(this.activeIndex));
    }

    console.log("🚀  activeIndex:", activeIndex)
    return activeIndex;

  }

  async onTabChange(event: any) {
    this.canRecieveUserInfo = true;
      this.activeIndex = await event.index;
  
      sessionStorage.setItem('activeIndex', JSON.stringify(this.activeIndex));
  
      this.canRecieveUserInfo = true;
  
     // await this.getUserInfo(this.formID);
  
      console.log("🚀  this.reloadGetUserInfo:", this.reloadGetUserInfo);
      // this.thankYouMessage = `Tab[${this.activeIndex}]`;
  
      console.log("🚀  onTabChange:", this.activeIndex);
  }

  private async createMktForm(guid: any) {
    let resp = await this.app.apiService.createMktForm(guid,FrmMarketingCodes.FRM_MARKETNG_SPEAKER);
    if (resp?.Result === "NOTFOUND") {
      this.showPopUp("errorInvalidGuid");
    } else {
      this.formID = resp?.FormMKT;
      this.folderID = resp?.FolderID;
      sessionStorage.setItem("mktFolderID", JSON.stringify(this.folderID));
      sessionStorage.setItem("mktFormID", JSON.stringify(this.formID));
    }
  }

  private showPopUp(errorMsg: any) {
    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.' + errorMsg), 'KO', 'alert', () => {
      this.app.router.navigate(['404']);
    }, () => { });
  }


  private async getMktCategories() {
    await this.getCategories();
  }

  async handleCanReloadGetUserInfo(event: boolean) {
    if (event === true) {
      await this.getUserInfo(this.formID);
    }

  }

  private async getCategories() {
    try {
      const response = await this.app.apiService.getCategories();

      let categoriesIni = cloneDeep(response);

      let speakerFormCategories = categoriesIni.filter((cat: any) => {
        return cat.CategoryType == CategoryType.SpeakerForm;
      });

      const filterCategories = cloneDeep(speakerFormCategories.filter((cat: any) => { return cat.ParentCategoryID == null; }));


      this.catIdSocialNetwork = filterCategories.filter((cat:any)=>{
        return cat?.Code == MktCategory.SOCIALNETWORK && cat?.CategoryType == CategoryType.SpeakerForm
      })[0]

      const filterbannierSpeakerSubCategories = cloneDeep(speakerFormCategories.filter((cat: any) => { 
        return cat.ParentCategoryID != null && cat.ParentCategoryID == this.catIdSocialNetwork?.CategoryID
      }));

      this.categories = this.sortByViewOrderAsc(filterCategories);
      this.socialNetworkCatID = this.categories?.filter((x:any)=> {return x.Code == MktCategory.SOCIALNETWORK})[0]?.CategoryID
      this.menuTabs = this.categories
      this.bannierSpeakerSubCategories = this.sortByViewOrderAsc(filterbannierSpeakerSubCategories);

    } catch (error) {
      console.error(error);
    }
  }

  private sortByViewOrderAsc(list: any){
    return list.sort((a:any,b:any) => {
      const catA = a.ViewOrder;
      const catB = b.ViewOrder;
      return catA - catB;
    });
  }

  async getMarketingSpeakerFrmInfo() {
    let request = { frmType: "" }
    request.frmType = FormCode.FRM_MARKETNG_SPEAKER
    let data = await firstValueFrom(this.app.apiService.getEditionInfo(request))
    if (data != undefined && data.Id != undefined) {
        this.frmData = data
    }
}

  private async getMediaSettings() {
    return this.app.apiService.getMediaSettings().subscribe({
      next: (resp) => {

        resp?.forEach(async (m: any) => {
          if (m.Code == MediasTypes.LOGO) {
            this.isLogo = true;
            let frmMarktetingSpeakerSettings = m?.Settings?.filter((x:any)=> x?.FormCode == FormCode.FRM_MARKETNG_SPEAKER)
            if(frmMarktetingSpeakerSettings && Array.isArray(frmMarktetingSpeakerSettings) && frmMarktetingSpeakerSettings?.length > 0){
              this.logoSettings = frmMarktetingSpeakerSettings[0];
            }
            this.isLoadLogoSettings = true;
          }

          if (m.Code == MediasTypes.IMAGE) {
            this.isImage = true;
            let frmMarktetingSpeakerSettings = m?.Settings?.filter((x:any)=> x?.FormCode == FormCode.FRM_MARKETNG_SPEAKER)
            if(frmMarktetingSpeakerSettings && Array.isArray(frmMarktetingSpeakerSettings) && frmMarktetingSpeakerSettings?.length > 0){
              this.imageSettings = frmMarktetingSpeakerSettings[0];
            }
            this.isLoadImageSettings = true;
          }

        });
      },
      error: (err) => {
        console.log("Error getMediaSettings", err);
      }
    });
  }

  async handleTriggerReloadEvent(event: boolean) {
    if (event == true || this.reloadGetUserInfo) {
      await this.getUserInfo(this.formID);
    }

    if (event != null) {
      this.canRecieveUserInfo = event;
    }
  }

  async getUserInfo(mktFormId: number) {
    let resp = await this.app.apiService.getUserInfo(mktFormId,CategoryType.SpeakerForm);
    this.userInfo = resp;
    this.isUserInfoLoaded = true;
  }
}
