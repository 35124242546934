import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { FOExternalPagesRoutes } from 'src/app/consts/fo-external-pages.routes';
import { ImageModelSuffix } from 'src/app/enums/image-model-suffix';
import { SharedtoolsService } from 'src/app/services/sharedtools.service';

@Component({
  selector: 'app-mini-card-solution-details',
  templateUrl: './mini-card-solution-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./mini-card-solution-details.component.less']
})
export class MiniCardSolutionDetailsComponent implements OnInit {

  @Input() solution: any;
  @Input() isResponsive: boolean = false;
  imageModelSuffix = ImageModelSuffix;

  constructor(public app: AppComponent, private sharedService: SharedtoolsService) {

  }
  ngOnInit(): void {

  }

  async GoToMap(location: any) {
    this.sharedService.goToMap(location)
  }

  openNewTab(url: string): void {
    window.open(url, "_blank")
  }

  async getDetail(exhiID: any) {
    let queryParams : any = {
      exhibitorID: exhiID
    };

    this.app.sharedTools.routeTo(FOExternalPagesRoutes.exhiDetail, queryParams);
  }

  goToExhibitor() {
    let exhiID = this.solution?.Prod_FK_Exhi_Id;
    let queryParams : any = {
      exhibitorID: exhiID
    };

    this.sharedService.routeTo(FOExternalPagesRoutes.exhiDetail, queryParams);
  }
}
