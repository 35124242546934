import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ImageModelSuffix } from 'src/app/enums/image-model-suffix';
import { Languages } from 'src/app/enums/language';
import { SharedtoolsService } from 'src/app/services/sharedtools.service';

@Component({
  selector: 'app-conf-details-mini-card',
  templateUrl: './conf-details-mini-card.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./conf-details-mini-card.component.less']
})
export class ConfDetailsMiniCardComponent implements OnInit {
  imageModelSuffix = ImageModelSuffix;
  @Input() conference : any;

  languages = Languages;
  selectedAccess : any
  access : any

  constructor(public app: AppComponent, private datePipe: DatePipe,private sharedService : SharedtoolsService) {

  }

  ngOnInit(): void {
    this.app.sharedTools.getJSON('general_data').subscribe(
      data => {
        this.access = data.accessTypes
      }
    )
  }

  getSelectedAccess(conference : any){
    if (conference?.IsPrivate == null) return null
    if(conference.IsPrivate == true){
      this.selectedAccess = this.access?.find((x:any)=> x.TypeId == 1)
    }else if(this.conference.IsPrivate == false ){
      this.selectedAccess = this.access?.find((x:any)=> x.TypeId == 2)
    }
    return this.selectedAccess
  }

  formatDayOfWeek(date: any) {
    return this.datePipe.transform(date, 'EEEE', undefined, this.app.translate.currentLang);
  }

  formatDayOfMonth(date: any) {
    return this.datePipe.transform(date, 'd MMMM', undefined, this.app.translate.currentLang);
  }

  getCurrentMonth(date: any) {
    return this.datePipe.transform(date, 'MM');
  }

  inscription() {

  }

  async GoToMap(location: any) {
    this.sharedService.goToMap(location)
  }

}
