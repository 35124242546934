import { Component } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-not-authorized-page',
  templateUrl: './not-authorized-page.component.html',
  styleUrls: ['./not-authorized-page.component.less']
})
export class NotAuthorizedPageComponent {
  appID: any = null;

  constructor(
    public app: AppComponent,
  ) {}

  ngOnInit() {
    this.app.sharedTools.getJSON('general_data').subscribe(
      resG => {
          console.log("resG", resG);
          this.appID = resG.APP_ID;
      });

  }
}
