import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { EmailRegex } from '../consts/utils';

@Component({
    selector: 'app-rappel-mdp',
    templateUrl: './rappel-mdp.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./rappel-mdp.component.less']
})
export class RappelMdpComponent {
    user: any;

    rappelMdpForm = new FormGroup({
        //Login: new FormControl('', [Validators.required]),
        Email: new FormControl('', [Validators.required, Validators.pattern(EmailRegex)]),
    });

    submitted = false;
    constructor(
        public app: AppComponent,
    ) { }

    get f() {
        return this.rappelMdpForm.controls;
    }

    ngOnInit(): void {
        // ==> On vérifie qu'il y a un.e user logué.e recupérant le sessionStorage
        setTimeout(() => {
            this.user = JSON.parse(sessionStorage.getItem('userData')!);

            if (this.user) {
                this.app.sharedTools.redirectTo('/');
            }
        }, 1);
    }

    onSubmit() {
        console.log("this.rappelMdpForm.value ==> ", this.rappelMdpForm.value);

        this.submitted = true;

        // Stop here if form is invalid
        if (this.rappelMdpForm.invalid) {
            //this.app.confirmDialogService.confirmThis("", this.communTextes.errorForm, "alert", "", () => { }, () => { })
            this.app.confirm.confirmDialog(this.app.translate.instant('racine.errorsOther.errorForm'), this.app.translate.instant('racine.errorsOther.errorFormRappelMdp'), 'KO', "alert", () => { }, () => { });
            return;
        }
        
        let dataUser: any = {
            Login: this.rappelMdpForm.value.Email,
            Email: this.rappelMdpForm.value.Email,
            Language: this.app.translate.currentLang.toUpperCase(),
        }

        // ==> Call Api sendLinkResetPassword
        this.sendLinkMdp(dataUser);
    }

    async sendLinkMdp(_user: any) {
        try {
            const response = await this.app.apiService.sendPasswordChoiceEmail(_user);
            console.log("sendLinkMdp ==> ", response);


            if (response && response.Result) {
                this.app.confirm.confirmDialog(this.app.translate.instant('fo.login.title'), this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.app.confirm.confirmDialog("", this.app.translate.instant('fo.login.emailEnvoye'), 'OK', "alert", () => { 
                this.app.sharedTools.redirectTo('/login'); 
            }, () => { });
            return;
        }
        catch (error) {
            console.error(error);
        }
    }
}
