import { Component, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dialog.component.less']
})
export class DialogComponent {
  modalInfo:any;
  constructor(
    public app: AppComponent,
  ) { }

  ngOnInit(): void {
    
    this.app.confirm.getModalInfo().subscribe((modalInfo:any) => {
      console.log("DialogComponent");
      this.modalInfo = modalInfo;
      console.log("this.modalInfo ==> ", this.modalInfo);
    });
  }
}
