import { Component, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { MessageService } from 'primeng/api';
import { SearchUser } from '../models/search-user';
import { cloneDeep } from 'lodash';
import { firstValueFrom, Subscription } from 'rxjs';
import { Menu } from 'primeng/menu';
import { Table, TableFilterEvent } from 'primeng/table';
import { UserSearchHelperService } from '../services/user-search-helper.service';
import { hasAnyNonNullProperty, UserSearchLocalStorageKey } from '../consts/utils';
import { DatePipe } from '@angular/common';
import { HelperService } from '../services/helper.service';
import { SearchHelperService } from '../services/search-helper.service';
import { PagePaginationInfo } from '../models/page-pagination-info';

@Component({
    selector: 'app-gestion-user',
    templateUrl: './gestion-user.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./gestion-user.component.less']
})
export class GestionUserComponent implements OnDestroy {
    private namescompleteSubscription?: Subscription;
    private usersExportSubscription?: Subscription;

    @ViewChild('menuAction') menuAction!: Menu;
    @ViewChild('dtUsers') dtUsers!: Table;
    @ViewChild('dvUsers') dvUsers!: DataView;

    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }

    set selectedColumns(val: any[]) {
        // ==> restore original order
        this._selectedColumns = this.colsUsers.filter((col: any) => val.includes(col));
    }
    _selectedColumns: any = [];
    fastSearchUserKeyword: string = '';
    showAdvancedSearch: boolean = false;
    search: any = new SearchUser();
    showTable: boolean = true;
    roles: any[] = [];
    roleList: { RoleID: number }[] = [];
    users: any[] = [];
    colsUsers: any[] = [];
    items: any = [];
    selectedUsers: any[] = [];
    fastFiltersUsers: any = ['LastName', 'FirstName', 'Email', 'MobilePrefix', 'Mobile', 'Phone', 'PhonePrefix'];
    userEtats: any[] = [];
    LastNameSuggestions: string[] = [];
    filteredUsers: any[] = [];

    currentPageTable : number = 1
    currentPageIndex : number = 1
    rowsCapacity : number = 50
    pagePaginationInfo : PagePaginationInfo = {pageNumber : 1, rowSize : 50}
    isLoaded : boolean = false 

    constructor(
        public app: AppComponent,
        private datePipe: DatePipe,
        public helperService: HelperService,
        private searchHelperService : SearchHelperService
    ) { }

    async ngOnInit() {
        //get privious advanced search value before routing to other routes
        this.search =cloneDeep(this.searchHelperService.getAdvancedSearch(UserSearchLocalStorageKey))
        if(!this.search){
            this.search = new SearchUser()
        }
        this.pagePaginationInfo = this.searchHelperService.getPagePaginationInfo(UserSearchLocalStorageKey)
        this.rowsCapacity = this.pagePaginationInfo.rowSize ?? 50
        // this.fastSearchUserKeyword = this.userSearchHelperService.fastSearchKeyword;

        this.app.sharedTools.getJSON('general_data').subscribe(
            async resG => {
                console.log("resG", resG);
                this.userEtats = cloneDeep(resG.userEtats);
                console.log("this.userEtats", this.userEtats);
                this.items = resG.actionsUsersRow;

                this.items.forEach((itemMenu: any) => {
                    itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
                });
                this.colsUsers = resG.colsUsers;
                this._selectedColumns = this.colsUsers;
                console.log("-----------", this._selectedColumns, "----------")
                await this.getRoles();
                this.initAdvancedSearch()
                this.searchUsers(this.search, true, true);
            });

    }

    pageChanged(event : any){
        let pageIndex = event.first/event.rows + 1
        this.currentPageIndex = pageIndex
        this.updatePaginationInfo(UserSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
    }
    rowsChanged(event : any){
        this.rowsCapacity = event
        this.updatePaginationInfo(UserSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
    }

    updatePaginationInfo(key : string, currentPageIndex: number, rowCapacity : number){
        let pagePaginationInfo : PagePaginationInfo={
            pageNumber : currentPageIndex,
            rowSize : rowCapacity
        }
        this.searchHelperService.setPagePaginationInfo(pagePaginationInfo,key)
    }



    onSearchName(event: any): void {
        let keyword: string | undefined = event.query;

        if (!keyword || keyword === '') {
            this.LastNameSuggestions = [];
        }
        else {
            this.refreshNamesAutocomplete(keyword);
        }
    }
    filterFast() {
        // this.userSearchHelperService.fastSearchKeyword = this.fastSearchUserKeyword;
        console.log("_value", this.fastSearchUserKeyword);

        this.dtUsers.filterGlobal(this.fastSearchUserKeyword, 'contains');
    }

    clearFastSearch(): void {
        this.fastSearchUserKeyword = '';
        this.filterFast();
    }

    toggleAdvancedSearch() {
        this.showAdvancedSearch = !this.showAdvancedSearch;
    }

    exportUsersToCsv() {
        this.UsersExport();
    }

    async getRoles() {
        let data = await firstValueFrom(this.app.apiService.getRoles())
            let roleNameFieldName = this.app.sharedTools.getLangPropertyName("Label")
            console.log("getRoles ==> ", data);
            this.roles = data?.sort((a: any, b: any) => {
                const roleNameA = a[roleNameFieldName].toLowerCase();
                const roleNameB = b[roleNameFieldName].toLowerCase();
                if (roleNameA < roleNameB) {
                    return -1;
                } else if (roleNameA > roleNameB) {
                    return 1;
                } else {
                    return 0;
                }
            });
    }

    onchangeSelect(_item: any) {
        if (!_item) return;
        console.log("onchangeSelect", _item);
    }

    compareByRoleName(a: any, b: any): number {
        console.log(this.app.sharedTools.getLangPropertyName("Label"))
        const roleNameA = a[this.app.sharedTools.getLangPropertyName("Label")].toLowerCase();
        const roleNameB = b[this.app.sharedTools.getLangPropertyName("Label")].toLowerCase();

        if (roleNameA < roleNameB) {
            return -1;
        } else if (roleNameA > roleNameB) {
            return 1;
        } else {
            return 0; // names are equal
        }
    }


    clearSearch() {
        console.log("clearSearch ==>");
        this.search = new SearchUser();
        this.searchUsers(this.search);
        this.clearFastSearch();
    }

    async searchUsers(search: any, thenFilterFast: boolean = false, goLatestViewedPage : boolean = false) {
        //save search to retrieve it when coming back to BO after redirect from BO
        this.searchHelperService.setAdvancedSearch(search, UserSearchLocalStorageKey)

        console.log("search ==> ", search);
        let searchTemp: any = cloneDeep(search);

        if (searchTemp.selectedRoles) {
            console.log("rolesId ==> ", searchTemp.selectedRoles);

            let rolesId: string = "";
            searchTemp.selectedRoles.forEach((role: any, index: any) => {
                rolesId += role.RoleID.toString();
                if (index != (searchTemp.selectedRoles.length - 1)) {
                    rolesId += ","
                }
            });
            console.log("rolesId ==> ", rolesId);

            searchTemp.RoleID = rolesId;
        }

        //if (searchTemp.selectedRoleID) searchTemp.RoleID = searchTemp.selectedRoleID.RoleID;
        if (searchTemp.selectedEtat) searchTemp.Active = searchTemp.selectedEtat.Active;

        if (searchTemp.selectedRoles) delete searchTemp.selectedRoles;
        //if (searchTemp.selectedRoleID) delete searchTemp.selectedRoleID;
        if (searchTemp.selectedEtat) delete searchTemp.selectedEtat;
        console.log("searchTemp ==> ", searchTemp);

        try {
            const response = await this.app.apiService.searchUsers(searchTemp);
            console.log("searchUsers==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { this.app.sharedTools.redirectTo('/users'); }, () => { })
                return;
            }

            this.users = cloneDeep(response)
            console.log("users", this.users);

            setTimeout(() => {
                this.users.forEach((user: any) => {
                    user.DateCreatedFormat! = this.datePipe.transform(user.DateCreated, 'dd/MM/yyyy HH:mm:ss');
                    user.DateLastLoginFormat! = this.datePipe.transform(user.DateLastLogin, 'dd/MM/yyyy HH:mm:ss');

                    if (this.fastFiltersUsers.indexOf('DateCreatedFormat') == -1) this.fastFiltersUsers.push("DateCreatedFormat");
                    if (this.fastFiltersUsers.indexOf('DateLastLoginFormat') == -1) this.fastFiltersUsers.push("DateLastLoginFormat");

                    user.selectedRoles = [];
                    user.Roles = "";

                    if (user.RoleID) {
                        let listRoles: any = user.RoleID.split(',').map((roleId: string) => {
                            return { RoleID: parseInt(roleId) };
                        });
                        user.selectedRoles = this.roles.filter((sourceElement: any) =>
                            listRoles.some((cibleElement: any) => cibleElement.RoleID === sourceElement.RoleID)
                        );
                    }

                    //user.selectedRoleID = this.roles.filter((role: any) => { return role.RoleID == user.RoleID })[0];
                    user.selectedActive = this.userEtats.filter((etat: any) => { return etat.Active === user.Active })[0];

                    if (user.selectedRoles.length > 0) {
                        user.selectedRoles.forEach((userRole: any) => {
                            this.app.sharedTools.getLabelSwitchLangByID(userRole.RoleID, 'RoleID', this.roles);
                            this.app.sharedTools.getLabelSwitchLang(userRole);
                            user.Roles += userRole.label + ' ';

                            if (this.fastFiltersUsers.indexOf('Roles') == -1) this.fastFiltersUsers.push("Roles");
                        });
                    }

                    // if (user.selectedRoleID) {
                    //     this.app.sharedTools.getLabelSwitchLang(user.selectedRoleID);
                    //     if (this.fastFiltersUsers.indexOf('selectedRoleID.label') == -1) this.fastFiltersUsers.push("selectedRoleID.label");
                    // }

                    if (user.selectedActive) {
                        this.app.sharedTools.getLabelSwitchLang(user.selectedActive);
                        if (this.fastFiltersUsers.indexOf('selectedActive.label') == -1) this.fastFiltersUsers.push("selectedActive.label");
                    }
                });


                if(goLatestViewedPage){
                    this.currentPageTable = ((this.pagePaginationInfo.pageNumber ?? 1) - 1) * (this.pagePaginationInfo.rowSize ?? 1);
                }
                this.isLoaded = true

                if (thenFilterFast) {
                    this.filterFast();

                    if (hasAnyNonNullProperty(this.search)) {
                        console.log("hasAnyNonNullProperty", hasAnyNonNullProperty(this.search))
                        //this.initAdvancedSearch();
                        this.showAdvancedSearch = true;
                    }
                }
                console.log("after users", this.users);
            }, 100);

        } catch (error) {
            console.error(error);
        }



    }

    onRowSelect(event: any) {
        console.log("event.data==>", event.data);
        console.log("selectedUsers==>", this.selectedUsers);

    }

    getActionsMenuByUser(user: any): any {
        return this.items.filter((item: any) => item.ActionID);

    }
    toggleMenu(event: any, UserID: number, items: any) {
        this.menuAction.toggle(event);

        console.log("User ID:", UserID);
        console.log("items:", items);

        items.forEach((itemMenu: any) => {
            itemMenu.userIdRow = UserID;
            itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

            if (itemMenu.ActionID == 1) {
                itemMenu.command = (event: any) => {
                    this.app.sharedTools.redirectTo('/detail-user/' + event.item.userIdRow.toString());
                }
            }

            if (itemMenu.ActionID == 2) {
                itemMenu.command = (event: any) => {
                    this.app.sharedTools.redirectTo('/vm-prog-demandes/' + event.item.userIdRow.toString());
                }
            }
        });
    }


    async onchangeEtat(_user: any) {
        console.log("onchangeEtat user ==> ", _user);
        //if (!_user.selectedActive) return;

        let objStatus: any = {
            Active: _user.Active,
            UserID: _user.UserID
        }

        console.log("objStatus ==> ", objStatus);

        try {
            const response = await this.app.apiService.changeActive(objStatus);
            console.log("apiService.changestatus ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            // this.userEtats();
            this.searchUsers(this.search, true, true);

        } catch (error) {
            console.error(error);
            return;

        }
    }

    ngOnDestroy(): void {
        this.unsubscribeActiveSubscriptions();
    }

    //#region private methods
    private unsubscribeActiveSubscriptions(): void {
        if (this.usersExportSubscription) {
            this.usersExportSubscription.unsubscribe();
        }

        if (this.namescompleteSubscription) {
            this.namescompleteSubscription.unsubscribe();
        }
    }
    private refreshNamesAutocomplete(keyword: string): void {
        this.namescompleteSubscription = this.app.apiService.userNamesAutocomplete(keyword).subscribe({
            next: (data: any) => {
                if (data && data != '' && Array.isArray(data)) {
                    this.LastNameSuggestions = data;
                }
                else {
                    this.LastNameSuggestions = []
                }
            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }
    private UsersExport(): void {
        this.usersExportSubscription = this.app.apiService.usersExport({}).subscribe({
            next: (data: any) => {
                console.log('UsersExport', data);
                if (data) {
                    let filesUrl: string = data;

                    window.open(filesUrl);
                }

            },
            error: (error: any) => {
                console.log(error);
            }
        });
    }

    exportCSV() {
        this.helperService.exportCSV(this.filteredUsers, this.mapColumn(), 'users_export_' + new Date().toLocaleDateString(), true);
    }
    exportExcel() {
        this.helperService.exportExcel(this.filteredUsers, this.selectedColumns, 'users_export_' + new Date().toLocaleDateString(), false);
    }

    private initAdvancedSearch(): void {
        //let findSelectedRole = this.roles.find((c: any) => c.RoleID === this.search.selectedRoleID?.RoleID);

        let findSelectedRoles = (this.search.selectedRoles && this.search.selectedRoles.length > 0) ? this.roles.filter(sourceElement =>
            this.search.selectedRoles.some((cibleElement: any) => cibleElement.RoleID === sourceElement.RoleID)
        ) : [];
        let findSelectedEtat = this.userEtats.find((c: any) => c.EtatID === this.search.selectedEtat?.EtatID);

        //this.search.selectedRoleID = findSelectedRole;
        this.search.selectedRoles = findSelectedRoles;
        this.search.selectedEtat = findSelectedEtat;
    }
    //this.helperService.exportCSV(this.folders, this.mapColumn(),'vm_exposants_export_' + new Date().toLocaleDateString(),true);


    mapColumn() {
        console.log(this.selectedColumns);
        console.log(this.filteredUsers);
        return this.filteredUsers.map((row: any) => {
            const filteredColumns: any = {};
            this.selectedColumns.forEach(column => {
                switch (column.field) {
                    case "LastName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.LastName ?? '';
                        break;
                    case "FirstName": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.FirstName ?? '';
                        break;
                    case "Email": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Email ?? '';
                        break;
                    case "Mobile": {
                        let mobileText: string = "";
                        if (row.Mobile) {
                            mobileText += `${row.MobilePrefix} ${row.Mobile}`
                        }
                        if (row.Phone) {
                            mobileText += ` ${row.PhonePrefix} ${row.Phone}`
                        }
                        filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = mobileText;
                    }
                        break;
                    case "Active": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(this.userEtats.find((e: any) => e.Active == row.Active)) ?? ''
                        break;
                    case "DateCreated": {
                        let dateText: string = "";
                        if (row.DateLastLogin) {
                            dateText += `${this.app.translate.instant('racine.generalites.lastconnection')} ${this.datePipe.transform(row.DateLastLogin, 'dd/MM/yyyy HH:mm:ss')}`
                        }
                        if (row.DateCreated) {
                            dateText += ` ${this.app.translate.instant('racine.generalites.dateCreation')} ${this.datePipe.transform(row.DateCreated, 'dd/MM/yyyy HH:mm:ss')}`
                        }
                        filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = dateText;
                    }
                        break;

                    default:
                        break;
                }
            });
            return filteredColumns;
        })
    }

    onFilter(event: TableFilterEvent) {
        console.log('onFilter ', event);
        this.filteredUsers = event.filteredValue ?? [];
    }

    async autoLoginUser(user : any){
        var result = await this.app.apiService.getUserAutoLoginUrl(user)
        window.open(result,"_blank")
    }
}
