import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { SharedtoolsService } from 'src/app/services/sharedtools.service';
import { IdentityManagerService } from 'src/app/services/identity-manager.service';
import { ConferenceStoreService } from 'src/app/services/conference-store.service';
import { FOExternalPagesQueryParamsNames, FOExternalPagesRoutes } from 'src/app/consts/fo-external-pages.routes';

@Component({
  selector: 'app-conf-details',
  templateUrl: './conf-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './conf-details-global.component.less',
    './conf-details.component.less',
    './conf-details-responsive.component.less'
  ]
})
export class ConfDetailsComponent implements OnInit {

  user: any = null;
  welcomeObj: any = null;
  paths = location.pathname.split('/');
  folderGuid: any;
  innovform: any;
  speaker: any;
  conference: any;
  currentPath: any = null;
  IsValidUrl: boolean = false;

  DayConference: any;
  interests: any;
  speakers: any;
  exhibitors: any;
  isEnabled: boolean = false;
  calendarObj: any;
  Description: string = '';
  Location: string = '';
  Summary: string = '';
  IsfileIsc: boolean = false;
  isActive: boolean = false;
  currentMonth: number = 1;
  tagsMaxLenght: number = 3;
  notDisplayedTags: string[] = [];

  tagsArray: string[] = [];
  notDisplayedTagsArray: string[] = [];
  dividerColor!: string;

  screenWidth: number = 0;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  isLargeScreen: boolean = false;
  isMinScreen: boolean = true;

  isExtraSmall: boolean = false;
  isSmall: boolean = false;
  isMedium: boolean = false;

  receivedData: any;

  isFirstElem: boolean = false;
  isLastElem: boolean = false;
  eventID: any;

  constructor(
    public app: AppComponent,
    private datePipe: DatePipe,
    private sharedService: SharedtoolsService,
    private router: Router,
    //private _conferenceService: ConferenceService,
    protected ims: IdentityManagerService,
    protected conferenceStore: ConferenceStoreService
  ) {
    this.screenWidth = window.innerWidth;
  }

  async ngOnInit() {

    this.onResize();

    this.app.route.queryParams.subscribe((params) => {
      if (params && params[FOExternalPagesQueryParamsNames.confDetail.conferenceID]) {
        this.eventID = params[FOExternalPagesQueryParamsNames.confDetail.conferenceID];
        this.retrieveEvent();
      }
    });
  }

  private retrieveEvent(): void{
    this.getConfByEventID(this.eventID);
    this.getLastOrFirstElement();
  }

  getLastOrFirstElement() {
    this.isFirstElem = this.conferenceStore.isFirstConfEvent(this.eventID);
    this.isLastElem = this.conferenceStore.isLastConfEvent(this.eventID);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 375) {
      this.isExtraSmall = true;
      this.isMobile = true;
      this.isDesktop = false;
    } else if (this.screenWidth <= 995) {
      this.isMobile = true;
      this.isDesktop = false;
      this.isExtraSmall = false;
    } else {
      this.isMobile = false;
      this.isDesktop = true;
      this.isExtraSmall = false;
    }

    // this.screenWidth = window.innerWidth;
    // console.log("🚀  this.screenWidth:", this.screenWidth)

    // if (this.screenWidth <= 375) {
    //   this.isExtraSmall = true;
    //   this.isSmall = false;
    //   this.isMedium = false;
    // } else if (this.screenWidth > 375 && this.screenWidth <= 390) {
    //   this.isSmall = true;
    //   this.isExtraSmall = false;
    //   this.isMedium = false;
    // } else {
    //   this.isMedium = true;
    //   this.isExtraSmall = false;
    //   this.isSmall = false;
    // }
  }

  goBack() {
    this.app.location.back();
  }

  goPrevious() {
    if (!this.isFirstElem) {
      let queryParams: any = {
        eventID: this.conferenceStore.previousConfEvent(this.eventID)
      };

      this.app.sharedTools.routeTo(FOExternalPagesRoutes.confDetail, queryParams);
    }
  }

  goNext() {
    if (!this.isLastElem) {
      let queryParams: any = {
        eventID: this.conferenceStore.nextConfEvent(this.eventID)
      };

      this.app.sharedTools.routeTo(FOExternalPagesRoutes.confDetail, queryParams);
    }
  }

  async getConfByEventID(confEventID: any) {
    const response = await this.app.apiService.getConfByEventId(confEventID)
    this.conference = response;
    if (this.conference?.CnfdTags) {
      this.tagsArray = this.conference.CnfdTags.split('|');
    } else {
      this.tagsArray = [];
    }
    for (let i = this.tagsMaxLenght; i < this.tagsArray.length; i++) {
      this.notDisplayedTags.push(this.tagsArray[i]);
    }
    // this.dividerColor = this.conference?.CodeColor || '#000000';
    this.dividerColor = this.conference?.CodeColor || '#3f3f3f';
    this.conferenceStore.currentConferenceDate = this.conference?.ConferenceEvent?.CnfeHourStart;
  }


  formatDateWithLanguage(date: any) {
    return this.datePipe.transform(date, 'fullDate', undefined, this.app.translate.currentLang);
  }

  formatDayOfWeek(date: any) {
    return this.datePipe.transform(date, 'EEEE', undefined, this.app.translate.currentLang);
  }

  formatDayOfMonth(date: any) {
    return this.datePipe.transform(date, 'd', undefined, this.app.translate.currentLang);
  }

  isImageUrl(url: string) {
    let isValidUrl: boolean = false;

    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const extension = url.substring(url.lastIndexOf('.')).toLowerCase();

    isValidUrl = imageExtensions.includes(extension);

    return isValidUrl;
  }

  speakerDetail(contactID: any): void {
    const queryparams = {
      speakerID: contactID
    }

    this.app.sharedTools.routeTo(FOExternalPagesRoutes.speakerDetail, { queryParams: queryparams });
  }

  async AddToCalendar(confEventID: any): Promise<void> {
    this.IsfileIsc = true;
    console.log(confEventID);
    let calendarData: string = '';
    const response = await this.app.apiService.getConfByEventId(confEventID)
    this.calendarObj = response;
    console.log("calendarObj", this.calendarObj);
    if (this.calendarObj) {
      if (this.app.translate.currentLang == 'en') {
        this.Description = this.calendarObj?.CnfdDescriptionEN;
        this.Location = this.calendarObj?.ConferenceEvent?.CatgeoryEventsDetails?.LabelEN;
        if (this.calendarObj?.ConferenceProgram?.CnfprNameEN != null && this.calendarObj?.ConferenceProgram?.CnfprNameEN != '') {
          this.Summary = this.calendarObj?.ConferenceProgram?.CnfprNameEN + ' : ' + this.calendarObj?.CnfdNameEN

        } else {
          this.Summary = this.calendarObj?.CnfdNameEN
        }
      }
      else if (this.app.translate.currentLang == 'fr') {
        this.Description = this.calendarObj?.CnfdDescriptionFR;
        this.Location = this.calendarObj?.ConferenceEvent?.CatgeoryEventsDetails?.LabelFR;
        if (this.calendarObj?.ConferenceProgram?.CnfprNameFR != null && this.calendarObj?.ConferenceProgram?.CnfprNameFR != '') {
          this.Summary = this.calendarObj?.ConferenceProgram?.CnfprNameFR + ' : ' + this.calendarObj?.CnfdNameFR

        }
        else {
          this.Summary = this.calendarObj?.CnfdNameFR
        }

      }
      else if (this.app.translate.currentLang == 'es') {
        this.Description = this.calendarObj?.CnfdDescriptionES;
        this.Location = this.calendarObj?.ConferenceEvent?.CatgeoryEventsDetails?.LabelES;
        if (this.calendarObj?.ConferenceProgram?.CnfprNameES != null && this.calendarObj?.ConferenceProgram?.CnfprNameES != '') {
          this.Summary = this.calendarObj?.ConferenceProgram?.CnfprNameES + ' : ' + this.calendarObj?.CnfdNameES

        } else {
          this.Summary = this.calendarObj?.CnfdNameES
        }
      }
      calendarData = [
        'data:text/calendar;charset=utf8,',
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'DESCRIPTION:' + this.Description,
        'DTSTART:' + this.calendarObj?.ConferenceEvent?.CnfeHourStart,
        'DTEND:' + this.calendarObj?.ConferenceEvent?.CnfeHourEnd,
        'LOCATION:' + this.Location,
        'SUMMARY:' + this.Summary,
        'TRANSP:TRANSPARENT',
        'END:VEVENT',
        'END:VCALENDAR',
        'UID:' + this.calendarObj?.ConfDetailID,
        'DTSTAMP:' + this.calendarObj?.ConferenceEvent?.Duration,
        'PRODID:website-1.0'
      ].join('\n');
      console.log(calendarData);
      this.downloadCalendar(calendarData);
    }

    this.IsfileIsc = false;
  }

  downloadCalendar(data: any) {
    let dtStart = this.calendarObj?.ConferenceEvent?.CnfeHourStart;
    let formatedDtStart = this.datePipe.transform(dtStart, 'dd/MM/yyyy');

    let fileName = formatedDtStart?.toString();

    var link = document.createElement("a");

    link.setAttribute("href", data);
    link.setAttribute("download", fileName!);
    link.click();

  }


  onToggleOverlay(event: any, op: any, targetEl: any) {
    //if (!this.isCompositionFormEditMode) {
    op.toggle(event, targetEl)
    //}
  }

  GoToMap(location: any) {
    this.sharedService.goToMap(location)
  }

  isBetween(text: string, min: number, max: number) {
    return text.length > min && text.length < max;
  }

  isMax(text: string, max: number) {
    return text.length <= max;
  }

  isGreaterThen(text: string, max: number) {
    return text.length >= max;
  }

}
