import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { Languages } from '../enums/language';

@Component({
    selector: 'app-welcome-steps-numbers',
    templateUrl: './welcome-steps-numbers.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./welcome-steps-numbers.component.less']
})
export class WelcomeStepsNumbersComponent {
    stepsWelcome: any = [];
    @Input() videoName : string | undefined
    @Input() stepsWelcomeObject : any

    constructor(
        public app: AppComponent,
    ) { }

    ngOnInit() {
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                // console.log("resG ==> ", resG);
                this.stepsWelcome = resG[this.stepsWelcomeObject]?.filter((x:any)=>x.isEnabled == "true");                
            });

    }

    getVideoSourceSwitchLang(){
        if(this.app.translate.currentLang == Languages.French){
            return `/assets/videos/${this.videoName}-fr.mp4`
        }
        if(this.app.translate.currentLang == Languages.Spanish){
            return `/assets/videos/${this.videoName}-es.mp4`
        }
        return `/assets/videos/${this.videoName}-en.mp4`;
    }
}
