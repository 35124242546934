import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { ProductInnov } from '../models/product-innov';
import { TreeNode } from 'primeng/api';
import { cloneDeep, forEach } from 'lodash';
import { MediasTypes } from '../enums/media-type';

@Component({
    selector: 'app-content-recap-innov',
    templateUrl: './content-recap-innov.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./content-recap-innov.component.less']
})
export class ContentRecapInnovComponent {
    paths = location.pathname.split('/');
    currentPath: any = null;
    user: any = null;
    @Input() product: any;// = new ProductInnov();
    document: any;
    isReadOnly: boolean = true;
    timelineSteps: any = [];
    TLRLaderObj: any = null;
    formInnovID!: number;
    steps: any;
    isEditMode: boolean = false;
    @Input() productId: any;

    categories: any = [];
    tree: any = {};
    orderedCategories!: TreeNode[];
    otherCategories: any[] = [];

    mediaTypes = MediasTypes;

    // #region Video verification
    isVideo: boolean = false;
    isYoutubeVideo: boolean = false;
    videoUrl: any;
    @Input() showEditButtons: boolean = false;
    // #endRegion

    constructor(
        public app: AppComponent,
    ) {
        //this.getFormInnovData();
    }

    async ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log(this.user);
        if (!this.user) {
            this.app.router.navigate(['/login']);
            return;
        }

        this.app.route.queryParams
            .subscribe(params => {
                console.log('[params]', params);
                if (params && params['productid']) {
                    this.productId = params['productid'];
                }
            });

        //get otherCategories from general_data json
        //get from general_data json
        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                this.otherCategories = resG.otherCategories;
                this.timelineSteps = resG.timelineSteps;

                // ==> 
                this.currentPath = this.paths[1];
            });
            

            await this.getCategories();

            if (this.productId) {
                this.isEditMode = true;
                this.getProduct(this.productId);
                console.log("this.productId ==> ", this.productId);
            }
            else {
                this.isEditMode = false;

            }
    }

    async getCategories() {
        try {
            const response = await this.app.apiService.getCategories();

            let categoriesIni = cloneDeep(response);

            let categories = categoriesIni.filter((cat: any) => {
                return cat.CategoryType == "ACTIVITIES"
            });

            this.categories = cloneDeep(categories);

        } catch (error) {
            console.error(error);
        }
    }

    private buidlOrderedCategoriesTree(categories: any[]): TreeNode[] {
        //map les categories manquantes
        let requiredCategories = [...categories];

        this.categories.forEach((c: any) => {
            let findCategoryAsParent = categories
                .find((sc: any) => sc.ParentCategoryID && sc.ParentCategoryID === c.CategoryID);

            let findCategory = categories
                .find((sc: any) => sc.CategoryID && sc.CategoryID === c.CategoryID);

            if (findCategoryAsParent && !findCategory) {
                requiredCategories.push(c);
            }
        });
        //

        requiredCategories = requiredCategories.sort((a: any, b: any) => { return b.Code > a.Code ? -1 : 1; });

        let tree: any = {};
        let orderedCategories!: TreeNode[];

        requiredCategories.forEach((cat: any) => {
            const parentId = cat.ParentCategoryID;

            if (!tree[parentId]) {
                tree[parentId] = [];
            }
            tree[parentId].push(cat);
        });

        orderedCategories = this.buildTree(tree, null);
        
        return orderedCategories;

    }


    async updateInnov(productObj: any): Promise<any> {
        try {
            console.log('UPDATE Product ===>', productObj);
            const response = await this.app.apiService.updateProduct(productObj);
            console.log('RESPONSE UPDATE Product ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                }, () => {
                })
                return;
            }

            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successproduitUpdt'), 'OK', "alert", () => {
                this.app.sharedTools.redirectTo(`/product-recapitulatif/${this.productId}`);
            }, () => { });

        } catch (error) {
            console.error(error);
        }
    }
    private buildTree(tree: any, parentId: any) {
        const children = tree[parentId] || [];

        return children.map((child: any) => {
            const node = {
                key: child.CategoryID,
                label: this.app.sharedTools.getLabelSwitchLang(child),
                icon: '',
                parent: parentId,
                expanded: false,
                children: this.buildTree(tree, child.CategoryID),
                data: child.Code
            };
            return node;
        });
    }

    getOrderedCategoriesTree(product: any): TreeNode[] {
        let cats = product?.Categories ?? [];
        let selectedCategories =
            this.categories.filter((c: any) => cats.indexOf(c.CategoryID) >= 0) ?? [];

        if (this.otherCategories && this.otherCategories.length > 0 && product?.OtherCategory && product?.OtherCategory != '') {
            this.otherCategories[1].LabelFR = product?.OtherCategory;
            this.otherCategories[1].LabelEN = product?.OtherCategory;
            this.otherCategories[1].LabelES = product?.OtherCategory;

            selectedCategories = [...selectedCategories, ...this.otherCategories];
        }

        let orderedCategoriesTree = this.buidlOrderedCategoriesTree(selectedCategories);
        if (this.otherCategories && this.otherCategories.length > 0) {
            this.otherCategories[1].LabelFR = "";
            this.otherCategories[1].LabelEN = "";
            this.otherCategories[1].LabelES = "";
        }

        console.log('orderedCategories ', orderedCategoriesTree);
        return orderedCategoriesTree;
    }

    private getFormInnovData(): void {
        let innovform: any = JSON.parse(sessionStorage.getItem('innov-form') ?? '{}');

        if (innovform) {
            this.formInnovID = innovform.InnoFormID;
        }
    }

    async getProduct(solId: any) {
        let response = await this.app.apiService.getProductById(solId);
        this.product = response
        this.document = response.Documents;
        console.log("Document : ------> ", this.document);
        //this.product.mapFormGetResponse(response)

        console.log('this.product : ', this.product);

        // this.videoUrl = this.product.Documents[0].DocUrl;
        // console.log('>>> DOC URL : ', this.videoUrl);

        if (this.product.TlrLader && this.product.TlrLader > 0) {
            this.TLRLaderObj = this.app.sharedTools.getObjById(this.product.TlrLader, 'index', this.timelineSteps);
        }

        this.document.forEach((doc: any) => {
            // console.log('>>> DOC URL : ', doc.DocUrl);
            let isYtb = this.isYtbVideo(doc.DocUrl);
            if (isYtb == true) {
                console.log('>>> DOC URL : ', doc.DocUrl);
                this.isYoutubeVideo = true;
                this.videoUrl = this.generateEmbedUrl(doc.DocUrl);
            }
        });
        
        this.orderedCategories = this.getOrderedCategoriesTree(this.product);
    }

    onEditGeneralites() {
        let queryparams: any = {
            productid: this.productId
        }
        this.app.sharedTools.redirectToWithParameters(`product-generalites/edit`, queryparams);
        //this.app.sharedTools.goTo(`/product-generalites/${this.productId}`)
    }

    onEditDescription() {
        let queryparams: any = {
            productid: this.productId
        }
        this.app.sharedTools.redirectToWithParameters(`product-description/edit`, queryparams);
        //this.app.sharedTools.goTo(`/product-description/${this.productId}`)
    }

    onEditMedias() {
        let queryparams: any = {
            productid: this.productId
        }
        this.app.sharedTools.redirectToWithParameters(`product-medias/edit`, queryparams);
        //this.app.sharedTools.goTo(`/product-medias/${this.productId}`)
    }

    onEditContact() {
        let queryparams: any = {
            productid: this.productId
        }
        this.app.sharedTools.redirectToWithParameters(`product-contact/edit`, queryparams);
        //this.app.sharedTools.goTo(`/product-contact/${this.productId}`)
    }

    //#region
    match?: string;
    selectedFileURL: string | null = null;
    private isYtbVideo(ytbURL: any): boolean {
        const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/watch\?v=([a-zA-Z0-9_-]+)/;
        const regex2 = /^(https?:\/\/)?(www\.)?(youtu\.be)\/([a-zA-Z0-9_-]+)/;

        var match;

        if (ytbURL.search('watch') >= 0) {
            match = ytbURL.match(regex);
        } else {
            match = ytbURL.match(regex2);
        }

        if (match) {
            return true
        } else {
            return false;
        }

    }

    private generateEmbedUrl(youtubeUrl: any) {
        const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/watch\?v=([a-zA-Z0-9_-]+)/;
        const regex2 = /^(https?:\/\/)?(www\.)?(youtu\.be)\/([a-zA-Z0-9_-]+)/;

        if (youtubeUrl.search('watch') >= 0) {
            this.match = youtubeUrl.match(regex);
            console.log("🚀  youtubeUrl.search('watch'):", youtubeUrl.search('watch'))
        } else {
            this.match = youtubeUrl.match(regex2);
        }

        if (this.match) {
            const videoId = this.match[4];

            const embedUrl = `https://www.youtube.com/embed/${videoId}`;

            return embedUrl;
        } else {
            return null;
        }
    }
    //#endregion



}
