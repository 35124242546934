import { Component, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-navbar-f18',
    templateUrl: './navbar-f18.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./navbar-f18.component.less']
})
export class NavbarF18Component {
    items: MenuItem[] | undefined;

    ngOnInit() {
        this.items = [
            {
                label: 'File',
                //icon: 'pi pi-fw pi-file',
            },
            {
                label: 'Edit',
                //icon: 'pi pi-fw pi-pencil',
            },
            {
                label: 'Users',
                //icon: 'pi pi-fw pi-user',
            },
        ];
    }
}
