import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './api.service';
import { ConfirmDialogService } from './confirm-dialog.service';
import { cloneDeep } from 'lodash';
import { Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FormInvalidHelperService {

    constructor(private translate: TranslateService,) { }

    getAdrGoogleInvalid(form: any, controlName: any, _fieldToRequire: string) {
        if (form.controls[controlName].invalid) form.controls[_fieldToRequire].setErrors(Validators.required);
        else form.controls[_fieldToRequire].setErrors(null);
    }

    fieldTouchedAndInvalid(_form: any, controlName: any, isSubmitted: boolean) {
        let isInvalid: boolean = false;
        if (controlName == "adresseComplete") {
            if (!_form.get('City')?.valid || !_form.get('AddressLine1')?.valid || !_form.get('ZipCode')?.valid) this.getAdrGoogleInvalid(_form, controlName, 'adresseComplete');
        }

        if (controlName == "adresseFactuComplete") {
            if (!_form.get('IsBillingAddressSameAsPrincipal') && (!_form.get('Billing_City')?.valid || !_form.get('Billing_AddressLine1')?.valid || !_form.get('Billing_ZipCode')?.valid)) this.getAdrGoogleInvalid(_form, controlName, 'adresseFactuComplete');
        }

        isInvalid = ((_form.get(controlName)?.touched && !_form.get(controlName)?.valid) || (isSubmitted && !_form.get(controlName)?.valid)) ? true : false;
        let elementInHtml = document.getElementById(controlName) as HTMLElement;

        if (elementInHtml) {
            if (isInvalid) elementInHtml.classList.add('submitted-invalid')
            else elementInHtml.classList.remove('submitted-invalid');
        }
        return isInvalid;
    }

    getFieldsInvalidLabels(form: any, isSubmitted: any): string | null {
        if (!form) return '';

        let _errorMsg: string = "";

        _errorMsg = '<span class="font-bold">' + this.translate.instant('racine.errorsOther.errorFormDetail') + "</span><ul>";
        let hasInvalidControls = false; // Track if any invalid controls are found

        for (const controlName in form.controls) {
            // ==> On rajoute le libellé dans la liste
            if (form.controls.hasOwnProperty(controlName)) {
                if (form.controls[controlName].invalid && (form.controls[controlName].touched || isSubmitted)) {
                    const label: any = document.querySelector(`label[for="${controlName}"]`);
                    if (label) {
                        if (controlName == "LegalNumber1" || controlName == "LegalNumber2") {
                            if (controlName == "LegalNumber1") {
                                _errorMsg += '<li class="text-sm">' + label.textContent;
                            }
                            else _errorMsg += ' ou ' + label.textContent + '</li>';
                        }
                        else _errorMsg += '<li class="text-sm">' + label.textContent + '</li>';
                    }
                    else {
                        //console.log("controlName ==> ", controlName)
                        // ==> Si City, AddressLine1 ou ZipCode sont invalides, alors le champ adresseComplete est required
                        if (controlName == 'City' || controlName == 'AddressLine1' || controlName == 'ZipCode') {
                            this.getAdrGoogleInvalid(form, controlName, 'adresseComplete');
                        }

                        if (controlName == 'Billing_City' || controlName == 'Billing_AddressLine1' || controlName == 'Billing_ZipCode') {
                            this.getAdrGoogleInvalid(form, controlName, 'adresseFactuComplete');
                        }

                    }
                    hasInvalidControls = true; // Mark that invalid controls were found
                }
            }
        }

        if (isSubmitted &&  form.errors?.atLeastHaveOneotherCategoryRequired) {
            hasInvalidControls = true;

            let messageTrad = (form.controls.ProductID) ? 'fo.productFormInnovation.categoriesRequiredAtLeastOne' : 'racine.formulaireDP.activitesRequiredAtLeastOne';
            //console.log("getFieldsInvalidLabels messageTrad", messageTrad);

            _errorMsg += '<li class="text-sm">' + this.translate.instant(messageTrad) + '</li>'
        }

        if (hasInvalidControls) {
            _errorMsg += '</ul>';
            return _errorMsg;
        } else {
            return '';
        }
    }

    addSubmittedInvalidClass(_list: any) {
        console.log("_list", _list);
        for (let i = 0; i < _list.length; i++) {
            _list[i].classList.add('submitted-invalid');
            setTimeout(() => {
                let elFirst: any = (_list[1].offsetTop == 0) ? _list[1].offsetParent.offsetTop - 20 : _list[1].offsetTop - 20;
                //console.log("elFirst", elFirst)
                if (elFirst) window.scroll({ top: elFirst, behavior: "smooth" });
            }, 10);
        }
    }
}
