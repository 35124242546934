export class FinalizeCart {
    FolderID: number;
    PaymentType: string;
    GPRDAccepted: boolean;
    ShareInfoAccepted: boolean;

    constructor(folderID: number
        , paymentType: string
        , gPRDAccepted: boolean
        , shareInfoAccepted: boolean){
            this.FolderID = folderID;
            this.PaymentType = paymentType;
            this.GPRDAccepted = gPRDAccepted;
            this.ShareInfoAccepted = shareInfoAccepted;
        }
  }