import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';
import { CategoryType } from '../enums/category-type';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { MediasTypes } from '../enums/media-type';
import { StatusType } from '../enums/status-type';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StatusCode } from '../enums/status-code';
import { TreeNode } from 'primeng/api';
import { TreeNodeSelectEvent } from 'primeng/tree';
import { DatePipe } from '@angular/common';
import { DateHelper } from '../helpers/date.helper';
import { MediaCmpComponent } from '../medias-components/media-cmp/media-cmp.component';
import { CustomValidators } from '../validators/custom-validators';
import { WebsiteRegex } from '../consts/utils';
import { NewsTypeLink } from '../enums/news-link-types';
import { MediaValueChange } from '../models/media-value-change';
import { FormCode } from '../enums/form-code';
import { Languages } from '../enums/language';
import { NewsDocUploadMediaInfo, UploadMediaInfo } from '../models/media-upload-info';

@Component({
  selector: 'app-detail-actualite',
  templateUrl: './detail-actualite.component.html',
  styleUrls: ['./detail-actualite.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class DetailActualiteComponent implements OnInit {

  isEditMode: boolean = false
  newsId: any
  news: any
  selectedCategories: any
  categories: any
  tree: any = {};
  showTree: boolean = false
  languages: any
  text: any
  mediasTypes = MediasTypes;
  newsPhoto: any
  newsPhotoEN: any
  newsPhotoES: any
  newsPhotoFR: any
  status: any
  linkTypes: any
  currentEditionDays: any
  newsForm !: FormGroup
  initForm: boolean = false
  roles: any
  submitted: boolean = false
  showCategoryName: boolean = false
  @ViewChild('photoInputFR') photoInputFR?: MediaCmpComponent;
  @ViewChild('photoInputEN') photoInputEN?: MediaCmpComponent;
  @ViewChild('photoInputES') photoInputES?: MediaCmpComponent;
  isPhotoFrSelected : boolean = false;
  isPhotoEnSelected : boolean = false;
  isPhotoEsSelected : boolean = false;
  
  formCode : FormCode = FormCode.FRM_CP; 
  
  showLinkPageFR: boolean = false
  showLinkPageEN: boolean = false
  showLinkPageES: boolean = false
  languag = Languages;

  constructor(public app: AppComponent,
    public formBuilder: FormBuilder, private _datePipe: DatePipe) { }


  async ngOnInit() {
    this.app.route.queryParams.subscribe(async (params: any) => {
      if (params && params["id"]) {
        this.newsId = parseInt(params["id"])
        this.isEditMode = true

      }
    })
    this.getRoles()
    await this.getCategories()
    await this.getLanguages()
    await this.getStatusTypes()
    //await this.getEdition()
    //await this.getEdition()
    this.app.sharedTools.getJSON('general_data').subscribe(
      async resG => {
        this.linkTypes = cloneDeep(resG.actualiteLinkType);
        if (this.isEditMode) {
          await this.getNewsDetail()
        } else {
          this.initFormulaire()
        }
      });
  }


  linkTypeChanged(lang: string) {
    lang = lang.toUpperCase()
    let selectedLinkTyp = this.newsForm.get(`selectedLinkType${lang.toUpperCase()}`)?.value
    let validators: any
    if (selectedLinkTyp?.typeId == 1) {
      (this as any)[`showLinkPage${lang.toUpperCase()}`] = true;
      validators = [Validators.required, Validators.pattern(WebsiteRegex)]
    } else {
      validators = [];
      (this as any)[`showLinkPage${lang.toUpperCase()}`] = false
      this.newsForm.get(`newsLinkPage${lang.toUpperCase()}`)?.setValue(null)
      this.newsForm.updateValueAndValidity()
    }
    this.updateValidators(`newsLinkPage${lang.toUpperCase()}`, validators)

  }


  async onSubmit() {
    this.submitted = true
    if (this.newsForm.invalid || !this.checkIfImageValid()) {
      console.log("invalid form errors ===>", this.newsForm.errors);

      //bloc explore form errors
      let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
      const invalidValues: any = {};
      const controls = this.newsForm.controls;

      for (const controlName in controls) {
        if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
          invalidValues[controlName] = controls[controlName].value;
        }
      }

      console.log('Invalid form values:', invalidValues);

      if (invalidField && invalidField.length > 1) {
        console.log("invalidField", invalidField)

        setTimeout(() => {
          let el = invalidField[1].offsetTop - 100;
          if (el) window.scroll({ top: el, behavior: "smooth" });
        }, 10);
      }
      //
      this.app.confirm.confirmDialog(""
        , this.app.translate.instant('racine.errorsOther.errorForm')
        , 'KO'
        , 'alert'
        , () => { }
        , () => { });
    } else {
      let newsForm = this.newsForm.value
      let formatedStartDate = this._datePipe.transform(newsForm.dateStartPublication, DateHelper.ApiDateTimeFormat) ?? ''
      let formatedEndDate = this._datePipe.transform(newsForm.dateEndPublication, DateHelper.ApiDateTimeFormat) ?? ''

      let startDateTime: string = DateHelper.concatDateTime(formatedStartDate, newsForm.hourStartPublication) ?? '';
      let endDateTime: string = DateHelper.concatDateTime(formatedEndDate, newsForm.hourEndPublication) ?? '';

      let newsRequest: {
        NewsStatus: any;
        Language: any[];
        PublicationDate: string;
        ExpirationDate: string;
        PermissionRoles: any;
        Categories: any;
        NewsTitleFR: any;
        NewsTitleEN: any;
        NewsTitleES: any;
        NewsExcerptFR: any;
        NewsExcerptEN: any;
        NewsExcerptES: any;
        NewsContentFR: any;
        NewsContentEN: any;
        NewsContentES: any;
        ViewOrder: any;
        NewsLinkFR: any;
        NewsLinkEN: any;
        NewsLinkES: any;
        IsLogoSelectedFR :any
        IsLogoSelectedEN :any
        IsLogoSelectedES :any
        NewsID?: any;
        RotationImgFR ?: any,
        MapPoint ?:any
      }

      newsRequest = {
        NewsStatus: newsForm.selectedNewsStatus,
        Language: newsForm.selectedNewsLanguage,
        PublicationDate: startDateTime,
        ExpirationDate: endDateTime,
        PermissionRoles: newsForm?.selectedPermissions?.map((x: any) => { return x.RoleID }) || [],
        Categories: this.selectedCategories?.map((x: any) => { return x.key }) || [],
        NewsTitleFR: newsForm.newsTitleFR,
        NewsTitleEN: newsForm.newsTitleEN,
        NewsTitleES: newsForm.newsTitleES,
        NewsExcerptFR: newsForm.newsIntroductionFR,
        NewsExcerptEN: newsForm.newsIntroductionEN,
        NewsExcerptES: newsForm.newsIntroductionES,
        NewsContentFR: newsForm.newsDescritpionFR,
        NewsContentEN: newsForm.newsDescritpionEN,
        NewsContentES: newsForm.newsDescritpionES,
        ViewOrder: newsForm.newsOrdre,
        NewsLinkFR: newsForm.newsLinkPageFR,
        NewsLinkEN: newsForm.newsLinkPageEN,
        NewsLinkES: newsForm.newsLinkPageES,
        IsLogoSelectedFR : this.isPhotoFrSelected,
        IsLogoSelectedEN : this.isPhotoEnSelected,
        IsLogoSelectedES : this.isPhotoEsSelected,
        MapPoint : newsForm.mapPoint
      }
      if (this.isEditMode) {
        newsRequest = {
          ...newsRequest,
          NewsID: this.news?.NewsID
        }
        const response = await this.app.apiService.updateNews(newsRequest, this.photoInputFR?.selectedFile, 
                                                          this.photoInputEN?.selectedFile, 
                                                          this.photoInputES?.selectedFile, 
                                                          this.constructUploadInfo())
        setTimeout(() => {
          if (response && response.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
            }, () => {
            })
            return;
          }
          else
            this.app.confirm.confirmDialog(""
              , this.app.translate.instant("racine.successMsg.successNewsUpdtated")
              , 'OK'
              , "alert"
              , () => { this.onCancel(); }
              , () => { });
        }, 300);
      }
      else {
        const response = await this.app.apiService.createNews(newsRequest, 
                                                          this.photoInputFR?.selectedFile, 
                                                          this.photoInputEN?.selectedFile, 
                                                          this.photoInputES?.selectedFile, 
                                                          this.constructUploadInfo())
        console.log(response)
        setTimeout(() => {
          if (response && response.Result) {
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
            }, () => {
            })
            return;
          }
          else
            this.app.confirm.confirmDialog(""
              , this.app.translate.instant("racine.successMsg.successNewsCree")
              , 'OK'
              , "alert"
              , () => { this.onCancel(); }
              , () => { });
        }, 300);
      }
    }
  }

  checkIfImageValid(){
    return this.photoInputFR?.isConformTodimension
  }

  async addCategory() {
    let categoryName = this.newsForm.get("categoryName")?.value
    if (!categoryName || categoryName == '') {
      this.app.confirm.confirmDialog(""
        , this.app.translate.instant('racine.errorsOther.errorForm')
        , 'KO'
        , 'alert'
        , () => { }
        , () => { });
    }
    else {
      let createCategoryRequest = {
        CategoryType: CategoryType.NewsCategory,
        Code: "News",
        LabelEN: categoryName,
        ParentCategoryID: this.selectedCategories[0]?.key ?? null,
      }
      const resp = await this.app.apiService.createCategory(createCategoryRequest)
      this.getCategories()
      this.showCategoryName = false
      this.newsForm.get("categoryName")?.setValue(null)
      this.newsForm.updateValueAndValidity()
    }
  }

  addCategoryClick() {
    if (this.selectedCategories.length != 1 && this.selectedCategories.length != 0) {
      return
    }
    this.showCategoryName = !this.showCategoryName
  }

  deleteCategory() {
    if (this.selectedCategories.length == 1) {
      this.app.confirm.confirmDialog("", this.app.translate.instant('bo.detail-actualite.confirm-category-delete'), "AUTRE", "confirm", async () => {
        await this.deleteCategoryWithChilds(this.selectedCategories[0]?.key)
      }, () => { })
    }
  }

  async deleteCategoryWithChilds(categoryId: any) {
    const response = await this.app.apiService.deleteCategory(categoryId,true)
    setTimeout(() => {
      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
        }, () => {
        })
        return;
      }
      else
        this.app.confirm.confirmDialog(""
          , this.app.translate.instant("racine.successMsg.successDeleteCategory")
          , 'OK'
          , "alert"
          , () => { this.getCategories(); }
          , () => { });
    }, 300);
  }

  initActualitePhotos(){
    this.newsPhotoEN = this.news?.NewsImages?.filter((x:any)=> x.DocLanguageCode?.toLowerCase() == Languages.English)[0]
    this.newsPhotoFR = this.news?.NewsImages?.filter((x:any)=> x.DocLanguageCode?.toLowerCase() == Languages.French)[0]
    this.newsPhotoES = this.news?.NewsImages?.filter((x:any)=> x.DocLanguageCode?.toLowerCase() == Languages.Spanish)[0]
  }

  initFormulaire() {
    if (this.isEditMode) {
      this.initActualitePhotos()
      let selectedPermissions = this.roles?.filter((x: any) => {
        return this.news?.PermissionRoles?.findIndex((s: any) => s == x.RoleID) != -1
      })
      let selectedLanguage = this.languages?.find((x: any) => {
        return x?.LanguageID == this.news?.Language?.LanguageID
      })
      let startHour: string = this._datePipe.transform(this.news.PublicationDate, DateHelper.ApiTimeFormat) ?? '';
      let endHour: string = this._datePipe.transform(this.news.ExpirationDate, DateHelper.ApiTimeFormat) ?? '';
      let dateStartPub: string = this._datePipe
        .transform(this.news.PublicationDate
          , `${DateHelper.ApiDateFormat}${DateHelper.DateTimeSeparator}${DateHelper.MidnightTime}`)
        ?? '';
      let dateEndPub: string = this._datePipe
        .transform(this.news.ExpirationDate
          , `${DateHelper.ApiDateFormat}${DateHelper.DateTimeSeparator}${DateHelper.MidnightTime}`)
        ?? '';
      this.newsForm = new FormGroup({
        selectedNewsStatus: new FormControl(this.news?.NewsStatus, [Validators.required]),
        selectedPermissions: new FormControl(selectedPermissions),
        dateStartPublication: new FormControl(dateStartPub ? (new Date(dateStartPub)) : null),
        hourStartPublication: new FormControl(startHour),
        dateEndPublication: new FormControl(dateEndPub ? (new Date(dateEndPub)) : null),
        hourEndPublication: new FormControl(endHour),
        newsDescritpionFR: new FormControl(this.news.NewsContentFR),
        newsDescritpionEN: new FormControl(this.news.NewsContentEN),
        newsDescritpionES: new FormControl(this.news.NewsContentES),
        newsTitleEN: new FormControl(this.news.NewsTitleEN),
        newsTitleFR: new FormControl(this.news.NewsTitleFR),
        newsTitleES: new FormControl(this.news.NewsTitleES),
        newsIntroductionEN: new FormControl(this.news.NewsExcerptEN),
        newsIntroductionFR: new FormControl(this.news.NewsExcerptFR),
        newsIntroductionES: new FormControl(this.news.NewsExcerptES),
        newsOrdre: new FormControl(this.news.ViewOrder),
        selectedNewsLanguage: new FormControl(selectedLanguage),
        categoryName: new FormControl(null),
        categories: this.formBuilder.array([]),
        selectedLinkTypeEN: new FormControl(null),
        selectedLinkTypeES: new FormControl(null),
        selectedLinkTypeFR: new FormControl(null),
        newsLinkPageFR: new FormControl(this.news.NewsLinkFR),
        newsLinkPageEN: new FormControl(this.news.NewsLinkEN),
        newsLinkPageES: new FormControl(this.news.NewsLinkES),
        mapPoint : new FormControl(this.news.MapPoint)
      },
        { validators: [CustomValidators
          .endDateTimeGreaterOrEqualThanStartDateTime('dateEndPublication'
          , 'hourEndPublication'
          , 'dateStartPublication'
          , 'hourStartPublication'),
        CustomValidators.controlsRequiredAtLeastOneLang(['newsTitle'])] }
      )
      this.selectedCategories = []
      this.selectedCategoryNodes()
      this.checkIfNewsHaveLinks(Languages.English);
      this.checkIfNewsHaveLinks(Languages.French);
      this.checkIfNewsHaveLinks(Languages.Spanish);
    } else {
      // form default value region
      let draftStatus = this.status?.find((x: any) => x.Code == StatusCode.DRAFT_NEWS);
      // end default value region
      this.newsForm = new FormGroup({
        selectedNewsStatus: new FormControl(draftStatus, [Validators.required]),
        selectedPermissions: new FormControl(this.roles),
        dateStartPublication: new FormControl(null),
        hourStartPublication: new FormControl(null),
        dateEndPublication: new FormControl(null),
        hourEndPublication: new FormControl(null),
        newsDescritpionFR: new FormControl(null),
        newsDescritpionEN: new FormControl(null),
        newsDescritpionES: new FormControl(null),
        newsTitleFR: new FormControl(null),
        newsTitleEN: new FormControl(null),
        newsTitleES: new FormControl(null),
        newsIntroductionFR: new FormControl(null),
        newsIntroductionEN: new FormControl(null),
        newsIntroductionES: new FormControl(null),
        newsOrdre: new FormControl(null),
        selectedNewsLanguage: new FormControl(null),
        categoryName: new FormControl(null),
        categories: this.formBuilder.array([]),
        selectedLinkTypeEN: new FormControl(null),
        selectedLinkTypeES: new FormControl(null),
        selectedLinkTypeFR: new FormControl(null),
        newsLinkPageFR: new FormControl(null),
        newsLinkPageEN: new FormControl(null),
        newsLinkPageES: new FormControl(null),
        mapPoint : new FormControl(null)
      }, { validators: 
        [CustomValidators
          .endDateTimeGreaterOrEqualThanStartDateTime('dateEndPublication'
          , 'hourEndPublication'
          , 'dateStartPublication'
          , 'hourStartPublication'),
          CustomValidators.controlsRequiredAtLeastOneLang(['newsTitle'])] })
    }
    this.initForm = true
  }

  checkIfNewsHaveLinks(lang: string) {
    lang = lang.toUpperCase();
    let linkTypeControl = this.newsForm.get(`selectedLinkType${lang.toUpperCase()}`)
    let linkTypeFromJson = null
    if (this.news[`NewsLink${lang.toUpperCase()}`] != undefined && this.news[`NewsLink${lang.toUpperCase()}`] != null && this.news[`NewsTypeLink${lang.toUpperCase()}`] == NewsTypeLink.InternalExternalLink) {
      (this as any)[`showLinkPage${lang.toUpperCase()}`] = true
      if (linkTypeControl) {
        linkTypeFromJson = this.linkTypes?.filter((x: any) => { return x.typeId == 1 })[0]
        linkTypeControl.setValue(linkTypeFromJson)
        this.newsForm.updateValueAndValidity()
        this.linkTypeChanged(lang)
      }
    } else {
      if (linkTypeControl) {
        linkTypeFromJson = this.linkTypes?.filter((x: any) => { return x.typeId == 0 })[0]
        linkTypeControl.setValue(linkTypeFromJson)
        this.newsForm.updateValueAndValidity()
        this.linkTypeChanged(lang)
      }
    }
  }

  updateValidators(fieldName: string, validators: any[]): void {
    const control = this.newsForm.get(fieldName);
    if (control) {
      control.setValidators(validators);
      control.updateValueAndValidity();
    }
  }

  formControlHavePatternError(name: string): boolean {
    let havePatternError: boolean = false;

    let control = this.newsForm.get(name);
    if (control?.errors && control.errors['pattern']) {
      havePatternError = true;
    }
    return havePatternError;
  }

  selectedCategoryNodes() {
    this.news?.Categories?.forEach((cat: any) => {
      setTimeout(() => {
        this.selectNodesByData(cat, this.categories);
        this.expandNodes(this.categories)
      }, 500);
    });
  }


  selectNodesByData(data: any, nodes: TreeNode[] = this.categories) {
    try {
      for (const node of nodes) {
        if (node.key === data) {
          this.selectedCategories.push(node);
          break;
        }
        if (node.children) {
          this.selectNodesByData(data, node.children);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  expandNodes(nodes: TreeNode[] = this.categories) {
    for (const node of nodes) {
      if (node.children) {
        const haveSubNodeSelected = this.haveSubNodeSelected(node.children);;
        if (haveSubNodeSelected) {
          this.expandParentNodes(node);
        }
      }
    }
  }
  
  haveSubNodeSelected(nodes: TreeNode[]): boolean {
    for (const node of nodes) {
      if (this.selectedCategories.findIndex((s: any) => s.key === node.key) !== -1) {
        return true;
      }
      if (node.children && this.haveSubNodeSelected(node.children)) {
        return true;
      }
    }
    return false;
  }
  
  expandParentNodes(node: TreeNode) {
    node.expanded = true; 
    node?.children?.forEach((element : TreeNode)=>{
      element.expanded = true
    })
  }
  
  
  

  getRoles() {
    this.app.apiService.getRoles().subscribe((data: any) => {
      this.roles = data;
    });
  }

  getTitle() {
    let title: string = ' ' + this.app.sharedTools.getPropertySwitchLang(this.news, 'NewsTitle') ?? '';
    return title;
  }


  refreshFormControlCategories(): void {
    // ==> We format the nodes
    let categories = this.app.sharedTools.getCategoriesFromSelectedNodes(this.categories, this.selectedCategories);
    //set activities to Activities form control
    this.setCategoriesToForm(categories);
  }

  private setCategoriesToForm(newValues: any): void {
    let categoriesControl = this.newsForm.get('categories') as FormArray;

    // Clear the existing controls in the FormArray
    categoriesControl.clear();

    // Add the new values to the FormArray as individual controls
    newValues.forEach((value: any) => {
      categoriesControl.push(this.formBuilder.control(value));
    });
    console.log("categoriesControl => ", this.selectedCategories)
  }


  onCancel() {
    this.app.sharedTools.redirectTo("/liste-news")
  }

  private async getEdition() {
    try {
      const response = await this.app.apiService.getEdition();
      let edition = response;

      if (edition?.AllEditionDates
        && Array.isArray(edition.AllEditionDates)
        && edition.AllEditionDates.length > 0) {
        this.currentEditionDays = edition.AllEditionDates;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getStatusTypes() {
    let statusResponse = await firstValueFrom(this.app.apiService.getStatus())
    if (statusResponse && Array.isArray(statusResponse)){
      this.status = statusResponse.filter(status => status.StatusType === StatusType.NEWS_State);
    }
    else {
      this.status = []
    }
  }

  async getNewsDetail() {
    this.news = await this.app.apiService.getNewsById(this.newsId)
    this.initFormulaire()
    console.log("actualite1", this.news)
  }

  async deleteNews(){
    const response = await this.app.apiService.deleteNewsFo(this.newsId)
    setTimeout(() => {
      if (response && response.Result) {
        this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
        }, () => {
        })
        return;
      }
      else
        this.app.confirm.confirmDialog(""
          , this.app.translate.instant("racine.successMsg.successNewsDeleted")
          , 'OK'
          , "alert"
          , () => { this.onCancel(); }
          , () => { });
    }, 300);
  }

  confirmDeleteNews(){
    this.app.confirm.confirmDialog("", this.app.translate.instant('bo.detail-actualite.confirm-delete-fiche'), "AUTRE", "confirm", async () => {
      await this.deleteNews()
    }, () => {  })
  }

  private async getLanguages() {
    const response = await firstValueFrom(this.app.apiService.getLanguages());

    if (response && Array.isArray(response)) {
      this.languages = response
    }
    else {
      this.languages = [];
    }
  }

  async getCategories() {
    this.categories = []
    this.tree = {}
    this.selectedCategories = []
    const response = await this.app.apiService.getCategories();

    let categoriesIni = cloneDeep(response);
    let categories = categoriesIni.filter((cat: any) => {
      return cat.CategoryType == CategoryType.NewsCategory
    });
    this.categories = cloneDeep(categories);

    categories.sort((a: any, b: any) => {
      return a?.ViewOrder - b?.ViewOrder
    })

    categories.forEach((cat: any) => {
      const parentId = cat.ParentCategoryID;
      if (!this.tree[parentId]) {
        this.tree[parentId] = [];
      }
      this.tree[parentId].push(cat);
    });

    this.categories = this.buildTree(null);

    this.selectedCategories = []
    this.selectedCategoryNodes()
  }

  // onPhotoFrSelected(data : MediaValueChange){
  //   this.isPhotoFrSelected = data?.HaveValue ?? false
  // }

  // onPhotoEnSelected(data : MediaValueChange){
  //   this.isPhotoFrSelected = data?.HaveValue ?? false
  // }

  // onPhotoEsSelected(data : MediaValueChange){
  //   this.isPhotoFrSelected = data?.HaveValue ?? false
  // }

  onPhotoFrSelected(imageFr: MediaValueChange): void {
    console.log(' imageFr ', imageFr);
    let imageUpdatedControl = this.newsForm.get('IsLogoSelectedFR');

    if (imageUpdatedControl && imageFr.IsFileSelected) {
        imageUpdatedControl.setValue(true);
    }
    this.isPhotoFrSelected = imageFr?.HaveValue ?? false

    console.log(imageFr, this.newsForm, this.photoInputFR?.selectedFile)
}

onPhotoEnSelected(imageEn: MediaValueChange): void {
  console.log(' imageEn ', imageEn);
  let imageUpdatedControl = this.newsForm.get('IsLogoSelectedEN');

  if (imageUpdatedControl && imageEn.IsFileSelected) {
      imageUpdatedControl.setValue(true);
  }
  this.isPhotoEnSelected = imageEn?.HaveValue ?? false

  console.log(imageEn, this.newsForm, this.photoInputEN?.selectedFile)
}

onPhotoEsSelected(imageEs: MediaValueChange): void {
  console.log(' imageEs ', imageEs);
  let imageUpdatedControl = this.newsForm.get('IsLogoSelectedES');

  if (imageUpdatedControl && imageEs.IsFileSelected) {
      imageUpdatedControl.setValue(true);
  }
  this.isPhotoEsSelected = imageEs?.HaveValue ?? false

  console.log(imageEs, this.newsForm, this.photoInputES?.selectedFile)
}

  buildTree(parentId: any) {
    const children = this.tree[parentId] || [];
    return children.map((child: any) => {
      const node = {
        key: child.CategoryID,
        label: this.app.sharedTools.getLabelSwitchLang(child),
        LabelEN: child.LabelEN,
        LabelFR: child.LabelFR,
        LabelES: child.LabelES,
        data: '',
        icon: "",
        children: this.buildTree(child.CategoryID)
      };
      return node;
    });
  }

  private constructUploadInfo(): NewsDocUploadMediaInfo {
    let photoFrUploadInfo: UploadMediaInfo = new UploadMediaInfo(this.photoInputFR?.degRotation ?? 0
        , this.newsForm.get('IsLogoSelectedFR')?.value
        , 1);

    let photoEnUploadInfo: UploadMediaInfo = new UploadMediaInfo(this.photoInputEN?.degRotation ?? 0
        , this.newsForm.get('IsLogoSelectedEN')?.value
        , 1);

    let photoEsUploadInfo: UploadMediaInfo = new UploadMediaInfo(this.photoInputES?.degRotation ?? 0
        , this.newsForm.get('IsLogoSelectedES')?.value
        , 1);

    let mediaUploadInfo: NewsDocUploadMediaInfo = new NewsDocUploadMediaInfo(photoFrUploadInfo, photoEnUploadInfo, photoEsUploadInfo);

    return mediaUploadInfo;
}



}
