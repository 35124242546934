import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { IdentityManagerService } from 'src/app/services/identity-manager.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./nav-menu.component.less']
})
export class NavMenuComponent implements OnInit {

  @Input() NbConferences?: number;
  @Input() NbSpeakers?: number;
  @Input() NbSolutions?: number;
  @Input() NbExhibitors?: number;

  @Input() isExhibitor: boolean = false;

  tabIndex: number = 0;

  @Output() isExhibitorMenu: EventEmitter<any> = new EventEmitter();

  constructor(
    public app: AppComponent,
    private _router: Router,
    protected ims: IdentityManagerService
  ) {
  }

  ngOnInit(): void {
    const storedActiveTab = localStorage.getItem('activeTab');
    this.tabIndex = storedActiveTab !== null ? parseInt(storedActiveTab, 10) : 0;
    this.isExhibitorMenuEvent();

    setTimeout(() => {
      console.log(">> NB Sol : ", this.NbSolutions);
    }, 200);
  }

  clickLink(routerLink: any) {
    this.isExhibitorMenuEvent();
    this.app.sharedTools.redirectTo(routerLink);
  }

  switchHeaders(tabNumber: any) {
    console.log("🚀  tabNumber:", tabNumber.index)
    this.tabIndex = tabNumber.index;
    localStorage.setItem('activeTab', this.tabIndex.toString());
  }

  isRouterActive(route: string): boolean {
    return this._router.url.split('?')[0] === route;
  }

  isExhibitorMenuEvent() {
    if (this.isRouterActive('/mapExhi') || this.isRouterActive('/welcome-sol') || this.isRouterActive('/welcome-exhibitor')) {
      this.isExhibitor = true;
    } else {
      this.isExhibitor = false;
    }
    this.isExhibitorMenu.emit(this.isExhibitor);
  }

}
