import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../app.component';
import { cloneDeep } from 'lodash';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-create-role',
    templateUrl: './create-role.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./create-role.component.less']
})
export class CreateRoleComponent {
    submitted: boolean = false;
    initForm: boolean = false;
    newLeadForm!: FormGroup;
    isEditMode: boolean = false;
    paths = location.pathname.split('/');
    currentPath: any = null;
    roleID: any;
    role?: any;
    appID: any;

    constructor(
        public app: AppComponent,
        private messageService: MessageService,
    ) {
    }
    async ngOnInit() {
        let user = JSON.parse(sessionStorage.getItem('userData')!);
        console.log(user);
        if (!user) {
            this.app.router.navigate(['/login']);
            return;
        }

        //check create or edit role
        console.log("this.path==> ", this.paths);
        this.currentPath = this.paths[1];
        var urlId: any = this.app.router.url.split('/').pop();
        if (this.currentPath == 'detail-role') {
            this.roleID = parseInt(urlId);
        }

        this.app.sharedTools.getJSON('general_data').subscribe(
            resG => {
                console.log("resG", resG);
                this.appID = resG.APP_ID;
            });

        if (this.roleID) {
            this.isEditMode = true;
            this.getRole(this.roleID);
            console.log("this.roleId ==> ", this.roleID);
        }
        else {
            this.InitForm();
            console.log("this.newLeadForm", this.newLeadForm);
        }

    }
    onSubmit() {
        this.submitted = true;
        console.log('[this.newLeadForm]', this.newLeadForm);

        if (this.newLeadForm.invalid) {

            console.log("invalid form ===>", this.newLeadForm);
            console.log("invalid form ===>", this.newLeadForm.errors);

            //bloc explore form errors

            let invalidField: any = document.getElementsByClassName('ng-invalid') as HTMLCollection;
            const invalidValues: any = {};
            const controls = this.newLeadForm.controls;

            for (const controlName in controls) {
                if (controls.hasOwnProperty(controlName) && controls[controlName].invalid) {
                    invalidValues[controlName] = controls[controlName].value;
                }
            }

            console.log('Invalid form values:', invalidValues);

            if (invalidField && invalidField.length > 1) {
                console.log("invalidField", invalidField)

                setTimeout(() => {
                    let el = invalidField[1].offsetTop - 100;
                    if (el) window.scroll({ top: el, behavior: "smooth" });
                }, 10);
            }
            //
            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsOther.errorForm'), 'KO', 'alert', () => {
            }, () => { });
        } else {

            let newRole = this.newLeadForm.value;

            console.log('[newRole]', newRole);

            this.createRole(newRole);
        }
    }

    annuler() {
        this.app.sharedTools.redirectTo('/roles');
    }

    onDeleteRole(_role: any) {
        this.app.confirm.confirmDialog('', this.app.translate.instant('racine.confirmMsg.confirmDeleteRole'), 'AUTRE', 'confirm', () => {
            setTimeout(() => { this.deleteRole(_role); }, 1000);
        }, () => { });
    }

    async deleteRole(_roleObj: any) {
        console.log("DELETE Role ===>", _roleObj);
        try {
            const response = await this.app.apiService.deleteRole(_roleObj);
            console.log('Response API : DELETE Role ===>', response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { })
                return;
            }

            this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successRoleDelete'), 'OK', "alert", () => {
                this.app.sharedTools.redirectTo('/roles');
            }, () => { });


        } catch (error) {
            console.error(error);
        }
    }

    async createRole(formObj: any): Promise<any> {
        console.log("CREATE Role ===>", formObj);

        try {
            if (!this.isEditMode) {
                formObj.AppID = (this.appID == 2) ? 3 : this.appID; // IMPORTANT TODO: enlever le if lors de la duplication et ne laisser que formObj.AppID = this.appID.
                console.log("CREATE Role NOT EDIT MOOD ===>", formObj);

                const response = await this.app.apiService.createRole(formObj);
                console.log('Response API : CREATE Role ===>', response);

                if (response && response.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                    }, () => {
                    })
                    return;
                }

                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successRoleCree'), 'OK', "alert", () => {
                    this.app.sharedTools.redirectTo('/roles');
                }, () => { });
            }

            else {
                formObj.RoleID = this.roleID;
                const response = await this.app.apiService.updateRole(formObj);
                console.log('Response API : UPDATE Role ===>', response);

                if (response && response.Result) {
                    this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => { }, () => { });
                    return;
                }

                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.successMsg.successRoleUpdate'), 'OK', "alert", () => {
                    this.app.sharedTools.redirectTo('/roles');
                }, () => { });

            }
        } catch (error) {
            console.error(error);
        }
    }
    private async getRole(roleId: any) {

        try {
            const response = await this.app.apiService.getRole(roleId);
            console.log("getRole ==> ", response);

            if (response && response.Result) {
                this.app.confirm.confirmDialog("", this.app.translate.instant('racine.errorsApi.errorsMsgApi_' + response.Result), 'KO', "alert", () => {
                }, () => {
                })
                return;
            }
            this.role = cloneDeep(response);
            this.InitForm();
        }
        catch (error) {
            console.error(error);
        }
    }
    private InitForm(): void {
        if (this.isEditMode) {
            this.newLeadForm = new FormGroup({
                LabelEN: new FormControl(this.role.LabelEN ?? '', Validators.required),
                LabelFR: new FormControl(this.role.LabelFR ?? ''),
                LabelES: new FormControl(this.role.LabelES ?? ''),
            });
        }
        else {
            this.newLeadForm = new FormGroup({
                LabelEN: new FormControl('', Validators.required),
                LabelFR: new FormControl(''),
                LabelES: new FormControl(''),
            });

        }

        this.initForm = true;
    }
}
