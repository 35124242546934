export class MarketingGenerateMediaRequest{
    Text1: string | undefined;
    Text2: string | undefined;
    Text3 : string | undefined;
    Text4 : string | undefined;
    Logo: number | undefined;
    UserPhoto: number | undefined;
    WebsiteUrl: string | undefined;
    CategoryID: number | undefined;
}
