import { Languages } from "../enums/language";

export class CartItem {
    CartID?: number;
    FolderID?: number;
    ProductID?: number;
    Quantity?: number;
    Total?: number;
    
    //local properties :: not mapped from api (filled locally - clien side)
    NameEN?: string;
    NameFR?: string;
    NameES?: string; 
    Price?: number;

    public getName(language: string): string {
        switch (language) {
            case Languages.English: return this.NameEN ?? '';
            case Languages.French: return this.NameFR ?? '';
            case Languages.Spanish: return this.NameES ?? '';
            default: return this.NameEN ?? '';
        }
    }
}