import { CategoryType } from './../enums/category-type';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponent } from '../app.component';
import { SearchConference } from '../models/search-conference';
import { cloneDeep, forEach } from 'lodash';
import { HelperService } from '../services/helper.service';
import { DatePipe } from '@angular/common';
import { DateHelper } from '../helpers/date.helper';
import { StatusType } from '../enums/status-type';
import { StatusCode } from '../enums/status-code';
import { Menu } from 'primeng/menu';
import { CsvExportSameColumnSeparator, ListConfAdvancedSearchLocalStorageKey } from '../consts/utils';
import { Table } from 'primeng/table';
import { RoleTypeId } from '../enums/role-type-id';
import { ConferenceRoutes } from '../consts/conference.routes';
import { firstValueFrom } from 'rxjs';
import { IdentityManagerService } from '../services/identity-manager.service';
import { SearchHelperService } from '../services/search-helper.service';
import { PagePaginationInfo } from '../models/page-pagination-info';
@Component({
  selector: 'app-liste-conferences',
  templateUrl: './liste-conferences.component.html',
  styleUrls: ['./liste-conferences.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ListeConferencesComponent implements OnInit {
  isProgram: boolean = false;
  @ViewChild('menuAction') menuAction!: Menu;
  rangeDates: Date[] = [];
  maxDate = new Date();
  fastSearchConferenceKeyword: any
  showAdvancedSearch: boolean = false
  search: SearchConference = new SearchConference()
  ConferenceNameSuggestions: string[] = []

  interestsOptions: any[] = [];
  organiserOptions: any;
  statesOptions: any;
  publishingOptions: any;
  speakersOptions: any;
  sitesOptions: any;
  cyclesOptions: any;
  menuActionItems: any = [];

  conferences: any
  selectedColumns: any[] = []
  listeConferenceColumns: any[] = []
  programOptions: any
  typesOptions: any

  currentPageTable: number = 1
  currentPageIndex: number = 1
  rowsCapacity: number = 50
  pagePaginationInfo: PagePaginationInfo = { pageNumber: 1, rowSize: 50 }
  isLoaded : boolean = false
  get fastFiltersConferences(): string[] {
    return ['NbSpeakers'
      , 'Code'
      , this.app.sharedTools.getLangPropertyName('CnfdName')
      , this.app.sharedTools.getLangPropertyName('CnfdDescription')
      , `LanguageDetail.${this.app.sharedTools.getLangPropertyName('Label')}`
      , `ConferenceProgram.${this.app.sharedTools.getLangPropertyName('CnfprName')}`
      , 'ConferenceEvent.CnfeHourStart'
      , `ConferenceEvent.CategoryEventsDetails.${this.app.sharedTools.getLangPropertyName('Label')}`
      , `ConfStatus.${this.app.sharedTools.getLangPropertyName('Label')}`
      , `PlubishOnWeb.${this.app.sharedTools.getLangPropertyName('Label')}`
      , `ConfTypeCategoryFull.${this.app.sharedTools.getLangPropertyName('Label')}`
      , this.app.sharedTools.getLangPropertyName('Activities')
    ]
  }
  selectedConferences: any[] = []
  selectedAction: any
  groupedActions: any[] = [];
  @ViewChild('dtConferences') dtConferences!: Table;

  constructor(public app: AppComponent,
    public helperService: HelperService,
    private datePipe: DatePipe,
    private ims: IdentityManagerService,
    private searchHelperService: SearchHelperService) { }
  isAdmin: boolean = false;
  async ngOnInit() {
    let user = JSON.parse(sessionStorage.getItem('userData')!);

    if (!user) {
      this.app.router.navigate(['/login']);
      return;
    }
    // verify if it's the back office of the program or the conference 
    this.isProgram = this.app.router.url == `/${ConferenceRoutes.programList}`;

    // verify if the user has the right to have admin features
    if (this.isProgram) {
      this.isAdmin = this.ims.haveAuthorizedRole([RoleTypeId.DP_SuperAdmin, RoleTypeId.ADMIN_FINDERR
        , RoleTypeId.PROG_Administrator, RoleTypeId.PROG_Manager]);
    }
    else {
      this.isAdmin = this.ims.haveAuthorizedRole([RoleTypeId.DP_SuperAdmin, RoleTypeId.ADMIN_FINDERR
        , RoleTypeId.CONF_Administrator, RoleTypeId.CONF_Manager]);
    }

    this.app.sharedTools.getJSON('general_data').subscribe(
      data => {
        this.selectedColumns = data.colsConferenceList;
        if (this.isProgram) {
          this.selectedColumns = this.selectedColumns.filter((c: any) => {
            return (c.isProgram == null || c.isProgram == undefined) || c.isProgram == true
          })
        } else {
          this.selectedColumns = this.selectedColumns.filter((c: any) => {
            return (c.isProgram == null || c.isProgram == undefined) || c.isProgram == false
          })
        }
        if (!this.isAdmin) {
          let columnIndex = this.selectedColumns.findIndex(x => x.field == "Publish")
          if (columnIndex != -1) {
            this.selectedColumns.splice(columnIndex, 1)
          }
        }
        this.listeConferenceColumns = data.colsConferenceList
        this.groupedActions = data.actionListeConference
        this.menuActionItems = data.actionsConferenceRow;
        this.menuActionItems.forEach((itemMenu: any) => {
          itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);
        });
      }
    )

    this.search = cloneDeep(this.searchHelperService.getAdvancedSearch(ListConfAdvancedSearchLocalStorageKey))
    if(!this.search){
      this.search = new SearchConference()
    }
    this.pagePaginationInfo = this.searchHelperService.getPagePaginationInfo(ListConfAdvancedSearchLocalStorageKey)
    this.rowsCapacity = this.pagePaginationInfo.rowSize ?? 50
    await this.getCategories();
    await this.getStatusTypes();
    await this.getAllOrganisers();
    await this.getAllSpeakers();
    await this.getAllTracks();
    await this.getAllInterests();
    await this.getAllPrograms()
    this.initAdvancedSearch(this.search);
    this.getAllConferences(this.search, true);
  }

  pageChanged(event: any) {
    let pageIndex = event.first / event.rows + 1
    this.currentPageIndex = pageIndex
    this.updatePaginationInfo(ListConfAdvancedSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
  }
  rowsChanged(event: any) {
    this.rowsCapacity = event
    this.updatePaginationInfo(ListConfAdvancedSearchLocalStorageKey, this.currentPageIndex, this.rowsCapacity)
  }

  updatePaginationInfo(key: string, currentPageIndex: number, rowCapacity: number) {
    let pagePaginationInfo: PagePaginationInfo = {
      pageNumber: currentPageIndex,
      rowSize: rowCapacity
    }
    this.searchHelperService.setPagePaginationInfo(pagePaginationInfo, key)
  }

  private async getAllPrograms() {
    let data = await firstValueFrom(this.app.apiService.getAllPrograms());
    if (data && Array.isArray(data)) {
      this.programOptions = data;
    }
    else {
      this.programOptions = [];
    }
  }

  async getStatusTypes() {
    let statusResponse = await firstValueFrom(this.app.apiService.getStatus())
      console.log('statusResponse : ', statusResponse);
      if (statusResponse && statusResponse != '' && Array.isArray(statusResponse)) {
        this.publishingOptions = statusResponse.filter(status => status.StatusType === StatusType.CONFWeb_State);
        this.statesOptions = statusResponse.filter(status => status.StatusType === StatusType.CONFState);
        console.log('status conf : ', this.statesOptions);
      }
      else {
        this.publishingOptions = []
        this.statesOptions = []
      }
  }



  toggleMenu(event: any, row: any) {
    this.menuAction.toggle(event);

    this.menuActionItems.forEach((itemMenu: any) => {
      itemMenu.label = this.app.sharedTools.getLabelSwitchLang(itemMenu);

      if (itemMenu.ActionID == 1) {
        itemMenu.command = (event: any) => {
          this.onEditClick(row)
        }
      }

      if (itemMenu.ActionID == 2) {
        itemMenu.command = (event: any) => {
        }
      }
    });
  }

  onDateChange(ev: any) {
    if (!ev || ev == null || "") {
      this.search.DateMin = null;
      this.search.DateMax = null;
      return;
    };
    this.search.DateMin = (ev[0]) ? this.datePipe.transform(ev[0], DateHelper.ApiDateTimeFormat) : null;
    this.search.DateMax = (ev[1]) ? this.datePipe.transform(ev[1], DateHelper.ApiDateTimeFormat) : null;
  }


  getColorStatus(status: any) {
    let classColor = "";
    if (status.StatusType === StatusType.CONFState) {
      switch (status.Code) {
        case StatusCode.CONFIRMED:
          classColor = "color-lime-green"
          break;

        case StatusCode.DRAFT_FICHE:
          classColor = "color-grey"
          break;

        case StatusCode.TOVALIDATE_FICHE:
          classColor = "color-orange"
          break;

        default:
          classColor = "color-black"
          break;
      }
    }
    else if (status.StatusType === StatusType.CONFWeb_State) {
      switch (status.Code) {
        case StatusCode.PUBLISHED:
          classColor = "color-lime-green"
          break;

        case StatusCode.SCHEDULED:
          classColor = "color-orange"
          break;

        default:
          classColor = "color-black"
          break;
      }
    }

    return classColor;
  }
  async getAllOrganisers() {
    let organisers = await firstValueFrom(this.app.apiService.getAllValidOrganisers(this.isProgram))
    if (organisers && Array.isArray(organisers)) {
      console.log(organisers, "organizers")
      this.organiserOptions = organisers
    } else {
      this.organiserOptions = []
    }
  }

  async getAllSpeakers() {
    let speakers = await firstValueFrom(this.app.apiService.getAllSpeakers())
    if (speakers && Array.isArray(speakers)) {
      console.log(speakers, "speakers")
      this.speakersOptions = speakers;
    } else {
      this.speakersOptions = []
    }
  }


  async getAllTracks() {
    let categories = await this.app.apiService.getCategories();
    if (categories && Array.isArray(categories)) {
      this.cyclesOptions = categories.filter((c: any) => c.CategoryType == CategoryType.ConfTheme);

      let typeCategoryType: string = this.isProgram ? CategoryType.DemoType : CategoryType.ConfType;
      this.typesOptions = categories.filter((cat: any) => {
        return cat.CategoryType == typeCategoryType
      });
    } else {
      this.cyclesOptions = []
    }
  }

  async getAllInterests() {
    let interests = await firstValueFrom(this.app.apiService.getAllInterests())
    if (interests?.ConferenceInterestList && Array.isArray(interests.ConferenceInterestList)) {
      this.interestsOptions = interests.ConferenceInterestList.map((i: any) => {
        return { key: i, value: i }
      });
    } else {
      this.interestsOptions = []
    }
  }

  modalOptions: any;
  showModal: boolean = false;
  onActionSelectChange(action: any) {
    this.showModal = true
    console.log("actioooon", action)
    if (action.value.ActionID == 1) {
      this.modalOptions = this.statesOptions;
    } else if (action.value.ActionID == 2) {
      this.modalOptions = this.publishingOptions;
    }
  }
  async acceptAction(event: any) {
    console.log(event, "event")
    if (this.selectedAction.ActionID == 1) {
      let body = {
        ConfDetailIDs: this.selectedConferences.map((x: any) => x.ConfDetailID),
        StatusID: event.StatusID
      };
      try {
        await this.app.apiService.SetConferenceState(body);
        this.showModal = false;
        this.getAllConferences(this.search)
        this.selectedAction = undefined
        this.selectedConferences = []
      } catch (error) {
        console.error(error);
      }
    } else if (this.selectedAction.ActionID == 2) {
      let body = {
        ConfDetailIDs: this.selectedConferences.map((x: any) => x.ConfDetailID),
        StatusID: event.StatusID
      };
      try {
        await this.app.apiService.SetPublishOnWebState(body);
        this.showModal = false;
        this.getAllConferences(this.search)
        this.selectedAction = undefined
        this.selectedConferences = []
      } catch (error) {
        console.error(error);
      }
    }
  }
  rejectAction() {
    this.showModal = false
  }

  private initAdvancedSearch(search: SearchConference) {
    let selectedStatus = this.statesOptions?.find((x: any) => x.StatusID == search?.StatusID?.StatusID)
    let selectedPublishedStatus = this.publishingOptions?.find((x: any) => x.StatusID == search?.PublishOnWebStatusID?.StatusID)
    let selectedLocations = this.sitesOptions?.find((x: any) => x.CategoryID == search.LocationsList?.CategoryID)
    this.rangeDates = [];

    if (search.DateMin) {
      this.rangeDates.push(new Date(search.DateMin));
    }

    if (search.DateMax) {
      this.rangeDates.push(new Date(search.DateMax));
    }

    this.search.StatusID = selectedStatus
    this.search.PublishOnWebStatusID = selectedPublishedStatus
    this.search.LocationsList = selectedLocations
  }

  async getAllConferences(search: SearchConference, goLatestViewedPage : boolean = false) {
    let lstOrganisers = []
    let lstSpeakers = []
    let lstTracks: any[] = []
    console.log("this is search ", search)
    search?.SpeakersList != null ? lstSpeakers.push(search?.SpeakersList?.ContactID) : ""
    search?.ManagerList != null ? lstOrganisers.push(search?.ManagerList?.ContactID) : ""
    if (search?.TracksList != null) {
      search?.TracksList.forEach((t: any) => {
        lstTracks.push(t.CategoryID)
      })

    }
    console.log("lstTracks", lstTracks)
    //search?.TracksList != null ? lstTracks.push(search?.TracksList?.CnfprID) : ""
    let body: any = {
      Code: search?.Code,
      DateMax: search.DateMax,
      DateMin: search.DateMin,
      InterestList: search.InterestList,
      LocationsList: search.LocationsList ? [search.LocationsList.CategoryID] : [],
      ManagerList: lstOrganisers,
      PublishOnWebStatusID: search.PublishOnWebStatusID?.StatusID,
      SpeakersList: lstSpeakers,
      StatusID: search.StatusID?.StatusID,
      TitleEN: undefined,
      TitleES: undefined,
      TitleFR: undefined,
      Title: undefined,
      TracksList: lstTracks,
      IsProgram: this.isProgram,
      ProgramList: this.search.ProgrammeList?.map((x: any) => x.CnfprID),
      TypesList: this.search.Types?.map((x: any) => x.CategoryID)
    };
    body[this.app.sharedTools.getLangPropertyName('Title')] = search.Title;
    console.log("getting data")
    try {
      const response = (await this.app.apiService.GetFilteredConferences(body))
        .subscribe((conferences: any) => {
          if (conferences && Array.isArray(conferences)) {
            this.conferences = conferences;
            this.conferences.forEach((p: any) => {
              p.SelectedPlubishOnWeb = this.publishingOptions.find((n: any) => n.StatusID == p.PlubishOnWeb?.StatusID)
              p.SelectedState = this.statesOptions.find((n: any) => n.StatusID == p.ConfStatus?.StatusID);
              this.mapSearchCriteria(p);

            });
            console.log("conferencessss", conferences)
            if(goLatestViewedPage){
              this.currentPageTable = ((this.pagePaginationInfo.pageNumber ?? 1) - 1) * (this.pagePaginationInfo.rowSize ?? 1);
          }
          this.isLoaded = true
          }
        })
    } catch (error) {
      console.error(error);
    }
  }

  private mapSearchCriteria(conf: any): void {
    if (conf) {
      if (conf.ConfDetailCategories && Array.isArray(conf.ConfDetailCategories) && conf.ConfDetailCategories.length > 0) {
        conf.ActivitiesEN = conf.ConfDetailCategories.map((c: any) => c.LabelEN);
        conf.ActivitiesFR = conf.ConfDetailCategories.map((c: any) => c.LabelFR);
        conf.ActivitiesES = conf.ConfDetailCategories.map((c: any) => c.LabelES);
      }
    }
  }


  async onChangeConfState(confID: any, statusID: any) {
    console.log("onChangeConfState")
    let listIds = []
    listIds.push(confID)
    let body = {
      ConfDetailIDs: listIds,
      StatusID: statusID
    };
    try {
      const response = await this.app.apiService.SetConferenceState(body);
      console.log("onChangeConfState !!!", response);
    } catch (error) {
      console.error(error);
    }
  }

  async onChangePublishOnWebState(confID: any, publishStatusID: any) {

    console.log("onChangePublishOnWebState")
    let listIds = []
    listIds.push(confID)
    let body = {
      ConfDetailIDs: listIds,
      StatusID: publishStatusID
    };
    try {
      const response = await this.app.apiService.SetPublishOnWebState(body);
      console.log("onChangeConfState !!!", response);
    } catch (error) {
      console.error(error);
    }

  }



  filterFast() {
    this.dtConferences.filterGlobal(this.fastSearchConferenceKeyword, 'contains');
  }




  clearFastSearch() {
    this.fastSearchConferenceKeyword = '';
    this.filterFast();
  }

  toggleAdvancedSearch() {
    this.showAdvancedSearch = !this.showAdvancedSearch
  }

  getTheTooltip() {
    return ""
  }

  onSearchConferenceName(args?: any) {

  }

  onSearchCompanyName(args?: any) {

  }

  clearSearch() {
    this.search = new SearchConference()
    this.rangeDates = [];
    this.searchHelperService.setAdvancedSearch(this.search, ListConfAdvancedSearchLocalStorageKey)
    this.getAllConferences(this.search)
  }

  searchConference() {
    console.log("search", this.search)
    this.searchHelperService.setAdvancedSearch(cloneDeep(this.search), ListConfAdvancedSearchLocalStorageKey);
    this.getAllConferences(this.search);
  }

  exportCSV() {
    let fileName: string = "";
    fileName = this.app.translate.instant('bo.liste-conferences.fileName');
    fileName = fileName && fileName != '' ? fileName : 'list_conferences';
    let dateStr: string = this.datePipe.transform(new Date(), 'dd_MM_yyyy') ?? "";
    let hourStr: string = this.datePipe.transform(new Date(), 'HH_mm_ss') ?? "";
    fileName = fileName.replace('{date}', dateStr);
    fileName = fileName.replace('{hour}', hourStr);
    this.helperService.exportCSV(this.conferences, this.mapColumn(), fileName + new Date().toLocaleDateString(), true);
    console.log("this is exportCSV")
  }


  mapColumn() {
    return this.conferences.map((row: any) => {
      const filteredColumns: any = {};
      this.selectedColumns.forEach(column => {
        //   if(this.translalteService.currentLang === "fr"){
        switch (column.field) {
          case "NbSpeakers": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.NbSpeakers ?? ''
            break;
          case "Code": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = row.Code ?? ''
            break;
          case "Title": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] =

            `${this.app.sharedTools.getPropertySwitchLang(row, 'CnfdName') ?? ''}`
            + `${CsvExportSameColumnSeparator}${this.app.sharedTools.getPropertySwitchLang(row, 'CnfdDescription') ?? ''}`
            + this.getSpeakersText(row.Speakers);



            break;
          case "Cycle": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getPropertySwitchLang(row.ConferenceProgram, 'CnfprName') ?? ''
            break;
          case "Date": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.datePipe.transform(row.ConferenceEvent.CnfeHourStart, 'yyyy-MM-dd') ?? ''
            break;
          case "Horaire": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.datePipe.transform(row.ConferenceEvent.CnfeHourStart, 'HH:mm') ?? ''
            break;
          case "Site": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(row.ConferenceEvent.CategoryEventsDetails) ?? ''
            break;
          case "Etat": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(row.ConfStatus) ?? ''
            break;
          case "Publish": filteredColumns[column[this.app.sharedTools.getLangPropertyName('Label')]] = this.app.sharedTools.getLabelSwitchLang(row.PlubishOnWeb) ?? ''
            break;

          default:
            break;
        }
      });
      return filteredColumns;
    })
  }

  private getSpeakersText(speakers: any[]): string {
    let speakersText: string = "";
    if (!(speakers && Array.isArray(speakers))) {
      return speakersText;
    }

    speakers.forEach((s: any) => {
      speakersText += `${CsvExportSameColumnSeparator}${this.app.translate.instant('bo.liste-conferences.searchLabel-speaker')} : ${s.FullName ?? ''} - ${s.Email ?? ''} `
    });

    return speakersText;
  }

  async getCategories() {
    try {
      const response = await this.app.apiService.getCategories();
      let categories = cloneDeep(response);
      if (categories && Array.isArray(categories)) {
        if (this.isProgram) {
          this.sitesOptions = categories.filter((c: any) => c.CategoryType == CategoryType.ProgPlace)
        } else {
          this.sitesOptions = categories.filter((c: any) => c.CategoryType == CategoryType.ConfPlace)
        }
      }

    } catch (error) {
      console.error(error);
    }
  }

  onNewClick(): void {
    this.app.router.navigate([this.isProgram
      ? `/${ConferenceRoutes.createProg}`
      : `/${ConferenceRoutes.createConf}`]);
  }

  onEditClick(item: any): void {
    console.log('item ', item);
    let queryparams: any = {};
    if (this.isProgram) {
      queryparams = {
        programId: item.ConfDetailID
      }
    }
    else {
      queryparams = {
        conferenceId: item.ConfDetailID
      }
    }

    this.app.router.navigate([this.isProgram
      ? `/${ConferenceRoutes.createProg}`
      : `/${ConferenceRoutes.createConf}`], { queryParams: queryparams });
  }
}

